import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'meteringPointForm'
const translations = {
  newPoint: 'New accounting point',
  pointParams: 'Accounting point parameters',
  dontForget: 'In order not to forget, let\'s name the place of installation - Kitchen, Bathroom, Tambour ...',
  name: 'Title',
  resourceType: 'Resource type',
  systemDevice: 'System device',
  device: 'Device',
  channel: 'Channel',
  primaryDevice: 'PRIMARY DEVICE (METERING DEVICE)',
  deviceName: 'Name',
  serial: 'Serial number',
  date: 'Date of future verification',
  siNumber: 'Register number',
  unitType: 'Unit of measurement',
  value: 'Indications',
  onDate: 'On date',
  time: 'Time',
  fillWhenConnecting: 'Fill in when connecting the meter, or update to correct readings',
  impulseSettings: 'Pulse Setting',
  impulses: 'Impulses',
  resource: 'Resource',
  'deletePoint?': 'Delete point of account?',
  pulseChannelOne: 'Pulse channel 1',
  pulseChannelTwo: 'Pulse channel 2',
}

export const meteringPointFormEn = createTranslationsWithSpaceName(translations, spaceName)