import {WattHome} from "@/assets/svg";
import {NavLink} from "react-router-dom";
import LangSelect from "@/presentation/components/main/components/LangSelect";

const Logo = () => {
  return (
    <div className={'logo'}>
      <NavLink to={'/user'} replace={true} className="logo__wrapper">
        <img src={WattHome} alt="logo" />
        <span className='logo__text'>Watt</span>
        <span className="logo__beta">beta</span>
      </NavLink>

      {/*<LangSelect />*/}
    </div>
  )
}

export default Logo;
