import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";
import {createEvent, createEffect, sample} from "effector";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import {addressEndpoint} from "@/data/api_entities/address/address_endpoint";
import {FormattedMessage} from "react-intl";
import {setMessage} from "@/presentation/shared/ui/message";
import {useEvent, useStore} from "effector-react";

const meteringPointsListModel = new StoreModelFabric([])
const lastGetListRequestModel = new StoreModelFabric()
const updatePointsList = createEvent()

meteringPointsListModel.getList = createEffect(() => {
  const id = addressesSelectors.selectedAddressModel.storeValue?.id

  if (!id) return

  if (lastGetListRequestModel.storeValue) {
    lastGetListRequestModel.storeValue.abort()
  }

  const {promise, controller} = addressEndpoint.getMeterointsByAddressId(id)

  lastGetListRequestModel.setState(controller)

  promise
    .then(res => {
      meteringPointsListModel.setState(res?.metering_points ?? [])
      lastGetListRequestModel.resetStore()
    })
    .catch(err => {
      if (err.name === 'AbortError') return

      setMessage({
        header: <FormattedMessage id={'requests.error'}/>,
        message: <FormattedMessage id={'requests.getObjectsError'}/>,
        type: 'error',
        count: 3000
      })
      lastGetListRequestModel.resetStore()
      console.log(err)
    })
})

sample({
  clock: updatePointsList,
  target: meteringPointsListModel.getList
})

export const meteringPointsPageSelectors = {
  pointsList: {
    ...meteringPointsListModel.createSelectors(),
    useIsPointsAvailable: () => !!useStore(meteringPointsListModel.$store).length,
    useGetPointsList: () => useEvent(meteringPointsListModel.getList),
    useUpdatePointsList: () => useEvent(updatePointsList)
  },
  lastGetListRequest: lastGetListRequestModel.createSelectors(),
}
