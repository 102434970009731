import {useLocation, useNavigate} from "react-router-dom";
import {useMemo} from "react";

export const useNavigateBack = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const pathParts = useMemo(() => {
    return location.pathname.split('/')
  }, [location])

  return [(backCount = 1) => navigate(pathParts.slice(0, pathParts.length - backCount).join('/')), pathParts]
}
