import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import React, {useEffect, useMemo} from "react";
import ObjectCard from "@/presentation/components/mainPage/components/ObjectCard";
import {mainPageSelectors} from "@/internal/lib/storeModels/models/mainPage/mainPageModel";
import {FormattedMessage} from "react-intl";
import cookiesManager from "@/internal/lib/services/cookiManager";
import AddIcon from '@mui/icons-material/Add';
import {isOpenAsideSelectors} from "@/internal/lib/storeModels/models/status/asideFormModel";
import {managerAddressesSelectors} from "@/internal/lib/storeModels/models/servicemen/stores";
import Loader from "@/presentation/components/loader/Loader";
import {mainPageNewAddressFormSelectors} from "@/internal/lib/storeModels/models/mainPage/forms/newAddressFormModel";

const ObjectsList = ({isServicemen = false, isLoading = false}) => {
  const managerAddresses = managerAddressesSelectors.useValue()
  const addresses = addressesSelectors.addresses.useValue()
  const getAddresses = addressesSelectors.addresses.useGetAddresses()
  const searchQuery = mainPageSelectors.searchQuery.useValue()
  const setIsOpenAside= isOpenAsideSelectors.useSetValue()
  const setIsNew = mainPageSelectors.isNewAddress.useSetValue()
  const resetForm = mainPageNewAddressFormSelectors.useResetForm()

  useEffect(() => {
    return () => {
      resetForm()
    }
  }, [])

  const addressesList = managerAddresses ?? addresses

  const filteredAddresses = useMemo(() => {
    if (searchQuery.length < 1) return addressesList

    return addressesList.filter(address => {
      const fullAddress = `${address.street} ${address.building}`.toLowerCase()

      return address.name.toLowerCase().includes(searchQuery.toLowerCase()) || fullAddress.includes(searchQuery.toLowerCase())
    })
  }, [searchQuery, addressesList])

  const onClickAddNew = () => {
    setIsNew(true)
    setIsOpenAside(true)
  }

  useEffect(() => {
    if (!cookiesManager.get('X-pulse') || isServicemen) return

    getAddresses()
  }, [])

  return (
    <Loader loading={isLoading}>
      {filteredAddresses.length > 0
        ? (
          <div className="objects-list">
            <div className="objects-list__add" onClick={onClickAddNew}>
              <div>
                <AddIcon/>
              </div>
            </div>
            {filteredAddresses.map(address => {
              return <ObjectCard key={address.id} data={address} isServicemen={isServicemen}/>
            })}
          </div>
        )
        : (
          <div className={'objects-list-empty'}>
            <div className="objects-list__add" onClick={onClickAddNew}>
              <div>
                <AddIcon/>
              </div>
            </div>
            {addressesList.length > 0
              ? <FormattedMessage id={'app.nothingFound'}/>
              : <FormattedMessage id={'userPage.addAccountingObject'}/>
            }
          </div>
        )}
    </Loader>
  )
}

export default ObjectsList
