import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'objectTypes'
const translations = {
  0: 'Тип объекта учета не задан',
  1: 'Квартира',
  2: 'Коттедж (частный дом)',
  3: 'МКД (многоквартирный дом)',
  4: 'Помещение МКД (встроенное)',
  5: 'Офисное помещение',
  6: 'Офисный центр (здание)',
  7: 'СОШ (школы)',
  8: 'Больница (здание)',
  9: 'Административное здание',
  10: 'Спортивное здание',
  11: 'Производственное здание',
  12: 'Складское здание',
  13: 'Магазин (здание)',
  14: 'Прочие здания и помещения'
}

export const objectTypesRu = createTranslationsWithSpaceName(translations, spaceName)
