const LegalForm = () => {
  return (
    <div className='form-fields user__balance-form'>
      <div className="form-group">
        <label>ИНН плательщика</label>

        <input
          type="number"
        />
      </div>

      <div className="form-group">
        <label>Наименование плательщика</label>

        <input
          type="text"
        />
      </div>

      <div className="form-group">
        <label>Адрес (не обязательно)</label>

        <input
          type="text"
        />
      </div>

      <div className="form-group">
        <label>Сумма, ₽</label>

        <input
          type="number"
        />
      </div>
    </div>
  )
}

export default LegalForm
