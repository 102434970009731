import {DEVICE_PATH} from "./const";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import React, {useEffect, useRef, useState} from "react";
import {devicesEndpoint} from "@/data/api_entities/devices/devices_endpoint";
import {selectors, typeMessage} from "@/presentation/shared/ui/message";
import {devicePageSelectors} from "@/internal/lib/storeModels/models/devices/devicePageModels";
import {FormattedMessage} from "react-intl";
import CanBeLinkedDevicesList from "@/presentation/components/devicesPage/components/CanBeLinkedDevicesList";
import DevicesTypes from "@/presentation/components/devicesPage/devices_types";
import {addressEndpoint} from "@/data/api_entities/address/address_endpoint";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import {Route, Routes} from "react-router-dom";
import {NEW_DEVICE_PATH} from "@/presentation/components/devicesPage/const";
import DeviceForm from "@/presentation/components/deviceForm/DeviceForm";
import {
  newDevicesOnlySlavesFormSelectors
} from "@/internal/lib/storeModels/models/devices/forms/deviceFormModels";
import LinkTab from "@/presentation/components/common/LinkTab";
import {responseHandler} from "@/internal/lib/transport/response_handler";

const DeviceSlaves = ({deviceInfo, devices = [], onAddNewDevice}) => {
  const timer = useRef()
  const [isGetVisibility, setIsGetVisibility] = useState(false)
  const [isUntying, setIsUntying] = useState(false)
  const [isLinking, setIsLinking] = useState(false)
  const [unattachedDevices, setUnattachedDevices] = useState([])
  const [lastGetUnattachedRequest, setLastGetUnattachedRequest] = useState(null)

  const updateDevices = devicePageSelectors.devicesList.useGetDevicesList()
  const isLoadingDevices = !!devicePageSelectors.lastGetDevicesRequest.useValue()
  const selectedAddress = addressesSelectors.selectedAddress.useValue()
  const deviceRequestLoading = devicePageSelectors.deviceRequestLoading.useValue()
  const setMessage = selectors.useSetMessage()

  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [])

  useEffect(() => {
    getUnattachedDevices(deviceInfo?.type, selectedAddress?.id)
  }, [deviceInfo?.type, selectedAddress?.id, devices])

  function getUnattachedDevices(type, addressId) {
    if (!type || !addressId) return

    const masterDeviceGroupId = DevicesTypes.transferDevicesRsAndRadio.includes(type)
      ? DevicesTypes.transferDevicesRsAndRadioType
      : DevicesTypes.transferDevicesOnlyRadio.includes(type)
        ? DevicesTypes.transferDevicesOnlyRadioType
        : DevicesTypes.transferDevicesOnlyRS.includes(type)
          ? DevicesTypes.transferDevicesOnlyRSType
          : -1

    if (masterDeviceGroupId < 0) return

    if (lastGetUnattachedRequest) {
      lastGetUnattachedRequest.abort()
    }

    const {promise, controller} = addressEndpoint.getUnattachedEndDevices({
      addressId,
      deviceGroupId: masterDeviceGroupId
    })

    setLastGetUnattachedRequest(controller)

    promise
      .then(res => {
        setUnattachedDevices(res?.devices ?? [])
        setLastGetUnattachedRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return
        setLastGetUnattachedRequest(null)
        setUnattachedDevices([])
        console.log(err)
      })
  }

  const handleGetBridgePingStatus = () => {
    if (!deviceInfo.id) return

    const {promise} = devicesEndpoint.getBridgePingStatus(deviceInfo.id)

    promise
      .then(res => {
        responseHandler(res, {
          pending: {
            callback: () => {
              const timerId = setTimeout(handleGetBridgePingStatus, 5000)

              timer.current = timerId
            }
          },
          error: {
            callback: () => {
              setIsGetVisibility(false)
            },
            message: <FormattedMessage id={'requests.pollError'}/>
          },
          ok: {
            callback: () => {
              console.log(res)
              updateDevices()
              setIsGetVisibility(false)
            }
          }
        })
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.pollError'}/>,
          count: 3000,
          type: typeMessage.error
        })
      })
  }

  const handleGetBridgeVisibility = () => {
    if (isGetVisibility) return

    setIsGetVisibility(true)

    const {promise} = devicesEndpoint.getBridgeVisibility(deviceInfo.id)

    promise
      .then(res => {
        responseHandler(res, {
          ok: {
            callback: () => {
              handleGetBridgePingStatus()
            }
          },
          error: {
            callback: () => {
              setIsGetVisibility(false)
            },
            message: <FormattedMessage id={'requests.pollError'}/>
          }
        })
      })
      .catch(err => {
        console.log(err)
        setIsGetVisibility(false)
        setMessage({
          header:  <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.pollError'}/>,
          count: 3000,
          type: typeMessage.error
        })
      })
  }

  function onUntie(slaveId) {
    setIsUntying(true)

    devicesEndpoint.linkDevice(null, slaveId).promise
      .then(res => {
        responseHandler(res, {
          ok: {
            callback: () => {
              updateDevices()
            },
            header: <FormattedMessage id={'requests.untieDevicesSuccessHeader'}/>,
            message: <FormattedMessage id={'requests.untieDevicesSuccess'}/>
          },
          error: {
            message: <FormattedMessage id={'requests.untieDevicesError'}/>
          }
        })
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message:  <FormattedMessage id={'requests.untieDevicesError'}/>,
          count: 3000,
          type: typeMessage.error
        })
        console.log(err)
      })
      .finally(() => setIsUntying(false))
  }

  function onLink(slaveId) {
    setIsLinking(true)

    devicesEndpoint.linkDevice(deviceInfo.id, slaveId).promise
      .then(res => {
        responseHandler(res, {
          ok: {
            callback: () => {
              updateDevices()
            },
            header: <FormattedMessage id={'requests.tieDevicesSuccessHeader'}/>,
            message: <FormattedMessage id={'requests.tieDevicesSuccess'}/>
          },
          error: {
            message: <FormattedMessage id={'requests.tieDevicesError'}/>
          }
        })
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message:  <FormattedMessage id={'requests.tieDevicesError'}/>,
          count: 3000,
          type: typeMessage.error
        })
      })
      .finally(() => setIsLinking(false))
  }

  return (
    <>
      <div className="device-slaves">
        <ArrowBack
          pathOnVisible={DEVICE_PATH}
        />

        <div className="device-slaves__header">
          <h2 className="title">
            <FormattedMessage id={'devicesPage.devices'}/>
          </h2>

          <button
            className="btn btn_transparent-bg"
            disabled={isGetVisibility}
            onClick={handleGetBridgeVisibility}
          >
            <FormattedMessage id={'devicesPage.pollNow'}/>
          </button>

          {isGetVisibility
            ? <p><FormattedMessage id={'devicesPage.devicePolling'}/></p>
            // : <p>Поиск был произведен 01.04.21 в 13:45</p>
            : ''
          }

          {/*<div className="form-group">*/}
          {/*  <label>*/}
          {/*    <FormattedMessage id={'devicesPage.deviceSearch'}/>*/}
          {/*  </label>*/}

          {/*  <SearchSelect*/}
          {/*    placeholder={<FormattedMessage id={'devicesPage.serialNumber'}/>}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>

        <LinkTab
          path={NEW_DEVICE_PATH}
          text={<FormattedMessage id={'devicesPage.createAndTie'}/>}
        />

        <div className="device-slaves__devices">
          <CanBeLinkedDevicesList
            isLoading={isUntying || isLoadingDevices}
            title={<FormattedMessage id={'devicesPage.tied'}/>}
            devices={devices.filter(device => device.master === deviceInfo?.id)}
            actionName={<FormattedMessage id={'devicesPage.untie'}/>}
            onAction={onUntie}
          />

          <CanBeLinkedDevicesList
            title={<FormattedMessage id={'devicesPage.inVisibility'}/>}
            devices={deviceInfo?.visible_devices ?? []}
            actionName={<FormattedMessage id={'devicesPage.bind'}/>}
          />

          <CanBeLinkedDevicesList
            isLoading={!!lastGetUnattachedRequest || isLoadingDevices || isLinking}
            title={<FormattedMessage id={'devicesPage.availableDevices'}/>}
            devices={unattachedDevices}
            actionName={<FormattedMessage id={'devicesPage.bind'}/>}
            onAction={onLink}
          />
        </div>
      </div>

      <Routes>
        <Route
          path={NEW_DEVICE_PATH}
          element={
            <DeviceForm
              formModelSelectors={newDevicesOnlySlavesFormSelectors}
              pathOnVisible={NEW_DEVICE_PATH}
              onValidSubmit={onAddNewDevice}
              onlyNewSlaves
              loading={deviceRequestLoading}
              preexistMaster={deviceInfo}
            />
          }
        />
      </Routes>
    </>
  )
}

export default DeviceSlaves
