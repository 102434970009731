import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import React from "react";
import {FormattedMessage} from "react-intl";

const TablesModal = (
  {
    children,
    hideModal,
    isOpenCloseSubmitModal=false,
    toggleSubmitCloseModal,
    handleAgreeSubmitCloseModal,
  }) => {
  const handleClick = (e) => {
    const {target} = e

    if (target.closest('.MuiPopover-root') ||
      target.closest('.MuiCalendarPicker-root') ||
    target.closest('.default-modal')) return

    if (target && (!target.closest('.tables-modal__substr'))) {
      hideModal()
    }
  }

  return (
    <>
      <div
        className='tables-modal'
        onClick={handleClick}
      >
        <div className="tables-modal__substr">
          {children}
        </div>
      </div>

      <DefaultModal
        isOpen={isOpenCloseSubmitModal}
        headerTitle={<FormattedMessage id={'analytics.unsavedReport'}/>}
        bodyText={
          <>
            <FormattedMessage id={'analytics.leavePage?'}/>
            <br />
            <br />
            <FormattedMessage id={'analytics.loseData'}/>
          </>
        }
        agreeBtnConfig={{
          className: 'btn',
          text: <FormattedMessage id={'app.confirm'}/>
        }}
        disagreeBtnConfig={{
          className: 'btn btn_transparent-bg',
          text: <FormattedMessage id={'app.cancel'}/>
        }}
        toggleModal={toggleSubmitCloseModal}
        onDisagree={toggleSubmitCloseModal}
        onAgree={handleAgreeSubmitCloseModal}
      />
    </>
  )
}

export default TablesModal
