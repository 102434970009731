import {FormattedMessage} from "react-intl";

export const DISABLED_COLOR = '#FBFCFF'
export const ACTIVE_COLOR = '#ECF0F6'

export const SELECT_STYLES = {
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
    width: '100%',
    background: '#fff',
    boxShadow: '0 .5rem 1rem rgba(53, 96, 181, 0.2)',
    borderRadius: '.375rem',
    overflowX: 'hidden',
    margin: '0'
  }),
  option: () => ({
    display: 'flex',
    flexDirection: 'column',
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    padding: '.75rem 1rem',
    borderTop: '1px solid #ECF0F6',
    cursor: 'pointer',
    ':hover': {
      background: '#E6EFFE'
    },
    ':last-child': {
      borderBottom: 'none'
    },
    'span': {
      ':nth-of-type(1)': {
        fontSize: '.875rem',
        lineHeight: '143%',
        fontWeight: '500',
        color: '#36455D',
      },
      ':last-child': {
        fontSize: '.875rem',
        lineHeight: '143%',
        color: '#727A86',
        marginTop: '-0.25rem'
      }
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#727A86',
    margin: 0,
    padding: '0.5rem 0 0.5rem  1.0625rem',
  }),
  multiValue: (provided) => ({
    ...provided,
    'div': {
      padding: 0,
      display: 'flex',
      'span': {
        padding: '.1875rem'
      }
    }
  })
}

export const analysisTimeList = [
  {
    value: 0,
    label: 'Не указано'
  },
  {
    value: 12,
    label: '1 год'
  },
  // {
  //   value: 24,
  //   label: '2 года'
  // },
  // {
  //   value: 36,
  //   label: '3 года'
  // },
  // {
  //   value: 48,
  //   label: '4 года'
  // },
  // {
  //   value: 60,
  //   label: '5 лет'
  // },
  {
    value: 100,
    label: 'Добавть интервал'
  },
]

export const ANALYSIS_STEPS_LIST = [
  // {
  //   value: '',
  //   label: <FormattedMessage id={'app.notSpecified'}/>
  // },
  // {
  //   value: '1',
  //   label: 'День'
  // },
  // {
  //   value: '2',
  //   label: 'Неделя'
  // },
  {
    value: '3',
    label: <FormattedMessage id={'analytics.month'}/>
  }
]

export const ANALYSIS_PERIOD_TYPES = [
  {
    value: 1,
    label: <FormattedMessage id={'analytics.calendarYear'}/>
  },
  {
    value: 2,
    label: <FormattedMessage id={'analytics.heatYear'}/>
  },
]

export const INTERVAL_TYPES_LIST = [
  {
    value: 0,
    label: 'Не указано'
  },
  {
    value: 1,
    label: 'Месяц'
  },
  {
    value: 2,
    label: 'Год'
  },
]

export const stepTypesList = [
  {
    value: 0,
    label: 'Не указано'
  },
  {
    value: 1,
    label: 'День'
  },
  {
    value: 2,
    label: 'Неделя'
  },
  {
    value: 3,
    label: 'Месяц'
  },
  {
    value: 4,
    label: 'Год'
  },
]
