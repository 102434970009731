import {capitalizeFirstLetter} from "@/internal/formatters/formatters";
import {FormattedDate} from "react-intl";

class MKDHelpers {
  getAddress(object) {
    const street = (object?.street ?? '').length > 0
      ? `${object?.street} `
      : ''
    return street + (object?.building ?? '')
  }

  getRegion(object) {
    const region = (object?.region ?? '').length > 0
      ? `${object?.region}, `
      : ''
    return region + (object?.city ?? '')
  }

  getAddressWithApartments(object, apartment) {
    return `${this.getAddress(object)}, кв ${apartment}`
  }

  formatDateRange(start, end) {
    if (!start || !end) return '-'
    if (start.slice(5, 7) === end.slice(5,7)) {
      return <FormattedDate
        children={capitalizeFirstLetter}
        value={start}
        month={'long'}
        year={'numeric'}
      />
    } else {
      return (
        <>
          <FormattedDate
            children={capitalizeFirstLetter}
            value={start}
            month={'long'}
            year={'numeric'}
          />
          {' - '}
          <FormattedDate
            children={capitalizeFirstLetter}
            value={end}
            month={'long'}
            year={'numeric'}
          />
        </>
      )
    }

  }

  formatNumber = (number) => {
    if (number === null) {
      return '-';
    }
    if (typeof number === 'number') {
      return number.toFixed(4);
    }
    throw new Error('Недопустимый тип данных: ожидалось число или null');
  }
}

export const MKDHelper = new MKDHelpers()
