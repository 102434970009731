import {MONTH_SCHEDULE_TYPE} from "@/presentation/components/reportsPage/const";
import {DETAILING_BY_DAY_TYPE, DETAILING_BY_HOUR_TYPE} from "@/presentation/components/reportsPage/components/const";
import {formattedDateToServ} from "@/internal/formatters/formatters";

export const REPORT_INIT_DATA = Object.freeze({
  id: null,
  name: '',
  is_active: true,
  pointId: null,
  resource_type: 0,
  gizmo_name: '',
  device_name: '',
  type: 0,
  deviceId: null,
  deviceType: null,
  deviceSerial: '',
  channelOne: false,
  channelTwo: false,
  errors: false,
  device: false,
  gizmo: false,
  incremental: false,
  accumulated: false,
  body: false,
  dimension: 0,
  detailing: DETAILING_BY_DAY_TYPE,
  unit_type: 0,
  system_type: '',
  scheduleType: MONTH_SCHEDULE_TYPE,
  scheduleDay: 1,
  scheduleTime: '12:00',
  providerName: '',
  providerBailee: '',
  providerPhone: '',
  consumerName: '',
  consumerBailee: '',
  consumerPhone: '',
  serviceOrgName: '',
  serviceOrgBailee: '',
  serviceOrgPhone: '',
  receivers: [],
})

export const REPORT_INIT_VALIDATIONS = Object.freeze({
  name: [],
  providerPhone: [],
  consumerPhone: [],
  serviceOrgPhone: [],
  receivers: [],
  dimension: []
})

export const PREVIEW_REPORT_INIT_DATA = Object.freeze({
  detailingPreview: DETAILING_BY_DAY_TYPE,
  startDate: (function (){
    const now = new Date()

    now.setDate(now.getDate() - 1)

    return formattedDateToServ(now)
  })(),
  endDate: (function (){
    const now = new Date()

    return formattedDateToServ(now)
  })(),
  startTime: 12,
  endTime: 23
})
