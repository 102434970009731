import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'deviceForm'
const translations = {
  addingDevice: 'Adding a device',
  deviceWillBind: 'The equipment will be bound to the\xa0object "{name}". You can change the binding later in\xa0settings.',
  selectDeviceType: 'Choose the type of device',
  serial: 'Enter the serial number',
  serialPlaceholder: '{start}-00001234',
  simNumber: 'Enter SIM card number',
  deviceAdded: 'Device added',
  devicesAdded: 'Devices added',
  customizeImpulse: 'Configure pulse inputs for\xa0reading and\xa0synchronize the device.',
  addToMasterDevice: 'Add the hardware to interact with {name}',
  address: 'Address',
  tetheredDevices: 'Tethered devices',
  chooseTethered: 'Select from the list of equipment already registered in the system and/or add a new one.',
  chooseTetheredOnlyPreexist: 'Select from the list of equipment already registered in the system.',
  selectedDevices: 'Selected equipment:',
  addDevice: 'Add a device',
  toSelectDevice: 'Select device',
  toSelectEquipment: 'Select equipment'
}

export const deviceFormEn = createTranslationsWithSpaceName(translations, spaceName)
