import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'navBar'
const translations = {
  consumption: 'Verbrauch',
  equipment: 'Ausrüstung',
  reports: 'Berichte',
  events: 'Entwicklungen',
  analytic: 'Analytik',
  meteringPoints: 'Abrechnungspunkte',
  accountingObjects: 'Buchhaltungsobjekte',
  balance: 'Balance - {balance, number, ::currency/EUR}',
  main: 'Home'
}

export const navBarDe = createTranslationsWithSpaceName(translations, spaceName)
