import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {INIT_CHANGE_PASSWORD_DATA, INIT_CHANGE_PASSWORD_VALIDATIONS} from "../const";
import {getChangePassValidator} from "@/internal/validators/formValidators";

const userPasswordFormModel = new FormModelFabric({
  initialData: INIT_CHANGE_PASSWORD_DATA,
  initialValidations: INIT_CHANGE_PASSWORD_VALIDATIONS,
  getValidators: getChangePassValidator
})

export const userPasswordFormSelectors = userPasswordFormModel.createSelectors()
