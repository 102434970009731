import {EMPTY_COLUMN, TABLE_COLUMNS_COUNT} from "../const";
import {setObjTypeName} from "../helpers";
import {currencyFormatter} from "@/internal/formatters/formatters";
import {FormattedMessage} from "react-intl";
import {ACTIVE_COLOR, DISABLED_COLOR} from "@/presentation/components/analytics/const";
import {ANALYTIC_UNIT_TYPES} from "@/presentation/components/addressForm/const";
import {addressEndpoint} from "@/data/api_entities/address/address_endpoint";

const getСellColor = (value, isDisabled, minValue, averageArray, resultData, address) => {
  if (isDisabled) return DISABLED_COLOR
  if (averageArray.includes(value) &&
    value !== minValue &&
    value !== resultData.ahd_max &&
    value !== resultData.hd_max[address.id]) return '#FFCC9E'

  switch (value) {
    case resultData.ahd_max:
      return 'linear-gradient(to right, #FFCDCD 0%, #FFCDCD 50%, #FC4F6E 51%, #FC4F6E 100%)'
    case resultData.hd_max[address.id]:
      return '#FFCDCD'
    case resultData.ahd_min:
      return 'linear-gradient(to right, #C3D9FA 0%, #C3D9FA 50%, #C1F3C3 51%, #C1F3C3 100%)'
    case minValue:
      return '#C1F3C3'
    default:
      return 'transparent'
  }
}

export const getEconomicAnalysisTableParams = (tableParams, tableNumb, selectedAddresses, columnWidth, resultData, unusedMonthsCountBeforeStart) => {
  if (!tableParams || !tableParams.length) return null

  const width = parseFloat(columnWidth) * 14 / 16 + '%'

  return {
    headerRows: [
      {
        cellsList: [
          {
            rowSpan: 2,
            styles: {
              textAlign: 'left !important',
              width,
            },
            title: <FormattedMessage id={'analytics.objects'}/>,
          },
          {
            rowSpan: 2,
            title: <FormattedMessage id={'analytics.objectType'}/>,
            styles: {
              textAlign: 'left !important',
              width: width,
            },
          },
          {
            rowSpan: 2,
            title: <FormattedMessage id={'analytics.tariff'}/>,
            styles: {
              width,
            }
          },
          {
            colSpan: TABLE_COLUMNS_COUNT,
            isRefForFirstTableHeaderRow: true,
            title: <FormattedMessage id={'analytics.payments'}/>,
          },
          {
            rowSpan: 2,
            title: <FormattedMessage id={'analytics.totalOfPeriod'}/>,
            styles: {
              width,
            }
          }
        ]
      },
      {
        cellsList: [
          ...tableParams[tableNumb - 1].columns.map(column => (
            {
              ...column,
              rowSpan: 2,
              topStyleForStickyHeader: true,
              styles: {
                background: column.title === EMPTY_COLUMN
                  ? `${DISABLED_COLOR} !important`
                  : '',
                borderColor: column.title === EMPTY_COLUMN
                  ? `${ACTIVE_COLOR} !important`
                  : '',
                width,
              }
            }
          ))
        ]
      }
    ],
    bodyRows: selectedAddresses.map(address => {
      const lastColumnValue = (() => {
        let count = 0
        const economAnalysis = resultData.econom_analysis[address.id] ?? []

        economAnalysis.forEach(data => {
          count += +data
        })

        return currencyFormatter(count).slice(0, -1)
      })()

      return {
        cellsList: [
          {
            title: address.name,
            styles: {
              fontWeight: '500 !important',
              width,
              textAlign: 'left !important'
            },
          },
          {
            title: setObjTypeName(address.object_type),
            styles: {
              fontWeight: '500 !important',
              width: width,
              textAlign: 'left !important'
            },
          },
          {
            title: currencyFormatter(address.tariff).slice(0, -1),
            styles: {width},
          },
          ...tableParams[tableNumb - 1].columns.map((column, i) => {
            const isDisabled = column.title === EMPTY_COLUMN
            const valueIndex = (tableNumb - 1) * TABLE_COLUMNS_COUNT + i - unusedMonthsCountBeforeStart
            const economAnalysis = resultData.econom_analysis[address.id] ?? []
            const value = economAnalysis[valueIndex] ?? NaN
            const roundValue = !isDisabled
              ? Number.isNaN(value)
                ? '-'
                : currencyFormatter(value).slice(0, -1)
              : ''

            return {
              title: roundValue,
              styles: {
                background: `${ isDisabled ? DISABLED_COLOR : 'transparent'}`,
                borderTop: 'none',
                borderBottom: 'none',
                cursor: 'text',
                input: {
                  background: `${isDisabled ? DISABLED_COLOR : 'transparent'}`,
                },
                pointerEvents: `${isDisabled ? 'none' : ''}`,
                width,
              }
            }
          }),
          {
            styles: {
              fontWeight: '500 !important',
              width,
            },
            title: lastColumnValue
          }
        ]
      }
    })
  }
}

export const getIntakeEfficiencyTableParams = (tableParams, tableNumb, selectedAddresses, columnWidth, resultData, unusedMonthsCountBeforeStart) => {
  if (!tableParams || !tableParams.length) return null

  const width = parseFloat(columnWidth) * 14 / 15 + '%'

  return {
    headerRows: [
      {
        cellsList: [
          {
            title: <FormattedMessage id={'analytics.objects'}/>,
            styles: {
              textAlign: 'left !important',
              width
            },
            rowSpan: 2,
          },
          {
            title: <FormattedMessage id={'analytics.objectType'}/>,
            styles: {
              textAlign: 'left !important',
              width: width
            },
            rowSpan: 2,
          },
          {
            title: (
              <FormattedMessage
                id={'analytics.specificConsumption'}
                values={{
                  sup: chunks => (<sup>{chunks}</sup>),
                  unit: ANALYTIC_UNIT_TYPES.find(type => type.value === resultData?.analytic_unit_type)?.label ?? '-'
                }}
              />
            ),
            colSpan: TABLE_COLUMNS_COUNT,
            isRefForFirstTableHeaderRow: true
          },
          {
            title: <FormattedMessage id={'analytics.totalOfPeriod'}/>,
            rowSpan: 2,
            styles: {
              width
            }
          },
        ]
      },
      {
        cellsList: [
          ...tableParams[tableNumb - 1].columns.map(column => (
            {
              ...column,
              rowSpan: 2,
              topStyleForStickyHeader: true,
              styles: {
                background: column.title === EMPTY_COLUMN
                  ? `${DISABLED_COLOR} !important`
                  : '',
                borderColor: column.title === EMPTY_COLUMN
                  ? `${ACTIVE_COLOR} !important`
                  : '',
                width
              }
            }
          ))
        ]
      }
    ],
    bodyRows: selectedAddresses.map(address => {
      return {
        cellsList: [
          {
            title: address.name,
            styles: {
              fontWeight: '500 !important',
              width,
              textAlign: 'left !important'
            }
          },
          {
            title: setObjTypeName(address.object_type),
            styles: {
              fontWeight: '500 !important',
              width: width,
              textAlign: 'left !important'
            }
          },
          ...tableParams[tableNumb - 1].columns.map((column, i) => {
            const isDisabled = column.title === EMPTY_COLUMN
            const valueIndex = (tableNumb - 1) * TABLE_COLUMNS_COUNT + i - unusedMonthsCountBeforeStart
            const heatingDays = resultData.heating_days[address.id] ?? []
            const value = heatingDays[valueIndex] ?? NaN
            const averageArray = resultData.high_middle[address.id] ?? []
            const roundValue = !isDisabled
              ? Number.isNaN(value)
                ? '-'
                : value.toFixed(7)
              : ''

            const cellColor = getСellColor(value, isDisabled, resultData.hd_min[address.id], averageArray, resultData, address)

            return {
              title: roundValue,
              styles: {
                background: cellColor,
                borderTop: 'none',
                borderBottom: 'none',
                cursor: 'text',
                input: {
                  background: `${isDisabled ? DISABLED_COLOR: 'transparent'}`,
                },
                pointerEvents: `${isDisabled ? 'none' : ''}`,
                width
              }
            }
          }),
          {
            title: resultData.hd_middle[address.id]
              ? resultData.hd_middle[address.id].toFixed(7)
              : '-',
            styles: {
              fontWeight: '500 !important',
              width
            }
          }
        ]
      }
    })
  }
}

export const getEconomicEfficiencyTableParams = (tableParams, tableNumb, selectedAddresses, columnWidth, resultData, unusedMonthsCountBeforeStart) => {
  if (!tableParams || !tableParams.length) return null

  const width = parseFloat(columnWidth) * 14 / 17 + '%'

  return {
    headerRows: [
      {
        cellsList: [
          {
            title: <FormattedMessage id={'analytics.objects'}/>,
            styles: {
              textAlign: 'left !important',
              width
            },
            rowSpan: 2
          },
          {
            title: <FormattedMessage id={'analytics.objectType'}/>,
            styles: {
              textAlign: 'left !important',
              width: width
            },
            rowSpan: 2
          },
          {
            title: <FormattedMessage id={'analytics.overpayments'}/>,
            isRefForFirstTableHeaderRow: true,
            colSpan: TABLE_COLUMNS_COUNT
          },
          {
            title: <FormattedMessage id={'analytics.totalOfPeriod'}/>,
            rowSpan: 2,
            styles: {
              width
            }
          },
          {
            title: <FormattedMessage id={'analytics.overpaymentsPercent'}/>,
            rowSpan: 2,
            styles: {
              width
            }
          },
          {
            title: <FormattedMessage id={'analytics.totalOverpayment'}/>,
            rowSpan: 2,
            styles: {
              width
            }
          },
        ]
      },
      {
        cellsList: [
          ...tableParams[tableNumb - 1].columns.map(column => ({
            title: column.title,
            rowSpan: 2,
            topStyleForStickyHeader: true,
            styles: {
              background: column.title === EMPTY_COLUMN
                ? `${DISABLED_COLOR} !important`
                : '',
              borderColor: column.title === EMPTY_COLUMN
                ? `${ACTIVE_COLOR} !important`
                : '',
              width
            }
          }))
        ]
      }
    ],
    bodyRows: selectedAddresses.map(address => {
      const lastColumnValue = (() => {
        let count = 0
        const economEfficiency = resultData.econom_efficiency[address.id] ?? []

        economEfficiency.forEach(data => {
          count += +data
        })

        return currencyFormatter(count).slice(0, -1)
      })()

      const totalPayment = (() => {
        let count = 0
        const economAnalysis = resultData.econom_analysis[address.id] ?? []

        economAnalysis.forEach(data => {
          count += +data
        })

        return currencyFormatter(count).slice(0, -1)
      })()

      const overpaymentByPercent = (() => {
        const totalValue = parseFloat(totalPayment.replace(/\s/g, ''))
        const overValue = parseFloat(lastColumnValue.replace(/\s/g, ''))

        if (Number.isNaN(totalValue) || Number.isNaN(overValue) || Number.isNaN(totalValue/overValue)) return 0

        return (overValue/totalValue * 100).toFixed(2)
      })()

      return {
        cellsList: [
          {
            title: address.name,
            styles: {
              fontWeight: '500 !important',
              width,
              textAlign: 'left !important'
            }
          },
          {
            title: setObjTypeName(address.object_type),
            styles: {
              fontWeight: '500 !important',
              width: width,
              textAlign: 'left !important'
            }
          },
          ...tableParams[tableNumb - 1].columns.map((column, i) => {
            const isDisabled = column.title === EMPTY_COLUMN
            const valueIndex = (tableNumb - 1) * TABLE_COLUMNS_COUNT + i - unusedMonthsCountBeforeStart
            const economEfficiency = resultData.econom_efficiency[address.id] ?? []
            const value = economEfficiency[valueIndex] ?? NaN
            const roundValue = !isDisabled
              ? Number.isNaN(value)
                ? '-'
                : currencyFormatter(value).slice(0, -1)
              : ''

            return {
              title: roundValue,
              styles: {
                background: `${ isDisabled ? DISABLED_COLOR : 'transparent'}`,
                borderTop: 'none',
                borderBottom: 'none',
                cursor: 'text',
                input: {
                  background: `${isDisabled ? DISABLED_COLOR : 'transparent'}`,
                },
                pointerEvents: `${isDisabled ? 'none' : ''}`,
                width,
              }
            }
          }),
          {
            title: lastColumnValue,
            styles: {
              fontWeight: '500 !important',
              width,
            }
          },
          {
            title: overpaymentByPercent,
            styles: {
              fontWeight: '500 !important',
              width,
            }
          },
          {
            title: totalPayment,
            styles: {
              fontWeight: '500 !important',
              width,
            }
          }
        ]
      }
    })
  }
}

export const getReturnInvestmentsTableParams = (tableParams, tableNumb, selectedAddresses, columnWidth, resultData, unusedMonthsCountBeforeStart) => {
  if (!tableParams || !tableParams.length) return null

  const width = parseFloat(columnWidth) * 14 / 18 + '%'
  const doubleWidth = parseFloat(width) * 2 + '%'
  const halfWidth = parseFloat(width) / 2 + '%'

  return {
    headerRows: [
      {
        cellsList: [
          {
            title: <FormattedMessage id={'analytics.objects'}/>,
            rowSpan: 2,
            styles: {
              textAlign: 'left !important',
              width
            }
          },
          {
            title: <FormattedMessage id={'analytics.objectType'}/>,
            rowSpan: 2,
            styles: {
              textAlign: 'left !important',
              width: doubleWidth
            }
          },
          {
            title: (
              <FormattedMessage
                id={'analytics.baseValue'}
              />
            ),
            rowSpan: 2,
            styles: {
              width
            }
          },
          {
            title: <FormattedMessage id={'analytics.investmentReturn'}/>,
            colSpan: TABLE_COLUMNS_COUNT,
            isRefForFirstTableHeaderRow: true
          },
          {
            title: <FormattedMessage id={'analytics.totalCurrency'}/>,
            rowSpan: 2,
            colSpan: 2,
            styles: {
              width,
            }
          },
          {
            title: <FormattedMessage id={'analytics.investments'}/>,
            rowSpan: 2,
            styles: {
              width,
            }
          }
        ]
      },
      {
        cellsList: [
          ...tableParams[tableNumb - 1].columns.map(column => ({
            title: column.title,
            rowSpan: 2,
            topStyleForStickyHeader: true,
            styles: {
              background: column.title === EMPTY_COLUMN
                ? `${DISABLED_COLOR} !important`
                : '',
              borderColor: column.title === EMPTY_COLUMN
                ? `${ACTIVE_COLOR} !important`
                : '',
              width
            }
          }))
        ]
      }
    ],
    bodyRows: selectedAddresses.map(address => {
      const investmentsReturnTotal = (() => {
        return resultData.investments_return_total[address.id] !== null
          ? currencyFormatter(resultData.investments_return_total[address.id]).slice(0, -1)
          : '-'
      })()

      const investmentsReturnTotalPercent = (() => {
        return resultData.investments_return_total_percent[address.id] !== null
          ? currencyFormatter(resultData.investments_return_total_percent[address.id]).slice(0, -1)
          : '-'
      })()

      const totalInvestments = (() => {
        return resultData.investments_total[address.id] !== null
          ? currencyFormatter(resultData.investments_total[address.id]).slice(0, -1)
          : '-'
      })()

      return {
        cellsList: [
          {
            title: address.name,
            styles: {
              fontWeight: '500 !important',
              width,
              textAlign: 'left !important'
            }
          },
          {
            title: setObjTypeName(address.object_type),
            styles: {
              fontWeight: '500 !important',
              width: doubleWidth,
              textAlign: 'left !important'
            }
          },
          // {
          //   title: '12 месяцев',
          //   styles: {width}
          // },
          {
            title: resultData.investments_hd_middle[address.id]
              ? resultData.investments_hd_middle[address.id].toFixed(7)
              : '-',
            styles: {width}
          },
          ...tableParams[tableNumb - 1].columns.map((column, i) => {
            const isDisabled = column.title === EMPTY_COLUMN
            const valueIndex = (tableNumb - 1) * TABLE_COLUMNS_COUNT + i - unusedMonthsCountBeforeStart
            const values = resultData.investments_return
              ? resultData.investments_return[address.id] ?? []
              : []
            const value = values[valueIndex] ?? '-'
            const currencyValue = Number.isNaN(+value)
              ? value
              : currencyFormatter(value).slice(0, -1)

            return {
              title: isDisabled ? '' : currencyValue,
              styles: {
                borderTop: 'none',
                borderBottom: 'none',
                cursor: 'text',
                background: `${isDisabled
                  ? DISABLED_COLOR
                  : +value < 0
                    ? '#92CDDC'
                    : 'transparent'}`,
                pointerEvents: `${isDisabled ? 'none' : ''}`,
                width,
              }
            }
          }),
          {
            title: investmentsReturnTotal,
            styles: {
              fontWeight: '500 !important',
              width: halfWidth,
              minWidth: halfWidth,
              background: +resultData.investments_return_total_percent[address.id] >= 100
                ? '#C1F3C3'
                : 'transparent'
            }
          },
          {
            title: investmentsReturnTotalPercent,
            styles: {
              fontWeight: '500 !important',
              width: halfWidth,
              minWidth: halfWidth,
              background: +resultData.investments_return_total_percent[address.id] >= 100
                ? '#C1F3C3'
                : 'transparent'
            }
          },
          {
            title: totalInvestments,
            styles: {
              fontWeight: '500 !important',
              width
            }
          },
        ]
      }
    })
  }
}

export const getPivotTableParams = (columnWidth, resultData, selectedAddresses) => {
  if (!resultData) return null

  const width = parseFloat(columnWidth) * 14 / 9 + '%'

  const minAverageValue = (() => {
    return resultData && resultData.ahd_midle_best
      ? resultData.ahd_midle_best.toFixed(7)
      : 0
  })()

  const maxAverageValue = (() => {
    return resultData && resultData.ahd_middle_worse
      ? resultData.ahd_middle_worse.toFixed(7)
      : 0
  })()


  return {
    headerRows: [
      {
        cellsList: [
          {
            title: <FormattedMessage id={'analytics.objects'}/>,
            styles: {
              textAlign: 'left !important',
              width,
            }
          },
          {
            title: <FormattedMessage id={'analytics.objectType'}/>,
            styles: {
              textAlign: 'left !important' ,
              width
            }
          },
          {
            title: (
              <FormattedMessage
                id={'analytics.objectSq'}
                values={{
                  sup: chunks => (<sup>{chunks}</sup>)
                }}
              />
            ),
            styles: {
              width
            }
          },
          {
            title: <FormattedMessage id={'analytics.tariffByUnit'}/>,
            styles: {
              width
            }
          },
          {
            title: <FormattedMessage id={'analytics.Units'}/>,
            styles: {
              width
            }
          },
          {
            title: <FormattedMessage id={'analytics.totalPayments'}/>,
            styles: {
              width
            }
          },
          {
            title: <FormattedMessage id={'analytics.totalOverpayments'}/>,
            styles: {
              width
            }
          },
          {
            title: <FormattedMessage id={'analytics.totalOverpaymentsPercent'}/>,
            styles: {
              width
            }
          },
          {
            title: (
              <FormattedMessage
                id={'analytics.bestValue'}
                values={{
                  sup: chunks => (<sup>{chunks}</sup>),
                  unit: ANALYTIC_UNIT_TYPES.find(type => type.value === resultData?.analytic_unit_type)?.label ?? '-'
                }}
              />
            ),
            styles: {
              width
            }
          },
          {
            title: (
              <FormattedMessage
                id={'analytics.averageValue'}
                values={{
                  sup: chunks => (<sup>{chunks}</sup>),
                  unit: ANALYTIC_UNIT_TYPES.find(type => type.value === resultData?.analytic_unit_type)?.label ?? '-'
                }}
              />
            ),
            styles: {
              width
            }
          },
        ]
      }
    ],
    bodyRows: selectedAddresses.map(address => {
      const hdMin = resultData.hd_min[address.id]
      const heatingDays = resultData.heating_days[address.id] ?? []
      const economAnalysis = resultData.econom_analysis[address.id] ?? []
      const economEfficiency = resultData.econom_efficiency[address.id] ?? []
      const overpaymentsPercent = resultData.overpayment[address.id] ?? '-'
      const minValue = hdMin
        ? hdMin.toFixed(7)
        : '-'
      const averageValue = resultData.hd_middle[address.id] ?? '-'

      let valuesSum = 0
      let totalPayments = 0
      let totalOverPayments = 0
      let withoutCalcCount = 0

      heatingDays.forEach((value) => {
        if (!value && value !== 0) {
          withoutCalcCount++

          return
        }

        valuesSum += +value
      })

      economAnalysis.forEach(value => {
        totalPayments += +value
      })

      economEfficiency.forEach(value => {
        if (Number.isNaN(+value)) return

        totalOverPayments += +value
      })

      return {
        cellsList: [
          {
            title: address.name,
            styles: {
              fontWeight: '500 !important',
              textAlign: 'left !important',
              width
            }
          },
          {
            title: setObjTypeName(address.object_type),
            styles: {
              fontWeight: '500 !important',
              textAlign: 'left !important',
              width
            }
          },
          {
            title: address.area,
            styles: {
              width
            }
          },
          {
            title: currencyFormatter(address.tariff).slice(0, -1),
            styles: {
              width
            }
          },
          {
            title: ANALYTIC_UNIT_TYPES.find(type => type.value === address.unit_type)?.label ?? '-',
            styles: {
              width
            }
          },
          {
            title: currencyFormatter(totalPayments).slice(0, -1),
            styles: {
              width
            }
          },
          {
            title: currencyFormatter(totalOverPayments).slice(0, -1),
            styles: {
              width
            }
          },
          {
            title: overpaymentsPercent,
            styles: {
              width
            }
          },
          {
            title: minValue,
            styles: {
              width
            }
          },
          {
            title: averageValue,
            styles: {
              background: averageValue == minAverageValue
                ? '#C2F4D9'
                : averageValue == maxAverageValue
                  ? '#FC4F6E'
                  : 'transparent',
              width
            }
          },
        ]
      }
    })
  }
}
