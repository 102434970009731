import {request} from "@/internal/lib/transport/request";

export const userEndpoint = {
  getUserData() {
    const params = {
      url: 'api/profile/',
      method: 'GET'
    }

    return request(params).promise
  },

  setUserData (data) {
    const params = {
      url: 'api/profile/',
      method: 'POST',
      data
    }

    return request(params).promise
  },

  getConfEmailCode (data) {
    const params = {
      url: 'api/profile/email/',
      method: 'POST',
      data
    }

    return request(params).promise
  },

  sendConfirmEmailCode (data) {
    const params = {
      url: `api/profile/confirmation_code/`,
      method: 'POST',
      data
    }

    return request(params).promise
  },

  getUserCompanyInfo () {
    const params = {
      url: 'api/profile/company/',
      method: 'GET'
    }

    return request(params)
  },

  setUserCompanyInfo (data) {
    const params = {
      url: 'api/profile/company/',
      method: 'POST',
      data
    }

    return request(params).promise
  },

  deleteUser () {
    const params = {
      url: 'api/users/delete/',
      method: 'DELETE'
    }

    return request(params)
  },
}
