import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {INIT_CHANGE_ADDRESS_DATA} from "@/internal/lib/storeModels/models/user/const";
import {ANALYTICS_INIT_VALIDATIONS} from "@/internal/lib/storeModels/models/address/const";
import {getAddressFormValidators} from "@/internal/validators/formValidators";

const changeAddressFormModel = new FormModelFabric({
  initialData: INIT_CHANGE_ADDRESS_DATA,
  initialValidations: ANALYTICS_INIT_VALIDATIONS,
  getValidators: getAddressFormValidators
})

export const mainPageEditAddressFormSelectors = changeAddressFormModel.createSelectors()
