import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";

const activeStepState = new StoreModelFabric(0)
const enumeratorsFormsState = new StoreModelFabric({})
const loadingFormsState = new StoreModelFabric(false)
const editCalculateIdState = new StoreModelFabric()
const loadingCalcState = new StoreModelFabric(false)
const apartmentsListState = new StoreModelFabric([])
const notValidApartmentsState = new StoreModelFabric([])
const calcResultApartmentsState = new StoreModelFabric([])
const resultTotalValuesState = new StoreModelFabric()
const apartmentsPaginationParamsState = new StoreModelFabric({
  count: 0,
  pageSize: 0,
  currentPage: 0
})
const notValidPaginationParamsState = new StoreModelFabric({
  count: 0,
  pageSize: 0,
  currentPage: 0
})
const resultsPaginationParamsState = new StoreModelFabric({
  count: 0,
  pageSize: 0,
  currentPage: 0
})
const calcListState = new StoreModelFabric([])
const calcListPaginationParamsState = new StoreModelFabric()
const isForceCalcState = new StoreModelFabric(false)
const dataSendingState = new StoreModelFabric({})
const rowsPerPageState = new StoreModelFabric(20)
const calcCoastState = new StoreModelFabric(0)

export const calculationsSelectors = {
  activeStep: activeStepState.createSelectors(),
  enumeratorsForms: enumeratorsFormsState.createSelectors(),
  apartmentsList: apartmentsListState.createSelectors(),
  loadingForms: loadingFormsState.createSelectors(),
  editCalculateId: editCalculateIdState.createSelectors(),
  loadingCalc: loadingCalcState.createSelectors(),
  notValidApartments: notValidApartmentsState.createSelectors(),
  calcResultApartments: calcResultApartmentsState.createSelectors(),
  resultTotalValues: resultTotalValuesState.createSelectors(),
  apartmentsPaginationParams: apartmentsPaginationParamsState.createSelectors(),
  notValidPaginationParams: notValidPaginationParamsState.createSelectors(),
  resultsPaginationParams: resultsPaginationParamsState.createSelectors(),
  calcList: calcListState.createSelectors(),
  calcListPaginationParams: calcListPaginationParamsState.createSelectors(),
  isForceCalc: isForceCalcState.createSelectors(),
  dataSending: dataSendingState.createSelectors(),
  rowsPerPage: rowsPerPageState.createSelectors(),
  calcCoast: calcCoastState.createSelectors()
}
