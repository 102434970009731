import {NavLink} from "react-router-dom";
import {RESOURCES_TYPES} from "@/internal/content/consts";
import {dateFormatter} from "@/internal/formatters/formatters";

const CalcCard = ({data}) => {
  return (
    <NavLink
      className={'calc-card'}
      to={data.id.toString()}
    >
      <div className="calc-card__img">
        <img src={RESOURCES_TYPES[3].img} alt="hot"/>
      </div>

      <div className={'calc-card__info'}>
        <h6>{data.name}</h6>

        <span>
          {dateFormatter(data.start_date, true)} - {dateFormatter(data.end_date, true)}
        </span>
      </div>
    </NavLink>
  )
}

export default CalcCard
