import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'main'
const translations = {
  noObjects: 'No objects',
  setUpSystem: 'Let\'s set up the system',
  bindDevice: 'It is necessary to bind the equipment to the accounting object and set up the transfer of readings.',
  noDevices: 'No equipment?',
  onlineStore: 'Online store',
  stepOne: 'Step 1',
  stepTwo: 'Step 2',
  stepThree: 'Step 3',
  stepOneTitle: 'Accounting object',
  stepTwoTitle: 'Equipment',
  stepThreeTitle: 'Broadcast',
  stepOneText: 'Add an apartment, house or factory. Each facility has its own equipment',
  stepTwoText: 'Connect your device to the cloud and set up all inputs and outputs',
  stepThreeText: 'Bind a metering point and set up readings',
  stepOneBtn: 'Add',
  stepTwoBtn: 'To plug',
  stepThreeBtn: 'To plug'
}

export const mainEn = createTranslationsWithSpaceName(translations, spaceName)
