import React, {useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'

// class GoogleAnalytics extends React.Component {
//     componentWillUpdate({location, history}){
//         const gtag = window.gtag;
//
//         if(location.pathname === this.props.location.pathname){
//             return;
//         }
//
//         if(history.action === 'PUSH' && typeof(gtag) === 'function') {
//             gtag('config', 'G-M9KZBTBZRM', {
//                 'page_title': document.title,
//                 'page_location': document.location.href,
//                 'page_path': document.pathname,
//             })
//         }
//     }
//     render (){
//         return null;
//     }
// }
//
// console.log('document.pathname', document.pathname)
//
// export default withRouter(GoogleAnalytics)

const GoogleAnalytics = () => {
  const isFirstRender = useRef(true)
  const location = useLocation()

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false

      return
    }

    const gtag = window.gtag

    if (typeof(gtag) !== 'function') return

    gtag('config', 'G-M9KZBTBZRM',
      {
        'page_title': document.title,
        'page_location': document.location.href,
        'page_path': location.pathname,
      }
    )
  }, [location.pathname])

  return null
}

export default GoogleAnalytics