const GroupedList = ({groupTitle, list}) => {
  return (
    <div className={'grouped-list'}>
      <h4 className="title_h4">{groupTitle}</h4>

      <div className="grouped-list__list">
        {list}
      </div>
    </div>
  )
}

export default GroupedList
