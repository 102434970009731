import {MONTH_SCHEDULE_TYPE} from "../const";
import {FormattedMessage} from "react-intl";
import React from "react";

export const REPORT_BY_DEVICE = <FormattedMessage id={'reportsPage.reportByDevice'}/>
export const REPORT_BY_RESOURCE = <FormattedMessage id={'reportsPage.reportByResource'}/>
export const REPORT_BY_METERING_POINT = <FormattedMessage id={'reportsPage.reportByMeteringPoint'}/>

export const DETAILING_BY_HOUR_TYPE = 0
export const DETAILING_BY_DAY_TYPE = 1
export const DETAILING_BY_MONTH_TYPE = 2
export const DETAILING_BY_GRAND_TYPE = 3

export const DETAILING_TYPES_LIST = [
  {
    value: DETAILING_BY_HOUR_TYPE,
    label: <FormattedMessage id={'app.byHour'}/>
  },
  {
    value: DETAILING_BY_DAY_TYPE,
    label: <FormattedMessage id={'app.byDay'}/>
  },
  {
    value: DETAILING_BY_MONTH_TYPE,
    label: <FormattedMessage id={'app.byMonth'}/>
  },
  {
    value: DETAILING_BY_GRAND_TYPE,
    label: <FormattedMessage id={'app.byGrand'}/>
  },
]

export const DISPLAY_INTEGRAL_TYPE = 1
export const DISPLAY_INCREMENT_TYPE = 2
export const DISPLAY_INTEGRAL_INCREMENT_TYPE = 3

export const DISPLAY_TYPES_LIST = [
  {
    value: 0,
    label: <FormattedMessage id={'app.notSpecified'}/>
  },
  {
    value: DISPLAY_INTEGRAL_TYPE,
    label: <FormattedMessage id={'reportsPage.integral'}/>
  },
  {
    value: DISPLAY_INCREMENT_TYPE,
    label: <FormattedMessage id={'reportsPage.increment'}/>
  },
  {
    value: DISPLAY_INTEGRAL_INCREMENT_TYPE,
    label: <FormattedMessage id={'reportsPage.integralAndIncrement'}/>
  },
]

export const DIMENSION_ZERO_TYPE = 1
export const DIMENSION_TEN_TYPE = 2
export const DIMENSION_HUNDRED_TYPE = 3
export const DIMENSION_THOUSAND_TYPE = 4
export const DIMENSION_TEN_THOUSAND_TYPE = 5

export const DIMENSION_TYPES_LIST = [
  {
    value: 0,
    label: <FormattedMessage id={'app.notSpecified'}/>
  },
  {
    value: DIMENSION_ZERO_TYPE,
    label: '0'
  },
  {
    value: DIMENSION_TEN_TYPE,
    label: '0,0'
  },
  {
    value: DIMENSION_HUNDRED_TYPE,
    label: '0,00'
  },
  {
    value: DIMENSION_THOUSAND_TYPE,
    label: '0,000'
  },
  {
    value: DIMENSION_TEN_THOUSAND_TYPE,
    label: '0,0000'
  },
]

export const FIRST_EMAIL_INPUT = Object.freeze({
  value: '',
  validationMsg: []
})

export const PHONE_INPUTS_NAMES_LIST = ['providerPhone', 'consumerPhone', 'serviceOrgPhone']
export const DEFAULT_SCHEDULE_VALUES = Object.freeze({
  scheduleType: MONTH_SCHEDULE_TYPE,
  scheduleDay: 1,
  // receivers: []
})


export const PERIOD_LEFT_SELECT_STYLES = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? 0 : 0,
    border: '1px solid #ECF0F6',
    borderRadius: '0.375rem',
    ':hover': {
      border: '1px solid #ECF0F6',
      borderRight: 'none'
    },
    borderRight: 'none',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    minHeight: 'unset',
    cursor: 'pointer',
    backgroundColor: state.isDisabled ? 'transparent' : 'transparent',
  }),
}

export const PERIOD_RIGHT_SELECT_STYLES = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? 0 : 0,
    border: '1px solid #ECF0F6',
    borderRadius: '0.375rem',
    ':hover': {
      border: '1px solid #ECF0F6',
    },
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    minHeight: 'unset',
    cursor: 'pointer',
    backgroundColor: state.isDisabled ? 'transparent' : 'transparent',
  }),
}

export const TIME_INPUT_SELECT_STYLES = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? 0 : 0,
    border: '1px solid #ECF0F6',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    borderRadius: '0.375rem',
    ':hover': {
      border: '1px solid #ECF0F6'
    },
    minHeight: 'unset',
    cursor: 'pointer',
    backgroundColor: state.isDisabled ? 'rgba(239, 239, 239, 0.3)' : 'transparent',
  }),
}

export const TIME_LIST = [
  {
    value: 0,
    label: "00:00"
  },
  {
    value: 1,
    label: "01:00"
  },
  {
    value: 2,
    label: "02:00"
  },
  {
    value: 3,
    label: "03:00"
  },
  {
    value: 4,
    label: "04:00"
  },
  {
    value: 5,
    label: "05:00"
  },
  {
    value: 6,
    label: "06:00"
  },
  {
    value: 7,
    label: "07:00"
  },
  {
    value: 8,
    label: "08:00"
  },
  {
    value: 9,
    label: "09:00"
  },
  {
    value: 10,
    label: "10:00"
  },
  {
    value: 11,
    label: "11:00"
  },
  {
    value: 12,
    label: "12:00"
  },
  {
    value: 13,
    label: "13:00"
  },
  {
    value: 14,
    label: "14:00"
  },{
    value: 15,
    label: "15:00"
  },{
    value: 16,
    label: "16:00"
  },{
    value: 17,
    label: "17:00"
  },
  {
    value: 18,
    label: "18:00"
  },
  {
    value: 19,
    label: "19:00"
  },
  {
    value: 20,
    label: "20:00"
  },
  {
    value: 21,
    label: "21:00"
  },
  {
    value: 22,
    label: "22:00"
  },
  {
    value: 23,
    label: "23:00"
  },
]
