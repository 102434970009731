import {DEVICE_TRANSFER_DEVICES_PATH} from "@/presentation/components/devicesPage/components/const";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import React, {useEffect, useState} from "react";
import CanBeLinkedDevicesList from "@/presentation/components/devicesPage/components/CanBeLinkedDevicesList";
import {addressEndpoint} from "@/data/api_entities/address/address_endpoint";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import DevicesTypes from "@/presentation/components/devicesPage/devices_types";
import {FormattedMessage} from "react-intl";
import {devicesEndpoint} from "@/data/api_entities/devices/devices_endpoint";
import {devicePageSelectors} from "@/internal/lib/storeModels/models/devices/devicePageModels";
import {useNavigateBack} from "@/internal/custom_hooks/navigateBack";
import {responseHandler} from "@/internal/lib/transport/response_handler";
import {selectors, typeMessage} from "@/presentation/shared/ui/message";

const TransferDevices = ({slave}) => {
  const selectedAddress = addressesSelectors.selectedAddress.useValue()
  const updateDevices = devicePageSelectors.devicesList.useGetDevicesList()
  const setMessage = selectors.useSetMessage()

  const [lastGetDevicesRequest, setLastGetDevicesRequest] = useState(null)
  const [devices, setDevices] = useState([])
  const [isLinking, setIsLinking] = useState(false)
  const [navigateBack] = useNavigateBack()

  useEffect(() => {
    getDevicesList(slave?.type, selectedAddress?.id)
  }, [slave?.type, selectedAddress?.id])

  function getDevicesList(type, addressId) {
    if (!type || !addressId) return
    const slaveDeviceGroupType = DevicesTypes.endDevicesRsAndRadio.includes(type)
      ? DevicesTypes.endDevicesRsAndRadioType
      : DevicesTypes.endDevicesOnlyRadio.includes(type)
        ? DevicesTypes.endDevicesOnlyRadioType
        : DevicesTypes.endDevicesOnlyRS.includes(type)
          ? DevicesTypes.endDevicesOnlyRSType
          : -1
    if (slaveDeviceGroupType < 0) return

    if (lastGetDevicesRequest) {
      lastGetDevicesRequest.abort()
    }

    const {promise, controller} = addressEndpoint.getTransferDevices({
      addressId: addressId,
      deviceGroupId: slaveDeviceGroupType
    })

    setLastGetDevicesRequest(controller)

    promise
      .then(res => {
        setDevices(res?.devices ?? [])
        setLastGetDevicesRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return

        setLastGetDevicesRequest(null)
        setDevices([])
      })
  }

  function onLink(masterId) {
    if (!slave?.id) return

    setIsLinking(true)

    devicesEndpoint.linkDevice(masterId, slave?.id).promise
      .then(res => {
        responseHandler(res, {
          ok: {
            callback: () => {
              updateDevices()
              navigateBack()
            },
            header: <FormattedMessage id={'requests.tieDevicesSuccessHeader'}/>,
            message: <FormattedMessage id={'requests.tieDevicesSuccess'}/>
          },
          error: {
            message: <FormattedMessage id={'requests.tieDevicesError'}/>
          }
        })
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message:  <FormattedMessage id={'requests.tieDevicesError'}/>,
          count: 3000,
          type: typeMessage.error
        })
        console.log(err)
      })
      .finally(() => setIsLinking(false))
  }

  return (
    <div className="transfer-devices">
      <ArrowBack
        pathOnVisible={DEVICE_TRANSFER_DEVICES_PATH}
      />

      <CanBeLinkedDevicesList
        devices={devices}
        actionName={<FormattedMessage id={'devicesPage.bind'}/>}
        title={<FormattedMessage id={'devicesPage.availableDevices'}/>}
        isLoading={!!lastGetDevicesRequest || isLinking}
        onAction={onLink}
      />
    </div>
  )
}

export default TransferDevices
