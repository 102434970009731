import React, {useEffect} from "react";
import {FormattedDate, FormattedMessage, useIntl} from "react-intl";
import Input from "@/presentation/components/common/formComponents/Input";
import DadataSelect from "@/presentation/components/addressForm/DadataSelect";
import {OBJECT_TYPE_OPTIONS} from "@/internal/content/consts";
import SelectInput from "@/presentation/components/common/formComponents/SelectInput";
import {ANALYTIC_UNIT_TYPES, CURRENCY_TYPES, WITHOUT_FLAT_TYPES} from "@/presentation/components/addressForm/const";
import {RIGHT_SELECT_WITH_INPUT, RIGHT_SELECT_WITH_INPUT_ERROR} from "@/presentation/components/common/selects/const";
import ValidationsWrapper from "@/presentation/components/common/formComponents/ValidationsWrapper";
import WithHistory from "@/presentation/components/addressForm/WithHistory";

const AddressModalForm = (
  {
    getAddressInfo,
    formModelSelectors,
    tariffsHistory
  }) => {
  const intl = useIntl()
  const resetForm = formModelSelectors.useResetForm()
  // const objectType = formModelSelectors.useFormDataValue('object_type')
  const unitType = formModelSelectors.useFormDataValue('unit_type')
  const currencyType = formModelSelectors.useFormDataValue('currency_type')
  const formValidations = formModelSelectors.useFormValidations()

  const tariffsHistoryList = []/*tariffsHistory.reverse().map((data, i) => {
    return (
      <div
        className="object-form__history-item"
        key={`tariff-${i}`}
      >
        <span>
          {data.tariff} {CURRENCY_TYPES.find(type => type.value === data.currency_type)?.label ?? '?'}
        </span>

        <span>
          <FormattedMessage
            id={'addressForm.tillDate'}
            values={{
              date: <FormattedDate value={data.date} />
            }}
          />
        </span>
      </div>
    )
  })*/

  const areaHistory = /*[
    {
      value: 36,
      date: '2022-02-02'
    }
  ]*/ []

  const areaHistoryList = areaHistory.reverse().map((data, i) => {
    return (
      <div
        className="object-form__history-item"
        key={`area-${i}`}
      >
        <span>
          <FormattedMessage
            id={'addressForm.areaValue'}
            values={{
              value: data.value,
              sup: (chunks) => (<sup>{chunks}</sup>)
            }}
          />
        </span>

        <span>
          <FormattedMessage
            id={'addressForm.tillDate'}
            values={{
              date: <FormattedDate value={data.date} />
            }}
          />
        </span>
      </div>
    )
  })

  useEffect(() => {
    getAddressInfo()

    return () => {
      resetForm()
    }
  }, [])

  return (
    <div className="form-fields">
      <Input
        label={<FormattedMessage id={'addressForm.objectName'}/>}
        placeholder={intl.formatMessage({id: 'addressForm.myHouse'})}
        name='name'
        formSelectors={formModelSelectors}
      />

      {/*<SelectInput*/}
      {/*  label={<FormattedMessage id={'addressForm.objectType'}/>}*/}
      {/*  name={'object_type'}*/}
      {/*  formSelectors={formModelSelectors}*/}
      {/*  options={OBJECT_TYPE_OPTIONS}*/}
      {/*/>*/}

      {/*<DadataSelect*/}
      {/*  label={<FormattedMessage id={'addressForm.findAddress'}/>}*/}
      {/*  addressFormSelectors={formModelSelectors}*/}
      {/*  placeholder={<FormattedMessage id={'addressForm.enterAddress'}/>}*/}
      {/*/>*/}

      {/*<DadataSelect*/}
      {/*  name={'region'}*/}
      {/*  label={<FormattedMessage id={'addressForm.region'}/>}*/}
      {/*  addressFormSelectors={formModelSelectors}*/}
      {/*/>*/}

      {/*<DadataSelect*/}
      {/*  name='city'*/}
      {/*  label={<FormattedMessage id={'addressForm.city'}/>}*/}
      {/*  startQueryFormNames={['region']}*/}
      {/*  addressFormSelectors={formModelSelectors}*/}
      {/*/>*/}

      {/*<DadataSelect*/}
      {/*  name={'street'}*/}
      {/*  label={<FormattedMessage id={'addressForm.street'}/>}*/}
      {/*  addressFormSelectors={formModelSelectors}*/}
      {/*  startQueryFormNames={['region', 'city']}*/}
      {/*/>*/}

      {/*<DadataSelect*/}
      {/*  name={'building'}*/}
      {/*  label={<FormattedMessage id={'addressForm.building'}/>}*/}
      {/*  addressFormSelectors={formModelSelectors}*/}
      {/*  startQueryFormNames={['region', 'city', 'street']}*/}
      {/*/>*/}

      {/*{*/}
      {/*  !WITHOUT_FLAT_TYPES.includes(objectType) &&*/}
      {/*  <DadataSelect*/}
      {/*    name={'apartment'}*/}
      {/*    label={<FormattedMessage id={'addressForm.apartment'}/>}*/}
      {/*    addressFormSelectors={formModelSelectors}*/}
      {/*    startQueryFormNames={['region', 'city', 'street', 'building']}*/}
      {/*  />*/}
      {/*}*/}

      <SelectInput
        label={<FormattedMessage id={'addressForm.unitType'}/>}
        name={'unit_type'}
        formSelectors={formModelSelectors}
        options={ANALYTIC_UNIT_TYPES}
      />

      <WithHistory
        title={<FormattedMessage id={'addressForm.tariffsChanges'}/>}
        historyList={tariffsHistoryList}
      >
        <div className="object-form__double-inputs">
          <ValidationsWrapper validationMsgs={formValidations.tariff}>
            <Input
              label={
                <FormattedMessage
                  id={'addressForm.tariff'}
                  values={{
                    currency: CURRENCY_TYPES.find(type => type.value === currencyType)?.label ?? '-',
                    unit: ANALYTIC_UNIT_TYPES.find(type => type.value === unitType)?.label ?? '-'
                  }}
                />
              }
              type="number"
              name='tariff'
              formSelectors={formModelSelectors}
              isShowValidMsg={false}
            />

            <SelectInput
              options={CURRENCY_TYPES}
              formSelectors={formModelSelectors}
              name={'currency_type'}
              styles={!!formValidations.tariff.length
                ? RIGHT_SELECT_WITH_INPUT_ERROR
                : RIGHT_SELECT_WITH_INPUT}
            />
          </ValidationsWrapper>
        </div>
      </WithHistory>


      <WithHistory
        title={<FormattedMessage id={'addressForm.areaChanges'}/>}
        historyList={areaHistoryList}
      >
        <Input
          label={<FormattedMessage id={'addressForm.area'}/>}
          formSelectors={formModelSelectors}
          type="number"
          name='area'
        />
      </WithHistory>

      {/*<Input*/}
      {/*  label={<FormattedMessage id={'addressForm.lat'}/>}*/}
      {/*  formSelectors={formModelSelectors}*/}
      {/*  type="number"*/}
      {/*  name='geo_lat'*/}
      {/*/>*/}

      {/*<Input*/}
      {/*  label={<FormattedMessage id={'addressForm.lon'}/>}*/}
      {/*  formSelectors={formModelSelectors}*/}
      {/*  type="number"*/}
      {/*  name='geo_len'*/}
      {/*/>*/}
    </div>
  )
}

export default AddressModalForm
