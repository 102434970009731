import {NavLink} from "react-router-dom";
import {RESOURCES_TYPES} from "@/internal/content/consts";
import {FormattedDate, FormattedMessage} from "react-intl";

const AnalyticItem = ({data}) => {
  return (
    <NavLink to={String(data.id)} className={'analytic-item'}>
      <div className="analytic-item__img">
        <img src={RESOURCES_TYPES[3].img} alt="hot"/>
      </div>

      <div className="analytic-item__info">
        <h6>{data.name}</h6>

        <span>
          <FormattedDate value={data.start_date} month={'long'} year={'numeric'}/> - <FormattedDate value={data.end_date} month={'long'} year={'numeric'}/>
        </span>

        <span>
          <FormattedMessage id={'analytics.created'}/>
          <FormattedDate value={data.date}/>
        </span>
      </div>
    </NavLink>
  )
}

export default AnalyticItem
