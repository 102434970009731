export const filteredAddressOptions = (option, searchText) => {
  const address = `${option.data.street} ${option.data.building}`.toLowerCase()
  return option.data.name.toLowerCase().includes(searchText.toLowerCase()) || address.includes(searchText.toLowerCase())
}

export const formatOptionLabel = option => {
  const apartment = option.apartment
    ? `  кв ${option.apartment}`
    : option.apartment

  return (
    <div className={'address-select'}>
      <div className={'address-select__title-wrapper'}>
        <span>{option.name}</span>

        <span>{option.street} {option.building}{apartment}</span>
      </div>
    </div>
  )
}