import {useEffect, useMemo, useState} from "react";
import AuthorizationLayout from "./AuthorizationLayout";
import PdfComponent from "./components/PdfComponent";
import Switch from '@mui/material/Switch';
import IndividualForm from "./components/IndividualForm";
import CorporateForm from "./components/CorporateForm";
import PasswordRules from "../common/PasswordRules";
import {
   registrationPasswordValidationsSelectors,
} from "@/internal/lib/storeModels/models/authorization/registrationPasswordModel";
import {authorizationEndpoint} from "@/data/api_entities/authorization/authorization_endpoint";
import Loader from "@/presentation/components/loader/Loader";
import {useNavigate} from "react-router-dom";
import {corporateFormSelectors, individualFormSelectors} from "@/internal/lib/storeModels/models/authorization/forms/registrationUserModels";
import {authorizationSelectors} from "@/internal/lib/storeModels/models/authorization/authorizationModels";
import {FormattedMessage} from "react-intl";

const Registration = () => {
  const navigate = useNavigate()

  const [isIndividual, setIsIndividual] = useState(true)
  const [isPdfOpen, setIsPdfOpen] = useState(false)
  const [isOffertAgree, setIsOffertAgree] = useState(false)

  const passwordValidations = registrationPasswordValidationsSelectors.usePasswordValidations()
  const resetPasswordValidations = registrationPasswordValidationsSelectors.useResetValidations()
  const isValidUserForm = individualFormSelectors.useIsFormValid()
  const resetIndividualForm = individualFormSelectors.useResetForm()
  const setOnValidIndividualForm = individualFormSelectors.useSetOnValidSubmit()
  const submitIndividualForm = individualFormSelectors.useOnClickSubmit()
  const isValidCorpForm = corporateFormSelectors.useIsFormValid()
  const resetCorpForm = corporateFormSelectors.useResetForm()
  const setOnValidCorpForm = corporateFormSelectors.useSetOnValidSubmit()
  const submitCorpForm = corporateFormSelectors.useOnClickSubmit()
  const [isLoadingCorporateRegistration, setIsLoadingCorporateRegistration] = authorizationSelectors.isLoadingCorporateRegistration.useState()
  const [isLoadingUserRegistration, setIsLoadingUserRegistration] = authorizationSelectors.isLoadingUserRegistration.useState()
  const [confirmRegistrationData, setRegistrationData]= authorizationSelectors.registrationData.useState()
  const [registrationErrorMsg, setRegistrationErrorMsg] = authorizationSelectors.registrationErrorMsg.useState()

  const toggleIndividual = () => setIsIndividual(!isIndividual)
  const toggleIsPdfOpen = () => setIsPdfOpen(!isPdfOpen)

  const handleAgreeOffert = () => {
    setIsOffertAgree(true)
    toggleIsPdfOpen()
  }

  const handleDisagreeOffert = () => {
    setIsOffertAgree(false)
    toggleIsPdfOpen()
  }

  const onClickOffertCheckbox = () => setIsOffertAgree(!isOffertAgree)

  const offertPdf = isPdfOpen
    ? <PdfComponent
      toggleIsPdfOpen={toggleIsPdfOpen}
      onAgree={handleAgreeOffert}
      onDisagree={handleDisagreeOffert}
    />
    : null

  const isValidForm = useMemo(() => {
    return isValidUserForm && isValidCorpForm && isOffertAgree && passwordValidations.isValid
  }, [isValidUserForm, isOffertAgree, passwordValidations.isValid, isValidCorpForm])

  useEffect(() => {
    // if (confirmRegistrationData.email) {
    //   navigate('/email_approve/confirm_registration')
    //   return
    // }

    setOnValidIndividualForm(onValidUserFormSubmit)
    setOnValidCorpForm(onValidCorpFormSubmit)

    return () => {
      resetIndividualForm()
      resetCorpForm()
    }
  }, [])

  useEffect(() => {
    resetPasswordValidations()
    resetIndividualForm()
    resetCorpForm()
    setIsOffertAgree(false)
    setRegistrationErrorMsg('')
  }, [isIndividual])

  const onValidUserFormSubmit = (data) => {
    setIsLoadingUserRegistration(true)

    authorizationEndpoint.registerNewUser(data)
      .then(res => {
        if (res.code === 201) {
          setRegistrationData(data)
          navigate('/email_approve/confirm_registration')
        } else if (res.code === 441 || res.code === 442) {
          setRegistrationErrorMsg(<FormattedMessage id={'authorization.invalidData'}/>)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setIsLoadingUserRegistration(false))
  }

  const onValidCorpFormSubmit = (data) => {
    const dataWithLogin = {
      ...data,
      login: data.organization_name
    }

    setIsLoadingCorporateRegistration(true)

    authorizationEndpoint.registerNewUser(dataWithLogin)
      .then(res => {
        if (res.code === 201) {
          setRegistrationData(dataWithLogin)
          navigate('/email_approve/confirm_registration')
        } else if (res.code === 441 || res.code === 442) {
          setRegistrationErrorMsg(<FormattedMessage id={'authorization.invalidData'}/>)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setIsLoadingCorporateRegistration(false))
  }

  const handleSubmit = () => {
    if (!!registrationErrorMsg) setRegistrationErrorMsg('')

    if (!isValidForm) return

    if (isIndividual) {
      submitIndividualForm()
    } else {
      submitCorpForm()
    }
  }

  return (
    <AuthorizationLayout
      customClass="registration"
      title={<FormattedMessage id={'authorization.registration'}/>}
      beforeHeader={offertPdf}
    >
      <Loader loading={isLoadingUserRegistration || isLoadingCorporateRegistration}>
        <div className="registration__switch">
          <span>
            <FormattedMessage
              id={'authorization.individual'}
              values={{
                a: () => (<br></br>)
              }}
            />
          </span>

          <Switch
            onClick={toggleIndividual}
          />

          <span>
            <FormattedMessage
              id={'authorization.corporate'}
              values={{
                a: () => (<br></br>)
              }}
            />
          </span>
        </div>

        <div className="form-fields">
          {
            isIndividual
              ? <IndividualForm />
              : <CorporateForm />
          }
        </div>

        {!passwordValidations.isValid &&
          <PasswordRules passwordValidations={passwordValidations}/>
        }

        <div className="registration__offert">
          <input
            className="checkbox"
            type="checkbox"
            id="checkbox-id"
            name="option"
            checked={isOffertAgree}
            onClick={onClickOffertCheckbox}
          />

          <p>
            <FormattedMessage
              id={'authorization.acceptTerms'}
              values={{
                tag: (chunks) => (
                  <span onClick={toggleIsPdfOpen}>{chunks}</span>
                )
              }}
            />
          </p>
        </div>

        {!!registrationErrorMsg &&
          <span className={'sign-in__incorrect-msg'}>{registrationErrorMsg}</span>
        }

        <button
          onClick={handleSubmit}
          disabled={!isValidForm}
          className="btn"
        >
          <FormattedMessage id={'authorization.createAcc'}/>
        </button>
      </Loader>
    </AuthorizationLayout>
  )
}

export default Registration
