import Logo from "@/presentation/components/logo/Logo";
import UserForm from "@/presentation/components/mainPageService/components/UserForm";
import Loader from "@/presentation/components/loader/Loader";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import Chat from "@/presentation/components/chat/Chat";
import React, {useEffect, useState} from "react";
import cookiesManager from "@/internal/lib/services/cookiManager";
import {useNavigate, Route, Routes} from "react-router-dom";
import UserPage from "@/presentation/components/userPage/UserPage";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import {USERS_LIST_PATH,} from "@/presentation/components/userPage/const";
import Tabs from "@/presentation/components/mainPageService/components/Tabs";
import Account from "@/presentation/components/account/Account";
import UsersList from "@/presentation/components/mainPageService/components/UsersList";
import Loading from "@/presentation/components/loading/Loading";
import {selectedUserSelectors} from "@/internal/lib/storeModels/models/servicemen/selectedUserModel";
import UserSpace from "@/presentation/components/mainPageService/components/UserSpace";
import AsideForm from "@/presentation/components/mainPage/components/AsideForm";
import {managerAddressesSelectors, managerAnalyticsSelectors} from "@/internal/lib/storeModels/models/servicemen/stores";
import {servicemenEndpoints} from "@/data/api_entities/servicemen/endpoints";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import {mkdsStateSelectors} from "@/internal/lib/storeModels/models/mkds/mkdsState";


const MainPageService = () => {
  const navigate = useNavigate()
  const setSelectedAddress = addressesSelectors.selectedAddress.useSetValue()
  const getUserData = userDataSelectors.userData.useGetUserData()
  const userDataLoading = userDataSelectors.loadingUserData.useValue()
  const userData = userDataSelectors.userData.useValue()

  const [isFirstLoading, setIsFirstLoading] = useState(true)

  useEffect(() => {
    if (userData.id == null) {
      getUserData()
    }

    navigate('users_list')

    if (!!cookiesManager.get('X-pulse')) {
      cookiesManager.set('X-pulseAddress', '')
      setSelectedAddress(null)
    } else {
      navigate('/input', {
        replace: true
      })
    }
  }, [])

  useEffect(() => {
    if (isFirstLoading && !userDataLoading) {
      setIsFirstLoading(false)
    }
  }, [userDataLoading])

  return (
    <Loading loading={isFirstLoading}>
      <_Page />
    </Loading>
  )
}

const _Page = () => {
  const isLoading = !!addressesSelectors.lastGetAddressesRequest.useValue()
  const texts = [['userPage.usersList', `${USERS_LIST_PATH}`]]
  const selectedUser = selectedUserSelectors.useValue()
  const userTexts = [['Аналитика', `/service/${selectedUser?.id ?? 0}/analytics`], ['Объекты учета', `/service/${selectedUser?.id ?? 0}/objects`]]
  const [mode, setMode] = useState(0)
  const userData = userDataSelectors.userData.useValue()
  const navigate = useNavigate()
  const [updateServicemenInfo, toggleUpdate] = useToggle()

  const setAddresses = managerAddressesSelectors.useSetValue()
  const setAnalytics = managerAnalyticsSelectors.useSetValue()
  const [lastGetInfoRequest, setLastGetInfoRequest] = useState(null)

  const getInfo = () => {
    if (lastGetInfoRequest) {
      lastGetInfoRequest.abort()
    }

    const {promise, controller} = servicemenEndpoints.getUserManagerInfo(selectedUser.id)

    setLastGetInfoRequest(controller)

    promise
      .then(res => {
        setAnalytics(res?.analytics ?? [])
        setAddresses(res?.address ?? [])
        setLastGetInfoRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return
        setAnalytics([])
        setAddresses([])
        setLastGetInfoRequest(null)
      })
  }

  useEffect(() => {
    return () => {
      setAddresses(null)
      setAnalytics(null)
    }
  }, [])

  useEffect(() => {
    if (userData.service_manager === null) {
      return
    }

    if (userData.service_manager === true) {
      navigate('users_list')
    } else if (userData.service_manager == false) {
      navigate('/user', {
        replace: true
      })
    }
  }, [userData.service_manager])

  return (
    <div className={'main-page main-page_service'}>
      <Chat/>

      <div className="main-page__header">
        <div className={'main-page__logo-block'}>
          <Logo />
        </div>

        <Tabs
          tabs={texts}
          setMode={setMode}
        />

        {!!selectedUser &&
          <div className={'main-page__user-tabs'}>
            <span>{selectedUser?.user ? `Клиент: ${selectedUser.user}` : ''}</span>

            <Tabs
              tabs={userTexts}
              setMode={setMode}
            />
          </div>
        }

        <Account />
      </div>

      <Loader
        loading={isLoading && mode === 1}
        styles={{
          wrapper: (base) => ({
            ...base,
            display: 'flex',
            flex: '3 1',
            height: '100%',
            maxWidth: '100%',
            overflow: 'auto',
            paddingRight: isLoading
              ? '0.625rem'
              : ''
          })
        }}
      >
        <div className="main-page__content">
          <Routes>
            <Route
              path={`${USERS_LIST_PATH}`}
              element={<UsersList updateServicemenInfo={updateServicemenInfo}/>}
            />

            <Route
              path={`:id/*`}
              element={<UserSpace
                lastGetInfoRequest={lastGetInfoRequest}
                getInfo={getInfo}
              />}
            />

            <Route
              path={'user_page/*'}
              element={<UserPage isService={true}/>}
            />
          </Routes>
        </div>
      </Loader>
      <UserForm toggleUpdate={toggleUpdate}/>
      <AsideForm getInfo={getInfo}/>
    </div>
  )
}

export default MainPageService
