const CheckBox = ({label, isChecked = false, onChange, name}) => {
  return (
    <div
      className={'check-box'}
    >
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={isChecked}
        onChange={onChange}
      />

      <label htmlFor={name}>{label}</label>
    </div>
  )
}

export default CheckBox
