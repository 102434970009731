
import icons from '@/assets/svg/icons/icons.svg'
import {NavLink} from "react-router-dom"
import {BLOCK_PATHS_LIST, NAV_BAR_LIST} from '../const'
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import {devicesSelectors} from "@/internal/lib/storeModels/models/devices/devicesModel";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import {FormattedMessage} from "react-intl";
import Logo from "@/presentation/components/logo/Logo";
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import HomeOutlineIcon from '@mui/icons-material/HomeOutlined';
import {meteringPointsPageSelectors} from "@/internal/lib/storeModels/models/meteringPointsPage/meteringPointsModels";
import React from "react";
import Account from "@/presentation/components/account/Account";

const NavBar = () => {
  const isDevicesAvailable = devicesSelectors.devices.useDevicesAvailable()
  const isMeteringPointsAvailable = meteringPointsPageSelectors.pointsList.useIsPointsAvailable()
  const selectedAddress = addressesSelectors.selectedAddress.useValue()

  const handleClick = (e, path) => {
    if (BLOCK_PATHS_LIST.includes(path) &&
      (!isDevicesAvailable)) {
      e.preventDefault()
    }

    if (path === NAV_BAR_LIST[1].path && !isMeteringPointsAvailable) {
      e.preventDefault()
    }
  }


  return (
    <div className="nav-bar">
      <div className="nav-bar__top-content">
        <Logo />

        <h3 className={'title title_h3'}>{selectedAddress?.name ?? ''}</h3>

        <nav className="nav-bar__nav">
          {NAV_BAR_LIST.map((item) => {
            const isDisabled = (BLOCK_PATHS_LIST.includes(item.path) && (!isDevicesAvailable)) ||
              (item.path === NAV_BAR_LIST[1].path && !isMeteringPointsAvailable)
            let icon;

            switch (item.id) {
              case 1:
                icon = <HomeOutlineIcon />
                break;
              case 4:
                icon = <BathtubOutlinedIcon />
                break;
              case 7:
                icon = <CottageOutlinedIcon />
                break;
              default:
                icon = (
                  <svg>
                    <use href={`${icons}#${item.iconId}`}></use>
                  </svg>
                )
                break;
            }

            return (
              <NavLink
                onClick={(e) => handleClick(e, item.path)}
                to={item.path}
                replace={item.id === 1}
                key={item.id}
                className={`nav-bar__nav-item ${isDisabled ? 'nav-bar__nav-item_disabled' : ''}`}
              >
                {icon}

                <span>
                  <FormattedMessage id={`navBar.${item.textId}`}/>
                </span>
              </NavLink>
            )
          })}
        </nav>
      </div>

      <Account fromMain/>
    </div>
  )
}

export default NavBar
