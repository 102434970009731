import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'app'
const translations = {
  delete: 'Удалить',
  cancel: 'Отмена',
  'e-mail': 'E-mail',
  password: 'Пароль',
  save: 'Сохранить',
  proceed: 'Продолжить',
  sendAgain: 'Отправить еще раз',
  senAgainByTime: 'Отправлено. Повторить через 00:{time}',
  change: 'Изменить',
  sectionDeveloped: 'Раздел в разработке',
  ok: 'ОК',
  use: 'Использовать',
  noData: 'Нет данных',
  notSet: 'Не задан',
  confirm: 'Подтверждаю',
  back: 'Назад',
  notSpecified: 'Не указано',
  accept: 'Подтвердить',
  topUp: 'Пополнить',
  send: 'Отправить',
  addMail: 'Добавить почту',
  add: 'Добавить',
  nothingFound: 'Ничего не найдено',
  customize: 'Настроить',
  onPage: 'На странице',
  countOf: '{from}-{to} из {count}',
  moreThen: 'более чем {to}',
  mon: 'Пн',
  tue: 'Вт',
  wed: 'Ср',
  thu: 'Чт',
  fri: 'Пт',
  sat: 'Сб',
  sun: 'Вс',
  byHour: 'Часовые',
  byDay: 'Суточные',
  byMonth: 'Месячные',
  byGrand: 'Итоговые',
  notSave: 'Не сохранять',
  download: 'Скачать',
  create: 'Создать',
  addNew: '+ Добавить новый',
  jan: 'янв',
  feb: 'фев',
  mar: 'март',
  apr: 'апр',
  may: 'май',
  june: 'июнь',
  july: 'июль',
  aug: 'авг',
  sept: 'сен',
  oct: 'окт',
  nov: 'ноя',
  dec: 'дек',
  january: '{count, plural, one {Январь} other {Января}}',
  february: '{count, plural, one {Февраль} other {Февраля}}',
  march: '{count, plural, one {Март} other {Марта}}',
  april: '{count, plural, one {Апрель} other {Апреля}}',
  mayFull: '{count, plural, one {Май} other {Мая}}',
  juneFull: '{count, plural, one {Июнь} other {Июня}}',
  julyFull: '{count, plural, one {Июль} other {Июля}}',
  august: '{count, plural, one {Август} other {Августа}}',
  september: '{count, plural, one {Сентябрь} other {Сентября}}',
  october: '{count, plural, one {Октябрь} other {Октября}}',
  november: '{count, plural, one {Ноябрь} other {Ноября}}',
  december: '{count, plural, one {Декабрь} other {Декабря}}',
  prepareSystem: 'Подготавливаем систему к работе...',
  gcal: 'Гкал',
  gj: 'ГДж',
  kwh: 'кВт·ч',
  mCube: 'м<sup>3</sup>',
  mCubeGas: 'м<sup>3</sup> · газ пр.',
  firstPg: 'первую',
  lastPg: 'последнюю',
  nextPg: 'след.',
  prevPg: 'пред.',
  pageControl: 'Перейти на {type} страницу'
}

export const appRu = createTranslationsWithSpaceName(translations, spaceName)
