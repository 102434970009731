const transactionsHistory = [
    {
        id: 1,
        date: '10.12.2021',
        time: '10:20',
        event: {
            info: 'Выход на связь',
            device: 'МОСТиК FB-00006805'
        },
        sum: -0.6,
        reason: 'Вне расписания',
    },
    {
        id: 2,
        date: '09.12.2021',
        time: '09:20',
        event: {
            device: 'МОСТиК FB-00006805',
        },
        sum: -18,
        reason: 'Подписка'
    },
    {
        id: 3,
        date: '08.12.2021',
        time: '08:20',
        sum: 1000,
        reason: 'Перевод на счет',
    },
    {
        id: 4,
        date: '07.12.2021',
        time: '07:20',
        sum: 1000,
        reason: 'Platron',
    },
]

const tariffsListItems = [
    {
        id: 1,
        device: 'МОСТиК GSM',
        coast: 12,
    },
    {
        id: 2,
        device: 'МОСТиК GSM +sim',
        coast: 18,
    },
    {
        id: 3,
        device: 'ЭР-Д  РМД',
        coast: 18,
    },
    {
        id: 4,
        device: 'Х-Пульс РМД',
        coast: 6,
    },
    {
        id: 5,
        device: 'Х-Пульс NB-IoT +sim',
        coast: 30,
    },
    {
        id: 6,
        device: 'Х-Пульс NB-IoT',
        coast: 16,
    },
    {
        id: 7,
        device: 'Х-Пульс GSM +sim',
        coast: 24,
    },
    {
        id: 8,
        device: 'Х-Пульс GSM',
        coast: 16,
    },
    {
        id: 9,
        device: 'Индивид РМД',
        coast: 3,
    }
]

export {tariffsListItems, transactionsHistory}
