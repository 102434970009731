import React from 'react'
import {selectors} from './index'
import useStyles from "./message.style";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import {CloseNot} from "@/assets/svg";

export const Message = () => {
  const classes = useStyles();
  const messagesList = selectors.useMessagesList()
  const deleteMessage = selectors.useDeleteMessage()

  const MARGIN_BOTTOM = 12

  const onEnter = node => {
    node.style.marginTop = `-${node.offsetHeight + MARGIN_BOTTOM}px`;
    node.style.marginBottom = `${MARGIN_BOTTOM}px`;
  }

  const onEntering = node => {
    node.style.marginTop = "";
    node.style.marginBottom = `${MARGIN_BOTTOM}px`;
  }

  const onExit = node => {
    node.style.marginTop = ""
    node.style.marginBottom = `${MARGIN_BOTTOM}px`
  }

  const onExiting = node => {
    node.style.marginTop = `-${node.offsetHeight + MARGIN_BOTTOM}px`
    node.style.marginBottom = `${MARGIN_BOTTOM}px`
  }

  return (
    <div
      className={classes.messages}
    >
      <TransitionGroup
        component={null}
      >
        {messagesList.map((message, i) => {
          return (
            <CSSTransition
              key={message.id}
              timeout={500}
              classNames="message"
              onEnter={onEnter}
              onEntering={onEntering}
              onExit={onExit}
              onExiting={onExiting}
            >
              <div
                className={classes.message}
                style={{ backgroundColor: message.type }}
              >
                <div onClick={() => deleteMessage(message)}>
                  <CountdownCircleTimer
                    isPlaying
                    duration={message.count / 1000}
                    colors={['#000']}
                    trailColor={'transparent'}
                    children={() => <div style={{marginLeft: '1px'}}><img src={CloseNot} alt="close"/></div>}
                    size={24}
                    strokeWidth={1.5}
                    onComplete={() => deleteMessage(message)}
                  />
                </div>
                <h6>{message.header}</h6>
                <p>{message.message}</p>
              </div>
            </CSSTransition>
          )
        })}
      </TransitionGroup>
    </div>
  )
}
