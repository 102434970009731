import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {
  INIT_CALC_SETTINGS_DATA,
  INIT_CALC_SETTINGS_VALIDATIONS
} from "@/internal/lib/storeModels/models/mkds/calculations/const";
import {getCalculationSettingsValidators} from "@/internal/validators/formValidators";
import {emailValidator, requiredValidator} from "@/internal/validators/validationsRules";
import {EMAIL_VALIDATION_MSG, requiredMsg} from "@/internal/validators/validationsMessages";

const calculationSettingsFormState = new FormModelFabric({
  initialData: INIT_CALC_SETTINGS_DATA,
  initialValidations: INIT_CALC_SETTINGS_VALIDATIONS,
  getValidators: getCalculationSettingsValidators
})

const calculationNameFormState = new FormModelFabric({
  initialData: {name: ''},
  initialValidations: {name: []},
  getValidators: () => ({
    name: (value) => {
      return requiredValidator(value)
        ? []
        : [requiredMsg]
    },
  })
})

const sendCalcResultFormState = new FormModelFabric({
  initialData: {
    email_list: []
  },
  initialValidations: {
    email_list: []
  },
  getValidators: () => {
    return {
      email_list: (value) => {
        const filteredValues = (value ?? []).filter(item => !!item?.trim().length)
        let isValid = true
        if (!filteredValues.length) {
          return [requiredMsg]
        }
        filteredValues.forEach(item => {
          if (!emailValidator(item)) isValid = false
        })

        return isValid
          ? []
          : [EMAIL_VALIDATION_MSG]
      }
    }
  }
})

export const sendCalcResultFormSelectors = sendCalcResultFormState.createSelectors()

export const calculationSettingsFormSelectors = calculationSettingsFormState.createSelectors()
export const calculationNameFormStateSelectors = calculationNameFormState.createSelectors()
