import {getDeviceInfoByType} from "@/internal/helpers/helpers";

const Subscribes = ({addresses}) => {
  const mapAddressDevices = (address) => {
    const {devices} = address

    return (
      devices.map((device, i) => {
        const deviceInfo = getDeviceInfoByType(device.type)

        return (
          <div key={device.id} className='subscribes__item'>
            <div className="subscribes__info">
              <div className="subscribes__img">
                <img src={deviceInfo.img} alt={deviceInfo.name}/>
              </div>

              <div className="subscribes__name">
                <span>{deviceInfo.name}</span>

                <span className="sn">{device.serial}</span>
              </div>
            </div>

            <div className="subscribes__coast">
              <span>{deviceInfo.monthCoast} ₽</span>
            </div>
          </div>
        )
      })
    )
  }

  return (
    addresses.map(address => {
      const {devices} = address

      return ( devices && devices.length
          ? <div key={address.id} className='subscribes__subscribe'>
            <span className="subscribes__object">{address.name}</span>

            <div className="subscribes__list">
              {mapAddressDevices(address)}
            </div>
          </div>
          : null
      )
    })
  )
}

export default Subscribes
