import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'units'
const translations = {
  0: 'Не указано',
  1: 'м<sup>3</sup> · газ пр.',
  2: 'л',
  3: 'Гкал',
  4: 'кВт⋅ч'
}

export const unitsRu = createTranslationsWithSpaceName(translations, spaceName)
