import {Close} from "@/assets/svg";
import {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {devicesEndpoint} from "@/data/api_entities/devices/devices_endpoint";
import Loader from "@/presentation/components/loader/Loader";
import {getScoreboardRows} from "../helpers";
import {FormattedDate, FormattedMessage} from "react-intl";
import {responseHandler} from "@/internal/lib/transport/response_handler";

const DeviceScoreboard = ({toggleScoreboard}) => {
  const {id} = useParams()

  const [scoreboardData, setScoreboardData] = useState(null)
  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const [lastGetDataRequest, setLastGetDataRequest] = useState(null)

  const lastSyncDate = useMemo(() => {
    return scoreboardData
      ? <FormattedDate value={scoreboardData.datetime}/>
      : '-'
  }, [scoreboardData])

  const tableRows = useMemo(() => {
    return getScoreboardRows(scoreboardData).map((item, i) => {
      return (
        <div
          key={i}
          className="device-scoreboard__table-row"
        >
          <span>{item.name}</span>
          <span>{item.value}</span>
        </div>
      )
    })
  }, [scoreboardData])

  useEffect(() => {
    if (!id) return

    if (lastGetDataRequest) {
      lastGetDataRequest.abort()
    }

    const {promise, controller} = devicesEndpoint.getDeviceScoreboard(id)

    setLastGetDataRequest(controller)

    promise
      .then(res => {
        responseHandler(res, {
          ok: {
            callback: () => {
              setScoreboardData(res)
            }
          }
        })

        setLastGetDataRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return

        console.log(err)
        setLastGetDataRequest(null)
      })
      .finally(() => {
        if (isFirstLoading) setIsFirstLoading(false)
      })
  }, [id])

  const handleClick = (e) => {
    const {target} = e

    if (target && !target.closest('.device-scoreboard__substrate')) {
      toggleScoreboard()
    }
  }

  return (
    <div
      className="device-scoreboard"
      onClick={handleClick}
    >
      <div className="device-scoreboard__substrate">
        <Loader loading={isFirstLoading || !!lastGetDataRequest}>
          <div className="device-scoreboard__header">
            <div className="device-scoreboard__header-info">
              <h3 className="title_h3">
                <FormattedMessage id={'devicesPage.state'} />
              </h3>

              <div className="device-scoreboard__last-update">
                <span>
                   <FormattedMessage id={'devicesPage.lastUpdate'} />
                </span>

                <span>{lastSyncDate}</span>
              </div>
            </div>

            <div
              className="device-scoreboard__header-close"
              onClick={toggleScoreboard}
            >
              <img src={Close} alt="Close"/>
            </div>
          </div>

          <div className="device-scoreboard__info">
            {!!scoreboardData
              ? (
                <div className="device-scoreboard__table">
                  <div className="device-scoreboard__table-header">
                    <span>
                      <FormattedMessage id={'devicesPage.title'} />
                    </span>
                    <span>
                      <FormattedMessage id={'devicesPage.value'} />
                    </span>
                  </div>

                  {tableRows}
                </div>
              )
              : (
                <p className={'device-scoreboard__no-info'}>
                  <FormattedMessage id={'devicesPage.notBeenConnectedYet'} />
                </p>
              )
            }
          </div>
        </Loader>
      </div>
    </div>
  )
}

export default DeviceScoreboard
