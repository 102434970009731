import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'resourcesTypes'
const translations = {
  0: 'Nicht festgelegt',
  1: 'Kaltes wasser',
  2: 'Heißes wasser',
  3: 'Wärmeenergie',
  4: 'Elektrizität',
  5: 'Gas',
  6: 'Belüftung'
}

export const resourcesTypesDe = createTranslationsWithSpaceName(translations, spaceName)
