import {useEffect, useMemo, useRef, useState} from "react";
import {RESULT_TABS_LIST} from "../const";
import Tabs from "@/presentation/components/common/tabs/Tabs";
import {currencyFormatter} from "@/internal/formatters/formatters";
import {
  analyticsSelectors,
} from "@/internal/lib/storeModels/models/analytics/analyticsModels";
import {analyticsEndpoint} from "@/data/api_entities/analytics/analytics_endpoint";
import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import {selectors} from "@/presentation/shared/ui/message";
import {periodFormatter} from "../helpers";
import SendResultForm from "./SendResultForm";
import ResultTableGenerator from "./ResultTableGenerator";
import {
  getEconomicAnalysisTableParams,
  getEconomicEfficiencyTableParams,
  getIntakeEfficiencyTableParams, getPivotTableParams, getReturnInvestmentsTableParams
} from "./const";
import {balanceEndpoint} from "@/data/api_entities/balance/balance_endpoint";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import {sendResultSelectors} from "@/internal/lib/storeModels/models/analytics/forms/sendResultModel";
import {analyticsListSelectors} from "@/internal/lib/storeModels/models/analytics/analyticsListModel";
import GetXLSButton from "@/presentation/components/analytics/сomponents/result/GetXLSButton";
import BestValues from "@/presentation/components/analytics/сomponents/result/BestValues";
import {FormattedMessage} from "react-intl";
import Loader from "@/presentation/components/loader/Loader";

const ResultTables = (
  {
    tablesConfig,
    selectedAddresses = [],
    resultData = null,
    columnWidth,
    startDate,
    endDate,
    isSaveAvailable = true,
    cost,
    unusedMonthsCountBeforeStart,
    isOnlyInvest = false,
    isLoading = false,
    getInfo = null
  }) => {
  const resultTablesRef = useRef()

  const analyticId = analyticsSelectors.analyticId.useValue()
  const [isPaid, setIsPaidAnalytic] = analyticsSelectors.isPaidAnalytic.useState()
  const isValidMailsForm = sendResultSelectors.useIsFormValid()
  const onClickSubmit = sendResultSelectors.useOnClickSubmit()
  const updateAnalyticsList = analyticsListSelectors.list.useUpdateAnalyticsList()
  const setMessage = selectors.useSetMessage()

  const [activeTab, setActiveTab] = useState(RESULT_TABS_LIST[isOnlyInvest ? 3 : 0].name)
  const [isOpenSaveModal, setIsOpenSaveModal] = useState(false)
  const [isPaidLoading, setIsPaidLoading] = useState(false)
  const [isSufficient, setIsSufficient] = useState(true)
  const [defaultSum, setDefaultSum] = useState(0)
  const [currentPeriodTable, setCurrentPeriodTable] = useState(1)
  const [isOpenSendResultModal, setIsOpenSendResultModal] = useState(false)
  const [isSendingResults, setIsSendingResults] = useState(false)

  const updateUserData = userDataSelectors.userData.useUpdateUserData()
  const userData = userDataSelectors.userData.useValue()

  const toggleSaveModal = () => setIsOpenSaveModal(!isOpenSaveModal)
  const toggleSendResultModal = () => setIsOpenSendResultModal(!isOpenSendResultModal)

  useEffect(() => {
    if (!resultTablesRef.current) return

    resultTablesRef.current.scrollTo(0, 0)
  }, [currentPeriodTable])

  const sortedAddressesByAverageIntake = useMemo(() => {
    if (!resultData) return []

    return selectedAddresses.sort((a, b) => {
      if (resultData.hd_middle[a.id] === null && resultData.hd_middle[b.id] === null) return 1
      if (resultData.hd_middle[a.id] === null) return 1
      if (resultData.hd_middle[b.id] === null) return -1

      return resultData.hd_middle[a.id] - resultData.hd_middle[b.id]
    })
  }, [selectedAddresses, resultData])

  const saveModalBody = useMemo(() => {
    return isSufficient
      ? !!cost
        ? (
          <FormattedMessage
            id={'analytics.acceptBuyReport'}
            values={{
              cost: currencyFormatter(cost)
            }}
          />
        )
        : <FormattedMessage id={'analytics.freeReport'}/>
      : (
        <>
          <FormattedMessage
            id={'analytics.reportCost'}
            values={{
              cost: currencyFormatter(cost)
            }}
          />
          <br/>
          <br/>
          {
            !!userData.service_manager
              ? `Недостаточно средств для сохранения отчета, попросите клиента пополните баланс на ${currencyFormatter(defaultSum)}`
              : <FormattedMessage
                id={'analytics.noCash'}
                values={{
                  sum: currencyFormatter(defaultSum)
                }}
              />
          }
        </>
      )
  }, [cost, isSufficient, defaultSum])

  const saveModalAgreeBtn = useMemo(() => {
    return isSufficient
      ? !!cost
        ? {
          className: 'btn',
          text: <FormattedMessage id={'app.accept'}/>
        }
        : {
          className: 'btn',
          text: <FormattedMessage id={'app.save'}/>
        }
      : userData.service_manager
        ? null
        : {
        className: 'btn',
        text:  <FormattedMessage id={'app.topUp'}/>
      }
  }, [isSufficient, cost])

  const periodTabs = useMemo(() => {
    return tablesConfig.map((tableConfig) => {
      return (
        <h3
          key={tableConfig.id}
          className={`title ${currentPeriodTable === tableConfig.id ? 'active' : ''}`}
          onClick={() => setCurrentPeriodTable(tableConfig.id)}
        >
          {periodFormatter(tableConfig.startPeriod, tableConfig.endPeriod)}
        </h3>
      )
    })
  }, [tablesConfig, currentPeriodTable])

  const tabsList = RESULT_TABS_LIST.map(tab => {
    if (tab.name === RESULT_TABS_LIST[3].name) return

    return (
      <div
        key={tab.name}
        onClick={() => setActiveTab(tab.name)}
        className={`tables__result-tab-item ${activeTab === tab.name ? 'active' : ''}`}
      >
        <span>{tab.title}</span>
      </div>
    )
  })

  const handleTopUpBalance = () => {
    if (userData.service_manager) return

    balanceEndpoint.setPayment(defaultSum).promise.then((result) => {
      window.open(result)
      toggleSaveModal()
      setIsSufficient(true)
    })
  }

  const onSaveAnalytic = () => {
    const id = analyticId

    if (!id) return

    setIsPaidLoading(true)

    const {promise} = analyticsEndpoint.saveAnalyticResult(id)

    promise
      .then((res) => {
        if (res.user_balance !== undefined) {
          setIsSufficient(false)
          setDefaultSum(res.user_balance)
        } else {
          setIsPaidAnalytic(true)
          updateUserData();
          (getInfo ?? updateAnalyticsList)()

          if (isOpenSaveModal) toggleSaveModal()
          setMessage({
            header: <FormattedMessage id={'requests.saveAnalyticSuccessHeader'}/>,
            message: <FormattedMessage id={'requests.saveAnalyticSuccess'}/>,
            count: 3000
          })
        }
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.saveAnalyticError'}/>,
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsPaidLoading(false))
  }

  const handleAgreeSaveModal = () => {
    if (isSufficient) {
      onSaveAnalytic()
    } else {
      handleTopUpBalance()
    }
  }

  const handleSendMails = () => {
    if (!isValidMailsForm) return

    onClickSubmit()
  }

  const onValidSendMails = (data) => {
    setIsSendingResults(true)

    const {promise} = analyticsEndpoint.sendResultMails({
      ...data,
      analytics_id: analyticId
    })

    promise
      .then((res) => {
        if (res.status === 'error') {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: <FormattedMessage id={'requests.sendReportError'}/>,
            type: 'error',
            count: 3000
          })
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.sendReportSuccessHeader'}/>,
            message: <FormattedMessage id={'requests.sendReportSuccess'}/>,
            count: 3000
          })
          toggleSendResultModal()
        }
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.sendReportError'}/>,
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
      .finally(() => setIsSendingResults(false))
  }

  const economicAnalysisTableConfig = useMemo(() => {
    return getEconomicAnalysisTableParams(tablesConfig, currentPeriodTable, sortedAddressesByAverageIntake, columnWidth, resultData, unusedMonthsCountBeforeStart)
  }, [tablesConfig, currentPeriodTable, sortedAddressesByAverageIntake, columnWidth, resultData, unusedMonthsCountBeforeStart])

  const intakeEfficiencyTableConfig = useMemo(() => {
    return getIntakeEfficiencyTableParams(tablesConfig, currentPeriodTable, sortedAddressesByAverageIntake, columnWidth, resultData, unusedMonthsCountBeforeStart)
  }, [tablesConfig, currentPeriodTable, sortedAddressesByAverageIntake, columnWidth, resultData, unusedMonthsCountBeforeStart])

  const economicEfficiencyTableConfig = useMemo(() => {
    return getEconomicEfficiencyTableParams(tablesConfig, currentPeriodTable, sortedAddressesByAverageIntake, columnWidth, resultData, unusedMonthsCountBeforeStart)
  }, [tablesConfig, currentPeriodTable, sortedAddressesByAverageIntake, columnWidth, resultData, unusedMonthsCountBeforeStart])

  const returnInvestmentsTableConfig = useMemo(() => {
    return getReturnInvestmentsTableParams(tablesConfig, currentPeriodTable, sortedAddressesByAverageIntake, columnWidth, resultData, unusedMonthsCountBeforeStart)
  }, [tablesConfig, currentPeriodTable, sortedAddressesByAverageIntake, columnWidth, resultData, unusedMonthsCountBeforeStart])

  const pivotTableConfig = useMemo(() => {
    return getPivotTableParams(columnWidth, resultData, sortedAddressesByAverageIntake)
  }, [columnWidth, resultData, sortedAddressesByAverageIntake])

  return (
    <>
      <Loader
        loading={isLoading}
        styles={{
          wrapper: (base) => ({
            ...base,
            width: '100%',
            maxWidth: '100%',
            overflow: 'auto',
            flex: 1,
            marginBottom: '1.25rem'
          })
        }}
      >
        {!isOnlyInvest &&
          <div className="tables__result-tabs">
            {tabsList}
          </div>

        }

        {activeTab !== RESULT_TABS_LIST[4].name &&
          <div
            className="tables__period-tabs"
            style={{width: '100%'}}
          >
            {periodTabs}
          </div>
        }

        {activeTab === RESULT_TABS_LIST[0].name &&
          <div className="tables__marks">
            <div className={'tables__mark'}>
              <div className='tables__mark-color tables__mark-color_green'/>

              <span>
              <FormattedMessage id={'analytics.bestValueInObject'}/>
            </span>
            </div>

            <div className={'tables__mark'}>
              <div className='tables__mark-color tables__mark-color_blue'/>

              <span>
              <FormattedMessage id={'analytics.bestValueInObjects'}/>
            </span>
            </div>

            <div className={'tables__mark'}>
              <div className='tables__mark-color tables__mark-color_orange'/>

              <span>
              <FormattedMessage id={'analytics.aboveAverageValue'}/>
            </span>
            </div>

            <div className={'tables__mark'}>
              <div className='tables__mark-color tables__mark-color_red-one'/>

              <span>
              <FormattedMessage id={'analytics.worseValueInObject'}/>
            </span>
            </div>

            <div className={'tables__mark'}>
              <div className='tables__mark-color tables__mark-color_red'/>

              <span>
              <FormattedMessage id={'analytics.worseValueInObjects'}/>
            </span>
            </div>

            <div className={'tables__mark'}>
              <div className='tables__mark-color tables__mark-color_white'/>

              <span>
              <FormattedMessage id={'analytics.belowAverageValue'}/>
            </span>
            </div>
          </div>
        }

        <Tabs activeTab={activeTab}>
          <ResultTableGenerator
            name={RESULT_TABS_LIST[0].name}
            tableConfig={intakeEfficiencyTableConfig}
          />

          <ResultTableGenerator
            name={RESULT_TABS_LIST[1].name}
            tableConfig={economicAnalysisTableConfig}
          />

          <ResultTableGenerator
            name={RESULT_TABS_LIST[2].name}
            tableConfig={economicEfficiencyTableConfig}
          />

          <ResultTableGenerator
            name={RESULT_TABS_LIST[3].name}
            tableConfig={returnInvestmentsTableConfig}
          />

          <ResultTableGenerator
            name={RESULT_TABS_LIST[4].name}
            tableConfig={pivotTableConfig}
            isPivot
            endDate={endDate}
            startDate={startDate}
          />
        </Tabs>

        <DefaultModal
          isOpen={isOpenSaveModal}
          toggleModal={toggleSaveModal}
          headerTitle={<FormattedMessage id={'analytics.savingReport'}/>}
          bodyText={saveModalBody}
          agreeBtnConfig={saveModalAgreeBtn}
          disagreeBtnConfig={{
            className: 'btn btn_transparent-bg',
            text: <FormattedMessage id={'app.cancel'}/>
          }}
          onDisagree={toggleSaveModal}
          onAgree={handleAgreeSaveModal}
          loading={isPaidLoading}
        />

        <DefaultModal
          isOpen={isOpenSendResultModal}
          toggleModal={toggleSendResultModal}
          headerTitle={<FormattedMessage id={'analytics.sendingReport'}/>}
          bodyText={<SendResultForm
            onValidSubmit={onValidSendMails}
            formSelectors={sendResultSelectors}
            name={'mails'}
          />}
          agreeBtnConfig={{
            className: 'btn',
            text: <FormattedMessage id={'app.send'}/>
          }}
          onDisagree={toggleSendResultModal}
          onAgree={handleSendMails}
          loading={isSendingResults}
        />
      </Loader>
      <div className={`tables__btn-wrapper`}>
        <BestValues
          resultData={resultData}
        />

        {!isSaveAvailable || isPaid
          ? (
            <div className={'tables__send-report'}>
                <span onClick={toggleSendResultModal}>
                  <FormattedMessage id={'app.send'}/>
                </span>

              <GetXLSButton getRequest={() => analyticsEndpoint.getResultXLS(analyticId)}/>
            </div>
          )
          : (
            <button
              onClick={toggleSaveModal}
              className="btn"
            >
              <FormattedMessage id={'analytics.saveReport'}/>
            </button>
          )
        }
      </div>
    </>
  )
}

export default ResultTables
