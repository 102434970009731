import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";
import {USER_REGISTRATION_INIT_DATA} from "./const";

const incorrectMsgModel = new StoreModelFabric('')
const isLoadingCorporateRegistrationModel = new StoreModelFabric(false)
const incorrectRecoveryPasswordMsgModel = new StoreModelFabric('')
const isRecoveryPasswordLoadingModel = new StoreModelFabric(false)
const recoveryEmailModel = new StoreModelFabric('')
const recoveryTokenModel = new StoreModelFabric('')
const incorrectSignInMsgModel = new StoreModelFabric('')
const loadingSignInModel = new StoreModelFabric(false)
const isLoadingUserRegistrationModel = new StoreModelFabric(false)
const registrationDataModel = new StoreModelFabric(USER_REGISTRATION_INIT_DATA)
const registrationErrorMsgModel = new StoreModelFabric('')

export const authorizationSelectors = {
  incorrectMsg: incorrectMsgModel.createSelectors(),
  isLoadingCorporateRegistration: isLoadingCorporateRegistrationModel.createSelectors(),
  incorrectRecoveryPasswordMsg: incorrectRecoveryPasswordMsgModel.createSelectors(),
  isRecoveryPasswordLoading: isRecoveryPasswordLoadingModel.createSelectors(),
  recoveryEmail: recoveryEmailModel.createSelectors(),
  recoveryToken: recoveryTokenModel.createSelectors(),
  incorrectSignInMsg: incorrectSignInMsgModel.createSelectors(),
  loadingSignIn: loadingSignInModel.createSelectors(),
  isLoadingUserRegistration: isLoadingUserRegistrationModel.createSelectors(),
  registrationData: registrationDataModel.createSelectors(),
  registrationErrorMsg: registrationErrorMsgModel.createSelectors()
}
