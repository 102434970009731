import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'objectTypes'
const translations = {
  0: 'Die buchhaltungsobjektart ist nicht gesetzt',
  1: 'Eben',
  2: 'Hütte (privathaus)',
  3: 'MFH (mehrfamilienhaus)',
  4: 'MFH-raum (eingebaut)',
  5: 'Офисное помещение',
  6: 'Bürozentrum (gebäude)',
  7: 'Schulgebäude',
  8: 'Krankenhaus (gebäude)',
  9: 'Verwaltungsgebäude',
  10: 'Sportgebäude',
  11: 'Gebäude herstellen',
  12: 'Lagergebäude',
  13: 'Geschäft (gebäude)',
  14: 'Andere gebäude und räumlichkeiten'
}

export const objectTypesDe = createTranslationsWithSpaceName(translations, spaceName)
