export const MULTI_OBJECTS_SELECT = {
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
    width: '100%',
    background: '#fff',
    boxShadow: '0 .5rem 1rem rgba(53, 96, 181, 0.2)',
    borderRadius: '.375rem',
    overflowX: 'hidden',
    margin: '0.25rem 0 0'
  }),
  option: (provided, state) => ({
    display: 'flex',
    flexDirection: 'column',
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    padding: '.75rem 1rem',
    borderTop: '1px solid #ECF0F6',
    cursor: 'pointer',
    background: state.isSelected ? '#E6EFFE' : '',
    ':hover': {
      background: '#E6EFFE'
    },
    ':last-child': {
      borderBottom: 'none'
    },
    'span': {
      ':nth-of-type(1)': {
        fontSize: '.875rem',
        lineHeight: '143%',
        fontWeight: '500',
        color: '#36455D',
      },
      ':last-child': {
        fontSize: '.875rem',
        lineHeight: '143%',
        color: '#727A86',
        marginTop: '-0.25rem'
      }
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#727A86',
    margin: 0,
    padding: '0.5rem 0 0.5rem 1.0625rem',
  }),
  multiValue: (provided) => ({
    ...provided,
    maxWidth: '100%',
    'div': {
      padding: 0,
      display: 'flex',
      'span': {
        padding: '.1875rem',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }
    }
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0.625rem 0.5625rem',
    'div': {
      maxWidth: '100%'
    }
  }),
  input: (provided, state) => {
    return ({
      ...provided,
      margin: 0,
      padding: state.hasValue ? '0.625rem .125rem 0.125rem' : '0.125rem',
      fontSize: '0.875rem',
      lineHeight: '0 !important',
      color: '#36455D',
      width: '100%'
    })
  }
}

export const OBJECTS_SELECT_STYLES = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? 0 : 0,
    border: '1px solid #ECF0F6',
    borderRadius: '0.375rem',
    ':hover': {
      border: '1px solid #ECF0F6'
    },
    minHeight: 'unset',
    cursor: 'pointer',
    backgroundColor: state.isDisabled ? 'rgba(239, 239, 239, 0.3)' : 'transparent',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: state.isMulti ? '' : 0,
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: '0.625rem 0.5625rem',
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    margin: 0,
    padding: '0.625rem 0.5625rem',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    margin: 0,
    padding: '0.625rem 0.5625rem'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: (provided, state) => ({
    padding: '0 .75rem',
    transition: 'transform .1s ease',
    transform: `rotate(${state.selectProps.menuIsOpen ? '180deg' : '0'})`
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
    width: '100%',
    background: '#fff',
    boxShadow: '0 .5rem 1rem rgba(53, 96, 181, 0.2)',
    borderRadius: '.375rem',
    margin: '0.25rem 0 0',
    overflow: 'hidden'
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0'
  }),
  option: () => ({
    display: 'flex',
    flexDirection: 'column',
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    padding: '.75rem 1rem',
    borderBottom: '1px solid #ECF0F6',
    cursor: 'pointer',
    ':hover': {
      background: '#E6EFFE'
    },
    ':last-child': {
      borderBottom: 'none'
    },
    'span': {
      ':nth-of-type(1)': {
        fontSize: '.875rem',
        lineHeight: '143%',
        fontWeight: '500',
        color: '#36455D',
      },
      ':last-child': {
        fontSize: '.875rem',
        lineHeight: '143%',
        color: '#727A86',
        marginTop: '-0.25rem'
      }
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#BBC4D2',
  })
}

export const RIGHT_SELECT_WITH_INPUT = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? 0 : 0,
    border: '1px solid #ECF0F6',
    borderRadius: '0.375rem',
    ':hover': {
      border: '1px solid #ECF0F6'
    },
    minHeight: 'unset',
    cursor: 'pointer',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    maxHeight: '2.6175rem'
  })
}

export const RIGHT_SELECT_WITH_INPUT_ERROR = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? 0 : 0,
    border: '1px solid #FC4F6E',
    borderRadius: '0.375rem',
    ':hover': {
      border: '1px solid #FC4F6E'
    },
    minHeight: 'unset',
    cursor: 'pointer',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    maxHeight: '2.6175rem'
  })
}
