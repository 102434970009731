import ValidationsWrapper from "@/presentation/components/common/formComponents/ValidationsWrapper";
import {CalendarIcon, CustomizedDatePicker} from "@/presentation/components/common/formComponents/const";
import React, {useState} from "react";
import {TextField} from "@mui/material";
import dayjs from "dayjs";

const DatePickerInput = (
  {
    label,
    formSelectors,
    name,
    minDate = '',
    maxDate = '',
    views,
    disabled = false,
    valueFormatter,
    openTo,
    inputFormat = 'DD.MM.YYYY'
  }) => {
  const value = formSelectors.useFormDataValue(name)
  const setFieldData = formSelectors.useSetFormDataValue(name)
  const validations = formSelectors.useFormValueValidation(name)

  const [open, setOpen] = useState(false)

  const onChange = (e) => {
    if (disabled) return

    setFieldData(valueFormatter(e.$d))
  }

  const onClickInput = () => {
    if (disabled) return

    setOpen(true)
  }

  return (
    <div className="form-group">
      {label &&
        <label>{label}</label>
      }

      <ValidationsWrapper validationMsgs={validations}>
        <CustomizedDatePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          components={{
            OpenPickerIcon: CalendarIcon
          }}
          inputFormat={inputFormat}
          minDate={dayjs(minDate)}
          maxDate={dayjs(maxDate)}
          value={value}
          views={views}
          onChange={onChange}
          sx={{
            width: '100%'
          }}
          openTo={openTo}
          disabled={disabled}
          renderInput={(params) => {
            return (
              <TextField data-cy={'cyDatePicker'}
                {...params}
                error={!!validations.length}
                inputProps={{
                  ...params.inputProps,
                  placeholder: '',
                  readOnly: true
                }}
                onClick={onClickInput}
              />
            )
          }}
        />
      </ValidationsWrapper>
    </div>
  )
}

export default DatePickerInput
