import {useEffect, useState} from "react";
import {userEndpoint} from "@/data/api_entities/user/user_endpoint";
import Loader from "@/presentation/components/loader/Loader";
import {phoneFormatter} from "@/internal/formatters/formatters";
import {cloneDeep} from "lodash";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {CHANGE_ENTITY_PATH} from "../../const";
import {selectors} from "@/presentation/shared/ui/message";
import Input from "@/presentation/components/common/formComponents/Input";
import {userEntityFormSelectors} from "@/internal/lib/storeModels/models/user/forms/userEntityFormModel";
import MaskInput from "@/presentation/components/common/formComponents/MaskInput";
import {FormattedMessage} from "react-intl";

const ChangeEntity = () => {
  const [lastCompanyDataRequest, setLastCompanyDataRequest] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const isValidForm = userEntityFormSelectors.useIsFormValid()
  const isActualIsLegal = userEntityFormSelectors.useIsActualIsLegal()
  const toggleIsActualIsLegal = userEntityFormSelectors.useToggleIsActualIsLegal()
  const setOnValidSubmit = userEntityFormSelectors.useSetOnValidSubmit()
  const setInitData = userEntityFormSelectors.useSetInitFormData()
  const onClickSubmit = userEntityFormSelectors.useOnClickSubmit()
  const resetForm = userEntityFormSelectors.useResetForm()
  const setMessage = selectors.useSetMessage()

  useEffect(() => {
    setOnValidSubmit(onValidSubmit)
    setIsLoading(true)

    if (lastCompanyDataRequest) {
      lastCompanyDataRequest.abort()
    }

    const {promise, controller} = userEndpoint.getUserCompanyInfo()

    setLastCompanyDataRequest(controller)

    promise
      .then(res => {
        const initData = cloneDeep(res)
        delete initData.id

        setInitData(initData)
        setLastCompanyDataRequest(null)
      })
      .catch((err) => {
        if (err.name === 'AbortError') return

        setLastCompanyDataRequest(null)
        console.log(err)
      })
      .finally(() => setIsLoading(false))

    return () => {
      resetForm()
    }
  }, [])

  const onValidSubmit = (data) => {
    userEndpoint.setUserCompanyInfo(data)
      .then(() => {
        setMessage({
          header: <FormattedMessage id={'requests.changeDetailsSuccessHeader'}/>,
          message: <FormattedMessage id={'requests.changeDetailsSuccess'}/>,
          count: 3000
        })
      })
      .catch(err => {
        console.log(err)

        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.changeDetailsError'}/>,
          count: 3000,
          type: 'error'
        })
      })
  }

  const handleSubmit = () => {
    if (!isValidForm) return

    onClickSubmit()
  }

  return (
    <div className='user-page__column user-page__column_entity'>
      <ArrowBack
        pathOnVisible={CHANGE_ENTITY_PATH}
      />

      <h2 className="title">
        <FormattedMessage id={'userPage.requisites'}/>
      </h2>

      <Loader loading={isLoading || !!lastCompanyDataRequest}>
        <div className="form-fields">
          <Input
            label={<FormattedMessage id={'userPage.inn'}/>}
            type='number'
            name={'inn'}
            formSelectors={userEntityFormSelectors}
          />

          <Input
            label={<FormattedMessage id={'userPage.legalEntityName'}/>}
            name={'name'}
            formSelectors={userEntityFormSelectors}
          />

          <Input
            label={<FormattedMessage id={'userPage.legalAddress'}/>}
            name={'address'}
            formSelectors={userEntityFormSelectors}
          />

          <Input
            label={<FormattedMessage id={'userPage.actualAddress'}/>}
            name={'actual_address'}
            formSelectors={userEntityFormSelectors}
            disabled={isActualIsLegal}
          />

          <div className="user-page__address-match">
            <input
              className="checkbox"
              type="checkbox"
              id="checkbox-id"
              checked={isActualIsLegal}
              onChange={toggleIsActualIsLegal}
            />

            <p onClick={toggleIsActualIsLegal}>
              <FormattedMessage id={'userPage.ifActualIsLegal'}/>
            </p>
          </div>

          <MaskInput
            label={<FormattedMessage id={'userPage.contactCommunication'}/>}
            name='contact'
            mask="+7 (999) 999-99-99"
            type="tel"
            valueFormatter={phoneFormatter}
            formSelectors={userEntityFormSelectors}
          />
        </div>

        <button
          className="btn btn_transparent-bg"
          onClick={handleSubmit}
          disabled={!isValidForm}
        >
          <FormattedMessage id={'app.change'}/>
        </button>
      </Loader>
    </div>
  )
}

export default ChangeEntity
