import Constants from "@/presentation/components/meteringPointsPage/constants";
import {NavLink, Routes, Route} from "react-router-dom";
import MeteringPointsList from "@/presentation/components/meteringPointsPage/components/MeteringPointsList";
import MeteringPointForm from "@/presentation/components/meteringPointForm/MeteringPointForm";
import {
  changeMeteringPointFormSelectors,
  meteringPointFormSelectors
} from "@/internal/lib/storeModels/models/user/forms/meteringPointFormModel";
import {useNavigateBack} from "@/internal/custom_hooks/navigateBack";
import {meteringPointsPageSelectors} from "@/internal/lib/storeModels/models/meteringPointsPage/meteringPointsModels";
import {meterointsEndpoint} from "@/data/api_entities/meteroints/meteroints_endpoint";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import {FormattedMessage} from "react-intl";
import {selectors} from "@/presentation/shared/ui/message";
import {useState} from "react";
import {useToggle} from "@/internal/custom_hooks/useToggle";

const MeteringPointsPage = () => {
  const [navigateBack] = useNavigateBack()

  const updatePointsList = meteringPointsPageSelectors.pointsList.useUpdatePointsList()
  const selectedAddress = addressesSelectors.selectedAddress.useValue()
  const setMessage = selectors.useSetMessage()
  const resetForm = meteringPointFormSelectors.useResetForm()

  const [isCreatingPoint, setIsCreatingPoint] = useState(false)
  const [isDeletingPoint, setIsDeletingPoint] = useState(false)
  const [isChangingPoint, setIsChangingPoint] = useState(false)
  const [isOpenDeleteModal, toggleDeleteModal] = useToggle()

  const handleCreateMeteringPoint = (data) => {
    const selectedAddressId = selectedAddress.id

    if (!selectedAddressId) {
      throw Error('selected address is undefined')
    } else {
      setIsCreatingPoint(true)

      meterointsEndpoint.createMeteroint({address: selectedAddressId, ...data})
        .then((res) => {
          if (res.status === 'error') {
            setMessage({
              header: <FormattedMessage id={'requests.error'}/>,
              message: <FormattedMessage id={'requests.addMeteringPointError'}/>,
              type: 'error',
              count: 3000
            })
          } else {
            navigateBack()
            updatePointsList()
            resetForm()

            setMessage({
              header: <FormattedMessage id={'requests.addMeteringPointSuccessHeader'}/>,
              message: <FormattedMessage id={'requests.addMeteringPointSuccess'}/>,
              count: 3000
            })
          }
        })
        .catch((err => {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: <FormattedMessage id={'requests.addMeteringPointError'}/>,
            type: 'error',
            count: 3000
          })
          console.log(err)
        }))
        .finally(() => setIsCreatingPoint(false))
    }
  }

  const handleDeleteMeteringPoint = (id) => {
    setIsDeletingPoint(true)

    meterointsEndpoint.deleteMeteroint(id)
      .then(() => {
        toggleDeleteModal()
        navigateBack()
        updatePointsList()

        setMessage({
          header: <FormattedMessage id={'requests.removeMeteringPointSuccessHeader'}/>,
          message: <FormattedMessage id={'requests.removeMeteringPointSuccess'}/>,
          count: 3000
        })
      })
      .catch(err => {
        console.log(err)

        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.removeMeteringPointError'}/>,
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsDeletingPoint(false))
  }

  const handleChangeMeteringPoint= (data) => {
    setIsChangingPoint(true)

    meterointsEndpoint.changeMeteroint(data)
      .then(() => {
        navigateBack()
        updatePointsList()

        setMessage({
          header: <FormattedMessage id={'requests.changingMeteringPointSuccessHeader'}/>,
          message: <FormattedMessage id={'requests.changingMeteringPointSuccess'}/>,
          count: 3000
        })
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.changingMeteringPointError'} values={{name: data.name}}/>,
          type: 'error',
          count: 3000
        })

        console.log(err)
      })
      .finally(() => setIsChangingPoint(false))
  }

  return (
    <div className={'metering-points-page'}>
      <div className="metering-points-page__column">
        <div className="metering-points-page__title-wrapper">
          <h2 className="title">
            <FormattedMessage id={'navBar.meteringPoints'}/>
          </h2>

          <NavLink
            className={'btn btn_transparent-bg'}
            to={Constants.newPointPath}
          >
            +
          </NavLink>
        </div>

        <MeteringPointsList />
      </div>

      <Routes>
        <Route
          path={Constants.newPointPath}
          element={
            <MeteringPointForm
              isNew
              loading={isCreatingPoint}
              customClass='metering-points-page__column'
              formModelSelectors={meteringPointFormSelectors}
              onValidSubmit={handleCreateMeteringPoint}
            />
          }
        />

        <Route
          path={'/:idP'}
          element={
            <MeteringPointForm
              customClass='metering-points-page__column'
              formModelSelectors={changeMeteringPointFormSelectors}
              loading={isDeletingPoint || isChangingPoint}
              onValidSubmit={handleChangeMeteringPoint}
              handleDelete={handleDeleteMeteringPoint}
              isOpenDeleteModal={isOpenDeleteModal}
              toggleDeleteModal={toggleDeleteModal}
            />
          }
        />
      </Routes>
    </div>
  )
}

export default MeteringPointsPage
