import {TableCell} from "@mui/material";
import {EMPTY_COLUMN} from "../const";
import {analyticsSelectors} from "@/internal/lib/storeModels/models/analytics/analyticsModels";
import {ACTIVE_COLOR, DISABLED_COLOR} from "@/presentation/components/analytics/const";

const TemperatureTableCell = (
  {
    activeCell,
    valueKey,
    modelInfo,
    id,
    setRandomData,
    onFocusInput,
    columnWidth,
  }) => {
  const addressId = modelInfo.selectors.useAddressId()
  const durationValue = valueKey === EMPTY_COLUMN
    ? []
    : modelInfo.selectors.useCurrentDurationValue(valueKey)
  const isDisabled = valueKey === EMPTY_COLUMN || !durationValue.length
  const saveChanges = modelInfo.selectors.useSaveChanges()
  const formValue = isDisabled
    ? ''
    : modelInfo.selectors.useCurrentTempValue(valueKey)
  const setValue = isDisabled
    ? () => {}
    : modelInfo.selectors.useSetCurrentTempValue()
  const tempFromDB = analyticsSelectors.temperatureFromDB.useValue()
  const backgroundColor = activeCell === id
    ? ACTIVE_COLOR
    : isDisabled
      ? DISABLED_COLOR
      : tempFromDB[addressId] && tempFromDB[addressId][valueKey] !== formValue
        ? '#eae598'
        : 'transparent'

  const handleClickCell = (e) => {
    const target = e.target.closest('.MuiTableCell-root')
    target.children[0].focus()
  }

  const onChange = (e) => {
    if (isDisabled) return

    const {value} = e.target

    setValue({
      key: valueKey,
      value
    })
  }

  const onBlur = () => {
    saveChanges()
  }

  return (
    <TableCell
      id={id}
      sx={{
        background: `${backgroundColor}`,
        borderTop: 'none',
        borderBottom: 'none',
        cursor: 'text',
        input: {
          background: `${backgroundColor}`,
        },
        pointerEvents: `${isDisabled ? 'none' : ''}`,
        width: columnWidth,
        minWidth: isDisabled ? '' : '7rem'
      }}
      onClick={handleClickCell}
    >
      <input
        type="number"
        value={formValue}
        disabled={isDisabled}
        onKeyDown={(e) => setRandomData(e, modelInfo.model, '$temperature', 'setTemperatures', 10)}
        onWheel={(e) => e.target.blur()}
        onBlur={onBlur}
        onFocus={(e) => onFocusInput(e, id)}
        onChange={onChange}
      />
    </TableCell>
  )
}

export default TemperatureTableCell
