import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'authorization'
const translations = {
  enter: 'Login',
  apologies: 'We\'re sorry, but the server is temporarily unavailable.',
  'e-mail': 'e-mail',
  mailPlaceHolder: 'user@gmail.com',
  password: 'Password',
  signIn: 'Sign in',
  'forgetPassword?': 'Forgot your password?',
  'noAcc?': 'Don\'t have an account?',
  register: 'Register',
  'haveAcc?': 'Already have an account?',
  lookDemo: 'See how it all works',
  demo: 'Demo',
  passwordReset: 'Your password has been reset',
  error: 'Error',
  youRegistered: 'You are registered',
  confirmPasswordReset: 'It remains to confirm password reset',
  confirmEmail: 'It remains to confirm the mail',
  invalidLink: 'Link is invalid!',
  emailConfirmed: 'You have verified your email!',
  weSendMail: 'We sent an email with a link to\xa0{email}',
  checkMail: 'Check mail',
  sending: 'Sending...',
  sent: 'Sent. Repeat through 00:{time}',
  sendAgain: 'Send again',
  resetPassword: 'Reset the password',
  recoveryPassword: 'Password recovery',
  createNewPassword: 'Create a new password',
  newPassword: 'New password',
  repeatPassword: 'Repeat password',
  saveNewPassword: 'Save new password',
  invalidData: 'Invalid or busy data',
  registration: 'Registration',
  individual: 'Individual',
  corporate: 'Corporate<a></a>client',
  acceptTerms: 'I accept <tag>the terms of service</tag>',
  createAcc: 'Create an account',
  inn: 'INN',
  legalName: 'Legal entity name',
  legalAddress: 'Legal address',
  actualAddress: 'The actual address',
  ifActualIsLegal: 'If the Actual address is the same as the Legal address',
  contactTel: 'Contact',
  phoneOptional: 'Phone (optional)',
  legalNameOrINN: 'Enter the name of the legal entity',
  findOrg: 'Find an organization',
  loading: 'Loading...',
  login: 'Name',
  accept: 'Accept'
}

export const authorizationEn = createTranslationsWithSpaceName(translations, spaceName)
