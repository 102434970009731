import { createRoot } from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router} from 'react-router-dom';
import './index.sass';
import App from './presentation/components/app/App.jsx';
import * as serviceWorker from './serviceWorker';
import Error from './presentation/components/error/Error.jsx'
import GoogleAnalytics from './internal/lib/googleAnalytics'
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";

process.env.NODE_ENV === 'production' &&
Sentry.init({
  dsn: "https://e8446975ab6046a791b523efca547fc2@sentry.rockitstudio.online/20",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Error>
    <Router>
      <App/>
      <GoogleAnalytics/>
    </Router>
  </Error>
)

serviceWorker.unregister()
