import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'backCall'
const translations = {
  title: 'Похоже, произошла ошибка',
  subtitle: 'Наша команда была уведомлена.',
  subtitle2: 'Если хотите помочь, расскажите нам, что произошло.',
  labelName: 'Имя',
  labelEmail: 'Эл. адрес',
  labelComments: 'Что случилось?',
  labelClose: 'Закрыть',
  labelSubmit: 'Отправить',
  errorGeneric: "При отправке отчета произошла неизвестная ошибка. Пожалуйста, попробуйте еще раз.",
  errorFormEntry: 'Некоторые поля были недействительны. Пожалуйста, исправьте ошибки и повторите попытку.',
  successMessage: 'Ваш отзыв был отправлен. Благодарю вас!',
  namePlaceholder: 'Имя',
  emailPlaceHolder: 'example@mail.com',
  titleCustom: 'Нашли ошибку?',
  subtitleCustom: 'Если хотите помочь, расскажите нам, что произошло.',
  subtitle2Custom: 'Наша команда будет уведомлена.'
}

export const backCallRu = createTranslationsWithSpaceName(translations, spaceName)
