import {createData} from "../helpers";
import {FormattedMessage} from "react-intl";
import DevicesTypes from "@/presentation/components/devicesPage/devices_types";

export const DEVICE_SETTINGS_PATH = 'all_settings'
export const DEVICE_CONNECTION_SYSTEM_PATH = 'connection_system'
export const DEVICE_PATH = 'device'
export const DEVICE_PULSE_ONE_PATH = 'pulse_input_one'
export const DEVICE_PULSE_TWO_PATH = 'pulse_input_two'
export const DEVICE_TEMP_PATH = 'temperature_sensor'
export const DEVICE_SIM_SETTINGS_PATH = 'sim_setting'
export const DEVICE_TRANSFER_DEVICES_PATH = 'transfer_devices'

export const DEVICE_INFO_TABS = [
  {
    id: 1,
    textId: 'devicesPage.generalSettings',
    path: DEVICE_SETTINGS_PATH,
  },
  {
    id: 2,
    textId: 'devicesPage.systemConnecting',
    path: DEVICE_CONNECTION_SYSTEM_PATH,
  },
  {
    id: 3,
    textId: 'devicesPage.devices',
    path: DEVICE_PATH,
    types: DevicesTypes.allTransferDevices
  },
  {
    id: 4,
    textId: 'devicesPage.pulseInputOne',
    path: DEVICE_PULSE_ONE_PATH,
    types: [DevicesTypes.xPulseOld, DevicesTypes.electronicFlowMeter, DevicesTypes.xPulseNBIoT]
  },
  {
    id: 5,
    textId: 'devicesPage.pulseInputTwo',
    path: DEVICE_PULSE_TWO_PATH,
    types: [DevicesTypes.xPulseOld, DevicesTypes.electronicFlowMeter, DevicesTypes.xPulseNBIoT]
  },
  {
    id: 6,
    textId: 'devicesPage.tempSensor',
    path: DEVICE_TEMP_PATH,
    types: [DevicesTypes.xPulseOld, DevicesTypes.xPulseNBIoT]
  },
  {
    id: 7,
    textId: 'devicesPage.simSettings',
    path: DEVICE_SIM_SETTINGS_PATH,
    types: DevicesTypes.devicesWithSim
  },
  {
    id: 8,
    textId: 'devicesPage.toBindDevice',
    path: DEVICE_TRANSFER_DEVICES_PATH,
    types: DevicesTypes.allEndDevices
  }
]

export const UTC_LIST = [
  {
    value: 1,
    label: 'UTC +1'
  },
  {
    value: 2,
    label: 'UTC +2'
  },
  {
    value: 3,
    label: 'UTC +3'
  },
]

export const ROWS_PER_PAGE_LIST = [
  {
    value: 25,
    label: (
      <FormattedMessage
        id={'devicesPage.entries'}
        values={{
          count: 25
        }}
      />
    )
  },
  {
    value: 50,
    label: (
      <FormattedMessage
        id={'devicesPage.entries'}
        values={{
          count: 50
        }}
      />
    )
  },
  {
    value: 100,
    label: (
      <FormattedMessage
        id={'devicesPage.entries'}
        values={{
          count: 100
        }}
      />
    )
  },
  {
    value: 1000,
    label: (
      <FormattedMessage
        id={'devicesPage.entries'}
        values={{
          count: 1000
        }}
      />
    )
  },
]

export const HEAD_CELLS = [
  { id: 'name', numeric: false, disablePadding: false, label: <FormattedMessage id={'devicesPage.localTime'}/> },
  { id: 'calories', numeric: true, disablePadding: false, label: <FormattedMessage id={'devicesPage.operation'}/> },
  { id: 'fat', numeric: true, disablePadding: false, label: <FormattedMessage id={'devicesPage.message'}/> },
  { id: 'carbs', numeric: true, disablePadding: false, label: <FormattedMessage id={'devicesPage.source'}/> },
  { id: 'protein', numeric: true, disablePadding: false, label: <FormattedMessage id={'devicesPage.executionStatus'}/> },
]

export const ROWS = [
  createData(1,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Система", "Завершено"),
  createData(2,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Система", "Завершено"),
  createData(3,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Код ошибки и расшифровка", "Система", "Завершено"),
  createData(4,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Код ошибки и расшифровка", "Система", "Завершено"),
  createData(5,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Код ошибки и расшифровка", "Система", "Завершено"),
  createData(6,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Пользователь\n"+"test_sochi", "Завершено"),
  createData(7,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(8,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"kuznik", "Ошибка"),
  createData(9,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"kuznik", "Ошибка"),
  createData(10,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"kuznik", "Ошибка"),
  createData(11,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"kuznik", "Завершено"),
  createData(12,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"kuznik", "Завершено"),
  createData(13,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(14,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(15,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(16,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(17,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(18,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(19,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(20,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(21,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(22,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(23,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(24,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(25,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(26,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(27,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(28,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(29,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(30,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(31,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(32,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(33,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(34,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(35,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(36,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(37,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(38,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(39,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
  createData(40,'05.07:21 12:04:03 (UTC+3)', "Отчет о потреблении коммунальных ресурсов", "Шаг 1. Опрос приборов через FB-0023400 (каналы 1,2,3): 72-3109425. Успешно для 1 из 1 приборов", "Администратор\n"+"basharov", "Ошибка"),
]