import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const translations = {
  shortLogin: `The name must not be shorter than {length, plural, one {# character} other {# characters}}`,
  longLogin: `Name must not exceed {length, plural, one {# character} other {# characters}}`,
  longName: 'The name must not exceed {length, plural, one {# character} other {# characters}}',
  login: 'Invalid username',
  email: 'Incorrect e-mail',
  phone: 'Invalid phone number',
  required: 'Required field',
  repeat: 'Passwords do not match',
  minDate: 'Try a later interval',
  maxDate: 'The interval cannot exceed the current date',
  notFoundEmail: 'User with this e-mail not found',
  inn: 'Incorrect value',
  notFullRangeData: 'Select the end of the interval',
  notMinus: "Can't be negative",
  wrongLon: 'Incorrect longitude',
  wrongLat: 'Incorrect latitude',
  wrongValue: 'Incorrect value',
  notZero: "Cannot equal 0",
  incorrectSerialNumber: 'The serial number must start with "{startSerial}"'
}

export const validationsMsgsEn = createTranslationsWithSpaceName(translations, 'validationsMsgs')