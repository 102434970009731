import cookiesManager from "../services/cookiManager";
import {API_URL} from "@/config";

const checkError = (res) => {
  if (res.statusCode === 401 || res.status === 401) {
    const origin = window.location.origin

    cookiesManager.cookiesDelete()
    window.location.replace(`${origin}/input`)
    throw Error(res)
  }
  const contentType = res.headers.get('Content-Type') ?? []

  if (contentType &&
    (contentType.includes('application/json') || contentType.includes('application/problem+json'))) {
    return res.json()
  } else {
    return res
  }
}

const getHeaders = (isAuth, tokenData) => {
  const initHeaders = {"Content-Type": "application/json",}

  if (isAuth || tokenData) {
    const token = tokenData ?? cookiesManager.getCookieToken()

    initHeaders['Access-Control-Allow-Origin'] = '*'
    initHeaders['Authorization'] = `JWT ${token}`
  } else {
    initHeaders['Access-Control-Allow-Origin'] = 'no-cors'
  }

  return new Headers(initHeaders)
}

export const request = (params, getDataAdapter = null) => {
  const controller = new AbortController()
  const isAuth = params.isAuth ?? true
  const token = params.token ?? null
  const url = API_URL + params.url

  const promiseOptions = {
    signal: controller.signal,
    method: params.method,
    headers: getHeaders(isAuth, token)
  }

  if (params.data) {
    promiseOptions.body = JSON.stringify(params.data)
  }

  const promise = fetch(url, promiseOptions)
    .then(checkError)
    .then(res => {
      if (res.code === 'token_not_valid') {
        const origin = window.location.origin

        cookiesManager.cookiesDelete()
        window.location.replace(`${origin}/input`)
        throw Error(res)
      }
      if (res.status === 'error') {
        console.log('error result', res)

        return res
      }

      if (getDataAdapter) {
        return getDataAdapter(res)
      } else {
        return res
      }
    })

  return ({
    promise,
    controller,
    promiseOptions,
  })
}
