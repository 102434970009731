import {Box, Step, StepLabel, Stepper} from "@mui/material";
import {CALCULATION_STEPS} from "@/presentation/components/heatCalculationPage/components/MKDPage/components/const";
import {calculationsSelectors} from "@/internal/lib/storeModels/models/mkds/calculations/calculationsState";
import {QontoConnector, QontoStepIcon} from "@/presentation/components/analytics/сomponents/Qonto";
import {calculationSettingsFormSelectors} from "@/internal/lib/storeModels/models/mkds/calculations/forms";

const CalculationSteps = (
  {
    loading = false,
    calculate
  }) => {
  const [activeStep, setActiveStep] = calculationsSelectors.activeStep.useState()
  const notValidApartments = calculationsSelectors.notValidApartments.useValue()
  const onClickSubmit = calculationSettingsFormSelectors.useOnClickSubmit()
  const isValidForm = calculationSettingsFormSelectors.useIsFormValid()
  const calcResultApartments = calculationsSelectors.calcResultApartments.useValue()
  const rowsPerPage= calculationsSelectors.rowsPerPage.useValue()

  const handleChangeStep = async (step) => {
    if (loading || !isValidForm || (step === activeStep)) return

    if (calcResultApartments.length) {
      setActiveStep(step)
      return
    }

    if (activeStep === 0 && step === CALCULATION_STEPS.length - 1) return
    if (activeStep === 0 && step === 1) {
      onClickSubmit()
      return
    }
    if (step === CALCULATION_STEPS.length - 1) {
      calculate({
        page: 1,
        limit: rowsPerPage
      })
      return
    }

    setActiveStep(step)
  }

  return (
    <div
      className={'calculations__steps'}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '1080px',
          minWidth: '800px'
        }}
      >
        <Stepper
          connector={<QontoConnector />}
          alternativeLabel
          activeStep={activeStep}
        >
          {CALCULATION_STEPS.map((step, index) => {
            if (index === 2 && !notValidApartments.length) return null
            return (
              <Step
                key={index}
              >
                <StepLabel
                  className={`stepper-step`}
                  StepIconComponent={QontoStepIcon}
                  onClick={() => handleChangeStep(index)}
                >
                  {step}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </Box>
    </div>
  )
}

export default CalculationSteps
