import {OBJECT_TYPE_OPTIONS} from "@/internal/content/consts";
import React, { useEffect, useMemo, useState} from "react";
import {ANALYTIC_UNIT_TYPES, CURRENCY_TYPES, WITHOUT_FLAT_TYPES, WITHOUT_RESIDENTS} from "./const";
import Loader from "@/presentation/components/loader/Loader";
import {Link, useParams} from "react-router-dom";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {addressEndpoint} from "@/data/api_entities/address/address_endpoint";
import {PageReturn} from "@/assets/svg";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import ObjectsSelect from "@/presentation/components/common/selects/ObjectsSelect";
import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import cookiesManager from "@/internal/lib/services/cookiManager";
import SelectInput from "@/presentation/components/common/formComponents/SelectInput";
import Input from "@/presentation/components/common/formComponents/Input";
import DadataSelect from "./DadataSelect";
import {FormattedDate, FormattedMessage, useIntl} from "react-intl";
import {RIGHT_SELECT_WITH_INPUT, RIGHT_SELECT_WITH_INPUT_ERROR} from "@/presentation/components/common/selects/const";
import ValidationsWrapper from "@/presentation/components/common/formComponents/ValidationsWrapper";
import WithHistory from "@/presentation/components/addressForm/WithHistory";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import {servicemenEndpoints} from "@/data/api_entities/servicemen/endpoints";
import {managerAddressesSelectors} from "@/internal/lib/storeModels/models/servicemen/stores";
import {isOpenAsideSelectors} from "@/internal/lib/storeModels/models/status/asideFormModel";

const AddressForm = (
  {
    isOpenDeleteModal = false,
    toggleDeleteModal,
    customClass = '',
    formModelSelectors,
    onValidSubmit,
    onDelete,
    isNewAddress = false,
    withOutBack = false,
    hideAside,
    pathOnVisible = null,
    pathWithID = false,
    isLoading = false,
    editAddressId = null,
    isDeleteAvailable = true,
    isOpenPreexistModal = false,
    togglePreexistModal,
    preexistingAddressInfo,
    canSelectPreexistAddress = false,
    onAgreeWithSelectPreexistModal,
    isOpenDeleteObjWithDevicesModal = false,
    isDeleting = false
  }) => {
  const intl = useIntl()
  const params = useParams()

  const addresses = addressesSelectors.addresses.useValue()
  const setSelectedAddress = addressesSelectors.selectedAddress.useSetValue()
  const userData = userDataSelectors.userData.useValue()
  const isOpenAside= isOpenAsideSelectors.useValue()

  const isValidForm = formModelSelectors.useIsFormValid()
  const setOnValidSubmit = formModelSelectors.useSetOnValidSubmit()
  const setFormValue = (key) => formModelSelectors.setFormDataValue(key)
  const setInitialData = formModelSelectors.useSetInitFormData()
  const onSubmitForm = formModelSelectors.useOnClickSubmit()
  const formValidations = formModelSelectors.useFormValidations()

  const region = formModelSelectors.useFormDataValue('region')
  const objectType = formModelSelectors.useFormDataValue('object_type')
  const addressName = formModelSelectors.useFormDataValue('name')
  const street = formModelSelectors.useFormDataValue('street')
  const building = formModelSelectors.useFormDataValue('building')
  const unitType = formModelSelectors.useFormDataValue('unit_type')
  const currencyType = formModelSelectors.useFormDataValue('currency_type')
  const serviceManager = formModelSelectors.useFormDataValue('servicemanager')

  const [lastGetAddressInfoRequest, setLastGetAddressInfoRequest] = useState(null)
  const [lastGetPrototypeRequest, setLastGetPrototypeRequest] = useState(null)
  const [firstLoading, setFirstLoading] = useState(() => true)
  const [addressPrototypeId, setAddressPrototypeId] = useState(null)
  const [tariffsHistory, setTariffsHistory] = useState([])
  const [isNameInputDirty, setIsNameInputDirty] = useState(false)

  const [lastGetServiceManagersRequest, setLastGetServiceManagersRequest] = useState(null)
  const [serviceManagers, setServiceManagers] = useState([])
  const managerAddresses = managerAddressesSelectors.useValue()
  const tariffsHistoryList = []/*tariffsHistory.reverse().map((data, i) => {
    return (
      <div
        className="object-form__history-item"
        key={`tariff-${i}`}
      >
        <span>
          {data.tariff} {CURRENCY_TYPES.find(type => type.value === data.currency_type)?.label ?? '?'}
        </span>

        <span>
          <FormattedMessage
            id={'addressForm.tillDate'}
            values={{
              date: <FormattedDate value={data.date} />
            }}
          />
        </span>
      </div>
    )
  })
*/
  const areaHistory = /*[
    {
      value: 36,
      date: '2022-02-02'
    }
  ]*/ []

  const loading = useMemo(() => {
    return isNewAddress
      ? isLoading || !!lastGetPrototypeRequest
      : firstLoading || isLoading || !!lastGetAddressInfoRequest
  }, [isNewAddress, firstLoading, lastGetAddressInfoRequest, isLoading])

  useEffect(() => {
    if (userData.service_manager || loading) return
    setServiceManagers([])
    if (lastGetServiceManagersRequest) {
      lastGetServiceManagersRequest.abort()
    }

    const {promise, controller} = servicemenEndpoints.getServicemensByRegion(region)

    setLastGetServiceManagersRequest(controller)

    promise.then(res => {
      if (Array.isArray(res)) {
        setServiceManagers(res.map(serviceManger => ({
          value: serviceManger.id,
          label: serviceManger.user
        })))
        const index = res.findIndex(elem => elem.id === serviceManager)
        if (index < 0) {
          setFormValue('servicemanager')(0)
        }
      }

      setLastGetServiceManagersRequest(null)
    })
      .catch(err => {
        if (err.name === 'AbortError') return

        setLastGetServiceManagersRequest(null)
      })
  }, [region, loading])

  useEffect(() => {
    if (isNameInputDirty) return
    setFormValue('name')(`${street} ${building}`)
  }, [street, building])

  const areaHistoryList = areaHistory.reverse().map((data, i) => {
    return (
      <div
        className="object-form__history-item"
        key={`area-${i}`}
      >
        <span>
          <FormattedMessage
            id={'addressForm.areaValue'}
            values={{
              value: data.value,
              sup: (chunks) => (<sup>{chunks}</sup>)
            }}
          />
        </span>

        <span>
          <FormattedMessage
            id={'addressForm.tillDate'}
            values={{
              date: <FormattedDate value={data.date} />
            }}
          />
        </span>
      </div>
    )
  })

  const preexistingModalBody = useMemo(() => {
    if (!preexistingAddressInfo) return ''

    return (
      <>
        <p>
          <FormattedMessage
            id={'addressForm.preExistObject'}
            values={{
              link: (
                <a href={`/user_page/accounting_objects/${preexistingAddressInfo.address_id}/object_parameters`} target={'_blank'}>
                  <FormattedMessage id={'addressForm.exists'}/>
                </a>
              )
            }}
          />
        </p>

        {canSelectPreexistAddress &&
          <p>
            <FormattedMessage id={'addressForm.changeToOrUse'}/>
          </p>
        }
      </>
    )
  }, [preexistingAddressInfo, canSelectPreexistAddress])

  const preexistingModalAgreeBtnConf = useMemo(() => {
    return canSelectPreexistAddress
      ? {
        className: 'btn',
        text: <FormattedMessage id={'app.use'}/>
      }
      : {
        className: 'btn',
        text: <FormattedMessage id={'app.ok'}/>
      }
  }, [canSelectPreexistAddress])

  const preexistingModalDisagreeBtnConf = useMemo(() => {
    return canSelectPreexistAddress
      ? {
        className: 'btn btn_transparent-bg',
        text: <FormattedMessage id={'app.cancel'}/>
      }
      : null
  }, [canSelectPreexistAddress])

  const selectedAddressPrototype = useMemo(() => {
    if (!addressPrototypeId) return null

    return (managerAddresses ?? addresses).find(address => address.id === addressPrototypeId)
  }, [addresses, addressPrototypeId, managerAddresses])

  useEffect(() => {
    setOnValidSubmit(onValidSubmit)
  }, [onValidSubmit])

  useEffect(() => {
    if (WITHOUT_FLAT_TYPES.includes(objectType)) {
      setFormValue('apartment')('')
    }

    if (WITHOUT_RESIDENTS.includes(objectType)) {
      setFormValue('residents')('')
    }
  }, [objectType])

  useEffect(() => {
    const addressId = editAddressId ?? params.id

    if (isNewAddress || !addressId) return

    if (lastGetAddressInfoRequest) {
      lastGetAddressInfoRequest.abort()
    }

    const {promise, controller} = addressEndpoint.getAddressById(addressId)

    setLastGetAddressInfoRequest(controller)

    promise
      .then(res => {
        const {name, tariff, region, city, street, building, object_type, apartment, area, id, geo_len, geo_lat} = res
        const data = {
          id,
          name,
          region,
          city,
          street,
          building,
          object_type,
          apartment,
          residents: res.residents || '',
          area,
          geo_len,
          geo_lat,
          tariff,
          currency_type: res.currency_type ?? 1,
          unit_type: res.unit_type ?? 1,
          servicemanager: res.servicemanager ?? 0
        }

        setTariffsHistory(res.tariffs_history ?? [])
        setInitialData(data)
        setLastGetAddressInfoRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return

        setLastGetAddressInfoRequest(null)
      })
      .finally(() => {
        if (firstLoading) setFirstLoading(false)
      })
  }, [params.id, editAddressId])

  useEffect(() => {
    if (!addressPrototypeId) return

    if (lastGetPrototypeRequest) {
      lastGetPrototypeRequest.abort()
    }

    const {promise, controller} = addressEndpoint.getAddressById(addressPrototypeId)

    setLastGetPrototypeRequest(controller)

    promise
      .then(res => {
        const {tariff, region, city, street, building, object_type, apartment, area, geo_len, geo_lat} = res
        setLastGetPrototypeRequest(null)

        const data = {
          region,
          city,
          street,
          building,
          object_type,
          apartment,
          residents: res.residents || '',
          area: area || 0,
          geo_len,
          geo_lat,
          tariff,
          unit_type: res.unit_type ?? 1,
          servicemanager: res.servicemanager ?? 0
        }

        setInitialData(data)
      })
      .catch(err => {
        if (err.name === 'AbortError') return

        setLastGetPrototypeRequest(null)
      })
  }, [addressPrototypeId])

  const handleSubmit = () => {
    if (!addressName) {
      setFormValue('name')(`${street} ${building}`)
    }

    if (!isValidForm) return

    onSubmitForm()
  }

  const handleAgreePreexistModal = () => {
    if (canSelectPreexistAddress) {
      onAgreeWithSelectPreexistModal(preexistingAddressInfo.address_id)
    } else {
      togglePreexistModal()
    }
  }

  const onChangePrototypeId = (e) => {
    setAddressPrototypeId(e.id)
  }

  const setSelectedAddressByDelete = () => {
    const address = addresses.find(address => address.id == params.id || address.id == editAddressId) ?? null

    cookiesManager.set('X-pulseAddress', (address?.id ?? 0))
    setSelectedAddress(address)
  }

  return (
    <div className={`object-form ${customClass}`}>
      { !withOutBack &&
        <ArrowBack
          pathOnVisible={pathOnVisible}
          withId={pathWithID}
        />
      }

      <Loader loading={loading}>
        <div className="object-form__title-wrapper">
          {!!hideAside &&
            <div className='object-form__back-img' onClick={() => {
              // setServiceManagers([])
              setAddressPrototypeId(null)
              hideAside()
            }}>
              <img
                src={PageReturn}
                alt="PageReturn"
              />
            </div>
          }

          <h2 className="title object-form__title">
            { isNewAddress
              ? <FormattedMessage id={'addressForm.newObject'}/>
              : <FormattedMessage id={'addressForm.objectParams'}/>
            }
          </h2>
        </div>

        <div className="form-fields">
          {isNewAddress && !!(managerAddresses ?? addresses).length &&
            <div className="form-group">
              <label>
                <FormattedMessage id={'addressForm.createFrom'}/>
              </label>

              <ObjectsSelect
                addresses={managerAddresses ?? addresses}
                value={selectedAddressPrototype}
                onChange={onChangePrototypeId}
              />
            </div>
          }

          <SelectInput
            label={<FormattedMessage id={'addressForm.objectType'}/>}
            name={'object_type'}
            formSelectors={formModelSelectors}
            options={OBJECT_TYPE_OPTIONS}
          />

          {!WITHOUT_RESIDENTS.includes(objectType) &&
            <Input
              label={<FormattedMessage id={'addressForm.residents'}/>}
              name={'residents'}
              type={'number'}
              formSelectors={formModelSelectors}
            />
          }

          <div className='object-form__comparison'>
            <span>
              <FormattedMessage id={'addressForm.forCompare'}/>
            </span>
          </div>

          <DadataSelect
            label={<FormattedMessage id={'addressForm.findAddress'}/>}
            addressFormSelectors={formModelSelectors}
            placeholder={<FormattedMessage id={'addressForm.enterAddress'}/>}
            clearNameOnChange={isOpenAside}
          />

          <DadataSelect
            name={'region'}
            label={<FormattedMessage id={'addressForm.region'}/>}
            addressFormSelectors={formModelSelectors}
          />

          <DadataSelect
            name='city'
            label={<FormattedMessage id={'addressForm.city'}/>}
            startQueryFormNames={['region']}
            addressFormSelectors={formModelSelectors}
          />

          <DadataSelect
            name={'street'}
            label={<FormattedMessage id={'addressForm.street'}/>}
            addressFormSelectors={formModelSelectors}
            startQueryFormNames={['region', 'city']}
          />

          <DadataSelect
            name={'building'}
            label={<FormattedMessage id={'addressForm.building'}/>}
            addressFormSelectors={formModelSelectors}
            startQueryFormNames={['region', 'city', 'street']}
          />

          {
            !WITHOUT_FLAT_TYPES.includes(objectType) &&
            <DadataSelect
              name={'apartment'}
              label={<FormattedMessage id={'addressForm.apartment'}/>}
              addressFormSelectors={formModelSelectors}
              startQueryFormNames={['region', 'city', 'street', 'building']}
            />
          }

          <Input
            label={<FormattedMessage id={'addressForm.objectName'}/>}
            placeholder={intl.formatMessage({id: 'addressForm.myHouse'})}
            name='name'
            formSelectors={formModelSelectors}
            withChangeValue={() => {if (!isNameInputDirty) setIsNameInputDirty(true)}}
          />

          <div className='object-form__comparison'>
            <span>
              <FormattedMessage id={'addressForm.forAnalytic'}/>
            </span>
          </div>

          {!userData.service_manager &&
            <SelectInput
              loading={!!lastGetServiceManagersRequest}
              label={'Сервис-менеджер'}
              name={'servicemanager'}
              formSelectors={formModelSelectors}
              options={[{
                value: 0,
                label: 'Не задан'
              }, ...serviceManagers]}
            />
          }


          <SelectInput
            label={<FormattedMessage id={'addressForm.unitType'}/>}
            name={'unit_type'}
            formSelectors={formModelSelectors}
            options={ANALYTIC_UNIT_TYPES}
          />

          <WithHistory
            title={<FormattedMessage id={'addressForm.tariffsChanges'}/>}
            historyList={tariffsHistoryList}
          >
            <div className="object-form__double-inputs">
              <ValidationsWrapper validationMsgs={formValidations.tariff ?? []}>
                <Input
                  label={
                    <FormattedMessage
                      id={'addressForm.tariff'}
                      values={{
                        currency: CURRENCY_TYPES.find(type => type.value === currencyType)?.label ?? '-',
                        unit: ANALYTIC_UNIT_TYPES.find(type => type.value === unitType)?.label ?? '-'
                      }}
                    />
                  }
                  type="number"
                  name='tariff'
                  formSelectors={formModelSelectors}
                  isShowValidMsg={false}
                />

                <SelectInput
                  options={CURRENCY_TYPES}
                  formSelectors={formModelSelectors}
                  name={'currency_type'}
                  styles={!!formValidations.tariff?.length
                    ? RIGHT_SELECT_WITH_INPUT_ERROR
                    : RIGHT_SELECT_WITH_INPUT}
                />
              </ValidationsWrapper>
            </div>
          </WithHistory>

          <WithHistory
            title={<FormattedMessage id={'addressForm.areaChanges'}/>}
            historyList={areaHistoryList}
          >
            <Input
              label={<FormattedMessage id={'addressForm.area'}/>}
              formSelectors={formModelSelectors}
              type="number"
              name='area'
            />
          </WithHistory>

          <Input
            label={<FormattedMessage id={'addressForm.lat'}/>}
            formSelectors={formModelSelectors}
            type="number"
            name='geo_lat'
          />

          <Input
            label={<FormattedMessage id={'addressForm.lon'}/>}
            formSelectors={formModelSelectors}
            type="number"
            name='geo_len'
          />
        </div>

        <div className="object-form__btns">
          <button
            className="btn"
            onClick={handleSubmit}
            disabled={!isValidForm}
          >
            <FormattedMessage id={'app.save'}/>
          </button>

          {
            isNewAddress || !isDeleteAvailable || userData.service_manager
              ? null
              : (
                <button
                  className='btn btn_red'
                  onClick={toggleDeleteModal}
                >
                  <FormattedMessage id={'app.delete'}/>
                </button>
              )
          }
        </div>
      </Loader>

      <DefaultModal
        loading={isDeleting}
        isOpen={isOpenDeleteModal}
        toggleModal={toggleDeleteModal}
        agreeBtnConfig={{
          className: 'btn btn_red',
          text: <FormattedMessage id={'app.delete'}/>
        }}
        onAgree={() => onDelete(editAddressId ?? params.id)}
        onDisagree={toggleDeleteModal}
        headerTitle={<FormattedMessage id={'addressForm.deleteObject?'}/>}
      />

      <DefaultModal
        isOpen={isOpenPreexistModal}
        headerTitle={<FormattedMessage id={'addressForm.savingObject'}/>}
        bodyText={preexistingModalBody}
        onAgree={handleAgreePreexistModal}
        onDisagree={togglePreexistModal}
        agreeBtnConfig={preexistingModalAgreeBtnConf}
        disagreeBtnConfig={preexistingModalDisagreeBtnConf}
        toggleModal={togglePreexistModal}
      />

      <DefaultModal
        isOpen={isOpenDeleteObjWithDevicesModal}
        headerTitle={<FormattedMessage id={'addressForm.removeObject'}/>}
        onAgree={toggleDeleteModal}
        bodyText={
          <>
            <FormattedMessage
              id={'addressForm.objectWithDevices'}
              values={{
                link: (
                  <Link
                    onClick={setSelectedAddressByDelete}
                    to={'device'}
                  >
                    <FormattedMessage id={'addressForm.assign'}/>
                  </Link>
                )
              }}
            />
          </>
        }
        agreeBtnConfig={{
          className: 'btn',
          text: <FormattedMessage id={'app.ok'}/>
        }}
        disagreeBtnConfig={null}
        toggleModal={toggleDeleteModal}
      />
    </div>
  )
}

export default AddressForm
