import ArrowBack from "@/presentation/components/common/ArrowBack";
import Input from "@/presentation/components/common/formComponents/Input";
import {FormattedMessage} from "react-intl";
import React, {useEffect, useMemo, useState} from "react";
import Loader from "@/presentation/components/loader/Loader";
import {apartmentsEndpoint} from "@/data/api_entities/apartments/apartments_endpoint";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import {selectors} from "@/presentation/shared/ui/message";
import {emulateTab} from "emulate-tab";

const ApartmentForm = (
  {
    isOpenDeleteModal = false,
    toggleDeleteModal,
    onDelete,
    isDeleting = false,
    formSelectors,
    pathOnVisible,
    onValidSubmit,
    isLoading = false,
    editId = null,
    lastApartment = null
  }) => {
  const userData = userDataSelectors.userData.useValue()
  const isValidForm = formSelectors.useIsFormValid()
  const onSubmitForm = formSelectors.useOnClickSubmit()
  const setInitialData = formSelectors.useSetInitFormData()
  const setOnValidSubmit = formSelectors.useSetOnValidSubmit()
  const [lastGetDataRequest, setLastGetDataRequest] = useState(null)
  const [firstLoading, setFirstLoading] = useState(true)
  const setMessage = selectors.useSetMessage()

  const loading = useMemo(() => {
    return !editId
      ? isLoading
      : firstLoading || isLoading || !!lastGetDataRequest
  }, [editId, firstLoading, lastGetDataRequest, isLoading])

  useEffect(() => {
    setOnValidSubmit(onValidSubmit)
  }, [onValidSubmit])

  useEffect(() => {
    if (editId) {
      if (lastGetDataRequest) {
        lastGetDataRequest.abort()
      }

      const {promise, controller} = apartmentsEndpoint.getById(editId)
      setLastGetDataRequest(controller)
      promise
        .then(res => {
          if ('id' in res) {
            setInitialData(res)
            setLastGetDataRequest(null)
          } else {
            setMessage({
              header: <FormattedMessage id={'requests.error'}/>,
              message: 'Не удалось загрузить данные квартиры',
              type: 'error',
              count: 3000
            })
            console.log(res)
          }
        })
        .catch(err => {
          if (err.name === 'AbortError') return
          setLastGetDataRequest(null)
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось загрузить данные квартиры',
            type: 'error',
            count: 3000
          })
        })
        .finally(() => {
          if (firstLoading) setFirstLoading(false)
        })
    } else {
      if (lastApartment !== null) {
        setInitialData({
          kv: (lastApartment + 1).toString()
        })
      }
    }
  }, [editId])

  const handleSubmit = () => {
    if (!isValidForm) return

    onSubmitForm()
  }

  const onPressEnter = (e) => {
    if (e.code !== 'Enter') return

    e.stopPropagation()
    emulateTab()
  }

  return (
    <div className="apartment-form" onKeyDown={onPressEnter}>
      <ArrowBack
        pathOnVisible={pathOnVisible}
      />

      <div className="apartment-form__title-wrapper">
        <h2 className="title">
          {editId
            ? 'Параметры квартиры'
            : 'Добавление квартиры'
          }
        </h2>
      </div>

      <Loader loading={loading}>
        <div className="form-fields">
          <Input
            formSelectors={formSelectors}
            name={'kv'}
            label={'Номер квартиры'}
          />

          <Input
            formSelectors={formSelectors}
            name={'area'}
            label={<>Площадь, м<sup>2</sup></>}
            valueFormatter={(value) => !!value.length ? +value : value}
            type={'number'}
          />

          <Input
            formSelectors={formSelectors}
            name={'invoice'}
            label={'Лицевой счет'}
          />

          <Input
            formSelectors={formSelectors}
            name={'email'}
            label={'Почта владельца'}
            type={'email'}
          />
        </div>

        <div className="apartment-form__btns">
          <button
            className="btn"
            onClick={handleSubmit}
            disabled={!isValidForm}
          >
            <FormattedMessage id={'app.save'}/>
          </button>

          {userData.service_manager || !editId
            ? null
            : (
              <button
                className='btn btn_red'
                onClick={toggleDeleteModal}
              >
                <FormattedMessage id={'app.delete'}/>
              </button>
            )
          }
        </div>
      </Loader>

      <DefaultModal
        loading={isDeleting}
        isOpen={isOpenDeleteModal}
        toggleModal={toggleDeleteModal}
        agreeBtnConfig={{
          className: 'btn btn_red',
          text: <FormattedMessage id={'app.delete'}/>
        }}
        onAgree={() => onDelete(editId)}
        onDisagree={toggleDeleteModal}
        headerTitle={'Удаление квартиры'}
      />
    </div>
  )
}

export default ApartmentForm
