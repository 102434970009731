import ArrowBack from "@/presentation/components/common/ArrowBack";
import {NavLink, Route, Routes, useParams} from "react-router-dom";
import Loader from "@/presentation/components/loader/Loader";
import HoveredPopover from "@/presentation/components/common/HoveredPopover";
import {FormattedMessage} from "react-intl";
import React, {useEffect, useRef, useState} from "react";
import {Delete, Edit} from "@mui/icons-material";
import {heatCalculateEndpoints} from "@/data/api_entities/heat_calculate/heat_calculate_endpoints";
import {MKDHelper} from "@/presentation/components/heatCalculationPage/helpers";
import {UNIT_TYPES} from "@/presentation/components/heatCalculationPage/const";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import {useNavigateBack} from "@/internal/custom_hooks/navigateBack";
import CalcNamePage from "@/presentation/components/heatCalculationPage/components/MKDPage/components/CalcNamePage";
import TablesModal from "@/presentation/components/analytics/сomponents/TablesModal";
import Calculations from "@/presentation/components/heatCalculationPage/components/MKDPage/components/Calculations";
import {selectors} from "@/presentation/shared/ui/message";
import {calculationsSelectors} from "@/internal/lib/storeModels/models/mkds/calculations/calculationsState";
import GetXLSButton from "@/presentation/components/analytics/сomponents/result/GetXLSButton";
import {dateFormatter} from "@/internal/formatters/formatters";

const CalcPage = ({uploadCalcList}) => {
  const {id} = useParams()
  const lastGetDataRequest = useRef(null)
  const lastDeleteRequest = useRef(null)
  const [deleting, setDeleting] = useState(false)
  const [info, setInfo] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isOpenDeleteModal, toggleDeleteModal] = useToggle()
  const [navigateBack] = useNavigateBack()
  const [isOpenTable, toggleOpenTable] = useToggle(false)
  const [isOpenCloseSubmitModal, toggleSubmitModal, setIsOpenCloseSubmitModal] = useToggle()
  const [isOpenNewTable, toggleOpenNewTable] = useToggle(false)
  const setMessage = selectors.useSetMessage()
  const rowsPerPage = calculationsSelectors.rowsPerPage.useValue()

  const toggleModalNew = () => {
    if (isOpenNewTable) {
      setIsOpenCloseSubmitModal(true)
    } else {
      toggleOpenNewTable()
    }
  }

  const handleAgreeSubmit = () => {
    toggleSubmitModal()
    toggleOpenNewTable()
  }

  const toggleModal = () => {
    toggleOpenTable()
  }

  useEffect(() => {
    if (!id) return
    setLoading(true)
    lastGetDataRequest.current?.abort()
    const {promise, controller} = heatCalculateEndpoints.getHistoryById(id, {
      page: 1,
      limit: rowsPerPage
    })

    lastGetDataRequest.current = controller
    promise
      .then(res => {
        if ('energy_period' in res) {
          setInfo(res)
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось загрузить данные расчета',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
        lastGetDataRequest.current = null
        setLoading(false)
      })
      .catch(err => {
        if (err.name === 'AbortError') return
        lastGetDataRequest.current = null
        setLoading(false)
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось загрузить данные расчета',
          type: 'error',
          count: 3000
        })
      })

    return () => {
      lastGetDataRequest.current?.abort()
      lastDeleteRequest.current?.abort()
    }
  }, [id])

  const handleDelete = () => {
    setDeleting(true)
    lastDeleteRequest.current?.abort()
    const {promise, controller} = heatCalculateEndpoints.delete(info?.calculate)
    lastDeleteRequest.current = controller
    promise
      .then(res => {
        if (res?.status === 'ok') {
          uploadCalcList()
          toggleDeleteModal()
          navigateBack()
          setMessage({
            header: 'Удаление расчета',
            message: 'Расчет успешно удален',
            type: 'success',
            count: 3000
          })
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось удалить расчет',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }

        lastDeleteRequest.current = null
        setDeleting(false)
      })
      .catch(err => {
        if (err.name === 'AbortError') return
        lastDeleteRequest.current = null
        setDeleting(false)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось удалить расчет',
          type: 'error',
          count: 3000
        })
      })
  }

  return (
   <>
     <div className={'column calc-page'}>
       <ArrowBack
         pathOnVisible={'calculations'}
         withId
       />

       <Loader loading={loading}>
         <div className="calc-page__title-wrapper">
           <h2 className="title">
             {info?.name ?? '-'}

             <HoveredPopover
               text={<FormattedMessage id={'app.change'}/>}
             >
               <NavLink to={'edit'}>
                 <Edit />
               </NavLink>
             </HoveredPopover>
           </h2>

           <HoveredPopover
             text={<FormattedMessage id={'app.delete'}/>}
           >
             <Delete
               sx={{
                 fill: '#FC4F6E',
                 cursor: 'pointer'
               }}
               onClick={toggleDeleteModal}
             />
           </HoveredPopover>
         </div>

         <hr/>

         <div className="calc-page__input-info">
           <h3 className={'title_h3'}>
             <FormattedMessage id={'analytics.period'}/>
           </h3>
           <span>{dateFormatter(info?.start_date, true)} - {dateFormatter(info?.end_date, true)}</span>
         </div>

         <div className="calc-page__input-info">
           <h3 className={'title_h3'}>
             Потреблено
           </h3>
           <span>{info?.energy_period ?? '-'} {UNIT_TYPES.find(unit => unit.value === info?.unit_type)?.label ?? '-'}</span>
         </div>

         <div className="calc-page__btns-wrapper">
           <button
             className="btn btn_transparent-bg"
             onClick={toggleModal}
           >
             <FormattedMessage id={'analytics.report'}/>
           </button>

           <GetXLSButton
             getRequest={() => heatCalculateEndpoints.getXLSX(info?.calculate)}
           />
         </div>

         <hr/>

         <button className="btn" onClick={toggleModalNew}>
           <FormattedMessage id={'analytics.createReport'}/> продолжением
         </button>
       </Loader>

       <DefaultModal
         isOpen={isOpenDeleteModal}
         headerTitle={'Удалить расчет?'}
         agreeBtnConfig={{
           className: 'btn btn_red',
           text: <FormattedMessage id={'app.delete'}/>
         }}
         toggleModal={toggleDeleteModal}
         onDisagree={toggleDeleteModal}
         onAgree={handleDelete}
         loading={deleting}
       />

       {isOpenTable &&
         <TablesModal
           hideModal={toggleModal}
           handleAgreeSubmitCloseModal={toggleModal}
         >
           <Calculations
             toggleModal={toggleModal}
             resultData={info}
             uploadCalcList={uploadCalcList}
           />
         </TablesModal>
       }

       {isOpenNewTable &&
         <TablesModal
           hideModal={toggleModalNew}
           isOpenCloseSubmitModal={isOpenCloseSubmitModal}
           toggleSubmitCloseModal={toggleSubmitModal}
           handleAgreeSubmitCloseModal={handleAgreeSubmit}
         >
           <Calculations
             toggleModal={toggleModalNew}
             uploadCalcList={uploadCalcList}
             startSettings={info}
           />
         </TablesModal>
       }
     </div>

     <Routes>
       <Route
         path={'edit'}
         element={<CalcNamePage
           info={info}
           setInfo={setInfo}
         />}
       />
     </Routes>
   </>
  )
}

export default CalcPage
