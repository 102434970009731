import {OBJECT_TYPE_OPTIONS} from "@/internal/content/consts";

export const periodFormatter = (startPeriod, endPeriod) => {
  return startPeriod === endPeriod
    ? startPeriod
    : (
      <>
        {startPeriod} - {endPeriod}
      </>
    )
}

export const setObjTypeName = (type) => {
  const objType = type ?? -1

  return objType < 0
    ? '-'
    : OBJECT_TYPE_OPTIONS.find(objectTypeInfo => objectTypeInfo.value === type).label
}
