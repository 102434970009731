import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

const TablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onPageChange } = props

  return (
    <div className={'pagination-actions'}>
      <button
        onClick={(event) => onPageChange(event, page - 1)}
        disabled={page === 0}
      >
        <ArrowBackIosIcon
          className={'pagination-actions__arrow'}
        />
      </button>

      <span>{page + 1}</span>

      <button
        onClick={(event) => onPageChange(event, page + 1)}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <ArrowForwardIosIcon
          className={'pagination-actions__arrow'}
        />
      </button>
    </div>
  )
}

export default TablePaginationActions