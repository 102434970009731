import {AVAILABLE_DEVICES_LIST, getSerialPlaceholder} from "./const";
import React, {useEffect} from "react";
import {phoneFormatter} from "@/internal/formatters/formatters";
import Loader from "@/presentation/components/loader/Loader";
import {statusDeviceSelectors} from "@/internal/lib/storeModels/models/status/statusDeviceFormModel";
import DeviceAdded from "./components/DeviceAdded";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import Input from "@/presentation/components/common/formComponents/Input";
import SelectInput from "@/presentation/components/common/formComponents/SelectInput";
import MaskInput from "@/presentation/components/common/formComponents/MaskInput";
import {FormattedMessage, useIntl} from "react-intl";
import DevicesTypes from "@/presentation/components/devicesPage/devices_types";
import {cloneDeep} from "lodash";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import {
  devicesMasterIdSelectors,
  devicesSlavesFormsSelectors, devicesToLinkedDevicesSelectors
} from "@/internal/lib/storeModels/models/devices/forms/deviceFormModels";
import DevicesToLink from "@/presentation/components/deviceForm/components/DevicesToLink";

const DeviceForm = (
  {
    hideAside = null,
    formModelSelectors,
    onValidSubmit,
    loading = false,
    pathOnVisible = null,
    onlyNewSlaves = false,
    preexistMaster = null
  }) => {
  const intl = useIntl()

  const type = formModelSelectors.useFormDataValue('type')
  const isValidForm = formModelSelectors.useIsFormValid()
  const isValidationsAvailable = formModelSelectors.useIsValidationsAvailable()
  const onSubmitForm = formModelSelectors.useOnClickSubmit()
  const validateAllFields = formModelSelectors.useValidateAllFields()
  const setFormValue = (key) => formModelSelectors.setFormDataValue(key)

  const selectedAddress = addressesSelectors.selectedAddress.useValue()
  const isDeviceCreated = statusDeviceSelectors.useValue()
  const isLowPermissions = userDataSelectors.isLowPermissions.useValue()
  const resetIsDeviceCreated = statusDeviceSelectors.useReset()
  const [slavesForms, setSlavesForms] = devicesSlavesFormsSelectors.useState()
  const masterId = devicesMasterIdSelectors.useValue()
  const toLinkedDevices = devicesToLinkedDevicesSelectors.useValue()

  const deviceAddedBtnText = pathOnVisible
    ? <FormattedMessage id={'app.proceed'}/>
    : <FormattedMessage id={'app.customize'}/>
  const isSubmitDisabled = !isValidForm || isLowPermissions

  useEffect(() => {
    if (onlyNewSlaves) {
      setFormValue('type')(0)
    }
    return () => {
      resetIsDeviceCreated()
    }
  }, [])

  const removeUnavailableDevices = (type) => {
    if (DevicesTypes.allEndDevices.includes(type) || DevicesTypes.soloDevices.includes(type)) {
      setSlavesForms([])
    } else {
      if (DevicesTypes.transferDevicesOnlyRadio.includes(type)) {
        const filteredSlavesForms = cloneDeep(slavesForms).filter(form => {
          return form.getStateFromModel('$formData').type !== DevicesTypes.heatMeter
        })
        setSlavesForms(filteredSlavesForms)
      }
    }
  }

  useEffect(() => {
    removeUnavailableDevices(type)

    if (isValidationsAvailable) {
      validateAllFields()
    }
  }, [type])

  const handleSubmit = () => {
    if (isSubmitDisabled) return
    let isFormsValid = true
    onSubmitForm()
    slavesForms.forEach(form => {
      form.model.onCickSubmit()
    })
    slavesForms.forEach(form => {
      isFormsValid = form.getStateFromModel('$isValidForm')
    })

    if (isFormsValid) {
      isFormsValid = formModelSelectors.getStateFromModel('$isValidForm')
    }

    if (isFormsValid) {
      const slaves = []

      slavesForms.forEach(form => {
        const formData = form.getStateFromModel('$formData')
        if (formData.type === 0) return
        slaves.push(formData)
      })

      toLinkedDevices.forEach(device => {
        slaves.push(device.id)
      })

      if (onlyNewSlaves && slaves.length === 0) return;

      onValidSubmit({
        ...formModelSelectors.getStateFromModel('$formData'),
        masterId: preexistMaster?.id ?? masterId,
        slaves,
      })
    } else {
      console.log('form not valid')
    }
  }
  return (
    <div className={'device-form'}>
      {pathOnVisible &&
        <ArrowBack
          pathOnVisible={pathOnVisible}
        />
      }
      {
        isDeviceCreated
          ? (
            <DeviceAdded
              isTwoDevices={!!slavesForms.length || !!toLinkedDevices.length}
              btnText={deviceAddedBtnText}
              hideAside={hideAside}
            />
          )
          : (
            <Loader loading={loading}>
              <div className="device-form__title-wrapper">
                <h2 className="title">
                  <FormattedMessage id={'deviceForm.addingDevice'}/>
                </h2>
              </div>

              <p className={'device-form__description'}>
                <FormattedMessage
                  id={'deviceForm.deviceWillBind'}
                  values={{
                    name: selectedAddress.name
                  }}
                />
              </p>

              {!onlyNewSlaves &&
              <div className="form-fields form-fields_first-group">
                <SelectInput
                  label={<FormattedMessage id={'deviceForm.selectDeviceType'}/>}
                  name={'type'}
                  formSelectors={formModelSelectors}
                  options={AVAILABLE_DEVICES_LIST}
                />

                {
                  !!type &&
                  <>
                    <Input
                      label={<FormattedMessage id={'deviceForm.serial'}/>}
                      name={'serial'}
                      placeholder={getSerialPlaceholder(type, intl)}
                      formSelectors={formModelSelectors}
                    />

                    {DevicesTypes.devicesWithSim.includes(type) &&
                      <MaskInput
                        label={<FormattedMessage id={'deviceForm.simNumber'}/>}
                        name={'sim'}
                        mask="+7 (999) 999-99-99"
                        type="tel"
                        formSelectors={formModelSelectors}
                        valueFormatter={phoneFormatter}
                      />
                    }

                    {type === DevicesTypes.heatMeter &&
                      <Input
                        formSelectors={formModelSelectors}
                        label={<FormattedMessage id={'deviceForm.address'}/>}
                        name={'rsAddress'}
                        type={'number'}
                      />
                    }
                  </>
                }
              </div>
              }

              {(!!type || onlyNewSlaves) && !DevicesTypes.soloDevices.includes(type) &&
                <DevicesToLink
                  mainDeviceType={preexistMaster?.type ?? type}
                  onlyNewSlaves={onlyNewSlaves}
                />
              }

              {(!!type || (onlyNewSlaves && slavesForms.length > 0)) &&
                <div className="device-form__btns-wrapper">
                  <button
                    className="btn"
                    onClick={handleSubmit}
                    disabled={isSubmitDisabled}
                  >
                    <FormattedMessage id={'app.create'}/>
                  </button>
                </div>
              }
            </Loader>
          )
      }
    </div>
  )
}

export default DeviceForm
