import {getShortMontName, SHORT_MONTH_NAME} from "@/internal/content/consts";

export const getDataAdapter = (data, intl) => {
  return {
    type_request: data.mode
      ? data.mode
      : data.displayMode,
    point_id: data.pointsIds,
    year: data.mode
      ? data.year
      : data.displayMode === 3
        ? data.pointValue
        : data.year,
    day: data.mode
      ? 0
      : data.displayMode === 1
        ? data.pointValue
        : 0,
    month: data.mode
      ? data.month + 1
      : data.displayMode === 2
        ? Object.keys(SHORT_MONTH_NAME).find(key => getShortMontName(intl, key) === data.pointValue)
        : data.month
  }
}
