import SelectComponent from "@/presentation/components/common/selects/Select";
import {useEffect, useState} from "react";
import {meteringsEndpoint} from "@/data/api_entities/meterings/meterings_endpoint";
import Chart from "./components/Chart";
import {
  CHART_TYPES_LIST, getShortMontName, SHORT_MONTH_NAME,
} from "@/internal/content/consts";
import Loader from "@/presentation/components/loader/Loader";
import DetailedChart from "./components/DetailedChart";
import {useNavigate} from "react-router-dom";
import {devicesSelectors} from "@/internal/lib/storeModels/models/devices/devicesModel";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import ResourcesList from "@/presentation/components/intake/components/ResourcesList";
import MeteringPointsList from "@/presentation/components/intake/components/MeteringPointsList";
import PointsTable from "@/presentation/components/intake/components/PointsTable";
import {intakeSelectors} from "@/internal/lib/storeModels/models/intake/intakeModels";
import ChartSettings from "@/presentation/components/intake/components/ChartSettings";
import {sampleParamsFormSelectors} from "@/internal/lib/storeModels/models/intake/forms/sampleParamsFormModel";
import {selectors} from "@/presentation/shared/ui/message";
import {FormattedMessage, useIntl} from "react-intl";
import {meteringPointsPageSelectors} from "@/internal/lib/storeModels/models/meteringPointsPage/meteringPointsModels";

const Intake = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const thisYear = new Date().getFullYear()

  const selectedAddress = addressesSelectors.selectedAddress.useValue()
  const isDevicesAvailable = devicesSelectors.devices.useDevicesAvailable()
  const isMeteringPointsAvailable = meteringPointsPageSelectors.pointsList.useIsPointsAvailable()
  const setIsFadeAddressSelect = addressesSelectors.isFadeActiveObjSelect.useSetValue()
  const setSelectedPoints = sampleParamsFormSelectors.useSetFormDataValue('pointsIds')
  const sampleData = sampleParamsFormSelectors.useFormData()
  const setPointValue = sampleParamsFormSelectors.useSetFormDataValue('pointValue')
  const setMessage = selectors.useSetMessage()
  const [isLoadingMeterings, setIsLoadingMeterings, resetIsLoadingMeterings] = intakeSelectors.isLoadingMeterings.useState()
  const setMeteringPoints = intakeSelectors.meteringPoints.useSetValue()
  const setResourceTypes = intakeSelectors.resourceTypes.useSetValue()
  const resetResourceTypes = intakeSelectors.resourceTypes.useReset()
  const setActiveTypes = intakeSelectors.activeTypes.useSetValue()
  const resetActiveTypes = intakeSelectors.activeTypes.useReset()

  const [lastSampleMeteringRequest, setLastSampleMeteringRequest] = useState(null)
  const [pointData, setPointData] = useState([])
  const [detailedXAxis, setDetailedAxis] = useState([])
  const [meteringXAxis, setMeteringXAxis] = useState([])
  const [selectedChartType, setSelectedChartType] = useState('column')
  const [pressedKey, setPressedKey] = useState(null)
  const [changeOnlyDetailed, setChangeOnlyDetailed] = useState(false)

  const resetPressedKey = () => setPressedKey(null)

  useEffect(() => {
    if (isDevicesAvailable && isMeteringPointsAvailable) {
      setIsLoadingMeterings(true)

    meteringsEndpoint.getMeterings()
      .then(res => {
        if (res.status === 'warning') {
          // setMessage({
          //   message: <FormattedMessage id={'requests.getMeteringsError'}/>,
          //   header: <FormattedMessage id={'requests.error'}/>,
          //   type: 'error',
          //   count: 3000
          // })

            resetResourceTypes()
            resetActiveTypes()
            return
          }

          setActiveTypes([res.metering_points[0].type])
          setResourceTypes(res.resource_types)
          setSelectedPoints([res.metering_points[0].id])
          setMeteringPoints(res.metering_points)
        })
        .catch(err => {
          console.log(err)

        // setMessage({
        //   message: <FormattedMessage id={'requests.getMeteringsError'}/>,
        //   header: <FormattedMessage id={'requests.error'}/>,
        //   type: 'error',
        //   count: 3000
        // })
      })
        .finally(() => setIsLoadingMeterings(false))
    } else {
      if (isDevicesAvailable) {
        navigate('/main/metering_points')
      } else {
        navigate('/main/device')
      }
    }

    return () => {
      resetIsLoadingMeterings()
    }
  }, [isDevicesAvailable, isMeteringPointsAvailable])

  // useEffect(() => {
  //   if (isDevicesAvailable && isMeteringPointsAvailable) return
  //
  //   navigate('main/device')
  // }, [ isDevicesAvailable, isMeteringPointsAvailable])

  useEffect(() => {
    window.addEventListener('keydown', handlePressArrows)
    window.addEventListener('keyup', resetPressedKey)

    return () => {
      window.removeEventListener('keydown', handlePressArrows)
      window.removeEventListener('keyup', resetPressedKey)

      setIsFadeAddressSelect(false)

      if (lastSampleMeteringRequest) {
        lastSampleMeteringRequest.abort()
      }
    }
  }, [])

  useEffect(() => {
    if (isLoadingMeterings || !!lastSampleMeteringRequest) return

    if (pressedKey === 'ArrowLeft') {
      handleChangePointValue(-1)
    } else if (pressedKey === 'ArrowRight') {
      handleChangePointValue(1)
    }
  }, [pressedKey])

  const getSampleMeterings = (data) => {
    if (!selectedAddress?.id) return

    if (lastSampleMeteringRequest) {
      lastSampleMeteringRequest.abort()
    }

    const {promise, controller} = meteringsEndpoint.getSampleMetering(data, selectedAddress.id, intl)

    setLastSampleMeteringRequest(controller)

    promise
      .then(res => {
        const firstPoint = res.point[0]
        const mainXAxis = firstPoint
          ? !!data.mode
            ? firstPoint.compare.x_month
            : firstPoint.array_main_xAxis
          : []

        const resDetailedXAxis = firstPoint
          ? firstPoint.array_detailed_xAxis.map((data => {
            const parts = data.split(' \n ')

            if (parts.length > 1) {
              return  `<span>${parts[0]}<br>${parts[1]}</span>`
            } else {
              return `<span>${parts[0]}</span>`
            }
          }))
          : []

        setPointData(res.point)
        setMeteringXAxis(mainXAxis)
        setDetailedAxis(resDetailedXAxis)

        setLastSampleMeteringRequest(null)

        setChangeOnlyDetailed(false)
      })
      .catch(err => {
        if (err.name === 'AbortError') return

        // setMessage({
        //   message: <FormattedMessage id={'requests.getMeteringsError'}/>,
        //   header: <FormattedMessage id={'requests.error'}/>,
        //   type: 'error',
        //   count: 3000
        // })

        setLastSampleMeteringRequest(null)
        console.log('err', err)
      })
  }

  function handleSelectColumn () {
    setChangeOnlyDetailed(true)

    const value = sampleData.displayMode === 1
      ? parseInt(this.category)
      : this.category

    setPointValue(value)
  }

  const handleChangePointValue = (value) => {
    if ((sampleData.pointValue == meteringXAxis[0] && value < 0) || (sampleData.pointValue == meteringXAxis[meteringXAxis.length - 1] && value > 0)) return

    switch (sampleData.displayMode) {
      case 1:
        setChangeOnlyDetailed(true)

        setPointValue(sampleData.pointValue + value)
        break
      case 2:
        setChangeOnlyDetailed(true)

        const currentMonthKey = Object.keys(SHORT_MONTH_NAME).find(key => getShortMontName(intl, key) === sampleData.pointValue)
        const newKey = +currentMonthKey + value

        setPointValue(getShortMontName(intl, newKey))
        break
      case 3:
        setChangeOnlyDetailed(true)
        setPointValue(sampleData.pointValue + value)
        break
      default:
        throw Error('unknown display chart mode')
    }
  }

  const handlePressArrows = (e) => setPressedKey(e.key)

  const handleScrollContainer = (e) => {
    e.persist()

    if (e.target.scrollTop > 20) {
      setIsFadeAddressSelect(true)
    } else {
      setIsFadeAddressSelect(false)
    }
  }

  return (
    <div className='intake' onKeyPress={handlePressArrows}>
      <Loader
        loading={isLoadingMeterings}
        styles={{
          wrapper: (base) => ({
            ...base,
            width: '100%',
            display: 'flex'
          }),
        }}
      >
        <div className="intake__left" onScroll={handleScrollContainer}>
          <div className="intake__left-container">
            <div className="intake__left-main">
              <h2 className="title_h1">
                <FormattedMessage id={'intake.consumption'}/>
              </h2>

              <ResourcesList />

              <div className="intake__chart-type form-group">
                <label>
                  <FormattedMessage id={'intake.chartType'}/>
                </label>

                <SelectComponent
                  optionsList={CHART_TYPES_LIST}
                  value={CHART_TYPES_LIST.find(item => item.value === selectedChartType)}
                  handleChange={(e) => {
                    if (!!sampleData.mode) return

                    setSelectedChartType(e.value)
                  }}
                  disabled={!!sampleData.mode}
                />
              </div>

              <ChartSettings getSampleMeterings={getSampleMeterings}/>

              <Chart
                xAxis={meteringXAxis}
                chartType={selectedChartType}
                isLoadingMeterings={isLoadingMeterings}
                changeOnlyDetailed={changeOnlyDetailed}
                isLoading={!!lastSampleMeteringRequest}
                pointData={pointData}
                handleSelectColumn={handleSelectColumn}
              />

              <MeteringPointsList />
            </div>

            <PointsTable />
          </div>
        </div>

        <DetailedChart
          isLoading={!!lastSampleMeteringRequest}
          isLoadingMeterings={isLoadingMeterings}
          pointData={pointData}
          meteringXAxis={meteringXAxis}
          xAxis={detailedXAxis}
          handleChangePointValue={handleChangePointValue}
        />
      </Loader>
    </div>
  )
}

export default Intake
