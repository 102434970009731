import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'meteringPointForm'
const translations = {
  newPoint: 'Новая точка учета',
  pointParams: 'Параметры точки учета',
  dontForget: 'Чтобы не забыть, давайте названия по месту установки - Кухня, Ванная, Тамбур...',
  name: 'Название',
  resourceType: 'Тип ресурса',
  systemDevice: 'Системный прибор',
  device: 'Прибор',
  channel: 'Канал',
  primaryDevice: 'ПЕРВИЧНЫЙ ПРИБОР (ПРИБОР УЧЕТА)',
  deviceName: 'Наименование',
  serial: 'Серийный номер',
  date: 'Дата будущей поверки',
  siNumber: 'Номер в реестре СИ РФ',
  unitType: 'Единица измерения',
  value: 'Показания',
  onDate: 'На дату',
  time: 'Время',
  fillWhenConnecting: 'Заполните при подключении счетчика, или\xa0обновите для коррекции показаний',
  impulseSettings: 'Настройка Импульсов',
  impulses: 'Импульсы',
  resource: 'Ресурс',
  'deletePoint?': 'Удалить точку учета?',
  pulseChannelOne: 'Импульсный канал 1',
  pulseChannelTwo: 'Импульсный канал 2',
}

export const meteringPointFormRu = createTranslationsWithSpaceName(translations, spaceName)