import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {INIT_ANALYTIC_NAME_DATA, INIT_ANALYTIC_NAME_VALIDATIONS} from "../const";
import {getAnalyticNameValidators} from "@/internal/validators/formValidators";

const changeNameFormModel = new FormModelFabric({
  initialData: INIT_ANALYTIC_NAME_DATA,
  initialValidations: INIT_ANALYTIC_NAME_VALIDATIONS,
  getValidators: getAnalyticNameValidators
})

export const changeNameFormSelectors = changeNameFormModel.createSelectors()
