import {Edit} from "@mui/icons-material";
import HoveredPopover from "@/presentation/components/common/HoveredPopover";
import {FormattedMessage} from "react-intl";
import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import {analyticsEditAddressShortFormSelectors} from "@/internal/lib/storeModels/models/analytics/forms/addressFormModels";
import AddressModalForm from "@/presentation/components/addressForm/AddressModalForm";
import React, {useCallback, useState} from "react";
import {addressEndpoint} from "@/data/api_entities/address/address_endpoint";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import {selectors} from "@/presentation/shared/ui/message";

const EditAddressButton = ({addressId}) => {
  const [isOpenModal, toggleIsOpenModal] = useToggle(false)
  const [isLoading, setIsLoading] = useState(false)
  const [lastGetAddressInfoRequest, setLastGetAddressInfoRequest] = useState(null)
  const [isFirstLoading, setFirstIsLoading] = useState(false)
  const [tariffsHistory, setTariffsHistory] = useState([])

  const updateAddresses = addressesSelectors.addresses.useUpdateAddresses()
  const setOnValidSubmit = analyticsEditAddressShortFormSelectors.useSetOnValidSubmit()
  const onSubmit = analyticsEditAddressShortFormSelectors.useOnClickSubmit()
  const isValidForm = analyticsEditAddressShortFormSelectors.useIsFormValid()
  const setMessage = selectors.useSetMessage()
  const setInitialData = analyticsEditAddressShortFormSelectors.useSetInitFormData()
  const addressName = analyticsEditAddressShortFormSelectors.useFormDataValue('name')
  const street = analyticsEditAddressShortFormSelectors.useFormDataValue('street')
  const building = analyticsEditAddressShortFormSelectors.useFormDataValue('building')
  const setFormValue = (key) => analyticsEditAddressShortFormSelectors.setFormDataValue(key)

  const getAddressInfo = () => {
    if (!addressId) return

    if (lastGetAddressInfoRequest) {
      lastGetAddressInfoRequest.abort()
    }

    const {promise, controller} = addressEndpoint.getAddressById(addressId)

    setLastGetAddressInfoRequest(controller)

    promise
      .then(res => {
        if (!res) return

        setInitialData({
          name: res.name,
          area: res.area,
          geo_len: res.geo_len,
          geo_lat: res.geo_lat,
          tariff: res.tariff,
          region: res.region,
          city: res.city,
          street: res.street,
          building: res.building,
          object_type: res.object_type,
          apartment: res.apartment,
          residents: res.residents || '',
          currency_type: res.currency_type ?? 1,
          unit_type: res.unit_type ?? 1
        })

        setTariffsHistory(res.tariffs_history ?? [])
        setLastGetAddressInfoRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return

        setLastGetAddressInfoRequest(null)
        console.log(err)
      })
      .finally(() => {
        if (isFirstLoading) setFirstIsLoading(false)
      })
  }

  const saveChanges = (data) => {
    setIsLoading(true)

    const {promise} = addressEndpoint.changeAddressInfo({
      ...data,
      id: addressId
    })

    promise
      .then((res) => {
        if (res.status === 'error') {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: <FormattedMessage id={'requests.changingObjectError'} values={{name: data.name}}/>,
            type: 'error',
            count: 3000
          })
        } else {
          updateAddresses()
          toggleIsOpenModal()

          setMessage({
            header: <FormattedMessage id={'requests.changingObjectSuccessHeader'}/>,
            message: <FormattedMessage id={'requests.changingObjectSuccess'}/>,
            count: 3000
          })
        }
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.changingObjectError'} values={{name: data.name}}/>,
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
      .finally(() => setIsLoading(false))
  }

  const onClickSubmit = () => {
    setOnValidSubmit(saveChanges)
    if (!addressName) {
      setFormValue('name')(`${street} ${building}`)
    }

    if (!isValidForm) return

    onSubmit()
  }

  return (
    <>
      <div className="tables__icon-wrapper">
        <HoveredPopover
          text={<FormattedMessage id={'analytics.editObject'}/>}
        >
          <div
            className="tables__icon"
            onClick={toggleIsOpenModal}
          >
            <Edit />
          </div>
        </HoveredPopover>
      </div>

      <DefaultModal
        loading={isFirstLoading || !!lastGetAddressInfoRequest || isLoading}
        isOpen={isOpenModal}
        toggleModal={toggleIsOpenModal}
        agreeBtnConfig={{
          text: <FormattedMessage id={'app.save'}/>,
          className: 'btn'
        }}
        headerTitle={<FormattedMessage id={'analytics.editingObject'}/>}
        bodyText={
          <AddressModalForm
            formModelSelectors={analyticsEditAddressShortFormSelectors}
            getAddressInfo={getAddressInfo}
            tariffsHistory={tariffsHistory}
          />
        }
        onAgree={onClickSubmit}
      />
    </>

  )
}

export default EditAddressButton
