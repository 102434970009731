import {request} from "@/internal/lib/transport/request";

export const authorizationEndpoint = {
  signIn (data) {
    const params = {
      url: 'rest-auth/login/',
      method: 'POST',
      isAuth: false,
      data,
    }

    return request(params).promise
  },

  sendResetPasswordMail (data) {
    const params = {
      url: 'api/anon/forgot_password/',
      method: 'POST',
      isAuth: false,
      data,
    }

    return request(params).promise
  },

  checkConfirmationCode (code) {
    const params = {
      url: `api/anon/confirm_user/?code=${code}`,
      method: 'GET',
      isAuth: false,
    }

    return request(params).promise
  },

  recoveryPassword (data, token) {
    const params = {
      url: 'rest-auth/password/change/',
      method: 'POST',
      isAuth: false,
      token,
      data
    }

    return request(params).promise
  },

  registerNewUser (data) {
    const params = {
      url: 'api/anon/create_user/',
      method: 'POST',
      isAuth: false,
      data,
    }

    return request(params).promise
  }
}
