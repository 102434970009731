import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import Loader from "@/presentation/components/loader/Loader";
import {vectoSelect} from "@/assets/svg";
import {sampleParamsFormSelectors} from "@/internal/lib/storeModels/models/intake/forms/sampleParamsFormModel";
import {intakeSelectors} from "@/internal/lib/storeModels/models/intake/intakeModels";
import {useEffect, useRef, useState} from "react";
import {RESOURCES_COLORS, RESOURCES_TYPES} from "@/internal/content/consts";
import useWindowSize from "@/internal/custom_hooks/windowResizeHook";
import {FormattedMessage, useIntl} from "react-intl";

const DetailedChart = (
  {
    xAxis,
    pointData,
    handleChangePointValue,
    meteringXAxis,
    isLoading,
    isLoadingMeterings
  }) => {
  const intl = useIntl()
  const detailedChartWrapper = useRef()
  const size = useWindowSize()

  const mode = sampleParamsFormSelectors.useFormDataValue('mode')
  const pointValue = sampleParamsFormSelectors.useFormDataValue('pointValue')
  const displayMode = sampleParamsFormSelectors.useFormDataValue('displayMode')
  const detailedChartName = intakeSelectors.detailedChartName.useValue()
  const selectedPoints = sampleParamsFormSelectors.useFormDataValue('pointsIds')

  const [periodInfo, setPeriodInfo] = useState([])
  const [pointsDetailedChartData, setPointsDetailedChartData] = useState([])
  const [maxDetailedChartHeight, setMaxDetailedChartHeight] = useState(null)

  useEffect(() => {
    if (!detailedChartWrapper.current) return

    const height = window.innerHeight - detailedChartWrapper.current.getBoundingClientRect().top

    setMaxDetailedChartHeight(height)
  }, [detailedChartWrapper, mode, isLoading, size])

  useEffect(() => {
    const selectedPointsParams = pointData
      .filter(point => selectedPoints.includes(point.point_id))

    const periodsInfo = selectedPointsParams
      .map(point => ({
        endPeriod: point.end_period,
        startPeriod: point.start_period,
        increment: point.increment
      }))

    setPeriodInfo(periodsInfo)

    const detailedData = selectedPointsParams
      .map(point => {
        return {
          name: intl.formatMessage({id: RESOURCES_TYPES[point.type_resource].nameId}),
          data: point.array_detailed_data,
          color: RESOURCES_COLORS[point.type_resource].color,
          borderRadius: 4,
          maxPointWidth: 6,
        }
      })

    setPointsDetailedChartData(detailedData)
  }, [pointData])

  const options = {
    chart: {
      type: 'bar',
      height: displayMode === 1
        ? 24 * 48
        : displayMode === 2
          ? 31 * 48
          : 12 * 48,
      spacing: [1, 0, 60, 0],
    },
    title: {
      text: ''
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: pointsDetailedChartData,
    xAxis: {
      categories: xAxis,
      crosshair: true,
      lineColor: '',
      gridLineWidth: 1,
      gridLineColor: '#ECF0F6',
      tickWidth: 1,
      tickLength: 24,
      tickColor: '#ECF0F6',
      labels: {
        x: 0,
        align: 'left',
        style: {
          color: '#727A86',
          fontSize: '.75rem',
        }
      },
    },
    yAxis: {
      gridLineColor: '',
      labels: {
        enabled: false
      },
      title: '',
      min: -0.0001
    },
    plotOptions: {
      bar: {
        dataLabels: {
          allowOverlap: true,
          enabled: true,
          useHTML: true,
          style: {
            fontSize: '.75rem',
            fontWeight: '500',
            color: '#36455D',
            lineHeight: '133%',
            fontFamily: '"Roboto", sans-serif'
          },
          x: 4,
          formatter: function(){
            return `<span style="position: relative; left: ${this.y ? 0 : '-10px'}">${this.y}</span>`
          },
        }
      }
    }
  }

  if (!!mode) return null

  return (
    <div className="intake__right">
      <Loader loading={!isLoadingMeterings && isLoading}>
        <div className="intake__wrapper">
          <div className="intake__right-header">
            <h2 className="title">
              {detailedChartName}
            </h2>

            <div className="intake__right-controls">
              <button
                className="intake__control-btn intake__control-btn_prev"
                onClick={() => handleChangePointValue(-1)}
                disabled={meteringXAxis[0] == pointValue}
              >
                <img src={vectoSelect} alt="vector_back"/>
              </button>

              <button
                className="intake__control-btn intake__control-btn_next"
                onClick={() => handleChangePointValue(1)}
                disabled={meteringXAxis[meteringXAxis.length - 1] == pointValue}
              >
                <img src={vectoSelect} alt="vector_next"/>
              </button>
            </div>
          </div>

          <div className="intake__info">
            <div className="intake__info-block">
              <span>
                <FormattedMessage id={'intake.startPeriod'}/>
              </span>

              <p>
                {periodInfo.map((point, i) => (
                  <span key={i}>
                    {point.startPeriod}&nbsp;<FormattedMessage id={'intake.m'}/><sup>3</sup>{i !== periodInfo.length - 1 ? ' / ' : ''}
                  </span>
                ))}
              </p>
            </div>

            <div className="intake__info-block">
              <span>
                <FormattedMessage id={'intake.endPeriod'}/>
              </span>

              <p>
                {periodInfo.map((point, i) => (
                  <span key={i}>
                    {point.endPeriod}&nbsp;<FormattedMessage id={'intake.m'}/><sup>3</sup>{i !== periodInfo.length - 1 ? ' / ' : ''}
                  </span>
                ))}
              </p>
            </div>

            <div className="intake__info-block">
              <span>
                <FormattedMessage id={'intake.increment'}/>
              </span>

              <p>
                {periodInfo.map((point, i) => (
                  <span key={i}>
                    {point.increment}&nbsp;<FormattedMessage id={'intake.m'}/><sup>3</sup>{i !== periodInfo.length - 1 ? ' / ' : ''}
                  </span>
                ))}
              </p>
            </div>
          </div>
        </div>

        <div
          className="intake__detailed-chart"
          ref={detailedChartWrapper}
          style={{
            maxHeight: maxDetailedChartHeight
          }}
        >
          <div className='detailed-chart'>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
            />
          </div>
        </div>
      </Loader>
    </div>
  )
}

export default DetailedChart
