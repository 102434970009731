import {useNavigate, useParams, Routes, Route, Link} from "react-router-dom";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {analyticsEndpoint} from "@/data/api_entities/analytics/analytics_endpoint";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {
  toggleTablesSelectors,
} from "@/internal/lib/storeModels/models/analytics/tablesModalModel";
import Tables from "./Tables";
import TablesModal from "./TablesModal";
import Loader from "@/presentation/components/loader/Loader";
import {selectors} from "@/presentation/shared/ui/message";
import {analyticsSelectors} from "@/internal/lib/storeModels/models/analytics/analyticsModels";
import { Delete, Edit} from '@mui/icons-material'
import SelectButton from "@/presentation/components/common/selects/SelectButton";
import {BY_TEMPLATE_TYPE, CHANGE_ANALYTIC_NAME_PATH, MAX_SHOWN_ADDRESSES_COUNT, NEW_ANALYTIC_TYPES_LIST} from "./const";
import { addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import HoveredPopover from "@/presentation/components/common/HoveredPopover";
import ChangeAnalyticName from "./ChangeAnalyticName";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import {analyticsListSelectors} from "@/internal/lib/storeModels/models/analytics/analyticsListModel";
import {FormattedDate, FormattedMessage} from "react-intl";
import {managerAddressesSelectors} from "@/internal/lib/storeModels/models/servicemen/stores";

const AnalyticInfo = ({getInfo = null}) => {
  const {id} = useParams()
  const navigate = useNavigate()

  const userAddresses = addressesSelectors.addresses.useValue()
  const managerAddresses = managerAddressesSelectors.useValue()
  const addresses = managerAddresses ?? userAddresses
  const updateAnalyticsList = analyticsListSelectors.list.useUpdateAnalyticsList()
  const isOpenEditTable = toggleTablesSelectors.editTables.useValue()
  const toggleIsOpenEditTable = toggleTablesSelectors.editTables.useToggleValue()
  const isOpenResultTable = toggleTablesSelectors.resultTables.useValue()
  const toggleIsOpenResultTable = toggleTablesSelectors.resultTables.useToggleValue()
  const isPaid = analyticsSelectors.isPaidAnalytic.useValue()
  const setAnalyticId = analyticsSelectors.analyticId.useSetValue()
  const setMessage = selectors.useSetMessage()

  const [data, setData] = useState(null)
  const [lastGetDataRequest, setLastGetDataRequest] = useState(null)
  const [newAnalysisType, setNewAnalysisType] = useState(BY_TEMPLATE_TYPE)
  const [isOpenNewTable, setIsOpenNewTable] = useState(false)
  const [showFullAddressesList, setShowFullAddressesList] = useState(false)
  const [isOpenDeleteModal, toggleDeleteModal] = useToggle()
  const [isOpenCloseSubmitModal, toggleSubmitModal, setIsOpenCloseSubmitModal] = useToggle()
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const getAnalyticInfo = useCallback(() => {
    if (!id) return

    if (lastGetDataRequest) {
      lastGetDataRequest.abort()
    }

    setShowFullAddressesList(false)

    const {promise, controller} = analyticsEndpoint.getAnalytic(id)

    setLastGetDataRequest(controller)

    promise
      .then(res => {
        if (res.detail) {
          navigate('analytics')
        } else {
          setData(res)
        }

        setLastGetDataRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return

        console.log(err)
        navigate('analytics')
        setLastGetDataRequest(null)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.getAnalyticDataError'}/>,
          type: 'error',
          count: 3000
        })
      })
  }, [id, lastGetDataRequest])

  useEffect(() => {
    return () => {
      if (lastGetDataRequest) {
        lastGetDataRequest.abort()
      }
    }
  }, [lastGetDataRequest])

  useEffect(() => {
    getAnalyticInfo()
  }, [id])

  const toggleNewTable = () => {
    if (isOpenNewTable && !isPaid) {
      setIsOpenCloseSubmitModal(true)
    } else {
      setIsOpenNewTable(!isOpenNewTable)
    }
  }

  const handleAgreeCloseModal = () => {
    toggleSubmitModal()
    setIsOpenNewTable(!isOpenNewTable)
  }

  const analyticAddressesIds = useMemo(() => {
    if (!data) return []

    return data.object.map(address => +address.id)
  }, [data])

  const newAnalysisTypeOption = useMemo(() => {
    return NEW_ANALYTIC_TYPES_LIST.find(type => type.value === newAnalysisType)
  }, [newAnalysisType])

  const removedAddressesIds = useMemo(() => {
    return analyticAddressesIds.filter(id => !addresses.find(address => address.id == id))
  }, [analyticAddressesIds, addresses])

  const isEditDisabled = useMemo(() => {
    return !!removedAddressesIds.length
  }, [removedAddressesIds])

  const interval = useMemo(() => {
    if (!data) return '-'

    return (
      <>
        <FormattedDate value={data.start_date} month={'long'} year={'numeric'}/> - <FormattedDate value={data.end_date} month={'long'} year={'numeric'}/>
      </>
    )
  }, [data])

  const analyticData = useMemo(() => {
    if (!data) return null

    return {
      ...data,
      newAnalysisType,
    }
  }, [data, newAnalysisType])

  const addressesList = useMemo(() => {
    if (!data) return []

    return data.object.map(address => {
      return (
        <div key={address.id}>
          <span className={removedAddressesIds.includes(address.id) ? 'deleted' : ''}>{address.name}</span>
        </div>
      )
    })
  }, [data, removedAddressesIds])

  const shownAddressesList = useMemo(() => {
    if (!data) return '-'

    if (showFullAddressesList) {
      return (
        <>
          {addressesList}
          <span
            className={'analytic-info__more-objects'}
            onClick={() => setShowFullAddressesList(false)}
          >
            <FormattedMessage id={'analytics.hide'}/>
          </span>
        </>
      )
    } else {
      return (
        <>
          {addressesList.slice(0, MAX_SHOWN_ADDRESSES_COUNT)}
          {addressesList.length - MAX_SHOWN_ADDRESSES_COUNT > 0 &&
            <span
              className={'analytic-info__more-objects'}
              onClick={() => setShowFullAddressesList(true)}
            >
              <FormattedMessage
                id={'analytics.andMore'}
                values={{
                  objectsCount: addressesList.length - MAX_SHOWN_ADDRESSES_COUNT
                }}
              />
            </span>
          }
        </>
      )
    }
  }, [showFullAddressesList, addressesList])

  const onDeleteAnalytic = (id) => {
    setIsDeleteLoading(true)

    analyticsEndpoint.deleteAnalytic(id).promise
      .then(() => {
        (getInfo ?? updateAnalyticsList)()
        navigate('analytics')

        setMessage({
          header: <FormattedMessage id={'requests.deleteAnalyticSuccessHeader'}/>,
          message: <FormattedMessage id={'requests.deleteAnalyticSuccess'}/>,
          count: 3000
        })
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.deleteAnalyticError'}/>,
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsDeleteLoading(false))
  }

  const handleCloseEditTable = () => {
    getAnalyticInfo()
    toggleIsOpenEditTable()
  }

  const handleOpenResultTable = () => {
    setAnalyticId(+id)
    toggleIsOpenResultTable()
  }

  const handleOpenEditTable = () => {
    if (isEditDisabled) return

    setAnalyticId(+id)
    toggleIsOpenEditTable()
  }

  return (
    <>
      <div className={'analytic-info'}>
        <ArrowBack
          pathOnVisible={'analytics'}
          withId
        />

        <Loader loading={!!lastGetDataRequest || isDeleteLoading || !data}>
          <div className="analytic-info__title-wrapper">
            <h2 className="title">
              {data?.name ?? '-'}

              <HoveredPopover
                text={<FormattedMessage id={'app.change'}/>}
              >
                <Link to={CHANGE_ANALYTIC_NAME_PATH}>
                  <Edit />
                </Link>
              </HoveredPopover>
            </h2>

            <HoveredPopover text={<FormattedMessage id={'app.delete'}/>}>
              <Delete
                className={'analytic-info__delete'}
                onClick={toggleDeleteModal}
              />
            </HoveredPopover>
          </div>

          <hr/>

          <div className="analytic-info__input-info">
            <h3 className={'title_h3'}>
              <FormattedMessage id={'analytics.period'}/>
            </h3>
            <span>{interval}</span>
          </div>

          <div className="analytic-info__input-info">
            <h3 className={'title_h3'}>
              <FormattedMessage id={'analytics.step'}/>
            </h3>
            <span>
              <FormattedMessage id={'analytics.month'}/>
            </span>
          </div>

          <div className="analytic-info__input-info">
            <h3 className={'title_h3'}>
              <FormattedMessage id={'analytics.accountingObjects'}/>
            </h3>

            {shownAddressesList}
          </div>

          <div className="analytic-info__btns-wrapper">
            <button
              className="btn btn_transparent-bg"
              onClick={handleOpenResultTable}
            >
              <FormattedMessage id={'analytics.report'}/>
            </button>

            <button
              className="btn btn_transparent-bg"
              onClick={handleOpenEditTable}
              disabled={isEditDisabled}
            >
              <FormattedMessage id={'analytics.edit'}/>
            </button>
          </div>

          <hr/>

          <SelectButton
            optionsList={NEW_ANALYTIC_TYPES_LIST}
            value={newAnalysisTypeOption}
            onChange={(e) => {
              setNewAnalysisType(e.value)
              toggleNewTable()
            }}
            onClickBtn={toggleNewTable}
          >
            <FormattedMessage id={'analytics.createReport'}/>
          </SelectButton>

          {isOpenEditTable &&
            <TablesModal hideModal={handleCloseEditTable}>
              <Tables
                getInfo={getInfo}
                toggleModal={handleCloseEditTable}
                editAnalyticId={data?.id}
                editData={analyticData}
              />
            </TablesModal>
          }

          {isOpenResultTable &&
            <TablesModal hideModal={toggleIsOpenResultTable}>
              <Tables
                getInfo={getInfo}
                toggleModal={toggleIsOpenResultTable}
                startResultData={analyticData}
                onlyResult
              />
            </TablesModal>
          }

          {
            isOpenNewTable &&
            <TablesModal
              hideModal={toggleNewTable}
              isOpenCloseSubmitModal={isOpenCloseSubmitModal}
              toggleSubmitCloseModal={toggleSubmitModal}
              handleAgreeSubmitCloseModal={handleAgreeCloseModal}
            >
              <Tables
                getInfo={getInfo}
                toggleModal={toggleNewTable}
                completedData={analyticData}
              />
            </TablesModal>
          }
        </Loader>

        <DefaultModal
          isOpen={isOpenDeleteModal}
          headerTitle={<FormattedMessage id={'analytics.deleteReport?'}/>}
          agreeBtnConfig={{
            className: 'btn btn_red',
            text: <FormattedMessage id={'app.delete'}/>
          }}
          toggleModal={toggleDeleteModal}
          onDisagree={toggleDeleteModal}
          onAgree={() => onDeleteAnalytic(data?.id)}
          loading={isDeleteLoading}
        />
      </div>

      <Routes>
        <Route
          path={CHANGE_ANALYTIC_NAME_PATH}
          element={<ChangeAnalyticName initName={data?.name} id={id} getAnalyticInfo={getAnalyticInfo} getInfo={getInfo}/>}
        />
      </Routes>
    </>
  )
}

export default AnalyticInfo