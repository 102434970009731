import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";
import {createEffect, createEvent, sample} from "effector";
import {reportsEndpoint} from "@/data/api_entities/reports/reports_endpoint";
import {setMessage} from "@/presentation/shared/ui/message";
import {useEvent} from "effector-react";

const reportsListModel = new StoreModelFabric([])
const isLoadingReportsListModel = new StoreModelFabric(true)
const reportLoadingModel = new StoreModelFabric(false)
const updateReportsList = createEvent()

reportsListModel.getList = createEffect(() => {
  isLoadingReportsListModel.setState(true)

  const {promise} = reportsEndpoint.getAll()

  promise
    .then(res => {
      if (Array.isArray(res?.results)) {
        reportsListModel.setState(res.results)
      } else {
        reportsListModel.resetStore()
        console.log('result is not array', res)
        setMessage({
          header: 'Ошибка!',
          message: 'Не удалось получить список отчетов',
          type: 'error',
          count: 3000
        })
      }
    })
    .catch(err => {
      console.log(err)
      setMessage({
        header: 'Ошибка!',
        message: 'Не удалось получить список отчетов',
        type: 'error',
        count: 3000
      })
    })
    .finally(() => isLoadingReportsListModel.setState(false))
})

sample({
  clock: updateReportsList,
  target: reportsListModel.getList
})

export const reportsPageSelectors = {
  reportsList: {
    ...reportsListModel.createSelectors(),
    useGetReports: () => useEvent(reportsListModel.getList),
    useUpdateReportsList: () => useEvent(updateReportsList)
  },
  isLoadingReportsList: isLoadingReportsListModel.createSelectors(),
  reportLoading: reportLoadingModel.createSelectors()
}