import {NavLink, Route, Routes, useNavigate} from "react-router-dom";
import {useCallback, useEffect} from "react";
import {NEW_DEVICE_PATH} from "./const";
import DeviceForm from "../deviceForm/DeviceForm";
import {devicesEndpoint} from "@/data/api_entities/devices/devices_endpoint";
import {selectors} from "@/presentation/shared/ui/message";
import DeviceInfo from "./components/DeviceInfo";
import {devicesSelectors} from "@/internal/lib/storeModels/models/devices/devicesModel";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import {statusDeviceSelectors} from "@/internal/lib/storeModels/models/status/statusDeviceFormModel";
import {devicesNewDeviceFormSelectors} from "@/internal/lib/storeModels/models/devices/forms/deviceFormModels";
import {devicePageSelectors} from "@/internal/lib/storeModels/models/devices/devicePageModels";
import DevicesList from "./components/DevicesList";
import {FormattedMessage} from "react-intl";
import {responseHandler} from "@/internal/lib/transport/response_handler";

const DevicesPage = () => {
  const navigate = useNavigate()

  const getDevicesList = devicePageSelectors.devicesList.useGetDevicesList()
  const resetDevicesList = devicePageSelectors.devicesList.useReset()
  const resetIsFirstLoading = devicePageSelectors.isFirstLoading.useReset()
  const [lastGetDevicesRequest, setLastGetDevicesRequest] = devicePageSelectors.lastGetDevicesRequest.useState()
  const selectedAddress = addressesSelectors.selectedAddress.useValue()
  const updateAddresses = addressesSelectors.addresses.useUpdateAddresses()
  const updateDevices = devicesSelectors.devices.useUpdateDevices()
  const setIsDeviceCreated = statusDeviceSelectors.useSetValue()
  const [deviceRequestLoading, setDeviceRequestLoading] = devicePageSelectors.deviceRequestLoading.useState()
  const setMessage = selectors.useSetMessage()
  const resetForm = devicesNewDeviceFormSelectors.useResetForm()

  useEffect(() => {
    return () => {
      resetDevicesList()
      resetIsFirstLoading()

      if (lastGetDevicesRequest) {
        lastGetDevicesRequest.abort()
        setLastGetDevicesRequest(null)
      }
    }
  }, [])

  // useEffect(() => {
  //   navigate('device')
  // }, [selectedAddress?.id])

  const onAddNewDevice = useCallback((data) => {
    setDeviceRequestLoading(true)

    const {promise} = devicesEndpoint.addNewDevice(data, selectedAddress.id)

    promise
      .then(res => {
        responseHandler(
          res,
          {
            ok: {
              callback: () => {
                getDevicesList()
                updateDevices()
                updateAddresses()
                setIsDeviceCreated(true)

                resetForm()
              },
              header: <FormattedMessage id={'requests.addDeviceSuccessHeader'}/>,
              message: <FormattedMessage id={'requests.addDeviceSuccess'}/>
            },
            error: {
              message: <FormattedMessage id={'requests.addDeviceError'}/>
            }
          }
        )
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.addDeviceError'}/>,
          type: 'error',
          count: 3000
        })
      })
      .finally(() => {
        setDeviceRequestLoading(false)
      })
  }, [selectedAddress])

  return (
    <div className={'devices-page'}>
      <div className="devices-page__column">
        <div className="devices-page__title-wrapper">
          <h2 className="title">
            <FormattedMessage id={'devicesPage.equipment'}/>
          </h2>

          <NavLink
            className={`btn btn_transparent-bg`}
            to={NEW_DEVICE_PATH}
          >
            +
          </NavLink>
        </div>

       <DevicesList />
      </div>

      <Routes>
        <Route
          path={NEW_DEVICE_PATH}
          element={
            <DeviceForm
              formModelSelectors={devicesNewDeviceFormSelectors}
              onValidSubmit={onAddNewDevice}
              loading={deviceRequestLoading}
              pathOnVisible={NEW_DEVICE_PATH}
            />
          }
        />

        <Route
          path={':id/*'}
          element={
            <DeviceInfo
              onAddNewDevice={onAddNewDevice}
            />
          }
        />
      </Routes>
    </div>
  )
}

export default DevicesPage
