import {FormattedMessage} from "react-intl";

const PasswordRules = ({passwordValidations}) => {
  return (
    <div className="password-rules">
      <span>
        <FormattedMessage id={'passwordRules.passwordRequirements'}/>
      </span>

      <ul className="password-rules__list">
        <li className={passwordValidations.hasNumber ? 'password-rules__checked' : ''}>
          <FormattedMessage id={'passwordRules.number'}/>
        </li>
        <li className={passwordValidations.hasSmallCase ? 'password-rules__checked' : ''}>
          <FormattedMessage id={'passwordRules.lowercaseLatinLetter'}/>
        </li>
        <li className={passwordValidations.hasUpperCase ? 'password-rules__checked' : ''}>
          <FormattedMessage id={'passwordRules.uppercaseLatinLetter'}/>
        </li>
        <li className={passwordValidations.hasEightCharacters ? 'password-rules__checked' : ''}>
          <FormattedMessage id={'passwordRules.eightSymbols'}/>
        </li>
        <li className={passwordValidations.hasSpecial ? 'password-rules__checked' : ''}>
          <FormattedMessage id={'passwordRules.specialSymbols'}/>
        </li>
      </ul>
    </div>
  )
}

export default PasswordRules
