import A_pen from './A_pen.svg'
import Battery from './Battery.svg'
import BatteryRed from './BatteryRed.svg'
import BatteryYellow from './BatteryYellow.svg'
import BatteryGrey from './BatteryGrey.svg'
import BroadcastBold from './BroadcastBold.svg'
import BroadcastIcon from './BroadcastIcon.svg'
import Check from './Check.svg'
import ColdWater from './ColdWater.svg'
import CommunicationIndicator from './CommunicationIndicator.svg'
import ConnectionGrey from './ConnectionGrey.svg'
import ConnectionGreen from './ConnectionGreen.svg'
import ConnectionGree from './ConnectionGree.svg'
import ConnectionRed from './ConnectionRed.svg'
import ConnectionYellow from './ConnectionYellow.svg'
import ConsumptionBold from './ConsumptionBold.svg'
import ConsumptionIcon from './ConsumptionIcon.svg'
import EquipmentBold from './EquipmentBold.svg'
import EquipmentIcon from './EquipmentIcon.svg'
import EventsIcon from './EventsIcon.svg'
import EventsBold from './EventsBold.svg'
import ElectroEnergy from './ElectroEnergy.svg'
import HotWater from './HotWater.svg'
import HederEquipment from './HederEquipment.svg'
import MainContent from './MainContent.svg'
import Message from './Message.svg'
import RedElips from './RedElips.svg'
import StatusIcon from './StatusIcon.svg'
import StatusBold from './StatusBold.svg'
import TestimonyTransmitted from './TestimonyTransmitted.svg'
import TwoArrows from './TwoArrows.svg'
import Vector from './Vector.svg'
import VectorBottom from './VectorBottom.svg'
import vectoSelect from './vectoSelect.svg'
import WattHome from './WattHome.svg'
import WattHomeBeta from './WattHomeBeta.svg'
import Xpulse from './Xpulse.svg'
import YellowElips from './YellowElips.svg'
import NoMessage from './NoMessage.svg'
import Telegram from './Telegram.svg'
import WhatsApp from './WhatsApp.svg'
import Viber from './Viber.svg'
import PlugGreen from './PlugGreen.svg'
import Elipse from "./Elipse.svg"
import ElipseGreen from "./ElipseGreen.svg"
import Demo from "./Demo.svg"
import StatusSettingsMen from "./StatusSettingsMen.svg"
import StatusSettingsHeaderRight from "./StatusSettingsHeaderRight.svg"
import StepOne from "./StepOne.svg"
import StepOneGrey from "./StepOneGrey.svg"
import StepTwo from "./StepTwo.svg"
import StepThree from "./StepThree.svg"
import StepThreeNew from "./StepThreeNew.svg"
import VectorBack from "./VectorBack.svg"
import True from "./True.svg"
import NewDeviceImg from "./NewDeviceImg.svg"
import Ruble from "./Ruble.svg"
import XWatterSmall from "./XWatterSmall.svg"
import SinhronizationNO from "./SinhronizationNO.svg"
import XWatterBig from "./XWatterBig.svg"
import VectorLink from "./VectorLink.svg"
import Calendar from "./Calendar.svg"
import RegistrationImg from "./Registration.svg"
import Letter from "./Letter.svg"
import LoadingImg from "./LoadingImg.svg"
import ImgLinkArrow from "./ImgLinkArrow.svg"
import Vector134 from "./Vector134.svg"
import Bridge from "./Bridge.svg"
import RD from "./RD.svg"
import Star from "./Star.svg"
import Search from "./Search.svg"
import Points from "./Points.svg"
import SinhronizationYes from "./SinhronizationYes.svg"
import SinhronizationNoo from "./SinhronizationNoo.svg"
import SinhronizationMid from "./SinhronizationMid.svg"
import AnalyticsMock from "./Analytics/AnalyticsMock.svg"
import Gas from "./gas_icon.svg"
import Warmly from "./warmly_icon.svg"
import Ventilation from "./ventilation_icon.svg"
import YourResurs from "./YourResurs.svg"
import GreenFork from "./GreenFork.svg"
import ReverseArrow from "./ReverseArrow.svg"
import BgMessage from "./BgMessage.svg"
import Basket from "./Basket.svg"
import AddDeviceTrue from "./AddDeviceTrue.svg"
import AddDeviceFalse from "./AddDeviceFalse.svg"
import OpenLock from "./OpenLock.svg"
import Key from "./Key.svg"
import YellowQuestion from "./YellowQuestion.svg"
import PageReturn from "./PageReturn.svg"
import SectionInDevelopment from "./SectionInDevelopment.svg"
import UserLogo from "./UserLogo.svg"
import Dropdown from './DropDown.svg'
import Close from './Close.svg'
import Alert from './Alert/alert.svg'
import WhatsAppIcon from './WhatsAppIcon.svg'
import TelegramIcon from './TelegramIcon.svg'
import Pen from './Pen.svg'
import PointsGrey from './PointsGrey.svg'
import CloseNot from './CloseNot.svg'
import DateIcon from './Date.svg'

export {
    Alert,
    UserLogo,
    SectionInDevelopment,
    PageReturn,
    YellowQuestion,
    Key,
    OpenLock,
    AddDeviceFalse,
    AddDeviceTrue,
    Basket,
    BgMessage,
    ReverseArrow,
    GreenFork,
    YourResurs,
    Ventilation,
    Warmly,
    Gas,
    AnalyticsMock,
    SinhronizationMid,
    SinhronizationNoo,
    SinhronizationYes,
    Points,
    Search,
    Star,
    RD,
    Bridge,
    Vector134,
    ImgLinkArrow,
    LoadingImg,
    Letter,
    RegistrationImg,
    Calendar,
    VectorLink,
    XWatterBig,
    SinhronizationNO,
    XWatterSmall,
    Ruble,
    NewDeviceImg,
    StepOneGrey,
    True,
    VectorBack,
    StepOne,
    StepTwo,
    StepThree,
    StepThreeNew,
    A_pen,
    Battery,
    BatteryGrey,
    BatteryRed,
    BatteryYellow,
    BroadcastBold,
    BroadcastIcon,
    Check,
    ColdWater,
    CommunicationIndicator,
    ConnectionGrey,
    ConnectionGreen,
    ConnectionGree,
    ConnectionRed,
    ConnectionYellow,
    ConsumptionBold,
    ConsumptionIcon,
    ElectroEnergy,
    EquipmentBold,
    EquipmentIcon,
    EventsIcon,
    EventsBold,
    HotWater,
    HederEquipment,
    MainContent,
    Message,
    RedElips,
    StatusIcon,
    StatusBold,
    TestimonyTransmitted,
    TwoArrows,
    Vector,
    VectorBottom,
    vectoSelect,
    WattHome,
    WattHomeBeta,
    Xpulse,
    YellowElips,
    NoMessage,
    Telegram,
    WhatsApp,
    Viber,
    PlugGreen,
    Elipse,
    ElipseGreen,
    Demo,
    StatusSettingsMen,
    StatusSettingsHeaderRight,
    Dropdown,
    Close,
    WhatsAppIcon,
    TelegramIcon,
    Pen,
    PointsGrey,
    CloseNot,
    DateIcon
}