import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'deviceForm'
const translations = {
  addingDevice: 'Hinzufügen eines Geräts',
  deviceWillBind: 'Das equipment wird an das\xa0object "{name}" gebunden. Sie können die bindung später in\xa0settings ändern.',
  selectDeviceType: 'Wählen sie die art der ausrüstung',
  serial: 'Geben sie die seriennummer ein',
  serialPlaceholder: '{start}-00001234',
  simNumber: 'Geben sie die SIM-kartennummer ein',
  deviceAdded: 'Gerät hinzugefügt',
  devicesAdded: 'Geräte hinzugefügt',
  customizeImpulse: 'Konfigurieren sie impulseingänge zum\xa0lesen und\xa0synchronisieren sie das gerät.',
  addToMasterDevice: 'Fügen Sie die Hardware hinzu, mit der {name} interagieren soll',
  address: 'Die adresse',
  tetheredDevices: 'Tetherable Geräte',
  chooseTethered: 'Wählen Sie die bereits im System registrierte Hardware aus der Liste aus und/oder fügen Sie eine neue hinzu.',
  chooseTetheredOnlyPreexist: 'Wählen Sie aus der Liste der bereits im System registrierten Hardware aus.',
  selectedDevices: 'Ausgewählte Ausrüstung:',
  addDevice: 'Gerät hinzufügen',
  toSelectDevice: 'Gerät auswählen',
  toSelectEquipment: 'Hardware auswählen'
}

export const deviceFormDe = createTranslationsWithSpaceName(translations, spaceName)
