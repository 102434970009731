import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";

const searchQueryModel = new StoreModelFabric('')
const isNewAddressModel = new StoreModelFabric(false)
const editAddressIdModel = new StoreModelFabric()

export const mainPageSelectors = {
  searchQuery: searchQueryModel.createSelectors(),
  isNewAddress: isNewAddressModel.createSelectors(),
  editAddress: editAddressIdModel.createSelectors()
}
