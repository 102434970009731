import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'analytics'
const translations = {
  analytics: 'Analytics',
  noAnalytics: 'There are no saved reports yet. You can create a new one by clicking +',
  heatConsumption: 'Heat consumption',
  created: 'created ',
  hide: 'hide',
  andMore: 'and {objectsCount, plural, =1 {{objectsCount} more object} other {{objectsCount} more objects}}',
  period: 'Period',
  step: 'Step',
  month: 'Month',
  accountingObjects: 'Accounting objects',
  report: 'Report',
  edit: 'Edit',
  createReport: 'Create report',
  'deleteReport?': 'Delete report?',
  byTemplate: 'by template',
  byContinue: 'continuation',
  objectsCount: '{count} objects',
  intakeEfficiency: 'Consumption efficiency',
  economicAnalysis: 'Economic analysis',
  economicEfficiency: 'Economic efficiency',
  returnOnInvestments: 'Return on investment',
  pivotTable: 'General information on objects',
  analysisSettings: 'Analysis setup',
  metersData: 'Meters data',
  durationDays: 'Number of days with heating',
  temperature: 'Temperature',
  objects: 'Objects',
  intake: 'Consumption, Gcal',
  totalPeriod: 'Total for\xa0period',
  temperatureWithSign: 'Temperature, ℃',
  investmentsWithCur: 'Invested, euro',
  unsavedReport: 'Unsaved report',
  'leavePage?': 'Are you sure you want to leave the page?',
  loseData: 'All data in an unsaved report will be lost.',
  continueReportName: 'Continuation of the report "{name}"',
  intakeEfficiencyAnalysis: 'Heat consumption efficiency analysis',
  analysis: 'Analysis',
  temperatureData: 'Temperature data',
  noAccessToService: 'At the moment there is no access to the service with temperature data.',
  manuallyTemperature: 'For calculations, please enter the missing data manually.',
  investments: 'Investments',
  newReport: 'New report',
  name: 'Name',
  chooseObjects: 'Select accounting objects',
  analysisPeriod: 'Analysis period',
  analysisStep: 'Analysis step',
  from: 'From',
  to: 'To',
  periodDisplay: 'Period display',
  calendarYear: 'January - December',
  heatYear: 'July - June',
  applyValuesForAllObjects: 'Apply values for all objects',
  temperatureBy: 'Temperature from the Weatherbit resource',
  manualTemp: 'User data',
  synchronize: 'Synchronize',
  tempSync: 'temperature synchronization',
  tempSyncWarning: 'Synchronization of temperature with the Weatherbit resource. Possible loss of data entered manually!',
  reportName: 'Report title',
  bestIntakeAverages: 'The best average consumption values, Gcal/m<sup>2</sup>*С° per day:',
  byObjects: 'By user objects:',
  bySystem: 'By system:',
  byRegion: 'By region:',
  objectType: 'Object type',
  tariff: 'Tariff, rub',
  payments: 'Payments for heating, rub',
  totalOfPeriod: 'Total for\xa0period',
  specificConsumption: 'Specific consumption, {unit}/m<sup>2</sup>*С° per day',
  overpayments: 'Overpayment for heating, rub',
  overpaymentsPercent: 'Overpayment in % of the total payment',
  totalOverpayment: 'Total payment for the period',
  investmentReturn: 'Return on investment, rub',
  averageSpecificConsumption: 'Average specific consumption, Gcal/m<sup>2</sup>*С° per day',
  totalCurrency: 'Total for\xa0period, rub/%',
  tariffByUnit: 'Tariff, rub',
  totalPayments: 'Payments for\xa0period, rub',
  totalOverpayments: 'Overpayment for\xa0period, rub',
  totalOverpaymentsPercent: 'Overpayment for\xa0period, %',
  bestValue: 'Best indicator of consumption, {unit}/m<sup>2</sup>*℃ per\xa0day',
  averageValue: 'Average consumption for\xa0period, {unit}/m<sup>2</sup>*℃ per\xa0day',
  downloadXLS: 'Download XLS',
  bestAverageValue: 'Best average across all properties',
  worseAverageValue: 'Worst average across all properties',
  acceptBuyReport: 'The service is paid, the report will cost {cost}. Confirm?',
  freeReport: 'A report with these objects for the selected period is already in the system. Saving will be free.',
  reportCost: 'The service is paid, the report will cost {cost}.',
  noCash: 'Insufficient funds to save the report, replenish the balance by {sum}',
  sendingReport: 'sending a report',
  saveReport: 'Save report',
  bestValueInObject: 'The best value for the object',
  bestValueInObjects: 'The best value for all objects',
  aboveAverageValue: 'Above average for the object',
  worseValueInObject: 'Worst value for the object',
  worseValueInObjects: 'Worst value for all objects',
  belowAverageValue: 'Below average for the object',
  savingReport: 'saving a report',
  objectSq: 'Object area, m<sup>2</sup>',
  editObject: 'Edit object',
  editingObject: 'Editing an object',
  baseValue: 'Base value',
  saveBeforeInvest: 'Save the report first'
}

export const analyticsEn = createTranslationsWithSpaceName(translations, spaceName)
