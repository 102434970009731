import {request} from "@/internal/lib/transport/request";
import {sendDataAdapter} from "./devices_adapters";

export const devicesEndpoint = {
  getAll() {
    const params = {
      url: 'api/device/',
      method: 'GET'
    }

    return request(params).promise
  },

  addNewDevice(data, addressId) {
    const params = {
      url: 'api/device/',
      method: 'POST',
      data: sendDataAdapter(data, addressId)
    }

    return request(params)
  },

  getDeviceInfo (id) {
    const params = {
      url: `api/device/${id}/`,
      method: 'GET'
    }

    return request(params)
  },

  getDeviceScoreboard (id) {
    const params = {
      url: `api/device/${id}/scoreboard/`,
      method: 'GET'
    }

    return request(params)
  },

  getBridgeVisibility (id) {
    const params = {
      url: `api/device/${id}/get_devices/`,
      method: 'GET'
    }

    return request(params)
  },

  getBridgePingStatus (id) {
    const params = {
      url: `api/device/${id}/status/`,
      method: 'GET'
    }

    return request(params)
  },

  linkDevice (masterId, slaveId) {
    const params = {
      url: `api/device/${slaveId}/link/`,
      method: 'POST',
      data: {
        master_id: masterId
      }
    }

    return request(params)
  },

  linkDevices(masterId, slavesIds) {
    const params = {
      url: `api/device/link_devices/`,
      method: 'POST',
      data: {
        master_id: masterId,
        slaves: slavesIds
      }
    }

    return request(params)
  },

  changeDeviceSettings (data) {
    const params = {
      url: `api/device/${data.id}/`,
      method: 'PUT',
      data,
    }

    return request(params)
  },

  deleteDevice (id) {
    const params = {
      url: `api/device/${id}/delete/`,
      method: 'DELETE',
    }

    return request(params)
  },
}
