import {HISTORY_PATH, TARIFFS_PATH} from "../const";

export const TARIFFS_LINKS_LIST = [
  {
    id: 1,
    textId: 'userPage.historyTransaction',
    path: HISTORY_PATH
  },
  {
    id: 2,
    textId: 'userPage.tariffs',
    path: TARIFFS_PATH
  },
]
