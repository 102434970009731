import Loader from "@/presentation/components/loader/Loader";
import {meteringPointsPageSelectors} from "@/internal/lib/storeModels/models/meteringPointsPage/meteringPointsModels";
import {useEffect, useState} from "react";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import Constants from "@/presentation/components/meteringPointsPage/constants";
import PointCard from "@/presentation/components/meteringPointsPage/components/PointCard";
import GroupedList from "@/presentation/components/meteringPointsPage/components/GroupedList";
import {FormattedMessage} from "react-intl";

const MeteringPointsList = () => {
  const getPointsList = meteringPointsPageSelectors.pointsList.useGetPointsList()
  const pointsList = meteringPointsPageSelectors.pointsList.useValue()
  const selectedAddress = addressesSelectors.selectedAddress.useValue()
  const isListLoading = !!meteringPointsPageSelectors.lastGetListRequest.useValue()

  const [isFirstLoading, setIsFirstLoading] = useState(true)

  useEffect(() => {
    if (!selectedAddress?.id) return

    getPointsList()
    setIsFirstLoading(false)
  }, [selectedAddress?.id])

  const getListByCurrentType = (type) => {
    return pointsList
      .filter(point => point.type === type)
      .map(point => <PointCard key={point.id} point={point} />)
  }

  const coldWaterPoints = getListByCurrentType(Constants.coldWaterType)
  const hotWaterPoints = getListByCurrentType(Constants.hotWaterType)
  const heatPoints = getListByCurrentType(Constants.heatType)
  const electricPoints = getListByCurrentType(Constants.electricType)
  const gasPoints = getListByCurrentType(Constants.gasType)
  const ventilationPoints = getListByCurrentType(Constants.ventilationType)

  const pointsCount = coldWaterPoints.length + hotWaterPoints.length +
    heatPoints.length + electricPoints.length + gasPoints.length +
    ventilationPoints.length

  return (
    <Loader loading={isFirstLoading || isListLoading}>
      {!pointsCount &&
        <p className={'empty-list'}>
          <FormattedMessage id={'meteringPointsPage.noPoints'}/>
        </p>
      }

      {!!coldWaterPoints.length &&
        <GroupedList
          list={coldWaterPoints}
          groupTitle={<FormattedMessage id={'resourcesTypes.1'}/>}
        />}

      {!!hotWaterPoints.length &&
        <GroupedList
          list={hotWaterPoints}
          groupTitle={<FormattedMessage id={'resourcesTypes.2'}/>}
        />}

      {!!heatPoints.length &&
        <GroupedList
          list={heatPoints}
          groupTitle={<FormattedMessage id={'resourcesTypes.3'}/>}
        />}

      {!!electricPoints.length &&
        <GroupedList
          list={electricPoints}
          groupTitle={<FormattedMessage id={'resourcesTypes.4'}/>}
        />}

      {!!gasPoints.length &&
        <GroupedList
          list={gasPoints}
          groupTitle={<FormattedMessage id={'resourcesTypes.5'}/>}
        />}

      {!!ventilationPoints.length &&
        <GroupedList
          list={ventilationPoints}
          groupTitle={<FormattedMessage id={'resourcesTypes.6'}/>}
        />}
    </Loader>
  )
}

export default MeteringPointsList
