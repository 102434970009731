import {TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {HEAD_CELLS} from "./const";

const EnhancedTableHead = ({classes, order, orderBy, onRequestSort}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const headCells = useMemo(() => {
    return HEAD_CELLS.map(headCell => {
      return (
        <TableCell
          key={headCell.id}
          align={'left'}
          // padding={headCell.disablePadding ? 'none' : 'default'}
          sortDirection={orderBy === headCell.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : 'asc'}
            onClick={createSortHandler(headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id
              ? (<span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>)
              : null}
          </TableSortLabel>
        </TableCell>
      )
    })
  }, [order, orderBy])

  return (
    <TableHead>
      <TableRow>
        {headCells}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
}

export default EnhancedTableHead