import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {INIT_SAMPLE_PARAMS_DATA} from "@/internal/lib/storeModels/models/intake/forms/const";

const sampleParamsFormModel = new FormModelFabric({
  initialData: INIT_SAMPLE_PARAMS_DATA
})

export const sampleParamsFormSelectors = {
  ...sampleParamsFormModel.createSelectors(),
  sampleParamsFormModel,
}
