import CheckBox from "@/presentation/components/common/formComponents/CheckBox";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import Input from "@/presentation/components/common/formComponents/Input";
import MaskInput from "@/presentation/components/common/formComponents/MaskInput";
import {phoneFormatter} from "@/internal/formatters/formatters";
import React, {useEffect} from "react";
import {FormattedMessage} from "react-intl";

const CheckBoxHideForm = (
  {
    label,
    checkBoxName,
    customClass = '',
    inputNames,
    formSelectors,
  }) => {
  const name = formSelectors.useFormDataValue(inputNames.name)
  const bailee = formSelectors.useFormDataValue(inputNames.bailee)
  const phone = formSelectors.useFormDataValue(inputNames.phone)
  const setFormValue = (name) => formSelectors.setFormDataValue(name)
  const initIsChecked = !!(name + bailee + phone)
  const [isChecked, toggleIsChecked] = useToggle(initIsChecked)

  useEffect(() => {
    if (isChecked) return

    for (const key in inputNames) {
      setFormValue(inputNames[key])('')
    }
  }, [isChecked])

  return (
    <div
      className={`checkbox-form ${customClass} ${isChecked ? 'show' : ''}`}
    >
      <CheckBox
        label={label}
        name={checkBoxName}
        isChecked={isChecked}
        onChange={toggleIsChecked}
      />

      {isChecked &&
        <div className="form-fields">
          <Input
            label={<FormattedMessage id={'reportsPage.name'}/>}
            name={inputNames.bailee}
            formSelectors={formSelectors}
          />

          <Input
            label={<FormattedMessage id={'reportsPage.nameOfResponsiblePerson'}/>}
            name={inputNames.name}
            formSelectors={formSelectors}
          />

          <MaskInput
            label={<FormattedMessage id={'reportsPage.tel'}/>}
            mask="+7 (999) 999-99-99"
            type="tel"
            name={inputNames.phone}
            formSelectors={formSelectors}
            valueFormatter={phoneFormatter}
          />
        </div>
      }
    </div>
  )
}

export default CheckBoxHideForm
