import {EquipmentBold} from "@/assets/svg";
import {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {NavLink} from "react-router-dom";
import useWindowSize from "@/internal/custom_hooks/windowResizeHook";
import {DEVICE_REPORT_TYPE, METERING_POINT_REPORT_TYPE, RESOURCE_REPORT_TYPE, SCHEDULE_TYPES_LIST} from "../const";
import {DEVICES_TYPES, RESOURCES_TYPES} from "@/internal/content/consts";
import {FormattedMessage, FormattedTime, useIntl} from "react-intl";

const ReportItem = ({reportData}) => {
  const intl = useIntl()
  const windowSize = useWindowSize()

  const title = useRef()
  const titleWrapper = useRef()
  const timerId = useRef()

  const [titleWidth, setTitleWidth] = useState(0)
  const [titleWrapperWidth, setTitleWrapperWidth] = useState(0)
  const [isMoveTitle, setIsMoveTitle] = useState(true)
  // const [timerId, setTimerId] = useState(null)

  const titleSpeed = useMemo(() => {
    return titleWidth - titleWrapperWidth < 10
      ? 0
      : Math.round((titleWidth - titleWrapperWidth) * 60) + 1000
  }, [titleWidth, titleWrapperWidth])

  useEffect(() => {
    if (!titleSpeed) return

    timerId.current = setTimeout(() => {
      if (isMoveTitle) {
        setIsMoveTitle(false)
      } else {
        setIsMoveTitle(true)
      }
    }, titleSpeed)

    // timerId.current = timer
    // setTimerId(timer)

    return () => {
      if (timerId.current) clearTimeout(timerId.current)
    }
  }, [isMoveTitle, titleSpeed])

  useLayoutEffect(() => {
    const widthTitle = window.getComputedStyle(title.current).width
    setTitleWidth(parseFloat(widthTitle))

    const widthWrapperTitle = window.getComputedStyle(titleWrapper.current).width
    setTitleWrapperWidth(parseFloat(widthWrapperTitle))
  }, [windowSize])

  const transformOffset = useMemo(() => {
    if (titleWidth === 0 || titleWrapperWidth === 0) return "0"

    const percentOffset = (titleWidth - titleWrapperWidth)/titleWidth * 100
    return `-${Math.abs(percentOffset)}%`
  }, [titleWidth, titleWrapperWidth])

  const scheduleInfo = useMemo(() => {
    if (!reportData?.schedule) return <FormattedMessage id={'reportsPage.withoutRegular'}/>
    const scheduleTypeName = SCHEDULE_TYPES_LIST.find((type) => type.value === reportData.schedule.type)
    return (
      <FormattedMessage
        id={'reportsPage.typeTitle'}
        values={{
          type: scheduleTypeName.label,
          day: reportData.schedule.day ?? 0,
          time: <FormattedTime value={reportData.schedule.minute} />
        }}
      />
    )
  }, [reportData])

  const receiversInfo = useMemo(() => {
    if (!reportData.receivers) return ''

    const mailsCount = reportData.receivers.mails.length
    return mailsCount > 1
      ? (
        <FormattedMessage
          id={'reportsPage.mailsCount'}
          values={{
            count: mailsCount
          }}
        />
      )
      : reportData.receivers.mails[0]
  }, [reportData])

  const imgBlock = useMemo(() => {
    if (!reportData) return null

    switch (reportData.type) {
      case METERING_POINT_REPORT_TYPE:
        return (
          <div className="report-item__img">
            <img src={EquipmentBold} alt="EquipmentBold" />
          </div>
        )
      case RESOURCE_REPORT_TYPE:
        const img = RESOURCES_TYPES[reportData.config.resource_type].img || null

        return (
          <div className="report-item__img">
            <img src={img} alt="resource" />
          </div>
        )
      case DEVICE_REPORT_TYPE:
        const deviceImg = DEVICES_TYPES[reportData.config.device.type].img || null

        return (
          <div className="report-item__img report-item__img_device">
            <img src={deviceImg} alt="device" />
          </div>
        )
    }
  }, [reportData.type])

  return (
    <NavLink
      className={`report-item`}
      to={String(reportData.id)}
    >
      {imgBlock}

      <div
        ref={titleWrapper}
        className="report-item__info"
      >
        <h6
          ref={title}
          style={{
            transform: isMoveTitle && titleWidth > titleWrapperWidth
              ? `translate(${transformOffset}, 0)` : `translate(0, 0)`,
            transition: `transform ${Math.round(titleSpeed - 1000)}ms linear`
          }}
        >
          {reportData.name}
        </h6>

        <div className="report-item__schedule-info">
          <span className='report-item__schedule'>{scheduleInfo}</span>

          <span className='report-item__receivers'>{receiversInfo}</span>
        </div>
      </div>
    </NavLink>
  )
}

export default ReportItem
