import {Close} from "@/assets/svg";
import {
  BY_CONTINUE_TYPE,
  EMPTY_COLUMN,
  getDurationsTableHeaderConfig,
  getIntakeTableHeaderConfig,
  getInvestmentsTableHeaderConfig,
  getTemperatureTableHeaderConfig,
  TABLE_COLUMNS_COUNT,
} from "./const";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Tabs from "@/presentation/components/common/tabs/Tabs";
import {
  capitalizeFirstLetter,
  formattedDateToServ,
} from "@/internal/formatters/formatters";
import ObjectAnalyticsFormFabric from "@/internal/lib/storeModels/fabrics/objectsAnalyticsFormFabric";
import ResultTables from "./result/ResultTables";
import {analyticsEndpoint} from "@/data/api_entities/analytics/analytics_endpoint";
import {selectors} from "@/presentation/shared/ui/message";
import dayjs from "dayjs";
import utc  from 'dayjs/plugin/utc'
import Preconfig from "./Preconfig";
import {cloneDeep} from "lodash";
import {
  newSelectedAddressIdSelectors,
} from "@/internal/lib/storeModels/models/analytics/newSelectedAddressModel";
import {
  analyticsSelectors,
} from "@/internal/lib/storeModels/models/analytics/analyticsModels";
import { addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import TableGenerator from "./TableGenerator";
import IntakeTableRow from "./intake/IntakeTableRow";
import DurationsTableRow from "./durations/DurationsTableRow";
import TemperatureTableRow from "./temperature/TemperatureTableRow";
import InvestmentsTableRow from "./investments/InvestmentsTableRow";
import {preconfigFormSelectors} from "@/internal/lib/storeModels/models/analytics/forms/preconfigFormModel";
import TableSteps from "./TableSteps";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import {activeInvestStepSelectors, tableStepsSelectors} from "@/internal/lib/storeModels/models/analytics/tableStepsModels";
import Loader from "@/presentation/components/loader/Loader";
import {FormattedDate, FormattedMessage, useIntl} from "react-intl";
import {managerAddressesSelectors} from "@/internal/lib/storeModels/models/servicemen/stores";

dayjs.extend(utc)

const Tables = (
  {
    toggleModal,
    startResultData = null,
    editData = null,
    completedData = null,
    editAnalyticId = null,
    onlyResult = false,
    getInfo = null
  }
) => {
  const resultTimerId = useRef()
  const intl = useIntl()

  const userAddresses = addressesSelectors.addresses.useValue()
  const managerAddresses = managerAddressesSelectors.useValue()
  const addresses = managerAddresses ?? userAddresses
  const preconfigData = preconfigFormSelectors.useFormData()
  const setInitPreconfigData = preconfigFormSelectors.useSetInitFormData()
  const setPreconfigDataValue = (name) => preconfigFormSelectors.setFormDataValue(name)
  const resetPreconfigForm = preconfigFormSelectors.useResetForm()
  const newSelectedAddressId = newSelectedAddressIdSelectors.useValue()
  const resetNewSelectedAddressId = newSelectedAddressIdSelectors.useReset()
  const analyticId = analyticsSelectors.analyticId.useValue()
  const resetAnalyticId = analyticsSelectors.analyticId.useReset()
  const [activeStep, setActiveStep] = tableStepsSelectors.useState()
  const [activeInvestStep, setActiveInvestStep] = activeInvestStepSelectors.useState()
  const setMessage = selectors.useSetMessage()
  const resetIsPaidAnalytic = analyticsSelectors.isPaidAnalytic.useReset()
  const setTempFromDB = analyticsSelectors.temperatureFromDB.useSetValue()
  const resetRowsPerPage = analyticsSelectors.rowsPerPage.useReset()

  const [allAddresses, setAllAddresses] = useState([])
  const [models, setModels] = useState({})
  const [resultData, setResultData] = useState(null)
  const [isMeteringsLoading, setIsMeteringsloading] = useState(false)
  const [isLoadingResult, setIsLoadingResult] = useState(false)
  const [cost, setCost] = useState(0)
  const [isLoadingTemp, setIsLoadingTemp] = useState(false)
  const [isCreatingModels, setIsCreatingModels] = useState(false)
  const [lastChangeAnalyticRequest, setLastChangeAnalyticRequest] = useState(null)
  const [lastGetAnalyticTempRequest, setLastGetAnalyticTempRequest] = useState(null)
  const [lastCalculateAnalyticRequest, setLastCalculateAnalyticRequest] = useState(null)
  const isSaveAvailable = !editAnalyticId && !onlyResult
  const setIsInvestResultStepCompleted = analyticsSelectors.isInvestResultStepCompleted.useSetValue()
  const setIsInvestStepCompleted = analyticsSelectors.isInvestStepCompleted.useSetValue()

  const [isShowAlertModal, toggleAlertModal, setIsShowAlertModal] = useToggle()

  useEffect(() => {
    if (!editData) return

    setInitPreconfigData({
      accounting_objects: editData.analytics,
      name: editData.name,
      step: editData.step,
      start_date: editData.start_date,
      end_date: editData.end_date,
      toStep: 1,
      period_display_type: editData.period_display_type ?? 1
    })
  }, [editData])

  useEffect(() => {
    if (!completedData) return

    if (completedData.newAnalysisType === BY_CONTINUE_TYPE) {
      setPreconfigDataValue('name')(intl.formatMessage(
        {
          id: 'analytics.continueReportName',
        },
        {
          name: completedData.name
        }
      ))
      setPreconfigDataValue('start_date')(completedData.start_date)
    } else {
      setPreconfigDataValue('start_date')(completedData.start_date)
      setPreconfigDataValue('end_date')(completedData.end_date)
    }

    const accountingObjects = {}
    const existingAddressesIds = addresses.map(address => address.id)
    const accountingObjectsIds = Object.keys(completedData.analytics)

    accountingObjectsIds.forEach(objectId => {
      if (!existingAddressesIds.includes(+objectId)) return
      accountingObjects[objectId] = {}
    })

    setPreconfigDataValue('accounting_objects')(accountingObjects)
    setPreconfigDataValue('step')(completedData.step)
    setPreconfigDataValue('period_display_type')(completedData.period_display_type ?? 1)
  }, [completedData])

  useEffect(() => {
    return () => {
      resetPreconfigForm()
      resetIsPaidAnalytic()
      resetAnalyticId()
      resetRowsPerPage()
      setTempFromDB(null)

      if (resultTimerId.current) clearTimeout(resultTimerId.current)
      resultTimerId.current = 0
    }
  }, [])

  useEffect(() => {
    const filteredAddresses = addresses.filter(address => address.area && address.tariff && address.geo_len && address.geo_lat)

    setAllAddresses(filteredAddresses)
  }, [addresses, newSelectedAddressId])

  useEffect(() => {
    if (!newSelectedAddressId) return

    const prevAccountingObj = cloneDeep(preconfigData.accounting_objects)

    setPreconfigDataValue('accounting_objects')({
      ...prevAccountingObj,
      [newSelectedAddressId]: {}
    })
    resetNewSelectedAddressId()
  }, [newSelectedAddressId])

  const selectedAddresses = useMemo(() => {
    if (startResultData) {
      return startResultData.object
    } else {
      const selectedAddressesId = Object.keys(preconfigData.accounting_objects)

      return allAddresses.filter(address => selectedAddressesId.includes(String(address.id)))
    }
  }, [preconfigData.accounting_objects, allAddresses, startResultData])

  const daysCount = useMemo(() => {
    const startDate = startResultData
      ? startResultData.start_date
      : preconfigData.start_date
    const endDate = startResultData
      ? startResultData.end_date
      : preconfigData.end_date

    const sT = new Date(startDate)
    const eT = new Date(endDate)

    return (eT - sT) > 0
      ? (eT - sT) / 1000 / 3600 / 24
      : 0
  }, [preconfigData.start_date, preconfigData.end_date, startResultData])

  const columnWidth = useMemo(() => {
    return `${100 / (2 + TABLE_COLUMNS_COUNT)}%`
  }, [])

  const unusedMonthsCountBeforeStart = useMemo(() => {
    const startDate = startResultData
      ? startResultData.start_date
      : preconfigData.start_date
    const periodType = startResultData
      ? startResultData.period_display_type
      : preconfigData.period_display_type
    const normalizedMonthCount = (periodType ?? 1) === 1
      ?  new Date(startDate).getMonth()
      : new Date(startDate).getMonth() >= 6
        ? new Date(startDate).getMonth() - 6
        : new Date(startDate).getMonth() + 6

    return !startDate
      ? 0
      : normalizedMonthCount
  }, [preconfigData.start_date, startResultData, preconfigData.period_display_type])

  const unusedMonthsCountAfterEnd = useMemo(() => {
    const endDate = startResultData
      ? startResultData.end_date
      : preconfigData.end_date
    const periodType = startResultData
      ? startResultData.period_display_type
      : preconfigData.period_display_type
    const normalizedMonthCount = (periodType ?? 1) === 1
      ?  new Date(endDate).getMonth()
      : new Date(endDate).getMonth() >= 6
        ? new Date(endDate).getMonth() - 6
        : new Date(endDate).getMonth() + 6

    return !endDate
      ? 0
      : 11 - normalizedMonthCount
  }, [preconfigData.end_date, startResultData, preconfigData.period_display_type])

  const monthsCount = useMemo(() => {
    const startDate = startResultData
      ? startResultData.start_date
      : preconfigData.start_date
    const endDate = startResultData
      ? startResultData.end_date
      : preconfigData.end_date

    if (!startDate || !endDate) return 0

    const sT = new Date(startDate)
    const eT = new Date(endDate)
    const monthsStart = sT.getMonth() +  sT.getFullYear() * 12
    const monthsEnd = eT.getMonth() +  eT.getFullYear() * 12

    return monthsEnd - monthsStart + 1 + unusedMonthsCountBeforeStart + unusedMonthsCountAfterEnd
  }, [preconfigData.start_date, preconfigData.end_date, startResultData, unusedMonthsCountBeforeStart, unusedMonthsCountAfterEnd])

  const tablesCount = useMemo(() => {
    const step = startResultData
      ? startResultData.step
      : preconfigData.step

    if (!step) return 0

    switch (step) {
      case '1':
        return Math.ceil(daysCount/TABLE_COLUMNS_COUNT)
      case '2':
        return Math.ceil(daysCount/TABLE_COLUMNS_COUNT/7)
      case '3':
        return Math.ceil(monthsCount/TABLE_COLUMNS_COUNT)
    }
  }, [preconfigData.step, daysCount, monthsCount, startResultData])

  const tablesConfig= useMemo(() => {
    const startDate = startResultData
      ? startResultData.start_date
      : preconfigData.start_date
    const endDate = startResultData
      ? startResultData.end_date
      : preconfigData.end_date
    const tablesList = []

    if (!startDate || !endDate) return []

    const eT = new Date(dayjs(endDate).utc().format())
    let iterableDate = new Date(dayjs(startDate).utc().format())

    for (let i = 0; i < tablesCount; i++) {
      const columns = []
      let startPeriod, endPeriod

      for (let j = 0; j < TABLE_COLUMNS_COUNT; j++) {
        if (i === 0 && j < unusedMonthsCountBeforeStart) {
          columns.push({
            title: EMPTY_COLUMN,
            value: EMPTY_COLUMN
          })
        } else if (i === tablesCount - 1 && j >= TABLE_COLUMNS_COUNT - unusedMonthsCountAfterEnd) {
          columns.push({
            title: EMPTY_COLUMN,
            value: EMPTY_COLUMN
          })
        } else {
          if (i === 0) {
            if (j === unusedMonthsCountBeforeStart) {
              const date = cloneDeep(iterableDate)
              startPeriod = (
                <FormattedDate
                  children={capitalizeFirstLetter}
                  value={date}
                  month={'long'}
                  year={'numeric'}
                />
              )
            }
          } else {
            if (j === 0) {
              const date = cloneDeep(iterableDate)
              startPeriod = (
                <FormattedDate
                  children={capitalizeFirstLetter}
                  value={date}
                  month={'long'}
                  year={'numeric'}
                />
              )
            }
          }

          if (i === tablesCount - 1) {
            if (j === TABLE_COLUMNS_COUNT - unusedMonthsCountAfterEnd) {
              const date = cloneDeep(iterableDate)
              endPeriod = (
                <FormattedDate
                  children={capitalizeFirstLetter}
                  value={date}
                  month={'long'}
                  year={'numeric'}
                />
              )
            }
          } else {
            if (j === TABLE_COLUMNS_COUNT - 1) {
              const date = cloneDeep(iterableDate)
              endPeriod = (
                <FormattedDate
                  children={capitalizeFirstLetter}
                  value={date}
                  month={'long'}
                  year={'numeric'}
                />
              )
            }
          }

          if (eT.getTime() - iterableDate.getTime() >= 0) {
            const date = cloneDeep(iterableDate)
            columns.push({
              title: (
                <FormattedDate
                  children={capitalizeFirstLetter}
                  value={date}
                  month={'long'}
                />
              ),
              value: formattedDateToServ(iterableDate)
            })
          }

          iterableDate.setMonth(iterableDate.getMonth() + 1)
        }
      }

      if (i === tablesCount - 1) {
        const date = cloneDeep(eT)
        endPeriod = (
          <FormattedDate
            children={capitalizeFirstLetter}
            value={date}
            month={'long'}
            year={'numeric'}
          />
        )
      }

      tablesList.push({
        id: i + 1,
        columns,
        startPeriod,
        endPeriod,
      })
    }

    return tablesList
  }, [preconfigData.start_date, preconfigData.end_date, tablesCount, startResultData, unusedMonthsCountAfterEnd, unusedMonthsCountBeforeStart])

  const createDefaultData = useCallback((addressId) => {
    const preexistModel = cloneDeep(models)[addressId]
    const preexistIntakeData = preexistModel?.selectors.getIntakeData() ?? {}
    const preexistDurationsData = preexistModel?.selectors.getDurationsData() ?? {}
    const preexistTemperatureData = preexistModel?.selectors.getTempData() ?? {}
    const preexistInvestmentsData = preexistModel?.selectors.getInvestData()  ?? {}

    const defaultIntakeData = {}
    const defaultDurationsData = {}
    const defaultTemperatureData = {}
    const defaultInvestmentsData = {}

    tablesConfig.forEach(config => {
      config.columns.forEach(column => {
        if (column.value === EMPTY_COLUMN) return

        defaultIntakeData[column.value] = preexistIntakeData[column.value] ?? '0'
        defaultTemperatureData[column.value] = preexistTemperatureData[column.value] ?? ''
        defaultInvestmentsData[column.value] = preexistInvestmentsData[column.value] ?? '0'

        if (preexistDurationsData[column.value]) {
          defaultDurationsData[column.value] = cloneDeep(preexistDurationsData[column.value])
        } else {
          const daysArray = []
          const notHeatingMonthsIndex = [5, 6, 7]
          let date = new Date(column.value)
          const days = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()

          if (notHeatingMonthsIndex.includes(date.getMonth())) {
            defaultDurationsData[column.value] = []
          } else {
            for (let i = 0; i < days; i++) {
              const now = new Date().getTime()
              const dateTime = new Date(date).getTime()

              if (now < dateTime) continue

              daysArray.push(formattedDateToServ(date))
              date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
            }

            defaultDurationsData[column.value] = daysArray
          }
        }
      })
    })

    return {
      defaultIntakeData,
      defaultDurationsData,
      defaultTemperatureData,
      defaultInvestmentsData
    }
  }, [tablesConfig, models])

  const createModelsPromise = useCallback(() => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const newModels = {}
        const currentAddressesIds = Object.keys(preconfigData.accounting_objects)

        currentAddressesIds.forEach((id) => {
          const hasData = !!Object.keys(preconfigData.accounting_objects[id]).length
          let model

          if (hasData) {
            model = new ObjectAnalyticsFormFabric({
              initIntakeData: preconfigData.accounting_objects[id].intake,
              initDurationsData: preconfigData.accounting_objects[id].durations,
              initTemperatureData: preconfigData.accounting_objects[id].temperature,
              initInvestmentsData: preconfigData.accounting_objects[id].investments,
              addressId: id,
              analyticId: editAnalyticId ?? analyticId
            })
          } else {
            const {
              defaultIntakeData,
              defaultDurationsData,
              defaultTemperatureData,
              defaultInvestmentsData
            } = createDefaultData(id)

            model = new ObjectAnalyticsFormFabric({
              initIntakeData: defaultIntakeData,
              initDurationsData: defaultDurationsData,
              initTemperatureData: defaultTemperatureData,
              initInvestmentsData: defaultInvestmentsData,
              addressId: id,
              analyticId: editAnalyticId ?? analyticId
            })
          }

          newModels[id] = {
            model,
            selectors: model.createSelectors()
          }
        })

        resolve(newModels)
      }, 0)
    })
  }, [editAnalyticId, analyticId, preconfigData, createDefaultData])

  useEffect(() => {
    if (!editData || (!preconfigData.start_date || !preconfigData.end_date)) return

    setIsCreatingModels(true)
    const promise = createModelsPromise()

    promise
      .then(newModels => {
        setModels(newModels)
      })
      .catch(err => {
        console.log('err create models', err)
      })
      .finally(() => setIsCreatingModels(false))
  }, [preconfigData])

  const getObjWithAllMeterings = () => {
    const meterings = {}

    for (const key in models) {
      meterings[key] = models[key].selectors.getFullData()
    }

    return meterings
  }

  const setTemp = (temperature) => {
    for (const key in models) {
      const currentAddressTemp = temperature[key]
      if (!currentAddressTemp) return

      for (const tempKey in currentAddressTemp) {
        const currentModelValue = models[key].selectors.getCurrentTempValue(tempKey)

        if (currentModelValue === undefined ||
          currentModelValue !== '' ||
          currentAddressTemp[tempKey] === null) continue

        models[key].selectors.setCurrentTempValue({
          key: tempKey,
          value: currentAddressTemp[tempKey]
        })
      }
    }
  }

  const saveMeterings = (resTempRequest, withCalc, fromInvest = false) => {
    const id = editAnalyticId ?? analyticId

    if (!id) return

    if (lastChangeAnalyticRequest) {
      lastChangeAnalyticRequest.abort()
    }

    setIsMeteringsloading(true)

    const meterings = getObjWithAllMeterings()

    const {promise, controller} = analyticsEndpoint.changeAnalytic(meterings, id)

    setLastChangeAnalyticRequest(controller)

    promise
      .then(() => {
        if (withCalc) {
          if (resTempRequest.status === 'ok') {
            onGetAnalytic(fromInvest)
          } else {
            setActiveInvestStep(-1)
            setActiveStep(3)
            setIsShowAlertModal(true)
          }
        } else {
          setActiveInvestStep(-1)
          setActiveStep(3)
        }

        setLastChangeAnalyticRequest(null)
      })
      .catch((err) => {
        if (err.name === 'AbortError') return

        setLastChangeAnalyticRequest(null)
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.analyticMeteringsError'}/>,
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsMeteringsloading(false))
  }

  const getAnalyticTemp = (id, withCalc, fromInvest = false) => {
    if (lastGetAnalyticTempRequest) {
      lastGetAnalyticTempRequest.abort()
    }

    setIsLoadingTemp(true)

    const {promise, controller} = analyticsEndpoint.getAnalyticTemp({
      analytics_id: id
    })

    setLastGetAnalyticTempRequest(controller)

    promise
      .then(res => {
        setTemp(res.temperature)
        setTempFromDB(res.temperature)
        saveMeterings(res, withCalc, fromInvest)
        setLastGetAnalyticTempRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return

        setLastGetAnalyticTempRequest(null)
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.analyticTemperatureError'}/>,
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsLoadingTemp(false))
  }

  const pingAnalytic = (id, fromInvest = false) => {
    const {promise} = analyticsEndpoint.pingAnalyticResult(id)

    promise
      .then(res => {
        if (res.status === 'pending') {
          resultTimerId.current = setTimeout(() => pingAnalytic(id), 5000)
        } else {
          setResultData(res)
          if (fromInvest) {
            setIsInvestResultStepCompleted(true)
            setIsInvestStepCompleted(true)
            setActiveStep(-1)
            setActiveInvestStep(1)
            setIsLoadingResult(false)
          } else {
            setCost(res.user_balance)

            setActiveInvestStep(-1)
            setActiveStep(4)
            setIsLoadingResult(false)
          }
        }
      })
      .catch(err => {
        setIsLoadingResult(false)
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.analyticCalcError'}/>,
          type: 'error',
          count: 3000
        })
      })
  }

  const onGetAnalytic = (fromInvest = false) => {
    const id = editAnalyticId ?? analyticId

    if (!id) return

    if (lastCalculateAnalyticRequest) {
      lastCalculateAnalyticRequest.abort()
    }

    setIsLoadingResult(true)

    const {promise, controller} = analyticsEndpoint.calculateAnalytic(id)

    setLastCalculateAnalyticRequest(controller)

    promise
      .then(res => {
        if (res.status === 'ok') {
          pingAnalytic(id, fromInvest)
        } else {
          if (res.detail === 'no meter readings') {
            setMessage({
              header: <FormattedMessage id={'requests.error'}/>,
              message: <FormattedMessage id={'requests.noMeterReadings'}/>,
              type: 'error',
              count: 3000
            })
          } else if (res.status === 'warning') {
            setMessage({
              header: <FormattedMessage id={'requests.error'}/>,
              message: res.detail,
              type: 'error',
              count: 6000
            })
          } else {
            setMessage({
              header: <FormattedMessage id={'requests.error'}/>,
              message: <FormattedMessage id={'requests.analyticCalcError'}/>,
              type: 'error',
              count: 3000
            })
          }

          setIsLoadingResult(false)
        }

        setLastCalculateAnalyticRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return

        console.log(err)
        setIsLoadingResult(false)
        setLastCalculateAnalyticRequest(null)

        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.analyticCalcError'}/>,
          type: 'error',
          count: 3000
        })
      })
  }

  const handleCloseModal = () => {
    resetAnalyticId()
    toggleModal()
  }

  const handleClickStepBtn = (step) => {
    if (isLoadingResult || isMeteringsLoading || isLoadingTemp) return

    setActiveInvestStep(-1)
    setActiveStep(step)
  }

  return (
    <Loader
      loading={isCreatingModels}
    >
      <div className='tables'>
        <div className="tables__header">
          <h2 className={'title'}>
            <FormattedMessage id={'analytics.intakeEfficiencyAnalysis'}/>
          </h2>

          <div
            className='tables__close'
            onClick={handleCloseModal}
          >
            <img src={Close} alt="close"/>
          </div>
        </div>

        <TableSteps
          editAnalyticId={editAnalyticId}
          startResultData={startResultData}
          loading={isLoadingResult || isMeteringsLoading || isLoadingTemp}
          getAnalyticTemp={getAnalyticTemp}
          onlyResult={onlyResult}
        />

        {activeInvestStep < 0
          ? <Tabs activeTab={activeStep}>
            <Preconfig
              name={0}
              getInfo={getInfo}
              allAddresses={allAddresses}
              addresses={addresses}
              setActiveStep={setActiveStep}
              getObjWithAllMeterings={getObjWithAllMeterings}
              completedData={completedData}
              createModelsPromise={createModelsPromise}
              setModels={setModels}
            />

            <TableGenerator
              name={1}
              getHeaderConfig={getIntakeTableHeaderConfig}
              tablesConfig={tablesConfig}
              selectedAddresses={selectedAddresses}
              isLoading={isLoadingResult || isMeteringsLoading || isLoadingTemp}
              dataRowComponent={<IntakeTableRow/>}
              models={models}
              editAnalyticId={editAnalyticId}
              columnWidth={columnWidth}
              getObjWithAllMeterings={getObjWithAllMeterings}
              completedData={completedData}
            >
              <div className={`tables__btn-wrapper ${editAnalyticId ? 'tables__btn-wrapper_one' : ''}`}>
                {!editAnalyticId &&
                  <button
                    className="btn btn_transparent-bg"
                    onClick={() => handleClickStepBtn(0)}
                  >
                    <FormattedMessage id={'app.back'}/>
                  </button>
                }

                <button
                  className="btn btn_transparent-bg"
                  onClick={() => handleClickStepBtn(2)}
                >
                  <FormattedMessage id={'app.proceed'}/>
                </button>
              </div>
            </TableGenerator>

            <TableGenerator
              name={2}
              getHeaderConfig={getDurationsTableHeaderConfig}
              tablesConfig={tablesConfig}
              selectedAddresses={selectedAddresses}
              isLoading={isLoadingResult || isMeteringsLoading || isLoadingTemp}
              dataRowComponent={<DurationsTableRow />}
              models={models}
              editAnalyticId={editAnalyticId}
              columnWidth={columnWidth}
              getObjWithAllMeterings={getObjWithAllMeterings}
              completedData={completedData}
            >
              <div className="tables__btn-wrapper">
                <button
                  className="btn btn_transparent-bg"
                  onClick={() => handleClickStepBtn(1)}
                >
                  <FormattedMessage id={'app.back'}/>
                </button>

                <button
                  className="btn btn_transparent-bg"
                  onClick={() => getAnalyticTemp(analyticId, false)}
                >
                  <FormattedMessage id={'app.proceed'}/>
                </button>
              </div>
            </TableGenerator>

            <TableGenerator
              name={3}
              isTempTable
              getHeaderConfig={getTemperatureTableHeaderConfig}
              tablesConfig={tablesConfig}
              selectedAddresses={selectedAddresses}
              isLoading={isLoadingResult || isMeteringsLoading || isLoadingTemp}
              dataRowComponent={<TemperatureTableRow />}
              models={models}
              editAnalyticId={editAnalyticId}
              columnWidth={columnWidth}
              getObjWithAllMeterings={getObjWithAllMeterings}
              completedData={completedData}
              saveMeterings={saveMeterings}
            >
              <div className="tables__btn-wrapper">
                <button
                  className="btn btn_transparent-bg"
                  onClick={() => handleClickStepBtn(2)}
                >
                  <FormattedMessage id={'app.back'}/>
                </button>

                <button
                  className="btn"
                  onClick={() => getAnalyticTemp(analyticId, true)}
                >
                  <FormattedMessage id={'analytics.analysis'}/>
                </button>
              </div>

              <DefaultModal
                isOpen={isShowAlertModal}
                headerTitle={<FormattedMessage id={'analytics.temperatureData'}/>}
                bodyText={<>
                  <FormattedMessage id={'analytics.noAccessToService'}/>
                  <br/>
                  <br/>
                  <FormattedMessage id={'analytics.manuallyTemperature'}/>
                </>}
                toggleModal={toggleAlertModal}
                onAgree={toggleAlertModal}
                agreeBtnConfig={{
                  className: 'btn',
                  text: <FormattedMessage id={'app.ok'}/>
                }}
                disagreeBtnConfig={null}
              />
            </TableGenerator>

            {/*<TableGenerator*/}
            {/*  name={5}*/}
            {/*  getHeaderConfig={getInvestmentsTableHeaderConfig}*/}
            {/*  tablesConfig={tablesConfig}*/}
            {/*  selectedAddresses={selectedAddresses}*/}
            {/*  isLoading={isLoadingResult || isMeteringsLoading || isLoadingTemp}*/}
            {/*  dataRowComponent={<InvestmentsTableRow />}*/}
            {/*  models={models}*/}
            {/*  editAnalyticId={editAnalyticId}*/}
            {/*  columnWidth={columnWidth}*/}
            {/*  getObjWithAllMeterings={getObjWithAllMeterings}*/}
            {/*  completedData={completedData}*/}
            {/*>*/}
            {/*  <div className="tables__btn-wrapper tables__btn-wrapper_one">*/}
            {/*    <button*/}
            {/*      className="btn"*/}
            {/*      onClick={() => getAnalyticTemp(analyticId, true)}*/}
            {/*    >*/}
            {/*      <FormattedMessage id={'analytics.analysis'}/>*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</TableGenerator>*/}

            <ResultTables
              name={4}
              tablesConfig={tablesConfig}
              isLoading={isLoadingResult || isMeteringsLoading || isLoadingTemp}
              selectedAddresses={selectedAddresses}
              resultData={startResultData ? startResultData : resultData}
              columnWidth={columnWidth}
              startDate={startResultData ? startResultData.start_date : preconfigData.start_date}
              endDate={startResultData ? startResultData.end_date : preconfigData.end_date}
              isSaveAvailable={isSaveAvailable}
              cost={cost}
              unusedMonthsCountBeforeStart={unusedMonthsCountBeforeStart}
              getInfo={getInfo}
            />
          </Tabs>
          : <Tabs activeTab={activeInvestStep}>
            <TableGenerator
              name={0}
              getHeaderConfig={getInvestmentsTableHeaderConfig}
              tablesConfig={tablesConfig}
              selectedAddresses={selectedAddresses}
              isLoading={isLoadingResult || isMeteringsLoading || isLoadingTemp}
              dataRowComponent={<InvestmentsTableRow />}
              models={models}
              editAnalyticId={editAnalyticId}
              columnWidth={columnWidth}
              getObjWithAllMeterings={getObjWithAllMeterings}
              completedData={completedData}
            >
              <div className="tables__btn-wrapper tables__btn-wrapper_one">
                <button
                  className="btn"
                  onClick={() => getAnalyticTemp(analyticId, true, true)}
                >
                  <FormattedMessage id={'analytics.analysis'}/>
                </button>
              </div>
            </TableGenerator>

            <ResultTables
              name={1}
              tablesConfig={tablesConfig}
              selectedAddresses={selectedAddresses}
              isLoading={isLoadingResult || isMeteringsLoading || isLoadingTemp}
              resultData={startResultData ? startResultData : resultData}
              columnWidth={columnWidth}
              startDate={startResultData ? startResultData.start_date : preconfigData.start_date}
              endDate={startResultData ? startResultData.end_date : preconfigData.end_date}
              isSaveAvailable={isSaveAvailable}
              cost={cost}
              unusedMonthsCountBeforeStart={unusedMonthsCountBeforeStart}
              isOnlyInvest
              getInfo={getInfo}
            />
          </Tabs>
        }
      </div>
    </Loader>
  )
}

export default Tables
