export const INIT_CALC_SETTINGS_DATA = Object.freeze({
  name: '',
  start_date: '',
  end_date: '',
  unit_type: 1,
  energy_period: null,
  tariff: null,
  tariff_type: 1
})

export const INIT_CALC_SETTINGS_VALIDATIONS = Object.freeze({
  name: [],
  start_date: [],
  end_date: [],
  energy_period: [],
  tariff: []
})
