import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";
import {createEvent} from "effector";
import {useEvent} from "effector-react";

const availableDevicesCountModel = new StoreModelFabric(0)
const changeEmailLoadingModel = new StoreModelFabric(false)
const isCodeSentModel = new StoreModelFabric(false)
const timeBeforeResendModel = new StoreModelFabric(30)
const resetResendCode = createEvent()

isCodeSentModel.$store.reset(resetResendCode)
timeBeforeResendModel.$store.reset(resetResendCode)

export const userPageSelectors = {
  availableDevicesCount: availableDevicesCountModel.createSelectors(),
  changeEmailLoading: changeEmailLoadingModel.createSelectors(),
  isCodeSent: isCodeSentModel.createSelectors(),
  timeBeforeResend: timeBeforeResendModel.createSelectors(),
  useResetResendCode: () => useEvent(resetResendCode)
}
