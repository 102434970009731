import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'navBar'
const translations = {
  consumption: 'Consumption',
  equipment: 'Equipment',
  reports: 'Reports',
  events: 'Events',
  analytic: 'Analytics',
  meteringPoints: 'Accounting points',
  accountingObjects: 'Accounting objects',
  balance: 'Balance - {balance, number, ::currency/EUR}',
  main: 'Main'
}

export const navBarEn = createTranslationsWithSpaceName(translations, spaceName)
