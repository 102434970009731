import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'requests'
const translations = {
  error: 'Error!',
  deleteAcc: 'Delete account',
  detailsChanges: 'Change of personal data',
  detailsChangesSuccess: 'Personal data changed successfully',
  detailsChangesError: 'Failed to change personal information',
  topUpAccountSuccessHeader: 'You did it!',
  topUpAccountSuccess: 'You have topped up your account with {amount, number}!',
  topUpAccountErrorHeader: 'You failed!',
  topUpAccountError: 'Contact us in any convenient way for you.',
  changeEmailSuccessHeader: 'Change email',
  changeEmailSuccess: 'Email changed successfully',
  changeEmailError: `Could not change email`,
  sendCodeMailError: 'Failed to send confirmation email',
  changeDetailsSuccessHeader: 'Change details',
  changeDetailsSuccess: 'Details changed successfully',
  changeDetailsError: 'Failed to change details',
  changePasswordSuccessHeader: 'Change password',
  changePasswordSuccess: 'Password changed successfully',
  changePasswordError: 'Failed to change password',
  creatingObjectSuccessHeader: 'Creating an accounting object',
  creatingObjectSuccess: 'Account object successfully created',
  creatingObjectError: 'Failed to create accounting object',
  getObjectsError: 'Failed to load accounting points',
  changingObjectSuccessHeader: 'Change of accounting object',
  changingObjectSuccess: 'The accounting object has been successfully changed',
  changingObjectError: 'Failed to change accounting object "{name, string}"',
  removeObjectSuccessHeader: 'Deleting an accounting object',
  removeObjectSuccess: 'The accounting object was successfully deleted',
  removeObjectError: 'Failed to delete accounting object',
  addMeteringPointError: 'Failed to add point of account',
  addMeteringPointSuccessHeader: 'Adding an accounting Point',
  addMeteringPointSuccess: 'Accounting point added successfully',
  changingMeteringPointSuccessHeader: 'Change of accounting point',
  changingMeteringPointSuccess: 'Accounting point changed successfully',
  changingMeteringPointError: 'Failed to change accounting point {name, string}',
  removeMeteringPointError: 'Failed to change accounting point',
  removeMeteringPointSuccessHeader: 'Deleting an accounting point',
  removeMeteringPointSuccess: 'Accounting point deleted successfully',
  loadAddressError: 'Failed to load searched address',
  loadMeteringPointError: 'Failed to load accounting point',
  confirmMailError: 'Failed to send confirmation email',
  getAnalyticDataError: 'Failed to get analytics data',
  deleteAnalyticSuccessHeader: 'Deleting a report',
  deleteAnalyticSuccess: 'Report deleted successfully!',
  deleteAnalyticError: 'Failed to delete report',
  analyticMeteringsError: 'Failed to save data',
  analyticTemperatureError: 'Failed to get temperature',
  analyticCalcError: 'Failed to perform calculations',
  analyticCreateError: 'Failed to create analytics',
  analyticNameChangeSuccessHeader: 'Editing a report',
  analyticNameChangeSuccess: 'Report title changed successfully',
  analyticNameChangeError: 'Failed to change report title',
  downloadReport: '{name} is downloading',
  downloadReportError: 'Failed to download report',
  sendReportSuccessHeader: 'Sending a report',
  sendReportSuccess: 'Report sent successfully!',
  sendReportError: 'Failed to send report',
  saveAnalyticSuccessHeader: 'Saving analytics',
  saveAnalyticSuccess: 'Analytics saved successfully!',
  saveAnalyticError: 'Failed to save analytics',
  addDeviceSuccessHeader: 'Adding a device',
  addDeviceSuccess: 'Device added successfully',
  addDeviceError: 'Failed to add device',
  changeCallFrequencySuccessHeader: 'Changing the call frequency',
  changeCallFrequencySuccess: 'Communication frequency successfully changed',
  changeCallFrequencyError: 'Failed to change call frequency',
  pollError: 'Failed to poll device',
  changeDeviceSettingsSuccessHeader: 'Change device settings',
  changeDeviceSettingsSuccess: 'Device settings changed successfully',
  changeDeviceSettingsError: 'Failed to change device settings',
  removeDeviceSuccessHeader: 'Removing a device',
  removeDeviceSuccess: 'Device removed successfully',
  removeDeviceError: 'Unable to remove device',
  changeSimSuccessHeader: 'Number change',
  changeSimSuccess: 'Number changed successfully',
  changeSimError: 'Failed to change number',
  changeReportSuccessHeader: 'Editing a report',
  changeReportSuccess: 'Report modified successfully',
  changeReportError: 'Failed to edit report',
  removeReportSuccessHeader: 'Deleting a report',
  removeReportSuccess: 'Report deleted successfully',
  removeReportError: 'Failed to delete report',
  createReportSuccessHeader: 'Create a report',
  createReportSuccess: 'Report created successfully',
  createReportError: 'Failed to generate report',
  getMeteringPointsListError: 'Failed to get list of accounting points',
  getDevicesListError: 'Failed to get device list',
  getReportsHistoryError: 'Failed to get report history',
  getMeteringsError: 'Failed to get meter readings',
  saveMeteringsError: 'Failed to save readings',
  noMeterReadings: 'Enter meter readings',
  checkAddressesDevicesError: 'It was not possible to check the availability of equipment at the facility',
  getDeviceInfoError: 'Failed to get device information',
  untieDevicesSuccessHeader: 'Untying equipment',
  untieDevicesSuccess: 'The equipment has been successfully untied',
  untieDevicesError: 'Failed to untie the equipment',
  tieDevicesSuccessHeader: 'Hardware binding',
  tieDevicesSuccess: 'The equipment is successfully linked',
  tieDevicesError: 'Failed to link а equipment',
}

export const requestsEn = createTranslationsWithSpaceName(translations, spaceName)
