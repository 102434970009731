import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";
import {addressEndpoint} from "@/data/api_entities/address/address_endpoint";
import {setMessage} from "@/presentation/shared/ui/message";
import {createEffect, createEvent, sample} from "effector";
import cookiesManager from "@/internal/lib/services/cookiManager";
import {useEvent, useStore} from "effector-react";

const addressesModel = new StoreModelFabric([])
const lastGetAddressesRequestModel = new StoreModelFabric(null)
const selectedAddressModel = new StoreModelFabric()
const isFadeActiveObjSelectModel = new StoreModelFabric(false)
const updateAddresses = createEvent()

addressesModel.getData = createEffect(() => {
  if (lastGetAddressesRequestModel.$store.getState()) {
    lastGetAddressesRequestModel.$store.getState().abort()
  }

  const {promise, controller} = addressEndpoint.getAllAddresses()

  lastGetAddressesRequestModel.setState(controller)

  promise
    .then(res => {
      if (!res || !Array.isArray(res) || !res.length) {
        addressesModel.setState([])
      } else {
        addressesModel.setState(res)
      }

      lastGetAddressesRequestModel.resetStore()
    })
    .catch((err) => {
      if (err.name === 'AbortError') return

      lastGetAddressesRequestModel.resetStore()
      console.log(err)
      setMessage({
        header: 'Ошибка!',
        message: 'Не удалось загрузить список объектов учета',
        type: 'error',
        count: 3000
      })
    })
})

addressesModel.$store.watch(state => {
  if (state.length) {
    const cookiesAddressId = cookiesManager.get('X-pulseAddress') ?? 0

    if (cookiesAddressId) {
      if (cookiesAddressId == selectedAddressModel.$store.getState()?.id) return
      const selectedAddress = state.find(address => address.id == cookiesAddressId)

      selectedAddressModel.setState(selectedAddress ?? null)
    }
  } else {
    selectedAddressModel.resetStore()
  }
})

sample({
  clock: updateAddresses,
  target: addressesModel.getData
})

export const addressesSelectors = {
  addresses: {
    ...addressesModel.createSelectors(),
    useUpdateAddresses: () => useEvent(updateAddresses),
    useGetAddresses: () => useEvent(addressesModel.getData),
    useAddressesAvailable:  () => !!useStore(addressesModel.$store).length
  },
  lastGetAddressesRequest: lastGetAddressesRequestModel.createSelectors(),
  selectedAddress: selectedAddressModel.createSelectors(),
  isFadeActiveObjSelect: isFadeActiveObjSelectModel.createSelectors(),
  selectedAddressModel
}
