import {combine, createEvent, createStore, sample, createEffect} from "effector";
import {useEvent, useStore} from 'effector-react'
import {isEqual} from "lodash";
import {analyticsEndpoint} from "@/data/api_entities/analytics/analytics_endpoint";
import {setMessage} from "@/presentation/shared/ui/message";
import {analyticsSelectors} from "@/internal/lib/storeModels/models/analytics/analyticsModels";
import {FormattedMessage} from "react-intl";

export default class ObjectAnalyticsFormFabric {
  constructor(
    {
      initIntakeData,
      initDurationsData,
      initTemperatureData,
      initInvestmentsData,
      addressId,
      analyticId
    }) {
    this.$analyticId = createStore(analyticId)
    this.$addressId = createStore(addressId)
    this.$isEqualData = createStore(false)
    this.$intake = createStore(initIntakeData)
    this.$durations = createStore(initDurationsData)
    this.$temperature = createStore(initTemperatureData)
    this.$investments = createStore(initInvestmentsData)
    this.$previousFormData = createStore({
      intake: initIntakeData,
      durations: initDurationsData,
      temperature: initTemperatureData,
      investments: initInvestmentsData
    })
    this.$hasInvestment = createStore(false)
    this.$intakeSum = createStore(0)
    this.setIntakeSum = createEvent()

    this.$intakeSum.on(this.setIntakeSum, (state, payload) => payload)

    this.$durationsSum = createStore(0)
    this.setDurationsSum = createEvent()

    this.$durationsSum.on(this.setDurationsSum, (state, payload) => payload)

    this.$investSum = createStore(0)
    this.setInvestSum = createEvent()

    this.$investSum.on(this.setInvestSum, (state, payload) => payload)

    this.$data = combine(this.$intake, this.$durations, this.$temperature, this.$investments, ($intake, $durations, $temperature, $investments) => {
      return (
        {
          intake: $intake,
          durations: $durations,
          temperature: this.transformTemperatureDataByDurations($durations, $temperature),
          investments: $investments
        }
      )
    })

    this.setTemperatureValue = createEvent()
    this.setTemperatures = createEvent()
    this.setIntakeValue = createEvent()
    this.setIntakes = createEvent()
    this.setDurationValue = createEvent()
    this.setDurations = createEvent()
    this.setInvestmentValue = createEvent()
    this.setInvestments = createEvent()
    this.saveChanges = createEvent()
    this.setPreviousData = createEvent()
    this.setIsEqual = createEvent()

    this.sendData = createEffect(({addressId, data, isEqual}) => {
      const analyticId = analyticsSelectors?.analyticId?.analyticIdModel?.$store.getState()

      if (isEqual || !analyticId) return

      const meterings = {
        [addressId]: data
      }

      const {promise} = analyticsEndpoint.changeAnalytic(meterings, analyticId)

      promise
        .then(() => {
          this.setPreviousData(data)
        })
        .catch(err => {
          console.log(err)
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: <FormattedMessage id={'requests.saveMeteringsError'}/>,
            type: 'error',
            count: 3000
          })
        })
    })

    this.compareWithPreviousData = createEffect((data) => {
      const isEqualData = isEqual(data[0], data[1])

      this.setIsEqual(isEqualData)
    })

    this.$isEqualData.on(this.setIsEqual, (s, payload) => payload)

    this.$intake
      .on(this.setIntakeValue, (s, payload) => {
        return {
          ...s,
          [payload.key]: payload.value
        }
      })
      .on(this.setIntakes, (s, payload) => {
        return payload
      })
      .watch((state) => {
        let sum = 0
        for (const key in state) {
          sum += +state[key]
        }

        const roundSum = Math.round((sum * 100))/100
        this.setIntakeSum(roundSum)
      })

    this.$durations
      .on(this.setDurationValue, (s, payload) => {
        return {
          ...s,
          [payload.key]: payload.value
        }
      })
      .on(this.setDurations, (s, payload) => payload)
      .watch((state) => {
        let sum = 0

        for (const key in state) {
          sum += state[key].length
        }

        this.setDurationsSum(sum)
      })

    this.$temperature
      .on(this.setTemperatureValue, (s, payload) => {
        return {
          ...s,
          [payload.key]: payload.value
        }
      })
      .on(this.setTemperatures, (s, payload) => payload)

    this.$investments
      .on(this.setInvestments, (s, payload) => payload)
      .on(this.setInvestmentValue, (s, payload) => {
        return {
          ...s,
          [payload.key]: payload.value
        }
      })
      .watch(state => {
        let sum = 0
        for (const key in state) {
          sum += +state[key]
        }

        const roundSum = Math.round((sum * 100))/100

        this.setInvestSum(roundSum)

      })

    this.$previousFormData.on(this.setPreviousData, (s, payload) => payload)

    sample({
      clock: this.saveChanges,
      source: [this.$data, this.$previousFormData],
      target: this.compareWithPreviousData
    })

    sample({
      clock: this.compareWithPreviousData.done,
      source: {
        analyticId: this.$analyticId,
        addressId: this.$addressId,
        data: this.$data,
        isEqual: this.$isEqualData
      },
      target: this.sendData
    })

    this.setPreExistData = createEffect(({data, dataName, storeName, setValueMethodName, preexistAddresses, addressId}) => {
      const preExistData = data[dataName][addressId]

      if (!preExistData) return

      const currentData = this[storeName].getState()

      for (const key in preExistData) {
        const currentValue = currentData[key]

        if (currentValue === undefined) continue
        if (storeName === '$intake' && preexistAddresses.includes(String(addressId))) continue
        if (storeName === '$temperature' && currentValue !== '') continue
        if (storeName === '$investments' && currentValue !== '0') continue
        if (storeName === '$intake' && currentValue !== '0') continue

        if (storeName === '$temperature' && currentValue === null) {
          this[setValueMethodName]({
            key,
            value: ''
          })
          continue
        }

        this[setValueMethodName]({
          key,
          value: preExistData[key]
        })
      }
    })
  }

  transformTemperatureDataByDurations(durationsData, temperatureData) {
    const transformTempData = {}

    for (const key in durationsData) {
      transformTempData[key] = durationsData[key].length
        ? temperatureData[key]
        : null
    }

    return transformTempData
  }

  createSelectors() {
    return {
      useCurrentIntakeValue: (key) => useStore(this.$intake)[key],
      useSetCurrentIntakeValue: () => useEvent(this.setIntakeValue),
      useSaveChanges: () => useEvent(this.saveChanges),
      useIntakeValues: () => useStore(this.$intake),
      useIntakeSum: () => useStore(this.$intakeSum),
      useCurrentDurationValue: (key) => useStore(this.$durations)[key],
      useSetCurrentDurationValue: () => useEvent(this.setDurationValue),
      useDurationsSum: () => useStore(this.$durationsSum),
      useDurations: () => useStore(this.$durations),
      setDurations: this.setDurations,
      useCurrentTempValue: (key) => useStore(this.$temperature)[key],
      useSetCurrentTempValue: () => useEvent(this.setTemperatureValue),
      setCurrentTempValue: this.setTemperatureValue,
      getCurrentTempValue: (key) => this.$temperature.getState()[key],
      useTempValues: () => useStore(this.$temperature),
      setTemperature: this.setTemperatures,
      useCurrentInvestValue: (key) => useStore(this.$investments)[key],
      useSetCurrentInvestValue: () => useEvent(this.setInvestmentValue),
      useInvestSum: () => useStore(this.$investSum),
      useAddressId: () => useStore(this.$addressId),
      setPreExistData: this.setPreExistData,
      getFullData: this.$data.getState,
      getIntakeData: this.$intake.getState,
      getDurationsData: this.$durations.getState,
      getTempData: this.$temperature.getState,
      getInvestData: this.$investments.getState,
    }
  }
}
