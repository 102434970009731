import Select, {components} from "react-select";
import {Edit} from "@mui/icons-material";
import React, {useRef, useState} from "react";
import {customStyles, customStylesError} from "../const";
import DropdownIndicator from "./CustomDropdownIndicator";
import {MULTI_OBJECTS_SELECT} from "./const";
import {filteredAddressOptions} from "./objects_select_fns";
import {
  analyticsSelectors
} from "@/internal/lib/storeModels/models/analytics/analyticsModels";
import {FormattedMessage} from "react-intl";

const MenuList = ({ children, ...props }) => {
  const resetEditAddressId = analyticsSelectors.editAddressId.useReset()
  const setIsShowEditForm = analyticsSelectors.isShowEditForm.useSetValue()
  const setIsShowAddForm = analyticsSelectors.isShowAddForm.useSetValue()

  const onClickAddObj = () => {
    setIsShowEditForm(false)
    resetEditAddressId()
    setIsShowAddForm(true)
  }

  return (
    <components.MenuList {...props}>
      <div
        className='select__add-option'
      >
        <div
          onClick={onClickAddObj}
        >
          <FormattedMessage id={'app.addNew'}/>
        </div>
      </div>
      {children}
    </components.MenuList>
  )
}

const MultiObjectSelect = (
  {
    addresses,
    onEditMulti,
    name,
    value,
    isError,
    handleChange,
    editAddressId,
    handleClickEditAddress
  }) => {
  const select = useRef()
  const [input, setInput] = useState("")

  const handleMouseDown = (e) => {
    const {target} = e

    if (target && target.closest('.select-edit-icon')) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  const handleMultiValueClick = (e, props) => {
    e.stopPropagation()
    e.preventDefault()

    const {target} = e

    if (target && target.closest('.select-edit-icon')) {
      onEditMulti(e, props.data.id)
    }
  }

  const formatOptionLabel = option => {
    const apartment = option.apartment
      ? `  кв ${option.apartment}`
      : option.apartment

    return (
      <div className={'address-select'}>
        <div className={'address-select__title-wrapper'}>
          <span>{option.name}</span>

          <span>{option.street} {option.building}{apartment}</span>
        </div>

        <div
          className={`address-select__btn ${editAddressId === option.id ? 'active' : ''}`}
          onClick={(e) => handleClickEditAddress(e, option.id)}
        >
          <Edit className={'address-select__icon'}/>
        </div>
      </div>
    )
  }

  const MultiValue = (props) => {
    return (
      <div onMouseDown={handleMouseDown} onClick={(e) => handleMultiValueClick(e, props)}>
        <components.MultiValue {...props}>
          {props.children.props.children[0].props.children[0]}
          <div className="select-edit-icon">
            <Edit className={'select-edit-icon__icon'}/>
          </div>
        </components.MultiValue>
      </div>
    )
  }

  return (
    <Select
      ref={select}
      name={name}
      value={value}
      autoFocus={false}
      options={addresses}
      maxMenuHeight={280}
      menuPosition={'absolute'}
      menuPlacement={'auto'}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{ DropdownIndicator, MultiValue, MenuList, }}
      styles={isError ? {...customStylesError, ...MULTI_OBJECTS_SELECT} : {...customStyles, ...MULTI_OBJECTS_SELECT}}
      isMulti
      isClearable={false}
      onChange={handleChange}
      filterOption={filteredAddressOptions}
      getOptionLabel={formatOptionLabel}
      getOptionValue={option => option.id}
      placeholder={null}
      backspaceRemovesValue={false}
      captureMenuScroll={false}
      inputValue={input}
      onInputChange={(value, action) => {
        if (action.action === "input-change") setInput(value)
      }}
      noOptionsMessage={() => <FormattedMessage id={'app.nothingFound'}/>}
    />
  )
}

export default MultiObjectSelect
