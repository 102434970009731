import StyledComponents from "@/presentation/components/heatCalculationPage/components/MKDPage/components/StyledComponents";
import {MKDHelper} from "@/presentation/components/heatCalculationPage/helpers";
import HoveredPopover from "@/presentation/components/common/HoveredPopover";

const ResultEnumeratorRow = (
  {
    withApartmentInfo = false,
    apartment,
    enumerator
  }) => {
  const validationMsg = enumerator.generate_end_period
    ? 'Сгенерированное значение на основании потребления остальных ОП на данной квартире'
    : enumerator.deviation
      ? 'Потрбление данного ОП меньше(больше) среднего потребления по МКД в 5(3) раза'
      : ''

  return (
    <StyledComponents.TableRow>
      {withApartmentInfo &&
        <>
          <StyledComponents.BodyTableCell
            rowSpan={apartment.enumerator.length + 1}
          >
            {apartment.kv}
          </StyledComponents.BodyTableCell>
          <StyledComponents.BodyTableCell
            rowSpan={apartment.enumerator.length + 1}
          >
            {apartment.area}
          </StyledComponents.BodyTableCell>
        </>
      }

      <StyledComponents.BodyTableCell>
        {enumerator.number}
      </StyledComponents.BodyTableCell>

      <StyledComponents.BodyTableCell>
        {enumerator.coefficient}
      </StyledComponents.BodyTableCell>

      <StyledComponents.BodyTableCell>
        {enumerator.power}
      </StyledComponents.BodyTableCell>

      <StyledComponents.BodyValueTableCell>
        {enumerator.start_period}
      </StyledComponents.BodyValueTableCell>

      <StyledComponents.BodyValueTableCell>
        {enumerator.end_period}
      </StyledComponents.BodyValueTableCell>

      <HoveredPopover
        text={validationMsg}
        disabled={!validationMsg.length}
      >
        <StyledComponents.BodyValueTableCell
          sx={{
            background: enumerator.generate_end_period
              ? '#fff5b1'
              : enumerator.deviation
                ? '#f7f060'
                : 'transparent'
          }}
        >
          {MKDHelper.formatNumber(enumerator.Ui)}
        </StyledComponents.BodyValueTableCell>
      </HoveredPopover>

      <StyledComponents.BodyTableCell colSpan={4}/>
    </StyledComponents.TableRow>
  )
}

export default ResultEnumeratorRow
