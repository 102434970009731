import {styled} from "@mui/material/styles";
import {TableCell, TableFooter, TableRow} from "@mui/material";

const StyledComponents = {
  HeadTableCell: styled(TableCell)({
    fontSize: '14px',
    lineHeight: 'calc(20/14 * 100%)',
    fontWeight: 500,
    color: '#36455D',
    background: '#ECF0F6'
  }),
  HeadValueTableCell: styled(TableCell)({
    fontSize: '14px',
    lineHeight: 'calc(20/14 * 100%)',
    fontWeight: 500,
    color: '#36455D',
    background: '#ECF0F6',
    textAlign: 'right',
  }),
  TableRow: styled(TableRow)({
    border: '1px solid #ECF0F6'
  }),
  BodyTableCell: styled(TableCell)({
    border: '0.5px solid #ECF0F6',
    fontSize: '14px',
    lineHeight: 'calc(20/14 * 100%)',
    fontWeight: 400,
    color: '#36455D',
  }),
  BodyValueTableCell: styled(TableCell)({
    border: '0.5px solid #ECF0F6',
    fontSize: '14px',
    lineHeight: 'calc(20/14 * 100%)',
    fontWeight: 400,
    color: '#36455D',
    textAlign: 'right'
  }),
  TableFooter: styled(TableFooter)({
    position: 'sticky',
    bottom: 0,
    background: '#fff'
  }),
  FooterTableCell: styled(TableCell)({
    border: '0.5px solid #ECF0F6',
    fontSize: '14px',
    lineHeight: 'calc(20/14 * 100%)',
    fontWeight: 500,
    color: '#36455D',
  }),
  FooterValueTableCell: styled(TableCell)({
    border: '0.5px solid #ECF0F6',
    fontSize: '14px',
    lineHeight: 'calc(20/14 * 100%)',
    fontWeight: 500,
    color: '#36455D',
    textAlign: 'right'
  }),
}

export default StyledComponents
