import {LOCALES} from "@/i18n/locales";
import {
  addressFormDe,
  addressFormEn,
  addressFormRu,
  analyticsDe,
  analyticsEn,
  analyticsRu,
  appDe,
  appEn,
  appRu,
  authorizationDe,
  authorizationEn,
  authorizationRu, backCallDe, backCallEn, backCallRu,
  deviceFormDe,
  deviceFormEn,
  deviceFormRu, devicesPageDe,
  devicesPageEn,
  devicesPageRu, intakeDe, intakeEn, intakeRu,
  mainDe,
  mainEn,
  mainRu,
  meteringPointFormDe,
  meteringPointFormEn,
  meteringPointFormRu, meteringPointsPageDe, meteringPointsPageEn, meteringPointsPageRu,
  navBarDe,
  navBarEn,
  navBarRu,
  objectTypesDe,
  objectTypesEn,
  objectTypesRu,
  passwordRulesDe,
  passwordRulesEn,
  passwordRulesRu, reportsPageDe, reportsPageEn, reportsPageRu,
  requestsDe,
  requestsEn,
  requestsRu,
  resourcesTypesDe,
  resourcesTypesEn,
  resourcesTypesRu,
  unitsDe,
  unitsEn,
  unitsRu,
  userPageDe,
  userPageEn,
  userPageRu,
  validationsMsgsDe,
  validationsMsgsEn,
  validationsMsgsRu
} from "@/i18n/translations";

export const messages = {
  [LOCALES.ENGLISH]: {
    ...appEn,
    ...userPageEn,
    ...validationsMsgsEn,
    ...passwordRulesEn,
    ...requestsEn,
    ...addressFormEn,
    ...objectTypesEn,
    ...meteringPointFormEn,
    ...resourcesTypesEn,
    ...navBarEn,
    ...authorizationEn,
    ...unitsEn,
    ...analyticsEn,
    ...mainEn,
    ...deviceFormEn,
    ...devicesPageEn,
    ...reportsPageEn,
    ...intakeEn,
    ...backCallEn,
    ...meteringPointsPageEn
  },
  [LOCALES.RUSSIAN]: {
    ...appRu,
    ...userPageRu,
    ...validationsMsgsRu,
    ...passwordRulesRu,
    ...requestsRu,
    ...addressFormRu,
    ...objectTypesRu,
    ...meteringPointFormRu,
    ...resourcesTypesRu,
    ...navBarRu,
    ...authorizationRu,
    ...unitsRu,
    ...analyticsRu,
    ...mainRu,
    ...deviceFormRu,
    ...devicesPageRu,
    ...reportsPageRu,
    ...intakeRu,
    ...backCallRu,
    ...meteringPointsPageRu
  },
  [LOCALES.GERMAN]: {
    ...appDe,
    ...userPageDe,
    ...validationsMsgsDe,
    ...passwordRulesDe,
    ...requestsDe,
    ...addressFormDe,
    ...objectTypesDe,
    ...meteringPointFormDe,
    ...resourcesTypesDe,
    ...navBarDe,
    ...authorizationDe,
    ...unitsDe,
    ...analyticsDe,
    ...mainDe,
    ...deviceFormDe,
    ...devicesPageDe,
    ...reportsPageDe,
    ...intakeDe,
    ...backCallDe,
    ...meteringPointsPageDe
  }
}
