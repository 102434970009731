export const DEVICE_FORM_INIT_DATA = Object.freeze({
  type: 0,
  serial: '',
  rsAddress: '',
  sim: '',
})

export const DEVICE_FORM_INIT_VALIDATIONS = Object.freeze({
  sim: [],
  serial: [],
  rsAddress: []
})
