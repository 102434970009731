import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'devicesPage'
const translations = {
  equipment: 'Ausrüstung',
  noDevices: 'Sie können neue ausrüstung hinzufügen, indem Sie auf + klicken',
  willConnect: 'In kontakt kommen - {date}',
  notConfigured: 'Nicht konfiguriert',
  activityLog: 'Aktivitätsprotokoll',
  scoreboard: 'Anzeigetafel',
  net: 'Netz',
  state: 'Bundesland',
  lastUpdate: 'Letztes update',
  title: 'Name',
  value: 'Bedeutung',
  notBeenConnectedYet: 'Dieses gerät wurde noch nicht verbunden.',
  serial: 'Fab №',
  version: 'Ausführung',
  voltage: 'U, V',
  statusCode: 'Statuscode',
  reportPeriodNum: 'Nummer des berichtszeitraums',
  channelOne: 'Kanal ID V1',
  channelOneVolume: 'V1, m3',
  channelOneVolumeDate: 'V1, m3 zum vorherigen stichtag',
  channelNum: '№ kanal',
  pulseWeight: 'Masse imp.,l',
  pulseOneCount: 'Anzahl der impulse N1',
  channelTwo: 'Kanal ID V2',
  channelTwoVolume: 'V2, m3',
  channelTwoVolumeDate: 'V2, m3 zum vorherigen stichtag',
  pulseTwoCount: 'Anzahl der impulse N2',
  localTime: 'Die ortszeit',
  periodFrom: 'Zeitraum ab',
  to: 'Durch',
  entries: '{count} einträge',
  operation: 'Betrieb',
  message: 'Nachricht',
  source: 'Quelle',
  executionStatus: 'Ausführungsstatus',
  generalSettings: 'Allgemeine einstellungen',
  devices: 'Geräte',
  pulseInputOne: 'Impulseingang 1',
  pulseInputTwo: 'Impulseingang 2',
  tempSensor: 'Temperatursensor',
  simSettings: 'Einstellungen der SIM-Karte',
  systemConnecting: 'Verbinden mit dem system',
  noInfo: 'Keine information',
  lastSession: 'Letzte sitzung',
  nextSession: 'Nächste sitzung',
  contactFrequency: 'Kontakthäufigkeit',
  devicePolling: 'Abfragegerät läuft, bitte warten..',
  stopPolling: 'Umfrage beenden',
  pollNow: 'Jetzt abfragen',
  meteringPoint: 'Abrechnungspunkt',
  storedInSystem: 'Die messwerte werden am ausgewählten zählpunkt im System gespeichert',
  setUpMeteringPoint: 'Richten sie eine abrechnungsstelle ein, um messwerte im System zu speichern',
  accountingObject: 'Buchhaltungsobjekt',
  location: 'Installationsort',
  warrantyUpTo: 'Garantie bis',
  serialNumber: 'Seriennummer',
  'removeDevice?': 'Gerät entfernen?',
  simNumber: 'SIM-Kartennummer',
  servicedByWatt: 'Von Watt gewartete SIM-Karte',
  changeSim: 'SIM wechseln',
  deviceUnbind: 'Gerät entbinden',
  deviceBind: 'Gerätebindung',
  successfullyUnpaired: 'Gerät erfolgreich entkoppelt',
  successfullyPaired: 'Gerät erfolgreich gekoppelt',
  failedUnpaired: 'Gerät kann nicht entkoppelt werden',
  failedPaired: 'Gerät konnte nicht gekoppelt werden',
  untie: 'Lösen',
  bind: 'Binden',
  deviceSearch: 'Gerätesuche',
  tied: 'Gebunden',
  noTied: 'Keine verbundenen geräte',
  inVisibility: 'IN SICHTBARKEIT',
  noDevicesAvailable: 'Keine geräte verfügbar',
  time: 'Zeit',
  reading: 'Indikation',
  toBindDevice: 'Gerät binden',
  availableDevices: 'Verfügbare Geräte',
  untieAndChange: 'Lösen und ändern',
  editDeviceSettings: 'Bearbeiten von Geräteeinstellungen',
  untieAll: 'Hardware lösen',
  untieAllTitle: 'Bestätigung der Entkopplung von Geräten',
  untieAllBody: 'Diese Aktion löst das Gerät von den angeschlossenen Geräten ab',
  removeDevice: 'Gerät entfernen',
  alertEdit: 'Trennen Sie das Gerät von anderen Geräten, bevor Sie es an ein anderes Buchhaltungsobjekt binden.',
  alertDelete: 'Trennen Sie die angeschlossenen Geräte vor dem Entfernen vom Gerät',
  createAndTie: 'Geräte erstellen und verknüpfen'
}

export const devicesPageDe = createTranslationsWithSpaceName(translations, spaceName)
