import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'intake'
const translations = {
  consumption: 'Потребление',
  chartType: 'Тип графика',
  mode: 'Режим',
  display: 'Отображать',
  year: 'Год',
  month: 'Месяц',
  forecastName: 'Прогноз {name}',
  lastYear: 'Прошлый год',
  thisYear: 'Текущий год',
  forecast: 'Прогноз',
  m: 'м',
  byDays: 'По дням',
  byMonths: 'По месяцам',
  byYears: 'По годам',
  fact: 'Факт',
  comparison: 'Сравнение',
  average: 'Среднее - ',
  meteringPoints: 'Точки учета',
  counter: 'Счетчик',
  values: 'Показания',
  onMoment: 'На момент',
  startPeriod: 'На начало периода',
  endPeriod: 'На конец периода',
  increment: 'Приращение',
}

export const intakeRu = createTranslationsWithSpaceName(translations, spaceName)
