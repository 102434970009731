import {FIRST_EMAIL_INPUT} from "./const";
import {emailValidator} from "@/internal/validators/validationsRules";
import {EMAIL_VALIDATION_MSG} from "@/internal/validators/validationsMessages";
import ValidationsWrapper from "./formComponents/ValidationsWrapper";
import {Basket} from "@/assets/svg";
import React from "react";
import {FormattedMessage} from "react-intl";

const MultiEmailsInputs = (
  {
    formSelectors,
    name,
    classNames = ''
  }) => {
  const valuesList = formSelectors.useFormDataValue(name)
  const setValuesList = formSelectors.useSetFormDataValue(name)
  const validationAvailable = formSelectors.useIsValidationsAvailable()

  const inputsInfo = !valuesList.length
    ? [FIRST_EMAIL_INPUT]
    : valuesList.map(value => {
      const validationMsg = value
        ? emailValidator(value)
          ? []
          : [EMAIL_VALIDATION_MSG]
        : []

      return {
        value,
        validationMsg: validationAvailable
          ? validationMsg
          : []
      }
    })

  const removeEmailInput = () => {
    if (inputsInfo.length === 1) return

    const newEmailsList = [...valuesList]
    newEmailsList.pop()

    setValuesList(newEmailsList)
  }

  const onChangeEmailInputs = (e) => {
    const {value, name} = e.target
    const newEmailsList = [...valuesList]

    newEmailsList[name] = value.trim()

    setValuesList(newEmailsList)
  }

  const addEmailInput = () => {
    const newEmailsList = valuesList.length
      ? [...valuesList, '']
      : ['', '']

    setValuesList(newEmailsList)
  }

  const emailInputsList = inputsInfo.map((inputInfo, i) => {
    return (
      <div
        key={i}
        className='report-form__email-wrapper'
      >
        <div className='form-group'>
          <label>E-mail</label>

          <ValidationsWrapper validationMsgs={inputInfo.validationMsg}>
            <input
              value={inputInfo.value}
              type={'text'}
              name={String(i)}
              onChange={onChangeEmailInputs}
            />
          </ValidationsWrapper>
        </div>

        {inputsInfo.length !== 1 && i === inputsInfo.length - 1 &&
          <button onClick={removeEmailInput}>
            <img src={Basket} alt="Basket"/>
          </button>
        }
      </div>
    )
  })

  return (
    <div className={`emails-inputs ${classNames}`}>
      {emailInputsList}

      <div className="emails-inputs__add-email">
        <button onClick={addEmailInput}>+</button>

        <span>
          <FormattedMessage id={'app.addMail'}/>
        </span>
      </div>
    </div>
  )
}

export default MultiEmailsInputs
