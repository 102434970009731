import {FormattedMessage} from "react-intl";
import DevicesTypes from "@/presentation/components/devicesPage/devices_types";

export const AVAILABLE_DEVICES_LIST = [
  {
    value: 0,
    label: <FormattedMessage id={'app.notSpecified'}/>
  },
  {
    value: DevicesTypes.xPulseNBIoT,
    label: 'X-Pulse NB-IoT'
  },
  {
    value: DevicesTypes.GSMBridge,
    label: 'МОСТиК'
  },
  {
    value: DevicesTypes.xPulseOld,
    label: 'Домовой (Х-Пульс)'
  },
  {
    value: DevicesTypes.electronicFlowMeter,
    label: 'ЭР-Д'
  },
  {
    value: DevicesTypes.heatMeter,
    label: 'КОМБиК' // HEATMETER
  },
  {
    value: DevicesTypes.bridge,
    label: 'МОСТ' // BRIDGE
  }
]

export const MOSTICK_DEVICES_LIST = [
  {
    value: 0,
    label: <FormattedMessage id={'app.notSpecified'}/>
  },
  {
    value: 12,
    label: 'Домовой (Х-Пульс)'
  },
  {
    value: 8,
    label: 'ЭР-Д'
  },
]

export const MOST_DEVICES_LIST = [
  {
    value: 14,
    label: 'КОМБиК' // HEATMETER
  },
]

export const getSerialPlaceholder = (type, intl) => {
  switch (type) {
    case 3:
      return intl.formatMessage({id: 'deviceForm.serialPlaceholder'}, {start: 'FB'})
    case 8:
      return intl.formatMessage({id: 'deviceForm.serialPlaceholder'}, {start: '64'})
    case 12:
      return intl.formatMessage({id: 'deviceForm.serialPlaceholder'}, {start: '03'})
    case 15:
      return intl.formatMessage({id: 'deviceForm.serialPlaceholder'}, {start: 'FA'})
  }
}

export const MULTI_DEVICE_SELECT_STYLES = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? 0 : 0,
    border: '1px solid #ECF0F6',
    borderRadius: '0.375rem',
    ':hover': {
      border: '1px solid #ECF0F6'
    },
    minHeight: 'unset',
    cursor: 'pointer',
    backgroundColor: state.isDisabled ? 'rgba(239, 239, 239, 0.3)' : 'transparent',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: '0.625rem 0.5625rem',
    fontSize: '0.875rem',
    lineHeight: '0 !important',
    color: '#36455D',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
    maxWidth: '100%',
    background: '#fff',
    boxShadow: '0 .5rem 1rem rgba(53, 96, 181, 0.2)',
    borderRadius: '.375rem',
    overflowX: 'hidden',
    margin: '0',
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: (provided, state) => ({
    padding: '0 .75rem',
    transition: 'transform .1s ease',
    transform: `rotate(${state.selectProps.menuIsOpen ? '180deg' : '0'})`
  }),
  option: () => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    padding: '.75rem',
    borderBottom: '1px solid #ECF0F6',
    cursor: 'pointer',
    ':hover': {
      background: '#E6EFFE'
    },
    ':last-child': {
      borderBottom: 'none'
    },
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#BBC4D2',
  }),
  multiValue: () => ({
    display: 'none'
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    margin: 0,
    padding: '0.625rem 0.5625rem',
    display: 'flex',
    alignItems: 'center',
  }),
}
