import {request} from "@/internal/lib/transport/request";

export const apartmentsEndpoint = {
  getAll() {
    const params = {
      url: 'api/heat_apartment/',
      method: 'GET'
    }

    return request(params)
  },
  getById(id) {
    const params = {
      url: `api/heat_apartment/${id}/`,
      method: 'GET'
    }

    return request(params)
  },
  create(data) {
    const params = {
      url: 'api/heat_apartment/',
      method: 'POST',
      data: data
    }

    return request(params)
  },
  change(data, id, currentMKD) {
    const params = {
      url: `api/heat_apartment/${id}/`,
      method: 'PUT',
      data: {
        ...data,
        mansions: +currentMKD
      }
    }

    return request(params)
  },
  delete(id) {
    const params = {
      url: `api/heat_apartment/${id}/`,
      method: 'DELETE',
    }

    return request(params)
  },
  getEnumerators(id) {
    const params = {
      url: `api/heat_apartment/${id}/enumerator/`,
      method: 'GET'
    }

    return request(params)
  },
}
