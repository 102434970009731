import Select from "react-select";
import {getDeviceInfoByType} from "@/internal/helpers/helpers";
import DropdownIndicator from "@/presentation/components/common/selects/CustomDropdownIndicator";
import {MULTI_DEVICE_SELECT_STYLES} from "@/presentation/components/deviceForm/const";
import {
  devicesToLinkedDevicesSelectors
} from "@/internal/lib/storeModels/models/devices/forms/deviceFormModels";
import {deviceOptionFormatter} from "@/presentation/components/deviceForm/components/helpers";
import {FormattedMessage} from "react-intl";

const DevicesMultiSelect = ({devices, loading}) => {
  const [toLinkedDevices, setToLinkedDevices] = devicesToLinkedDevicesSelectors.useState()

  const onChange = (values) => setToLinkedDevices(values)

  return (
    <div className="form-group">
      <label>
        <FormattedMessage id={'deviceForm.toSelectEquipment'}/>
      </label>

      <Select
        value={toLinkedDevices}
        isLoading={loading}
        components={{DropdownIndicator}}
        maxMenuHeight={280}
        menuPosition={'absolute'}
        menuPlacement={'auto'}
        isMulti={true}
        options={devices}
        placeholder={''}
        styles={{...MULTI_DEVICE_SELECT_STYLES}}
        noOptionsMessage={() => <FormattedMessage id={'devicesPage.noDevicesAvailable'}/>}
        isSearchable={false}
        isClearable={false}
        closeMenuOnSelect={false}
        getOptionLabel={deviceOptionFormatter}
        getOptionValue={option => option.id}
        onChange={onChange}
      />
    </div>
  )
}

export default DevicesMultiSelect
