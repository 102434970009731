const Tabs = ({activeTab, children}) => {
  if (!children) return null

  const childrenArray = Array.isArray(children) ? children : [children]

  const currentTab =  childrenArray.map(child => {
    const { name } = child.props

    return activeTab === name ? child : null
  })

  return currentTab
}

export default Tabs
