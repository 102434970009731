import {request} from "@/internal/lib/transport/request";

export const suggestEndpoint = {
  getDadataInfo (data) {
    const params = {
      url: 'api/suggest/',
      isAuth: false,
      method: 'POST',
      data,
    }

    return request(params)
  },

  getDadataINNInfo (data) {
    const params = {
      url: 'api/dadatainn/',
      isAuth: false,
      method: 'POST',
      data,
    }

    return request(params)
  }
}