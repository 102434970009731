import AddIcon from "@mui/icons-material/Add";
import React from "react";

const AddButton = (
  {
    onMouseEnter,
    onMouseLeave,
    onClick
  }) => {
  return (
    <div
      className={'add-button'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <div>
        <AddIcon
          color={'red'}

        />
      </div>
    </div>
  )
}

export default AddButton
