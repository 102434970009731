import Loader from "@/presentation/components/loader/Loader";
import {useEffect} from "react";
import {reportsPageSelectors} from "@/internal/lib/storeModels/models/report/reportPageModels";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import {DEVICE_REPORT_TYPE, METERING_POINT_REPORT_TYPE, RESOURCE_REPORT_TYPE} from "../const";
import ReportItem from "./ReportItem";
import {FormattedMessage} from "react-intl";

const ReportsList = () => {
  const selectedAddress = addressesSelectors.selectedAddress.useValue()
  const reportsList = reportsPageSelectors.reportsList.useValue()
  const resetReportsList = reportsPageSelectors.reportsList.useReset()
  const isLoading = reportsPageSelectors.isLoadingReportsList.useValue()
  const resetIsLoading = reportsPageSelectors.isLoadingReportsList.useReset()
  const getReportsList = reportsPageSelectors.reportsList.useGetReports()

  useEffect(() => {
    getReportsList()

    return () => {
      resetReportsList()
      resetIsLoading()
    }
  }, [])

  const selectedAddressReports = reportsList.filter(report => report.address === selectedAddress?.id)

  const deviceReports = selectedAddressReports
    .filter(report => report.type === DEVICE_REPORT_TYPE)
    .map(report => {
      return (
        <ReportItem
          key={report.id}
          reportData={report}
        />
      )
    })

  const meteringPointReports = selectedAddressReports
    .filter(report => report.type === METERING_POINT_REPORT_TYPE)
    .map(report => {
      return (
        <ReportItem
          key={report.id}
          reportData={report}
        />
      )
    })

  const resourceReports = selectedAddressReports
    .filter(report => report.type === RESOURCE_REPORT_TYPE)
    .map(report => {
      return (
        <ReportItem
          key={report.id}
          reportData={report}
        />
      )
    })

  const reportsCount = deviceReports.length + meteringPointReports.length + resourceReports.length

  return (
    <Loader loading={isLoading}>
      {!reportsCount &&
        <p>
          <FormattedMessage id={'reportsPage.noReports'}/>
        </p>
      }

      {!!resourceReports.length &&
        <div className='reports-page__reports-block'>
          <h4 className="title_h4">
            <FormattedMessage id={'reportsPage.resources'}/>
          </h4>

          <div className="reports-page__reports-list">
            {resourceReports}
          </div>
        </div>
      }

      {!!deviceReports.length &&
        <div className='reports-page__reports-block'>
          <h4 className="title_h4">
            <FormattedMessage id={'reportsPage.systemDevice'}/>
          </h4>

          <div className="reports-page__reports-list">
            {deviceReports}
          </div>
        </div>
      }

      {!!meteringPointReports.length &&
        <div className='reports-page__reports-block'>
          <h4 className="title_h4">
            <FormattedMessage id={'reportsPage.points'}/>
          </h4>

          <div className="reports-page__reports-list">
            {meteringPointReports}
          </div>
        </div>
      }
    </Loader>
  )
}

export default ReportsList
