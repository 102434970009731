class HeatCalculationMocks {
  state = [
    new MKD({
      id: 1,
      region: 'Московская',
      city: 'Москва',
      street: 'Шипиловская',
      building: '29',
      odn: 35,
      unit_type: 1,
      servicemanager: 0
    }),
    new MKD({
      id: 2,
      region: 'Московская',
      city: 'Москва',
      street: 'Шипиловская',
      building: '28',
      odn: 40,
      unit_type: 1,
      servicemanager: 0
    }),
    new MKD({
      id: 3,
      region: 'Московская',
      city: 'Москва',
      street: 'Шипиловская',
      building: '27',
      odn: 45,
      unit_type: 1,
      servicemanager: 0
    }),
    new MKD({
      id: 4,
      region: 'Московская',
      city: 'Москва',
      street: 'Шипиловская',
      building: '26',
      odn: 60,
      unit_type: 1,
      servicemanager: 0
    })
  ]
  getMKDs() {
    return this.state
  }

  getNewMKD(data, currentState) {
    return new MKD({
      id: +currentState.length + 1,
      ...data,
      odn: +data.odn,
    })
  }
}

class MKD {
  constructor(props) {
    this.id = props.id
    this.region = props.region
    this.city = props.city
    this.street = props.street
    this.building = props.building
    this.odn = props.odn
    this.unit_type = props.unit_type
    this.servicemanager = props.servicemanager
  }
}

export const mocks = new HeatCalculationMocks()
