import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {NavLink} from "react-router-dom";
import React from "react";
import {selectedUserSelectors} from "@/internal/lib/storeModels/models/servicemen/selectedUserModel";

const UserCard = ({data}) => {
  const setSelectedUser = selectedUserSelectors.useSetValue()

  const onClickCard = () => {
    setSelectedUser(data)
  }

  return (
    <NavLink
      to={`/service/${data.id}/analytics`}
      className={`user-card`}
      onClick={onClickCard}
    >
      <div className="user-card__header">
        <div className="user-card__img">
          <AccountCircleOutlinedIcon
            className={'user-card__icon'}
          />
        </div>
        <div className={'user-card__name'}>
          <span>{data.login ?? ''}</span>
          <span>{data.user ?? ''}</span>
        </div>

      </div>

      <div className="user-card__body">
        <span>Баланс: {data.balance ?? 0} ₽</span>
        <span>Кол-во отчетов: {data.count_analytics ?? 0}</span>
        <span>Кол-во объектов: {data.count_address ?? 0}</span>
        <span>Заработано: {data.earnings ?? 0} ₽</span>
      </div>
    </NavLink>
  )
}

export default UserCard
