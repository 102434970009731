import DevicesTypes from "@/presentation/components/devicesPage/devices_types";

export const sendDataAdapter = (data, addressId) => {
  const slaves = data.slaves.map(slaveData => {
    if (typeof slaveData === 'number' ) {
      return slaveData
    } else {
      const defaultSlaveData = {
        address: addressId,
        type: slaveData.type,
        serial: slaveData.serial
      }

      return {
        ...defaultSlaveData,
        ...(DevicesTypes.devicesWithSim.includes(slaveData.type)
          ? {
            sim: slaveData.sim
          }
          : {}),
        ...(slaveData.type === DevicesTypes.heatMeter
          ? {
            rs_address: +slaveData.rsAddress
          }
          : {})
      }
    }
  })

  const mainDeviceDefaultData = !!data.type
    ? {
        address: addressId,
        type: data.type,
        serial: data.serial,
      }
    : {}

  return {
    ...mainDeviceDefaultData,
    ...(data.masterId === 0
      ? {}
      : {master: +data.masterId}),
    ...(DevicesTypes.devicesWithSim.includes(data.type)
      ? {
        sim: data.sim
      }
      : {}),
    ...(data.type === DevicesTypes.heatMeter
      ? {
        rs_address: +data.rsAddress
      }
      : {}),
    slaves,
  }
}