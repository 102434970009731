import {useEffect, useMemo, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {capitalizeFirstLetter} from "@/internal/formatters/formatters";
import Loader from "@/presentation/components/loader/Loader";
import {FormattedDate, FormattedMessage} from "react-intl";
import WithVisibilitySensor from "@/presentation/components/analytics/сomponents/WithVisibilitySensor";

const ResultTableGenerator = (
  {
    tableConfig,
    isPivot = false,
    startDate = '',
    endDate = ''
  }) => {
  const [firstTableHeader, setFirstTableHeader] = useState(null)
  const [isCreatingTableBody, setIsCreatingTableBody] = useState(true)
  const [loaderWrapper, setLoaderWrapper] = useState(null)

  const scrollToStartTable = () => {
    const loaderWrapper = document.querySelector('.tables > ._loading_overlay_wrapper')

    if (!loaderWrapper) return

    setLoaderWrapper(loaderWrapper)
    loaderWrapper.scrollTo(0, 0)
  }

  useEffect(() => {
    scrollToStartTable()
  }, [])

  const intervalDates = useMemo(() => {
    return (
      <>
        <FormattedDate
          value={startDate}
          month={'long'}
          year={'numeric'}
          children={capitalizeFirstLetter}
        /> - <FormattedDate
                value={endDate}
                month={'long'}
                year={'numeric'}
                children={capitalizeFirstLetter}
              />
      </>
    )
  }, [startDate, endDate])

  const firstTableHeaderHeight = useMemo(() => {
    if (!firstTableHeader) return 0

    return firstTableHeader.offsetHeight
  }, [firstTableHeader])

  const tableHeadRows = useMemo(() => {
    if (!tableConfig) return null

    return tableConfig.headerRows.map((row, i) => {
      return (
        <TableRow
          key={`result-head-row-${i}`}
        >
          {row.cellsList.map((cell, j) => {
            const topStyles = cell.topStyleForStickyHeader
              ? {top: firstTableHeaderHeight ?? 0}
              : {}

            return (
              <TableCell
                key={`result-head-row-${i}:cell-${j}`}
                rowSpan={cell.rowSpan ?? 1}
                colSpan={cell.colSpan ?? 1}
                sx={cell.styles
                  ? {...cell.styles, ...topStyles}
                  : {...topStyles}}
                ref={(elem) => {
                  if (cell.isRefForFirstTableHeaderRow) setFirstTableHeader(elem)
                }}
              >
                {cell.title}
              </TableCell>
            )
          })}
        </TableRow>
      )
    })
  }, [tableConfig, firstTableHeaderHeight])

  const rows = tableConfig?.bodyRows.map((row, i) => {
    return (
      <WithVisibilitySensor
        key={`result-body-row-${i}`}
        component={(
          <TableRow>
            {row.cellsList.map((cell, j) => {
                // console.log('cell.title',cell.title)
              return (
                <TableCell
                  key={`result-body-row-${i}:cell-${j}`}
                  sx={cell.styles ?? {}}
                  rowSpan={cell.rowSpan ?? 1}
                  colSpan={cell.colSpan ?? 1}
                >
                  {cell.title}
                </TableCell>
              )
            })}
          </TableRow>
        )}
        placeholderComponent={
          <TableRow
            sx={{
              height: '62px'
            }}
          />
        }
        setIsCreatingRows={setIsCreatingTableBody}
        sensorsProps={{
          containment: loaderWrapper
        }}
      />
    )
  }) ?? []


  return (
    <>
      {isPivot &&
        <>
          <h2 className="title pivot-title">{intervalDates}</h2>

          <div className="tables__marks">
            <div className={'tables__mark'}>
              <div className='tables__mark-color tables__mark-color_cold-green'/>

              <span>
                <FormattedMessage id={'analytics.bestAverageValue'}/>
              </span>
            </div>

            <div className={'tables__mark'}>
              <div className='tables__mark-color tables__mark-color_red'/>

              <span>
                <FormattedMessage id={'analytics.worseAverageValue'}/>
              </span>
            </div>
          </div>
        </>
      }

      <Loader
        loading={isCreatingTableBody}
        withoutSpinner
        styles={{
          wrapper: (base) => ({
            ...base,
            width: '100%',
            maxWidth: '100%',
            overflow: 'auto',
            minHeight: '18.75rem',
            marginBottom: '1.25rem'
          })
        }}
      >
        <div className="tables__all-wrapper">
          <Table stickyHeader>
            <TableHead>
              {tableHeadRows}
            </TableHead>

            <TableBody>
              {rows}
            </TableBody>
          </Table>
        </div>
      </Loader>
    </>
  )
}

export default ResultTableGenerator
