import {RESOURCES_TYPES} from "@/internal/content/consts";
import {NavLink} from "react-router-dom";

const PointCard = ({point}) => {
  return (
    <NavLink to={`${point.id}`} className={'point-card'}>
      <div className="point-card__header">
        <div className="point-card__img">
          <img src={RESOURCES_TYPES[point.type].img} alt="resource"/>
        </div>

        <span>{point.name}</span>
      </div>

      {/*<div className="point-card__body">*/}

      {/*</div>*/}
    </NavLink>
  )
}

export default PointCard
