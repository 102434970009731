import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'addressForm'
const translations = {
  newObject: 'New accounting object',
  objectParams: 'Object parameters',
  createFrom: 'Create from another object',
  objectType: 'Object type',
  residents: 'Number of residents',
  findAddress: 'Find object',
  enterAddress: 'Enter address or name',
  region: 'Region / District',
  city: 'City / Locality',
  street: 'Street',
  building: 'House',
  apartment: 'Apartment/Office',
  objectName: 'Object name',
  myHouse: 'My house',
  forAnalytic: 'For analytics',
  tariff: 'Tariff, {currency}/{unit}',
  area: 'Area sq.m.',
  lat: 'Latitude',
  lon: 'Longitude',
  'deleteObject?': 'Delete accounting object?',
  savingObject: 'Saving an object',
  removeObject: 'Deleting an object',
  preExistObject: 'The object with the given address is already {link}.',
  exists: 'exists',
  changeToOrUse: 'Change the address of the current object or use an already created one.',
  objectWithDevices: 'Devices are installed at this facility. {link} them to another object to keep access.',
  assign: 'Link',
  forCompare: 'To compare consumption',
  unitType: 'Unit of measurement',
  changesHistory: 'history of changes',
  areaChanges: 'Area changes',
  tariffsChanges: 'Tariff changes',
  areaValue: '{value} m<sup>2</sup>',
  tillDate: 'until {date}'
}

export const addressFormEn = createTranslationsWithSpaceName(translations, spaceName)
