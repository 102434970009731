import {UserLogo} from "@/assets/svg";
import {FormattedMessage} from "react-intl";
import {NavLink} from "react-router-dom";
import React from "react";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";

const Account = ({fromMain = false}) => {
  const userData = userDataSelectors.userData.useValue()
  const userName = userData?.login ?? '';

  return (
    <NavLink to={fromMain ? 'user_page' : 'user_page'} className="account">
      <div className="account__avatar">
        <img src={userData?.avatar ? /*userData.avatar*/ UserLogo : UserLogo} alt="avatar" />
      </div>

      <div className="account__info">
        <span className='account__name'>
          {userName.length === 0 ? '   ' : userName.split(' ')[0]}{(userName.split(' ')[1] && userName.split(' ')[1].slice(0, 1)) ?? ''}
        </span>

        <span className='account__balance'>
          <FormattedMessage
            id={'navBar.balance'}
            values={{
              balance: userData?.balance ?? 0
            }}
          />
        </span>
      </div>
    </NavLink>
  )
}

export default Account
