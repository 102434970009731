import {FormattedMessage} from "react-intl";

export const NEW_REPORT_PATH = 'add_report'

export const DEVICE_REPORT_TYPE = 1
export const METERING_POINT_REPORT_TYPE = 2
export const RESOURCE_REPORT_TYPE = 3

export const REPORTS_TYPES_LIST = [
  {
    value: 0,
    label: <FormattedMessage id={'app.notSpecified'} />
  },
  {
    value: DEVICE_REPORT_TYPE,
    label: <FormattedMessage id={'reportsPage.systemDevice'} />
  },
  {
    value: METERING_POINT_REPORT_TYPE,
    label: <FormattedMessage id={'reportsPage.meteringPoint'} />
  },
  {
    value: RESOURCE_REPORT_TYPE,
    label: <FormattedMessage id={'reportsPage.resource'} />
  },
]

export const MONTH_SCHEDULE_TYPE = 'month'
export const WEEK_SCHEDULE_TYPE = 'week'
export const DAY_SCHEDULE_TYPE = 'day'

export const SCHEDULE_TYPES_LIST = [
  {
    value: MONTH_SCHEDULE_TYPE,
    label: <FormattedMessage id={'reportsPage.byMonth'} />
  },
  {
    value: WEEK_SCHEDULE_TYPE,
    label: <FormattedMessage id={'reportsPage.byWeek'} />
  },
  {
    value: DAY_SCHEDULE_TYPE,
    label: <FormattedMessage id={'reportsPage.byDay'} />
  },
]

export const WEEK_TYPES_LIST = [
  {
    value: 1,
    label: <FormattedMessage id={'app.mon'}/>
  },
  {
    value: 2,
    label: <FormattedMessage id={'app.tue'}/>
  },
  {
    value: 3,
    label: <FormattedMessage id={'app.wed'}/>
  },
  {
    value: 4,
    label: <FormattedMessage id={'app.thu'}/>
  },
  {
    value: 5,
    label: <FormattedMessage id={'app.fri'}/>
  },
  {
    value: 6,
    label: <FormattedMessage id={'app.sat'}/>
  },
  {
    value: 7,
    label: <FormattedMessage id={'app.sun'}/>
  },
]

const createDaysList = () => {
  const arr = []

  for (let i = 1; i <= 28; i++) {
    arr.push({
      value: i,
      label: <FormattedMessage id={'reportsPage.byNum'} values={{num: i}}/>
    })
  }

  return arr
}

export const DAYS_LIST = createDaysList()
