import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'devicesPage'
const translations = {
  equipment: 'Оборудование',
  noDevices: 'Вы можете добавить новое оборудование нажав на +',
  willConnect: 'Выйдет на связь - {date}',
  notConfigured: 'Не настроено',
  activityLog: 'Журнал операций',
  scoreboard: 'Табло',
  net: 'Сеть',
  state: 'Состояние',
  lastUpdate: 'Последнее обновление',
  title: 'Название',
  value: 'Значение',
  notBeenConnectedYet: 'Данное устройство еще не выходило на связь',
  serial: 'Зав №',
  version: 'Версия',
  voltage: 'Uпит, В',
  statusCode: 'Код состояния',
  reportPeriodNum: 'Число отчетного периода',
  channelOne: 'Идентификатор канала V1',
  channelOneVolume: 'V1, m3',
  channelOneVolumeDate: 'V1, m3 на предыдущую отчетную дату',
  channelNum: '№ канала',
  pulseWeight: 'Вес имп.,л',
  pulseOneCount: 'Количество импульсов N1',
  channelTwo: 'Идентификатор канала V2',
  channelTwoVolume: 'V2, m3',
  channelTwoVolumeDate: 'V2, m3 на предыдущую отчетную дату',
  pulseTwoCount: 'Количество импульсов N2',
  localTime: 'Местное время',
  periodFrom: 'Период с',
  to: 'По',
  entries: '{count} записей',
  operation: 'Операция',
  message: 'Сообщение',
  source: 'Источник',
  executionStatus: 'Статус выполнения',
  generalSettings: 'Общие настройки',
  devices: 'Устройства',
  pulseInputOne: 'Импульсный вход 1',
  pulseInputTwo: 'Импульсный вход 2',
  tempSensor: 'Датчик температуры',
  simSettings: 'Настройки SIM-карты',
  systemConnecting: 'Подключение к системе',
  noInfo: 'Нет информации',
  lastSession: 'Последний сеанс связи',
  nextSession: 'Следующий сеанс связи',
  contactFrequency: 'Частота выхода на связь',
  devicePolling: 'Выполняется опрос устройства, ожидайте..',
  stopPolling: 'Прекратить опрос',
  pollNow: 'Опросить сейчас',
  meteringPoint: 'Точка учета',
  storedInSystem: 'Показания сохраняются в Системе по выбранной точке учета',
  setUpMeteringPoint: 'Настройте точку учета, чтобы сохранять показания в Системе',
  accountingObject: 'Объект учета',
  location: 'Место установки',
  warrantyUpTo: 'Гарантия до',
  serialNumber: 'Серийный номер',
  'removeDevice?': 'Удалить устройство?',
  simNumber: 'Номер SIM-карты',
  servicedByWatt: 'SIM-карта обслуживается Watt',
  changeSim: 'Изменить SIM-карту',
  deviceUnbind: 'Отвязка устройства',
  deviceBind: 'Привязка устройства',
  successfullyUnpaired: 'Устройство успешно отвязано',
  successfullyPaired: 'Устройство успешно привязано',
  failedUnpaired: 'Не удалось отвязать устройство',
  failedPaired: 'Не удалось привязать устройство',
  untie: 'Отвязать',
  bind: 'Привязать',
  deviceSearch: 'Поиск устройств',
  tied: 'Привязанные',
  noTied: 'Нет привязанных устройств',
  inVisibility: 'В зоне видимости',
  noDevicesAvailable: 'Нет доступных устройств',
  time: 'Время',
  reading: 'Показание',
  toBindDevice: 'Привязать устройство',
  availableDevices: 'Доступные устройства',
  untieAndChange: 'Отвязать и изменить',
  editDeviceSettings: 'Редактирование параметров устройства',
  untieAll: 'Отвязать оборудование',
  untieAllTitle: 'Подтверждение отвязки оборудования',
  untieAllBody: 'Данное действие отвяжет оборудование от привязанных устройств',
  removeDevice: 'Удаление устройства',
  alertEdit: 'Перед привязкой к другому объекту учета отвяжите оборудование от других устройств.',
  alertDelete: 'Перед удалением отвяжите от оборудования привязанные устройства',
  createAndTie: 'Создать и привязать устройства'
}

export const devicesPageRu = createTranslationsWithSpaceName(translations, spaceName)
