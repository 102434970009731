import LoadingWrapper from "./components/LoadingWrapper";

const Loading = ({children, loading}) => {
  return (
    <>
      {
        loading
          ? <LoadingWrapper />
          : children
      }
    </>
  )
}

export default Loading