import ValidationsWrapper from "./ValidationsWrapper";
import DefaultSelect from "../selects/DefaultSelect";
import {useMemo} from "react";
import {isEqual} from "lodash";

const SelectInput = (
  {
    label = null,
    name,
    formSelectors,
    options,
    getOptionValue = (option) => option.value,
    getOptionLabel = (option) => option.label,
    selectedValueName = 'value',
    loading = false,
    isShowValidMsg = true,
    styles = {},
    groupClass = '',
    withChangeValue,
    disabled = false,
    placeholder = '',
    isError = false,
    errorStyles = {}
  }
) => {
  const formValue = formSelectors.useFormDataValue(name)
  const setFieldData = formSelectors.useSetFormDataValue(name)
  const validations = formSelectors.useFormValueValidation(name)

  const value = useMemo(() => {
    return options.find(option => option[selectedValueName] === formValue || isEqual(option[selectedValueName], formValue)) ?? null
  }, [options, formValue,selectedValueName])

  const onChange = (value) => {
    if (disabled) return

    if (withChangeValue) {
      withChangeValue(value[selectedValueName])
    }

    setFieldData(value[selectedValueName])
  }

  return (
    <div className={`form-group ${groupClass}`}>
      {label &&
        <label>{label}</label>
      }

      <ValidationsWrapper
        validationMsgs={validations}
        isShowValidMsg={isShowValidMsg}
      >
        <DefaultSelect
          name={name}
          value={value}
          options={options}
          onChange={onChange}
          loading={loading}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          styles={!!validations.length || isError ? errorStyles : styles}
          isError={!!validations.length || isError}
          disabled={disabled}
          placeholder={placeholder}
        />
      </ValidationsWrapper>
    </div>
  )
}

export default SelectInput
