import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {INIT_CHANGE_EMAIL_FORM_DATA, INIT_CHANGE_EMAIL_VALIDATIONS} from "../const";
import {getChangeEmailValidators} from "@/internal/validators/formValidators";

const userEmailFormModel = new FormModelFabric({
  initialData: INIT_CHANGE_EMAIL_FORM_DATA,
  initialValidations: INIT_CHANGE_EMAIL_VALIDATIONS,
  getValidators: getChangeEmailValidators
})

export const userEmailFormSelectors = userEmailFormModel.createSelectors()
