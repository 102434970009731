import icons from "@/assets/svg/icons/icons.svg";
import {addressFormatter} from "@/internal/formatters/formatters";
import {mainPageSelectors} from "@/internal/lib/storeModels/models/mainPage/mainPageModel";
import {isOpenAsideSelectors} from "@/internal/lib/storeModels/models/status/asideFormModel";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import cookiesManager from "@/internal/lib/services/cookiManager";
import {NavLink, useNavigate} from "react-router-dom";
import React from "react";

const ObjectCard = ({data, isServicemen}) => {
  const navigate = useNavigate()

  const [editAddressId ,setEditAddressId] = mainPageSelectors.editAddress.useState()
  const setSelectedAddress = addressesSelectors.selectedAddress.useSetValue()
  const setIsOpenAside= isOpenAsideSelectors.useSetValue()
  const setIsNew = mainPageSelectors.isNewAddress.useSetValue()

  const onClickCard = () => {
    if (isServicemen) {
      setIsNew(false)
      setEditAddressId(data.id)
      setIsOpenAside(true)
    } else {
      setSelectedAddress(data)
      cookiesManager.set('X-pulseAddress', data.id)
      navigate('/main/intake')
    }
  }

  const onClickEdit = (e, addressId) => {
    e.stopPropagation()
    setIsNew(false)
    setEditAddressId(addressId)
    setIsOpenAside(true)
  }

  return (
    <div
      className={`object-card ${data.id === editAddressId ? 'selected' : ''}`}
      onClick={onClickCard}
    >
        <div className="object-card__header">
          {data.object_type === 3
              ? (
                  <div className="object-card__img object-card__img-lg">
                    <svg><use href={`${icons}#mansions`}/></svg>
                  </div>
              )
              : (
                  <div className="object-card__img">
                    <svg><use href={`${icons}#addresses_step`}/></svg>
                  </div>
              )
          }
          <div className="object-card__icons">
            <svg onClick={(e) => onClickEdit(e, data.id)}><use href={`${icons}#edit`}/></svg>
          </div>
        </div>
        <div className="object-card__name">
          <span>{data.name}</span>
        </div>
      <div className="object-card__address">
        <span>{addressFormatter(data)}</span>
      </div>
    </div>
  )
}

export default ObjectCard
