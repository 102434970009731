const IndividualForm = () => {
  return (
    <div className='form-fields user__balance-form'>
      <div className="form-group">
        <label>Фамилия</label>

        <input
          type='text'
        />
      </div>

      <div className="form-group">
        <label>Имя</label>

        <input
          type='text'
        />
      </div>

      <div className="form-group">
        <label>Отчество (не обязательно)</label>

        <input
          type='text'
        />
      </div>

      <div className="form-group">
        <label>Сумма, ₽</label>

        <input
          type='number'
        />
      </div>
    </div>
  )
}

export default IndividualForm
