import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'backCall'
const translations = {
  title: 'Looks like there\'s been an error',
  subtitle: 'Our team has been notified.',
  subtitle2: 'If you want to help, tell us what happened.',
  labelName: 'Name',
  labelEmail: 'E-mail',
  labelComments: 'What happened?',
  labelClose: 'Close',
  labelSubmit: 'Send',
  errorGeneric: "An unknown error occurred while sending the report. Please try again.",
  errorFormEntry: 'Some fields were invalid. Please correct the errors and try again.',
  successMessage: 'Your review has been sent. Thank you!',
  namePlaceholder: 'Name',
  emailPlaceHolder: 'example@mail.com',
  titleCustom: 'Found an error?',
  subtitleCustom: 'If you want to help, tell us what happened.',
  subtitle2Custom: 'Our team will be notified.'
}

export const backCallEn = createTranslationsWithSpaceName(translations, spaceName)
