import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'deviceForm'
const translations = {
  addingDevice: 'Добавление устройства',
  deviceWillBind: 'Оборудование будет привязано к\xa0объекту "{name}". Изменить привязку можно будет позднее в\xa0настройках.',
  selectDeviceType: 'Выберите тип оборудования',
  serial: 'Введите серийный номер',
  serialPlaceholder: '{start}-00001234',
  simNumber: 'Введите номер сим-карты',
  deviceAdded: 'Устройство добавлено',
  devicesAdded: 'Устройства добавлены',
  customizeImpulse: 'Настройте импульсные входы для\xa0сбора показаний и\xa0синхронизируйте устройство.',
  addToMasterDevice: 'Добавьте оборудование, с которым будет взаимодействовать {name}',
  address: 'Адрес',
  tetheredDevices: 'Привязываемые устройства',
  chooseTethered: 'Выберите из спикска уже зарегистрированного в системе оборудования и/или добавьте новое.',
  chooseTetheredOnlyPreexist: 'Выберите из спикска уже зарегистрированного в системе оборудования.',
  selectedDevices: 'Выбранное оборудование:',
  addDevice: 'Добавить устройство',
  toSelectDevice: 'Выберите устройство',
  toSelectEquipment: 'Выберите оборудование'
}

export const deviceFormRu = createTranslationsWithSpaceName(translations, spaceName)
