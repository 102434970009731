import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'requests'
const translations = {
  error: 'Fehler!',
  detailsChanges: 'Änderung personenbezogener daten',
  deleteAccErr: 'Konto konnte nicht gelöscht werden',
  detailsChangesSuccess: 'Persönliche daten erfolgreich geändert',
  detailsChangesError: 'Persönliche daten konnten nicht geändert werden',
  topUpAccountSuccessHeader: 'Du hast es geschafft!',
  topUpAccountSuccess: 'Sie haben ihr konto mit {amount, number} aufgeladen!',
  topUpAccountErrorHeader: 'Du hast versagt!',
  topUpAccountError: 'Kontaktieren sie uns auf eine für Sie bequeme weise.',
  changeEmailSuccessHeader: 'E-mail ändern',
  changeEmailSuccess: 'E-mail erfolgreich geändert',
  changeEmailError: `E-mail konnte nicht geändert werden`,
  sendCodeMailError: 'Bestätigungs-e-mail konnte nicht gesendet werden',
  changeDetailsSuccessHeader: 'Details ändern',
  changeDetailsSuccess: 'Details erfolgreich geändert',
  changeDetailsError: 'Details konnten nicht geändert werden',
  changePasswordSuccessHeader: 'Passwort ändern',
  changePasswordSuccess: 'Passwort erfolgreich geändert',
  changePasswordError: 'Passwort konnte nicht geändert werden',
  creatingObjectSuccessHeader: 'Erstellen eines buchhaltungsobjekts',
  creatingObjectSuccess: 'Kontoobjekt erfolgreich erstellt',
  creatingObjectError: 'Buchhaltungsobjekt konnte nicht erstellt werden',
  getObjectsError: 'Abrechnungspunkte konnten nicht geladen werden',
  changingObjectSuccessHeader: 'Wechsel des rechnungsobjekts',
  changingObjectSuccess: 'Das buchhaltungsobjekt wurde erfolgreich geändert',
  changingObjectError: 'Buchhaltungsobjekt "{name, string}" konnte nicht geändert werden',
  removeObjectSuccessHeader: 'Löschen eines buchhaltungsobjekts',
  removeObjectSuccess: 'Das buchhaltungsobjekt wurde erfolgreich gelöscht',
  removeObjectError: 'Abrechnungsobjekt konnte nicht gelöscht werden',
  addMeteringPointError: 'Kontopunkt konnte nicht hinzugefügt werden',
  addMeteringPointSuccessHeader: 'Abrechnungspunkt hinzufügen',
  addMeteringPointSuccess: 'Abrechnungsstelle erfolgreich hinzugefügt',
  changingMeteringPointSuccessHeader: 'Änderung der buchungsstelle',
  changingMeteringPointSuccess: 'Buchungsstelle erfolgreich geändert',
  changingMeteringPointError: 'Kontopunkt {name, string} konnte nicht geändert werden',
  removeMeteringPointError: 'Abrechnungspunkt konnte nicht gelöscht werden',
  removeMeteringPointSuccessHeader: 'Löschen einer abrechnungsstelle',
  removeMeteringPointSuccess: 'Abrechnungspunkt erfolgreich gelöscht',
  loadAddressError: 'Gesuchte adresse konnte nicht geladen werden',
  loadMeteringPointError: 'Abrechnungspunkt konnte nicht geladen werden',
  confirmMailError: 'Bestätigungs-e-mail konnte nicht gesendet werden',
  getAnalyticDataError: 'Analysedaten konnten nicht abgerufen werden',
  deleteAnalyticSuccessHeader: 'Löschen eines berichts',
  deleteAnalyticSuccess: 'Bericht erfolgreich gelöscht!',
  deleteAnalyticError: 'Bericht konnte nicht gelöscht werden',
  analyticMeteringsError: 'Daten konnten nicht gespeichert werden',
  analyticTemperatureError: 'Fehler beim abrufen der temperatur',
  analyticCalcError: 'Berechnungen konnten nicht durchgeführt werden',
  analyticCreateError: 'Analyse konnte nicht erstellt werden',
  analyticNameChangeSuccessHeader: 'Bearbeiten eines berichts',
  analyticNameChangeSuccess: 'Berichtstitel erfolgreich geändert',
  analyticNameChangeError: 'Titel des berichts konnte nicht geändert werden',
  downloadReport: '{name} wird heruntergeladen',
  downloadReportError: 'Bericht konnte nicht heruntergeladen werden',
  sendReportSuccessHeader: 'Senden eines berichts',
  sendReportSuccess: 'Bericht erfolgreich gesendet!',
  sendReportError: 'Bericht konnte nicht gesendet werden',
  saveAnalyticSuccessHeader: 'Analysen speichern',
  saveAnalyticSuccess: 'Analytics erfolgreich gespeichert!',
  saveAnalyticError: 'Analyse konnte nicht gespeichert werden',
  addDeviceSuccessHeader: 'Hinzufügen eines geräts',
  addDeviceSuccess: 'Gerät erfolgreich hinzugefügt',
  addDeviceError: 'Gerät konnte nicht hinzugefügt werden',
  changeCallFrequencySuccessHeader: 'Anrufhäufigkeit ändern',
  changeCallFrequencySuccess: 'Kommunikationsfrequenz erfolgreich geändert',
  changeCallFrequencyError: 'Anrufhäufigkeit konnte nicht geändert werden',
  pollError: 'Gerät konnte nicht abgefragt werden',
  changeDeviceSettingsSuccessHeader: 'Geräteeinstellungen ändern',
  changeDeviceSettingsSuccess: 'Geräteeinstellungen erfolgreich geändert',
  changeDeviceSettingsError: 'Geräteeinstellungen konnten nicht geändert werden',
  removeDeviceSuccessHeader: 'Entfernen eines geräts',
  removeDeviceSuccess: 'Gerät erfolgreich entfernt',
  removeDeviceError: 'Gerät kann nicht entfernt werden',
  changeSimSuccessHeader: 'Nummer ändern',
  changeSimSuccess: 'Nummer erfolgreich geändert',
  changeSimError: 'Nummer konnte nicht geändert werden',
  changeReportSuccessHeader: 'Bearbeiten eines berichts',
  changeReportSuccess: 'Bericht erfolgreich geändert',
  changeReportError: 'Bericht konnte nicht bearbeitet werden',
  removeReportSuccessHeader: 'Löschen eines berichts',
  removeReportSuccess: 'Bericht erfolgreich gelöscht',
  removeReportError: 'Bericht konnte nicht gelöscht werden',
  createReportSuccessHeader: 'Erstellen sie einen bericht',
  createReportSuccess: 'Bericht erfolgreich erstellt',
  createReportError: 'Bericht konnte nicht erstellt werden',
  getMeteringPointsListError: 'Liste der abrechnungspunkte konnte nicht abgerufen werden',
  getDevicesListError: 'Geräteliste konnte nicht abgerufen werden',
  getReportsHistoryError: 'Berichtsverlauf konnte nicht abgerufen werden',
  getMeteringsError: 'Zählerstände konnten nicht abgerufen werden',
  saveMeteringsError: 'Die Messwerte konnten nicht gespeichert werden',
  noMeterReadings: 'Zählerstände eingeben',
  checkAddressesDevicesError: 'Die Verfügbarkeit von Hardware in der Einrichtung konnte nicht überprüft werden',
  getDeviceInfoError: 'Die Geräteinformationen konnten nicht abgerufen werden',
  untieDevicesSuccessHeader: 'Hardware lösen',
  untieDevicesSuccess: 'Das Gerät wurde erfolgreich gelöst',
  untieDevicesError: 'Das Gerät konnte nicht gelöst werden',
  tieDevicesSuccessHeader: 'Hardware-Bindung',
  tieDevicesSuccess: 'Hardware erfolgreich gebunden',
  tieDevicesError: 'Hardware konnte nicht gebunden werden',
}

export const requestsDe = createTranslationsWithSpaceName(translations, spaceName)
