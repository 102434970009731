import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'units'
const translations = {
  0: 'Unbestimmt',
  1: 'm<sup>3</sup>',
  2: 'l',
  3: 'Gcal',
  4: 'kW⋅h'
}

export const unitsDe = createTranslationsWithSpaceName(translations, spaceName)
