import Input from "@/presentation/components/common/formComponents/Input";
import {calculationSettingsFormSelectors} from "@/internal/lib/storeModels/models/mkds/calculations/forms";
import {FormattedMessage} from "react-intl";
import React, {useEffect, useMemo, useState} from "react";
import {mkdsStateSelectors} from "@/internal/lib/storeModels/models/mkds/mkdsState";
import DatePickerInput from "@/presentation/components/common/formComponents/DatePickerInput";
import { MIN_DATE} from "@/presentation/components/analytics/сomponents/const";
import {
  dateFormatter,
  formattedDateToServ,
} from "@/internal/formatters/formatters";
import 'dayjs/locale/ru';
import 'dayjs/locale/de';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {appSelectors} from "@/internal/lib/storeModels/models/app/appModels";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import Loader from "@/presentation/components/loader/Loader";
import {calculationsSelectors} from "@/internal/lib/storeModels/models/mkds/calculations/calculationsState";
import {TARIFF_TYPES, UNIT_TYPES} from "@/presentation/components/heatCalculationPage/const";
import InputWithSelect from "@/presentation/components/common/formComponents/InputWithSelect";
import {emulateTab} from "emulate-tab";

dayjs.extend(utc)

const CalculationSettings = ({onCreateCalc, loading, onChangeCalc}) => {
  const locale = appSelectors.locale.useValue()
  const mkdsList = mkdsStateSelectors.list.useValue()
  const selectedMKDId = mkdsStateSelectors.selectedMKD.useValue()
  const formStartDate = calculationSettingsFormSelectors.useFormDataValue('start_date')
  const formEndDate = calculationSettingsFormSelectors.useFormDataValue('end_date')
  const setInitData = calculationSettingsFormSelectors.useSetInitFormData()
  const onClickSubmit = calculationSettingsFormSelectors.useOnClickSubmit()
  const isValidForm = calculationSettingsFormSelectors.useIsFormValid()
  const setOnValidSubmit = calculationSettingsFormSelectors.useSetOnValidSubmit()
  const [isDirtyNameInput, setIsDirtyNameInput] = useState(false)
  const [calcId] = calculationsSelectors.editCalculateId.useState()
  const unitType = calculationSettingsFormSelectors.useFormDataValue('unit_type')
  const tariffType = calculationSettingsFormSelectors.useFormDataValue('tariff_type')
  const calcResultApartments = calculationsSelectors.calcResultApartments.useValue()
  const setActiveStep = calculationsSelectors.activeStep.useSetValue()

  useEffect(() => {
    if (calcId) {
      setOnValidSubmit(onChangeCalc)
    } else {
      setOnValidSubmit(onCreateCalc)
    }

  }, [calcId])

  const minStartDate = useMemo(() => {
    if (!formEndDate) return MIN_DATE
    const endDate = dayjs(formEndDate)

    const fiveYearsBeforeEndDate = endDate.year(endDate.year() - 5).month(endDate.month() + 1)

    return fiveYearsBeforeEndDate.valueOf() < dayjs(MIN_DATE).valueOf()
      ? MIN_DATE
      : fiveYearsBeforeEndDate
  }, [formEndDate])

  const maxStartDate = useMemo(() => {
    const endDate = formEndDate
      ? dayjs(formEndDate).subtract(1, 'day')
      : dayjs(Date.now())

    return endDate
  }, [formEndDate])

  const minEndDate = useMemo(() => {
    const startDate = formStartDate
      ? dayjs(formStartDate).add(1, 'day')
      : dayjs(MIN_DATE)

    return startDate

  }, [formStartDate, /*completedData*/])

  const maxEndDate = useMemo(() => {
    if (!formStartDate) return Date.now()

    const startDate = dayjs(formStartDate)
    const fiveYearsByStartDate = startDate.year(startDate.year() + 5).month(startDate.month() - 1)

    return fiveYearsByStartDate.valueOf() > Date.now()
      ? Date.now()
      : fiveYearsByStartDate
  }, [formStartDate])

  useEffect(() => {
    const currentMKD = mkdsList.find(item => item.id == selectedMKDId)
    if (!currentMKD || calcId) return

    setInitData({
      unit_type: currentMKD.unit_type,
      tariff: currentMKD.last_calc_tariff
    })
  }, [selectedMKDId, calcId])

  useEffect(() => {
    if (isDirtyNameInput) return

    setInitData({
      name: `${dateFormatter(Date.now(), true)}`
    })
  }, [isDirtyNameInput])

  const withChangeName = () => {
    if (!isDirtyNameInput) {
      setIsDirtyNameInput(true)
    }
  }

  const handleSubmit = () => {
    if (!isValidForm) return

    if (!!calcResultApartments.length) {
      setActiveStep(1)
    } else {
      onClickSubmit()
    }
  }

  const onPressEnter = (e) => {
    if (e.code !== 'Enter') return

    e.stopPropagation()
    emulateTab()
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <div
        className={'calculation-settings'}
        onKeyDown={onPressEnter}
      >
        <div className="calculation-settings__form">
          <h2 className="title">Параметры расчета</h2>

          <Loader loading={loading}>
            <div className="form-fields">
              <Input
                label={'Название'}
                formSelectors={calculationSettingsFormSelectors}
                name={'name'}
                withChangeValue={withChangeName}
                disabled={!!calcResultApartments.length}
              />

              <DatePickerInput
                label={<FormattedMessage id={'analytics.from'}/>}
                formSelectors={calculationSettingsFormSelectors}
                name={'start_date'}
                minDate={minStartDate}
                maxDate={maxStartDate}
                views={['year', 'month', 'day']}
                disabled={!!calcId || !!calcResultApartments.length}
                valueFormatter={formattedDateToServ}
                openTo={'year'}
                inputFormat={'DD.MM.YYYY 00:00:00'}
              />

              <DatePickerInput
                label={<FormattedMessage id={'analytics.to'}/>}
                formSelectors={calculationSettingsFormSelectors}
                name={'end_date'}
                minDate={minEndDate}
                maxDate={maxEndDate}
                views={['year', 'month', 'day']}
                disabled={!!calcId || !!calcResultApartments.length}
                valueFormatter={formattedDateToServ}
                openTo={'year'}
                inputFormat={'DD.MM.YYYY 24:00:00'}
              />

              <InputWithSelect
                label={<>
                  Потреблено МКД за период (к распределению)
                </>}
                formSelectors={calculationSettingsFormSelectors}
                inputName={'energy_period'}
                selectName={'unit_type'}
                options={UNIT_TYPES}
                disabled={!!calcResultApartments.length}
                valueFormatter={(value) => !!value?.length ? +value : value}
                type={'number'}
              />

              <InputWithSelect
                label={`Тариф, ${TARIFF_TYPES.find(option => option.value === tariffType)?.label ?? '-'}`}
                formSelectors={calculationSettingsFormSelectors}
                inputName={'tariff'}
                selectName={'tariff_type'}
                options={TARIFF_TYPES}
                disabled={!!calcResultApartments.length}
                valueFormatter={(value) => !!value?.length ? +value : value}
                type={'number'}
              />
            </div>

            <div className="calculation-settings__btn">
              <button
                className="btn"
                onClick={handleSubmit}
                disabled={!isValidForm}
              >
                <FormattedMessage id={'app.proceed'}/>
              </button>
            </div>
          </Loader>
        </div>
      </div>
    </LocalizationProvider>
  )
}

export default CalculationSettings
