import {RESOURCES_TYPES} from "@/internal/content/consts";

export const RESOURCES_TYPES_ARRAY = [
  {
    type: 1,
    img: RESOURCES_TYPES[1].img
  },
  {
    type: 2,
    img: RESOURCES_TYPES[2].img
  },
  {
    type: 3,
    img: RESOURCES_TYPES[3].img
  },
  {
    type: 4,
    img: RESOURCES_TYPES[4].img
  },
  {
    type: 5,
    img: RESOURCES_TYPES[5].img
  },
  {
    type: 6,
    img: RESOURCES_TYPES[6].img
  },
]

export const SORTED_POINTS_BY_TYPES = [
  {
    type: 1,
    points: []
  },
  {
    type: 2,
    points: []
  },
  {
    type: 3,
    points: []
  },
  {
    type: 4,
    points: []
  },
  {
    type: 5,
    points: []
  },
  {
    type: 6,
    points: []
  },
]
