import {request} from "@/internal/lib/transport/request";
import {APARTMENTS_PAGE_SIZE} from "@/data/api_entities/const";

export const heatCalculateEndpoints = {
  create(data) {
    const params = {
      url: 'api/heat_calculate/',
      method: 'POST',
      data: data
    }

    return request(params)
  },
  change(data, id) {
    const params = {
      url: `api/heat_calculate/${id}/`,
      method: 'PUT',
      data: data
    }

    return request(params)
  },
  getIndicationsData(id, paginationParams = {
    page: 1,
    limit: 20
  }) {
    const query = paginationParams
      ? `?page=${paginationParams.page}&limit=${paginationParams.limit}`
      : ''
    const params = {
      url: `api/heat_calculate/${id}/apartament_enumerator_indication/${query}`,
      method: 'GET'
    }

    return request(params)
  },
  calculate(id, paginationParams = {
    page: 1,
    limit: 20
  }, isForce) {
    const query = paginationParams
      ? `?page=${paginationParams.page}&limit=${paginationParams.limit}`
      : ''

    const params = {
      url: `api/heat_calculate/${id}/calc/${query}`,
      method: 'POST',
      data: {
        is_force: !isForce
      }
    }

    return request(params)
  },
  getList(page = null) {
    const query = page
      ? `?page=${page}&limit=${APARTMENTS_PAGE_SIZE}`
      : ''

    const params = {
      url: `api/heat_calculate/${query}`,
      method: 'GET',
    }

    return request(params)
  },
  getById(id) {
    const params = {
      url: `api/heat_calculate/${id}/`,
      method: 'GET',
    }

    return request(params)
  },
  delete(id) {
    const params = {
      url: `api/heat_calculate/${id}/`,
      method: 'DELETE',
    }

    return request(params)
  },
  getHistory(mkdId, page = null) {
    const query = page
      ? `?page=${page}&limit=${APARTMENTS_PAGE_SIZE}`
      : ''

    const params = {
      url: `api/heat_history/${mkdId}/mansions/${query}`,
      method: 'GET',
    }

    return request(params)
  },
  getHistoryById(id, paginationParams = {
    page: 1,
    limit: 20
  }) {
    const query = paginationParams
      ? `?page=${paginationParams.page}&limit=${paginationParams.limit}`
      : ''

    const params = {
      url: `api/heat_history/${id}/${query}`,
      method: 'GET',
    }

    return request(params)
  },
  getXLSX(id) {
    const params = {
      url: `api/heat_history/${id}/report/`,
      method: 'GET',
    }

    return request(params)
  },
  sendResult(id, data) {
    const params = {
      url: `api/heat_history/${id}/send_report/`,
      method: 'POST',
      data: {
        email_list: data.email_list.filter(value => value.length)
      }
    }

    return request(params)
  }
}
