import {useEffect, useMemo, useState} from "react";
import {NavLink, Route, Routes, useNavigate} from "react-router-dom";
import {reportsEndpoint} from "@/data/api_entities/reports/reports_endpoint";
import {NEW_REPORT_PATH} from "./const";
import ReportForm from "./components/ReportForm";
import {addressEndpoint} from "@/data/api_entities/address/address_endpoint";
import {getDeviceInfoByType} from "@/internal/helpers/helpers";
import {selectors} from "@/presentation/shared/ui/message";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import {newReportFormSelectors} from "@/internal/lib/storeModels/models/report/forms/newReportFormModel";
import {changeReportFormSelectors} from "@/internal/lib/storeModels/models/report/forms/changeReportFormModel";
import {reportsPageSelectors} from "@/internal/lib/storeModels/models/report/reportPageModels";
import ReportsList from "./components/ReportsList";
import {FormattedMessage} from "react-intl";

const ReportsPage = () => {
  const navigate = useNavigate()

  const selectedAddress = addressesSelectors.selectedAddress.useValue()
  const [isReportLoading, setIsReportLoading] = reportsPageSelectors.reportLoading.useState()
  const updateReportsList = reportsPageSelectors.reportsList.useUpdateReportsList()
  const setMessage = selectors.useSetMessage()
  const resetForm = newReportFormSelectors.useResetForm()

  const [devices, setDevices] = useState([])
  const [meteringPoints, setMeteringPoints] = useState([])
  const [isLoadingDevices, setIsLoadingDevices] = useState(true)
  const [isLoadingPoints, setIsLoadingPoints] = useState(true)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

  const toggleDeleteModal = () => setIsOpenDeleteModal(!isOpenDeleteModal)

  const devicesList = useMemo(() => {
    return devices
      .filter(device => device.type !== 3)
      .map(device => {
        return {
          value: device,
          label: `${getDeviceInfoByType(device.type).name} - ${device.serial}`
        }
      })
  }, [devices])

  // useEffect(() => {
  //   navigate('report')
  // }, [selectedAddress?.id])

  useEffect(() => {
    if (!selectedAddress) return

    setIsLoadingDevices(true)
    setIsLoadingPoints(true)

    addressEndpoint.getDevices(selectedAddress.id).promise
      .then(res => {
        if (!res.devices) {
          setDevices([])
        } else {
          setDevices(res.devices)
        }
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.getDevicesListError'}/>,
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsLoadingDevices(false))

    addressEndpoint.getMeterointsByAddressId(selectedAddress.id).promise
      .then(res => {
        if (!res.metering_points) {
          setMeteringPoints([])
        } else {
          setMeteringPoints(res.metering_points)
        }
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.getMeteringPointsListError'}/>,
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsLoadingPoints(false))
  }, [selectedAddress])

  const onSaveReport = (data) => {
    setIsReportLoading(true)

    reportsEndpoint.createReport({...data, address: selectedAddress.id}).promise
      .then(() => {
        updateReportsList()
        navigate('report')
        resetForm()
        setMessage({
          header: <FormattedMessage id={'requests.createReportSuccessHeader'}/>,
          message: <FormattedMessage id={'requests.createReportSuccess'}/>,
          count: 3000
        })
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.createReportError'}/>,
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsReportLoading(false))
  }

  const onChangeReport = (data) => {
    setIsReportLoading(true)

    reportsEndpoint.changeReportData({
      address: selectedAddress.id,
      ...data
    }).promise
      .then(() => {
        updateReportsList()
        navigate('report')
        setMessage({
          header: <FormattedMessage id={'requests.changeReportSuccessHeader'}/>,
          message: <FormattedMessage id={'requests.changeReportSuccess'}/>,
          count: 3000
        })
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.changeReportError'}/>,
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsReportLoading(false))
  }

  const onDeleteReport = (id) => {
    setIsReportLoading(true)

    reportsEndpoint.deleteReport(id).promise
      .then(() => {
        toggleDeleteModal()
        updateReportsList()
        navigate('report')
        resetForm()
        setMessage({
          header: <FormattedMessage id={'requests.removeReportSuccessHeader'}/>,
          message: <FormattedMessage id={'requests.removeReportSuccess'}/>,
          count: 3000
        })
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.removeReportError'}/>,
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsReportLoading(false))
  }

  return (
   <div className='reports-page'>
     <div className="reports-page__column">
       <div className="reports-page__title-wrapper">
         <h2 className="title">
           <FormattedMessage id={'reportsPage.reports'}/>
         </h2>

         <NavLink
           className={`btn btn_transparent-bg`}
           to={NEW_REPORT_PATH}
         >
           +
         </NavLink>
       </div>

       <ReportsList />
     </div>

     <Routes>
       <Route
        path={NEW_REPORT_PATH}
        element={<ReportForm
          isNewReport
          devicesList={devicesList}
          meteringPoints={meteringPoints}
          formModelSelectors={newReportFormSelectors}
          onValidSubmit={onSaveReport}
          loading={isLoadingDevices || isLoadingPoints || isReportLoading}
        />}
       />

       <Route
         path={':id'}
         element={<ReportForm
           meteringPoints={meteringPoints}
           formModelSelectors={changeReportFormSelectors}
           devicesList={devicesList}
           onValidSubmit={onChangeReport}
           loading={isLoadingDevices || isLoadingPoints || isReportLoading}
           onDelete={onDeleteReport}
           toggleDeleteModal={toggleDeleteModal}
           isOpenDeleteModal={isOpenDeleteModal}
           modalLoading={isReportLoading}
         />}
       />
     </Routes>
   </div>
  )
}

export default ReportsPage
