import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {INIT_SEND_RESULTS_DATA, INIT_SEND_RESULTS_VALIDATIONS} from "../const";
import {getSendResultValidators} from "@/internal/validators/formValidators";

const sendResultModel = new FormModelFabric({
  initialData: INIT_SEND_RESULTS_DATA,
  initialValidations: INIT_SEND_RESULTS_VALIDATIONS,
  getValidators: getSendResultValidators
})

export const sendResultSelectors = sendResultModel.createSelectors()
