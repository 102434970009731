import { makeStyles } from '@mui/styles';
import {Alert} from '@/assets/svg'
import {createTheme} from "@mui/material";
const theme = createTheme()

const useStyles = makeStyles({
  alertGreen: {
    fontSize: 16,
    color: '#36455D',
    backgroundImage: `url(${Alert})` ,
    backgroundPosition: 'right top',
    backgroundSize: 'auto',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#05DEC4',
    padding: theme.spacing(4, 4),
    lineHeight: '24px',
    borderRadius: '16px',
  },
  alertRed: {
    fontSize: 16,
    color: '#36455D',
    backgroundImage: `url(${Alert})` ,
    backgroundPosition: 'right top',
    backgroundSize: 'auto',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#FC4F6E',
    padding: theme.spacing(4, 4),
    lineHeight: '24px',
    borderRadius: '16px',
  },
})

export default useStyles;