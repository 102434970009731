import {RESOURCES_TYPES_ARRAY} from "@/presentation/components/intake/const";
import {dateFormatter} from "@/internal/formatters/formatters";
import {intakeSelectors} from "@/internal/lib/storeModels/models/intake/intakeModels";
import {sampleParamsFormSelectors} from "@/internal/lib/storeModels/models/intake/forms/sampleParamsFormModel";
import {FormattedMessage} from "react-intl";

const   PointsTable = () => {
  const meteringPoints = intakeSelectors.meteringPoints.useValue()
  const selectedPoints = sampleParamsFormSelectors.useFormDataValue('pointsIds')

  const meteringPointsTableItems = meteringPoints
    .filter(point => selectedPoints.includes(point.id))
    .map(point => {
      const type = RESOURCES_TYPES_ARRAY.find(resource => resource.type === point.type)
      const img = type?.img ?? null

      return (
        <div key={point.id} className='flex-table__row'>
          <div className='flex-table__cell'>
            <div>
              <img src={img} alt="resource"/>
            </div>

            <span>{point.name}</span>
          </div>

          <div className='flex-table__cell'>
            <span>{point.device_serial}</span>
          </div>

          <div className='flex-table__cell'>
            <span>{point.total}</span>
          </div>

          <div className='flex-table__cell'>
            <span>{dateFormatter(point.data_total)}</span>
          </div>
        </div>
      )
    })

  return (
    <div className="intake__table flex-table">
      <div className="flex-table__header">
        <div className='flex-table__cell'>
          <span>
            <FormattedMessage id={'intake.meteringPoints'}/>
          </span>
        </div>

        <div className='flex-table__cell'>
          <span>
            <FormattedMessage id={'intake.counter'}/>
          </span>
        </div>

        <div className='flex-table__cell'>
          <span>
            <FormattedMessage id={'intake.values'}/>
          </span>
        </div>

        <div className='flex-table__cell'>
          <span>
            <FormattedMessage id={'intake.onMoment'}/>
          </span>
        </div>
      </div>

      {meteringPointsTableItems}
    </div>
  )
}

export default PointsTable
