import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";

const analyticIdModel = new StoreModelFabric()
const isPaidAnalyticModel = new StoreModelFabric(false)
const isCreatingAddressModel = new StoreModelFabric(false)
const lastCreateAnalyticRequestModel = new StoreModelFabric()
const editAddressIdModel = new StoreModelFabric({})
const isShowEditFormModel = new StoreModelFabric(false)
const isShowAddFormModel = new StoreModelFabric(false)
const isLoadingEditAddressModel = new StoreModelFabric(false)
const temperatureFromDBModel = new StoreModelFabric()
const rowsPerPageModel = new StoreModelFabric(20)
const isInvestStepCompletedModel = new StoreModelFabric(false)
const isInvestResultStepCompletedModel = new StoreModelFabric(false)

export const analyticsSelectors = {
  analyticId: {
    ...analyticIdModel.createSelectors(),
    analyticIdModel,
  },
  isPaidAnalytic: isPaidAnalyticModel.createSelectors(),
  isCreatingAddress: isCreatingAddressModel.createSelectors(),
  lastCreateAnalyticRequest: lastCreateAnalyticRequestModel.createSelectors(),
  editAddressId: editAddressIdModel.createSelectors(),
  isShowEditForm: isShowEditFormModel.createSelectors(),
  isShowAddForm: isShowAddFormModel.createSelectors(),
  isLoadingEditAddress: isLoadingEditAddressModel.createSelectors(),
  temperatureFromDB: temperatureFromDBModel.createSelectors(),
  rowsPerPage: rowsPerPageModel.createSelectors(),
  isInvestStepCompleted: isInvestStepCompletedModel.createSelectors(),
  isInvestResultStepCompleted: isInvestResultStepCompletedModel.createSelectors()
}
