import {TableCell, TableRow} from "@mui/material";
import {useMemo, useState} from "react";
import IntakeTableCell from "./IntakeTableCell";
import SumTableCell from "../SumTableCell";
import EditAddressButton from "@/presentation/components/analytics/сomponents/EditAddressButton";
import {ANALYTIC_UNIT_TYPES} from "@/presentation/components/addressForm/const";

const IntakeTableRow =(
  {
    address,
    columns,
    columnWidth,
    setRandomData,
    modelInfo,
  }) => {
  const [activeCell, setActiveCell] = useState(null)
  const investColumnWidth = +columnWidth.slice(0, columnWidth.length - 2) * 14 /15 + '%'
  const onFocusInput = (e, id) => {
    e.target.select()
    setActiveCell(id)
  }

  const inputCells = useMemo(() => {
    return columns.map((column, i) => {
      const id = `${address.id}:${column.value}:${i}`

      return (
        <IntakeTableCell
          key={id}
          id={id}
          modelInfo={modelInfo}
          valueKey={column.value}
          onFocus={(e) => onFocusInput(e, id)}
          activeCell={activeCell}
          setRandomData={setRandomData}
        />
      )
    })
  }, [modelInfo, activeCell])

  return (
    <TableRow
      onBlur={() => setActiveCell(null)}
    >
      <TableCell
        sx={{
          fontWeight: '500 !important',
          minWidth: investColumnWidth,
          textAlign: 'left !important'
        }}
      >
        <div className="tables__address-cell">
          <span>{address.name}</span>

          <div className="tables__address-btns">
            <EditAddressButton
              addressId={address.id}
            />
          </div>
        </div>
      </TableCell>

      <TableCell
        sx={{
          fontWeight: '500 !important',
          minWidth: investColumnWidth,
          textAlign: 'center'
        }}
      >
        <div className="tables__address-cell" style={{justifyContent: 'center'}}>
          <span>
            {ANALYTIC_UNIT_TYPES.find(type => type.value === address.unit_type)?.label ?? '-'}
          </span>
        </div>
      </TableCell>

      {inputCells}

      <SumTableCell
        selectors={modelInfo.selectors}
        columnWidth={columnWidth}
        useSumSpaceName={'useIntakeSum'}
      />
    </TableRow>
  )
}

export default IntakeTableRow
