import ArrowBack from "@/presentation/components/common/ArrowBack";
import {DEVICE_TEMP_PATH} from "./const";
import {FormattedMessage} from "react-intl";

const DeviceTempSensor = () => {
  return (
    <div className="device-temp">
      <ArrowBack
        pathOnVisible={DEVICE_TEMP_PATH}
      />

      <h2 className="title">
        <FormattedMessage id={'devicesPage.tempSensor'}/>
      </h2>

      <div className="device-temp__info">
        <div>
          <span>
            <FormattedMessage id={'devicesPage.time'}/>
          </span>

          <p>22.10.2023 8:00</p>
        </div>

        <div>
          <span>
            <FormattedMessage id={'devicesPage.reading'}/>
          </span>

          <p>+25ºC</p>
        </div>
      </div>
    </div>
  )
}

export default DeviceTempSensor
