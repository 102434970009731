export const SIGN_IN_INIT_DATA = Object.freeze({
  email: '',
  password: '',
})

export const SIGN_IN_VALIDATIONS = Object.freeze({
  email: [],
  password: [],
})

export const RECOVERY_PASSWORD_BY_EMAIL_INIT_DATA = Object.freeze({
  email: ''
})

export const RECOVERY_PASSWORD_BY_EMAIL_INIT_VALIDATIONS = Object.freeze({
  email: []
})

export const CHANGE_PASSWORD_INIT_DATA = Object.freeze({
  new_password: '',
  repeatedPassword: ''
})

export const CHANGE_PASSWORD_INIT_VALIDATIONS = Object.freeze({
  new_password: [],
  repeatedPassword: []
})

export const USER_REGISTRATION_INIT_DATA = Object.freeze({
  login: '',
  email: '',
  password: '',
  phone: '',
  organization: false,
  group: 3,
  resend_email: false,
})

export const USER_REGISTRATION_INIT_VALIDATIONS = Object.freeze({
  login: [],
  phone: [],
  email: [],
  password: []
})

export const CORPORATE_REGISTRATION_INIT_DATA = Object.freeze({
  inn: '',
  organization_name: '',
  organization_address: '',
  organization_actual_address: '',
  contact: '',
  email: '',
  password: '',
  phone: '',
  organization: true,
  group: 3,
  resend_email: false,
})

export const CORPORATE_REGISTRATION_INIT_VALIDATIONS = Object.freeze({
  inn: [],
  email: [],
  phone: [],
  contact: [],
  password: [],
  organization_name: []
})
