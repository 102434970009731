import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {INIT_DATA, INIT_VALIDATIONS, NEW_METERING_POINT_INIT_DATA} from "../../meteringPoints/const";
import {getMeteringPointValidator} from "@/internal/validators/formValidators";

const newMeteringPointFormModel = new FormModelFabric({
  initialData: NEW_METERING_POINT_INIT_DATA,
  initialValidations: INIT_VALIDATIONS,
  getValidators: getMeteringPointValidator
})

const changeMeteringPointFormModel = new FormModelFabric({
  initialData: INIT_DATA,
  initialValidations: INIT_VALIDATIONS,
  getValidators: getMeteringPointValidator
})

export const meteringPointFormSelectors = newMeteringPointFormModel.createSelectors()
export const changeMeteringPointFormSelectors = changeMeteringPointFormModel.createSelectors()
