import {Children, cloneElement, isValidElement, useState} from "react";
import {CustomizedPopover} from "../analytics/сomponents/const";

const HoveredPopover = (
  {
    children,
    text,
    anchorOrigin = {
      vertical: 'top',
      horizontal: 'right',
    },
    transformOrigin = {
      vertical: 'bottom',
      horizontal: 'left',
    },
    disabled = false
  }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        ...child.props,
        onMouseEnter: handlePopoverOpen,
        onMouseLeave: handlePopoverClose
      })
    }
    return child
  })

  return (
    <>
      {childrenWithProps}

      <CustomizedPopover
        open={!!anchorEl && !disabled}
        anchorEl={anchorEl}
        sx={{ pointerEvents: 'none' }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={handlePopoverClose}
        disableAutoFocus
      >
        {text}
      </CustomizedPopover>
    </>
  )
}

export default HoveredPopover
