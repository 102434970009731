export const TARIFF_PATH = 'tariff'
export const MY_DETAILS_PATH = 'my_details'
export const ACCOUNTING_OBJECTS_PATH = 'accounting_objects'
export const USERS_LIST_PATH = 'users_list'
export const ACCESS_PATH = 'access'
export const TOP_UP_PATH = 'top_up'
export const HISTORY_PATH = 'history'
export const TARIFFS_PATH = 'tariffs'
export const CHANGE_EMAIL_PATH = 'change_email'
export const CHANGE_PASSWORD_PATH = 'change_password'
export const CHANGE_ENTITY_PATH = 'change_entity'
export const ADD_OBJECT_PATH = 'new_object'
export const OBJECT_PARAMS_PATH = 'object_parameters'
export const NEW_METEROINT_PATH = 'new_metering'
export const GRANT_ACCESS_PATH = 'grant_access'
export const GRANTED_ACCESS_PATH = 'granted_access'
export const METERING_POINT_PATH = 'metering_point'

export const USER_LINKS_LIST = [
  {
    id: 1,
    textId: 'userPage.accAndRates',
    path: TARIFF_PATH,
  },
  {
    id: 2,
    textId: 'userPage.myData',
    path: MY_DETAILS_PATH,
  },
  // {
  //   id: 3,
  //   textId: 'userPage.accountingObjects',
  //   path: ACCOUNTING_OBJECTS_PATH,
  // },
  {
    id: 4,
    textId: 'userPage.accesses',
    path: ACCESS_PATH
  },
  // {
  //   id: 5,
  //   text: 'Уведомления и чат бот'
  // },
]
