import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'app'
const translations = {
  delete: 'Delete',
  cancel: 'Cancel',
  'e-mail': 'E-mail',
  password: 'Password',
  save: 'Save',
  proceed: 'Proceed',
  sendAgain: 'Send again',
  senAgainByTime: 'Sent. Repeat after 00:{time}',
  change: 'Change',
  sectionDeveloped: 'Section under development',
  ok: 'OK',
  use: 'Use',
  noData: 'No data',
  notSet: 'Not set',
  confirm: 'Confirm',
  back: 'Back',
  notSpecified: 'Not specified',
  accept: 'Confirm',
  topUp: 'Top up',
  send: 'Send',
  addMail: 'Add mail',
  add: 'Add',
  nothingFound: 'Nothing found',
  customize: 'Customize',
  onPage: 'On the page',
  countOf: '{from}-{to} from {count}',
  moreThen: 'more then {to}',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
  sun: 'Sun',
  byHour: 'Hourly',
  byDay: 'Per diem',
  byMonth: 'Period',
  byGrand: 'Final',
  notSave: "Don't save",
  download: 'Download',
  create: 'Create',
  addNew: '+ Add new',
  jan: 'jan',
  feb: 'feb',
  mar: 'mar',
  apr: 'apr',
  may: 'may',
  june: 'june',
  july: 'july',
  aug: 'aug',
  sept: 'sept',
  oct: 'oct',
  nov: 'nov',
  dec: 'dec',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  mayFull: 'May',
  juneFull: 'June',
  julyFull: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  prepareSystem: 'Getting the system ready...',
  gcal: 'Gcal',
  gj: 'GJ',
  kwh: 'kWh',
  mCube: 'm<sup>3</sup>',
  mCubeGas: 'm<sup>3</sup>·'+' nat gas',
  firstPg: 'first',
  lastPg: 'last',
  nextPg: 'next',
  prevPg: 'prev.',
  pageControl: 'Go to {type} page'
}

export const appEn = createTranslationsWithSpaceName(translations, spaceName)
