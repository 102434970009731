import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'userPage'
const translations = {
  'logOut': 'Ausloggen',
  'balanceAndPoints': 'Balance – {bonus, number} punkte und {balance, number, ::currency/EUR}',
  'accountDeleted': 'Dein Account wurde gelöscht',
  deleteAcc: 'Konto löschen',
  'accAndRates': 'Konto und tarife',
  'myData': 'Meine Details',
  'accountingObjects': 'Buchhaltungsobjekte',
  'accesses': 'Zugang',
  'deleteAcc?': 'Konto löschen?',
  'requisites': 'Voraussetzungen',
  'personalAccount': 'Konto',
  'howCanICallU': 'So erreichen sie uns (optional)',
  'tellNotReq': 'Telefon (optional)',
  historyTransaction: 'Verlauf der transaktionen',
  tariffs: 'Tarife',
  balance: 'Balance',
  topUp: 'Nachfüllen',
  subscription: 'Ihr abonnement',
  onMonth: 'für 30 tage',
  monthTotal: 'Insgesamt für 30 tage:',
  topUping: 'Nachschub',
  writeOff: 'Abschreiben',
  writeOffHistory: 'Abschreibungshistorie',
  keepHistory: 'Wir führen eine historie aller transaktionen der letzten 3 Jahre.',
  latestTransactions: 'Letzte transaktionen',
  noTransactions: 'Keine abgeschlossenen transaktionen',
  topUpAccount: 'Konto aufladen',
  bankCard: '1. bankkarte',
  byPlatron: 'Durch Platron',
  paymentAmount: 'Zahlungsbetrag',
  changeEmail: 'E-Mail ändern',
  enterCode: 'Code eingeben',
  ifNoMail: 'Wenn keine mail vorhanden ist, überprüfen sie bitte die richtigkeit der eingegebenen adresse und versuchen sie es erneut',
  ifChangeMail: 'Wenn sie die e-mail ändern, wird eine bestätigungs-e-mail an sie gesendet. Wenn sie es ignorieren, wird die e-mail nicht geändert',
  sendCode: 'Code senden',
  legalEntityName: 'Name der juristischen person',
  legalAddress: 'Rechtliche adresse',
  actualAddress: 'Aktuelle adresse',
  ifActualIsLegal: 'Wenn die tatsächliche Adresse mit der juristischen adresse übereinstimmt',
  contactCommunication: 'Kommunikationskontakt',
  inn: 'INN',
  changePassword: 'Passwort ändern',
  newPassword: 'Neues passwort',
  repeatPassword: 'Passwort wiederholen',
  yourEmail:'Deine e-mail',
  tariffsDescription: 'Grundtarife für analytik, auslesung und messwertübermittlung.',
  device: 'Gerät',
  additionalServices: 'Zusätzliche services',
  smsService: 'System sendet SMS an\xa0gerät',
  manualReading: 'Lesen von messwerten bei einem manuellen befehl außerhalb des zeitplans',
  partOfTariff: '1/60 von\xa0tarif',
  efficiencyAnalysis: 'Effizienzanalyse des wärmeverbrauchs',
  addAccountingObject: 'Es wurden noch keine Buchhaltungsobjekte angelegt. Sie können eine neue erstellen, indem Sie auf + klicken',
  addObject: 'Objekt hinzufügen',
  objectParams: 'Objektparameter',
  objectsMeteringPoints: 'Objektmesspunkte',
  historyIsStored: 'Der punkt speichert den verbrauchsverlauf, sodass die statistiken nicht zurückgesetzt werden, wenn zähler oder sender ausgetauscht werden.',
  pointsNotAdded: 'Punkte nicht hinzugefügt',
  addedIsNotActive: 'Die funktion zum hinzufügen von messpunkten wird aktiv, nachdem geräte zum Übertragen von messwerten zu diesem objekt hinzugefügt wurden',
  addMore: 'Mehr hinzufügen',
  priceForOneObject: '{price, number, ::currency/RUB}\npro\xa0buchhaltungsobjekt'
}

export const userPageDe = createTranslationsWithSpaceName(translations, spaceName)
