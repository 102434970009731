import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {INIT_CHANGE_ADDRESS_DATA} from "../../user/const";
import {
  ANALYTICS_INIT_VALIDATIONS,
  INITIAL_NEW_ADDRESS_FORM_DATA
} from "../../address/const";
import {getAnalyticAddressValidator} from "@/internal/validators/formValidators";

const changeAddressFormModel = new FormModelFabric({
  initialData: INIT_CHANGE_ADDRESS_DATA,
  initialValidations: ANALYTICS_INIT_VALIDATIONS,
  getValidators: getAnalyticAddressValidator
})

const newAddressFormModel = new FormModelFabric({
  initialData: INITIAL_NEW_ADDRESS_FORM_DATA,
  initialValidations: ANALYTICS_INIT_VALIDATIONS,
  getValidators: getAnalyticAddressValidator
})

const editAddressShortFormModel = new FormModelFabric({
  initialData: INITIAL_NEW_ADDRESS_FORM_DATA,
  initialValidations: ANALYTICS_INIT_VALIDATIONS,
  getValidators: getAnalyticAddressValidator
})

export const analyticNewAddressFormSelectors = newAddressFormModel.createSelectors()
export const analyticChangeAddressFormSelectors = changeAddressFormModel.createSelectors()
export const analyticsEditAddressShortFormSelectors = editAddressShortFormModel.createSelectors()
