import DevicesMultiSelect from "@/presentation/components/deviceForm/components/DevicesMultiSelect";
import React, {useEffect, useState} from "react";
import DevicesTypes from "@/presentation/components/devicesPage/devices_types";
import {addressEndpoint} from "@/data/api_entities/address/address_endpoint";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import {
  devicesMasterIdSelectors,
  devicesSlavesFormsSelectors, devicesToLinkedDevicesSelectors,
} from "@/internal/lib/storeModels/models/devices/forms/deviceFormModels";
import {cloneDeep} from "lodash";
import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {DEVICE_FORM_INIT_DATA, DEVICE_FORM_INIT_VALIDATIONS} from "@/internal/lib/storeModels/models/status/const";
import {getDeviceFormValidators} from "@/internal/validators/formValidators";
import SlaveForm from "@/presentation/components/deviceForm/components/SlaveForm";
import {getDeviceInfoByType} from "@/internal/helpers/helpers";
import {Basket} from "@/assets/svg";
import TransferDeviceSelect from "@/presentation/components/deviceForm/components/TransferDeviceSelect";
import {FormattedMessage} from "react-intl";

const DevicesToLink = ({mainDeviceType, onlyNewSlaves}) => {
  const selectedAddress = addressesSelectors.selectedAddress.useValue()
  const [slavesForms, setSlavesForms] = devicesSlavesFormsSelectors.useState()
  const [toLinkedDevices, setToLinkedDevices] = devicesToLinkedDevicesSelectors.useState()
  const setMasterId = devicesMasterIdSelectors.useSetValue()

  const [unattachedEndDevices, setUnattachedEndDevices] = useState([])
  const [transferDevices, setTransferDevices] = useState([])
  const [lastGetUnattachedRequest, setLastGetUnattachedRequest] = useState(null)
  const [lastGetTransferRequest, setLastGetTransferRequest] = useState(null)

  useEffect(() => {
    return () => {
      setToLinkedDevices([])
      setSlavesForms([])
      setMasterId(0)
    }
  }, [])

  const getUnattachedDevices = (type) => {
    setToLinkedDevices([])
    if (!type || !DevicesTypes.allTransferDevices.includes(type) || !selectedAddress?.id) return

    const masterDeviceGroupId = DevicesTypes.transferDevicesRsAndRadio.includes(type)
      ? DevicesTypes.transferDevicesRsAndRadioType
      : DevicesTypes.transferDevicesOnlyRadio.includes(type)
        ? DevicesTypes.transferDevicesOnlyRadioType
        : DevicesTypes.transferDevicesOnlyRS.includes(type)
          ? DevicesTypes.transferDevicesOnlyRSType
          : -1

    if (masterDeviceGroupId < 0) return

    if (lastGetUnattachedRequest) {
      lastGetUnattachedRequest.abort()
    }

    const {promise, controller} = addressEndpoint.getUnattachedEndDevices({
      addressId: selectedAddress.id,
      deviceGroupId: masterDeviceGroupId
    })

    setLastGetUnattachedRequest(controller)

    promise
      .then(res => {
        setUnattachedEndDevices(res?.devices ?? [])
        setLastGetUnattachedRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return

        setLastGetUnattachedRequest(null)
        setUnattachedEndDevices([])
        console.log(err)
      })
  }

  const getTransferDevices = (type) => {
    setMasterId(0)
    if (!type || !DevicesTypes.allEndDevices.includes(type) || !selectedAddress?.id) return

    const slaveDeviceGroupId = DevicesTypes.endDevicesRsAndRadio.includes(type)
      ? DevicesTypes.endDevicesRsAndRadioType
      : DevicesTypes.endDevicesOnlyRS.includes(type)
        ? DevicesTypes.endDevicesOnlyRSType
        : DevicesTypes.endDevicesOnlyRadio.includes(type)
          ? DevicesTypes.endDevicesOnlyRadioType
          : -1

    if (slaveDeviceGroupId < 0) return

    if (lastGetTransferRequest) {
      lastGetTransferRequest.abort()
    }

    const {promise, controller} = addressEndpoint.getTransferDevices({
      addressId: selectedAddress.id,
      deviceGroupId: slaveDeviceGroupId
    })

    setLastGetTransferRequest(controller)

    promise
      .then(res => {
        setTransferDevices(res?.devices ?? [])
        setLastGetTransferRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return

        setLastGetTransferRequest(null)
        setTransferDevices([])
      })
  }

  useEffect(() => {
    getUnattachedDevices(mainDeviceType)
    getTransferDevices(mainDeviceType)
  }, [mainDeviceType])

  const slavesFormsComponents = slavesForms.map((formSelectors, i) => {
    return <SlaveForm
      key={i}
      index={i}
      masterType={mainDeviceType}
      formSelectors={formSelectors}
      slavesForms={slavesForms}
      setSlavesForms={setSlavesForms}
    />
  })

  const onClickAddSlave = () => {
    const forms = cloneDeep(slavesForms)

    const newModal = new FormModelFabric({
      initialData: DEVICE_FORM_INIT_DATA,
      initialValidations: DEVICE_FORM_INIT_VALIDATIONS,
      getValidators: getDeviceFormValidators,
    })

    forms.push(newModal.createSelectors())
    setSlavesForms(forms)
  }

  const removePreexistDeviceToLink = (deviceId) => {
    setToLinkedDevices(toLinkedDevices.filter(device => device.id !== +deviceId))
  }

  return (
    <div className={'devices-to-link'}>
      <h3 className={'title title_h3'}>
        <FormattedMessage id={'deviceForm.tetheredDevices'}/>
      </h3>

      {!onlyNewSlaves &&
        <p className={'devices-to-link__description'}>
          {DevicesTypes.allTransferDevices.includes(mainDeviceType)
            ? <FormattedMessage id={'deviceForm.chooseTethered'}/>
            : <FormattedMessage id={'deviceForm.chooseTetheredOnlyPreexist'}/>
          }
        </p>
      }

      {DevicesTypes.allTransferDevices.includes(mainDeviceType) && !onlyNewSlaves &&
        <DevicesMultiSelect
          devices={unattachedEndDevices}
          loading={!!lastGetUnattachedRequest}
        />
      }

      {DevicesTypes.allEndDevices.includes(mainDeviceType) && !onlyNewSlaves &&
        <TransferDeviceSelect
          devices={[
            {id: 0},
            ...transferDevices
          ]}
          loading={!!lastGetTransferRequest}
        />
      }

      {!!toLinkedDevices.length &&
        <div className="devices-to-link__to-linked">
          <h5>
            <FormattedMessage id={'deviceForm.selectedDevices'}/>
          </h5>

          {toLinkedDevices.map(deviceInfo => {
            return (
              <div
                key={deviceInfo.id}
                className={'devices-to-link__linked-device'}
              >
                <div>
                  <span>{getDeviceInfoByType(deviceInfo.type).name}</span>

                  <span>SN: {deviceInfo.serial}</span>
                </div>

                <div>
                  <button onClick={() => removePreexistDeviceToLink(deviceInfo.id)}>
                    <img src={Basket} alt="Basket"/>
                  </button>
                </div>
              </div>
            )
          })}
        </div>
      }

      {!!slavesFormsComponents.length &&
        <div className="devices-to-link__slaves-forms">
          {slavesFormsComponents}
        </div>
      }

      {(DevicesTypes.allTransferDevices.includes(mainDeviceType) || onlyNewSlaves) &&
        <button
          className="btn btn_transparent-bg"
          onClick={onClickAddSlave}
        >
          <FormattedMessage id={'deviceForm.addDevice'}/>
        </button>
      }
    </div>
  )
}

export default DevicesToLink
