import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'intake'
const translations = {
  consumption: 'Consumption',
  chartType: 'Chart type',
  mode: 'Mode',
  display: 'Display',
  year: 'Year',
  month: 'Month',
  forecastName: 'Forecast {name}',
  lastYear: 'Last year',
  thisYear: 'This year',
  forecast: 'Forecast',
  m: 'm',
  byDays: 'By days',
  byMonths: 'By month',
  byYears: 'On years',
  fact: 'Fact',
  comparison: 'Comparison',
  average: 'Average - ',
  meteringPoints: 'Accounting points',
  counter: 'Counter',
  values: 'Readings',
  onMoment: 'At the moment',
  startPeriod: 'At the beginning of the period',
  endPeriod: 'At the end of the period',
  increment: 'Increment',
}

export const intakeEn = createTranslationsWithSpaceName(translations, spaceName)
