import {getDeviceInfoByType} from "@/internal/helpers/helpers";
import {FormattedMessage} from "react-intl";

export const deviceOptionFormatter = (deviceInfo) => {
  return (
    <div className="device-option">
      {deviceInfo.id === 0
        ? (
          <span className={'device-option__no-value'}>
            <FormattedMessage id={'app.notSet'}/>
          </span>
        )
        : (
          <>
            <span>{getDeviceInfoByType(deviceInfo.type).name}</span>
            <span>SN: {deviceInfo.serial}</span>
          </>
        )
      }

    </div>
  )
}
