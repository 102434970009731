import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";
import {createEffect, createEvent, sample} from "effector";
import {useEvent} from "effector-react";
import {addressesSelectors} from "../address/addressesModel";
import {addressEndpoint} from "@/data/api_entities/address/address_endpoint";
import {setMessage} from "@/presentation/shared/ui/message";
import {merge} from "lodash";

const devicesListModel = new StoreModelFabric([])
const lastGetDevicesRequestModel = new StoreModelFabric()
const isFirstLoadingModel = new StoreModelFabric(true)
const deviceRequestLoadingModel = new StoreModelFabric(false)
const isDeviceChangingModel = new StoreModelFabric(false)
const isChangeSimModel = new StoreModelFabric(false)

const getDevicesList = createEvent()

devicesListModel.getList = createEffect(({selectedAddress, lastGetDevicesRequest, isFirstLoading}) => {
  if (!selectedAddress) return

  if (lastGetDevicesRequest) {
    lastGetDevicesRequest.abort()
  }

  const {promise, controller} = addressEndpoint.getDevices(selectedAddress.id)

  lastGetDevicesRequestModel.setState(controller)

  promise
    .then(res => {
      if (!res.devices) {
        devicesListModel.resetStore()
        lastGetDevicesRequestModel.resetStore()

        if (res.devices === undefined) {
          setMessage({
            header: 'Ошибка!',
            message: 'Не удалось получить список устройств',
            type: 'error',
            count: 3000
          })
        }
      } else {
        const sortedDevicesByMaster = []

        for (let i = 0; i < res.devices.length; i++) {
          if (res.devices[i].master !== null) continue

          let childIndex = 0
          const allSubjects = res.devices
            .filter(device => device.master === res.devices[i].id)

          const allSubjectsWithIndex = allSubjects
            .map(device => {
              childIndex++
              return merge(device, {childIndex, childrenCounts: allSubjects.length})
            })

          sortedDevicesByMaster.push(res.devices[i])

          sortedDevicesByMaster.push(...allSubjectsWithIndex)
        }

        devicesListModel.setState(sortedDevicesByMaster)
        lastGetDevicesRequestModel.resetStore()
      }
    })
    .catch(err => {
      if (err.name === 'AbortError') return

      lastGetDevicesRequestModel.resetStore()
      console.log(err)
    })
    .finally(() => {
      if (isFirstLoading) isFirstLoadingModel.setState(false)
    })
})

sample({
  clock: getDevicesList,
  source: {
    selectedAddress: addressesSelectors.selectedAddressModel.$store,
    lastGetDevicesRequest: lastGetDevicesRequestModel.$store,
    isFirstLoading: isFirstLoadingModel.$store
  },
  target: devicesListModel.getList
})

export const devicePageSelectors = {
  devicesList: {
    ...devicesListModel.createSelectors(),
    useGetDevicesList: () => useEvent(getDevicesList)
  },
  lastGetDevicesRequest: lastGetDevicesRequestModel.createSelectors(),
  isFirstLoading: isFirstLoadingModel.createSelectors(),
  deviceRequestLoading: deviceRequestLoadingModel.createSelectors(),
  isDeviceChanging: isDeviceChangingModel.createSelectors(),
  isChangeSim: isChangeSimModel.createSelectors()
}
