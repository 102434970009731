import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'reportsPage'
const translations = {
  reports: 'Berichte',
  systemDevice: 'Systemgerät',
  meteringPoint: 'Abrechnungspunkt',
  resource: 'Ressource',
  byMonth: 'Pro monat',
  byWeek: 'Während der woche',
  byDay: 'Pro tag',
  byNum: '{num} tag des',
  name: 'Name',
  nameOfResponsiblePerson: 'Name der verantwortlichen person',
  tel: 'Telefon',
  reportByDevice: 'Systeminstrumentenbericht',
  reportByResource: 'Ressourcenbericht',
  reportByMeteringPoint: 'Point of account bericht',
  integral: 'Integral (kumulative summe)',
  increment: 'Periodeninkrement',
  integralAndIncrement: 'Integral und inkrementell',
  displayReadings: 'Messwerte anzeigen',
  customizeReport: 'Passen sie den bericht an',
  reportType: 'Berichtstyp',
  meteringPointsInReport: 'Im bericht enthaltene abrechnungspunkte:',
  gizmoDevice: 'Primäres gerät',
  detailing: 'Detaillierung',
  unitsTypes: 'Einheiten',
  dimension: 'Abmessungen',
  reportName: 'Berichtstitel (kann bearbeitet werden)',
  reportContents: 'Inhalt des berichts',
  body: 'Allgemeiner informationsblock',
  provider: 'Anbieter',
  consumer: 'Verbraucher',
  serviceOrg: 'Serviceorganisation',
  systemType: 'Systemtyp (optional)',
  channelOne: 'Kanal 1',
  channelTwo: 'Kanal 2',
  errors: 'NA-Codes',
  preview: 'Vorschau',
  regularDispatch: 'Regelmäßiger versand',
  sendRegular: 'Senden sie regelmäßig',
  periodExample: 'Zeitraum beispiel:',
  example: '1. Juni 00:00 – 30. Juni 24:00',
  sendEmail: 'An e-mail senden post',
  inNextUpdates: 'In den nächsten Updates werden wir das Senden an mos.ru, an Chat-Messenger, per SMS und an externe Server hinzufügen',
  'deleteReport?': 'Bericht löschen?',
  successfulTransfer: 'Erfolgreiche Übertragung',
  delayed: '{time} - verspätet',
  missed: 'Senden verpasst',
  plan: 'Sendeplan',
  fact: 'Tatsache senden',
  noHistory: 'Dieser bericht hat keinen verlauf',
  history: 'Übermittlungshistorie melden',
  withoutRegular: 'Kein regelmäßiges Posten',
  mailsCount: '{count} sendekanäle',
  createReport: 'Erstellen sie einen periodenbericht',
  dontForget: 'Um es nicht zu vergessen, nennen wir den Installationsort - Küche, Bad, Tambour ...',
  periodFrom: 'Zeitraum ab',
  to: 'an',
  noReports: 'Es liegen noch keine Berichte vor. Sie können einen neuen Bericht erstellen und seinen Versand konfigurieren, indem Sie auf + klicken',
  resources: 'Ressourcen',
  points: 'Punkte',
  device: 'Gerät',
  selectDevice: 'Wähle ein Messgerät',
  resourceType: 'Ressourcentyp',
  selectPoint: 'Wählen Sie einen abrechnungspunkt aus',
  typeTitle: '{type} - {day, plural, =0 {} other {{day} das Datum,}} {time}'
}

export const reportsPageDe = createTranslationsWithSpaceName(translations, spaceName)
