import {
  DAY_SCHEDULE_TYPE,
  DEVICE_REPORT_TYPE,
  METERING_POINT_REPORT_TYPE,
  MONTH_SCHEDULE_TYPE,
  RESOURCE_REPORT_TYPE, WEEK_SCHEDULE_TYPE
} from "../../../presentation/components/reportsPage/const";

export const createReportAdapter = (data) => {
  const provider = setProviderData(data),
    consumer = setConsumerData(data),
    service_org = setServiceOrgData(data),
    config = setConfig(data)
  let schedule = null

  const receiversData = data.receivers.filter(value => !!value)
  const scheduleTimeParts = data.scheduleTime.split(':')

  if (data.is_active && data.scheduleType !== DAY_SCHEDULE_TYPE) {
    schedule = {
      type: data.scheduleType,
      day: data.scheduleDay,
      hour: +scheduleTimeParts[0],
      minute: +scheduleTimeParts[1]
    }
  } else if (data.is_active) {
    schedule = {
      type: data.scheduleType,
      hour: +scheduleTimeParts[0],
      minute: +scheduleTimeParts[1]
    }
  }

  const deviceData = {
    address: data.address,
    name: data.name,
    dimension: 3,
    unit_type: 0,
    is_active: data.is_active,
    type: data.type,
    detailing: data.detailing,
    system_type: data.system_type || null,
    accumulated: data.accumulated,
    incremental: data.incremental,
    body: data.body,
    receivers: receiversData.length && data.is_active
      ? {mails: receiversData}
      : null,
    provider,
    consumer,
    service_org,
    config,
    schedule
  }

  const notDeviceData = {
    address: data.address,
    name: data.name,
    is_active: data.is_active,
    type: data.type,
    detailing: data.detailing,
    unit_type: data.unit_type,
    dimension: data.dimension,
    system_type: data.system_type || null,
    accumulated: data.accumulated,
    incremental: data.incremental,
    body: data.body,
    receivers: receiversData.length && data.is_active
      ? {mails: receiversData}
      : null,
    provider,
    consumer,
    service_org,
    config,
    schedule
  }

  return data.type === DEVICE_REPORT_TYPE
    ? deviceData
    : notDeviceData
}

export const getReportAdapter = (data) => {
  return {
    id: data.id,
    name: data.name,
    accumulated: data.accumulated,
    incremental: data.incremental,
    is_active: data.is_active,
    type: data.type,
    body: data.body,
    dimension: data.dimension || 0,
    detailing: data.detailing,
    system_type: data.system_type,
    receivers: data.receivers ? data.receivers.mails : [],
    unit_type: data.unit_type,
    providerName: data.provider ? data.provider.name : '',
    providerBailee: data.provider ? data.provider.bailee : '',
    providerPhone: data.provider ? data.provider.phone : '',
    consumerName: data.consumer ? data.consumer.name : '',
    consumerBailee: data.consumer ? data.consumer.bailee : '',
    consumerPhone: data.consumer ? data.consumer.phone : '',
    serviceOrgName: data.service_org ? data.service_org.name : '',
    serviceOrgBailee: data.service_org ? data.service_org.bailee : '',
    serviceOrgPhone: data.service_org ? data.service_org.phone : '',
    device: data.type === DEVICE_REPORT_TYPE
      ? false
      : data.config.device || false,
    gizmo: data.config.gizmo || false,
    scheduleType: data.schedule ? data.schedule.type : MONTH_SCHEDULE_TYPE,
    scheduleDay: data.schedule ? data.schedule.day : 1,
    scheduleTime: data.schedule
      ? `${data.schedule.hour}:${data.schedule.minute > 9 ? data.schedule.minute : `0${data.schedule.minute}`}`
      : '12:00',
    deviceType: data.type === DEVICE_REPORT_TYPE ? data.config.device.type : null,
    deviceSerial: data.type === DEVICE_REPORT_TYPE ? data.config.device.serial : '',
    deviceId: data.type === DEVICE_REPORT_TYPE ? data.config.device.id : null,
    channelOne: data.type === DEVICE_REPORT_TYPE ? data.config.channels[1] : false,
    channelTwo: data.type === DEVICE_REPORT_TYPE ? data.config.channels[2] : false,
    pointId: data.type === METERING_POINT_REPORT_TYPE ? data.config.point.id : null,
    gizmo_name: data.type === METERING_POINT_REPORT_TYPE ? data.config.point.gizmo_name : '',
    device_name: data.type === METERING_POINT_REPORT_TYPE ? data.config.point.device_name : '',
    errors: data.config.errors,
    resource_type: data.type === RESOURCE_REPORT_TYPE ? data.config.resource_type : 0
  }
}

export const setPreviewAdapter = (data) => {
  const provider = setProviderData(data),
        consumer = setConsumerData(data),
        service_org = setServiceOrgData(data),
        config = setConfig(data)

  return {
    type: data.type,
    detailing: data.detailing,
    system_type: data.system_type || null,
    accumulated: data.accumulated,
    incremental: data.incremental,
    body: data.body,
    provider,
    consumer,
    service_org,
    config,
    preview: {
      detailing: data.detailingPreview,
      start: `${data.startDate}T${data.startTime}:00`,
      end: `${data.endDate}T${data.endTime}:00`
    }
  }
}

function setConsumerData (data) {
  return data.consumerName || data.consumerBailee || data.consumerPhone
    ? {
      name: data.consumerName,
      bailee: data.consumerBailee,
      phone: data.consumerPhone
    }
    : null
}

function setProviderData (data) {
  return data.providerName || data.providerBailee || data.providerPhone
    ? {
      name: data.providerName,
      bailee: data.providerBailee,
      phone: data.providerPhone
    }
    : null
}

function setServiceOrgData (data) {
  return data.serviceOrgName || data.serviceOrgBailee || data.serviceOrgPhone
    ? {
      name: data.serviceOrgName,
      bailee: data.serviceOrgBailee,
      phone: data.serviceOrgPhone
    }
    : null
}

function setConfig (data) {
  switch (data.type) {
    case DEVICE_REPORT_TYPE:
      return {
        device: data.deviceId,
        channels: {1: data.channelOne, 2: data.channelTwo},
        errors: data.errors
      }
    case RESOURCE_REPORT_TYPE:
      return {
        resource_type: data.resource_type,
        device: data.device,
        gizmo: data.gizmo,
        errors: data.errors
      }
    case METERING_POINT_REPORT_TYPE:
      return {
        point: data.pointId,
        device: data.device,
        gizmo: data.gizmo,
        errors: data.errors
      }
    default:
      return null
  }
}