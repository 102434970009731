import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {ANALYTICS_INIT_VALIDATIONS, INITIAL_NEW_ADDRESS_FORM_DATA} from "../../address/const";
import {getAddressFormValidators} from "@/internal/validators/formValidators";
import {INIT_CHANGE_ADDRESS_DATA} from "../const";

const newAddressFormModel = new FormModelFabric({
  initialData: INITIAL_NEW_ADDRESS_FORM_DATA,
  initialValidations: ANALYTICS_INIT_VALIDATIONS,
  getValidators: getAddressFormValidators
})

const changeAddressFormModel = new FormModelFabric({
  initialData: INIT_CHANGE_ADDRESS_DATA,
  initialValidations: ANALYTICS_INIT_VALIDATIONS,
  getValidators: getAddressFormValidators
})

export const userNewAddressSelectors = newAddressFormModel.createSelectors()
export const userChangeAddressSelectors = changeAddressFormModel.createSelectors()
