import SectionDeveloped from "@/presentation/components/common/SectionDeveloped";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {ACCESS_PATH} from "../const";
import {FormattedMessage} from "react-intl";

const Access = () => {
  return (
    <div className='user-page__column user-page__column_access'>
      <ArrowBack
        pathOnVisible={ACCESS_PATH}
      />

      <h2 className="title">
        <FormattedMessage id={'userPage.accesses'}/>
      </h2>

      <SectionDeveloped />
    </div>
  )
}

export default Access
