import {useEffect, useMemo, useState} from "react";
import {userEndpoint} from "@/data/api_entities/user/user_endpoint";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {useNavigate} from "react-router-dom";
import ValidationsWrapper from "@/presentation/components/common/formComponents/ValidationsWrapper";
import {CHANGE_EMAIL_PATH} from "../../const";
import Loader from "@/presentation/components/loader/Loader";
import {selectors} from "@/presentation/shared/ui/message";
import cookiesManager from "@/internal/lib/services/cookiManager";
import Input from "@/presentation/components/common/formComponents/Input";
import {userEmailFormSelectors} from "@/internal/lib/storeModels/models/user/forms/userEmailFormModel";
import {userPageSelectors} from "@/internal/lib/storeModels/models/user/userPageModels";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import {FormattedMessage} from "react-intl";

const ChangeEmail = () => {
  const navigate = useNavigate()

  const [isCodeSent, setIsCodeSent] = userPageSelectors.isCodeSent.useState()
  const resetResendCode = userPageSelectors.useResetResendCode()
  const [timeBeforeResend, setTimeBeforeResend] = userPageSelectors.timeBeforeResend.useState()
  const [isLoading, setIsLoading, resetIsLoading] = userPageSelectors.changeEmailLoading.useState()
  const setMessage = selectors.useSetMessage()

  const [userConfirmCode, setUserConfirmCode] = useState('')
  const [codeValidation, setCodeValidation] = useState([])

  const isValidForm = userEmailFormSelectors.useIsFormValid()
  const setPreviousFormData = userEmailFormSelectors.useSetPreviousData()
  const setFormDataValue = (name) => userEmailFormSelectors.setFormDataValue(name)
  const setOnValidSubmit = userEmailFormSelectors.useSetOnValidSubmit()
  const onClickSubmit = userEmailFormSelectors.useOnClickSubmit()
  const userData = userDataSelectors.userData.useValue()

  useEffect(() => {
    setOnValidSubmit(onValidSubmit)

    return () => {
      resetResendCode()
      resetIsLoading()
    }
  }, [])

  useEffect(() => {
    setFormDataValue('new_email')(userData.email)

    setPreviousFormData({
      new_email: userData.email
    })
  }, [userData])

  useEffect(() => {
    if (!isCodeSent || timeBeforeResend < 1) return

    setTimeout(() => {
      setTimeBeforeResend(timeBeforeResend - 1)
    }, 1000)
  }, [timeBeforeResend, isCodeSent])

  const timeBeforeSendWithZero = useMemo(() => {
    return timeBeforeResend > 9
      ? String(timeBeforeResend)
      : `0${timeBeforeResend}`
  }, [timeBeforeResend])

  const onValidSubmit = (data) => {
    setIsLoading(true)

    userEndpoint.getConfEmailCode(data)
      .then(() => {
        setIsCodeSent(true)
        setTimeBeforeResend(30)
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.sendCodeMailError'}/>,
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsLoading(false))
  }

  const handleSubmitChange = () => {
    if (!isValidForm) return

    onClickSubmit()
  }

  const onChangeCodeInput = (e) => {
    setUserConfirmCode(e.target.value.trim())
    setCodeValidation([])
  }

  const handleSubmit = () => {
    if (!userConfirmCode) return

    setIsLoading(true)
    userEndpoint.sendConfirmEmailCode({
      code: userConfirmCode
    })
      .then((res) => {
        if (res.status === 'error') {
          if (res.detail.code) {
            setCodeValidation([res.detail.code])
          } else {
            console.log(res.detail)
          }
        } else {
          cookiesManager.set('X-pulse', res.access_token)
          navigate('/user_page/my_details')
          setMessage({
            header: <FormattedMessage id={'requests.changeEmailSuccessHeader'}/>,
            message: <FormattedMessage id={'requests.changeEmailSuccess'}/>,
            count: 3000
          })
        }
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.changeEmailError'}/>,
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <div className='user-page__column user-page__column_email'>
      <ArrowBack
        pathOnVisible={CHANGE_EMAIL_PATH}
      />

      <h2 className="title">
        <FormattedMessage id={'userPage.changeEmail'}/>
      </h2>

      <Loader loading={isLoading}>
        <div className="form-fields">
          <Input
            label={<FormattedMessage id={'userPage.yourEmail'}/>}
            name='new_email'
            formSelectors={userEmailFormSelectors}
          />

          {
            isCodeSent
              ? <div className="form-group">
                  <label>
                    <FormattedMessage id={'userPage.enterCode'}/>
                  </label>

                  <ValidationsWrapper validationMsgs={codeValidation}>
                    <input
                      type="text"
                      value={userConfirmCode}
                      onChange={onChangeCodeInput}
                      className={!codeValidation.length ? '' : 'error-input'}
                    />
                  </ValidationsWrapper>
              </div>
              : null
          }
        </div>

        {
          isCodeSent
            ? <>
              <p>
                <FormattedMessage id={'userPage.ifNoMail'}/>
              </p>

              {timeBeforeResend < 1
                ? (
                  <span onClick={handleSubmitChange} className={'user-page__resend'}>
                    <FormattedMessage id={'app.sendAgain'}/>
                  </span>
                )
                : (
                  <span className={'user-page__sent'}>
                    <FormattedMessage id={'app.senAgainByTime'} values={{time: timeBeforeSendWithZero}}/>
                  </span>
                )
              }

            </>
            : (
              <p>
                <FormattedMessage id={'userPage.ifChangeMail'}/>
              </p>
            )
        }

        <div className="user-page__btn-wrapper">
          <button
            className="btn btn_transparent-bg"
            onClick={isCodeSent ? handleSubmit : handleSubmitChange}
          >
            {isCodeSent ? <FormattedMessage id={'userPage.sendCode'}/> : <FormattedMessage id={'app.change'}/>}
          </button>
        </div>
      </Loader>
    </div>
  )
}

export default ChangeEmail
