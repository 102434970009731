import {devicePageSelectors} from "@/internal/lib/storeModels/models/devices/devicePageModels";
import Loader from "@/presentation/components/loader/Loader";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import {NavLink} from "react-router-dom";
import {getDeviceInfoByType} from "@/internal/helpers/helpers";
import {BATTERY_IMGS, CONNECT_IMGS} from "../const";
import {useEffect} from "react";
import {FormattedMessage} from "react-intl";

const DevicesList = () => {
  const selectedAddress = addressesSelectors.selectedAddress.useValue()
  const getDevicesList = devicePageSelectors.devicesList.useGetDevicesList()
  const devicesList = devicePageSelectors.devicesList.useValue()
  const isFirstLoading = devicePageSelectors.isFirstLoading.useValue()
  const lastGetDevicesRequest = devicePageSelectors.lastGetDevicesRequest.useValue()

  useEffect(() => {
    getDevicesList()
  }, [selectedAddress])

  const devices = devicesList.map(device => {
    const lastClass = device.childIndex === device.childrenCounts ? 'last' : ''
    const firstClass = device.childIndex === 1 ? 'first' : ''
    const onlyOneClass = device.childrenCounts === 1 ? 'only-one' : ''

    return (
      <NavLink
        key={device.id}
        className={`device-item ${device.master ? 'device-item_child' : ''}`}
        to={`${device.id}`}
      >
        {
          device.master
            ? (
              <div
                className={
                  `device-item__relation ${lastClass} ${firstClass} ${onlyOneClass}`
                }
              />
            )
            : null
        }
        <div className="device-item__header">
          <div className="device-item__img">
            <img src={getDeviceInfoByType(device.type).img} alt="device"/>
          </div>

          <div className="device-item__icons">
            <div className="device-item__icon-img">
              <img src={BATTERY_IMGS[device.battery]} alt="battery"/>
            </div>

            <div className="device-item__icon-img">
              <img src={CONNECT_IMGS[device.signal]} alt="signal"/>
            </div>
          </div>
        </div>

        <div className="device-item__name">
          <span className='title_h3'>{getDeviceInfoByType(device.type).name}</span>
        </div>

        <div className="device-item__footer">
          <span>SN: {device.serial}</span>
        </div>
      </NavLink>
    )
  })

  return (
    <Loader
      loading={isFirstLoading || !!lastGetDevicesRequest}
    >
      {!devicesList.length &&
        <p className={'devices-page__empty-list'}>
          <FormattedMessage id={'devicesPage.noDevices'}/>
        </p>
      }

      <div className="devices-page__list">
        {devices}
      </div>
    </Loader>
  )
}

export default DevicesList
