import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'resourcesTypes'
const translations = {
  0: 'Not set',
  1: 'Cold water',
  2: 'Hot water',
  3: 'Heat',
  4: 'Electricity',
  5: 'Gas',
  6: 'Ventilation'
}

export const resourcesTypesEn = createTranslationsWithSpaceName(translations, spaceName)
