import {TableCell} from "@mui/material";

const SumTableCell = (
  {
    selectors,
    columnWidth,
    useSumSpaceName
  }) => {
  const sum = selectors[useSumSpaceName]()

  return (
    <TableCell
      sx={{
        fontWeight: '500 !important',
        width: columnWidth
      }}
    >
      {sum}
    </TableCell>
  )
}

export default SumTableCell
