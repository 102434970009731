import {MONTH_SCHEDULE_TYPE} from "@/presentation/components/reportsPage/const";

export const INIT_CHANGE_SETTINGS_DATA = Object.freeze({
  address: null,
  location: null
})

export const INIT_SCHEDULE_DATA = Object.freeze({
  type: MONTH_SCHEDULE_TYPE,
  day: 1,
  time: '12:00'
})
