import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'requests'
const translations = {
  error: 'Ошибка!',
  deleteAccErr: 'Не удалось удалить аккаунт',
  detailsChanges: 'Изменение личных данных',
  detailsChangesSuccess: 'Личные данные успешно изменены',
  detailsChangesError: 'Не удалось изменить личные данные',
  topUpAccountSuccessHeader: 'Пополнение баланса',
  topUpAccountSuccess: 'Вы пополнили счёт на сумму {amount, number}!',
  topUpAccountErrorHeader: 'Пополнение баланса',
  topUpAccountError: 'Не удалось пополнить баланс. Оплата не произведена',
  changeEmailSuccessHeader: 'Изменение email',
  changeEmailSuccess: 'Email успешно изменен',
  changeEmailError: `Не удалось изменить email`,
  sendCodeMailError: 'Не удалось отправить письмо с подтверждением',
  changeDetailsSuccessHeader: 'Изменение реквизитов',
  changeDetailsSuccess: 'Реквизиты успешно изменены',
  changeDetailsError: 'Изменить реквизиты не удалось',
  changePasswordSuccessHeader: 'Изменение пароля',
  changePasswordSuccess: 'Пароль успешно изменен!',
  changePasswordError: 'Не удалось изменить пароль',
  creatingObjectSuccessHeader: 'Создание объекта учета',
  creatingObjectSuccess: 'Объект учета успешно создан',
  creatingObjectError: 'Не удалось создать объект учета',
  getObjectsError: 'Не удалось загрузить точки учета',
  changingObjectSuccessHeader: 'Изменение объекта учета',
  changingObjectSuccess: 'Объект учета успешно изменен',
  changingObjectError: 'Не удалось изменить объект учета "{name, string}"',
  removeObjectSuccessHeader: 'Удаление объекта учета',
  removeObjectSuccess: 'Объект учета успешно удален',
  removeObjectError: 'Не удалось удалить объект учета',
  addMeteringPointError: 'Не удалось добавить точку учета',
  addMeteringPointSuccessHeader: 'Добавление точки учета',
  addMeteringPointSuccess: 'Точка учета успешно добавлена',
  changingMeteringPointSuccessHeader: 'Изменение точки учета',
  changingMeteringPointSuccess: 'Точка учета успешно изменена',
  changingMeteringPointError: 'Не удалось изменить точку учета {name, string}',
  removeMeteringPointError: 'Не удалось удалить точку учета',
  removeMeteringPointSuccessHeader: 'Удаление точки учета',
  removeMeteringPointSuccess: 'Точка учета успешно удалена',
  loadAddressError: 'Не удалось загрузить искомый адрес',
  loadMeteringPointError: 'Не удалось загрузить точку учета',
  confirmMailError: 'Не удалось выслать письмо с подтверждением',
  getAnalyticDataError: 'Не удалось получить данные о аналитике',
  deleteAnalyticSuccessHeader: 'Удаление отчета',
  deleteAnalyticSuccess: 'Отчет успешно удален!',
  deleteAnalyticError: 'Не удалось удалить отчет',
  analyticMeteringsError: 'Не удалось сохранить данные',
  analyticTemperatureError: 'Не удалось получить температуру',
  analyticCalcError: 'Не удалось выполнить расчеты',
  analyticCreateError: 'Не удалось создать аналитику',
  analyticNameChangeSuccessHeader: 'Изменение отчета',
  analyticNameChangeSuccess: 'Название отчета успешно изменено',
  analyticNameChangeError: 'Не удалось изменить название отчета',
  downloadReport: 'Скачивается {name}',
  downloadReportError: 'Не удалось скачать отчет',
  sendReportSuccessHeader: 'Отправка отчета',
  sendReportSuccess: 'Отчет успешно отправлен!',
  sendReportError: 'Не удалось отправить отчет',
  saveAnalyticSuccessHeader: 'Сохранение аналитики',
  saveAnalyticSuccess: 'Аналитика успешно сохранена!',
  saveAnalyticError: 'Не удалось сохранить аналитику',
  addDeviceSuccessHeader: 'Добавление устройства',
  addDeviceSuccess: 'Устройство успешно добавлено',
  addDeviceError: 'Не удалось добавить устройство',
  changeCallFrequencySuccessHeader: 'Изменение частоты выхода на связь',
  changeCallFrequencySuccess: 'Частота выхода на связь успешно изменена',
  changeCallFrequencyError: 'Не удалось изменить частоту выхода на связь',
  pollError: 'Не удалось опросить устройство',
  changeDeviceSettingsSuccessHeader: 'Изменение настроек устройства',
  changeDeviceSettingsSuccess: 'Настрйоки устройства успешно изменены',
  changeDeviceSettingsError: 'Не удалось изменить настрйоки устройства',
  removeDeviceSuccessHeader: 'Удаление устройства',
  removeDeviceSuccess: 'Устройство успешно удалено',
  removeDeviceError: 'Не удалось удалить устройство',
  changeSimSuccessHeader: 'Изменение номера',
  changeSimSuccess: 'Номер успешно изменен',
  changeSimError: 'Не удалось изменить номер',
  changeReportSuccessHeader: 'Изменение отчета',
  changeReportSuccess: 'Отчет изменен успешно',
  changeReportError: 'Не удалось изменить отчет',
  removeReportSuccessHeader: 'Удаление отчета',
  removeReportSuccess: 'Отчет удален успешно',
  removeReportError: 'Не удалось удалить отчет',
  createReportSuccessHeader: 'Создание отчета',
  createReportSuccess: 'Отчет создан успешно',
  createReportError: 'Не удалось создать отчет',
  getMeteringPointsListError: 'Не удалось получить список точек учета',
  getDevicesListError: 'Не удалось получить список устройств',
  getReportsHistoryError: 'Не удалось получить историю отчетов',
  getMeteringsError: 'Не удалось получить показния счетчиков',
  saveMeteringsError: 'Не удалось сохранить показания',
  noMeterReadings: 'Введите показания счетчиков',
  checkAddressesDevicesError: 'Не удалось проверить наличие оборудования на объекте',
  getDeviceInfoError: 'Не удалось получить информацию об устрйостве',
  untieDevicesSuccessHeader: 'Отвязка оборудования',
  untieDevicesSuccess: 'Оборудование успешно отвязано',
  untieDevicesError: 'Не удалось отвязать оборудование',
  tieDevicesSuccessHeader: 'Привязка оборудования',
  tieDevicesSuccess: 'Оборудование успешно привязано',
  tieDevicesError: 'Не удалось привязать оборудование',
}

export const requestsRu = createTranslationsWithSpaceName(translations, spaceName)
