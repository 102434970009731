export default class Constants {
  static newPointPath = 'add_metering_point'

  // resources types
  static coldWaterType = 1
  static hotWaterType = 2
  static heatType = 3
  static electricType = 4
  static gasType = 5
  static ventilationType = 6
}
