import {DEVICES_TYPES} from "@/internal/content/consts";

export const toggleItems = (param, array, setNewArray) => {
  if (array.includes(param)) {
    const newArr = array.filter(item => item !== param)

    setNewArray(newArr)
  } else {
    const newArr = [...array, param]

    setNewArray(newArr)
  }
}

export const getDeviceInfoByType = (type) => {
  if (!DEVICES_TYPES[type]) {
    throw Error('unknown device type')
  } else {
    return DEVICES_TYPES[type]
  }
}

export const createTranslationsWithSpaceName = (translations, spaceName) => {
  const translationsWithSpaceName = {}

  for (const key in translations) {
    translationsWithSpaceName[`${spaceName}.${key}`] = translations[key]
  }

  return translationsWithSpaceName
}

export const extractNumber = (str) => {
  const matches = str?.match(/\d+/);
  return matches ? parseInt(matches[0], 10) : null;
}
