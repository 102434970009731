import {createEvent, createStore} from "effector";
import {useEvent, useStore} from "effector-react";
import {typeMessageColors} from "./type";

const setMessageId = createEvent()
const deleteMessage = createEvent()
export const setMessage = setMessageId.prepend(payload => ({...payload, id: Date.now(),}))

export const $messagesList = createStore([])
  .on(setMessageId, (s, payload) => {
    const newState = [...s]

    newState.push({
      id: payload?.id,
      header: payload?.header ?? 'Сообщение',
      message: payload?.message ?? 'Привет.',
      type: typeMessageColors[payload?.type] ?? typeMessageColors['success'],
      count: payload?.count ?? 5000,
    })

    return newState
  })
  .on(deleteMessage, (s, payload) => {
    const newState = [...s]
    const currentMsgIndex = newState.findIndex(message => payload.id === message.id)

    if (currentMsgIndex < 0) return newState

    newState.splice(currentMsgIndex, 1)
    return newState
  })

export const selectors = {
  useMessagesList: () => useStore($messagesList),
  useSetMessage: () => useEvent(setMessage),
  useDeleteMessage: () => useEvent(deleteMessage),
}
