import pdfOffert from '../pdfOffert/offert.pdf'
import {FormattedMessage} from "react-intl";

const PdfComponent = ({onAgree, onDisagree, toggleIsPdfOpen}) => {
  const handleClickClosePdf = (e) => {
    const {target} = e

    if (target && !target.closest('.container-ofer__btns')) {
      toggleIsPdfOpen()
    }
  }
  return (
    <div
      className="container-ofer"
      onClick={handleClickClosePdf}
    >
      <div>
        <embed src={pdfOffert}  type='application/pdf'/>

        <div className="container-ofer__btns">
          <button className={'btn'} onClick={onAgree}>
            <FormattedMessage id={'authorization.accept'}/>
          </button>
          <button className="btn btn_transparent-bg" onClick={onDisagree}>
            <FormattedMessage id={'app.cancel'}/>
          </button>
        </div>
      </div>
    </div>
  )
}

export default PdfComponent
