import {useState} from "react";
import VisibilitySensor from "react-visibility-sensor";

const WithVisibilitySensor = ({component, placeholderComponent, sensorsProps, setIsCreatingRows}) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <VisibilitySensor
      onChange={(is) => {
        if (is && setIsCreatingRows) setIsCreatingRows(false)
        setIsVisible(is)
      }}
      partialVisibility
      intervalDelay={100}
      delayedCall
      {...sensorsProps}
    >
      {isVisible
        ? component
        : placeholderComponent
      }
    </VisibilitySensor>
  )
}

export default WithVisibilitySensor
