import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'passwordRules'
const translations = {
  passwordRequirements: 'Passwortanforderungen',
  number: 'nummer',
  lowercaseLatinLetter: 'dinkel lateinischer buchstabe',
  uppercaseLatinLetter: 'hauptstadt lateinischer buchstabe',
  eightSymbols: '8 charaktere',
  specialSymbols: 'besonderes symbol (!@#$%^&*()_)'
}

export const passwordRulesDe = createTranslationsWithSpaceName(translations, spaceName)