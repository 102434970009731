import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {DEVICE_FORM_INIT_DATA, DEVICE_FORM_INIT_VALIDATIONS} from "../../status/const";
import {getDeviceFormValidators, getDeviceSimValidator} from "@/internal/validators/formValidators";
import {INIT_CHANGE_SETTINGS_DATA, INIT_SCHEDULE_DATA} from "../const";
import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";

const newDeviceFormModel = new FormModelFabric({
  initialData: DEVICE_FORM_INIT_DATA,
  initialValidations: DEVICE_FORM_INIT_VALIDATIONS,
  getValidators: getDeviceFormValidators
})

const scheduleDeviceFormModel = new FormModelFabric({
  initialData: INIT_SCHEDULE_DATA
})

const deviceSettingsFormModel = new FormModelFabric({
  initialData: INIT_CHANGE_SETTINGS_DATA
})

const deviceSimSettingsModel = new FormModelFabric({
  initialData: {
    sim: ''
  },
  initialValidations: {
    sim: []
  },
  getValidators: getDeviceSimValidator
})

const newDevicesOnlySlavesFormModel = new FormModelFabric({
  initialData: DEVICE_FORM_INIT_DATA,
  initialValidations: DEVICE_FORM_INIT_VALIDATIONS,
  getValidators: getDeviceFormValidators
})

const slavesFormsModel = new StoreModelFabric([])
const toLinkedDevicesModel = new StoreModelFabric([])
const masterIdModel = new StoreModelFabric(0)

export const devicesNewDeviceFormSelectors = newDeviceFormModel.createSelectors()
export const scheduleDeviceFormSelectors = scheduleDeviceFormModel.createSelectors()
export const deviceSettingsFormSelectors = deviceSettingsFormModel.createSelectors()
export const deviceSimSettingsFromSelectors = deviceSimSettingsModel.createSelectors()
export const devicesSlavesFormsSelectors = slavesFormsModel.createSelectors()
export const devicesToLinkedDevicesSelectors = toLinkedDevicesModel.createSelectors()
export const devicesMasterIdSelectors = masterIdModel.createSelectors()
export const newDevicesOnlySlavesFormSelectors = newDevicesOnlySlavesFormModel.createSelectors()
