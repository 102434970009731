import {Basket} from "@/assets/svg";
import React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import SelectInput from "@/presentation/components/common/formComponents/SelectInput";
import Input from "@/presentation/components/common/formComponents/Input";
import {AVAILABLE_DEVICES_LIST, getSerialPlaceholder} from "@/presentation/components/deviceForm/const";
import DevicesTypes from "@/presentation/components/devicesPage/devices_types";
import {cloneDeep} from "lodash";
import {devicesSlavesFormsSelectors} from "@/internal/lib/storeModels/models/devices/forms/deviceFormModels";

const SlaveForm = ({index, formSelectors, masterType}) => {
  const intl = useIntl()

  const type = formSelectors.useFormDataValue('type')
  const [slavesForms, setSlavesForms] = devicesSlavesFormsSelectors.useState()
  const availableDevicesTypes = masterType === DevicesTypes.GSMBridge
    ? DevicesTypes.endDevicesRsAndRadio + DevicesTypes.endDevicesOnlyRadio
    : masterType === DevicesTypes.bridge
      ? DevicesTypes.endDevicesRsAndRadio + DevicesTypes.endDevicesOnlyRS + DevicesTypes.endDevicesOnlyRadio
      : []

  const onDeleteDeviceForm = () => {
    const currentForms = cloneDeep(slavesForms)
    currentForms.splice(index, 1)
    setSlavesForms(currentForms)
  }

  return (
    <div className={'slave-form'}>
      <div className="slave-form__title">
        <h4 className={'title title_h4'}>Устройство {index + 1}</h4>

        <button onClick={onDeleteDeviceForm}>
          <img src={Basket} alt="Basket"/>
        </button>
      </div>

      <div className="form-fields">
        <SelectInput
          label={<FormattedMessage id={'deviceForm.selectDeviceType'}/>}
          name={'type'}
          options={AVAILABLE_DEVICES_LIST.filter(option => option.value === 0 || availableDevicesTypes.includes(option.value))}
          formSelectors={formSelectors}
        />

        {!!type &&
          <>
            <Input
              formSelectors={formSelectors}
              label={<FormattedMessage id={'deviceForm.serial'}/>}
              name={'serial'}
              placeholder={getSerialPlaceholder(type, intl)}
            />

            {type === DevicesTypes.heatMeter &&
              <Input
                formSelectors={formSelectors}
                label={<FormattedMessage id={'deviceForm.address'}/>}
                name={'rsAddress'}
                type={'number'}
              />
            }
          </>
        }
      </div>
    </div>
  )
}

export default SlaveForm
