import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {
  INIT_APARTMENT_DATA, INIT_APARTMENT_FORM_VALIDATIONS, INIT_ENUMERATOR_DATA, INIT_ENUMERATOR_FORM_VALIDATIONS,
  INIT_MKD_FORM_DATA,
  INIT_MKD_FORM_VALIDATIONS
} from "@/internal/lib/storeModels/models/mkds/forms/const";
import {getApartmentFormValidators, getEnumeratorValidators, getMKDFormValidators} from "@/internal/validators/formValidators";

const newMKDFormState = new FormModelFabric({
  initialData: INIT_MKD_FORM_DATA,
  initialValidations: INIT_MKD_FORM_VALIDATIONS,
  getValidators: getMKDFormValidators
})

const changeMKDFormState = new FormModelFabric({
  initialData: INIT_MKD_FORM_DATA,
  initialValidations: INIT_MKD_FORM_VALIDATIONS,
  getValidators: getMKDFormValidators
})

const newApartmentFormState = new FormModelFabric({
  initialData: INIT_APARTMENT_DATA,
  initialValidations: INIT_APARTMENT_FORM_VALIDATIONS,
  getValidators: getApartmentFormValidators
})

const changeApartmentFormState = new FormModelFabric({
  initialData: INIT_APARTMENT_DATA,
  initialValidations: INIT_APARTMENT_FORM_VALIDATIONS,
  getValidators: getApartmentFormValidators
})

const newEnumeratorFormState = new FormModelFabric({
  initialData: INIT_ENUMERATOR_DATA,
  initialValidations: INIT_ENUMERATOR_FORM_VALIDATIONS,
  getValidators: getEnumeratorValidators
})

const changeEnumeratorFormState = new FormModelFabric({
  initialData: INIT_ENUMERATOR_DATA,
  initialValidations: INIT_ENUMERATOR_FORM_VALIDATIONS,
  getValidators: getEnumeratorValidators
})

export const newMKDFormStateSelectors = newMKDFormState.createSelectors()
export const changeMKDFormStateSelectors = changeMKDFormState.createSelectors()
export const newApartmentFormSelectors = newApartmentFormState.createSelectors()
export const changeApartmentFormSelectors = changeApartmentFormState.createSelectors()
export const newEnumeratorFormSelectors = newEnumeratorFormState.createSelectors()
export const changeEnumeratorFormSelectors = changeEnumeratorFormState.createSelectors()
