import {
  registrationPasswordValidationsSelectors,
} from "@/internal/lib/storeModels/models/authorization/registrationPasswordModel";
import {phoneFormatter} from "@/internal/formatters/formatters";
import Input from "@/presentation/components/common/formComponents/Input";
import {individualFormSelectors} from "@/internal/lib/storeModels/models/authorization/forms/registrationUserModels";
import MaskInput from "@/presentation/components/common/formComponents/MaskInput";
import {FormattedMessage, useIntl} from "react-intl";

const IndividualForm = () => {
  const intl = useIntl()
  const validatePassword = registrationPasswordValidationsSelectors.useValidatePassword()

  return (
    <>
      <Input
        label={<FormattedMessage id={'authorization.login'}/>}
        name={'login'}
        formSelectors={individualFormSelectors}
      />

      <MaskInput
        mask="+7 (999) 999-99-99"
        type="tel"
        name="phone"
        label={<FormattedMessage id={'authorization.phoneOptional'}/>}
        valueFormatter={phoneFormatter}
        formSelectors={individualFormSelectors}
      />

      <Input
        type="email"
        name={'email'}
        placeholder={intl.formatMessage({id: 'authorization.mailPlaceHolder'})}
        label={<FormattedMessage id={'authorization.e-mail'}/>}
        formSelectors={individualFormSelectors}
      />

      <Input
        label={<FormattedMessage id={'authorization.password'}/>}
        type={'password'}
        name={'password'}
        formSelectors={individualFormSelectors}
        withChangeValue={(value) => validatePassword(value)}
      />
    </>
  )
}

export default IndividualForm
