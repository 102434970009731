export const LOCALES = {
  ENGLISH: 'en',
  RUSSIAN: 'ru',
  GERMAN: 'de'
}

export const CURRENCY = {
  [LOCALES.ENGLISH]: 'EUR',
  [LOCALES.GERMAN]: 'EUR',
  [LOCALES.RUSSIAN]: 'RUB',
}
