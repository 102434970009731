import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";
import {LOCALES} from "@/i18n/locales";

export const AppAuthStatus = {
  notAuth: 'notAuth',
  auth: 'auth'
}

const isAppErrorModel = new StoreModelFabric(false)
const localeModel = new StoreModelFabric(LOCALES.RUSSIAN)
const appAuthorizationState = new StoreModelFabric(AppAuthStatus.notAuth)

export const appSelectors = {
  isAppError: isAppErrorModel.createSelectors(),
  locale: {
    ...localeModel.createSelectors(),
    getLocale: localeModel.$store.getState
  },
  appAuthorization: appAuthorizationState.createSelectors()
}
