import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {SIGN_IN_INIT_DATA, SIGN_IN_VALIDATIONS} from "../const";
import {getSignInValidator} from "@/internal/validators/formValidators";

const signInModel = new FormModelFabric({
  initialData: SIGN_IN_INIT_DATA,
  initialValidations: SIGN_IN_VALIDATIONS,
  getValidators: getSignInValidator
})

export const signInFormSelectors = signInModel.createSelectors()
