import {request} from "@/internal/lib/transport/request";

export const heatIndicationsEndpoints = {
  setData(data, id) {
    const params = {
      url: `api/heat_indications/${id}/`,
      method: 'PUT',
      data: data
    }

    return request(params)
  }
}
