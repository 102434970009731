import {useEffect} from "react";
import MultiEmailsInputs from "@/presentation/components/common/MultiEmailsInputs";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";

const SendResultForm = ({onValidSubmit, formSelectors, name}) => {
  const resetForm = formSelectors.useResetForm()
  const setOnValidSubmit = formSelectors.useSetOnValidSubmit()
  const setMails = formSelectors.useSetFormDataValue(name)
  const userData = userDataSelectors.userData.useValue()

  useEffect(() => {
    setOnValidSubmit(onValidSubmit)
    setMails([userData.email])
    return () => {
      resetForm()
    }
  }, [])

  return (
    <MultiEmailsInputs
      name={name}
      formSelectors={formSelectors}
    />
  )
}

export default SendResultForm
