export const INIT_MKD_FORM_DATA = Object.freeze({
  region: '',
  city: '',
  street: '',
  building: '',
  odn: 35,
  unit_type: 1,
  device_type: null,
  servicemanager: 0
})

export const INIT_MKD_FORM_VALIDATIONS = Object.freeze({
  street: [],
  device_type: [],
  odn: []
})

export const INIT_APARTMENT_DATA = Object.freeze({
  kv: '',
  area: null,
  invoice: '',
  email: ''
})

export const INIT_APARTMENT_FORM_VALIDATIONS = Object.freeze({
  kv: [],
  area: [],
  invoice: [],
  email: []
})

export const INIT_ENUMERATOR_DATA = Object.freeze({
  number: null,
  coefficient: 1.00,
  serial: '',
  power: null
})

export const INIT_ENUMERATOR_FORM_VALIDATIONS = Object.freeze({
  number: [],
  coefficient: [],
  serial: [],
  power: []
})
