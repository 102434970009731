import {AddDeviceTrue} from "@/assets/svg";
import {useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {statusDeviceSelectors} from "@/internal/lib/storeModels/models/status/statusDeviceFormModel";
import {FormattedMessage} from "react-intl";
import {useNavigateBack} from "@/internal/custom_hooks/navigateBack";

const DeviceAdded = ({isTwoDevices, hideAside = null, btnText}) => {
  const [navigateBack] = useNavigateBack()
  const resetIsDeviceCreated = statusDeviceSelectors.useReset()

  const title = useMemo(() => {
    return isTwoDevices
      ? <FormattedMessage id={'deviceForm.devicesAdded'}/>
      : <FormattedMessage id={'deviceForm.deviceAdded'}/>
  }, [isTwoDevices])

  const handleClick = () => {
    navigateBack()
    resetIsDeviceCreated()

    if (hideAside) hideAside()
  }

  return (
    <div
      className={'device-added'}
    >
      <div className="device-added__img">
        <img src={AddDeviceTrue} alt="AddDeviceTrue"/>
      </div>

      <h2 className="title">{title}</h2>

      <p className={'device-added__description'}>
        <FormattedMessage id={'deviceForm.customizeImpulse'}/>
      </p>

      <button
        className='btn'
        onClick={handleClick}
      >
        {btnText}
      </button>
    </div>
  )
}

export default DeviceAdded
