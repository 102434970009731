import {SectionInDevelopment} from "@/assets/svg";
import {FormattedMessage} from "react-intl";

const SectionDeveloped = () => {
  return (
    <div className='section-developed'>
      <div className="section-developed__img">
        <img src={SectionInDevelopment} alt="SectionInDevelopment"/>
      </div>

      <h3 className="title_h3">
        <FormattedMessage id={'app.sectionDeveloped'}/>
      </h3>
    </div>
  )
}

export default SectionDeveloped
