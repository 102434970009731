import {Close} from "@/assets/svg";
import SelectComponent from "@/presentation/components/common/selects/Select";
import {Table, TableBody, TableCell, TablePagination, TableRow} from "@mui/material";
import {makeStyles} from '@mui/styles'
import EnhancedTableHead from "./EnhancedTableHead";
import React, {useMemo, useState} from "react";
import {getComparator, stableSort} from "../helpers";
import {ROWS, ROWS_PER_PAGE_LIST, UTC_LIST} from "./const";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: 750,

  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
    background: '#ECF0F6',
  },
});

const DeviceActivityLog = ({toggleActivity}) => {
  const intl = useIntl()
  const classes = useStyles()

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [UTC, setUTC] = useState(3)
  const [startDate, setStartDate] = useState('2021-04-27')
  const [endDate, setEndDate] = useState('2021-04-27')

  const UTCOption = useMemo(() => {
    return UTC_LIST.find(option => option.value === UTC)
  }, [UTC])

  const rowsPerPageOption = useMemo(() => {
    return ROWS_PER_PAGE_LIST.find(option => option.value === rowsPerPage)
  }, [rowsPerPage])

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }

  const handleClick = (e) => {
    const {target} = e

    if (target && !target.closest('.device-activity__substr')) {
      toggleActivity()
    }
  }

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const rows = useMemo(() => {
    return stableSort(ROWS, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row, index) => {
        const labelId = `enhanced-table-checkbox-${index}`

        return (
          <TableRow hover key={row.id}>
            <TableCell
              component={'th'}
              id={labelId}
              scope="row"
            >
              {row.name}
            </TableCell>
            <TableCell align="left">{row.calories}</TableCell>
            <TableCell align="left">{row.fat}</TableCell>
            <TableCell align="left">{row.carbs}</TableCell>
            <TableCell align="left">{row.protein}</TableCell>
          </TableRow>
        )
      })
  }, [order, orderBy, page, rowsPerPage])

  return (
    <div
      className="device-activity"
      onClick={handleClick}
    >
      <div className="device-activity__substr">
        <div className="device-activity__header">
          <div className="device-activity__header-left">
            <h3 className="title_h3">
              <FormattedMessage id={'devicesPage.activityLog'}/>
            </h3>

            <p>
              <FormattedMessage id={'devicesPage.lastUpdate'}/>
            </p>

            <span>05.07.21 12:04:03</span>
          </div>

          <div className="device-activity__header-right">
            <div
              className="device-activity__close"
              onClick={toggleActivity}
            >
              <img src={Close} alt="Close"/>
            </div>
          </div>
        </div>

        <div className="device-activity__table-settings">
          <div className="device-activity__table-selects">
            <div className="form-group">
              <label>
                <FormattedMessage id={'devicesPage.localTime'}/>
              </label>

              <SelectComponent
                optionsList={UTC_LIST}
                value={UTCOption}
                handleChange={(e) => setUTC(e.value)}
              />
            </div>

            <div className="device-activity__double-inputs">
              <div className="form-group">
                <label>
                  <FormattedMessage id={'devicesPage.periodFrom'}/>
                </label>

                <input
                  type={'date'}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label>
                  <FormattedMessage id={'devicesPage.to'}/>
                </label>

                <input
                  type={'date'}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>

            <div className="form-group">
              <label>
                <FormattedMessage id={'app.onPage'}/>
              </label>

              <SelectComponent
                optionsList={ROWS_PER_PAGE_LIST}
                value={rowsPerPageOption}
                handleChange={(e) => {
                  setRowsPerPage(e.value)
                  setPage(0)
                }}
              />
            </div>
          </div>

          <TablePagination
            component={'div'}
            count={ROWS.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            labelDisplayedRows={({from, to, count}) => {
              return (
                <FormattedMessage
                  id={'app.countOf'}
                  values={{
                    from,
                    to,
                    count: count !== -1
                      ? count
                      : intl.formatMessage(
                        {id: 'app.moreThen'},
                        {to}
                      )
                  }}
                />
              )
            }}
          />
        </div>

        <div className="device-activity__table">
          <Table
            className={classes.table}
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {rows}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default DeviceActivityLog
