import React, {useMemo} from "react";
import {
  DISPLAY_INCREMENT_TYPE,
  DISPLAY_INTEGRAL_INCREMENT_TYPE,
  DISPLAY_INTEGRAL_TYPE,
  DISPLAY_TYPES_LIST
} from "./const";
import SelectComponent from "@/presentation/components/common/selects/Select";
import {FormattedMessage} from "react-intl";

const ReportDisplayTypeSelect = (
  {
    formSelectors
  }) => {
  const incremental = formSelectors.useFormDataValue('incremental')
  const accumulated = formSelectors.useFormDataValue('accumulated')
  const setFormValue = (name) => formSelectors.setFormDataValue(name)

  const displayType = incremental
    ? accumulated
      ? DISPLAY_TYPES_LIST.find(item => item.value === DISPLAY_INTEGRAL_TYPE)
      : DISPLAY_TYPES_LIST.find(item => item.value === DISPLAY_INCREMENT_TYPE)
    : accumulated
      ? DISPLAY_TYPES_LIST.find(item => item.value === DISPLAY_INTEGRAL_INCREMENT_TYPE)
      : DISPLAY_TYPES_LIST.find(item => item.value === 0)

  const onChangeDisplayType = (e) => {
    switch (e.value) {
      case DISPLAY_INTEGRAL_TYPE:
        setFormValue('incremental')(true)
        setFormValue('accumulated')(true)

        break
      case DISPLAY_INCREMENT_TYPE:
        setFormValue('incremental')(true)
        setFormValue('accumulated')(false)

        break
      case DISPLAY_INTEGRAL_INCREMENT_TYPE:
        setFormValue('incremental')(false)
        setFormValue('accumulated')(true)

        break
      case 0:
        setFormValue('incremental')(false)
        setFormValue('accumulated')(false)

        break
    }
  }

  return (
    <div className="form-group">
      <label>
        <FormattedMessage id={'reportsPage.displayReadings'}/>
      </label>

      <SelectComponent
        optionsList={DISPLAY_TYPES_LIST}
        value={displayType}
        handleChange={onChangeDisplayType}
      />
    </div>
  )
}

export default ReportDisplayTypeSelect
