import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'backCall'
const translations = {
  title: 'Anscheinend ist ein Fehler aufgetreten',
  subtitle: 'Unser Team wurde benachrichtigt.',
  subtitle2: 'Wenn Sie helfen wollen, erzählen Sie uns, was passiert ist.',
  labelName: 'Name',
  labelEmail: 'E-mail',
  labelComments: 'Was ist passiert?',
  labelClose: 'Nah dran',
  labelSubmit: 'Senden',
  errorGeneric: "Beim Senden des Berichts ist ein unbekannter Fehler aufgetreten. Bitte versuche es erneut.",
  errorFormEntry: 'Einige Felder waren ungültig. Bitte korrigieren Sie die Fehler und versuchen Sie es erneut.',
  successMessage: 'Ihre Bewertung wurde gesendet. Ich danke Ihnen!',
  namePlaceholder: 'Name',
  emailPlaceHolder: 'example@mail.com',
  titleCustom: 'Fehler gefunden?',
  subtitleCustom: 'Wenn Sie helfen wollen, erzählen Sie uns, was passiert ist.',
  subtitle2Custom: 'Unser Team wird benachrichtigt.'
}

export const backCallDe = createTranslationsWithSpaceName(translations, spaceName)
