import {isOpenAsideSelectors} from "@/internal/lib/storeModels/models/status/asideFormModel";
import AddressForm from "@/presentation/components/addressForm/AddressForm";
import {mainPageNewAddressFormSelectors} from "@/internal/lib/storeModels/models/mainPage/forms/newAddressFormModel";
import {useCallback, useEffect, useRef, useState} from "react";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import {selectors} from "@/presentation/shared/ui/message";
import {addressEndpoint} from "@/data/api_entities/address/address_endpoint";
import {FormattedMessage} from "react-intl";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import {mainPageSelectors} from "@/internal/lib/storeModels/models/mainPage/mainPageModel";
import {mainPageEditAddressFormSelectors} from "@/internal/lib/storeModels/models/mainPage/forms/editAddressFormModel";
import {selectedUserSelectors} from "@/internal/lib/storeModels/models/servicemen/selectedUserModel";

const AsideForm = ({getInfo = null}) => {
  const substrRef = useRef()

  const [isLoadingNewAddress, setIsLoadingNewAddress] = useState(false)
  const [preexistingAddressInfo, setPreexistingAddressInfo] = useState(null)
  const [isOpenPreexistModal, togglePreexistModal] = useToggle()
  const [isOpenDeleteModal, toggleDeleteModal] = useToggle()
  const [isOpenDeleteObjWithDevicesModal, toggleDeleteObjWithDevicesModal] = useToggle()
  const [isDeleting, setIsDeleting] = useState(false)
  const [isDeviceChecking, setIsDeviceChecking] = useState(false)
  const [isAddressWithDevices, setIsAddressWithDevices] = useState(false)

  const [isOpenAside, setIsOpenAside]= isOpenAsideSelectors.useState()
  const isNewAddress = mainPageSelectors.isNewAddress.useValue()
  const [editAddressId, setEditAddressId] = mainPageSelectors.editAddress.useState()
  const updateAddresses = addressesSelectors.addresses.useUpdateAddresses()
  const setMessage = selectors.useSetMessage()
  const resetForm = mainPageNewAddressFormSelectors.useResetForm()
  const selectedUser = selectedUserSelectors.useValue()

  useEffect(() => {
    if (!editAddressId) return

    setIsDeviceChecking(true)
    const {promise} = addressEndpoint.getDevices(editAddressId)

    promise
      .then(res => {
        setIsAddressWithDevices(!!((res?.devices ?? []).length))
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.checkAddressesDevicesError'} />,
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
      .finally(() => setIsDeviceChecking(false))
  }, [editAddressId])

  useEffect(() => {
    if (!substrRef.current) return

    substrRef.current.scrollTo(0, 0)
  }, [isOpenAside])

  const onClickAsideSubstr = (e) => {
    const {target} = e

    // if (target && !target.closest('.aside-form__substr')) {
    //   hideAside()
    // }
  }

  const hideAside = () => {
    setIsOpenAside(false)
    setEditAddressId(null)
  }

  const onValidSubmitNewAddress = (data) => {
    setIsLoadingNewAddress(true)

    addressEndpoint.createNewAddress({...data, user_manager: selectedUser?.id ?? null}).promise
      .then((res) => {
        if (res.status === 'error') {
          console.log(res)
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: <FormattedMessage id={'requests.creatingObjectError'}/>,
            type: 'error',
            count: 3000
          })

          return
        }
        if (res.status_code === 409) {
          setPreexistingAddressInfo(res.object)
          togglePreexistModal()
        } else {
          hideAside()
          resetForm();
          (getInfo ?? updateAddresses)()

          setMessage({
            header: <FormattedMessage id={'requests.creatingObjectSuccessHeader'}/>,
            message: <FormattedMessage id={'requests.creatingObjectSuccess'}/>,
            count: 3000
          })
        }
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.creatingObjectError'}/>,
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsLoadingNewAddress(false))
  }

  const onValidSubmitChangeAddress = (data) => {
    addressEndpoint.changeAddressInfo({...data,  user_manager: selectedUser?.id ?? null}).promise
      .then((res) => {
        if (res.status_code === 409) {
          setPreexistingAddressInfo(res.object)
          togglePreexistModal()
        } else {
          hideAside();
          (getInfo ?? updateAddresses)()

          setMessage({
            header: <FormattedMessage id={'requests.changingObjectSuccessHeader'}/>,
            message: <FormattedMessage id={'requests.changingObjectSuccess'}/>,
            count: 3000
          })
        }
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.changingObjectError'} values={{name: data.name}}/>,
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
  }

  const onDeleteAddress = (addressId) => {
    if (!addressId || isDeleting) return

    setIsDeleting(true)

    addressEndpoint.deleteAddress(addressId)
      .then(() => {
        toggleDeleteModal()
        hideAside();
        (getInfo ?? updateAddresses)()

        setMessage({
          header: <FormattedMessage id={'requests.removeObjectSuccessHeader'}/>,
          message: <FormattedMessage id={'requests.removeObjectSuccess'}/>,
          count: 3000
        })
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.removeObjectError'}/>,
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
      .finally(() => setIsDeleting(false))
  }

  const handleToggleDeleteModal = () => {
    if (isAddressWithDevices) {
      toggleDeleteObjWithDevicesModal()
    } else {
      toggleDeleteModal()
    }
  }

  return (
    <div
      className={`aside-form ${isOpenAside === null ? '' : isOpenAside ? 'aside-form_open' : 'aside-form_hidden'}`}
      onClick={onClickAsideSubstr}
    >
      {isOpenAside &&
        <div
          ref={substrRef}
          className="aside-form__substr"
        >
          {isNewAddress
            ? (
              <AddressForm
                isNewAddress
                formModelSelectors={mainPageNewAddressFormSelectors}
                hideAside={() => setIsOpenAside(false)}
                isOpenPreexistModal={isOpenPreexistModal}
                togglePreexistModal={togglePreexistModal}
                preexistingAddressInfo={preexistingAddressInfo}
                onValidSubmit={onValidSubmitNewAddress}
                isLoading={isLoadingNewAddress}
              />
            )
            : (
              <AddressForm
                isLoading={isDeviceChecking}
                editAddressId={editAddressId}
                formModelSelectors={mainPageEditAddressFormSelectors}
                hideAside={hideAside}
                isOpenDeleteModal={isOpenDeleteModal}
                toggleDeleteModal={handleToggleDeleteModal}
                isOpenPreexistModal={isOpenPreexistModal}
                togglePreexistModal={togglePreexistModal}
                isOpenDeleteObjWithDevicesModal={isOpenDeleteObjWithDevicesModal}
                preexistingAddressInfo={preexistingAddressInfo}
                onValidSubmit={onValidSubmitChangeAddress}
                onDelete={onDeleteAddress}
                isDeleting={isDeleting}
              />
            )
          }
        </div>
      }

    </div>
  )
}

export default AsideForm
