import {request} from "@/internal/lib/transport/request";
import {getMeterointAdapter, sendMeterointAdapter} from "./meteroints_adapters";

export const meterointsEndpoint = {
  getAllMeteroints () {
    const params = {
      url: 'api/meteroint/',
      method: 'GET'
    }

    return request(params)
  },

  createMeteroint (data) {
    const params = {
      url: 'api/meteroint/',
      method: 'POST',
      data: sendMeterointAdapter(data)
    }

    return request(params).promise
  },

  getMeteriontById (id) {
    const params = {
      url: `api/meteroint/${id}`,
      method: 'GET'
    }

    return request(params, getMeterointAdapter)
  },

  changeMeteroint (data) {
    const params = {
      url: `api/meteroint/${data.point_id}/`,
      method: 'PUT',
      data: sendMeterointAdapter(data)
    }

    return request(params).promise
  },

  deleteMeteroint (id) {
    const params = {
      url: `api/meteroint/${id}/`,
      method: 'DELETE'
    }

    return request(params).promise
  }
}