import {useMemo} from "react";
import {DEVICE_PULSE_ONE_PATH, DEVICE_PULSE_TWO_PATH} from "./const";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {RESOURCES_TYPES} from "@/internal/content/consts";
import {Link} from "react-router-dom";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import {FormattedMessage} from "react-intl";

const DevicePulseInput = ({isOne = false, deviceInfo}) => {
  const selectedAddress = addressesSelectors.selectedAddress.useValue()

  const pathOnVisible = useMemo(() => {
    return isOne
      ? DEVICE_PULSE_ONE_PATH
      : DEVICE_PULSE_TWO_PATH
  }, [isOne])

  const title = useMemo(() => {
    return isOne
      ? <FormattedMessage id={'devicesPage.pulseInputOne'}/>
      : <FormattedMessage id={'devicesPage.pulseInputTwo'}/>
  }, [isOne])

  const pointInfo = useMemo(() => {
    if (!deviceInfo) return null

    const data = isOne
      ? deviceInfo.pulse_channel_1
      : deviceInfo.pulse_channel_2

    return data
      ? <div className={'device-pulse-input__point-name'}>
        <div className="device-pulse-input__point-img">
          <img src={RESOURCES_TYPES[data.type].img} alt="resource"/>
        </div>

        <span>{data.name}</span>
      </div>
      : <span>
        <FormattedMessage id={'devicesPage.notConfigured'}/>
      </span>
  }, [isOne, deviceInfo])

  const prevText = useMemo(() => {
    if (!deviceInfo) return null

    const data = isOne
      ? deviceInfo.pulse_channel_1
      : deviceInfo.pulse_channel_2

    return data
      ? <p><FormattedMessage id={'devicesPage.storedInSystem'}/></p>
      : <p><FormattedMessage id={'devicesPage.setUpMeteringPoint'}/></p>
  }, [isOne, deviceInfo])

  const pathToChangePoint = useMemo(() => {
    if (!deviceInfo) return '/metering_points'

    const data = isOne
      ? deviceInfo.pulse_channel_1
      : deviceInfo.pulse_channel_2

    return data
      ? `/metering_points/${data.id}`
      : '/metering_points'
  }, [isOne, deviceInfo])

  if (!deviceInfo) return null

  return (
    <div className={'device-pulse-input'}>
      <ArrowBack
        pathOnVisible={pathOnVisible}
      />

      <h2 className="title">{title}</h2>

      {prevText}

      <div className="device-pulse-input__settings">
        <div className="device-pulse-input__point">
          <span><FormattedMessage id={'devicesPage.meteringPoint'}/></span>

          {pointInfo}
        </div>

        <Link to={pathToChangePoint} className="btn btn_transparent-bg">
          <FormattedMessage id={'app.customize'}/>
        </Link>
      </div>
    </div>
  )
}

export default DevicePulseInput
