import ArrowBack from "@/presentation/components/common/ArrowBack";
import {PATHS} from "@/presentation/components/heatCalculationPage/components/MKDPage/const";
import {NavLink, Route, Routes} from "react-router-dom";
import HoveredPopover from "@/presentation/components/common/HoveredPopover";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import TablesModal from "@/presentation/components/analytics/сomponents/TablesModal";
import Calculations from "@/presentation/components/heatCalculationPage/components/MKDPage/components/Calculations";
import {calculationsSelectors} from "@/internal/lib/storeModels/models/mkds/calculations/calculationsState";
import React, {useEffect, useRef, useState} from "react";
import {heatCalculateEndpoints} from "@/data/api_entities/heat_calculate/heat_calculate_endpoints";
import Loader from "@/presentation/components/loader/Loader";
import CalcList from "@/presentation/components/heatCalculationPage/components/MKDPage/components/CalcList";
import CalcPage from "@/presentation/components/heatCalculationPage/components/MKDPage/components/CalcPage";
import {FormattedMessage} from "react-intl";
import {selectors} from "@/presentation/shared/ui/message";
import {mkdsStateSelectors} from "@/internal/lib/storeModels/models/mkds/mkdsState";

const CalculationsPage = () => {
  const [isOpenTable, toggleOpenTable] = useToggle(false)
  const [isOpenCloseSubmitModal, toggleSubmitModal, setIsOpenCloseSubmitModal] = useToggle()
  const [firstLoading, setFirstLoading] = useState(true)
  const [_, setList, resetList] = calculationsSelectors.calcList.useState()
  const setPaginationParams = calculationsSelectors.calcListPaginationParams.useSetValue()
  const lastGetListRequest = useRef(null)
  const setMessage = selectors.useSetMessage()
  const calcResult = calculationsSelectors.resultTotalValues.useValue()
  const selectedMKDId = mkdsStateSelectors.selectedMKD.useValue()

  const uploadCalcList = () => {
    setFirstLoading(true)
    lastGetListRequest.current?.abort()

    const {promise, controller} = heatCalculateEndpoints.getHistory(selectedMKDId, 1)
    lastGetListRequest.current = controller

    promise
      .then(res => {
        if (Array.isArray(res?.results)) {
          setList(res.results)
          if (res.current_page < res.total_pages) {
            setPaginationParams({
              nextPage: 2,
              totalPages: res.total_pages
            })
          }
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось получить список расчетов',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
        lastGetListRequest.current = null
        setFirstLoading(false)
      })
      .catch(err => {
        if (err.name === 'AbortError') return
        lastGetListRequest.current = null
        setFirstLoading(false)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось получить список расчетов',
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
  }

  useEffect(() => {
    uploadCalcList()

    return () => {
      if (lastGetListRequest.current) {
        lastGetListRequest.current.abort()
      }
      resetList()
    }
  }, [])

  const toggleModal = () => {
    if (isOpenTable) {
      if (calcResult) {
        toggleOpenTable()
      } else {
        setIsOpenCloseSubmitModal(true)
      }
    } else {
      toggleOpenTable()
    }
  }

  const handleAgreeSubmit = () => {
    toggleSubmitModal()
    toggleOpenTable()
  }

  return (
    <>
      <div
        className={'column calculations-page'}
      >
        <ArrowBack
          pathOnVisible={PATHS.calculations}
        />

        <div className="calculations-page__title-wrapper">
          <h2 className="title">
            Расчеты
          </h2>

          <HoveredPopover text={'Новый расчет'}>
            <button
              onClick={toggleModal}
              className={`btn btn_transparent-bg`}
            >
              +
            </button>
          </HoveredPopover>
        </div>

        {isOpenTable &&
          <TablesModal
            hideModal={toggleModal}
            isOpenCloseSubmitModal={isOpenCloseSubmitModal}
            toggleSubmitCloseModal={toggleSubmitModal}
            handleAgreeSubmitCloseModal={handleAgreeSubmit}
          >
            <Calculations
              toggleModal={toggleModal}
              uploadCalcList={uploadCalcList}
            />
          </TablesModal>
        }

        <CalcList loading={firstLoading}/>
      </div>

      <Routes>
        <Route
          path={':id/*'}
          element={<CalcPage
            uploadCalcList={uploadCalcList}
          />}
        />
      </Routes>
    </>
  )
}

export default CalculationsPage
