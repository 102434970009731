import {ContentCopy} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";
import HoveredPopover from "@/presentation/components/common/HoveredPopover";

const CopyValuesButton = (
  {
    models,
    selectors,
    setMethodName,
    useDataName
  }) => {
  const data = selectors[useDataName]()
  const saveChanges = selectors.useSaveChanges()

  const handleClickCopy = () => {
    for (const key in models) {
      models[key].selectors[setMethodName](data)
    }

    saveChanges()
  }

  return (
    <div className="tables__icon-wrapper">
      <HoveredPopover
        text={<FormattedMessage id={'analytics.applyValuesForAllObjects'}/>}
      >
        <div
          className="tables__icon"
          onClick={handleClickCopy}
        >
          <ContentCopy />
        </div>
      </HoveredPopover>
    </div>
  )
}

export default CopyValuesButton
