import {createStore, createEvent} from "effector";
import {passwordValidator} from "@/internal/validators/validationsRules";
import {initialPasswordState} from "./const";
import {useEvent, useStore} from "effector-react";

export default class PasswordModel {
  constructor() {
    // events
    this.validatePassword = createEvent()
    this.resetPasswordTrigger = createEvent()

    // store
    this.$passwordValidations = createStore(initialPasswordState)

    this.$passwordValidations
      .on(this.validatePassword, (s, value) => {
        return passwordValidator(value)
      })
      .reset(this.resetPasswordTrigger)
  }

  createSelectors () {
    return {
      usePasswordValidations: () => useStore(this.$passwordValidations),
      useValidatePassword: () => useEvent(this.validatePassword),
      useResetValidations: () => useEvent(this.resetPasswordTrigger)
    }
  }
}