import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import React from "react";
import ObjectsSelect from "../selects/ObjectsSelect";

const ObjectInput = (
  {
    label,
    formSelectors,
    name
  }) => {
  const addresses = addressesSelectors.addresses.useValue()
  const value = formSelectors.useFormDataValue(name)
  const setValue = formSelectors.useSetFormDataValue(name)

  const addressOption = addresses.find(address => address.id === value)

  const onChange = (e) => {
    setValue(e.id)
  }

  return (
    <div className="form-group">
      <label>{label}</label>

      <ObjectsSelect
        addresses={addresses}
        name={name}
        value={addressOption}
        onChange={onChange}
      />
    </div>
  )
}

export default ObjectInput
