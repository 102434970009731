import {FormattedMessage} from "react-intl";
import React from "react";

export const UNIT_TYPES = [
  {
    value: 1,
    label: <FormattedMessage id={'app.gcal'}/>
  },
  {
    value: 2,
    label: <FormattedMessage id={'app.gj'}/>
  },
  {
    value: 3,
    label: 'кВт'
  },
]

export const TARIFF_TYPES = [
  {
    value: 1,
    label: '₽/Гкал'
  },
  {
    value: 2,
    label: '₽/ГДж'
  },
  {
    value: 3,
    label: '₽/кВт'
  },
]