import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'analytics'
const translations = {
  analytics: 'Аналитика',
  noAnalytics: 'Пока нет сохраненных отчетов. Вы можете создать новый нажав на +',
  heatConsumption: 'Теплопотребление',
  created: 'создан ',
  hide: 'скрыть',
  andMore: 'и ещё {objectsCount, plural, one {{objectsCount} объект} few {{objectsCount} объекта} other {{objectsCount} объектов}}',
  period: 'Период',
  step: 'Шаг',
  month: 'Месяц',
  accountingObjects: 'Объекты учета',
  report: 'Отчет',
  edit: 'Редактировать',
  createReport: 'Создать отчет',
  'deleteReport?': 'Удалить отчет?',
  byTemplate: 'по шаблону',
  byContinue: 'продолжением',
  objectsCount: '{count} объектов',
  intakeEfficiency: 'Эффективность потребления',
  economicAnalysis: 'Платежи',
  economicEfficiency: 'Экономическая эффективность',
  returnOnInvestments: 'Возврат инвестиций',
  pivotTable: 'Общая информация по объектам',
  analysisSettings: 'Настройка анализа',
  metersData: 'Показания счетчиков',
  durationDays: 'Количество дней с отоплением',
  temperature: 'Температура',
  objects: 'Объекты',
  intake: 'Потребление',
  totalPeriod: 'Всего за\xa0период',
  temperatureWithSign: 'Температура, ℃',
  investmentsWithCur: 'Инвестировано, руб',
  unsavedReport: 'Несохраненный отчет',
  'leavePage?': 'Вы уверены что хотите покинуть страницу?',
  loseData: 'Все данные несохраненного отчета будут утеряны.',
  continueReportName: 'Продолжение отчета "{name}"',
  intakeEfficiencyAnalysis: 'Анализ эффективности теплопотребления',
  analysis: 'Анализ',
  temperatureData: 'Данные о температуре',
  noAccessToService: 'В данный момент нет доступа к сервису с данными о температуре.',
  manuallyTemperature: 'Для проведения расчетов, пожалуйста, введите недостающие данные вручную.',
  investments: 'Инвестиции',
  newReport: 'Новый отчет',
  name: 'Название',
  chooseObjects: 'Выберите объекты учета',
  analysisPeriod: 'Период анализа',
  analysisStep: 'Шаг анализа',
  from: 'С',
  to: 'По',
  periodDisplay: 'Отображение периода',
  calendarYear: 'Январь - Декабрь',
  heatYear: 'Июль - Июнь',
  applyValuesForAllObjects: 'Применить значения для всех объектов',
  temperatureBy: 'Температура с ресура Weatherbit',
  manualTemp: 'Пользовательские данные',
  synchronize: 'Синхронизировать',
  tempSync: 'синхронизация температуры',
  tempSyncWarning: 'Синхронизация температуры с ресуросом Weatherbit. Возможна потеря данных введенных вручную!',
  reportName: 'Название отчета',
  bestIntakeAverages: 'Лучшие значения удельного потребления',
  byObjects: 'По объектам пользователя:',
  bySystem: 'По системе:',
  byRegion: 'По региону:',
  objectType: 'Тип объекта',
  tariff: 'Тариф, руб',
  payments: 'Платежи за отопление, руб',
  totalOfPeriod: 'Итого за\xa0период',
  specificConsumption: 'Удельное потребление, {unit}/м<sup>2</sup>*С° в сутки',
  overpayments: 'Переплата за отопление, руб',
  overpaymentsPercent: 'Переплата в % от общего платежа',
  totalOverpayment: 'Общий платеж за период',
  investmentReturn: 'Возврат инвестиций, руб',
  averageSpecificConsumption: 'Среднее удельное потребление, Гкал/м<sup>2</sup>*С° в сутки',
  totalCurrency: 'Итого за\xa0период, руб/%',
  tariffByUnit: 'Тариф, руб',
  Units: 'Единицы измерения',
  totalPayments: 'Платежи за\xa0период, руб',
  totalOverpayments: 'Переплата за\xa0период, руб',
  totalOverpaymentsPercent: 'Переплата за\xa0период, %',
  bestValue: 'Лучший показатель потребления, {unit}/м<sup>2</sup>*℃ в\xa0сутки',
  averageValue: 'Среднее потребление за\xa0период, {unit}/м<sup>2</sup>*℃ в\xa0сутки',
  downloadXLS: 'Скачать XLS',
  bestAverageValue: 'Лучший средний показатель по всем объектам',
  worseAverageValue: 'Худший средний показатель по всем объектам',
  acceptBuyReport: 'Услуга платная, отчет будет стоить {cost}. Подтверждаете?',
  freeReport: 'Отчет с данными объекатми за выбранный период уже есть в системе. Сохранение будет бесплатным.',
  reportCost: 'Услуга платная, отчет будет стоить {cost}.',
  noCash: 'Недостаточно средств для сохранения отчета, пополните баланс на {sum}',
  sendingReport: 'отправка отчета',
  saveReport: 'Сохранить отчет',
  bestValueInObject: 'Лучший показатель по объекту',
  bestValueInObjects: 'Лучший показатель по всем объектам',
  aboveAverageValue: 'Выше среднего по объекту',
  worseValueInObject: 'Худший показатель по объекту',
  worseValueInObjects: 'Худший показатель по всем объектам',
  belowAverageValue: 'Ниже среднего по объекту',
  savingReport: 'сохранение отчета',
  objectSq: 'Площадь объекта, м<sup>2</sup>',
  editObject: 'Редактировать объект',
  editingObject: 'Редактирование объекта',
  baseValue: 'Базовое значение',
  saveBeforeInvest: 'Сначала сохраните отчет'
}

export const analyticsRu = createTranslationsWithSpaceName(translations, spaceName)
