import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'navBar'
const translations = {
  consumption: 'Потребление',
  equipment: 'Оборудование',
  reports: 'Отчеты',
  events: 'События',
  analytic: 'Аналитика',
  meteringPoints: 'Точки учета',
  accountingObjects: 'Объекты учета',
  balance: 'Баланс - {balance, number, ::currency/RUB}',
  main: 'Главная'
}

export const navBarRu = createTranslationsWithSpaceName(translations, spaceName)
