import {TableCell, TableRow} from "@mui/material";
import {useMemo, useState} from "react";
import DurationsTableCell from "./DurationsTableCell";
import CopyValuesButton from "../CopyValuesButton";
import SumTableCell from "../SumTableCell";
import EditAddressButton from "@/presentation/components/analytics/сomponents/EditAddressButton";

const DurationsTableRow = (
  {
    address,
    columns,
    modelInfo,
    columnWidth,
    models,
  }) => {
  const [activeCell, setActiveCell] = useState(null)
  const [isVisible, setIsVisible] = useState(true)

  const dateCells = useMemo(() => {
    return columns.map((column, i) => {
      const id = `${address.id}:${column.value}:${i}`

      return (
        <DurationsTableCell
          key={id}
          id={id}
          valueKey={column.value}
          activeCell={activeCell}
          columnWidth={columnWidth}
          modelInfo={modelInfo}
          setActiveCell={setActiveCell}
        />
      )
    })
  }, [modelInfo, activeCell])

  return (
    <TableRow>
      <TableCell
        sx={{
          fontWeight: '500 !important',
          width: columnWidth,
          textAlign: 'left !important',
        }}
      >
        <div className="tables__address-cell">
          <span>{address.name}</span>

          <div className="tables__address-btns">
            <EditAddressButton
              addressId={address.id}
            />

            <CopyValuesButton
              models={models}
              selectors={modelInfo.selectors}
              useDataName={'useDurations'}
              setMethodName={'setDurations'}
            />
          </div>
        </div>
      </TableCell>

      {dateCells}

      <SumTableCell
        selectors={modelInfo.selectors}
        columnWidth={columnWidth}
        useSumSpaceName={'useDurationsSum'}
      />
    </TableRow>
  )
}

export default DurationsTableRow
