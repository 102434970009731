import {useParams, Routes, Route} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {devicesEndpoint} from "@/data/api_entities/devices/devices_endpoint";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import Loader from "@/presentation/components/loader/Loader";
import {getDeviceInfoByType} from "@/internal/helpers/helpers";
import {BATTERY_IMGS, CONNECT_IMGS} from "../const";
import LinkTab from "@/presentation/components/common/LinkTab";
import {
  DEVICE_CONNECTION_SYSTEM_PATH,
  DEVICE_INFO_TABS, DEVICE_PATH,
  DEVICE_PULSE_ONE_PATH,
  DEVICE_PULSE_TWO_PATH,
  DEVICE_SETTINGS_PATH, DEVICE_SIM_SETTINGS_PATH, DEVICE_TEMP_PATH, DEVICE_TRANSFER_DEVICES_PATH
} from "./const";
import DeviceSettings from "./DeviceSettings";
import DeviceScoreboard from "./DeviceScoreboard";
import DeviceActivityLog from "./DeviceActivityLog";
import DeviceConnectionSystem from "./DeviceConnectionSystem";
import {RESOURCES_TYPES} from "@/internal/content/consts";
import DevicePulseInput from "./DevicePulseInput";
import DeviceTempSensor from "./DeviceTempSensor";
import DeviceSimSettings from "./DeviceSimSettings";
import DeviceSlaves from "./DeviceSlaves";
import {devicePageSelectors} from "@/internal/lib/storeModels/models/devices/devicePageModels";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import {FormattedDate, FormattedMessage} from "react-intl";
import TransferDevices from "@/presentation/components/devicesPage/components/TransferDevices";
import {responseHandler} from "@/internal/lib/transport/response_handler";
import {selectors, typeMessage} from "@/presentation/shared/ui/message";

const DeviceInfo = ({onAddNewDevice}) => {
  const {id} = useParams()

  const devices = devicePageSelectors.devicesList.useValue()
  const setMessage = selectors.useSetMessage()
  const [lastGetDeviceInfoRequest, setLastGetDeviceInfoRequest] = useState(null)
  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const [deviceInfo, setDeviceInfo] = useState(null)

  const [isOpenScoreboard, toggleScoreboard] = useToggle()
  const [isOpenActivity, toggleActivity] = useToggle()

  useEffect(() => {
    return () => {
      if (lastGetDeviceInfoRequest) {
        lastGetDeviceInfoRequest.abort()
      }
    }
  }, [])

  const getDeviceInfo = () => {
    if (!id) return

    if (lastGetDeviceInfoRequest) {
      lastGetDeviceInfoRequest.abort()
    }

    const {promise, controller} = devicesEndpoint.getDeviceInfo(id)

    setLastGetDeviceInfoRequest(controller)

    promise
      .then(res => {
        responseHandler(res, {
          ok: {
            callback: () => {
              setDeviceInfo(res?.data)
              setLastGetDeviceInfoRequest(null)
            }
          },
          error: {
            message: <FormattedMessage id={'requests.getDeviceInfoError'}/>
          }
        })
      })
      .catch(err => {
        if (err.name === 'AbortError') return

        setMessage({
          type: typeMessage.error,
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.getDeviceInfoError'}/>,
          count: 3000,
        })
        setLastGetDeviceInfoRequest(null)
        console.log(err)
      })
      .finally(() => {
        if (isFirstLoading) setIsFirstLoading(false)
      })
  }

  useEffect(() => {
    getDeviceInfo()
  }, [id, devices])

  const tabs = useMemo(() => {
    if (!deviceInfo) return null

    return DEVICE_INFO_TABS
      .filter(tab => !tab.types || tab.types.includes(deviceInfo.type))
      .map(tab => {
        let subText = ''

        switch (tab.id) {
          case 2:
            subText = deviceInfo.next_sync_date
              ? (
                <FormattedMessage
                  id={'devicesPage.willConnect'}
                  values={{
                    date: (
                      <FormattedDate
                        value={deviceInfo.next_sync_date}
                      />
                    )
                  }}
                />
              )
              : ''
            break
          case 3:
            subText = <FormattedMessage id={'devicesPage.notConfigured'}/>
            break
          case 4:
            subText = deviceInfo.pulse_channel_1
              ? <>
                <div>
                  <img src={RESOURCES_TYPES[deviceInfo.pulse_channel_1.type].img} alt="resource"/>
                </div>

                <span>{deviceInfo.pulse_channel_1.name}</span>
              </>
              : <FormattedMessage id={'devicesPage.notConfigured'}/>
            break
          case 5:
            subText = deviceInfo.pulse_channel_2
              ? <>
                <div>
                  <img src={RESOURCES_TYPES[deviceInfo.pulse_channel_2.type].img} alt="resource"/>
                </div>

                <span>{deviceInfo.pulse_channel_2.name}</span>
              </>
              : <FormattedMessage id={'devicesPage.notConfigured'}/>
            break
          case 6:
            subText = '+25ºC'
            break
          case 7:
            subText = deviceInfo.sim || <FormattedMessage id={'devicesPage.notConfigured'}/>
            break
          case 8:
            if (!!deviceInfo.master) {
              return null
            } else {
              break;
            }
          default:
            subText = ''
            break
        }

        return (
          <LinkTab
            key={tab.id}
            path={tab.path}
            textId={tab.textId}
            subText={subText}
          />
        )
      })
  }, [deviceInfo])

  if (!deviceInfo) return null

  return (
    <>
      <div className={'device-info'}>
        <ArrowBack
          pathOnVisible={String(id)}
        />

        <Loader loading={isFirstLoading || !!lastGetDeviceInfoRequest}>
          <h2 className="title">{getDeviceInfoByType(deviceInfo.type).name}</h2>

          <div className="device-info__about">
            <div className="device-info__img">
              <img src={getDeviceInfoByType(deviceInfo.type).img} alt="device"/>
            </div>

            <div className="device-info__status">
              <div className="device-info__battery">
                <img src={BATTERY_IMGS[deviceInfo.battery]} alt="battery"/>

                <span>
                  <FormattedMessage id={'devicesPage.net'}/>
                </span>
              </div>

              <div className="device-info__signal">
                <img src={CONNECT_IMGS[deviceInfo.signal]} alt="signal"/>

                <span>--</span>
              </div>
            </div>
          </div>

          <div className="device-info__btns">
            <button
              className="btn btn_transparent-bg"
              onClick={toggleActivity}
            >
              <FormattedMessage id={'devicesPage.activityLog'}/>
            </button>

            {deviceInfo.type !== 3 &&
              <button
                className="btn btn_transparent-bg"
                onClick={toggleScoreboard}
              >
                <FormattedMessage id={'devicesPage.scoreboard'}/>
              </button>
            }
          </div>


          <div className="device-info__tabs">
            {tabs}
          </div>
        </Loader>
      </div>

      {isOpenScoreboard &&
        <DeviceScoreboard
          toggleScoreboard={toggleScoreboard}
        />
      }

      {isOpenActivity &&
        <DeviceActivityLog
          toggleActivity={toggleActivity}
        />
      }

      <Routes>
        <Route
          path={DEVICE_SETTINGS_PATH}
          element={
            <DeviceSettings
              deviceInfo={deviceInfo}
              getDeviceInfo={getDeviceInfo}
              devices={devices}
            />
          }
        />

        <Route
          path={DEVICE_CONNECTION_SYSTEM_PATH}
          element={
            <DeviceConnectionSystem
              deviceInfo={deviceInfo}
              getDeviceInfo={getDeviceInfo}
            />
          }
        />

        <Route
          path={DEVICE_PULSE_ONE_PATH}
          element={
            <DevicePulseInput
              isOne
              deviceInfo={deviceInfo}
            />
          }
        />

        <Route
          path={DEVICE_PULSE_TWO_PATH}
          element={
            <DevicePulseInput
              deviceInfo={deviceInfo}
            />
          }
        />

        <Route
          path={DEVICE_TEMP_PATH}
          element={
            <DeviceTempSensor />
          }
        />

        <Route
          path={DEVICE_SIM_SETTINGS_PATH}
          element={
            <DeviceSimSettings
              deviceInfo={deviceInfo}
              getDeviceInfo={getDeviceInfo}
            />
          }
        />

        <Route
          path={`${DEVICE_PATH}/*`}
          element={
            <DeviceSlaves
              deviceInfo={deviceInfo}
              devices={devices}
              onAddNewDevice={onAddNewDevice}
            />
          }
        />

        <Route
          path={DEVICE_TRANSFER_DEVICES_PATH}
          element={
            <TransferDevices
              slave={deviceInfo}
            />
          }
        />
      </Routes>
    </>
  )
}

export default DeviceInfo
