import React, {useEffect, useState} from "react";
import DeviceSlaveCard from "@/presentation/components/devicesPage/components/DeviceSlaveCard";
import Loader from "@/presentation/components/loader/Loader";
import {FormattedMessage} from "react-intl";
import {ImgLinkArrow} from "@/assets/svg";

const CanBeLinkedDevicesList = (
  {
    title,
    devices,
    actionName,
    isLoading = false,
    onAction
  }) => {
  const [isActive, setIsActive] = useState(false)
  const [currentDevice, setCurrentDevice] = useState(null)

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  function handleClickOutside(e) {
    const {target} = e

    if (target &&
      !target.closest('.MuiTooltip-popper') &&
      !target.closest('.device-slave-card__options')
    ) {
      setCurrentDevice(null)
    }
  }

  const onClickAction = () => {
    if (onAction) {
      onAction(currentDevice)
    }

    setCurrentDevice(null)
  }

  const onOpenOptions = (id) => {
    if (currentDevice === id) {
      setCurrentDevice(null)
    } else {
      setCurrentDevice(id)
    }
  }

  return (
    <div className={`can-be-linked ${isActive ? 'active' : ''}`}>
      <div className="can-be-linked__tab" onClick={() => setIsActive(!isActive)}>
        <h4 className={'title_h4'}>
          {title}
        </h4>

        <div className="can-be-linked__arrow">
          <img src={ImgLinkArrow} alt="arrow"/>
        </div>
      </div>

      {isActive &&
        <div className="can-be-linked__list">
          <Loader loading={isLoading}>
            {!!devices.length
              ? devices.map(device => {
                return (
                  <DeviceSlaveCard
                    key={device.id}
                    data={device}
                    cardId={`${device.id}`}
                    currentDeviceOption={currentDevice}
                    onClickOption={onOpenOptions}
                    actionName={actionName}
                    onClickAction={onClickAction}
                  />
                )
              })
              : <p className={'can-be-linked__p'}><FormattedMessage id={'devicesPage.noDevicesAvailable'}/></p>
            }
          </Loader>
        </div>
      }
    </div>
  )
}

export default CanBeLinkedDevicesList
