import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";
import {SORTED_POINTS_BY_TYPES} from "@/presentation/components/intake/const";
import {sample, createEffect} from "effector";
import {isEqual} from "lodash";
import {sampleParamsFormSelectors} from "@/internal/lib/storeModels/models/intake/forms/sampleParamsFormModel";

const resourceTypesModel = new StoreModelFabric([])
const activeTypesModel = new StoreModelFabric([])
const meteringPointsModel = new StoreModelFabric([])
const sortedMeteringPointsModel = new StoreModelFabric(SORTED_POINTS_BY_TYPES)
const isLoadingMeteringsModel = new StoreModelFabric(true)
const detailedChartNameModel = new StoreModelFabric('')

const sortMeteringPoints = createEffect(({meteringPoints, sortedMeteringPoints}) => {
  const newSort = sortedMeteringPoints.map(type => {
    const pointsArr = meteringPoints.filter(point => point.type === type.type)

    return {
      ...type,
      points: pointsArr
    }
  })

  sortedMeteringPointsModel.setState(newSort)
})

const setSelectedPointsByActiveTypes = createEffect(({selectedPoints, meteringPoints, activeTypes}) => {
  meteringPoints.forEach(point => {
    if (activeTypes.includes(point.type)) return

    const newSelectedPoints = selectedPoints.filter(pointId => pointId !== point.id)

    if (isEqual(newSelectedPoints, selectedPoints)) return

    sampleParamsFormSelectors.sampleParamsFormModel.valueStateManager['pointsIds'].setValue(newSelectedPoints)
  })
})

sample({
  clock: meteringPointsModel.$store,
  source: {
    meteringPoints: meteringPointsModel.$store,
    sortedMeteringPoints: sortedMeteringPointsModel.$store,
  },
  target: sortMeteringPoints
})

sample({
  clock: activeTypesModel.$store,
  source: {
    meteringPoints: meteringPointsModel.$store,
    activeTypes: activeTypesModel.$store,
    selectedPoints: sampleParamsFormSelectors.sampleParamsFormModel.valueStateManager['pointsIds'].$store
  },
  target: setSelectedPointsByActiveTypes
})

export const intakeSelectors = {
  resourceTypes: resourceTypesModel.createSelectors(),
  activeTypes: activeTypesModel.createSelectors(),
  meteringPoints: meteringPointsModel.createSelectors(),
  sortedMeteringPoints: sortedMeteringPointsModel.createSelectors(),
  isLoadingMeterings: isLoadingMeteringsModel.createSelectors(),
  detailedChartName: detailedChartNameModel.createSelectors()
}
