import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";
import {INIT_USER_DATA} from "./const";
import {userEndpoint} from "@/data/api_entities/user/user_endpoint";
import {setMessage} from "@/presentation/shared/ui/message";
import {useEvent, useStore} from "effector-react";
import {createEffect, createEvent, sample} from "effector";

const userDataModel = new StoreModelFabric(INIT_USER_DATA)
const loadingUserDataModel = new StoreModelFabric(true)
const isLowPermissionsModel = new StoreModelFabric(false)
const updateUserData = createEvent()

userDataModel.getData = createEffect(() => {
  loadingUserDataModel.setState(true)

  userEndpoint.getUserData()
    .then(res => {
      userDataModel.setState(res)
    })
    .catch(err => {
      setMessage({
        header: 'Ошибка!',
        message: 'Не удалось загрузить данные о пользователе',
        type: 'error',
        count: 3000
      })
      console.log(err)
    })
    .finally(() => loadingUserDataModel.setState(false))
})

sample({
  clock: updateUserData,
  target: userDataModel.getData
})

sample({
  clock: userDataModel.setState,
  fn: (userData) => false,
  target: isLowPermissionsModel.setState
})

export const userDataSelectors = {
  userData: {
    ...userDataModel.createSelectors(),
    useGetUserData: () => useEvent(userDataModel.getData),
    useUpdateUserData: () => useEvent(updateUserData),
    getUserData: userDataModel.$store.getState,
  },
  loadingUserData: loadingUserDataModel.createSelectors(),
  isLowPermissions: isLowPermissionsModel.createSelectors(),
  useIsLowPermissions: () => useStore(isLowPermissionsModel.$store),
}
