import {request} from "@/internal/lib/transport/request";
import {sendAddressAdapter} from "./address_adapters";

export const addressEndpoint = {
  getAllAddresses () {
    const params = {
      url: 'api/addresses/',
      method: 'GET'
    }

    return request(params)
  },

  createNewAddress (data) {
    const params = {
      url: 'api/addresses/',
      method: 'POST',
      data: sendAddressAdapter({...data})
    }

    return request(params)
  },

  getAddressById (id) {
    const params = {
      url: `api/addresses/${id}`,
      method: 'GET',
    }

    return request(params)
  },

  getMeterointsByAddressId (id) {
    const params = {
      url: `api/addresses/${id}/meteroint/`,
      method: 'GET'
    }

    return request(params)
  },

  changeAddressInfo (data) {
    const params = {
      url: `api/addresses/${data.id}/`,
      method: 'PUT',
      data: sendAddressAdapter(data)
    }

    return request(params)
  },

  deleteAddress (id) {
    const params = {
      url: `api/addresses/${id}/`,
      method: 'DELETE',
    }

    return request(params).promise
  },

  getDevices (id) {
    const params = {
      url: `api/addresses/${id}/device/`,
      method: 'GET'
    }

    return request(params)
  },

  getUnattachedEndDevices ({addressId, deviceGroupId}) {
    const params = {
      url: `api/addresses/unattachedend/`,
      method: 'POST',
      data: {
        address_id: addressId,
        device_group_id: deviceGroupId
      }
    }

    return request(params)
  },

  getTransferDevices ({addressId, deviceGroupId}) {
    const params = {
      url: `api/addresses/transferdevices/`,
      method: 'POST',
      data: {
        address_id: addressId,
        device_group_id: deviceGroupId
      }
    }

    return request(params)
  }
}