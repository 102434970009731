import {
  Battery,
  BatteryGrey,
  BatteryRed, BatteryYellow,
  ColdWater, ConnectionGreen, ConnectionGrey, ConnectionRed, ConnectionYellow,
  ElectroEnergy,
  Gas,
  GreenFork,
  HotWater,
  Ventilation,
  Warmly,
  Bridge, RD, SinhronizationNO, Xpulse, XWatterSmall
} from "@/assets/svg";
import {FormattedMessage} from "react-intl";

export const OBJECT_TYPE_OPTIONS = [
  // ...(() => {
  //   const arr = []
  //   for (let i = 0; i < 3; i++) {
  //     arr[i] = {
  //       value: i,
  //       label: <FormattedMessage id={`objectTypes.${i}`}/>
  //     }
  //   }
  //   return arr
  // })()
  {
    value: 0,
    label: <FormattedMessage id={`objectTypes.${0}`}/>
  },
  // {
  //   value: 1,
  //   label: <FormattedMessage id={`objectTypes.${1}`}/>
  // },
  {
    value: 2,
    label: <FormattedMessage id={`objectTypes.${2}`}/>
  },
  {
    value: 3,
    label: <FormattedMessage id={`objectTypes.${3}`}/>
  },
  {
    value: 7,
    label: <FormattedMessage id={`objectTypes.${7}`}/>
  },
]

export const RESOURCES_TYPES = Object.freeze(
  (() => {
    const imgs = [null, ColdWater, HotWater, Warmly, ElectroEnergy, Gas, Ventilation]
    const types = {}

    imgs.forEach((img, i) => {
      types[i] = {
        name: <FormattedMessage id={`resourcesTypes.${i}`}/>,
        img,
        nameId: `resourcesTypes.${i}`
      }
    })
    return types
  })())

export const MONTHS_LIST = [
  {
    value: 1,
    label: <FormattedMessage id={`app.january`} values={{count: 1}}/>,
  },
  {
    value: 2,
    label: <FormattedMessage id={`app.february`} values={{count: 1}}/>,
  },
  {
    value: 3,
    label: <FormattedMessage id={`app.march`} values={{count: 1}}/>,
  },
  {
    value: 4,
    label: <FormattedMessage id={`app.april`} values={{count: 1}}/>,
  },
  {
    value: 5,
    label: <FormattedMessage id={`app.mayFull`} values={{count: 1}}/>,
  },
  {
    value: 6,
    label: <FormattedMessage id={`app.juneFull`} values={{count: 1}}/>,
  },
  {
    value: 7,
    label: <FormattedMessage id={`app.julyFull`} values={{count: 1}}/>,
  },
  {
    value: 8,
    label: <FormattedMessage id={`app.august`} values={{count: 1}}/>,
  },
  {
    value: 9,
    label: <FormattedMessage id={`app.september`} values={{count: 1}}/>,
  },
  {
    value: 10,
    label: <FormattedMessage id={`app.october`} values={{count: 1}}/>,
  },
  {
    value: 11,
    label: <FormattedMessage id={`app.november`} values={{count: 1}}/>,
  },
  {
    value: 12,
    label: <FormattedMessage id={`app.december`} values={{count: 1}}/>,
  },
]

export const YEARS_LIST = [
  {
    value: 2021,
    label: '2021'
  }
]

export const DISPLAY_MODE_LIST = [
  {
    value: 1,
    label: <FormattedMessage id={`intake.byDays`} />
  },
  {
    value: 2,
    label: <FormattedMessage id={`intake.byMonths`} />
  },
  {
    value: 3,
    label: <FormattedMessage id={`intake.byYears`} />
  },
]

export const MODE_LIST = [
  {
    value: 0,
    label: <FormattedMessage id={`intake.fact`} />
  },
  {
    value: 4,
    label: <FormattedMessage id={`intake.comparison`} />
  }
]

export const RESOURCES_COLORS = Object.freeze({
  1: {
    color: '#08C3ED'
  },
  2: {
    color: '#FA7C83'
  },
  3: {
    color: '#F99970'
  },
  4: {
    color: '#FFC773'
  },
  5: {
    color: '#209FF9'
  },
  6: {
    color: '#AF8AFF'
  },
})

export const CHART_TYPES_LIST = [
  {
    value: 'column',
    label: 'Сolumn'
  },
  {
    value: 'spline',
    label: 'Spline',
  },
  {
    value: 'area',
    label: 'Area'
  },
  {
    value: 'areaspline',
    label: 'Areaspline'
  },
  {
    value: 'scatter',
    label: 'Scatter'
  }
]

export const AVERAGE_LINES_COLOR = Object.freeze({
  1: {
    color: '#A6D4FF'
  },
  2: {
    color: '#ffa6b0'
  }
})

const MONTHS_NAMES = {
  1: 'app.january',
  2: 'app.february',
  3: 'app.march',
  4: 'app.april',
  5: 'app.mayFull',
  6: 'app.juneFull',
  7: 'app.julyFull',
  8: 'app.august',
  9: 'app.september',
  10: 'app.october',
  11: 'app.november',
  12: 'app.december'
}

export const SHORT_MONTH_NAME = ({
  1: 'app.jan',
  2: 'app.feb',
  3: 'app.mar',
  4: 'app.apr',
  5: 'app.may',
  6: 'app.june',
  7: 'app.july',
  8: 'app.aug',
  9: 'app.sept',
  10: 'app.oct',
  11: 'app.nov',
  12: 'app.dec',
})

export const getShortMontName = (intl, value) => {
  return intl.formatMessage({id: SHORT_MONTH_NAME[value]})
}

export const getFullMontNameByShort = (intl, shortName) => {
  return getMonthNameByDate(intl, (Object.keys(SHORT_MONTH_NAME)
    .findIndex(value => shortName == getShortMontName(intl, value)) + 1)) ?? ''
}

export const getMonthNameByDate = (intl, value, count = 1) => {
  return intl.formatMessage(
    {
      id: MONTHS_NAMES[value]
    },
    {
      count
    }
  )
}

export const DEVICES_TYPES = {
  0: {
    name: "Неизвестное устройство",
    img: SinhronizationNO,
    monthCoast: 18,
  },
  1: {
    name: "Неизвестное устройство",
    img: SinhronizationNO,
    monthCoast: 18,
  },
  2: {
    name: "Неизвестное устройство",
    img: SinhronizationNO,
    monthCoast: 18,
  },
  3: {
    name: 'МОСТиК',
    img: Bridge,
    monthCoast: 18,
  },
  4: {
    name: "Неизвестное устройство",
    img: SinhronizationNO,
    monthCoast: 18,
  },
  5: {
    name: "Неизвестное устройство",
    img: SinhronizationNO,
  },
  6: {
    name: "Неизвестное устройство",
    img: SinhronizationNO,
    monthCoast: 18,
  },
  7: {
    name: "Неизвестное устройство",
    img: SinhronizationNO,
    monthCoast: 18,
  },
  8: {
    name: 'ЭР-Д',
    img: RD,
    monthCoast: 18,
  },
  9: {
    name: 'X-Watter',
    img: XWatterSmall,
  },
  10: {
    name: "Неизвестное устройство",
    img: SinhronizationNO,
    monthCoast: 18,
  },
  11: {
    name: "Неизвестное устройство",
    img: SinhronizationNO,
    monthCoast: 18,
  },
  12: {
    name: 'Домовой (Х-Пульс)',
    img: Xpulse,
    monthCoast: 18,
  },
  13: {
    name: 'X-Pulse NB-IoT',
    img: Xpulse,
    monthCoast: 18,
  },
  14: {
    name: 'КОМБиК',
    img: RD,
    monthCoast: 18
  },
  15: {
    name: 'МОСТ',
    img: Bridge,
    monthCoast: 18
  },
  110: {
    name: 'X-Pulse NB-IoT',
    img: Xpulse,
    monthCoast: 18,
  }
}

