import {DEVICE_REPORT_TYPE, METERING_POINT_REPORT_TYPE, RESOURCE_REPORT_TYPE} from "../const";
import SelectComponent from "@/presentation/components/common/selects/Select";
import React, {useEffect} from "react";
import SelectInput from "@/presentation/components/common/formComponents/SelectInput";
import {RESOURCES_LIST} from "@/presentation/components/meteringPointForm/const";
import {RESOURCES_TYPES} from "@/internal/content/consts";
import {REPORT_BY_DEVICE, REPORT_BY_METERING_POINT, REPORT_BY_RESOURCE} from "./const";
import {FormattedMessage, useIntl} from "react-intl";

const ReportSubjectTypeSelect = (
  {
    formSelectors,
    devicesList,
    meteringPoints
  }) => {
  const intl = useIntl()

  const reportName = formSelectors.useFormDataValue('name')
  const reportType = formSelectors.useFormDataValue('type')
  const resourceType = formSelectors.useFormDataValue('resource_type')
  const deviceId = formSelectors.useFormDataValue('deviceId')
  const pointId = formSelectors.useFormDataValue('pointId')
  const setFormDataValue = (name) => formSelectors.setFormDataValue(name)
  
  const selectedDevice = devicesList.find(device => device.value.id === deviceId) ?? null
  const meteringPointSelectList = meteringPoints.map(point => {
    return {
      value: point.id,
      label: <>
        <div>
          {RESOURCES_TYPES[point.type].img
            ?  <img src={RESOURCES_TYPES[point.type].img} alt={RESOURCES_TYPES[point.type].name}/>
            : null }
        </div>

        <span>{point.name}</span>
      </>,
    }
  })
  const selectedMeteringPoint = meteringPointSelectList.find(point => point.value === pointId) ?? null

  useEffect(() => {
    if (!selectedMeteringPoint) return

    const currentPoint = meteringPoints.find(point => point.id === selectedMeteringPoint.value)

    setFormDataValue('gizmo_name')(currentPoint.gizmo_name)
    setFormDataValue('device_name')(currentPoint.device_name)
  }, [selectedMeteringPoint])

  useEffect(() => {
    if (reportName) return

    switch (reportType) {
      case DEVICE_REPORT_TYPE:
        if (!selectedDevice) return

        setFormDataValue('name')(`${intl.formatMessage({id: 'reportsPage.reportByDevice'})} ${selectedDevice.label}`)
        break
      case RESOURCE_REPORT_TYPE:
        if (!resourceType) return

        setFormDataValue('name')(`${intl.formatMessage({id: 'reportsPage.reportByResource'})} - ${RESOURCES_TYPES[resourceType].name}`)
        break
      case METERING_POINT_REPORT_TYPE:
        if (!pointId || !meteringPoints.length) return

        const currentMeteringPoint = meteringPoints.find(point => point.id === pointId)

        setFormDataValue('name')(`${intl.formatMessage({id: 'reportsPage.reportByMeteringPoint'})} - ${currentMeteringPoint.name}`)
        break
    }
  }, [resourceType, selectedDevice, pointId, meteringPoints])

  const onChangeDeviceInput = (e) => {
    const {value} = e

    setFormDataValue('deviceId')(value.id)
    setFormDataValue('deviceType')(value.type)
    setFormDataValue('deviceSerial')(value.serial)
  }

  return (
    <>
      {reportType === DEVICE_REPORT_TYPE &&
        <div className="form-group">
          <label>
            <FormattedMessage id={'reportsPage.device'}/>
          </label>

          <SelectComponent
            optionsList={devicesList}
            placeholder={<FormattedMessage id={'reportsPage.selectDevice'}/>}
            handleChange={onChangeDeviceInput}
            value={selectedDevice}
          />
        </div>
      }

      {reportType === RESOURCE_REPORT_TYPE &&
        <SelectInput
          label={<FormattedMessage id={'reportsPage.resourceType'}/>}
          formSelectors={formSelectors}
          name={'resource_type'}
          options={RESOURCES_LIST}
        />
      }

      {reportType === METERING_POINT_REPORT_TYPE &&
        <SelectInput
          label={<FormattedMessage id={'reportsPage.meteringPoint'}/>}
          formSelectors={formSelectors}
          name={'pointId'}
          options={meteringPointSelectList}
          placeholder={<FormattedMessage id={'reportsPage.selectPoint'}/>}
        />
      }
    </>
  )
}

export default ReportSubjectTypeSelect

