import {Box, Step, StepLabel, Stepper} from "@mui/material";
import {QontoConnector, QontoStepIcon} from "./Qonto";
import {INVEST_STEPS, STEPPER_STEPS} from "./const";
import {activeInvestStepSelectors, tableStepsSelectors} from "@/internal/lib/storeModels/models/analytics/tableStepsModels";
import {useEffect, useLayoutEffect} from "react";
import {
  analyticsSelectors,
} from "@/internal/lib/storeModels/models/analytics/analyticsModels";
import {preconfigFormSelectors} from "@/internal/lib/storeModels/models/analytics/forms/preconfigFormModel";
import {FormattedMessage} from "react-intl";
import HoveredPopover from "@/presentation/components/common/HoveredPopover";

const TableSteps = (
  {
    editAnalyticId,
    startResultData,
    loading,
    getAnalyticTemp,
    onlyResult
  }) => {
  const [activeStep, setActiveStep, resetActiveStep] = tableStepsSelectors.useState()
  const [activeInvestStep, setActiveInvestStep, resetActiveInvestStep] = activeInvestStepSelectors.useState()
  const setPreconfigDataValue = (name) => preconfigFormSelectors.setFormDataValue(name)
  const isValidForm = preconfigFormSelectors.useIsFormValid()
  const onClickSubmit = preconfigFormSelectors.useOnClickSubmit()
  const lastCreateAnalyticRequest = analyticsSelectors.lastCreateAnalyticRequest.useValue()
  const analyticId = analyticsSelectors.analyticId.useValue()
  const isPaid = analyticsSelectors.isPaidAnalytic.useValue()
  const [isInvestStepCompleted, setIsInvestStepCompleted, resetIsInvestStepCompleted] = analyticsSelectors.isInvestStepCompleted.useState()
  const [isInvestResultStepCompleted, _, resetIsInvestResultStepCompleted] = analyticsSelectors.isInvestResultStepCompleted.useState()

  useEffect(() => {
    return () => {
      resetActiveStep()
      resetActiveInvestStep()
      resetIsInvestStepCompleted()
      resetIsInvestResultStepCompleted()
    }
  }, [])

  useLayoutEffect(() => {
    if (!!startResultData) {
      setActiveStep(4)
    } else if (editAnalyticId) {
      setActiveStep(1)
    } else {
      setActiveStep(0)
    }
  }, [startResultData, editAnalyticId])

  const handleStep = (index) => {
    if (lastCreateAnalyticRequest ||
      ((onlyResult || isPaid) && index !== 4) ||
      (index === 4 && activeStep === 0) ||
      (index === 3 && activeStep === 0) ||
      index === activeStep ||
      loading) return

    if (editAnalyticId && index === 0) return

    if (activeStep === 0) {
      setPreconfigDataValue('toStep')(index)

      if (!isValidForm) return

      onClickSubmit()
    } else if (index === 3) {
      getAnalyticTemp(analyticId, false)
    } else if (index === 4) {
      if (onlyResult || isPaid) {
        setActiveInvestStep(-1)
        setActiveStep(index)
      } else {
        getAnalyticTemp(analyticId, true)
      }
    } else {
      setActiveInvestStep(-1)
      setActiveStep(index)
    }
  }

  const handleInvestStep = (index, isDisabled) => {
    if (isDisabled || loading) return

    if (index === 1) {
      setIsInvestStepCompleted(true)
    }

    if (index === 1 && !onlyResult) {
      getAnalyticTemp(analyticId, true, true)
    } else {
      setActiveStep(-1)
      setActiveInvestStep(index)
    }
  }

  return (
    <div className={'tables__steps'}>
      <Box sx={{
        width: '100%',
        maxWidth: '1080px',
        minWidth: '800px'
      }}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {STEPPER_STEPS.map((step) => {
            const isDisabled = !!((onlyResult && step.index !== 4) ||
              (isPaid && step.index < 4)) ||
              (step.index === 4 && activeStep === 0) ||
              (step.index === 0 && editAnalyticId)

            const isCompleted = activeInvestStep >= 0 ||
              activeStep > step.index ||
              onlyResult ||
              isPaid

            return (
              <Step
                key={step.index}
                completed={!!isCompleted}
                disabled={isDisabled}
              >
                <StepLabel
                  className={`stepper-step`}
                  StepIconComponent={QontoStepIcon}
                  onClick={() => handleStep(step.index)}
                >
                  {step.name}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </Box>

      <HoveredPopover
        disabled={isPaid || onlyResult || !!editAnalyticId}
        text={'Сначала сохраните отчет'}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '430px',
            minWidth: '300px',
            opacity: isPaid || onlyResult || !!editAnalyticId
              ? 1
              : 0.3,
          }}
        >
          <Stepper
            activeStep={activeInvestStep}
            alternativeLabel
            connector={<QontoConnector />}
          >
            {
              INVEST_STEPS.map(step => {
                const isDisabled = (!isPaid && !onlyResult && !editAnalyticId) || (onlyResult && step.index === 0)
                const isCompleted = onlyResult ||
                  (step.index === 0 && isInvestStepCompleted) ||
                  (step.index === 1 && isInvestResultStepCompleted)

                return (
                  <Step
                    key={step.index}
                    disabled={isDisabled}
                    completed={!!isCompleted}
                  >
                    <StepLabel
                      className={`stepper-step`}
                      StepIconComponent={QontoStepIcon}
                      onClick={() => handleInvestStep(step.index, isDisabled)}
                    >
                      {step.name}
                    </StepLabel>
                  </Step>
                )
              })
            }
          </Stepper>
        </Box>
      </HoveredPopover>
    </div>
  )
}

export default TableSteps
