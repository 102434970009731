import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts"
import {getFullMontNameByShort, getMonthNameByDate} from "@/internal/content/consts";
import {useIntl} from "react-intl";

const MainChart = ({chartType, eRender, displayMode, data, xAxis, month}) => {
  const intl = useIntl()

  const options = {
    chart: {
      type: chartType,
      height: '50%',
      showAxes: false,
      spacing: [37, 0, 0, 0],
      events: {
        render: eRender,
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: ''
    },
    series: data,
    tooltip: {
      backgroundColor: 'rgba(0,0,0,0)',
      shadow: false,
      borderWidth: 0,
      useHTML: true,
      shared: true,
      hideDelay: 100,
      formatter: function() {
        const pointsValues = chartType === 'scatter'
          ? this.series
            ? `<span>${this.series.name}: ${Math.round(this.point.y * 100)/100} ${intl.formatMessage({id: 'intake.m'})}<sup>3</sup></span>`
            : `<span>${this.points[0].series.name}: ${Math.round(this.y * 100)/100} ${intl.formatMessage({id: 'intake.m'})}<sup>3</sup></span>`
          : this.points.map(point => {
            return `
                    <span>${point.series.name}: ${Math.round(point.point.y * 100)/100} ${intl.formatMessage({id: 'intake.m'})}<sup>3</sup></span>
                  `
          })

        let tooltipTitle = ''

        switch (displayMode) {
          case 1:
            tooltipTitle = `<span>${this.x} ${getMonthNameByDate(intl, month, 2)}</span>`
            break
          case 2:
            tooltipTitle = `<span>${getFullMontNameByShort(intl, this.x)}</span>`
            break
          case 3:
            tooltipTitle = `<span>${this.x}</span>`
          default:
            break
        }

        const tooltip = `
                <div class="chart-tooltip">
                  ${tooltipTitle}
                  ${Array.isArray(pointsValues) ? pointsValues.join('') : pointsValues}
                </div>
              `
        return tooltip
      },
      valueDecimals: 2
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
      },
    },
    xAxis: {
      categories: xAxis,
      crosshair: true,
      gridLineColor: '#fff',
      labels: {
        style: {
          fontSize: '.75rem',
          color: '#727A86',
        },
        y: 20,
        step: displayMode === 1 ? 2 : 0
      },
      lineColor: 'none',
      width: displayMode === 3
        ? data.length
          ? `${data[0].data.length*2/31 * 100}%`
          : '100%'
        : '100%'
    },
    yAxis: {
      crosshair: true,
      gridLineColor: '#fff',
      title: {
        enabled: false
      },
      floor: 0,
      labels: {
        style: {
          fontSize: '.75rem',
          color: '#727A86'
        },
        formatter: function () {
          return Math.round(this.value * 100)/100
        },
        step: 1,
        x: -7,
        y: -2,
        distance: 0
      },
    },
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}

export default MainChart
