import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const translations = {
  shortLogin: `Der Name darf nicht kürzer als {length, plural, one {# symbol} other {# symbole}}`,
  longLogin: `Name sollte nicht überwinden {length, plural, one {# symbol} other {# symbole}}`,
  longName: 'Der Name sollte nicht erscheinen {length, plural, one {# symbol} other {# symbole}}',
  login: 'Ungültiger Benutzername',
  email: 'Falsche e-mail',
  phone: 'Ungültige Telefonnummer',
  required: 'Pflichtfeld',
  repeat: 'Passwörter stimmen nicht überein',
  minDate: 'Versuchen Sie es mit einem späteren Intervall',
  maxDate: 'Das Intervall darf das aktuelle Datum nicht überschreiten',
  notFoundEmail: 'Benutzer mit dieser e-mail nicht gefunden',
  inn: 'Falscher Wert',
  notFullRangeData: 'Wählen Sie das Ende des Intervalls aus',
  notMinus: 'Kann nicht negativ sein',
  wrongLon: 'Falscher Längengrad',
  wrongLat: 'Falscher Breitengrad',
  wrongValue: 'Falscher Wert',
  notZero: 'Kann nicht gleich 0 sein',
  incorrectSerialNumber: 'Die Seriennummer muss mit "{startSerial}" beginnen',
}

export const validationsMsgsDe = createTranslationsWithSpaceName(translations, 'validationsMsgs')