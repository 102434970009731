import PasswordRules from "@/presentation/components/common/PasswordRules";
import {
  userPasswordValidationsSelectors
} from "@/internal/lib/storeModels/models/user/userChangePasswordModel";
import {useEffect, useState} from "react";
import {userEndpoint} from "@/data/api_entities/user/user_endpoint";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {CHANGE_PASSWORD_PATH} from "../../const";
import {selectors} from "@/presentation/shared/ui/message";
import Input from "@/presentation/components/common/formComponents/Input";
import {userPasswordFormSelectors} from "@/internal/lib/storeModels/models/user/forms/userPasswordFormModel";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import {FormattedMessage} from "react-intl";
import Loader from "@/presentation/components/loader/Loader";

const ChangePassword = () => {
  const isValidForm = userPasswordFormSelectors.useIsFormValid()
  const resetForm = userPasswordFormSelectors.useResetForm()
  const onClickSubmit = userPasswordFormSelectors.useOnClickSubmit()
  const setOnValidSubmit = userPasswordFormSelectors.useSetOnValidSubmit()
  const passwordValidations = userPasswordValidationsSelectors.usePasswordValidations()
  const resetPasswordValidations = userPasswordValidationsSelectors.useResetValidations()
  const validatePassword = userPasswordValidationsSelectors.useValidatePassword()
  const userData = userDataSelectors.userData.useValue()
  const setMessage = selectors.useSetMessage()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setOnValidSubmit(onValidSubmit)

    return () => {
      resetForm()
      resetPasswordValidations()
    }
  }, [])

  const onSubmit = () => {
    if (!isValidForm || !passwordValidations.isValid) return

    onClickSubmit()
  }

  const onValidSubmit = (data) => {
    setIsLoading(true)

    userEndpoint.setUserData({
      new_password: data.new_password,
      login: userData.login
    })
      .then(() => {
        setMessage({
          header: <FormattedMessage id={'requests.changePasswordSuccessHeader'}/>,
          message: <FormattedMessage id={'requests.changePasswordSuccess'}/>,
          count: 3000
        })
        resetForm()
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.changePasswordError'}/>,
          count: 3000,
          type: 'error'
        })
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <div className="user-page__column user-page__column_password">
      <ArrowBack
        pathOnVisible={CHANGE_PASSWORD_PATH}
      />

      <h2 className="title">
        <FormattedMessage id={'userPage.changePassword'}/>
      </h2>

      <Loader loading={isLoading}>
        <div className="form-fields">
          <Input
            label={<FormattedMessage id={'userPage.newPassword'}/>}
            type="password"
            name="new_password"
            formSelectors={userPasswordFormSelectors}
            withChangeValue={validatePassword}
          />

          <Input
            label={<FormattedMessage id={'userPage.repeatPassword'}/>}
            type="password"
            name="repeatedPassword"
            formSelectors={userPasswordFormSelectors}
          />

          { !passwordValidations.isValid ?
            <PasswordRules passwordValidations={passwordValidations} />
            : null
          }
        </div>

        <button
          className="btn btn_transparent-bg"
          onClick={onSubmit}
          disabled={!passwordValidations.isValid || !isValidForm}
        >
          <FormattedMessage id={'app.change'}/>
        </button>
      </Loader>
    </div>
  )
}

export default ChangePassword
