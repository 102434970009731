import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'addressForm'
const translations = {
  newObject: 'Neues buchhaltungsobjekt',
  objectParams: 'Objektparameter',
  createFrom: 'Aus einem anderen objekt erstellen',
  objectType: 'Objekttyp',
  residents: 'Anzahl der einwohner',
  findAddress: 'Objekt finden',
  enterAddress: 'Adresse oder name eingeben',
  region: 'Region / Bezirk',
  city: 'Stadt / Ort',
  street: 'Straße',
  building: 'Anstalt',
  apartment: 'Wohnung/Büro',
  objectName: 'Name des Buchhaltungsobjekts',
  myHouse: 'Mein Haus',
  forAnalytic: 'Für Analytik',
  tariff: 'Tarif, {currency}/{unit}',
  area: 'Fläche qm',
  lat: 'Breite',
  lon: 'Längengrad',
  'deleteObject?': 'Buchhaltungsposition löschen?',
  savingObject: 'Speichern eines objekts',
  removeObject: 'Löschen eines objekts',
  preExistObject: 'Ein Objekt mit der angegebenen Adresse {link} bereits.',
  exists: 'existiert',
  changeToOrUse: 'Ändern sie die adresse des aktuellen objekts oder verwenden Sie ein bereits erstelltes.',
  objectWithDevices: 'In dieser einrichtung werden geräte installiert. {link} sie sie mit einem anderen objekt, um den zugriff zu behalten.',
  assign: 'Verknüpfen',
  forCompare: 'Verbrauch zu vergleichen',
  unitType: 'Maßeinheit',
  changesHistory: 'geschichte der veränderungen',
  areaChanges: 'Gebiet ändert',
  tariffsChanges: 'Tarifänderungen',
  areaValue: '{value} m<sup>2</sup>',
  tillDate: 'bis {date}'
}

export const addressFormDe = createTranslationsWithSpaceName(translations, spaceName)
