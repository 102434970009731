 import LinkTab from "@/presentation/components/common/LinkTab";
import {addressFormatter} from "@/internal/formatters/formatters";
import {ACCOUNTING_OBJECTS_PATH, ADD_OBJECT_PATH} from "../const";
import {Routes, NavLink, Route} from "react-router-dom";
import Loader from "@/presentation/components/loader/Loader";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {useMemo, useState} from "react";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import ObjectInfo from "./components/ObjectInfo";
import {userNewAddressSelectors} from "@/internal/lib/storeModels/models/user/forms/addressFormModels";
import AddressForm from "@/presentation/components/addressForm/AddressForm";
import {addressEndpoint} from "@/data/api_entities/address/address_endpoint";
import {setMessage} from "@/presentation/shared/ui/message";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import {FormattedMessage} from "react-intl";
import {useNavigateBack} from "@/internal/custom_hooks/navigateBack";

const Objects = () => {
  const [navigateBack] = useNavigateBack()

  const addresses = addressesSelectors.addresses.useValue()
  const loadingAddresses = !!addressesSelectors.lastGetAddressesRequest.useValue()
  const updateAddresses = addressesSelectors.addresses.useUpdateAddresses()
  const resetForm = userNewAddressSelectors.useResetForm()

  const [preexistingAddressInfo, setPreexistingAddressInfo] = useState(null)
  const [creatingAddress, setCreatingAddress] = useState(false)
  const [isOpenPreexistModal, togglePreexistModal] = useToggle()

  const links = useMemo(() => {
    return addresses.map(address => {
      return (
        <LinkTab
          key={address.id}
          id={address.id}
          text={address.name}
          subText={addressFormatter(address)}
          path={String(address.id)}
        />
      )
    })
  }, [addresses])


  const handleCreateAddress = (data) => {
    setCreatingAddress(true)

    addressEndpoint.createNewAddress(data).promise
      .then((res) => {
        if (res.status === 'error') {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: <FormattedMessage id={'requests.creatingObjectError'}/>,
            type: 'error',
            count: 3000
          })
        } else {
          if (res.status_code === 409) {
            setPreexistingAddressInfo(res.object)
            togglePreexistModal()
          } else {
            updateAddresses()
            resetForm()
            navigateBack()

            setMessage({
              header:  <FormattedMessage id={'requests.creatingObjectSuccessHeader'}/>,
              message: <FormattedMessage id={'requests.creatingObjectSuccess'}/>,
              count: 3000
            })
          }
        }
      })
      .catch((err) => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.creatingObjectError'}/>,
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setCreatingAddress(false))
  }

  return (
    <>
      <div className='user-page__column user-page__column_objects'>
        <ArrowBack
          pathOnVisible={ACCOUNTING_OBJECTS_PATH}
        />

        <h2 className="title">
          <FormattedMessage id={'userPage.accountingObjects'}/>

          <NavLink
            className={`btn btn_transparent-bg`}
            to={ADD_OBJECT_PATH}
          >
            +
          </NavLink>
        </h2>

        <Loader loading={loadingAddresses}>
          {links.length
            ? (
              <div className="user-page__links">
                {links}
              </div>
            )
            : (
              <p>
                <FormattedMessage id={'userPage.addAccountingObject'}/>
              </p>
            )
          }

          {/*<div className="user-page__btn-wrapper">*/}
          {/*  <NavLink*/}
          {/*    className={`btn btn_transparent-bg`}*/}
          {/*    to={ADD_OBJECT_PATH}*/}
          {/*  >*/}
          {/*    <FormattedMessage id={'userPage.addObject'}/>*/}
          {/*  </NavLink>*/}
          {/*</div>*/}
        </Loader>
      </div>

      <Routes>
        <Route
          path=':id/*'
          element={
            <ObjectInfo
              preexistingAddressInfo={preexistingAddressInfo}
              setPreexistingAddressInfo={setPreexistingAddressInfo}
            />
          }
        />

        <Route
          path={ADD_OBJECT_PATH}
          element={
            <AddressForm
              customClass='user-page__column user-page__column_add-object'
              isNewAddress
              formModelSelectors={userNewAddressSelectors}
              onValidSubmit={handleCreateAddress}
              loading={creatingAddress}
              pathOnVisible={ADD_OBJECT_PATH}
              isOpenPreexistModal={isOpenPreexistModal}
              togglePreexistModal={togglePreexistModal}
              preexistingAddressInfo={preexistingAddressInfo}
            />
          }
        />
      </Routes>
    </>
  )
}

export default Objects