import {PageReturn} from "@/assets/svg";
import {useNavigateBack} from "@/internal/custom_hooks/navigateBack";

const ArrowBack = ({pathOnVisible, withId = false, backCount =  1}) => {
  const [navigateBack, pathParts] = useNavigateBack()
  const filteredPathParts = (pathParts ?? []).filter(str => str.length)
  if (withId) {
    if (pathOnVisible !== filteredPathParts[filteredPathParts.length - 2]) return null
  } else {
    if (pathOnVisible !== filteredPathParts[filteredPathParts.length - 1]) return null
  }

  return (
    <div className='arrow-back' onClick={() => navigateBack(backCount)}>
      <img src={PageReturn} alt="PageReturn"/>
    </div>
  )
}

export default ArrowBack