import {customStyles} from "@/presentation/components/common/const";
import {MKDHelper} from "@/presentation/components/heatCalculationPage/helpers";
import React from "react";

export const CALCULATION_STEPS = [
  'Параметры расчета',
  'Показания распределителей',
  'Невалидные данные',
  'Расчет'
]

export const DEFAULT_OPTION = {
  value: 0,
  label: 'Не выбран'
}

export const MOCKS_TABLE_OBJECTS = (() => {
  const data = []

  for (let i = 0; i < 50000; i++) {
    data.push(
      {
        id: i + 1,
        kv: i + 1,
        area: Math.round(Math.random() * 100),
        enumerators: (() => {
          const enumerators = []
          const count = Math.round(Math.random() * 6 + 1)

          for (let j = 0; j < count; j++) {
            enumerators.push({
              id: (j + 1) * (100 + i),
              number: 100 + j,
              coefficient: Math.round(Math.random() * 2 * 100)/100,
              power:  Math.round(Math.random() * 10 * 1000)/1000
            })
          }
          return enumerators
        })()
      }
    )
  }

  return data
})()

export const apartmentsSort = (a, b) => {
  const getNumericPart = (kv) => {
    const matches = kv.match(/^(\d+)/);
    return matches ? parseInt(matches[1], 10) : Number.MAX_SAFE_INTEGER;
  };

  const aEnumerators = a.enumerator ?? [];
  const bEnumerators = b.enumerator ?? [];

  // Сначала сортировка по наличию Enumerators
  if (aEnumerators.length === 0 && bEnumerators.length > 0) {
    return 1;
  } else if (aEnumerators.length > 0 && bEnumerators.length === 0) {
    return -1;
  }

  // Если количество Enumerators одинаково, сортируем по kv
  const aNumericKv = getNumericPart(a.kv ?? "");
  const bNumericKv = getNumericPart(b.kv ?? "");

  if (aNumericKv !== bNumericKv) {
    return aNumericKv - bNumericKv;
  }

  // Если kv одинаковые, сохраняем исходный порядок
  return 0;
}

export const MKDS_SELECTOR_STYLES = {
  ...customStyles,
  option: () => ({
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    padding: '.75rem',
    borderBottom: '1px solid #ECF0F6',
    cursor: 'pointer',
    ':hover': {
      background: '#E6EFFE'
    },
    ':last-child': {
      borderBottom: 'none'
    },
    'span': {
      ':last-child': {
        color: '#C4C4C4',
        fontSize: '.775rem',
      },
      ':first-child': {
        color: '#36455D',
        fontSize: '.875rem',
      },
    },

  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    margin: 0,
    padding: '0.625rem 0.5625rem',
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    'span': {
      ':last-child': {
        display: 'none',
      },
      ':first-child': {
        display: 'block',
      },
    },
  }),
}

export const MKDS_SELECTOR_STYLES_ERROR = {
  ...MKDS_SELECTOR_STYLES,
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? 0 : 0,
    border: '1px solid #FC4F6E',
    borderRadius: '0.375rem',
    ':hover': {
      border: '1px solid #FC4F6E'
    },
    minHeight: 'unset',
    cursor: 'pointer',
    backgroundColor: state.isDisabled ? 'transparent' : 'transparent',
  }),
}

export const getMKDSelectLabel = (option) => {
  return option.value === 0
    ? (
      <span>{option.label}</span>
    )
    : (
      <>
        <span>
          {MKDHelper.getAddress(option.label)}
        </span>
        <span>
          {MKDHelper.getRegion(option.label)}
        </span>
      </>
    )
}