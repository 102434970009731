import AsyncSelectComponent from "../../common/AsyncSelect";
import {useState} from "react";
import {suggestEndpoint} from "@/data/api_entities/suggest/suggest_endpoint";
import {corporateFormSelectors} from "@/internal/lib/storeModels/models/authorization/forms/registrationUserModels";
import {FormattedMessage} from "react-intl";

const FindOrganizationInput = () => {
  const [noDadataOptionsMsg, setNoDadataOptionsMsg] = useState(<FormattedMessage id={'authorization.legalNameOrINN'}/>)
  const [inputValue, setInputValue] = useState('')
  const setFormValue = (name) => corporateFormSelectors.setFormDataValue(name)

  const getINNOptions = (inputValue) => {
    if (!inputValue) return

    const {promise} = suggestEndpoint.getDadataINNInfo({
      value: inputValue
    })

    const result = promise
      .then(res => {
        const options = res.data.map(item => {
          return {
            value: {...item},
            label: item.name
          }
        })

        return options
      })
      .catch(err => {
        console.log(err)
      })

    return result
  }

  const handleChangeAsyncInputValue = (value) => {
    const noOptionsMsg = value
      ? <FormattedMessage id={'app.noData'}/>
      : <FormattedMessage id={'authorization.legalNameOrINN'}/>

    setNoDadataOptionsMsg(noOptionsMsg)
    setInputValue(value)
  }

  const handleChangeDadataSelect = (e) => {
    const {name, address, inn} = e.value

    const data = {
      organization_name: name || '',
      organization_address: address || '',
      inn: inn || ''
    }

    for (const key in data) {
      setFormValue(key)(data[key])
    }
  }

  return (
    <div className={'form-group'}>
      <label>
        <FormattedMessage id={'authorization.findOrg'}/>
      </label>

      <AsyncSelectComponent
        loadOptions={getINNOptions}
        noOptionsMessage={() => noDadataOptionsMsg}
        onInputChange={handleChangeAsyncInputValue}
        handleChange={handleChangeDadataSelect}
        inputValue={inputValue}
      />
    </div>
  )
}

export default FindOrganizationInput
