import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'passwordRules'
const translations = {
  passwordRequirements: 'Требования к паролю',
  number: 'цифра',
  lowercaseLatinLetter: 'прописная латинская буква',
  uppercaseLatinLetter: 'заглавная латинская буква',
  eightSymbols: '8 символов',
  specialSymbols: 'спец символ (!@#$%^&*()_)'
}

export const passwordRulesRu = createTranslationsWithSpaceName(translations, spaceName)