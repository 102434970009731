import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'objectTypes'
const translations = {
  0: 'The accounting object type is not set',
  1: 'Flat',
  2: 'Cottage (private house)',
  3: 'Apartment building',
  4: 'Apartment building room (built-in)',
  5: 'Office',
  6: 'Office center (building)',
  7: 'School (building)',
  8: 'Hospital (building)',
  9: 'Administrative building',
  10: 'Sports building',
  11: 'Manufacture building',
  12: 'Warehouse building',
  13: 'Shop (building)',
  14: 'Other buildings and premises'
}

export const objectTypesEn = createTranslationsWithSpaceName(translations, spaceName)
