import Loader from "@/presentation/components/loader/Loader";
import {Table, TableBody, TableHead, TableRow} from "@mui/material";
import {calculationsSelectors} from "@/internal/lib/storeModels/models/mkds/calculations/calculationsState";
import ResultEnumeratorRow from "@/presentation/components/heatCalculationPage/components/MKDPage/components/ResultEnumeratorRow";
import StyledComponents from "@/presentation/components/heatCalculationPage/components/MKDPage/components/StyledComponents";
import TableLabel from "@/presentation/components/heatCalculationPage/components/MKDPage/components/TableLabel";
import {MKDHelper} from "@/presentation/components/heatCalculationPage/helpers";
import HoveredPopover from "@/presentation/components/common/HoveredPopover";
import {UNIT_TYPES} from "@/presentation/components/heatCalculationPage/const";
import {
  calculationSettingsFormSelectors, sendCalcResultFormSelectors,
} from "@/internal/lib/storeModels/models/mkds/calculations/forms";
import GetXLSButton from "@/presentation/components/analytics/сomponents/result/GetXLSButton";
import {heatCalculateEndpoints} from "@/data/api_entities/heat_calculate/heat_calculate_endpoints";
import {useRef, useState} from "react";
import {selectors} from "@/presentation/shared/ui/message";
import {FormattedMessage} from "react-intl";
import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import SendResultForm from "@/presentation/components/analytics/сomponents/result/SendResultForm";
import {apartmentsSort} from "@/presentation/components/heatCalculationPage/components/MKDPage/components/const";

const ResultTable = (
  {
    onChangePage,
    paginationParams,
    loading
  }) => {
  const calcId = calculationsSelectors.editCalculateId.useValue()
  const calcResultApartments = calculationsSelectors.calcResultApartments.useValue()
  const totalValues = calculationsSelectors.resultTotalValues.useValue()
  const calcData = calculationSettingsFormSelectors.useFormData()
  const [sending, setSending] = useState(false)
  const setMessage = selectors.useSetMessage()
  const [openSendResultModal, toggleSendResultModal] = useToggle()
  const isValidMailsForm = sendCalcResultFormSelectors.useIsFormValid()
  const onClickSubmit = sendCalcResultFormSelectors.useOnClickSubmit()
  const lastSendMailsRequest = useRef(null)

  const handleSendResult = (data) => {
    if (sending) return
    setSending(true)
    lastSendMailsRequest.current?.abort()
    const {promise, controller} = heatCalculateEndpoints.sendResult(calcId, data)
    lastSendMailsRequest.current = controller
    promise
      .then((res) => {
        if (res?.status === 'ok') {
          toggleSendResultModal()
          setMessage({
            header: 'Отправка расчета на почту',
            message: 'Расчет успешно отправлен на почту',
            type: 'success',
            count: 3000
          })
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось отправить расчет на почту',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
        lastSendMailsRequest.current = null
        setSending(false)
      })
      .catch(err => {
        if (err.name === 'AbortError') return
        console.log(err)
        lastSendMailsRequest.current = null
        setSending(false)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось отправить расчет на почту',
          type: 'error',
          count: 3000
        })
      })
  }

  const rows = calcResultApartments.sort(apartmentsSort).map(apartment => {
    const enumerators = apartment.enumerator ?? []
    if (enumerators.length) {
      return enumerators.map((enumerator, index) => {
        return (
          <>
            <ResultEnumeratorRow
              key={apartment.id.toString() + '-' + enumerator.id}
              withApartmentInfo={index === 0}
              apartment={apartment}
              enumerator={enumerator}
            />

            {index === enumerators.length - 1
              ? (
                <TableRow
                  key={apartment.id.toString() + '-' + enumerator.id + ':total'}
                >
                  <StyledComponents.BodyTableCell
                    colSpan={4}
                  />
                  <StyledComponents.BodyValueTableCell>
                    Итого:
                  </StyledComponents.BodyValueTableCell>
                  <StyledComponents.BodyValueTableCell>
                    {MKDHelper.formatNumber(apartment?.total_Ui)}
                  </StyledComponents.BodyValueTableCell>

                  <StyledComponents.BodyValueTableCell>
                    {MKDHelper.formatNumber(apartment.Viodn)}
                  </StyledComponents.BodyValueTableCell>
                  <StyledComponents.BodyValueTableCell>
                    {MKDHelper.formatNumber(apartment.Vin)}
                  </StyledComponents.BodyValueTableCell>
                  <StyledComponents.BodyValueTableCell>
                    {MKDHelper.formatNumber(apartment.Vip)}
                  </StyledComponents.BodyValueTableCell>
                  <StyledComponents.BodyValueTableCell>
                    {MKDHelper.formatNumber(apartment.Vk)}
                  </StyledComponents.BodyValueTableCell>
                </TableRow>
              )
              : null
            }
          </>
        )
      })
    } else {
      return (
        <TableRow key={apartment.id + ':total'}>
          <StyledComponents.BodyTableCell>
            {apartment.kv}
          </StyledComponents.BodyTableCell>

          <StyledComponents.BodyTableCell>
            {apartment.area}
          </StyledComponents.BodyTableCell>

          <StyledComponents.BodyTableCell
            colSpan={6}
          >
            Нет распределителей или показаний
          </StyledComponents.BodyTableCell>

          <StyledComponents.BodyValueTableCell>
            {MKDHelper.formatNumber(apartment?.Viodn)}
          </StyledComponents.BodyValueTableCell>

          <StyledComponents.BodyValueTableCell>
            {MKDHelper.formatNumber(apartment?.Vin)}
          </StyledComponents.BodyValueTableCell>

          <StyledComponents.BodyValueTableCell>
            {MKDHelper.formatNumber(apartment?.Vip)}
          </StyledComponents.BodyValueTableCell>

          <StyledComponents.BodyValueTableCell >
            {MKDHelper.formatNumber(apartment?.Vk)}
          </StyledComponents.BodyValueTableCell>
        </TableRow>
      )
    }
  })

  const handleSendMails = () => {
    if (!isValidMailsForm) return

    onClickSubmit()
  }

  return (
    <>
      <TableLabel
        onChangePage={onChangePage}
        paginationParams={paginationParams}
      />

      <Loader
        loading={loading}
        styles={{
          wrapper: (base) => ({
            ...base,
            flex: 1,
            overflow: 'auto',
            marginBottom: '20px'
          }),
        }}
      >
        <Table
          stickyHeader
        >
          <TableHead>
            <StyledComponents.TableRow>
              <StyledComponents.HeadTableCell>
                № квартиры
              </StyledComponents.HeadTableCell>

              <StyledComponents.HeadTableCell>
                Площадь, м<sup>2</sup>
              </StyledComponents.HeadTableCell>

              <StyledComponents.HeadTableCell>
                № ОП на квартире
              </StyledComponents.HeadTableCell>
              <StyledComponents.HeadTableCell>
                Коэф. ОП
              </StyledComponents.HeadTableCell>
              <StyledComponents.HeadTableCell>
                Мощность ОП, КВт/ч
              </StyledComponents.HeadTableCell>
              <StyledComponents.HeadValueTableCell>
                Показания на начало, у.е.
              </StyledComponents.HeadValueTableCell>
              <StyledComponents.HeadValueTableCell>
                Показания на конец, у.е.
              </StyledComponents.HeadValueTableCell>

              <HoveredPopover
                text={'Потреблено по показаниям распределителей с учетом мощности и коэффициента типа ОП'}
              >
                <StyledComponents.HeadValueTableCell>
                  U<sub>i</sub>, у.е.
                </StyledComponents.HeadValueTableCell>
              </HoveredPopover>


              <HoveredPopover
                text={'Доля энергии квартиры на общедомовые нужды'}
              >
                <StyledComponents.HeadValueTableCell>
                  V<sub>i</sub>одн, {UNIT_TYPES.find(type => type.value === calcData.unit_type)?.label ?? '-'}
                </StyledComponents.HeadValueTableCell>
              </HoveredPopover>

              <HoveredPopover
                text={'Доля энергии на отопление квартиры в которой нет распределителей'}
              >
                <StyledComponents.HeadValueTableCell>
                  V<sub>i</sub>н, {UNIT_TYPES.find(type => type.value === calcData.unit_type)?.label ?? '-'}
                </StyledComponents.HeadValueTableCell>
              </HoveredPopover>

              <HoveredPopover
                text={'Доля энергии на отопление квартиры c распределителями'}
              >
                <StyledComponents.HeadValueTableCell>
                  V<sub>i</sub>р, {UNIT_TYPES.find(type => type.value === calcData.unit_type)?.label ?? '-'}
                </StyledComponents.HeadValueTableCell>
              </HoveredPopover>

              <HoveredPopover
                text={'Сумарное кол-во энергии затраченное квартирой'}
              >
                <StyledComponents.HeadValueTableCell>
                  Vк, {UNIT_TYPES.find(type => type.value === calcData.unit_type)?.label ?? '-'}
                </StyledComponents.HeadValueTableCell>
              </HoveredPopover>
            </StyledComponents.TableRow>
          </TableHead>

          <TableBody>
            {rows}
          </TableBody>

          <StyledComponents.TableFooter>
            <StyledComponents.TableRow>
              <StyledComponents.FooterTableCell>
                Сумма
              </StyledComponents.FooterTableCell>

              <StyledComponents.FooterTableCell>
                {totalValues?.total_area}
              </StyledComponents.FooterTableCell>

              <StyledComponents.FooterTableCell
                colSpan={5}
              />

              <StyledComponents.FooterValueTableCell>
                {MKDHelper.formatNumber(totalValues?.all_total_Ui)}
              </StyledComponents.FooterValueTableCell>

              <StyledComponents.FooterValueTableCell>
                {MKDHelper.formatNumber(totalValues?.total_Viodn)}
              </StyledComponents.FooterValueTableCell>

              <StyledComponents.FooterValueTableCell>
                {MKDHelper.formatNumber(totalValues?.total_Vin)}
              </StyledComponents.FooterValueTableCell>

              <StyledComponents.FooterValueTableCell>
                {MKDHelper.formatNumber(totalValues?.total_Vip)}
              </StyledComponents.FooterValueTableCell>

              <StyledComponents.FooterValueTableCell>
                {MKDHelper.formatNumber(totalValues?.total_Vk)}
              </StyledComponents.FooterValueTableCell>
            </StyledComponents.TableRow>
          </StyledComponents.TableFooter>
        </Table>
      </Loader>

      <div className={'calculations__btn-wrapper calculations__btn-wrapper_one-right'}>
        <button
          onClick={toggleSendResultModal}
          disabled={sending}
          className={'btn btn_transparent-bg'}
          style={{
            marginRight: '10px'
          }}
        >
          Отправить на почту
        </button>

        <GetXLSButton
          getRequest={() => heatCalculateEndpoints.getXLSX(calcId)}
        />
      </div>

      <DefaultModal
        isOpen={openSendResultModal}
        toggleModal={toggleSendResultModal}
        headerTitle={<FormattedMessage id={'analytics.sendingReport'}/>}
        bodyText={<SendResultForm
          formSelectors={sendCalcResultFormSelectors}
          name={'email_list'}
          onValidSubmit={handleSendResult}
        />}
        loading={sending}
        agreeBtnConfig={{
          className: 'btn',
          text: <FormattedMessage id={'app.send'}/>,
          disabled: sending
        }}
        onAgree={handleSendMails}
        onDisagree={toggleSendResultModal}
      />
    </>
  )
}

export default ResultTable
