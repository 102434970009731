import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {INIT_CHANGE_USER_DATA, INIT_CHANGE_USER_DATA_VALIDATIONS} from "../const";
import {getChangeUserDataValidators} from "@/internal/validators/formValidators";

const userDataFormModel = new FormModelFabric({
  initialData: INIT_CHANGE_USER_DATA,
  initialValidations: INIT_CHANGE_USER_DATA_VALIDATIONS,
  getValidators: getChangeUserDataValidators
})

export const userDataFormSelectors = userDataFormModel.createSelectors()
