import Select from "react-select";
import {customStyles, customStylesError} from "../const";
import DropdownIndicator from "./CustomDropdownIndicator";

const DefaultSelect = (
  {
    name,
    value,
    onChange,
    loading = false,
    placeholder = '',
    isError = false,
    options,
    styles = {},
    getOptionValue,
    getOptionLabel,
    disabled = false
  }) => {
  return (
    <Select
      components={{DropdownIndicator}}
      isLoading={loading}
      name={name}
      value={value}
      options={options}
      onChange={onChange}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      styles={isError ? {...customStylesError, ...styles} : {...customStyles, ...styles}}
      autoFocus={false}
      maxMenuHeight={180}
      menuPosition={'absolute'}
      menuPlacement={'auto'}
      isSearchable={false}
      placeholder={placeholder}
      closeMenuOnSelect={true}
      isDisabled={disabled}
    />
  )
}

export default DefaultSelect
