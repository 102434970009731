import ArrowBack from "@/presentation/components/common/ArrowBack";
import {DEVICE_SETTINGS_PATH} from "./const";
import { addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import React, {useEffect, useMemo, useState} from "react";
import {devicesEndpoint} from "@/data/api_entities/devices/devices_endpoint";
import {devicePageSelectors} from "@/internal/lib/storeModels/models/devices/devicePageModels";
import {selectors, typeMessage} from "@/presentation/shared/ui/message";
import {useNavigate} from "react-router-dom";
import Loader from "@/presentation/components/loader/Loader";
import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import {devicesSelectors} from "@/internal/lib/storeModels/models/devices/devicesModel";
import Input from "../../common/formComponents/Input";
import {deviceSettingsFormSelectors} from "@/internal/lib/storeModels/models/devices/forms/deviceFormModels";
import ObjectInput from "@/presentation/components/common/formComponents/ObjectInput";
import {useIntl} from "react-intl";
import {FormattedMessage} from "react-intl/lib";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import {responseHandler} from "@/internal/lib/transport/response_handler";

const DeviceSettings = ({deviceInfo, getDeviceInfo, devices}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const selectedAddress = addressesSelectors.selectedAddress.useValue()
  const setInitData = deviceSettingsFormSelectors.useSetInitFormData()
  const resetForm = deviceSettingsFormSelectors.useResetForm()
  const setOnValidSubmit = deviceSettingsFormSelectors.useSetOnValidSubmit()
  const onClickSubmit = deviceSettingsFormSelectors.useOnClickSubmit()
  const [loading, setLoading] = devicePageSelectors.isDeviceChanging.useState()
  const userData = userDataSelectors.userData.useValue()
  const updateAddresses = addressesSelectors.addresses.useUpdateAddresses()
  const updateDevices = devicesSelectors.devices.useUpdateDevices()
  const getDevicesList = devicePageSelectors.devicesList.useGetDevicesList()
  const setMessage = selectors.useSetMessage()
  const addressId = deviceSettingsFormSelectors.useFormDataValue('address')
  const [isOpenDeleteModal, toggleDeleteModal] = useToggle()
  const [isOpenEditModal, toggleEditModal] = useToggle()
  const [isOpenUntieModal, toggleUntieModal] = useToggle()
  const [isOpenDeleteLinkedModal, toggleDeleteLinkedModal] = useToggle()
  const [untieLoading, setUntieLoading] = useState(false)

  const slaves = devices.filter(device => device.master === deviceInfo.id)

  useEffect(() => {
    if (!deviceInfo) return

    setOnValidSubmit(onValidSubmit)

    setInitData({
      address: selectedAddress.id,
      location: deviceInfo.location ?? intl.formatMessage({id: 'devicesPage.notConfigured'})
    })

    return () => {
      resetForm()
    }
  }, [deviceInfo])

  const disabledByDemo = useMemo(() => {
    return userData.email === 'demo@e-watt.ru' || userData.email ===  'demo@rockitstudio.ru'
  }, [userData])

  const onValidSubmit = (data) => {
    setLoading(true)

    const {promise} = devicesEndpoint.changeDeviceSettings({
      id: deviceInfo.id,
      ...data
    })

    promise
      .then(res => {
        responseHandler(res, {
          ok: {
            callback: () => {
              if (data.address === selectedAddress.id) {
                getDeviceInfo()
              } else {
                getDevicesList()
                updateAddresses()

                navigate('/device')
              }
            },
            header: <FormattedMessage id={'requests.changeDeviceSettingsSuccessHeader'}/>,
            message: <FormattedMessage id={'requests.changeDeviceSettingsSuccess'}/>,
          },
          error: {
            message: <FormattedMessage id={'requests.changeDeviceSettingsError'}/>,
          }
        })
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message:  <FormattedMessage id={'requests.changeDeviceSettingsError'}/>,
          count: 3000,
          type: typeMessage.error
        })
      })
      .finally(() => setLoading(false))
  }

  const handleSubmit = () => {
    if (disabledByDemo) return

    if (selectedAddress.id !== addressId && (slaves.length !== 0 || !!deviceInfo.master)) {
      toggleEditModal()
    } else {
      onClickSubmit()
    }
  }

  const handleDeleteDevice = () => {
    if (disabledByDemo) return

    setLoading(true)

    const {promise} = devicesEndpoint.deleteDevice(deviceInfo.id)

    promise
      .then(res => {
        responseHandler(res, {
          ok: {
            callback: () => {
              toggleDeleteModal()
              getDevicesList()

              updateAddresses()
              updateDevices()
              navigate('/device')
            },
            header:  <FormattedMessage id={'requests.removeDeviceSuccessHeader'}/>,
            message: <FormattedMessage id={'requests.removeDeviceSuccess'}/>,
          },
          error: {
            message: <FormattedMessage id={'requests.removeDeviceError'}/>,
          }
        })
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.removeDeviceError'}/>,
          count: 3000,
          type: typeMessage.error
        })
      })
      .finally(() => setLoading(false))
  }

  const untieAllDevices = () => {
    if (slaves.length > 0) {
      setUntieLoading(true)
      devicesEndpoint.linkDevices(null, slaves.map(slave => slave.id)).promise
        .then(res => {
          responseHandler(res, {
            ok: {
              callback: () => {
                toggleUntieModal()
                getDevicesList()
                updateDevices()
              },
              header: <FormattedMessage id={'requests.untieDevicesSuccessHeader'}/>,
              message: <FormattedMessage id={'requests.untieDevicesSuccess'}/>
            },
            error: {
              message: <FormattedMessage id={'requests.untieDevicesError'}/>
            }
          })
        })
        .catch(err => {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message:  <FormattedMessage id={'requests.untieDevicesError'}/>,
            count: 3000,
            type: typeMessage.error
          })
          console.log(err)
        })
        .finally(() => setUntieLoading(false))
    } else if (!!deviceInfo.master) {
      setUntieLoading(true)
      devicesEndpoint.linkDevice(null, deviceInfo.id).promise
        .then(res => {
          responseHandler(res, {
            ok: {
              callback: () => {
                toggleUntieModal()
                getDevicesList()
                updateDevices()
              },
              header: <FormattedMessage id={'requests.untieDevicesSuccessHeader'}/>,
              message: <FormattedMessage id={'requests.untieDevicesSuccess'}/>
            },
            error: {
              message: <FormattedMessage id={'requests.untieDevicesError'}/>
            }
          })
        })
        .catch(err => {
          console.log(err)
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message:  <FormattedMessage id={'requests.untieDevicesError'}/>,
            count: 3000,
            type: typeMessage.error
          })
        })
        .finally(() => setUntieLoading(false))
    }
  }

  const onDeleteDevice = () => {
    if (slaves.length !== 0) {
      toggleDeleteModal()
      toggleDeleteLinkedModal()
    } else {
      handleDeleteDevice()
    }
  }

  return (
    <div className='device-settings'>
      <ArrowBack
        pathOnVisible={DEVICE_SETTINGS_PATH}
      />

      <h2 className="title">
        <FormattedMessage id={'devicesPage.generalSettings'}/>
      </h2>

      <Loader loading={loading}>
        <div className="form-fields">
          <ObjectInput
            label={<FormattedMessage id={'devicesPage.accountingObject'}/>}
            name={'address'}
            formSelectors={deviceSettingsFormSelectors}
          />

          <Input
            label={<FormattedMessage id={'devicesPage.location'}/>}
            name={'location'}
            formSelectors={deviceSettingsFormSelectors}
          />
        </div>

        {(!!slaves.length || !!deviceInfo.master) &&
          <button
            className="btn device-settings__untie-btn"
            onClick={toggleUntieModal}
          >
            <FormattedMessage id={'devicesPage.untieAll'}/>
          </button>
        }

        <div className="device-settings__info">
          <div className="device-settings__expired">
            <span>
              <FormattedMessage id={'devicesPage.warrantyUpTo'}/>
            </span>

            <p>
              {deviceInfo?.expired || <FormattedMessage id={'devicesPage.notConfigured'}/>}
            </p>
          </div>

          <div className="device-settings__serial">
            <span>
              <FormattedMessage id={'devicesPage.serialNumber'}/>
            </span>

            <p>
              {deviceInfo?.serial || <FormattedMessage id={'devicesPage.notConfigured'}/>}
            </p>
          </div>
        </div>

        <div className="device-settings__btns">
          <button
            className="btn btn_transparent-bg"
            onClick={handleSubmit}
            disabled={disabledByDemo}
          >
            <FormattedMessage id={'app.save'}/>
          </button>

          <button
            className="btn btn_red"
            onClick={toggleDeleteModal}
            disabled={disabledByDemo}
          >
            <FormattedMessage id={'app.delete'}/>
          </button>
        </div>
      </Loader>

      <DefaultModal
        isOpen={isOpenDeleteModal}
        toggleModal={toggleDeleteModal}
        agreeBtnConfig={{
          className: 'btn btn_red',
          text: <FormattedMessage id={'app.delete'}/>
        }}
        loading={loading}
        onAgree={onDeleteDevice}
        onDisagree={toggleDeleteModal}
        headerTitle={<FormattedMessage id={'devicesPage.removeDevice?'}/>}
      />

      <DefaultModal
        isOpen={isOpenDeleteLinkedModal}
        toggleModal={toggleDeleteLinkedModal}
        agreeBtnConfig={{
          className: 'btn',
          text: <FormattedMessage id={'app.ok'}/>
        }}
        disagreeBtnConfig={null}
        bodyText={<FormattedMessage id={'devicesPage.alertDelete'}/>}
        headerTitle={<FormattedMessage id={'devicesPage.removeDevice'}/>}
      />

      <DefaultModal
        isOpen={isOpenEditModal}
        toggleModal={toggleEditModal}
        agreeBtnConfig={{
          className: 'btn',
          text: <FormattedMessage id={'app.ok'}/>
        }}
        disagreeBtnConfig={null}
        headerTitle={<FormattedMessage id={'devicesPage.editDeviceSettings'}/>}
        bodyText={<FormattedMessage id={'devicesPage.alertEdit'}/>}
      />

      <DefaultModal
        isOpen={isOpenUntieModal}
        toggleModal={toggleUntieModal}
        agreeBtnConfig={{
          className: 'btn',
          text: <FormattedMessage id={'app.accept'}/>
        }}
        loading={untieLoading}
        headerTitle={<FormattedMessage id={'devicesPage.untieAllTitle'}/>}
        bodyText={<FormattedMessage id={'devicesPage.untieAllBody'}/>}
        onAgree={untieAllDevices}
      />
    </div>
  )
}

export default DeviceSettings
