import Select, {components} from "react-select";
import {SEARCH_SELECT_STYLES} from "../const";
import {Search} from "@/assets/svg";
import {FormattedMessage} from "react-intl";
import React, {useEffect, useRef, useState} from "react";
import {enumeratorEndpoint} from "@/data/api_entities/enumerator/enumartaor_endpoint";
import {selectors} from "@/presentation/shared/ui/message";

const SearchSelect = (
  {
    placeholder = '',
    noOptionsMessage = () => <FormattedMessage id={'app.nothingFound'}/>,
    onSelect,
    id
  }) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const searchTimeout = useRef(null)
  const request = useRef(null)
  const [loading, setLoading] = useState(false)
  const setMessage = selectors.useSetMessage()

  useEffect(() => {
    onSearch('')
    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current)
      }
    }
  }, [])

  const onSearch = (value) => {
    setLoading(true)

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current)
    }
    request.current?.abort()

    const timeout = setTimeout(() => {
      const {promise, controller} = enumeratorEndpoint.find(value, id)
      request.current = controller
      promise
        .then(res => {
          if (Array.isArray(res?.results)) {
            setOptions(res.results.map(item => {
              return {
                value: item.coefficient,
                item,
                label: (
                  <span style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <span>{item.name}</span>
                    <span style={{
                      fontSize: '12px',
                      color: '#727A86'
                    }}>{item.сoolant_supply}</span>
                  </span>
                )
              }
            }))
          } else {
            setMessage({
              header: <FormattedMessage id={'requests.error'}/>,
              message: 'Не удалось загрузить список ОП',
              type: 'error',
              count: 3000
            })
          }
          request.current = null
        })
        .catch(err => {
          if (err.name === 'AbortError') return
          request.current = null
          console.log(err)
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось загрузить список ОП',
            type: 'error',
            count: 3000
          })
        })
        .finally(() =>  setLoading(false))
      searchTimeout.current = null
    }, 300)

    searchTimeout.current = timeout
  }

  const onChangeInputValue = (value) => {
    setInputValue(value)
    onSearch(value)
  }

  return (
    <Select
      isSearchable
      menuPosition={'absolute'}
      menuPlacement={'auto'}
      maxMenuHeight={480}
      options={options}
      autoFocus={false}
      onChange={onSelect}
      isLoading={loading}
      escapeClearsValue={true}
      filterOption={() => true}
      loadingMessage={() => 'Загрузка...'}
      inputValue={inputValue}
      onInputChange={onChangeInputValue}
      components={{DropdownIndicator, SingleValue}}
      styles={SEARCH_SELECT_STYLES}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
    />
  )
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={Search} alt="Search"/>
    </components.DropdownIndicator>
  )
}

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      {props.data?.item.name ?? '-'}
    </components.SingleValue>
  )
}

export default SearchSelect
