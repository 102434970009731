export const INIT_PRECONFIG_DATA = Object.freeze({
  accounting_objects: {},
  start_date: '',
  end_date: '',
  step: '3',
  name: '',
  toStep: 1,
  period_display_type: 1,
  analytic_unit_type: 1
})

export const INIT_PRECONFIG_VALIDATIONS = Object.freeze({
  accounting_objects: [],
  start_date: [],
  end_date: [],
  step: [],
  name: []
})

export const INIT_SEND_RESULTS_DATA = Object.freeze({
  mails: []
})

export const INIT_SEND_RESULTS_VALIDATIONS = Object.freeze({
  mails: []
})

export const INIT_ANALYTIC_NAME_DATA = Object.freeze({
  name: ''
})

export const INIT_ANALYTIC_NAME_VALIDATIONS = Object.freeze({
  name: []
})
