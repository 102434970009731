import StyledComponents from "@/presentation/components/heatCalculationPage/components/MKDPage/components/StyledComponents";
import {calculationsSelectors} from "@/internal/lib/storeModels/models/mkds/calculations/calculationsState";
import {useEffect, useRef, useState} from "react";
import {heatIndicationsEndpoints} from "@/data/api_entities/heat_indications/heat_indications_endpoints";
import HoveredPopover from "@/presentation/components/common/HoveredPopover";

const DataRow = (
  {
    withApartmentInfo,
    apartment,
    enumerator,
    formSelectors,
    setDataSending
  }) => {
  const [activeCell, setActiveCell] = useState(null)
  const setDataValueById = formSelectors?.useSetEnumeratorData() ?? (() => {})
  const data = formSelectors?.useCurrentEnumeratorData(enumerator.id)
  const calcId = calculationsSelectors.editCalculateId.useValue()
  const notValidData = calculationsSelectors.notValidApartments.useValue().find(apartmentEl => apartmentEl.id === apartment.id)
  const calcResultApartments = calculationsSelectors.calcResultApartments.useValue()
  const lastSaveRequest = useRef(null)
  const handleFocus = (event, id) => {
    if (!!calcResultApartments.length) return
    event.target.select()
    setActiveCell(id)
  }
  const handleBlur = () => {
    if (!!calcResultApartments.length) return
    lastSaveRequest.current?.abort()

    setDataSending(prev => ({
      ...prev,
      [enumerator.id]: true
    }))

    const {promise, controller} = heatIndicationsEndpoints.setData({
      start_period:(data?.start ?? '').toString().length ? data.start : null,
      end_period: (data?.end ?? '').toString().length ? data.end : null,
      enumerator: enumerator.id,
      heatcalculate: calcId
    }, data.indication_id)

    lastSaveRequest.current = controller
    promise
      .then(() => {
        lastSaveRequest.current = null
        setDataSending(prev => ({
          ...prev,
          [enumerator.id]: false
        }))
      })
      .catch(err => {
        if (err.name === 'AbortError') return
        lastSaveRequest.current = null
        setDataSending(prev => ({
          ...prev,
          [enumerator.id]: false
        }))
      })
  }

  const handleChange = (e, isStart = false) => {
    if (!!calcResultApartments.length) return
    const value = e.target.value

    if (typeof value === 'string' && value.includes('-')) return

    setDataValueById({
      enumeratorId: enumerator.id,
      key: isStart
        ? 'start'
        : 'end',
      value: !!value.length ? +value : value
    })
  }

  const handleClickCell = (e) => {
    if (!!calcResultApartments.length) return
    const target = e.target.closest('.MuiTableCell-root')
    target.children[0].focus()
  }

  const inputStartValueValidations = (start, end) => {
    if (!notValidData) return ''

    if ((start && end) && start > end) {
      return 'Значение на начало периода не должно быть больше значения на конец периода'
    }

    return ''
  }

  const deviationValues = () => {
    const enumeratorData = notValidData?.enumerator?.find(elem => elem.id === enumerator.id)
    if (!notValidData || !enumeratorData) return ''

    return enumeratorData.indications?.deviation
      ? 'Потрбление данного ОП меньше(больше) среднего потребления по МКД в 5(3) раза'
      : ''
  }

  const validation = !!inputStartValueValidations(data?.start, data?.end).length
    ? inputStartValueValidations(data?.start, data?.end)
    : deviationValues()

  return (
    <StyledComponents.TableRow
      onBlur={() => setActiveCell(null)}
    >
      {withApartmentInfo &&
        <>
          <StyledComponents.BodyTableCell
            rowSpan={apartment.enumerator.length}
          >
            {apartment.kv}
          </StyledComponents.BodyTableCell>

          <StyledComponents.BodyTableCell
            rowSpan={apartment.enumerator.length}
          >
            {apartment.area}
          </StyledComponents.BodyTableCell>
        </>
      }

      <StyledComponents.BodyTableCell>
        {enumerator.number}
      </StyledComponents.BodyTableCell>

      <StyledComponents.BodyTableCell>
        {enumerator.serial ?? '-'}
      </StyledComponents.BodyTableCell>

      <StyledComponents.BodyTableCell>
        {enumerator.coefficient}
      </StyledComponents.BodyTableCell>

      <StyledComponents.BodyTableCell>
        {enumerator.power}
      </StyledComponents.BodyTableCell>

      <HoveredPopover
        text={validation}
        disabled={!validation.length}
      >
        <StyledComponents.BodyTableCell
          sx={{
            background: activeCell === (enumerator.id + '-start')
              ? '#ECF0F6'
              : 'transparent',
            input: {
              background: activeCell === (enumerator.id + '-start')
                ? '#ECF0F6'
                : 'transparent',
            },
            border: !!inputStartValueValidations(data.start, data.end).length
              ? '0.5px solid red'
                : !!deviationValues().length
                  ? '0.5px solid yellow'
                  : '0.5px solid #ECF0F6'
          }}
          onClick={handleClickCell}
        >
          <input
            disabled={!!calcResultApartments.length}
            className={'table-input'}
            value={data?.start ?? ''}
            onChange={(e) => handleChange(e, true)}
            onFocus={(e) => handleFocus(e, enumerator.id + '-start')}
            type={'number'}
            min={'0'}
            onWheel={(e) => e.target.blur()}
            onBlur={handleBlur}
          />
        </StyledComponents.BodyTableCell>
      </HoveredPopover>


      <StyledComponents.BodyTableCell
        sx={{
          background: activeCell === (enumerator.id + '-end')
            ? '#ECF0F6'
            : 'transparent',
          input: {
            background: activeCell === (enumerator.id + '-end')
              ? '#ECF0F6'
              : 'transparent',
          }
        }}
        onClick={handleClickCell}
      >
        <input
          disabled={!!calcResultApartments.length}
          className={'table-input'}
          value={data?.end ?? ''}
          onChange={handleChange}
          onFocus={(e) => handleFocus(e, enumerator.id + '-end')}
          type={'number'}
          min={'0'}
          onWheel={(e) => e.target.blur()}
          onBlur={handleBlur}
        />
      </StyledComponents.BodyTableCell>
    </StyledComponents.TableRow>
  )
}

export default DataRow
