import {
  Battery,
  BatteryGrey,
  BatteryRed,
  BatteryYellow, ConnectionGreen,
  ConnectionGrey,
  ConnectionRed, ConnectionYellow,
  GreenFork
} from "@/assets/svg";

export const NEW_DEVICE_PATH = 'add_device'

export const BATTERY_IMGS = Object.freeze({
  0: GreenFork,
  1: BatteryGrey,
  2: BatteryRed,
  3: BatteryYellow,
  4: Battery
})

export const CONNECT_IMGS = Object.freeze({
  0: ConnectionGrey,
  1: ConnectionRed,
  2: ConnectionYellow,
  3: ConnectionGreen
})
