import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'main'
const translations = {
  noObjects: 'Нет объектов',
  setUpSystem: 'Давайте настроим систему',
  bindDevice: 'Нужно привязать оборудование к объекту учета и настроить передачу показаний.',
  noDevices: 'Нет оборудования?',
  onlineStore: 'Интернет-магазин оборудования',
  stepOne: 'Шаг 1',
  stepTwo: 'Шаг 2',
  stepThree: 'Шаг 3',
  stepOneTitle: 'Объект учета',
  stepTwoTitle: 'Оборудование',
  stepThreeTitle: 'Передача',
  stepOneText: 'Добавьте квартиру, дом или завод. У\xa0каждого объекта - свое оборудование',
  stepTwoText: 'Подключите устройство к\xa0облаку и\xa0настройте все входы и\xa0выходы',
  stepThreeText: 'Привяжите точку учета и настройте считывание показаний',
  stepOneBtn: 'Добавить',
  stepTwoBtn: 'Подключить',
  stepThreeBtn: 'Подключить'
}

export const mainRu = createTranslationsWithSpaceName(translations, spaceName)
