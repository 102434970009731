import {Link, useLocation, Routes, Route} from "react-router-dom";
import {HISTORY_PATH, TARIFF_PATH, TARIFFS_PATH, TOP_UP_PATH} from "../const";
import {TARIFFS_LINKS_LIST} from "./const";
import LinkTab from "@/presentation/components/common/LinkTab";
import Subscribes from "./components/Subscribes";
import {getDeviceInfoByType} from "@/internal/helpers/helpers";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import Balance from "./components/Balance";
import History from "./components/History";
import TariffsList from "./components/TariffsList";
import {FormattedMessage, FormattedNumber} from "react-intl";
import {appSelectors} from "@/internal/lib/storeModels/models/app/appModels";
import {CURRENCY} from "@/i18n/locales";

const Tariffs = ({isService = false}) => {
  const location = useLocation()

  const addresses = addressesSelectors.addresses.useValue()
  const userData = userDataSelectors.userData.useValue()
  const locale = appSelectors.locale.useValue()

  const links = TARIFFS_LINKS_LIST.filter(({id}) => {
    if (!isService) return true

    return id !== 2
  }).map(({id, textId, path}) => {
    return (
      <LinkTab
        key={id}
        textId={textId}
        path={path}
      />
    )
  })

  const getTotalCoast = (addresses) => {
    let total = 0

    if (!addresses || !addresses.length) return total

    addresses.forEach(address => {
      if (!address.devices) return

      address.devices.forEach(device => {
        const deviceInfo = getDeviceInfoByType(device.type)

        total += deviceInfo.monthCoast
      })
    })

    return total
  }

  return (
    <>
      <div className='user-page__column user-page__column_tariffs'>
        <ArrowBack
          pathOnVisible={TARIFF_PATH}
        />

        <h2 className="title">
          <FormattedMessage id={'userPage.accAndRates'}/>
        </h2>

        <div className="user-page__balance-block">
          <div className="user-page__balance">
            <span>
              <FormattedMessage id={'userPage.balance'}/>
            </span>

            <span className='cache'>
              <FormattedNumber
                value={userData?.balance ?? 0}
                style="currency"
                currency={CURRENCY[locale]}
              />
            </span>
          </div>

          {!isService &&
            <Link
              className={`btn user-page__balance-btn ${location.pathname.includes(TOP_UP_PATH)  ? 'inactive' : ''}`}
              to={TOP_UP_PATH}
            >
              <FormattedMessage id={'userPage.topUp'}/>
            </Link>
          }
        </div>

        <div className="user-page__links">
          {links}
        </div>

        {!isService &&
          <div className="user-page__subscribes subscribes">
            <div className="subscribes__header">
              <h4>
                <FormattedMessage id={'userPage.subscription'}/>
              </h4>

              <span>
              <FormattedMessage id={'userPage.onMonth'}/>
            </span>
            </div>

            <Subscribes addresses={addresses}/>

            {!!addresses?.length &&
              <div className="subscribes__total">
              <span>
                <FormattedMessage id={'userPage.monthTotal'}/>
              </span>
                <span>
                <FormattedNumber
                  value={getTotalCoast(addresses) ?? 0}
                  style="currency"
                  currency={CURRENCY[locale]}
                />
              </span>
              </div>
            }
          </div>
        }
      </div>

      <Routes>
        {!isService &&
          <Route path={TOP_UP_PATH} element={<Balance />} />
        }

        <Route path={HISTORY_PATH} element={ <History /> } />

        {!isService &&
          <Route path={TARIFFS_PATH} element={ <TariffsList /> } />
        }
      </Routes>
    </>
  )
}

export default Tariffs
