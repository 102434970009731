import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {REPORT_INIT_DATA, REPORT_INIT_VALIDATIONS} from "../const";
import {getReportFormValidators} from "@/internal/validators/formValidators";

const newReportFormModel = new FormModelFabric({
  initialData: REPORT_INIT_DATA,
  initialValidations: REPORT_INIT_VALIDATIONS,
  getValidators: getReportFormValidators
})

export const newReportFormSelectors = newReportFormModel.createSelectors()
