import {Close} from "@/assets/svg";
import Loader from "@/presentation/components/loader/Loader";
import {FormattedMessage} from "react-intl";
import {createPortal} from "react-dom";

const DefaultModal = (
  {
    isOpen,
    headerTitle = '',
    bodyText = null,
    agreeBtnConfig,
    disagreeBtnConfig = {
      className: 'btn btn_transparent-bg',
      text: <FormattedMessage id={'app.cancel'}/>
    },
    onAgree,
    onDisagree,
    toggleModal,
    loading = false,
    afterLoadingText = ''
  }) => {

  const handleClickOutside = (e) => {
    const {target} = e

    if (target && !target.closest('.default-modal__substr')) {
      toggleModal()
    }
  }

  return isOpen
    ? (
      createPortal(
        <div className={'default-modal'} onClick={handleClickOutside}>
          <div className="default-modal__substr">
            {!afterLoadingText
              ? <>
                <div className="default-modal__header">

                  <h4 className="title_h4">{headerTitle}</h4>

                  <div className="default-modal__header-img" onClick={toggleModal}>
                    <img src={Close} alt="close"/>
                  </div>
                </div>

                {!!bodyText &&
                  <div className="default-modal__body">
                    <Loader
                      loading={loading}
                    >
                      {bodyText}
                    </Loader>
                  </div>
                }

                <div className="default-modal__btns">
                  {!!agreeBtnConfig &&
                    <button
                      className={agreeBtnConfig.className}
                      onClick={onAgree ?? toggleModal}
                      disabled={agreeBtnConfig.disabled ?? false}
                    >
                      {agreeBtnConfig.text}
                    </button>
                  }

                  {!!disagreeBtnConfig &&
                    <button
                      className={disagreeBtnConfig.className}
                      onClick={onDisagree ?? toggleModal}
                    >
                      {disagreeBtnConfig.text}
                    </button>
                  }
                </div>
              </>
              : <p className="default-modal__body">
                {afterLoadingText}
              </p>
            }
          </div>
        </div>,
        document.getElementById('root')
      )
    )
    : null
}

export default DefaultModal
