import {CHANGE_EMAIL_PATH, CHANGE_ENTITY_PATH, CHANGE_PASSWORD_PATH} from "../const";

export const USER_DATA_TABS_LIST = [
  {
    id: 1,
    textId: 'app.e-mail',
    path: CHANGE_EMAIL_PATH
  },
  {
    id: 2,
    textId: 'app.password',
    path: CHANGE_PASSWORD_PATH
  },
  {
    id: 3,
    textId: 'userPage.requisites',
    path: CHANGE_ENTITY_PATH
  }
]
