import React, {memo, useEffect, useState} from 'react'
import './Main.sass'
import Chat from '../chat/Chat.jsx'

// services
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import NavBar from "./components/NavBar";
import Intake from "../intake/Intake";
import Loading from "../loading/Loading";
import ReportsPage from "../reportsPage/ReportsPage";
import DevicesPage from "../devicesPage/DevicePage";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import EventsPage from "../eventsPage/EventsPage";
import cookiesManager from "@/internal/lib/services/cookiManager";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import {devicesSelectors} from "@/internal/lib/storeModels/models/devices/devicesModel";
import RoutePaths from "@/route_paths";
import MeteringPointsPage from "@/presentation/components/meteringPointsPage/MeteringPointsPage";
import {meteringPointsPageSelectors} from "@/internal/lib/storeModels/models/meteringPointsPage/meteringPointsModels";
import UserPage from "@/presentation/components/userPage/UserPage";


const Main = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [isFirstLoading, setIsFirstLoading] = useState(true)

  const loadingUserData = userDataSelectors.loadingUserData.useValue()
  const resetLoadingUserData = userDataSelectors.loadingUserData.useReset()
  const isLoadingDevices = devicesSelectors.loadingDevices.useValue()
  const resetIsLoadingDevices = devicesSelectors.loadingDevices.useReset()
  const isLoadingMeteroints = !!meteringPointsPageSelectors.lastGetListRequest.useValue()
  const resetIsLoadingMeteroints = meteringPointsPageSelectors.lastGetListRequest.useReset()
  const lastGetAllAddressesRequest = addressesSelectors.lastGetAddressesRequest.useValue()
  const resetIsLoadingAddresses = addressesSelectors.lastGetAddressesRequest.useReset()
  const selectedAddress = addressesSelectors.selectedAddress.useValue()

  const getUserData = userDataSelectors.userData.useGetUserData()
  const getPointsList = meteringPointsPageSelectors.pointsList.useGetPointsList()
  const getDevices = devicesSelectors.devices.useGetDevices()
  const getAddresses = addressesSelectors.addresses.useGetAddresses()

  useEffect(() => {
    return () => {
      resetIsLoadingAddresses()
      // resetLoadingUserData()
      resetIsLoadingDevices()
      resetIsLoadingMeteroints()
    }
  }, [])

  useEffect(() => {
    if (!!cookiesManager.get('X-pulse')) {
      const selectedAddressId = cookiesManager.get('X-pulseAddress')

      if (!selectedAddressId) {
        navigate('/user', {
          replace: true
        })
      }
    } else {
      navigate('/input', {
        replace: true
      })
    }
  }, [location])

  useEffect(() => {
    if (!cookiesManager.get('X-pulse')) return
    getAddresses()

    if (!selectedAddress) return;

    getUserData()
    getDevices(selectedAddress?.id)
    getPointsList()

  }, [selectedAddress])

  useEffect(() => {
    if (!isFirstLoading ||
      !!lastGetAllAddressesRequest ||
      loadingUserData ||
      isLoadingDevices ||
      isLoadingMeteroints
    ) return

    setIsFirstLoading(false)
  } ,[loadingUserData, lastGetAllAddressesRequest, isLoadingDevices, isLoadingMeteroints])

  return (
    <Loading loading={isFirstLoading}>
      <MemoMain />
    </Loading>
  )
}

const MemoMain = memo(() => {
  return (
    <div className="main">
      <NavBar />

      <div className="content">
        <Chat/>

        <Routes>
          <Route path={RoutePaths.intakePath} element={<Intake />}/>
          <Route path={RoutePaths.reportPath} element={<ReportsPage />}/>
          <Route path={RoutePaths.devicePath} element={<DevicesPage />}/>
          <Route path={RoutePaths.eventsPath} element={<EventsPage />}/>
          <Route path={RoutePaths.meteringPointsPath} element={<MeteringPointsPage />} />
          <Route path="user_page/*" element={<UserPage />}/>
        </Routes>
      </div>
    </div>
  )
})

export default Main
