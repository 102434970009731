import {request} from "@/internal/lib/transport/request";

export const balanceEndpoint = {
  getTransactionHistory () {
    const params = {
      url: 'api/balance/history',
      method: 'GET'
    }

    return request(params)
  },

  getTariffs () {
    const params = {
      url: 'api/balance/tariffs',
      method: 'GET'
    }

    return request(params)
  },

  setPayment (data) {
    const params = {
      url: 'api/balance/payment/',
      method: 'POST',
      data,
    }

    return request(params)
  }
}
