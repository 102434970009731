import {DETAILING_TYPES_LIST, TIME_INPUT_SELECT_STYLES, TIME_LIST} from "./const";
import {useEffect, useRef, useState} from "react";
import {reportsEndpoint} from "@/data/api_entities/reports/reports_endpoint";
import Loader from "@/presentation/components/loader/Loader";
import {API_URL} from "@/config";
import SelectInput from "@/presentation/components/common/formComponents/SelectInput";
import {previewReportFormSelectors} from "@/internal/lib/storeModels/models/report/forms/previewReportFormModel";
import Input from "@/presentation/components/common/formComponents/Input";
import {FormattedMessage} from "react-intl";

const ReportPreviewForm = ({reportFormSelectors}) => {
  const reportData = reportFormSelectors.useFormData()
  const formData = previewReportFormSelectors.useFormData()
  const resetForm = previewReportFormSelectors.useResetForm()

  const previewFileLink = useRef()

  const [previewTaskId, setPreviewTaskId] = useState(null)
  const [pendingTimerId, setPendingTimerId] = useState(null)

  useEffect(() => {
    return () => {
      resetForm()

      if (pendingTimerId) {
       clearTimeout(pendingTimerId)
      }
    }
  }, [])

  const onDownLoadFile = (url) => {
    previewFileLink.current.href = `${API_URL}${url}`

    previewFileLink.current.click()
  }

  const getPreviewFileUrl = (id) => {
    reportsEndpoint.getReportPreviewTaskId(id).promise
      .then(res => {
        if (res.status === 'pending') {
          const timerId = setTimeout(() => getPreviewFileUrl(id), 1000)
          setPendingTimerId(timerId)
        } else if (res.status === 'ok') {
          setPreviewTaskId(null)
          onDownLoadFile(res.url)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (!previewTaskId) return

    getPreviewFileUrl(previewTaskId)
  }, [previewTaskId])

  const handleSubmit = () => {
    reportsEndpoint.getUnsavedReportPreview({...reportData, ...formData}).promise
      .then(res => {
        if (!res.task_id) return

        setPreviewTaskId(res.task_id)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <div className={'report-preview'}>
      <h2 className="title">
        <FormattedMessage id={'reportsPage.createReport'}/>
      </h2>

      <p className={'report-preview__small'}>
        <FormattedMessage id={'reportsPage.dontForget'}/>
      </p>

      <Loader loading={!!previewTaskId}>
        <div className="form-fields">
          <SelectInput
            label={<FormattedMessage id={'reportsPage.detailing'}/>}
            options={DETAILING_TYPES_LIST}
            formSelectors={previewReportFormSelectors}
            name={'detailingPreview'}
          />

          <div className="report-preview__double-inputs">
            <Input
              label={<FormattedMessage id={'reportsPage.periodFrom'}/>}
              type={'date'}
              name={'startDate'}
              formSelectors={previewReportFormSelectors}
            />

            <SelectInput
              options={TIME_LIST}
              name={'startTime'}
              formSelectors={previewReportFormSelectors}
              styles={TIME_INPUT_SELECT_STYLES}
            />
          </div>

          <div className="report-preview__double-inputs">
            <Input
              label={<FormattedMessage id={'reportsPage.to'}/>}
              type={'date'}
              name={'endDate'}
              formSelectors={previewReportFormSelectors}
            />

            <SelectInput
              options={TIME_LIST}
              name={'endTime'}
              formSelectors={previewReportFormSelectors}
              styles={TIME_INPUT_SELECT_STYLES}
            />
          </div>
        </div>

        <button
          className="btn"
          onClick={handleSubmit}
          disabled={!!previewTaskId}
        >
          <FormattedMessage id={'app.create'}/>
        </button>
      </Loader>

      <a ref={previewFileLink} style={{display: 'none'}} download/>
    </div>
  )
}

export default ReportPreviewForm
