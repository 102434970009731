import {FormattedMessage, useIntl} from "react-intl";
import {mainPageSelectors} from "@/internal/lib/storeModels/models/mainPage/mainPageModel";

const SearchAddressInput = ({isHidden}) => {
  const intl = useIntl()
  const [query, setQuery] = mainPageSelectors.searchQuery.useState()

  const onChange = (e) => {
    const {value} = e.target

    setQuery(value)
  }

  return (
    <div className="form-group search-address-input" style={{opacity: isHidden ? 0 : 1, pointerEvents: isHidden ? 'none' : 'unset'}}>
      <label>
        <FormattedMessage id={'addressForm.findAddress'}/>
      </label>

      <input
        type={'text'}
        placeholder={intl.formatMessage({id: 'addressForm.enterAddress'})}
        value={query}
        onChange={onChange}
      />
    </div>
  )
}

export default SearchAddressInput
