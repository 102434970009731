import {styled} from "@mui/material/styles";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {SvgIcon} from "@mui/material";
import React from "react";

export const CalendarIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" sx={{
      fill: 'none',
    }}>
      <rect x="1" y="1.37305" width="16" height="16" rx="2.5" stroke="#BBC4D2"/>
      <rect x="3.5" y="8.87305" width="3" height="3" rx="1" fill="#BBC4D2"/>
      <rect x="3.5" y="12.873" width="3" height="3" rx="1" fill="#BBC4D2"/>
      <rect x="7.5" y="8.87305" width="3" height="3" rx="1" fill="#BBC4D2"/>
      <rect x="7.5" y="12.873" width="3" height="3" rx="1" fill="#BBC4D2"/>
      <rect x="11.5" y="8.87305" width="3" height="3" rx="1" fill="#BBC4D2"/>
      <rect x="7.5" y="4.87305" width="3" height="3" rx="1" fill="#BBC4D2"/>
      <rect x="1.5" y="1.87305" width="15" height="2" fill="#BBC4D2"/>
      <rect x="11.5" y="4.87305" width="3" height="3" rx="1" fill="#BBC4D2"/>
    </SvgIcon>
  )
}

export const CustomizedDatePicker = styled(DatePicker)(({theme}) => ({
  '.MuiOutlinedInput-root': {
    border: '1px solid #ECF0F6',
    borderRadius: theme.typography.pxToRem(6),
    width: '100%',
    '&.Mui-error': {
      border: '1px solid #FC4F6E',
    },
    '&.Mui-disabled': {
      background: 'rgba(239, 239, 239, 0.3)',
      color: '#36455D',
      WebkitTextFillColor: 'rgba(0, 0, 0, 1)',
      'input': {
        color: '#36455D',
        WebkitTextFillColor: 'rgba(0, 0, 0, 1)',
      }
    }
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '.MuiOutlinedInput-input': {
    border: 'none',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 20/14 * 100 + '%',
    fontWeight: 400,
    color: '#36455D',
    padding: '.86em .86em .57em'
  },
  '.MuiButtonBase-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    'svg': {
      width: '18px',
      height: '18px'
    }
  }
}))