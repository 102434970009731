import {
  DAY_SCHEDULE_TYPE,
  DAYS_LIST,
  DEVICE_REPORT_TYPE, METERING_POINT_REPORT_TYPE, MONTH_SCHEDULE_TYPE, NEW_REPORT_PATH,
  REPORTS_TYPES_LIST,
  RESOURCE_REPORT_TYPE,
  SCHEDULE_TYPES_LIST, WEEK_SCHEDULE_TYPE,
  WEEK_TYPES_LIST
} from "../const";
import {
  DETAILING_TYPES_LIST,
  DIMENSION_TYPES_LIST,
  PERIOD_LEFT_SELECT_STYLES,
  PERIOD_RIGHT_SELECT_STYLES,
} from "./const";
import CheckBoxHideForm from "./CheckBoxHideForm";
import React, {useEffect, useMemo, useState} from "react";
import {REPORT_INIT_DATA} from "@/internal/lib/storeModels/models/report/const";
import {UNITS_LIST} from "@/presentation/components/meteringPointForm/const";
import {RESOURCES_TYPES_ARRAY} from "../../intake/const";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "@/presentation/components/loader/Loader";
import {reportsEndpoint} from "@/data/api_entities/reports/reports_endpoint";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import ReportPreviewForm from "./ReportPreviewForm";
import ReportHistory from "./ReportHistory";
import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import SelectInput from "@/presentation/components/common/formComponents/SelectInput";
import Input from "@/presentation/components/common/formComponents/Input";
import MultiEmailsInputs from "@/presentation/components/common/MultiEmailsInputs";
import ReportSubjectTypeSelect from "./ReportSubjectTypeSelect";
import CheckBoxInput from "@/presentation/components/common/formComponents/CheckBoxInput";
import ReportDisplayTypeSelect from "./ReportDisplayTypeSelect";
import {FormattedMessage} from "react-intl";

const ReportForm = (
  {
    isNewReport = false,
    devicesList,
    formModelSelectors,
    meteringPoints,
    onValidSubmit,
    loading = false,
    onDelete,
    modalLoading,
    isOpenDeleteModal,
    toggleDeleteModal
  }) => {
  const {id} = useParams()
  const navigate = useNavigate()

  const reportType = formModelSelectors.useFormDataValue('type')
  const resourceType = formModelSelectors.useFormDataValue('resource_type')
  const scheduleType = formModelSelectors.useFormDataValue('scheduleType')
  const isActive = formModelSelectors.useFormDataValue('is_active')
  const deviceId = formModelSelectors.useFormDataValue('deviceId')
  const pointId = formModelSelectors.useFormDataValue('pointId')
  const isValidForm = formModelSelectors.useIsFormValid()
  const setOnValidSubmit = formModelSelectors.useSetOnValidSubmit()
  const setInitData = formModelSelectors.useSetInitFormData()
  const setFormDataValue = (name) => formModelSelectors.setFormDataValue(name)
  const onClickSubmit = formModelSelectors.useOnClickSubmit()

  const [lastGetReportDataRequest, setLastGetReportDataRequest] = useState(null)
  const [reportName, setReportName] = useState('')
  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const [isShowPreviewForm, setIsShowPreviewForm] = useState(false)

  const pathOnVisible = id ?? NEW_REPORT_PATH
  const title = id
    ? reportName
    : <FormattedMessage id={'reportsPage.customizeReport'}/>

  const isLoadingData = useMemo(() => {
    return isNewReport
      ? false
      : isFirstLoading || !!lastGetReportDataRequest
  }, [isNewReport, lastGetReportDataRequest, isFirstLoading])

  useEffect(() => {
    setOnValidSubmit(onValidSubmit)

    return () => {
      setIsShowPreviewForm(false)
    }
  }, [isNewReport])

  useEffect(() => {
    if (!id || isNewReport) return

    if (lastGetReportDataRequest) {
      lastGetReportDataRequest.abort()
    }

    const {promise, controller} = reportsEndpoint.getReportInfoById(id)

    setLastGetReportDataRequest(controller)

    promise
      .then(res => {
        setInitData(res)
        setReportName(res.name)
        setLastGetReportDataRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return

        setLastGetReportDataRequest(null)
        console.log(err)
      })
      .finally(() => {
        if (isFirstLoading) {
          setIsFirstLoading(false)
        }
      })
  }, [id, isNewReport])

  useEffect(() => {
    setFormDataValue('scheduleDay', 1)
  }, [scheduleType])

  const meteringPointsList = useMemo(() => {
    return meteringPoints
      .filter(point => point.type === resourceType)
      .map(point => {
        const type = RESOURCES_TYPES_ARRAY.find(resource => resource.type === point.type)
        const img = type ? type.img : null

        return (
          <div
            key={point.id}
            className={'report-form__point'}
          >
            <div className={'report-form__point-img'}>
              <img src={img} alt="resource"/>
            </div>

            <span>{point.name}</span>
          </div>
        )
    })
  }, [resourceType, meteringPoints])

  const isShowForm = useMemo(() => {
    return (reportType === DEVICE_REPORT_TYPE && !!deviceId) ||
      (reportType === METERING_POINT_REPORT_TYPE && !!pointId) ||
      (reportType === RESOURCE_REPORT_TYPE && !!resourceType)
  }, [reportType, deviceId, resourceType, pointId])

  const handleSubmit = () => {
    if (!isValidForm) return

    onClickSubmit()
  }

  return (
    <>
      <div className='report-form'>
        <ArrowBack
          pathOnVisible={pathOnVisible}
        />

        <Loader loading={loading || isLoadingData}>
          <h2 className="title">{title}</h2>

          <div className="form-fields report-form__main-form">
            <SelectInput
              label={<FormattedMessage id={'reportsPage.reportType'}/>}
              options={REPORTS_TYPES_LIST}
              name={'type'}
              formSelectors={formModelSelectors}
              withChangeValue={(value) => {
                setIsShowPreviewForm(false)
                setInitData({
                  ...REPORT_INIT_DATA,
                  type: value
                })
              }}
            />

            {!!reportType &&
              <>
                <ReportSubjectTypeSelect
                  formSelectors={formModelSelectors}
                  devicesList={devicesList}
                  meteringPoints={meteringPoints}
                />

                {isShowForm &&
                  <>
                    {!!resourceType &&
                      <div className={'report-form__match-points'}>
                        <p>
                          <FormattedMessage id={'reportsPage.meteringPointsInReport'}/>
                        </p>

                        <div className="report-form__points-list">
                          {meteringPointsList}
                        </div>
                      </div>
                    }

                    {
                      reportType === METERING_POINT_REPORT_TYPE &&
                      <>
                        <Input
                          label={<FormattedMessage id={'reportsPage.systemDevice'}/>}
                          name={'device_name'}
                          formSelectors={formModelSelectors}
                          disabled
                        />

                        <Input
                          label={<FormattedMessage id={'reportsPage.gizmoDevice'}/>}
                          name={'gizmo_name'}
                          formSelectors={formModelSelectors}
                          disabled
                        />
                      </>
                    }

                    <SelectInput
                      label={<FormattedMessage id={'reportsPage.detailing'}/>}
                      formSelectors={formModelSelectors}
                      name={'detailing'}
                      options={DETAILING_TYPES_LIST}
                    />

                    {reportType !== DEVICE_REPORT_TYPE &&
                      <>
                        <SelectInput
                          label={<FormattedMessage id={'reportsPage.unitsTypes'}/>}
                          formSelectors={formModelSelectors}
                          name={'unit_type'}
                          options={UNITS_LIST}
                        />

                        <SelectInput
                          label={<FormattedMessage id={'reportsPage.dimension'}/>}
                          formSelectors={formModelSelectors}
                          name={'dimension'}
                          options={DIMENSION_TYPES_LIST}
                        />
                      </>
                    }

                    <ReportDisplayTypeSelect
                      formSelectors={formModelSelectors}
                    />

                    <Input
                      label={<FormattedMessage id={'reportsPage.reportName'}/>}
                      name={'name'}
                      formSelectors={formModelSelectors}
                    />
                  </>
                }
              </>
            }
          </div>

          {!!reportType && isShowForm &&
            <>
              <h4 className="title_h4">
                <FormattedMessage id={'reportsPage.reportContents'}/>
              </h4>

              <div className="form-fields report-form__composition">
                <CheckBoxInput
                  label={<FormattedMessage id={'reportsPage.body'}/>}
                  name={'body'}
                  formSelectors={formModelSelectors}
                />

                <CheckBoxHideForm
                  label={<FormattedMessage id={'reportsPage.provider'}/>}
                  checkBoxName={'is_provider'}
                  inputNames={{
                    name: 'providerName',
                    bailee: 'providerBailee',
                    phone: 'providerPhone'
                  }}
                  formSelectors={formModelSelectors}
                />

                <CheckBoxHideForm
                  label={<FormattedMessage id={'reportsPage.consumer'}/>}
                  checkBoxName={'is_consumer'}
                  inputNames={{
                    name: 'consumerName',
                    bailee: 'consumerBailee',
                    phone: 'consumerPhone'
                  }}
                  formSelectors={formModelSelectors}
                />

                <CheckBoxHideForm
                  label={<FormattedMessage id={'reportsPage.serviceOrg'}/>}
                  checkBoxName={'is_service_org'}
                  customClass={'last'}
                  inputNames={{
                    name: 'serviceOrgName',
                    bailee: 'serviceOrgBailee',
                    phone: 'serviceOrgPhone'
                  }}
                  formSelectors={formModelSelectors}
                />

                <Input
                  label={<FormattedMessage id={'reportsPage.systemType'}/>}
                  name={'system_type'}
                  formSelectors={formModelSelectors}
                />

                {reportType === DEVICE_REPORT_TYPE &&
                  <>
                    <CheckBoxInput
                      label={<FormattedMessage id={'reportsPage.channelOne'}/>}
                      name={'channelOne'}
                      formSelectors={formModelSelectors}
                    />

                    <CheckBoxInput
                      label={<FormattedMessage id={'reportsPage.channelTwo'}/>}
                      name={'channelTwo'}
                      formSelectors={formModelSelectors}
                    />
                  </>
                }

                {reportType !== DEVICE_REPORT_TYPE &&
                  <>
                    <CheckBoxInput
                      label={<FormattedMessage id={'reportsPage.systemDevice'}/>}
                      name={'device'}
                      formSelectors={formModelSelectors}
                    />

                    <CheckBoxInput
                      label={<FormattedMessage id={'reportsPage.gizmoDevice'}/>}
                      name={'gizmo'}
                      formSelectors={formModelSelectors}
                    />
                  </>
                }

                <CheckBoxInput
                  label={<FormattedMessage id={'reportsPage.errors'}/>}
                  name={'errors'}
                  formSelectors={formModelSelectors}
                />
              </div>

              <button
                className={`btn btn_transparent-bg report-form__advance ${isShowPreviewForm ? 'active' : ''}`}
                onClick={() => setIsShowPreviewForm(!isShowPreviewForm)}
              >
                <FormattedMessage id={'reportsPage.preview'}/>
              </button>

              <h4 className="title_h4">
                <FormattedMessage id={'reportsPage.regularDispatch'}/>
              </h4>

              <div className="form-fields report-form__sending">
                <CheckBoxInput
                  label={<FormattedMessage id={'reportsPage.sendRegular'}/>}
                  name={'is_active'}
                  formSelectors={formModelSelectors}
                />

                {isActive &&
                  <>
                    <div className="report-form__double-inputs">
                      <SelectInput
                        groupClass={scheduleType === DAY_SCHEDULE_TYPE ? 'full-w' : ''}
                        styles={scheduleType === DAY_SCHEDULE_TYPE ? {} : PERIOD_LEFT_SELECT_STYLES}
                        name={'scheduleType'}
                        options={SCHEDULE_TYPES_LIST}
                        formSelectors={formModelSelectors}
                      />

                      {scheduleType === MONTH_SCHEDULE_TYPE &&
                        <SelectInput
                          styles={PERIOD_RIGHT_SELECT_STYLES}
                          name={'scheduleDay'}
                          options={DAYS_LIST}
                          formSelectors={formModelSelectors}
                        />
                      }

                      {scheduleType === WEEK_SCHEDULE_TYPE &&
                        <SelectInput
                          styles={PERIOD_RIGHT_SELECT_STYLES}
                          name={'scheduleDay'}
                          options={WEEK_TYPES_LIST}
                          formSelectors={formModelSelectors}
                        />
                      }
                    </div>

                    <div className="report-form__schedule-ex">
                      <span>
                        <FormattedMessage id={'reportsPage.periodExample'}/>
                        <br/>
                        <FormattedMessage id={'reportsPage.example'}/>
                      </span>
                    </div>

                    <span className={'report-form__send-email'}>
                      <FormattedMessage id={'reportsPage.sendEmail'}/>
                    </span>

                    <MultiEmailsInputs 
                      name={'receivers'}
                      formSelectors={formModelSelectors}
                      classNames={'report-form__multi-emails'}
                    />

                    <div className="report-form__next-update">
                      <p>
                        <FormattedMessage id={'reportsPage.inNextUpdates'}/>
                      </p>
                    </div>
                  </>
                }
              </div>

              <div className="report-form__btns-wrapper">
                <button
                  className="btn"
                  onClick={handleSubmit}
                  disabled={!isValidForm}
                >
                  <FormattedMessage id={'app.save'}/>
                </button>

                {isNewReport
                  ? <button
                    className="btn btn_transparent-bg"
                    onClick={() => navigate('report')}
                  >
                    <FormattedMessage id={'app.notSave'}/>
                  </button>
                  : <button
                    className="btn btn_transparent-bg btn_red"
                    onClick={toggleDeleteModal}
                  >
                    <FormattedMessage id={'app.delete'}/>
                  </button>
                }
              </div>
            </>
          }
        </Loader>

        <DefaultModal
          isOpen={isOpenDeleteModal}
          headerTitle={<FormattedMessage id={'reportsPage.deleteReport?'}/>}
          agreeBtnConfig={{
            className: 'btn btn_red',
            text: <FormattedMessage id={'app.delete'}/>
          }}
          toggleModal={toggleDeleteModal}
          onDisagree={toggleDeleteModal}
          onAgree={() => onDelete(id)}
          loading={modalLoading}
        />
      </div>

      {isNewReport
        ? isShowPreviewForm
          ? <div className={'reports-page__column'}>
            <ReportPreviewForm reportFormSelectors={formModelSelectors}/>
          </div>
          : null
        : <ReportHistory reportFormSelectors={formModelSelectors}>
          {isShowPreviewForm &&
            <div className={'report-history__preview-wrapper'}>
              <ReportPreviewForm reportFormSelectors={formModelSelectors}/>
            </div>
          }
        </ReportHistory>
      }
    </>
  )
}

export default ReportForm
