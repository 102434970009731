import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc)

export const phoneFormatter = (value) => {
  const reg = /[^+\d]/g

  return value.replace(reg, '')
}

export const addressFormatter = (address) => {
  if (!address) return 'address is missing'

  const apartment = address.apartment != null && address.apartment.length !== 0
    ? `,  кв ${address.apartment}`
    : ""

  return `${address.street} ${address.building}${apartment}`
}

const addedZero = (data) => {
  return data >= 10 ? data : `0${data}`
}

export const dateFormatter = (dateString, withoutTime = false) => {
  if (!dateString) return

  const date = new Date(dateString)
  const month = addedZero(date.getMonth() + 1)
  const day = addedZero(date.getDate())
  const hours = addedZero(date.getUTCHours())
  const minutes = addedZero(date.getMinutes())

  return withoutTime
    ? `${day}.${month}.${date.getFullYear()}`
    : `${hours}:${minutes} ${day}.${month}.${date.getFullYear()}`
}

export const sumFormatter = (sum) => {
  const sumWithSign = sum > 0 ? `+${sum}` : `${sum}`
  const fractionalSum = sum % 1 === 0
    ? `${sumWithSign},0`
    : sumWithSign.replace('.', ',')

  return fractionalSum
}

export const formattedDateToServ = (dateString) => {
  if (!dateString) return

  const date = new Date(dateString)
  const yyyy = date.getFullYear()
  let mm = date.getMonth() + 1
  let dd = date.getDate()

  if (dd < 10) dd = '0' + dd
  if (mm < 10) mm = '0' + mm

  return `${yyyy}-${mm}-${dd}`
}

export const formattedDateToServFirstDay = (dateString) => {
  if (!dateString) return

  const date = new Date(dateString)
  const yyyy = date.getFullYear()
  let mm = date.getMonth() + 1

  if (mm < 10) mm = '0' + mm

  return `${yyyy}-${mm}-01`
}

export const formattedDateToServLastDay = (dateString) => {
  if (!dateString) return
  const date = new Date(dateString)
  const yyyy = date.getFullYear()
  let mm = date.getMonth() + 1
  const nextMonth = new Date(yyyy, mm, 1)
  nextMonth.setDate(nextMonth.getDate() - 1)
  const lastDay = nextMonth.getDate()
  const lastDayString = lastDay < 10 ? '0' + lastDay : lastDay

  if (mm < 10) mm = '0' + mm

  return `${yyyy}-${mm}-${lastDayString}`
}

export const currencyFormatter = (value) => {
  return new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}).format(value)
}

export const capitalizeFirstLetter = (string) => (string.charAt(0).toUpperCase() + string.slice(1))
