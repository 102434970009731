import {
  DISPLAY_MODE_LIST, getFullMontNameByShort, getMonthNameByDate, getShortMontName,
  MODE_LIST,
  MONTHS_LIST,
  YEARS_LIST
} from "@/internal/content/consts";
import {intakeSelectors} from "@/internal/lib/storeModels/models/intake/intakeModels";
import SelectInput from "@/presentation/components/common/formComponents/SelectInput";
import {sampleParamsFormSelectors} from "@/internal/lib/storeModels/models/intake/forms/sampleParamsFormModel";
import {useEffect} from "react";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import {FormattedMessage, useIntl} from "react-intl";
import {appSelectors} from "@/internal/lib/storeModels/models/app/appModels";

const ChartSettings = ({getSampleMeterings}) => {
  const intl = useIntl()
  const selectedAddress = addressesSelectors.selectedAddress.useValue()
  const formData = sampleParamsFormSelectors.useFormData()
  const setPointValue = sampleParamsFormSelectors.useSetFormDataValue('pointValue')
  const setDetailedChartName = intakeSelectors.detailedChartName.useSetValue()
  const locale = appSelectors.locale.useValue()

  useEffect(() => {
    getSampleMeterings(formData)
  }, [formData.year, formData.pointValue, formData.pointsIds, formData.mode, formData.month, selectedAddress?.id])

  useEffect(() => {
    if (formData.displayMode !== 1) return

    setPointValue(1)
  }, [formData.month])

  useEffect(() => {
    switch (formData.displayMode) {
      case 1:
        setPointValue(1)
        break
      case 2:
        setPointValue(getShortMontName(intl, formData.month))
        break
      case 3:
        setPointValue(formData.year)
        break
      default:
        setPointValue(1)
    }
  }, [formData.displayMode, locale])

  useEffect(() => {
    switch (formData.displayMode) {
      case 1:
        setDetailedChartName(`${formData.pointValue} ${getMonthNameByDate(intl, formData.month, 2)}`)
        break
      case 2:
        const name = getFullMontNameByShort(intl, formData.pointValue)
        setDetailedChartName(name)
        break
      case 3:
        setDetailedChartName(formData.pointValue)
        break
      default:
        setDetailedChartName('')
        break
    }
  }, [formData.pointValue, formData.month, locale])

  return (
    <div className="intake__chart-settings">
      <SelectInput
        formSelectors={sampleParamsFormSelectors}
        options={MODE_LIST}
        name={'mode'}
        label={<FormattedMessage id={'intake.mode'}/>}
      />

      <SelectInput
        label={<FormattedMessage id={'intake.display'}/>}
        formSelectors={sampleParamsFormSelectors}
        name={'displayMode'}
        options={DISPLAY_MODE_LIST}
        disabled={!!formData.mode}
      />

      <SelectInput
        label={<FormattedMessage id={'intake.year'}/>}
        formSelectors={sampleParamsFormSelectors}
        name={'year'}
        options={YEARS_LIST}
        disabled={formData.displayMode === 3 || formData.mode}
      />

      <SelectInput
        label={<FormattedMessage id={'intake.month'}/>}
        formSelectors={sampleParamsFormSelectors}
        name={'month'}
        options={MONTHS_LIST}
        disabled={formData.displayMode === 2 || formData.displayMode === 3 || formData.mode}
      />
    </div>
  )
}

export default ChartSettings
