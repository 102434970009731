import {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import Analytics from "@/presentation/components/analytics/Analytics";
import ObjectsList from "@/presentation/components/mainPage/components/ObjectsList";
import {selectedUserSelectors} from "@/internal/lib/storeModels/models/servicemen/selectedUserModel";

const UserSpace = ({lastGetInfoRequest, getInfo}) => {
  const selectedUser = selectedUserSelectors.useValue()

  useEffect(() => {
    if (!selectedUser) return

    getInfo()
  }, [selectedUser])

  return (
    <div className="user-space">
      <Routes>
        <Route
          path={'/analytics/*'}
          element={<Analytics isServicemen={true} getInfo={getInfo} isLoading={!!lastGetInfoRequest}/>}
        />

        <Route
          path={'/objects/'}
          element={<ObjectsList isServicemen={true} isLoading={!!lastGetInfoRequest}/>}
        />
      </Routes>
    </div>
  )
}

export default UserSpace
