import {createEvent, createStore} from "effector";
import {useEvent, useStore} from "effector-react";

export default class EnumeratorsValuesStateFabric {
  constructor(
    {
      enumerators
    }) {
    const dataTemplate = {}

    enumerators.forEach(enumerator => {
      dataTemplate[enumerator.id] = {
        indication_id: enumerator.indications.id,
        start: enumerator.indications.start_period,
        end: enumerator.indications.end_period,
      }
    })
    this.$data = createStore(dataTemplate)
    this.setValue = createEvent()

    this.$data
      .on(this.setValue, (state, payload) => {
        return {
          ...state,
          [payload.enumeratorId]: {
            ...state[payload.enumeratorId],
            [payload.key]: payload.value
          }
        }
      })
  }

  createSelectors() {
    return {
      useData: () => useStore(this.$data),
      useCurrentEnumeratorData: (id) => useStore(this.$data)[id],
      useSetEnumeratorData: () => useEvent(this.setValue),
      getData: this.$data.getState
    }
  }
}