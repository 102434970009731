import AuthorizationLayout from "./AuthorizationLayout";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import { Letter } from "@/assets/svg"
import {authorizationEndpoint} from "@/data/api_entities/authorization/authorization_endpoint";
import Loader from "@/presentation/components/loader/Loader";
import {cloneDeep} from "lodash";
import cookiesManager from "@/internal/lib/services/cookiManager";
import {NOT_FOUND_EMAIL} from "@/internal/validators/validationsMessages";
import {selectors} from "@/presentation/shared/ui/message";
import {recoveryPasswordSelectors} from "@/internal/lib/storeModels/models/authorization/forms/recoveryPasswordModels";
import {authorizationSelectors} from "@/internal/lib/storeModels/models/authorization/authorizationModels";
import {FormattedMessage} from "react-intl";

const ConfirmationLetter = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [recoveryEmail, setRecoveryEmail, resetRecoveryEmail] = authorizationSelectors.recoveryEmail.useState()
  const confirmRegistrationData= authorizationSelectors.registrationData.useValue()
  const resetRegistrationData = authorizationSelectors.registrationData.useReset()
  const setIsRecoveryPasswordLoading = authorizationSelectors.isRecoveryPasswordLoading.useValue()
  const setIncorrectRecoveryPasswordMsg = authorizationSelectors.incorrectRecoveryPasswordMsg.useSetValue()
  const recoveryData = recoveryPasswordSelectors.useFormData()
  const setMessage = selectors.useSetMessage()

  const [isConfirm, setIsConfirm] = useState(false)
  const [resendTimerCount, setResendTimerCount] = useState(59)
  const [relocateTimeout, setRelocateTimeout] = useState(null)
  const [isLoadingResend, setIsLoadingResend] = useState(false)
  const [isChecking, setIsChecking] = useState(false)
  const [isWrongCode, setIsWrongCode] = useState(false)

  const locationPathParts = useMemo(() => {
    return location.pathname.split('/').filter(item => !!item)
  }, [location])

  useEffect(() => {
    if (locationPathParts.includes('recovery_password') || locationPathParts.includes('confirm_registration')) return

    setIsChecking(true)
    setIsConfirm(true)

    authorizationEndpoint.checkConfirmationCode(locationPathParts[1])
      .then(res => {
        if (res.token) {
          cookiesManager.set('X-pulseId', res.user.id)
          cookiesManager.set('X-pulseAvatar', res.user.avatar)
          cookiesManager.set('X-pulseEmail', res.user.email)
          cookiesManager.set('X-pulseFirstName', res.user.first_name)
          cookiesManager.set('X-pulseLastName', res.user.last_name)
          cookiesManager.set('X-pulse', res.token)

          resetRegistrationData()
          resetRecoveryEmail()

          const timeout = setTimeout(() => navigate('/user'), 2000)
          setRelocateTimeout(timeout)
        } else {
          setIsWrongCode(true)

          const timeout = setTimeout(() => navigate('/input'), 4000)
          setRelocateTimeout(timeout)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setIsChecking(false))
  }, [])


  useEffect(() => {
    if (locationPathParts.length === 1 ||
      (locationPathParts[1] === 'recovery_password' && !recoveryEmail) ||
      (locationPathParts[1] === 'confirm_registration' && !confirmRegistrationData.email)
    ) {
      navigate('/input')
    }

    return () => {
      if (!relocateTimeout) return

      clearTimeout(relocateTimeout)
    }
  }, [])

  const title = useMemo(() => {
    return locationPathParts[1] === 'recovery_password'
      ? <FormattedMessage id={'authorization.passwordReset'} />
      : isWrongCode
        ? <FormattedMessage id={'authorization.error'} />
        : <FormattedMessage id={'authorization.youRegistered'} />
  }, [locationPathParts, isWrongCode])

  const email = useMemo(() => {
    return locationPathParts[1] === 'recovery_password'
      ? recoveryEmail
      : confirmRegistrationData.email
  }, [locationPathParts, recoveryEmail, confirmRegistrationData.email])

  const subTitle = useMemo(() => {
    return locationPathParts[1] === 'recovery_password'
      ? <FormattedMessage id={'authorization.confirmPasswordReset'} />
      : locationPathParts[1] === 'confirm_registration'
        ? <FormattedMessage id={'authorization.confirmEmail'} />
        : isWrongCode
          ? <FormattedMessage id={'authorization.invalidLink'} />
          : <FormattedMessage id={'authorization.emailConfirmed'} />
  }, [locationPathParts, isWrongCode])

  const resendTimerCountWithZero = useMemo(() => {
    return resendTimerCount > 9
      ? String(resendTimerCount)
      : `0${resendTimerCount}`
  }, [resendTimerCount])

  useEffect(() => {
    if (resendTimerCount < 1) return

    setTimeout(() => {
      setResendTimerCount(resendTimerCount - 1)
    }, 1000)
  }, [resendTimerCount])

  const resendLetter = () => {
    if (locationPathParts[1] === 'recovery_password') {
      setIsRecoveryPasswordLoading(true)

      authorizationEndpoint.sendResetPasswordMail(recoveryData)
        .then(res => {
          if (res.code === 440) {
            setIncorrectRecoveryPasswordMsg(NOT_FOUND_EMAIL)
          } else if (res.code === 200) {
            setRecoveryEmail(recoveryData.email)
            setResendTimerCount(59)

            navigate('/email_approve/recovery_password')
          } else {
            console.log(res)
            setMessage({
              message: <FormattedMessage id={'requests.confirmMailError'} />,
              header: <FormattedMessage id={'requests.error'} />,
              type: 'error',
              count: 3000
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => setIsRecoveryPasswordLoading(false))
    } else {
      setIsLoadingResend(true)
      const cloneData = cloneDeep(confirmRegistrationData)

      cloneData.resend_email = true

      authorizationEndpoint.registerNewUser(cloneData)
        .then((res) => {
          if (res.code === 200) {
            setResendTimerCount(59)
          } else {
            console.log(res)
            setMessage({
              message: <FormattedMessage id={'requests.confirmMailError'} />,
              header: <FormattedMessage id={'requests.error'} />,
              type: 'error',
              count: 3000
            })
          }
        })
        .catch(err => {
          console.log(err)
          setMessage({
            message: <FormattedMessage id={'requests.confirmMailError'} />,
            header: <FormattedMessage id={'requests.error'} />,
            type: 'error',
            count: 3000
          })
        })
        .finally(() => setIsLoadingResend(false))
    }
  }

  return (
    <AuthorizationLayout
      title={title}
      customClass="confirm"
      footerAvailable={!isConfirm}
      demoAvailable={!isConfirm}
    >
      <Loader
        loading={isChecking}
        styles={{
          wrapper: (base) => ({
            ...base,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          })
        }}
      >
        <div className="confirm__img">
          <img src={Letter} alt="letter" />
        </div>

        <p className="confirm__bold-p">{subTitle}</p>

        {!isConfirm && !isWrongCode &&
          <>
            <p
              className="confirm__p"
            >
              <FormattedMessage
                id={'authorization.weSendMail'}
                values={{email}}
              />
            </p>

            <a href={`mailto:${email}`} className={'btn'}>
              <FormattedMessage id={'authorization.checkMail'} />
            </a>

            {isLoadingResend
              ? (
                <span className={'authorization-layout__resend'}>
                  <FormattedMessage id={'authorization.sending'} />
                </span>
              )
              : !!resendTimerCount
                ? (
                  <span className={'authorization-layout__timeout'}>
                    <FormattedMessage
                      id={'authorization.sent'}
                      values={{
                        time: resendTimerCountWithZero
                      }}
                    />
                  </span>
                )
                : (
                  <span
                    className={'authorization-layout__resend'}
                    onClick={resendLetter}
                  >
                    <FormattedMessage id={'authorization.sendAgain'} />
                  </span>
                )
            }
          </>
        }
      </Loader>
    </AuthorizationLayout>
  )
}

export default ConfirmationLetter
