import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'app'
const translations = {
  delete: 'Löschen',
  cancel: 'Absagen',
  'e-mail': 'E-mail',
  password: 'Passwort',
  save: 'Speichern',
  proceed: 'Fortfahren',
  sendAgain: 'Erneut senden',
  senAgainByTime: 'Gesendet. Wiederholung nach 00:{time}',
  change: 'Veränderung',
  sectionDeveloped: 'Abschnitt in entwicklung',
  ok: 'OK',
  use: 'Verwenden',
  noData: 'Keine daten',
  notSet: 'Nicht festgelegt',
  confirm: 'Ich bestätige',
  back: 'Zurück',
  notSpecified: 'Unbestimmt',
  accept: 'Bestätigen',
  topUp: 'Nachfüllen',
  send: 'Senden',
  addMail: 'E-mail hinzufügen',
  add: 'Hinzufügen',
  nothingFound: 'Nichts gefunden',
  customize: 'Anpassen',
  onPage: 'Auf der Seite',
  countOf: '{from}-{to} aus {count}',
  moreThen: 'mehr als {to}',
  mon: 'Mo',
  tue: 'Di',
  wed: 'Mi',
  thu: 'Do',
  fri: 'Fr',
  sat: 'Sa',
  sun: 'So',
  byHour: 'Wächter',
  byDay: 'Pro tag',
  byMonth: 'Zeitraum',
  byGrand: 'Finale',
  notSave: 'Rette nicht',
  download: 'Herunterladen',
  create: 'Schaffen',
  addNew: '+ Neu hinzufügen',
  jan: 'jan',
  feb: 'feb',
  mar: 'märz',
  apr: 'apr',
  may: 'mai',
  june: 'juni',
  july: 'juli',
  aug: 'aug',
  sept: 'sept',
  oct: 'okt',
  nov: 'nov',
  dec: 'dez',
  january: 'Januar',
  february: 'Februar',
  march: 'März',
  april: 'April',
  mayFull: 'Mai',
  juneFull: 'Juni',
  julyFull: 'Juli',
  august: 'August',
  september: 'September',
  october: 'Oktober',
  november: 'November',
  december: 'Dezember',
  prepareSystem: 'System vorbereiten...',
  ggcal: 'Gcal',
  gj: 'GJ',
  kwh: 'kWh',
  mCube: 'm<sup>3</sup>',
  mCubeGas: 'm<sup>3</sup>·'+' nat gas',
  firstPg: 'ersten',
  lastPg: 'letzten',
  nextPg: 'näch.',
  prevPg: 'vorh.',
  pageControl: 'Zur {type} Seite wechseln'
}

export const appDe = createTranslationsWithSpaceName(translations, spaceName)
