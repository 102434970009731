import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'addressForm'
const translations = {
  newObject: 'Новый объект учета',
  objectParams: 'Параметры объекта',
  createFrom: 'Создать на основе другого объекта',
  objectType: 'Тип объекта',
  residents: 'Количество жильцов',
  findAddress: 'Найти объект',
  enterAddress: 'Введите адрес или название',
  region: 'Регион / Район',
  city: 'Город / Населенный пункт',
  street: 'Улица',
  building: 'Дом',
  apartment: 'Квартира/Офис',
  objectName: 'Название объекта',
  myHouse: 'Мой дом',
  forAnalytic: 'Для аналитики',
  tariff: 'Тариф, {currency}/{unit}',
  area: 'Площадь м.кв.',
  lat: 'Широта',
  lon: 'Долгота',
  'deleteObject?': 'Удалить объект учета?',
  savingObject: 'Сохранение объекта',
  removeObject: 'Удаление объекта',
  preExistObject: 'Объект с данным адресом или названием уже {link}.',
  exists: 'существует',
  changeToOrUse: 'Измените адрес текущего объекта или используйте уже созданный.',
  objectWithDevices: 'На данном объекте установлены устройства. {link} их к другому объекту, чтобы сохранить доступ.',
  assign: 'Привяжите',
  forCompare: 'Для сравнения потребления',
  unitTypeReport: 'Единица измерения в отчете',
  unitType: 'Единица измерения на объекте',
  changesHistory: 'история изменений',
  areaChanges: 'Изменения площади',
  tariffsChanges: 'Изменения тарифа',
  areaValue: '{value} м<sup>2</sup>',
  tillDate: 'до {date}'
}

export const addressFormRu = createTranslationsWithSpaceName(translations, spaceName)
