import { DayPicker, Button, useDayRender } from 'react-day-picker';
import { useEffect, useMemo, useRef, useState} from "react";
import 'react-day-picker/dist/style.css';
import dayjs from "dayjs";
import utc  from 'dayjs/plugin/utc'
import {formattedDateToServ} from "@/internal/formatters/formatters";
import {cloneDeep, isEqual} from "lodash";
import ru from 'date-fns/locale/ru'
import en from 'date-fns/locale/en-US'
import de from 'date-fns/locale/de'
import {
  hoveredDatesSelectors,
} from "@/internal/lib/storeModels/models/durationsDatePicker/durationsDatePicker";
import {Popover} from "@mui/material";
import {appSelectors} from "@/internal/lib/storeModels/models/app/appModels";
import {LOCALES} from "@/i18n/locales";

dayjs.extend(utc)

const CustomButton = (props) => {
  const buttonRef = useRef(null)
  const dayRender = useDayRender(props.date, props.displayMonth, buttonRef)
  const hoveredDates = hoveredDatesSelectors.useValue()

  if (dayRender.isHidden) {
    return <></>;
  }
  if (!dayRender.isButton) {
    return <div {...dayRender.divProps} />;
  }

  return (
    <Button
      {...dayRender.buttonProps}
      ref={buttonRef}
      style={{
        background: hoveredDates.includes(dayjs(props.date).date()) ? '#e7edff' : '',
      }}
    />
  )
}

const DurationsDayPicker = ({toDate, fromDate, values, onSelectDates, handleBlur, handleClickInput}) => {
  const [ranges, setRanges] = useState(() => setInitRanges(values))
  const [startRange, setStartRange] = useState(null)
  const [endRange, setEndRange] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const setHoveredDates = hoveredDatesSelectors.useSetValue()
  const resetHoveredDates = hoveredDatesSelectors.useReset()
  const locale = appSelectors.locale.useValue()

  const datePickerLocale = useMemo(() => {
    switch (locale) {
      case LOCALES.ENGLISH:
        return en
      case LOCALES.RUSSIAN:
        return ru
      case LOCALES.GERMAN:
        return de
      default:
        return en
    }
  }, [locale])

  useEffect(() => {
    if (endRange) resetHoveredDates()
  }, [endRange])

  useEffect(() => {
    if (startRange && endRange) {
      const dateRange = []
      let iterableDate = dayjs(startRange).utc()
      const start = dayjs(startRange).date()
      const end = dayjs(endRange).date()

      for (let i = start; i <= end; i++) {
        dateRange.push(formattedDateToServ(iterableDate))

        iterableDate = iterableDate.date(iterableDate.date() + 1)
      }

      const newRanges = [...ranges]
      newRanges.push({
        startRange,
        endRange,
        dates: dateRange
      })

      setRanges(newRanges)
      setEndRange(null)
      setStartRange(null)
    }
  }, [startRange, endRange])

  function setInitRanges(startValues) {
    const startRanges = []
    let startDate = startValues[0] ? dayjs(startValues[0]).$d : null
    let endDate = null
    let startIndex = 0

    startValues.forEach((value, i) => {
      const dateDay = dayjs(value).date()
      const nextDateDay = i === startValues.length - 1
        ? dateDay
        : dayjs(startValues[i + 1]).date()

      if (dateDay === nextDateDay - 1) return

      endDate = dayjs(value).$d

      startRanges.push({
        startRange: startDate,
        endRange: endDate,
        dates: startValues.slice(startIndex, i + 1)
      })

      startDate = dayjs(startValues[i + 1]).$d
      startIndex = i + 1
    })

    return startRanges
  }

  const mergeRanges = (ranges) => {
    const cloneRanges = cloneDeep(ranges)

    if (cloneRanges.length < 2) return ranges

    cloneRanges.sort((aRange, bRange) => {
      const a = new Date(aRange.dates[0]).getTime()
      const b = new Date(bRange.dates[0]).getTime()

      return a - b
    })

    const result = []
    const mergedIndexes = []

    cloneRanges.forEach((range, i) => {
      if (mergedIndexes.includes(i)) return

      if (i === cloneRanges.length - 1) {
        result.push(range)

        return;
      }
      const timeDiffer = new Date(range.endRange).getTime() - (new Date(cloneRanges[i + 1].startRange).getTime() - 24 * 60 * 60 * 1000)
      if (timeDiffer >= 0) {
        mergedIndexes.push(i + 1)
        const startDates = [...range.dates]
        const endDates = [...cloneRanges[i + 1].dates]
        const newDatesObj = {}

        startDates.concat(endDates).forEach(item => newDatesObj[item] = true)

        result.push({
          startRange: range.startRange,
          endRange: timeDiffer > 0 ? range.endRange : cloneRanges[i + 1].endRange,
          dates: Object.keys(newDatesObj)
        })
      } else {
        result.push(range)
      }
    })

    if (mergedIndexes.length) {
      return mergeRanges(result)
    } else {
      return result
    }
  }

  useEffect(() => {
    const dataByRanges = ranges.map(range => {
      return range.dates
    }).flat()

    onSelectDates(dataByRanges)

    const newRanges = mergeRanges(ranges)
    if (isEqual(ranges, newRanges)) return

    setRanges(newRanges)
  }, [ranges])

  const dates = useMemo(() => {
    return values.map(date => dayjs(date).$d)
  }, [values])

  const handleDayClick = (date) => {
    const currentRange = ranges.find(range => {
      return range.dates.includes(formattedDateToServ(date))
    })

    if (currentRange) {
      const newRanges = ranges.filter(range => !isEqual(range, currentRange) )
      setRanges(newRanges)
    } else {
      if (startRange) {
        if (new Date(date).getTime() < new Date(startRange).getTime()) {
          setStartRange(date)
          setEndRange(startRange)
        } else {
          setEndRange(date)
        }
      } else {
        setStartRange(date)
      }
    }
  }

  const handleDayMouseEnter = (date) => {
    if (!startRange) return resetHoveredDates()
    const hoveredArr = []
    let iterableDate = dayjs(startRange)

    if (new Date(date).getTime() < new Date(startRange).getTime()) {
      const end = dayjs(startRange).date()
      const start = dayjs(date).date()

      for (let i = start; i <= end; i++) {
        hoveredArr.push(iterableDate.$D)

        iterableDate = iterableDate.date(iterableDate.date() - 1)
      }
    } else {
      const start = dayjs(startRange).date()
      const end = dayjs(date).date()

      for (let i = start; i <= end; i++) {
        hoveredArr.push(iterableDate.$D)

        iterableDate = iterableDate.date(iterableDate.date() + 1)
      }
    }

    setHoveredDates(hoveredArr)
  }


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    handleClickInput()
  }

  const handleClose = () => {
    setAnchorEl(null)
    handleBlur()
    setStartRange(null)
    setEndRange(null)
  }

  const formattedSelectedDates = useMemo(() => {
    const daysArray = values.map(item => {
      const date = new Date(item)

      return +date.getDate()
    }).sort((a, b) => a - b)

    let emptyArr = []

    const intervals = daysArray
      .reduce((prev, current, i, arr) => {
        emptyArr.push(current)

        if (arr[i] + 1 !== arr[i + 1]) {
          prev.push(emptyArr)
          emptyArr = []
        }

        return prev
      }, [])
      .map(interval => {
        if (interval.length < 2) {
          return `${interval[0]}`
        } else {
          return `${interval[0]}—${interval[interval.length - 1]}`
        }
      })

    return intervals.join(', ')
  }, [values])

  return (
    <>
      <input
        value={formattedSelectedDates}
        onClick={handleClick}
        readOnly
      />

      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        <DayPicker
          locale={datePickerLocale}
          mode="multiple"
          selected={dates}
          components={{
            Day: CustomButton
          }}
          onDayClick={handleDayClick}
          onDayMouseEnter={handleDayMouseEnter}
          onSelect={() => {}}
          toDate={toDate}
          fromDate={fromDate}
          footer={
            <div className={'durations-day-picker__clear-btn'}>
              <button onClick={() => setRanges([])}>Очистить</button>
            </div>
          }
        />
      </Popover>
    </>
  )
}

export default DurationsDayPicker
