import React, {useEffect, useState} from "react";
import {PageReturn, ReverseArrow} from "@/assets/svg";
import {addressEndpoint} from "@/data/api_entities/address/address_endpoint";
import {getDeviceInfoByType} from "@/internal/helpers/helpers";
import {CHANNELS_LIST, RESOURCES_LIST, UNITS_LIST} from "./const";
import {useParams} from "react-router-dom";
import {meterointsEndpoint} from "@/data/api_entities/meteroints/meteroints_endpoint";
import Loader from "../loader/Loader";
import ArrowBack from "../common/ArrowBack";
import {selectors} from "@/presentation/shared/ui/message";
import DefaultModal from "../common/modals/DefaultModal";
import ValidationsWrapper from "../common/formComponents/ValidationsWrapper";
import Input from "../common/formComponents/Input";
import SelectInput from "../common/formComponents/SelectInput";
import {FormattedMessage} from "react-intl";
import {RIGHT_SELECT_WITH_INPUT} from "@/presentation/components/common/selects/const";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import Constants from "@/presentation/components/meteringPointsPage/constants";
import DevicesTypes from "@/presentation/components/devicesPage/devices_types";

const MeteringPointForm = (
  {
    customClass = '',
    formModelSelectors,
    onValidSubmit,
    isNew = false,
    handleDelete,
    hideAside,
    fromStatus = false,
    loading = false,
    pathWithId = false,
    backCount = 1,
    isOpenDeleteModal,
    toggleDeleteModal
  }
) => {
  const {idP} = useParams()

  const [devices, setDevices] = useState([])
  const [loadingDevices, setLoadingDevices] = useState(true)
  const [lastGetMeterointInfoRequest, setLastGetMeterointInfoRequest] = useState(null)
  const [isFirstLoading, setIsFirstLoading] = useState(true)

  const formValidations = formModelSelectors.useFormValidations()
  const isValidForm = formModelSelectors.useIsFormValid()
  const setInitData = formModelSelectors.useSetInitFormData()
  const setPreviousFormData = formModelSelectors.useSetPreviousData()
  const setOnValidSubmit = formModelSelectors.useSetOnValidSubmit()
  const onClickSubmit = formModelSelectors.useOnClickSubmit()
  const setMessage = selectors.useSetMessage()
  const selectedAddress = addressesSelectors.selectedAddress.useValue()

  const pathOnVisible = isNew ? Constants.newPointPath : idP;

  useEffect(() => {
    return () => {
      setIsFirstLoading(!isNew)
    }
  }, [isNew])

  useEffect(() => {
    if (isNew || !idP) return

    if (lastGetMeterointInfoRequest) {
      lastGetMeterointInfoRequest.abort()
    }

    const {promise, controller} = meterointsEndpoint.getMeteriontById(+idP)

    setLastGetMeterointInfoRequest(controller)

    promise
      .then(res => {
        setInitData(res)
        setPreviousFormData(res)

        setLastGetMeterointInfoRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return

        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.loadMeteringPointError'}/>,
          type: 'error',
          count: 3000
        })
        setLastGetMeterointInfoRequest(null)
        console.log(err)
      })
      .finally(() => {
        if (isFirstLoading) setIsFirstLoading(false)
      })
  }, [idP])

  useEffect(() => {
    setOnValidSubmit(onValidSubmit)
  }, [onValidSubmit])

  useEffect(() => {
    if (!selectedAddress.id) return

    addressEndpoint.getDevices(selectedAddress.id).promise
      .then(res => {
        if (!res.devices) return

        setDevices(res.devices)
      })
      .catch(err => console.log(err))
      .finally(() => setLoadingDevices(false))
  }, [selectedAddress])

  const devicesArray = devices
    ? devices.filter(device => !DevicesTypes.allTransferDevices.includes(device.type)).map(device => {
        return {
          value: device.id,
          label: (
            <>
              {getDeviceInfoByType(device.type).name} {device.serial}
            </>
          )
        }
      })
    : []

  const devicesList = [
    {
      value: 0,
      label: <FormattedMessage id={'app.notSet'}/>
    },
    ...devicesArray
  ]

  const handleSubmit = () => {
    if (!isValidForm) return

    onClickSubmit()
  }

  return (
    <div className={`metring-point-form ${customClass}`}>
      <ArrowBack
        pathOnVisible={pathOnVisible}
        withId={pathWithId}
        backCount={backCount}
      />

      <div className="metring-point-form__title-wrapper">
        {fromStatus &&
          <div className='metring-point-form__back-img' onClick={hideAside}>
            <img
              src={PageReturn}
              alt="PageReturn"
            />
          </div>
        }

        <h2 className="title">
          {
            isNew
              ? <FormattedMessage id={'meteringPointForm.newPoint'}/>
              : <FormattedMessage id={'meteringPointForm.pointParams'}/>
          }
        </h2>
      </div>

      <Loader loading={(isFirstLoading && !isNew) || !!lastGetMeterointInfoRequest || loading}>
        <p>
          <FormattedMessage id={'meteringPointForm.dontForget'}/>
        </p>

        <div className="form-fields form-fields_first">
          <Input
            label={<FormattedMessage id={'meteringPointForm.name'}/>}
            name='name'
            formSelectors={formModelSelectors}
          />

          <SelectInput
            label={<FormattedMessage id={'meteringPointForm.resourceType'}/>}
            name={'type'}
            formSelectors={formModelSelectors}
            options={RESOURCES_LIST}
          />
        </div>

        <h4 className="title_h4">
          <FormattedMessage id={'meteringPointForm.systemDevice'}/>
        </h4>

        <div className="form-fields form-fields_second">
          <SelectInput
            label={<FormattedMessage id={'meteringPointForm.device'}/>}
            name={'device'}
            options={devicesList}
            formSelectors={formModelSelectors}
            loading={loadingDevices}
          />

          <SelectInput
            label={<FormattedMessage id={'meteringPointForm.channel'}/>}
            name={'channel'}
            formSelectors={formModelSelectors}
            options={CHANNELS_LIST}
          />
        </div>

        <h4 className="title_h4">
          <FormattedMessage id={'meteringPointForm.primaryDevice'}/>
        </h4>

        <div className="form-fields form-fields__third">
          <Input
            label={<FormattedMessage id={'meteringPointForm.deviceName'}/>}
            name={'device_name'}
            formSelectors={formModelSelectors}
          />

          <Input
            label={<FormattedMessage id={'meteringPointForm.serial'}/>}
            name={'serial'}
            formSelectors={formModelSelectors}
          />

          <Input
            label={<FormattedMessage id={'meteringPointForm.date'}/>}
            type="date"
            name={'next_verification'}
            formSelectors={formModelSelectors}
          />

          <Input
            label={<FormattedMessage id={'meteringPointForm.siNumber'}/>}
            name={'si_number'}
            formSelectors={formModelSelectors}
          />

          <SelectInput
            label={<FormattedMessage id={'meteringPointForm.unitType'}/>}
            name='unit_type'
            formSelectors={formModelSelectors}
            options={UNITS_LIST}
          />

          <ValidationsWrapper
            validationMsgs={
              formValidations.date.length
                ? formValidations.date
                : formValidations.time.length
                  ? formValidations.time
                  : formValidations.value
            }
          >
            <div className="metring-point-form__complex-inputs-wrapper">
              <Input
                type="number"
                name={'value'}
                label={<FormattedMessage id={'meteringPointForm.value'}/>}
                placeholder='00000,000'
                formSelectors={formModelSelectors}
                groupClass={'first-complex-group'}
                isShowValidMsg={false}
              />

              <div className="double-inputs">
                <Input
                  label={<FormattedMessage id={'meteringPointForm.onDate'}/>}
                  type='date'
                  name='date'
                  formSelectors={formModelSelectors}
                  isShowValidMsg={false}
                />

                <Input
                  label={<FormattedMessage id={'meteringPointForm.time'}/>}
                  type='time'
                  name='time'
                  formSelectors={formModelSelectors}
                  isShowValidMsg={false}
                />
              </div>
            </div>
          </ValidationsWrapper>
        </div>

        <p>
          <FormattedMessage id={'meteringPointForm.fillWhenConnecting'}/>
        </p>

        <h4 className='title_h4'>
          <FormattedMessage id={'meteringPointForm.impulseSettings'}/>
        </h4>

        <div className="metring-point-form__impulse">
          <ValidationsWrapper validationMsgs={formValidations.value_impulse.length ? formValidations.value_impulse : formValidations.pulse}>
            <div className="metring-point-form__complex-inputs-wrapper_2">
              <Input
                label={<FormattedMessage id={'meteringPointForm.impulses'}/>}
                type="number"
                name='pulse'
                formSelectors={formModelSelectors}
                isShowValidMsg={false}
              />

              <metring-point-form__img>
                <img src={ReverseArrow} alt="ReverseArrow"/>
              </metring-point-form__img>

              <div className="double-inputs">
                <Input
                  label={<FormattedMessage id={'meteringPointForm.resource'}/>}
                  name={'value_impulse'}
                  type="number"
                  formSelectors={formModelSelectors}
                  isShowValidMsg={false}
                />

                <SelectInput
                  name='unit_type_impulse'
                  options={UNITS_LIST}
                  isShowValidMsg={false}
                  styles={RIGHT_SELECT_WITH_INPUT}
                  formSelectors={formModelSelectors}
                />
              </div>
            </div>
          </ValidationsWrapper>
        </div>

        <div className="metring-point-form__btns-wrapper">
          <button className="btn" onClick={handleSubmit}>
            <FormattedMessage id={'app.save'}/>
          </button>

          {!isNew
            ? (
              <button className="btn btn_red" onClick={toggleDeleteModal}>
                <FormattedMessage id={'app.delete'}/>
              </button>
            )
            : null
          }
        </div>
      </Loader>

      <DefaultModal
        isOpen={isOpenDeleteModal}
        toggleModal={toggleDeleteModal}
        agreeBtnConfig={{
          className: 'btn btn_red',
          text: <FormattedMessage id={'app.delete'}/>
        }}
        onAgree={() => handleDelete(idP)}
        onDisagree={toggleDeleteModal}
        headerTitle={<FormattedMessage id={'meteringPointForm.deletePoint?'}/>}
      />
    </div>
  )
}

export default MeteringPointForm
