import {PointsGrey} from "@/assets/svg";
import {getDeviceInfoByType} from "@/internal/helpers/helpers";
import {Tooltip, tooltipClasses} from "@mui/material";
import {styled} from "@mui/material/styles";
import React from "react";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  right: '-8px !important',
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFF',
    color: '#0060F7',
    fontSize: theme.typography.pxToRem(14),
    borderRadius: theme.typography.pxToRem(6),
    padding: `.643em 1.143em`,
    lineHeight: '143%',
    border: 'none',
    boxShadow: `0 .57em 1.07em rgba(53, 96, 181, 0.2)`,
    'span': {
      cursor: 'pointer',
      fontWeight: 400,
    },
    marginTop: '10px !important'
  },
}))

const DeviceSlaveCard = ({data, cardId, currentDeviceOption, onClickOption, actionName, onClickAction}) => {
  const tooltipTitle = (
    <span onClick={onClickAction}>
      {actionName}
    </span>
  )

  return (
    <div className={'device-slave-card'}>
      <div className="device-slave-card__info">
        <div className="device-slave-card__img">
          <img src={getDeviceInfoByType(data.type).img} alt="device"/>
        </div>

        <div className="device-slave-card__name">
          <span>{getDeviceInfoByType(data.type).name}</span>
          <span>SN: {data.serial}</span>
        </div>
      </div>

      <HtmlTooltip
        open={currentDeviceOption === cardId}
        title={tooltipTitle}
        placement={'bottom-end'}
      >
        <div
          className="device-slave-card__options"
          onClick={() => onClickOption(cardId)}
        >
          <img src={PointsGrey} alt="Points"/>
        </div>
      </HtmlTooltip>
    </div>
  )
}


export default DeviceSlaveCard
