import TableLabel from "@/presentation/components/heatCalculationPage/components/MKDPage/components/TableLabel";
import {Table, TableBody, TableHead, TableRow} from "@mui/material";
import Loader from "@/presentation/components/loader/Loader";
import {calculationsSelectors} from "@/internal/lib/storeModels/models/mkds/calculations/calculationsState";
import DataRow from "@/presentation/components/heatCalculationPage/components/MKDPage/components/DataRow";
import StyledComponents from "@/presentation/components/heatCalculationPage/components/MKDPage/components/StyledComponents";
import {useEffect} from "react";
import {emulateTab} from "emulate-tab";
import {
  apartmentsSort,
  CALCULATION_STEPS
} from "@/presentation/components/heatCalculationPage/components/MKDPage/components/const";

const DataTable = (
  {
    // calculate,
    loadingCalc,
    apartments,
    onChangePage,
    paginationParams,
    calcDisabled,
    toggleOpenCalcModal,
    setDataSending
  }) => {
  const forms = calculationsSelectors.enumeratorsForms.useValue()
  const loadingForms = calculationsSelectors.loadingForms.useValue()
  const resetIsSendingData = calculationsSelectors.dataSending.useReset()
  const rowsPerPage = calculationsSelectors.rowsPerPage.useValue()
  const calcResultApartments = calculationsSelectors.calcResultApartments.useValue()
  const setActiveStep = calculationsSelectors.activeStep.useSetValue()

  useEffect(() => {
    return () => {
      resetIsSendingData()
    }
  }, [])

  const handleCalc = () => {
    if (!!calcResultApartments.length) {
      setActiveStep(CALCULATION_STEPS.length - 1)
    } else {
      toggleOpenCalcModal()
    }
  }

  const onPressEnter = (e) => {
    if (e.code !== 'Enter') return

    e.stopPropagation()
    emulateTab()
  }

  const rows = apartments.sort(apartmentsSort).map(apartment => {
    const enumerators = apartment.enumerator ?? []
    if (enumerators.length) {
      return enumerators.map((enumerator, index) => {
        return (
          <DataRow
            setDataSending={setDataSending}
            key={apartment.id.toString() + '-' + enumerator.id}
            withApartmentInfo={index === 0}
            apartment={apartment}
            enumerator={enumerator}
            formSelectors={forms[apartment.id]}
          />
        )
      })
    } else {
      return (
        <TableRow
          key={apartment.id.toString()}
        >
          <StyledComponents.BodyTableCell>
            {apartment.kv}
          </StyledComponents.BodyTableCell>

          <StyledComponents.BodyTableCell>
            {apartment.area}
          </StyledComponents.BodyTableCell>

          <StyledComponents.BodyTableCell
            sx={{
              textAlign: 'center'
            }}
            colSpan={6}
          >
            Нет распределителей
          </StyledComponents.BodyTableCell>
        </TableRow>
      )
    }
  })

  return (
   <>
     <TableLabel
       onChangePage={onChangePage}
       paginationParams={paginationParams}
       loading={calcDisabled}
     />
     <Loader
       loading={loadingCalc || loadingForms}
       styles={{
         wrapper: (base) => ({
           ...base,
           flex: 1,
           overflow: 'auto',
           marginBottom: '20px'
         }),
       }}
     >
       <Table
         stickyHeader
         onKeyPress={onPressEnter}
       >
         <TableHead>
           <StyledComponents.TableRow>
             <StyledComponents.HeadTableCell>
               № квартиры
             </StyledComponents.HeadTableCell>

             <StyledComponents.HeadTableCell>
               Площадь, м<sup>2</sup>
             </StyledComponents.HeadTableCell>

             <StyledComponents.HeadTableCell>
               № ОП на квартире
             </StyledComponents.HeadTableCell>

             <StyledComponents.HeadTableCell>
               Серийный номер распределителя
             </StyledComponents.HeadTableCell>

             <StyledComponents.HeadTableCell>
               Коэф. ОП
             </StyledComponents.HeadTableCell>

             <StyledComponents.HeadTableCell>
               Мощность ОП, КВт/ч
             </StyledComponents.HeadTableCell>

             <StyledComponents.HeadValueTableCell>
               Показания на начало, у.е.
             </StyledComponents.HeadValueTableCell>

             <StyledComponents.HeadValueTableCell>
               Показания на конец, у.е.
             </StyledComponents.HeadValueTableCell>
           </StyledComponents.TableRow>
         </TableHead>

         <TableBody>
           {rows}
         </TableBody>
       </Table>
     </Loader>

     <div className="calculations__btn-wrapper">
       <button
         className={'btn'}
         onClick={handleCalc}
         disabled={calcDisabled}
       >
         Выполнить расчет
       </button>
     </div>
   </>
  )
}

export default DataTable
