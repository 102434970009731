import Select from "react-select";
import {LANG_SELECT_OPTIONS, SELECT_LANG_STYLES} from "./const";
import DropdownIndicator from "@/presentation/components/common/selects/CustomDropdownIndicator";
import {appSelectors} from "@/internal/lib/storeModels/models/app/appModels";

const LangSelect = () => {
  const [locale, setLocale] = appSelectors.locale.useState()
  const value = LANG_SELECT_OPTIONS.find(option => option.value === locale)

  const onChange = (e) => {
    setLocale(e.value)
  }

  return (
    <Select
      options={LANG_SELECT_OPTIONS}
      value={value}
      styles={SELECT_LANG_STYLES}
      components={{ DropdownIndicator }}
      isSearchable={false}
      placeholder={''}
      onChange={onChange}
    />
  )
}

export default LangSelect
