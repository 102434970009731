import React, {useEffect, useState} from "react";
import UserCard from "@/presentation/components/mainPageService/components/UserCard";
import {mainPageSelectors} from "@/internal/lib/storeModels/models/mainPage/mainPageModel";
import AddIcon from '@mui/icons-material/Add';
import {selectedUserSelectors} from "@/internal/lib/storeModels/models/servicemen/selectedUserModel";
import {servicemenEndpoints} from "@/data/api_entities/servicemen/endpoints";
import Loader from "@/presentation/components/loader/Loader";
import {isOpenCreateUserFormSelectors} from "@/internal/lib/storeModels/models/servicemen/stores";
import {selectors} from "@/presentation/shared/ui/message";

const UsersList = ({updateServicemenInfo}) => {
  const setIsOpenAside= isOpenCreateUserFormSelectors.useSetValue()
  const setIsNew = mainPageSelectors.isNewAddress.useSetValue()
  const setSelectedUser = selectedUserSelectors.useSetValue()
  const [isUsersLoading, setIsUsersLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [isOfferAgree, setIsOfferAgree] = useState(true)
  const [serviceManagerId, setServiceManagerId] = useState(null)
  const [acceptLoading, setAcceptLoading] = useState(false)
  const setMessage = selectors.useSetMessage()

  useEffect(() => {
    setSelectedUser(null)
  }, [])


  useEffect(() => {
    setIsUsersLoading(true)

    servicemenEndpoints.getServicemenInfo().promise
      .then(res => {
        setUsers(res.users_manager ?? [])
        setIsOfferAgree(res?.service_manager?.offer ?? false)
        setServiceManagerId(res?.service_manager?.id ?? null)
      })
      .catch(err => {
        console.log(err)
        setUsers([])
      })
      .finally(() => setIsUsersLoading(false))
  }, [updateServicemenInfo])

  const onAcceptOffer = () => {
    if (!serviceManagerId) return

    setAcceptLoading(true)
    servicemenEndpoints.acceptOffer(serviceManagerId).promise
      .then(res => {
        if (res?.status === 'ok') {
          setIsOfferAgree(true)
        } else {
          setMessage({
            header: 'Принятие офферты',
            message: 'Ошибка!'
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setAcceptLoading(false))
  }

  const onClickAddNew = () => {
    setIsNew(true)
    setIsOpenAside(true)
  }

  return (
    <Loader loading={isUsersLoading || acceptLoading}>
      <div className="objects-list">
        <div className="objects-list__add" onClick={onClickAddNew}>
          <div>
            <AddIcon/>
          </div>
        </div>
        {users.map(user => {
          return <UserCard key={user.id} data={user}/>
        })}
      </div>
    </Loader>

  )
}

export default UsersList
