export const formTypesList = [
  {
    value: 'legal',
    label: 'Юридическое лицо',
  },
  {
    value: 'individual',
    label: 'Физическое лицо',
  },
]



