import {request} from "@/internal/lib/transport/request";
import {getDataAdapter} from "@/data/api_entities/meterings/meterings_adapters";

export const meteringsEndpoint = {
  getMeterings () {
    const params = {
      url: 'api/metering/',
      method: 'GET'
    }

    return request(params).promise
  },

  getSampleMetering (data, addressId, intl) {
    const params = {
      url: 'api/metering/sample/',
      method: 'POST',
      data: {
        ...getDataAdapter(data, intl),
        address_id: addressId
      }
    }

    return request(params)
  }
}
