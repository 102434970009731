import { makeStyles } from '@mui/styles'
import {Alert} from '@/assets/svg'
import {createTheme} from "@mui/material";
const theme = createTheme()

const useStyles = makeStyles({
  messages: {
    position: 'absolute',
    left: theme.spacing(2),
    bottom: theme.spacing(0.5),
    zIndex: 1000,
  },
  message: {
    zIndex: 1000,
    fontSize: 16,
    color: '#36455D',
    backgroundImage: `url(${Alert})` ,
    backgroundPosition: 'right top',
    backgroundSize: 'auto',
    backgroundRepeat: 'no-repeat',
    padding: theme.spacing(4, 4),
    lineHeight: '24px',
    borderRadius: '16px',
    position: 'relative',
    '& > div': {
      cursor: 'pointer',
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1)
    }
  },
})

export default useStyles
