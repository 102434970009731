import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {useIntl} from "react-intl";

const CompareChart = ({xAxis, data, averages, plotLines}) => {
  const intl = useIntl()

  const options = {
    chart: {
      type: 'column',
      height: `53%`,
      showAxes: false,
      spacing: [37, 0, 0, 0],
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: ''
    },
    subtitle: {
      useHTML: true,
      widthAdjust: 0,
      floating: true,
      align: 'left',
      y: 0,
      text:(
        `<div class='chart__header'>
              <span class='chart__last-year'>
                ${intl.formatMessage({id: 'intake.lastYear'})}
              </span>
  
              <p class='chart__this-year'>
                <span>
                 ${intl.formatMessage({id: 'intake.thisYear'})}
                </span>
    
                <span style="color: ${averages.length === 1 ? averages[0].color : ''}">
                  ${intl.formatMessage({id: 'intake.average'})}${averages.map(average => {
          return (`
                      <span style="color: ${average.color}">
                        ${Math.round(average.value * 10)/10}
                      </span>
                  `)
        }).join(' / ')}
                </span>
              </p>
        
              <span class='chart__forecast'>
                ${intl.formatMessage({id: 'intake.forecast'})}
              </span>
            </div>`
      )
    },
    tooltip: {
      backgroundColor: 'rgba(0,0,0,0)',
      shadow: false,
      borderWidth: 0,
      useHTML: true,
      shared: true,
      hideDelay: 100,
      formatter: function() {
        const pointsValues = this.points.map(point => {
          return `
                <span>
                  ${point.series.name}: ${Math.round(point.point.y * 100)/100} ${intl.formatMessage({id: 'intake.m'})}<sup>3</sup>
                </span>
              `
        })

        const tooltip = `
              <div class="chart-tooltip">
                ${pointsValues.join('')}
              </div>
            `
        return tooltip
      },
      valueDecimals: 4,
    },
    series: data,
    xAxis:{
      categories: xAxis,
      gridLineColor: '#fff',
      labels: {
        style: {
          fontSize: '.75rem',
          color: '#727A86',
        },
        y: 20,
      },
      lineColor: 'none',
      plotLines: [{
        color: '#ECF0F6',
        dashStyle: 'solid',
        value: 1,
        width: 1
      }, {
        color: '#ECF0F6',
        dashStyle: 'solid',
        value: 5,
        width: 1
      }],
    },
    yAxis: {
      gridLineColor: '#fff',
      title: {
        enabled: false
      },
      labels: {
        style: {
          fontSize: '.75rem',
          color: '#727A86'
        },
        x: -18,
        y: -2,
        distance: 0
      },
      plotLines
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          verticalAlign: 'top',
          enabled: true,
          useHTML: true,
          style: {
            fontSize: '.75rem',
            fontWeight: 'normal',
            color: '#36455D',
            lineHeight: '133%',
          },
          x: 0,
          y: -30,
          formatter: function () {
            return `${Math.round(this.y * 10)/10}`
          }
        }
      }
    },
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}

export default CompareChart
