import React, {useEffect, useState} from 'react'
import AnalyticsList from "./сomponents/AnalyticsList";
import {FormattedMessage} from "react-intl";
import {Route, Routes} from "react-router-dom";
import AnalyticInfo from "@/presentation/components/analytics/сomponents/AnalyticInfo";
import {analyticsSelectors} from "@/internal/lib/storeModels/models/analytics/analyticsModels";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import TablesModal from "@/presentation/components/analytics/сomponents/TablesModal";
import Tables from "@/presentation/components/analytics/сomponents/Tables";

export default ({isServicemen = false, isLoading = false, getInfo = null}) => {
    const [isPaid] = analyticsSelectors.isPaidAnalytic.useState()

    const [initialResultData, setInitialResultData] = useState(null)
    const [isOpenCloseSubmitModal, toggleSubmitModal, setIsOpenCloseSubmitModal] = useToggle()
    const [isOpenModal, toggleOpenModal] = useToggle()
    const handleAgreeSubmit = () => {
      toggleSubmitModal()
      toggleOpenModal()
    }

    const toggleModal = () => {
      if (isOpenModal && !isPaid) {
        setInitialResultData(null)
        setIsOpenCloseSubmitModal(true)
      } else {
        toggleOpenModal()
      }
    }

    return(
    <div className='analytics'>
      <div className="analytics__column">
        <div className="analytics__title-wrapper">
          <h2 className="title">
            <FormattedMessage id={'analytics.analytics'}/>
          </h2>

          <button
            className={`btn btn_transparent-bg`}
            onClick={toggleModal}
          >
            +
          </button>
        </div>

        <AnalyticsList isServicemen={isServicemen} loading={isLoading}/>
      </div>

      {isOpenModal &&
        <TablesModal
          hideModal={toggleModal}
          isOpenCloseSubmitModal={isOpenCloseSubmitModal}
          toggleSubmitCloseModal={toggleSubmitModal}
          handleAgreeSubmitCloseModal={handleAgreeSubmit}
        >
          <Tables
            getInfo={getInfo}
            toggleModal={toggleModal}
            startResultData={initialResultData}
          />
        </TablesModal>
      }

      <Routes>
        <Route
          path={':id/*'}
          element={<AnalyticInfo getInfo={getInfo}/>}
        >
        </Route>
      </Routes>
    </div>
  )
}
