export default class DevicesTypes {
  // Типы устройств
  static GSMBridge = 3 // МОСТиК(GSM_BRIDGE)
  static electronicFlowMeter = 8 // РД(ELECTRONIC_FLOW_METER)
  static xPulseOld = 12 // Домовой (Х-Пульс) (X_PULSE_OLD)
  static xPulseNBIoT = 13 // X-Pulse NB-IoT (X_PULSE_NB_IOT)
  static heatMeter = 14 // КОМБиК(HEAT_METER)
  static bridge = 15 // МОСТ(BRIDGE)

  // Группы устройств
  static transferDevicesOnlyRadio = [this.GSMBridge] // TRANSFER_DEVICES_ONLY_RADIO
  static transferDevicesOnlyRS = [] // TRANSFER_DEVICES_ONLY_RS
  static transferDevicesRsAndRadio = [this.bridge] // TRANSFER_DEVICES_RS_AND_RADIO
  static endDevicesOnlyRadio = [] // END_DEVICES_ONLY_RADIO
  static endDevicesOnlyRS = [this.heatMeter] // END_DEVICES_ONLY_RS
  static endDevicesRsAndRadio = [this.electronicFlowMeter, this.xPulseOld] // END_DEVICES_RS_AND_RADIO
  static soloDevices = [this.xPulseNBIoT] // SOLO_DEVICES
  static allTransferDevices = [this.GSMBridge, this.bridge]
  static allEndDevices = [this.heatMeter, this.electronicFlowMeter, this.xPulseOld]
  static devicesWithSim = [this.GSMBridge, this.bridge, this.xPulseNBIoT]

  // Типы групп устройств
  static transferDevicesOnlyRadioType = 0
  static transferDevicesOnlyRSType = 1
  static transferDevicesRsAndRadioType = 2
  static endDevicesOnlyRadioType = 3
  static endDevicesOnlyRSType = 4
  static endDevicesRsAndRadioType = 5
  static soloDevicesType = 6
  static allTransferDevicesType = 7
  static allEndDevicesType = 8
}
