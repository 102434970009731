import {TableCell} from "@mui/material";
import {EMPTY_COLUMN} from "../const";
import {ACTIVE_COLOR, DISABLED_COLOR} from "@/presentation/components/analytics/const";

const IntakeTableCell = (
  {
    modelInfo,
    valueKey,
    activeCell,
    columnWidth,
    onFocus,
    id,
    setRandomData,
  }) => {
  const isDisabled = valueKey === EMPTY_COLUMN
  const saveChanges = modelInfo.selectors.useSaveChanges()
  const formValue = isDisabled
    ? ''
    : modelInfo.selectors.useCurrentIntakeValue(valueKey)
  const setValue = isDisabled
    ? () => {}
    : modelInfo.selectors.useSetCurrentIntakeValue()


  const onChange = (e) => {
    if (isDisabled) return

    const {value} = e.target

    if (typeof value === 'string' && value.includes('-')) return

    setValue({
      key: valueKey,
      value
    })
  }

  const onBlur = (e) => {
    const {value} = e.target

    saveChanges()

    if (value.length) return

    setValue({
      value: '0',
      key: valueKey,
    })
  }

  const handleClickCell = (e) => {
    const target = e.target.closest('.MuiTableCell-root')
    target.children[0].focus()
  }

  const background = activeCell === id
    ? ACTIVE_COLOR
    : isDisabled
      ? DISABLED_COLOR
      : 'transparent'

  return (
    <TableCell
      id={id}
      sx={{
        background,
        borderTop: 'none',
        borderBottom: 'none',
        cursor: 'text',
        input: {
          background,
        },
        pointerEvents: `${isDisabled ? 'none' : ''}`,
        width: columnWidth,
        minWidth: isDisabled ? '' : '7rem'
      }}
      onClick={handleClickCell}
    >
      <input
        value={formValue}
        disabled={isDisabled}
        type="number"
        min={'0'}
        onWheel={(e) => e.target.blur()}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={(e) => setRandomData(e, modelInfo.model, '$intake', 'setIntakes', 1000)}
      />
    </TableCell>
  )
}

export default IntakeTableCell
