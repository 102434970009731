import ArrowBack from "@/presentation/components/common/ArrowBack";
import {CHANGE_ANALYTIC_NAME_PATH} from "./const";
import Loader from "@/presentation/components/loader/Loader";
import {useCallback, useEffect, useState} from "react";
import {analyticsEndpoint} from "@/data/api_entities/analytics/analytics_endpoint";
import {selectors} from "@/presentation/shared/ui/message";
import Input from "@/presentation/components/common/formComponents/Input";
import {changeNameFormSelectors} from "@/internal/lib/storeModels/models/analytics/forms/changeNameFormModel";
import {analyticsListSelectors} from "@/internal/lib/storeModels/models/analytics/analyticsListModel";
import {FormattedMessage} from "react-intl";

const ChangeAnalyticName = ({initName, id, getAnalyticInfo, getInfo = null}) => {
  const isValidForm = changeNameFormSelectors.useIsFormValid()
  const resetForm = changeNameFormSelectors.useResetForm()
  const setOnValidSubmit = changeNameFormSelectors.useSetOnValidSubmit()
  const setInitData = changeNameFormSelectors.useSetInitFormData()
  const onClickSubmit = changeNameFormSelectors.useOnClickSubmit()
  const updateAnalyticsList = analyticsListSelectors.list.useUpdateAnalyticsList()
  const setMessage = selectors.useSetMessage()

  const [lastChangeNameRequest, setLastChangeNameRequest] = useState(null)

  useEffect(() => {
    return () => {
      if (lastChangeNameRequest) lastChangeNameRequest.abort()

      resetForm()
    }
  }, [])

  const onValidSubmit = useCallback((data) => {
    if (lastChangeNameRequest) {
      lastChangeNameRequest.abort()
    }

    const {promise, controller} = analyticsEndpoint.changeAnalyticParams(data, id)

    setLastChangeNameRequest(controller)

    promise
      .then(() => {
        setMessage({
          header: <FormattedMessage id={'requests.analyticNameChangeSuccessHeader'}/>,
          message: <FormattedMessage id={'requests.analyticNameChangeSuccess'}/>,
          count: 3000
        })
        (getInfo ?? updateAnalyticsList)()

        getAnalyticInfo()
        setLastChangeNameRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return

        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.analyticNameChangeError'}/>,
          type: 'error',
          count: 3000
        })
        setLastChangeNameRequest(null)
        console.log(err)
      })
  }, [lastChangeNameRequest, id])

  useEffect(() => {
    setOnValidSubmit(onValidSubmit)
  }, [onValidSubmit])

  useEffect(() => {
    setInitData({
      name: initName
    })
  }, [initName])

  const handleSubmit = () => {
    if (!isValidForm) return

    onClickSubmit()
  }

  return (
    <div className="change-analytic-name">
      <ArrowBack
        pathOnVisible={CHANGE_ANALYTIC_NAME_PATH}
      />

      <Loader loading={!!lastChangeNameRequest}>
        <h2 className={'title'}>
          <FormattedMessage id={'analytics.reportName'}/>
        </h2>

        <Input
          label={<FormattedMessage id={'analytics.name'}/>}
          name={'name'}
          formSelectors={changeNameFormSelectors}
        />

        <button
          className="btn"
          onClick={handleSubmit}
        >
          <FormattedMessage id={'app.save'}/>
        </button>
      </Loader>
    </div>
  )
}

export default ChangeAnalyticName
