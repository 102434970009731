import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'reportsPage'
const translations = {
  reports: 'Reports',
  systemDevice: 'System device',
  meteringPoint: 'Accounting point',
  resource: 'Resource',
  byMonth: 'Per month',
  byWeek: 'Per week',
  byDay: 'Per day',
  byNum: '{num} of the month',
  name: 'Name',
  nameOfResponsiblePerson: 'Name of responsible person',
  tel: 'Phone',
  reportByDevice: 'System instrument report',
  reportByResource: 'Resource report',
  reportByMeteringPoint: 'Point of account Report',
  integral: 'Integral (cumulative total)',
  increment: 'Period increment',
  integralAndIncrement: 'Integral and incremental',
  displayReadings: 'Display readings',
  customizeReport: 'Customize the report',
  reportType: 'Report type',
  meteringPointsInReport: 'Accounting points included in the report:',
  gizmoDevice: 'Primary device',
  detailing: 'Detailing',
  unitsTypes: 'Units',
  dimension: 'Dimension',
  reportName: 'Report title (can be edited)',
  reportContents: 'Contents of the report',
  body: 'General block of information',
  provider: 'Provider',
  consumer: 'Consumer',
  serviceOrg: 'Service organization',
  systemType: 'System type (optional)',
  channelOne: 'Channel 1',
  channelTwo: 'Channel 2',
  errors: 'NA codes',
  preview: 'Preview',
  regularDispatch: 'Regular dispatch',
  sendRegular: 'Send periodically',
  periodExample: 'Period example:',
  example: 'June 1 00:00 – June 30 24:00',
  sendEmail: 'Send to email mail',
  inNextUpdates: 'In the next updates, we will add sending to mos.ru, to chat messengers, via SMS and to external servers',
  'deleteReport?': 'Delete report?',
  successfulTransfer: 'Successful transfer',
  delayed: '{time} - delayed',
  missed: 'Sending missed',
  plan: 'Sending plan',
  fact: 'Sending fact',
  noHistory: 'This report has no history',
  history: 'Report submission history',
  withoutRegular: 'No regular posting',
  mailsCount: 'sending channels',
  createReport: 'Create a period report',
  dontForget: 'In order not to forget, let\'s name the place of installation - Kitchen, Bathroom, Tambour ...',
  periodFrom: 'Period from',
  to: 'to',
  noReports: 'There are no reports yet. You can create a new report and configure its sending by clicking +',
  resources: 'Resources',
  points: 'Points',
  device: 'Device',
  selectDevice: 'Choose a device',
  resourceType: 'Resource type',
  selectPoint: 'Choose an accounting point',
  typeTitle: '{type} - {day, plural, =0 {} other {{day} date,}} {time}'
}

export const reportsPageEn = createTranslationsWithSpaceName(translations, spaceName)
