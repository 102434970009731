import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";
import {createEffect, createEvent, sample} from "effector";
import {setMessage} from "@/presentation/shared/ui/message";
import {useEvent, useStore} from "effector-react";
import {addressEndpoint} from "@/data/api_entities/address/address_endpoint";
import cookiesManager from "@/internal/lib/services/cookiManager";

const devicesModel = new StoreModelFabric([])
const loadingDevicesModel = new StoreModelFabric(true)
const updateDevices = createEvent()

devicesModel.getData = createEffect(() => {
  const id = cookiesManager.get('X-pulseAddress')

  if (!id) return

  loadingDevicesModel.setState(true)

  addressEndpoint.getDevices(id).promise
    .then(res => {
      devicesModel.setState(res?.devices ?? [])
    })
    .catch(err => {
      setMessage({
        header: 'Ошибка!',
        message: 'Не удалось загрузить список устройств',
        type: 'error',
        count: 3000
      })
      console.log(err)
    })
    .finally(() => {
      loadingDevicesModel.setState(false)
    })
})

sample({
  clock: updateDevices,
  target: devicesModel.getData
})

export const devicesSelectors = {
  devices: {
    ...devicesModel.createSelectors(),
    useGetDevices: () => useEvent(devicesModel.getData),
    useUpdateDevices: () => useEvent(updateDevices),
    useDevicesAvailable: () => !!useStore(devicesModel.$store).length
  },
  loadingDevices: loadingDevicesModel.createSelectors(),
}
