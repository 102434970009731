import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'reportsPage'
const translations = {
  reports: 'Отчеты',
  systemDevice: 'Системный прибор',
  meteringPoint: 'Точка учета',
  resource: 'Ресурс',
  byMonth: 'За месяц',
  byWeek: 'За неделю',
  byDay: 'За сутки',
  byNum: '{num} число',
  name: 'Наименование',
  nameOfResponsiblePerson: 'ФИО ответственого лица',
  tel: 'Телефон',
  reportByDevice: 'Отчет по системному прибору',
  reportByResource: 'Отчет по ресурсу',
  reportByMeteringPoint: 'Отчет по точке учета',
  integral: 'Интегрально (накопленный итог)',
  increment: 'Приращение за период',
  integralAndIncrement: 'Интегрально и приращением',
  displayReadings: 'Отображать показания',
  customizeReport: 'Настройте отчет',
  reportType: 'Тип отчета',
  meteringPointsInReport: 'Точки учета, попадающие в отчет:',
  gizmoDevice: 'Первичный прибор',
  detailing: 'Детализация',
  unitsTypes: 'Единицы измерения',
  dimension: 'Размерность',
  reportName: 'Название отчета (можно редактировать)',
  reportContents: 'Состав отчета',
  body: 'Общий блок информации',
  provider: 'Поставщик',
  consumer: 'Потребитель',
  serviceOrg: 'Обслуживающая организация',
  systemType: 'Тип системы (не обязательно)',
  channelOne: 'Канал 1',
  channelTwo: 'Канал 2',
  errors: 'Коды НС',
  preview: 'Предварительный просмотр',
  regularDispatch: 'Регулярная отправка',
  sendRegular: 'Отправлять периодически',
  periodExample: 'Пример периода:',
  example: '1 июня 00:00 – 30 июня 24:00',
  sendEmail: 'Отправлять на эл. почту',
  inNextUpdates: 'В следующих обновлениях добавим отправку в mos.ru, в чат-мессенджеры, по\xa0СМС и на внешние серверы',
  'deleteReport?': 'Удалить отчет?',
  successfulTransfer: 'Успешная передача',
  delayed: '{time} - с задержкой',
  missed: 'Отправка пропущена',
  plan: 'Отправка план',
  fact: 'Отправка факт',
  noHistory: 'У этого отчета нет истории',
  history: 'История отправки отчета',
  withoutRegular: 'Без регулярной отправки',
  mailsCount: '{count, plural, one {{count} канал} few {{count} канала} other {{count} каналов}} отправки',
  createReport: 'Создать отчет за период',
  dontForget: 'Чтобы не забыть, давайте названия по месту установки - Кухня, Ванная, Тамбур...',
  periodFrom: 'Период с',
  to: 'по',
  noReports: 'Пока нет отчетов. Вы можете создать новый отчет и настроить его отправку нажав на +',
  resources: 'Ресурсы',
  points: 'Точки',
  device: 'Прибор',
  selectDevice: 'Выберите прибор учета',
  resourceType: 'Тип ресурса',
  selectPoint: 'Выберите точку учета',
  typeTitle: '{type} - {day, plural, =0 {} other {{day} число,}} {time}'
}

export const reportsPageRu = createTranslationsWithSpaceName(translations, spaceName)
