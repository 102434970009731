import {RESOURCES_TYPES_ARRAY} from "@/presentation/components/intake/const";
import {toggleItems} from "@/internal/helpers/helpers";
import {intakeSelectors} from "@/internal/lib/storeModels/models/intake/intakeModels";
import {sampleParamsFormSelectors} from "@/internal/lib/storeModels/models/intake/forms/sampleParamsFormModel";
import {FormattedMessage} from "react-intl";

const MeteringPointsList = () => {
  const selectedPoints = sampleParamsFormSelectors.useFormDataValue('pointsIds')
  const setSelectedPoints = sampleParamsFormSelectors.useSetFormDataValue('pointsIds')
  const sortedMeteringPoints = intakeSelectors.sortedMeteringPoints.useValue()
  const activeTypes = intakeSelectors.activeTypes.useValue()

  const handleSelectPoint = (id) => {
    if (selectedPoints.length > 1 && !selectedPoints.includes(id)) return

    toggleItems(id, selectedPoints, setSelectedPoints)
  }

  const meteringPointsList = sortedMeteringPoints.map(pointType => {
    if (!pointType.points.length || !activeTypes.includes(pointType.type)) return null

    const type = RESOURCES_TYPES_ARRAY.find(resource => resource.type === pointType.type)
    const img = type ? type.img : null

    const pointsList = pointType.points.map(point => {
      return (
        <div
          key={point.id}
          className={`metering-row__point ${selectedPoints.includes(point.id) ? 'active' : ''}`}
          onClick={() => handleSelectPoint(point.id)}
        >
          <div className="metering-row__img">
            <img src={img} alt="resource"/>
          </div>

          <p>{point.name}</p>
        </div>
      )
    })

    return (
      <div key={pointType.type} className='metering-row'>
        <div className='metering-row__name'>
          <div className="metering-row__img">
            <img src={img} alt="resource"/>
          </div>

          <p>
            <FormattedMessage id={'intake.meteringPoints'}/>:
          </p>
        </div>

        <div className="metering-row__points">
          {pointsList}
        </div>
      </div>
    )
  })

  return (
    <div className="intake__metering-points">
      {meteringPointsList}
    </div>
  )
}

export default MeteringPointsList
