import Select from "react-select";
import {OBJECTS_SELECT_STYLES} from "./const";
import DropdownIndicator from "./CustomDropdownIndicator";
import SingleValue from "./CustomSingleValue";
import {filteredAddressOptions, formatOptionLabel} from "./objects_select_fns";
import {FormattedMessage} from "react-intl";

const ObjectsSelect = (
  {
    addresses,
    onChange,
    value,
    name,
    placeholder,
    customComponents = {},
    classNames = '',
    customStyles = {},
    loading = false
  }) => {
  return (
    <Select
      name={name}
      isLoading={loading}
      options={addresses}
      className={classNames}
      components={{DropdownIndicator, SingleValue, ...customComponents}}
      styles={{...OBJECTS_SELECT_STYLES, ...customStyles}}
      placeholder={placeholder ?? null}
      filterOption={filteredAddressOptions}
      getOptionValue={option => option.id}
      getOptionLabel={formatOptionLabel}
      maxMenuHeight={280}
      autoFocus={false}
      menuPosition={'absolute'}
      menuPlacement={'auto'}
      backspaceRemovesValue={false}
      onChange={onChange}
      value={value}
      noOptionsMessage={() => <FormattedMessage id={'app.nothingFound'}/>}
    />
  )
}

export default ObjectsSelect
