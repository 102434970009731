import {useMemo, useState} from "react";
import {TableCell, TableRow} from "@mui/material";
import TemperatureTableCell from "./TemperatureTableCell";
import CopyValuesButton from "../CopyValuesButton";
import EditAddressButton from "@/presentation/components/analytics/сomponents/EditAddressButton";

const TemperatureTableRow = (
  {
    address,
    columns,
    columnWidth,
    models,
    setRandomData,
    modelInfo
  }) => {
  const [activeCell, setActiveCell] = useState(null)

  const onFocusInput = (e, id) => {
    e.target.select()
    setActiveCell(id)
  }

  const inputCells = useMemo(() => {
    return columns.map((column, i) => {
      const id = `${address.id}-${column.value}-${i}`

      return (
        <TemperatureTableCell
          key={id}
          id={id}
          activeCell={activeCell}
          valueKey={column.value}
          setRandomData={setRandomData}
          onFocusInput={onFocusInput}
          modelInfo={modelInfo}
          columnWidth={columnWidth}
        />
      )
    })
  }, [modelInfo, activeCell])


  return (
    <TableRow  onBlur={() => setActiveCell(null)}>
      <TableCell
        sx={{
          fontWeight: '500 !important',
          width: columnWidth,
          textAlign: 'left !important'
        }}
      >
        <div className="tables__address-cell">
          <span>{address.name}</span>

          <div className="tables__address-btns">
            <EditAddressButton
              addressId={address.id}
            />
            <CopyValuesButton
              models={models}
              selectors={modelInfo.selectors}
              useDataName={'useTempValues'}
              setMethodName={'setTemperature'}
            />
          </div>
        </div>
      </TableCell>

      {inputCells}
    </TableRow>
  )
}

export default TemperatureTableRow
