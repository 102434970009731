import {styled} from "@mui/material/styles";
import {Popover} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {ACTIVE_COLOR, DISABLED_COLOR} from "@/presentation/components/analytics/const";

export const CHANGE_ANALYTIC_NAME_PATH = 'change_name'
export const TABLE_COLUMNS_COUNT = 12
export const EMPTY_COLUMN = '-'
export const MAX_SHOWN_ADDRESSES_COUNT = 10
export const MIN_DATE = '2012-01-01'

export const paginationDirection = Object.freeze({
  'first': 'app.firstPg',
  'last': 'app.lastPg',
  'next': 'app.nextPg',
  'previous': 'app.prevPg',
})

export const STEPPER_STEPS = [
  {
    index: 0,
    name: <FormattedMessage id={'analytics.analysisSettings'}/>
  },
  {
    index: 1,
    name: <FormattedMessage id={'analytics.metersData'}/>
  },
  {
    index: 2,
    name: <FormattedMessage id={'analytics.durationDays'}/>
  },
  {
    index: 3,
    name: <FormattedMessage id={'analytics.temperature'}/>
  },
  {
    index: 4,
    name: <FormattedMessage id={'analytics.report'}/>
  },
]

export const INVEST_STEPS = [
  {
    index: 0,
    name: <FormattedMessage id={'analytics.investments'}/>
  },
  {
    index: 1,
    name: <FormattedMessage id={'analytics.returnOnInvestments'}/>
  }
]

export const RESULT_TABS_LIST = [
  {
    name: 'intake-efficiency',
    title: <FormattedMessage id={'analytics.intakeEfficiency'}/>
  },
  {
    name: 'economic-analysis',
    title: <FormattedMessage id={'analytics.economicAnalysis'}/>
  },
  {
    name: 'economic-efficiency',
    title: <FormattedMessage id={'analytics.economicEfficiency'}/>
  },
  {
    name: 'return-on-investments',
    title: <FormattedMessage id={'analytics.returnOnInvestments'}/>
  },
  {
    name: 'pivot-table',
    title: <FormattedMessage id={'analytics.pivotTable'}/>
  },
]

export const BY_TEMPLATE_TYPE = 0
export const BY_CONTINUE_TYPE = 1

export const NEW_ANALYTIC_TYPES_LIST = [
  {
    value: BY_TEMPLATE_TYPE,
    label: <FormattedMessage id={'analytics.byTemplate'}/>
  },
  {
    value: BY_CONTINUE_TYPE,
    label: <FormattedMessage id={'analytics.byContinue'}/>
  },
]

export const TABLE_ROWS_PER_PAGE_LIST = [
  {
    value: 20,
    label: (
      <FormattedMessage
        id={'analytics.objectsCount'}
        values={{
          count: 20
        }}
      />
    )
  },
  {
    value: 30,
    label: (
      <FormattedMessage
        id={'analytics.objectsCount'}
        values={{
          count: 30
        }}
      />
    )
  },
  {
    value: 40,
    label: (
      <FormattedMessage
        id={'analytics.objectsCount'}
        values={{
          count: 40
        }}
      />
    )
  },
  {
    value: 50,
    label: (
      <FormattedMessage
        id={'analytics.objectsCount'}
        values={{
          count: 50
        }}
      />
    )
  },
]

export const CustomizedPopover = styled(Popover)(({theme}) => ({
  '.MuiPaper-root': {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: `${20/14 * 100}%`,
    fontWeight: 400,
    color: '#36455D',
    padding: theme.typography.pxToRem(6),
    borderRadius: theme.typography.pxToRem(6),
    boxShadow: `0 ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)} rgba(53, 96, 181, 0.2)`
  }
}))

export const getIntakeTableHeaderConfig = (tableParams, tableNumb, columnWidth) => {
  if (!tableParams || !tableParams.length) return []

  const investColumnWidth = +columnWidth.slice(0, columnWidth.length - 2) * 14 /15 + '%'

  return [
    {
      cellsList: [
        {
          title: <FormattedMessage id={'analytics.objects'}/>,
          rowSpan: 2,
          styles: {
            textAlign: 'left !important',
            width: investColumnWidth
          }
        },
        {
          title: <FormattedMessage id={'addressForm.unitType'}/>,
          rowSpan: 2,
          styles: {
            // textAlign: 'left !important',
            width: investColumnWidth
          }
        },
        {
          title: <FormattedMessage id={'analytics.intake'}/>,
          colSpan: TABLE_COLUMNS_COUNT,
          isRefForFirstTableHeaderRow: true
        },
        {
          title: <FormattedMessage id={'analytics.totalPeriod'}/>,
          rowSpan: 2,
          styles: {
            textAlign: 'right !important',
            width: investColumnWidth
          }
        }
      ]
    },
    {
      cellsList: [
        ...tableParams[tableNumb - 1].columns.map(column => ({
          ...column,
          rowSpan: 2,
          topStyleForStickyHeader: true,
          styles: {
            background: column.title === EMPTY_COLUMN
              ? `${DISABLED_COLOR} !important`
              : '',
            borderColor: column.title === EMPTY_COLUMN
              ? `${ACTIVE_COLOR} !important`
              : '',
            width: investColumnWidth
          }
        }))
      ]
    }
  ]
}

export const getDurationsTableHeaderConfig = (tableParams, tableNumb, columnWidth) => {
  if (!tableParams || !tableParams.length) return []

  return [
    {
      cellsList: [
        {
          title: <FormattedMessage id={'analytics.objects'}/>,
          rowSpan: 2,
          styles: {
            textAlign: 'left !important',
            width: columnWidth
          },
        },
        {
          title: <FormattedMessage id={'analytics.durationDays'}/>,
          colSpan: TABLE_COLUMNS_COUNT,
          isRefForFirstTableHeaderRow: true
        },
        {
          title: <FormattedMessage id={'analytics.totalPeriod'}/>,
          rowSpan: 2,
          styles: {
            textAlign: 'right !important',
            width: columnWidth
          }
        }
      ]
    },
    {
      cellsList: [
        ...tableParams[tableNumb - 1].columns.map(column => ({
          ...column,
          rowSpan: 2,
          topStyleForStickyHeader: true,
          styles: {
            background: column.title === EMPTY_COLUMN
              ? `${DISABLED_COLOR} !important`
              : '',
            borderColor: column.title === EMPTY_COLUMN
              ? `${ACTIVE_COLOR} !important`
              : '',
            width: columnWidth
          }
        }))
      ]
    }
  ]
}

export const getTemperatureTableHeaderConfig = (tableParams, tableNumb, columnWidth) => {
  if (!tableParams || !tableParams.length) return []
  const tempColumnWidth = +columnWidth.slice(0, columnWidth.length - 2) * 14 /13 + '%'

  return [
    {
      cellsList: [
        {
          title: <FormattedMessage id={'analytics.objects'}/>,
          rowSpan: 2,
          styles: {
            textAlign: 'left !important',
            width: tempColumnWidth
          }
        },
        {
          title: <FormattedMessage id={'analytics.temperatureWithSign'}/>,
          colSpan: TABLE_COLUMNS_COUNT,
          isRefForFirstTableHeaderRow: true
        },
      ]
    },
    {
      cellsList:  [
        ...tableParams[tableNumb - 1].columns.map(column => ({
          ...column,
          rowSpan: 2,
          topStyleForStickyHeader: true,
          styles: {
            background: column.title === EMPTY_COLUMN
              ? `${DISABLED_COLOR} !important`
              : '',
            borderColor: column.title === EMPTY_COLUMN
              ? `${ACTIVE_COLOR} !important`
              : '',
            width: tempColumnWidth
          }
        }))
      ]
    }
  ]
}

export const getInvestmentsTableHeaderConfig = (tableParams, tableNumb, columnWidth) => {
  return  [
    {
      cellsList: [
        {
          title: <FormattedMessage id={'analytics.objects'}/>,
          rowSpan: 2,
          styles: {
            textAlign: 'left !important',
            width: columnWidth
          }
        },
        {
          title: <FormattedMessage id={'analytics.investmentsWithCur'}/>,
          colSpan: TABLE_COLUMNS_COUNT,
          isRefForFirstTableHeaderRow: true
        },
        {
          title: <FormattedMessage id={'analytics.totalPeriod'}/>,
          rowSpan: 2,
          styles: {
            textAlign: 'right !important',
            width: columnWidth
          }
        }
      ]
    },
    {
      cellsList: [
        ...tableParams[tableNumb - 1].columns.map(column => ({
          ...column,
          rowSpan: 2,
          topStyleForStickyHeader: true,
          styles: {
            background: column.title === EMPTY_COLUMN
              ? `${DISABLED_COLOR} !important`
              : '',
            borderColor: column.title === EMPTY_COLUMN
              ? `${ACTIVE_COLOR} !important`
              : '',
            width: columnWidth
          }
        }))
      ]
    }
  ]
}
