import {request} from "@/internal/lib/transport/request";
import {createReportAdapter, getReportAdapter, setPreviewAdapter} from "./reports_adapters";

export const reportsEndpoint = {
  getAll () {
    const params = {
      url: 'api/report/',
      method: 'GET'
    }

    return request(params)
  },

  getReportInfoById (id) {
    const params = {
      url: `api/report/${id}/`,
      method: 'GET'
    }

    return request(params, getReportAdapter)
  },

  createReport (data) {
    const params = {
      url: 'api/report/',
      method: 'POST',
      data: createReportAdapter(data)
    }

    return request(params)
  },

  changeReportData (data) {
    const params = {
      url: `api/report/${data.id}/`,
      method: 'PUT',
      data: createReportAdapter(data)
    }

    return request(params)
  },

  deleteReport (id) {
    const params = {
      url: `api/report/${id}/`,
      method: 'DELETE',
    }

    return request(params)
  },

  getReportHistory (id) {
    const params = {
      url: `api/report/${id}/journal/`,
      method: 'GET'
    }

    return request(params)
  },

  getUnsavedReportPreview (data) {
    const params = {
      url: 'api/report/preview/',
      method: 'POST',
      data: setPreviewAdapter(data)
    }

    return request(params)
  },

  getReportPreviewTaskId (id) {
    const params = {
      url: `api/report/task/${id}/`,
      method: 'GET'
    }

    return request(params)
  }
}
