import {useToggle} from "@/internal/custom_hooks/useToggle";
import React from "react";
import {FormattedMessage} from "react-intl";
import {SvgIcon} from "@mui/material";

const DropdownIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M0.5 1L6.5 7L12.5 1"/>
    </SvgIcon>
  )
}

const WithHistory = ({children, title, historyList}) => {
  const [isShow, toggleIsShow] = useToggle(false)

  return (
    <div className="object-form__history-wrapper">
      {children}

      {!!historyList.length &&
        <div className="object-form__history">
          <div
            onClick={toggleIsShow}
            className="object-form__history-show"
          >
            <span>
              <FormattedMessage id={'addressForm.changesHistory'} />
            </span>

            <div className="object-form__history-show-icon">
              <DropdownIcon
                viewBox={'0 0 13 8'}
                sx={{
                  fill: 'none',
                  transition: 'transform .2s ease-out',
                  transform: `rotate(${isShow ? '180deg' : '0'})`,
                  path: {
                    stroke: '#0060F6'
                  }
                }}
              />
            </div>
          </div>

          {
            isShow &&
            <div className="object-form__history-list">
              <span>{title}:</span>

              <div className="object-form__history-items">
                {historyList}
              </div>
            </div>
          }
        </div>
      }
    </div>
  )
}

export default WithHistory
