class CookiesManager {
  get(name) {
    const cookies = this.getAll()
    return cookies[name]
  }

  getAll() {
    const pairs = document.cookie.split(';');
    const cookies = {};

    for (let i = 0; i < pairs.length; i++){
      const pair = pairs[i].split('=');
      cookies[(pair[0] + '').trim()] = decodeURIComponent(pair[1]);
    }
    return cookies;
  }

  set(name, value = '', days = 365) {
    const date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));

    const chunks = [      `${name}=${value}`,
      `expires=${date.toUTCString()}`,

      'path=/'
    ].join('; ');
    document.cookie = chunks;
  }

  cookiesDelete() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
      document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    document.cookie = 'X-pulse-admin=; path=/; domain=.e-watt.ru; expires=' + new Date(0).toUTCString();
    document.cookie = 'X-pulse-admin=; domain=.e-watt.ru; expires=' + new Date(0).toUTCString();
  }

  getCookieToken() {
    return (cookiesManager.get('X-pulse-admin') ?? '').length === 0
      ? cookiesManager.get('X-pulse')
      : cookiesManager.get('X-pulse-admin')
  }
}

const cookiesManager = new CookiesManager()

export default cookiesManager
