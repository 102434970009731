import AsyncSelect from "react-select/async";
import {ASYNC_SELECT_STYLES, ASYNC_SELECT_STYLES_ERROR} from "./const";
import {components} from "react-select";
import {FormattedMessage} from "react-intl";

const AsyncSelectComponent = (
  {
    customClass='',
    loadOptions,
    handleChange,
    name,
    disabled = false,
    noOptionsMessage = () => <FormattedMessage id={'app.noData'}/>,
    loadingMessage = () => <FormattedMessage id={'authorization.loading'}/>,
    onInputChange,
    inputValue = '',
    placeholder = '',
    isError = false
  }
) => {
  return (
    <AsyncSelect
      defaultOptions
      className={`select-component ${customClass}`}
      components={{ DropdownIndicator, Input }}
      loadOptions={loadOptions}
      value={null}
      onChange={handleChange}
      styles={!isError ? ASYNC_SELECT_STYLES : ASYNC_SELECT_STYLES_ERROR}
      autoFocus={false}
      maxMenuHeight={180}
      menuPosition={'absolute'}
      menuPlacement={'auto'}
      name={name}
      defaultInputValue={''}
      blurInputOnSelect={false}
      onInputChange={onInputChange}
      isDisabled={disabled}
      noOptionsMessage={noOptionsMessage}
      loadingMessage={loadingMessage}
      inputValue={inputValue}
      placeholder={placeholder}
    />
  )
}

const DropdownIndicator = () => {
  return null
}

const Input = (props) => {
  return (
    <components.Input {...props} autoComplete={'off'}/>
  )
}

export default AsyncSelectComponent
