import {useMemo, useState} from "react";
import {TableCell, TableRow} from "@mui/material";
import InvestmentsTableCell from "./InvestmentsTableCell";
import SumTableCell from "../SumTableCell";
import EditAddressButton from "@/presentation/components/analytics/сomponents/EditAddressButton";

const InvestmentsTableRow = (
  {
    address,
    columns,
    columnWidth,
    setRandomData,
    modelInfo
  }) => {
  const [activeCell, setActiveCell] = useState(null)

  const onFocusInput = (e, id) => {
    e.target.select()
    setActiveCell(id)
  }

  const inputCells = useMemo(() => {
    return columns.map((column, i) => {
      const id = `${address.id}:${column.value}-${i}`

      return (
        <InvestmentsTableCell
          key={id}
          id={id}
          valueKey={column.value}
          activeCell={activeCell}
          columnWidth={columnWidth}
          setRandomData={setRandomData}
          modelInfo={modelInfo}
          onFocusInput={onFocusInput}
        />
      )
    })
  }, [activeCell, modelInfo])

  return (
    <TableRow onBlur={() => setActiveCell(null)}>
      <TableCell
        sx={{
          fontWeight: '500 !important',
          minWidth: columnWidth,
          textAlign: 'left !important'
        }}
      >
        <div className="tables__address-cell">
          <span>
            {address.name}
          </span>

          <div className="tables__address-btns">
            <EditAddressButton
              addressId={address.id}
            />
          </div>
        </div>
      </TableCell>

      {inputCells}

      <SumTableCell
        selectors={modelInfo.selectors}
        columnWidth={columnWidth}
        useSumSpaceName={'useInvestSum'}
      />
    </TableRow>
  )
}

export default InvestmentsTableRow
