import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";
import {createEffect, createEvent, sample} from "effector";
import {setMessage} from "@/presentation/shared/ui/message";
import {useEvent, useStore} from "effector-react";
import {meterointsEndpoint} from "@/data/api_entities/meteroints/meteroints_endpoint";

const meterointsModel = new StoreModelFabric([])
const loadingMeterointsModel = new StoreModelFabric(true)
const updateMeteroints = createEvent()

meterointsModel.getData = createEffect(() => {
  loadingMeterointsModel.setState(true)

  meterointsEndpoint.getAllMeteroints().promise
    .then(res => {
      meterointsModel.setState(res.metering_points ?? [])
    })
    .catch(err => {
      setMessage({
        header: 'Ошибка!',
        message: 'Не удалось загрузить список точек учета',
        type: 'error',
        count: 3000
      })
      console.log(err)
    })
    .finally(() => loadingMeterointsModel.setState(false))
})

sample({
  clock: updateMeteroints,
  target: meterointsModel.getData
})


export const meterointsSelectors = {
  meteroints: {
    ...meterointsModel.createSelectors(),
    useGetMeteroints: () => useEvent(meterointsModel.getData),
    useUpdateMeteroints: () => useEvent(updateMeteroints),
    useMeterointsAvailable: () => !!useStore(meterointsModel.$store).length,
  },
  loadingMeteroints: loadingMeterointsModel.createSelectors(),
}
