import {FormattedMessage} from "react-intl";
import {StatusSettingsHeaderRight} from "@/assets/svg";

// const StoreBlock = () => {
//   return (
//     <div className="store-block">
//       <h3 className="title_h3">
//         <FormattedMessage id={'main.noDevices'}/>
//       </h3>
//
//       <a
//         href="https://e-watt.ru"
//         target={'_blank'}
//         className="btn"
//       >
//         <FormattedMessage id={'main.onlineStore'}/>
//       </a>
//
//       <img src={StatusSettingsHeaderRight} alt="StatusSettingsHeaderRight"/>
//     </div>
//   )
// }
//
// export default StoreBlock

const StoreBlock = () => {
    return (
        <div >
            <a
                href="https://e-watt.ru"
                target={'_blank'}
                className="btn"
            >
                <FormattedMessage id={'main.onlineStore'}/>
            </a>

        </div>
    )
}

export default StoreBlock

