import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'authorization'
const translations = {
  enter: 'Eingang',
  apologies: 'Es tut uns leid, aber der server ist vorübergehend nicht verfügbar.',
  'e-mail': 'e-mail',
  mailPlaceHolder: 'user@gmail.com',
  password: 'Passwort',
  signIn: 'Einloggen',
  'forgetPassword?': 'Passwort vergessen?',
  'noAcc?': 'Sie haben kein konto?',
  register: 'Registrieren',
  'haveAcc?': 'Sie haben bereits ein Konto?',
  lookDemo: 'Sehen sie,<a></a>wie alles funktioniert',
  demo: 'Demo',
  passwordReset: 'Dein passwort wurde zurück gesetzt',
  error: 'Fehler',
  youRegistered: 'Sie sind registriert',
  confirmPasswordReset: 'Es bleibt, das zurücksetzen des passworts zu bestätigen',
  confirmEmail: 'Es bleibt die mail zu bestätigen',
  invalidLink: 'Link ist ungültig!',
  emailConfirmed: 'Sie haben Ihre e-mail bestätigt!',
  weSendMail: 'Wir haben eine e-mail mit einem link zu ihrer {email} gesendet',
  checkMail: 'Post checken',
  sending: 'Senden...',
  sent: 'Gesendet. Wiederholen sie durch 00:{time}',
  sendAgain: 'Nochmals senden',
  resetPassword: 'Setzen sie das passwort zurück',
  recoveryPassword: 'Passwort wiederherstellung',
  createNewPassword: 'Erstelle ein neues passwort',
  newPassword: 'Neues passwort',
  repeatPassword: 'Passwort wiederholen',
  saveNewPassword: 'Neues passwort speichern',
  invalidData: 'Ungültige oder ausgelastete daten',
  registration: 'Anmeldung',
  individual: 'Individuell',
  corporate: 'Firmenkunde',
  acceptTerms: 'Ich akzeptiere die <tag>servicebedingungen</tag>',
  createAcc: 'Ein konto erstellen',
  inn: 'INN',
  legalName: 'Offizielle unternehmensbezeichnung',
  legalAddress: 'Juristische adresse',
  actualAddress: 'Die eigentliche adresse',
  ifActualIsLegal: 'Wenn die tatsächliche adresse mit der rechtlichen adresse übereinstimmt',
  contactTel: 'Kontakt',
  phoneOptional: 'Telefon (optional)',
  legalNameOrINN: 'Geben sie den namen der juristischen person ein',
  findOrg: 'Finden sie eine organisation',
  loading: 'Wird geladen...',
  login: 'Name',
  accept: 'Akzeptieren'
}

export const authorizationDe = createTranslationsWithSpaceName(translations, spaceName)
