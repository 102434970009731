import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const translations = {
  shortLogin: `Имя не должно быть короче {length, plural, one {# символа} other {# символов}}`,
  longLogin: `Имя не должно привышать {length, plural, one {# символа} other {# символов}}`,
  longName: 'Название не должно привышать {length, plural, one {# символа} other {# символов}}',
  login: 'Некорректное имя пользователя',
  email: 'Некорректный адрес',
  phone: 'Некорректный номер телефона',
  required: 'Обязательное поле',
  repeat: 'Пароли не совпадают',
  minDate: 'Попробуйте более поздний интервал',
  maxDate: 'Интервал не может привышать текущую дату',
  notFoundEmail: 'Пользователь с данной почтой не найден',
  inn: 'Некорректное значение',
  notFullRangeData: 'Выберите конец интервала',
  notMinus: 'Не может быть отрицательным',
  wrongLon: 'Некорректная долгота',
  wrongLat: 'Некорректная широта',
  wrongValue: 'Некорректное значение',
  notZero: 'Не может равняться 0',
  incorrectSerialNumber: 'Серийный номер должен начинаться с "{startSerial}"'
}

export const validationsMsgsRu = createTranslationsWithSpaceName(translations, 'validationsMsgs')
