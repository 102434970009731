import {components} from "react-select";

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      {props.children.props.children.props.children[0]}
    </components.SingleValue>
  )
}

export default SingleValue
