import Spinner from "@/presentation/components/loader/Spinner";
import {FormattedMessage} from "react-intl";

const LoadingWrapper = () => {
  return (
    <div className={'loading'}>
      <div className="loading__content">
        <Spinner />

        <span>
          <FormattedMessage id={'app.prepareSystem'}/>
        </span>
      </div>
    </div>
  )
}

export default LoadingWrapper
