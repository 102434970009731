import {useState} from "react";
import SelectComponent from "@/presentation/components/common/selects/Select";
import {formTypesList} from "./const";
import LegalForm from "./LegalForm";
import IndividualForm from "./IndividualForm";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import qs from 'query-string';
import {CustomizedSnackbars} from "@/presentation/components/alert";
import {TOP_UP_PATH} from "../../const";
import {balanceEndpoint} from "@/data/api_entities/balance/balance_endpoint";
import {FormattedMessage, useIntl} from "react-intl";

const Balance = () => {
  const intl = useIntl()
  let path = qs.parse(window.location.search)
  const [isLegalForm, setIsLegalForm] = useState(false)
  const [defaultSum, setDefaultSum] = useState(100)
  let alertSt = false

  const handleSelectChange = (e) => setIsLegalForm(e.value === 'legal')

  if('amount' in path){
    alertSt = true
    if(+path.success){
      path.header = <FormattedMessage id={'requests.topUpAccountSuccessHeader'} />
      path.paragraph = <FormattedMessage id={'requests.topUpAccountSuccess'} values={{amount: +path.amount}}/>
    }else{
      path.header = <FormattedMessage id={'requests.topUpAccountErrorHeader'} />
      path.paragraph = <FormattedMessage id={'requests.topUpAccountError'} />
    }
  }

  const handleChangeDefaultSum = (e) => {
    setDefaultSum(e.target.value)
  }

  const handleClick = () => {
    balanceEndpoint.setPayment(defaultSum).promise.then((result) => {
      window.location.replace(result);
    })
  }

  return (
    <div className='user-page__column user-page__column_balance'>
      <ArrowBack pathOnVisible={TOP_UP_PATH}/>
      <h2 className="title">
        <FormattedMessage id={'userPage.topUpAccount'}/>
      </h2>
      <div className="user-page__steps">
        <div className="user-page__step">
          <h4 className="user-page__step-title title_h4">
            <FormattedMessage id={'userPage.bankCard'}/>
          </h4>

          <p>
            <FormattedMessage id={'userPage.byPlatron'}/>
          </p>

          <div className="form-group">
            <label>
              <FormattedMessage id={'userPage.byPlatron'}/>
            </label>

            <input
              type="number"
              value={defaultSum}
              onChange={handleChangeDefaultSum}
            />
          </div>

          <button
            className="btn user-page__add-balance-btn"
            onClick={handleClick}
          >
            <FormattedMessage id={'app.proceed'}/>
          </button>
        </div>

        {/*<div className="user-page__step">
          <h4 className="user-page__step-title title_h4">2. Оплата по счету</h4>

          <SelectComponent
            handleChange={handleSelectChange}
            optionsList={formTypesList}
            value={formTypesList[1]}
          />

          {
            isLegalForm
              ? <LegalForm />
              : <IndividualFormCreateUser />
          }

          <button className="btn btn_transparent-bg">Запросить счет-оферту</button>
        </div>*/}
      </div>
      {alertSt ? <CustomizedSnackbars pr={path}/> : <></> }
    </div>
  )
}

export default Balance
