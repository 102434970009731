import {RESOURCES_TYPES_ARRAY} from "@/presentation/components/intake/const";
import {toggleItems} from "@/internal/helpers/helpers";
import {intakeSelectors} from "@/internal/lib/storeModels/models/intake/intakeModels";
import {useEffect} from "react";
import {meteringsEndpoint} from "@/data/api_entities/meterings/meterings_endpoint";
import {selectors} from "@/presentation/shared/ui/message";
import {sampleParamsFormSelectors} from "@/internal/lib/storeModels/models/intake/forms/sampleParamsFormModel";

const ResourcesList = () => {
  const resourceTypes = intakeSelectors.resourceTypes.useValue()
  const [activeTypes, setActiveTypes] = intakeSelectors.activeTypes.useState()

  const handleSetActiveTypes = (type) => {
    if (!resourceTypes.includes(type)) return

    toggleItems(type, activeTypes, setActiveTypes)
  }

  const resourcesList = RESOURCES_TYPES_ARRAY.map(resource => {
    const disabledClass = !resourceTypes.includes(resource.type) ? 'disabled' : ''
    const activeClass = activeTypes.includes(resource.type) ? 'active' : ''

    return (
      <div
        key={resource.type}
        className={`intake__resources-item ${activeClass} ${disabledClass}`}
        onClick={() => handleSetActiveTypes(resource.type)}
      >
        <div className="intake__resources-img">
          <img src={resource.img} alt="resource"/>
        </div>
      </div>
    )
  })

  return (
    <div className="intake__resources-list">
      {resourcesList}
    </div>
  )
}

export default ResourcesList
