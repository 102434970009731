import React, {useEffect, useMemo, useRef, useState} from "react";
import {FormattedMessage} from "react-intl";
import {PageReturn} from "@/assets/svg";
import Switch from "@mui/material/Switch";
import Loader from "@/presentation/components/loader/Loader";
import {authorizationSelectors} from "@/internal/lib/storeModels/models/authorization/authorizationModels";
import {
  registrationPasswordValidationsSelectors
} from "@/internal/lib/storeModels/models/authorization/registrationPasswordModel";
import IndividualFormCreateUser from "@/presentation/components/mainPageService/components/IndividualFormCreateUser";
import CorporateFormCreateUser from "@/presentation/components/mainPageService/components/CorporateFormCreateUser";
import {corporateUserFormSelectors, individualUserFormSelectors} from "@/internal/lib/storeModels/models/servicemen/formModels";
import PasswordRules from "@/presentation/components/common/PasswordRules";
import {isOpenCreateUserFormSelectors} from "@/internal/lib/storeModels/models/servicemen/stores";
import {
  corporateFormSelectors,
  individualFormSelectors
} from "@/internal/lib/storeModels/models/authorization/forms/registrationUserModels";
import {authorizationEndpoint} from "@/data/api_entities/authorization/authorization_endpoint";
import {appSelectors} from "@/internal/lib/storeModels/models/app/appModels";
import {modelMessage, selectors} from "@/presentation/shared/ui/message";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";

const UserForm = ({toggleUpdate}) => {
  const substrRef = useRef()

  const [isIndividual, setIsIndividual] = useState(true)
  const [isPdfOpen, setIsPdfOpen] = useState(false)
  const [isOffertAgree, setIsOffertAgree] = useState(false)

  const passwordValidations = registrationPasswordValidationsSelectors.usePasswordValidations()
  const resetPasswordValidations = registrationPasswordValidationsSelectors.useResetValidations()
  const isValidUserForm = individualFormSelectors.useIsFormValid()
  const resetIndividualForm = individualFormSelectors.useResetForm()
  const setOnValidIndividualForm = individualFormSelectors.useSetOnValidSubmit()
  const submitIndividualForm = individualFormSelectors.useOnClickSubmit()
  const isValidCorpForm = corporateFormSelectors.useIsFormValid()
  const resetCorpForm = corporateFormSelectors.useResetForm()
  const setOnValidCorpForm = corporateFormSelectors.useSetOnValidSubmit()
  const submitCorpForm = corporateFormSelectors.useOnClickSubmit()
  const [isLoadingCorporateRegistration, setIsLoadingCorporateRegistration] = authorizationSelectors.isLoadingCorporateRegistration.useState()
  const [isLoadingUserRegistration, setIsLoadingUserRegistration] = authorizationSelectors.isLoadingUserRegistration.useState()
  const [confirmRegistrationData, setRegistrationData]= authorizationSelectors.registrationData.useState()
  const [registrationErrorMsg, setRegistrationErrorMsg] = authorizationSelectors.registrationErrorMsg.useState()
  const setMessage = selectors.useSetMessage()
  const toggleIsPdfOpen = () => setIsPdfOpen(!isPdfOpen)
  const userData = userDataSelectors.userData.useValue()
  const toggleIndividual = () => setIsIndividual(!isIndividual)

  const [isOpenAside, setIsOpenAside]= isOpenCreateUserFormSelectors.useState()

  const isValidForm = useMemo(() => {
    return isValidUserForm && isValidCorpForm && passwordValidations.isValid
  }, [isValidUserForm, passwordValidations.isValid, isValidCorpForm])

  const onValidUserFormSubmit = (data) => {
    setIsLoadingUserRegistration(true)

    authorizationEndpoint.registerNewUser({...data, servicemanager: userData?.id})
      .then(res => {
        if (res.code === 201) {
          // setRegistrationData(data)
          setMessage({
            header: 'Создание клиента',
            message: 'Клиеннт успешно создан!',
          })
          hideAside()
          resetCorpForm()
          resetIndividualForm()
          toggleUpdate()
        } else if (res.code === 441 || res.code === 442) {
          setRegistrationErrorMsg(<FormattedMessage id={'authorization.invalidData'}/>)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setIsLoadingUserRegistration(false))
  }

  const onValidCorpFormSubmit = (data) => {
    const dataWithLogin = {
      ...data,
      login: data.organization_name,
      servicemanager: userData?.id
    }

    setIsLoadingCorporateRegistration(true)

    authorizationEndpoint.registerNewUser(dataWithLogin)
      .then(res => {
        if (res.code === 201) {
          setMessage({
            header: 'Создание клиента',
            message: 'Клиеннт успешно создан!',
          })
          hideAside()
          resetCorpForm()
          resetIndividualForm()
          toggleUpdate()
        } else if (res.code === 441 || res.code === 442) {
          setRegistrationErrorMsg(<FormattedMessage id={'authorization.invalidData'}/>)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setIsLoadingCorporateRegistration(false))
  }

  useEffect(() => {
    setOnValidIndividualForm(onValidUserFormSubmit)
    setOnValidCorpForm(onValidCorpFormSubmit)

    return () => {
      resetIndividualForm()
      resetCorpForm()
    }
  }, [userData])

  useEffect(() => {
    if (!substrRef.current) return

    substrRef.current.scrollTo(0, 0)
  }, [isOpenAside])

  const onClickAsideSubstr = (e) => {
    const {target} = e

    // if (target && !target.closest('.aside-form__substr')) {
    //   hideAside()
    // }
  }

  useEffect(() => {
    resetPasswordValidations()
    resetIndividualForm()
    resetCorpForm()
    setIsOffertAgree(false)
    setRegistrationErrorMsg('')
  }, [isIndividual])

  const hideAside = () => {
    setIsOpenAside(false)
  }
  // const onClickOffertCheckbox = () => setIsOffertAgree(!isOffertAgree)

  const handleSubmit = () => {
    if (!!registrationErrorMsg) setRegistrationErrorMsg('')

    if (!isValidForm) return

    if (isIndividual) {
      submitIndividualForm()
    } else {
      submitCorpForm()
    }
  }

  return (
    <div
      className={`aside-form ${isOpenAside === null ? '' : isOpenAside ? 'aside-form_open' : 'aside-form_hidden'} service`}
      onClick={onClickAsideSubstr}
    >
      <div
        ref={substrRef}
        className="aside-form__substr"
      >
        {!!hideAside &&
          <div className='object-form__back-img' onClick={hideAside}>
            <img
              src={PageReturn}
              alt="PageReturn"
            />
          </div>
        }

        <div className={'title'}>
          <span>Создание клиента</span>
        </div>

        <Loader loading={isLoadingUserRegistration || isLoadingCorporateRegistration}>
          <div className="registration__switch">
          <span>
            <FormattedMessage
                id={'authorization.individual'}
                values={{
                  a: () => (<br></br>)
                }}
            />
          </span>

            <Switch
                onClick={toggleIndividual}
            />

            <span>
            <FormattedMessage
                id={'authorization.corporate'}
                values={{
                  a: () => (<br></br>)
                }}
            />
          </span>
          </div>

          <div className="form-fields">
            {
              isIndividual
                ? <IndividualFormCreateUser />
                : <CorporateFormCreateUser />
            }
          </div>

          {!passwordValidations.isValid &&
            <PasswordRules passwordValidations={passwordValidations}/>
          }

          {!!registrationErrorMsg &&
            <span className={'sign-in__incorrect-msg'}>{registrationErrorMsg}</span>
          }

          <button
            className={'btn service-create-btn'}
            onClick={handleSubmit}
            disabled={!isValidForm}
          >

            <FormattedMessage id={'authorization.createUsr'}/>
          </button>
        </Loader>

      </div>
    </div>
  )
}

export default UserForm
