import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {INIT_PRECONFIG_DATA, INIT_PRECONFIG_VALIDATIONS} from "../const";
import {getPreconfigAnalyticValidators} from "@/internal/validators/formValidators";

const preconfigFormModel = new FormModelFabric({
  initialData: INIT_PRECONFIG_DATA,
  initialValidations: INIT_PRECONFIG_VALIDATIONS,
  getValidators: getPreconfigAnalyticValidators
})

export const preconfigFormSelectors = preconfigFormModel.createSelectors()
