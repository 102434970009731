import {calculationsSelectors} from "@/internal/lib/storeModels/models/mkds/calculations/calculationsState";
import Loader from "@/presentation/components/loader/Loader";
import React, {useEffect, useRef, useState} from "react";
import {heatCalculateEndpoints} from "@/data/api_entities/heat_calculate/heat_calculate_endpoints";
import CalcCard from "@/presentation/components/heatCalculationPage/components/MKDPage/components/CalcCard";
import {selectors} from "@/presentation/shared/ui/message";
import {FormattedMessage} from "react-intl";
import {mkdsStateSelectors} from "@/internal/lib/storeModels/models/mkds/mkdsState";
import dayjs from 'dayjs'
import useWindowSize from "@/internal/custom_hooks/windowResizeHook";

const CalcList = ({loading}) => {
  const [list, setList] = calculationsSelectors.calcList.useState()
  const lastGetListRequest = useRef(null)
  const [paginationParams, setPaginationParams, resetPaginationParams] = calculationsSelectors.calcListPaginationParams.useState()
  const [paginationLoading, setPaginationLoading] = useState(false)
  const setMessage = selectors.useSetMessage()
  const selectedMKDId = mkdsStateSelectors.selectedMKD.useValue()
  const listLoaderRef = useRef(null)
  const windowSize = useWindowSize()

  useEffect(() => {
    return () => {
      resetPaginationParams()
    }
  }, [])

  useEffect(() => {
    const wrapper = listLoaderRef.current?.wrapper.current

    if (wrapper) {
      const hasVerticalScroll = wrapper.scrollHeight > wrapper.clientHeight

      if (!hasVerticalScroll && paginationParams) {
        if (paginationLoading) return
        uploadNextPage()
      }
    }
  }, [paginationParams, windowSize, list])

  const uploadNextPage = () => {
    if (!paginationParams) return
    lastGetListRequest.current?.abort()

    setPaginationLoading(true)
    const {promise, controller} = heatCalculateEndpoints.getHistory(selectedMKDId, paginationParams.nextPage)
    lastGetListRequest.current = controller
    promise
      .then(res => {
        if (Array.isArray(res?.results)) {
          const newList = []
          res.results.forEach(calc => {
            const preexistElem = list.find(elem => elem.id === calc.id)
            if (preexistElem) return
            newList.push(calc)
          })
          setList([...list, ...newList])
          if (res.current_page < res.total_pages) {
            setPaginationParams({
              nextPage: paginationParams.nextPage + 1,
              totalPages: res.total_pages
            })
          } else {
            setPaginationParams(null)
          }
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось загрузить список расчетов',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
        lastGetListRequest.current = null
        setPaginationLoading(false)
      })
      .catch(err => {
        if (err.name === 'AbortError') return
        lastGetListRequest.current = null
        setPaginationLoading(false)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось загрузить список расчетов',
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
  }

  const handleScrollList = (event) => {
    if (paginationLoading) return
    const wrapper = event.target

    if (wrapper.scrollTop + wrapper.clientHeight >= wrapper.scrollHeight * 0.99) {
      uploadNextPage()
    }
  }

  const cards = list.map(calc => {
    return (
      <CalcCard
        key={calc.id}
        data={calc}
      />
    )
  })

  return (
    <Loader
      loading={loading}
      styles={{
        wrapper: (base) => ({
          ...base,
          width: '100%',
          overflow: 'auto',
          minHeight: '100px'
        }),
      }}
      propRef={listLoaderRef}
      onScrollWrapper={handleScrollList}
    >
      <div className={'calc-list'}>
        {
          !!list.length
            ? cards
            : <p className={'calc-list__empty-list'}>Нет сохраненных расчетов. Создайте новый нажав на +</p>
        }
      </div>
    </Loader>
  )
}

export default CalcList
