import {calculationSettingsFormSelectors} from "@/internal/lib/storeModels/models/mkds/calculations/forms";
import {mkdsStateSelectors} from "@/internal/lib/storeModels/models/mkds/mkdsState";
import {MKDHelper} from "@/presentation/components/heatCalculationPage/helpers";
import TablePaginationActions
  from "@/presentation/components/heatCalculationPage/components/MKDPage/components/TablePaginationActions";
import {TablePagination} from "@mui/material";
import {useIntl} from "react-intl";
import {UNIT_TYPES} from "@/presentation/components/heatCalculationPage/const";
import {calculationsSelectors} from "@/internal/lib/storeModels/models/mkds/calculations/calculationsState";
import {dateFormatter} from "@/internal/formatters/formatters";

const TableLabel = ({onChangePage, paginationParams, loading}) => {
  const intl = useIntl()
  const settingsForm = calculationSettingsFormSelectors.useFormData()
  const currentMKDId = mkdsStateSelectors.selectedMKD.useValue()
  const currentMKD = mkdsStateSelectors.list.useValue().find(mkd => mkd.id == currentMKDId)
  const [rowsPerPage, setRowsPerPage] = calculationsSelectors.rowsPerPage.useState()

  return (
    <div className={'table-label'}>
      <div className={'table-label__period'}>
        <span>
          Адрес: {MKDHelper.getRegion(currentMKD ?? null)}, {MKDHelper.getAddress(currentMKD ?? null)};
          ОДН - {currentMKD.odn}%;
        </span>
        <span>
          За период: {dateFormatter(settingsForm.start_date, true)} -  {dateFormatter(settingsForm.end_date, true)}
        </span>
        <span>
          Потреблено: {settingsForm.energy_period} {UNIT_TYPES.find(type => type.value === settingsForm.unit_type)?.label}
        </span>
      </div>

      <TablePagination
        component={'div'}
        sx={{
          opacity: loading ? .5 : 1,
          transition: 'opacity .15s ease'
        }}
        count={paginationParams.count}
        page={paginationParams.currentPage}
        ActionsComponent={TablePaginationActions}
        onPageChange={onChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(+e.target.value)
          onChangePage(null, 0, +e.target.value)
        }}
        labelRowsPerPage={'Кол-во квартир на странице'}
        rowsPerPageOptions={[20, 50, 100]}
        labelDisplayedRows={({from, to, count}) => {
          return intl.formatMessage({id: 'app.countOf'}, {
            from,
            to,
            count: count !== -1
              ? count
              : intl.formatMessage(
                {id: 'app.moreThen'},
                {to}
              )
          })
        }}
      />
    </div>
  )
}

export default TableLabel
