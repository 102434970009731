const DottedSpinner = ({scale= 1}) => {
  return (
    <div className={'dotted-spinner'} style={{transform: `scale(${scale})`}}>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default DottedSpinner
