import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {addressEndpoint} from "@/data/api_entities/address/address_endpoint";
import {ANALYSIS_PERIOD_TYPES, ANALYSIS_STEPS_LIST, SELECT_STYLES} from "../const";
import ValidationsWrapper from "@/presentation/components/common/formComponents/ValidationsWrapper";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddressForm from "@/presentation/components/addressForm/AddressForm";
import MultiObjectSelect from "@/presentation/components/common/selects/MultiObjectSelect";
import Input from "@/presentation/components/common/formComponents/Input";
import SelectInput from "@/presentation/components/common/formComponents/SelectInput";
import Loader from "@/presentation/components/loader/Loader";
import {
  newSelectedAddressIdSelectors,
} from "@/internal/lib/storeModels/models/analytics/newSelectedAddressModel";
import {
  analyticsSelectors
} from "@/internal/lib/storeModels/models/analytics/analyticsModels";
import {selectors} from "@/presentation/shared/ui/message";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import {
  analyticChangeAddressFormSelectors,
  analyticNewAddressFormSelectors
} from "@/internal/lib/storeModels/models/analytics/forms/addressFormModels";
import {preconfigFormSelectors} from "@/internal/lib/storeModels/models/analytics/forms/preconfigFormModel";
import {cloneDeep} from "lodash";
import {analyticsEndpoint} from "@/data/api_entities/analytics/analytics_endpoint";
import {BY_CONTINUE_TYPE, MIN_DATE} from "./const";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import 'dayjs/locale/ru';
import 'dayjs/locale/de';
import DatePickerInput from "@/presentation/components/common/formComponents/DatePickerInput";
import {dateFormatter, formattedDateToServFirstDay} from "@/internal/formatters/formatters";
import {appSelectors} from "@/internal/lib/storeModels/models/app/appModels";
import {FormattedMessage} from "react-intl";
import {activeInvestStepSelectors} from "@/internal/lib/storeModels/models/analytics/tableStepsModels";
import {ANALYTIC_UNIT_TYPES_ANALYTICS_PRECONFIG} from "@/presentation/components/addressForm/const";
import {selectedUserSelectors} from "@/internal/lib/storeModels/models/servicemen/selectedUserModel";

dayjs.extend(utc)

const now = new Date()

const Preconfig = (
  {
    initialData = null,
    allAddresses,
    addresses,
    setActiveStep,
    getObjWithAllMeterings,
    completedData = null,
    createModelsPromise,
    setModels,
    getInfo = null
  }) => {
  const locale = appSelectors.locale.useValue()
  const formData = preconfigFormSelectors.useFormData()
  const formValidations = preconfigFormSelectors.useFormValidations()
  const isValidForm = preconfigFormSelectors.useIsFormValid()
  const setOnValidSubmit = preconfigFormSelectors.useSetOnValidSubmit()
  const selectedUser = selectedUserSelectors.useValue()
  const setFormDataValue = (name) => preconfigFormSelectors.setFormDataValue(name)
  const setInitData = preconfigFormSelectors.useSetInitFormData()
  const onClickSubmit = preconfigFormSelectors.useOnClickSubmit()
  const [lastCreateAnalyticRequest, setLastCreateAnalyticRequest, resetLastCreateAnalyticRequest] = analyticsSelectors.lastCreateAnalyticRequest.useState()
  const [isCreatingAddress, setIsCreatingAddress] = analyticsSelectors.isCreatingAddress.useState()
  const [isLoadingEditAddress, setIsLoadingEditAddress] = analyticsSelectors.isLoadingEditAddress.useState()
  const [analyticId, setAnalyticId] = analyticsSelectors.analyticId.useState()
  const [editAddressId, setEditAddressId, resetEditAddressId] = analyticsSelectors.editAddressId.useState()
  const [isShowEditForm, setIsShowEditForm, resetIsShowEditForm] = analyticsSelectors.isShowEditForm.useState()
  const [isShowAddForm, setIsShowAddForm, resetIsShowAddForm] = analyticsSelectors.isShowAddForm.useState()
  const updateAddresses = addressesSelectors.addresses.useUpdateAddresses()
  const resetNewSelectedAddressId = newSelectedAddressIdSelectors.useReset()
  const setNewSelectedAddressId = newSelectedAddressIdSelectors.useSetValue()
  const setMessage = selectors.useSetMessage()
  const setActiveInvestStep = activeInvestStepSelectors.useSetValue()

  const [preexistingAddressInfo, setPreexistingAddressInfo] = useState(null)
  const [preexistAddresses, setPreexistAddresses] = useState([])
  const [isCreatingModels, setIsCreatingModels] = useState(false)

  const [isOpenPreexistModal, togglePreexistModal] = useToggle()

  const minStartDate = useMemo(() => {
    if (!formData.end_date) return MIN_DATE
    const endDate = dayjs(formData.end_date)

    const fiveYearsBeforeEndDate = endDate.year(endDate.year() - 5).month(endDate.month() + 1)

    return fiveYearsBeforeEndDate.valueOf() < dayjs(MIN_DATE).valueOf()
      ? MIN_DATE
      : fiveYearsBeforeEndDate
  }, [formData.end_date])

  const minEndDate = useMemo(() => {
    if (completedData?.newAnalysisType === BY_CONTINUE_TYPE) {
      const endDate = dayjs(completedData.end_date)

      const nextMontDate = endDate.month(endDate.month() + 1)

      return nextMontDate
    } else {
      const startDate = formData.start_date
        ? dayjs(formData.start_date)
        : dayjs(MIN_DATE)

      const nextMontDate = startDate.month(startDate.month() + 1)

      return nextMontDate
    }

  }, [formData.start_date, completedData])

  const maxStartDate = useMemo(() => {
    const endDate = formData.end_date
      ? dayjs(formData.end_date)
      : dayjs(Date.now())

    const prevMontDate = endDate.month(endDate.month() - 1)

    return prevMontDate
  }, [formData.end_date])

  const maxEndDate = useMemo(() => {
    if (!formData.start_date) return Date.now()

    const startDate = dayjs(formData.start_date)
    const fiveYearsByStartDate = startDate.year(startDate.year() + 5).month(startDate.month() - 1)

    return fiveYearsByStartDate.valueOf() > now
      ? Date.now()
      : fiveYearsByStartDate
  }, [formData.start_date])

  const handleClickEditAddress = (e, id) => {
    e.stopPropagation()

    setEditAddressId(id)
    setIsShowAddForm(false)

    setIsShowEditForm(true)
  }

  const selectedAddresses = useMemo(() => {
    const addressesIdsArray = Object.keys(formData.accounting_objects)

    return allAddresses.filter(address => addressesIdsArray.includes(String(address.id)))
  }, [formData.accounting_objects, allAddresses])

  const setPreExistingData = useCallback((res, resDataKey, storeName, setValueMethodName, newModels) => {
    if (!res[resDataKey]) return

    for (const key in newModels) {
      newModels[key].selectors.setPreExistData({
        data: res,
        dataName: resDataKey,
        storeName,
        setValueMethodName,
        preexistAddresses,
        addressId: key
      })
    }
  }, [preexistAddresses])

  const onValidCreateSubmit = useCallback((data) => {
    setIsCreatingModels(true)
    const cloneData = cloneDeep(data)

    delete cloneData.toStep

    if (lastCreateAnalyticRequest) {
      lastCreateAnalyticRequest.abort()
    }

    createModelsPromise()
      .then(result => {
        const newModels = cloneDeep(result)

        const {promise, controller} = analyticsEndpoint.createAnalyticEntity({...cloneData, user_manager: selectedUser?.id ?? null})

        setLastCreateAnalyticRequest(controller)

        promise
          .then(res => {
            setPreExistingData(res, 'intake', '$intake', 'setIntakeValue', newModels)
            setPreExistingData(res, 'durations', '$durations', 'setDurationValue', newModels)
            setPreExistingData(res, 'manual_temp', '$temperature', 'setTemperatureValue', newModels)
            setPreExistingData(res, 'invest', '$investments', 'setInvestmentValue', newModels)

            setModels(newModels)

            setPreexistAddresses(Object.keys(data.accounting_objects))

            setAnalyticId(res.id)
            setActiveInvestStep(-1)
            setActiveStep(data.toStep)
            resetLastCreateAnalyticRequest()
          })
          .catch(err => {
            if (err.name === 'AbortError') return

            setMessage({
              message: <FormattedMessage id={'requests.analyticCreateError'}/>,
              header: <FormattedMessage id={'requests.error'}/>,
              type: 'error',
              count: 3000
            })
            resetLastCreateAnalyticRequest()
            console.log(err)
          })
      })
      .catch(err => {
        console.log('err create models', err)
      })
      .finally(() => setIsCreatingModels(false))
  }, [lastCreateAnalyticRequest, setPreExistingData, formData, createModelsPromise])

  const onValidChangeSubmit = useCallback((data) => {
    setIsCreatingModels(true)

    if (lastCreateAnalyticRequest) {
      lastCreateAnalyticRequest.abort()
    }

    const dataWithMeterings = cloneDeep(data)

    delete dataWithMeterings.toStep

    dataWithMeterings.accounting_objects = getObjWithAllMeterings()

    createModelsPromise()
      .then(result => {
        const newModels = cloneDeep(result)

        const {promise, controller} = analyticsEndpoint.changeAnalyticParams(dataWithMeterings, analyticId)

        setLastCreateAnalyticRequest(controller)

        promise
          .then((res) => {
            setPreExistingData(res, 'intake', '$intake', 'setIntakeValue', newModels)
            setPreExistingData(res, 'durations', '$durations', 'setDurationValue', newModels)
            setPreExistingData(res, 'manual_temp', '$temperature', 'setTemperatureValue', newModels)
            setPreExistingData(res, 'investments', '$investments', 'setInvestmentValue', newModels)

            setModels(newModels)

            setPreexistAddresses(Object.keys(data.accounting_objects))
            setActiveInvestStep(-1)
            setActiveStep(data.toStep)
            resetLastCreateAnalyticRequest()
          })
          .catch(err => {
            if (err.name === 'AbortError') return

            setMessage({
              message: <FormattedMessage id={'requests.analyticCreateError'}/>,
              header: <FormattedMessage id={'requests.error'}/>,
              type: 'error',
              count: 3000
            })
            resetLastCreateAnalyticRequest()
            console.log(err)
          })
      })
      .catch(err => {
        console.log('err create models', err)
      })
      .finally(() => setIsCreatingModels(false))
  }, [lastCreateAnalyticRequest, setPreExistingData, formData, createModelsPromise])

  useEffect(() => {
    if (!analyticId) {
      setOnValidSubmit(onValidCreateSubmit)
    } else {
      setOnValidSubmit(onValidChangeSubmit)
    }
  }, [analyticId, onValidCreateSubmit, onValidChangeSubmit])

  useEffect(() => {
    return () => {
      resetNewSelectedAddressId()
      resetEditAddressId()
      resetIsShowEditForm()
      resetIsShowAddForm()
    }
  }, [])

  useEffect(() => {
    if (!initialData) {
      setInitData({
        name: dateFormatter(Date.now(), true)
      })
      return
    }

    setInitData({
      accounting_objects: initialData.accounting_objects,
      name: initialData.name,
      step: initialData.step,
      start_date: initialData.start_date,
      end_date: initialData.end_date,
      toStep: 1,
      period_display_type: initialData.period_display_type ?? 1,
      analytic_unit_type: initialData.analytic_unit_type ?? 1
    })
  }, [initialData])

  const handleChange = (e, action) => {
    if (e.target) {
      const {name, value} = e.target

      setFormDataValue(name)(value)
    } else {
      if (action.name === 'accounting_objects') {
        const valuesObj = {}

        e.forEach(item => {
          valuesObj[item.id] = {}
        })

        setFormDataValue('accounting_objects')({
          ...valuesObj
        })
      } else {
        setFormDataValue(action.name)(e.value)
      }
    }
  }

  const handleSubmit = () => {
    setFormDataValue('toStep')(1)

    if (!isValidForm) return

    onClickSubmit()
  }

  const handleHideObj = () => setIsShowAddForm(false)

  const handleHideEditObj = () => {
    setIsShowEditForm(false)
    resetEditAddressId()
  }

  const createNewAddress = (data) => {
    setIsCreatingAddress(true)

    addressEndpoint.createNewAddress({...data, user_manager: selectedUser?.id ?? null}).promise
      .then((res) => {
        if (res.status_code === 409) {
          setPreexistingAddressInfo(res.object)
          togglePreexistModal()
        } else {
          setNewSelectedAddressId(res.id);
          (getInfo ?? updateAddresses)()

          setMessage({
            header: <FormattedMessage id={'requests.creatingObjectSuccessHeader'}/>,
            message: <FormattedMessage id={'requests.creatingObjectSuccess'}/>,
            count: 3000
          })

          handleHideObj()
        }
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.creatingObjectError'}/>,
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsCreatingAddress(false))
  }

  const saveEditAddress = (data) => {
    setIsLoadingEditAddress(true)

    const {promise} = addressEndpoint.changeAddressInfo(data)

    promise
      .then((res) => {
        if (res.status_code === 409) {
          setPreexistingAddressInfo(res.object)
          togglePreexistModal()
        } else {
          (getInfo ?? updateAddresses)()

          setMessage({
            header: <FormattedMessage id={'requests.changingObjectSuccessHeader'}/>,
            message: <FormattedMessage id={'requests.changingObjectSuccess'}/>,
            count: 3000
          })

          handleHideEditObj()
        }
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.changingObjectError'} values={{name: data.name}}/>,
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
      .finally(() => setIsLoadingEditAddress(false))
  }

  const onAgreeWithSelectPreexistModal = (id) => {
    setNewSelectedAddressId(id)
    togglePreexistModal()
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <div className='preconfig'>
        <div className="preconfig__form">
          <Loader loading={!!lastCreateAnalyticRequest || isCreatingModels}>
            <h2 className="title" data-cy ='newReport'>
              <FormattedMessage id={'analytics.newReport'}/>
            </h2>

            <div className="form-fields">
              <Input
                label={<FormattedMessage id={'analytics.name'}/>}
                name={'name'}
                formSelectors={preconfigFormSelectors}
                attr = "newReportName"
              />

              <div className="form-group" data-cy="labelAnalyticsAddObject">
                <label>
                  <FormattedMessage id={'analytics.chooseObjects'}/>
                </label>

                <ValidationsWrapper validationMsgs={formValidations.accounting_objects}>
                  <MultiObjectSelect
                    addresses={allAddresses}
                    styles={SELECT_STYLES}
                    onEditMulti={handleClickEditAddress}
                    name={'accounting_objects'}
                    value={selectedAddresses}
                    handleChange={handleChange}
                    isError={!!formValidations.accounting_objects.length}
                    handleClickEditAddress={handleClickEditAddress}
                    editAddressId={editAddressId}
                  />
                </ValidationsWrapper>
              </div>

              <h4 className={'title_h4'}>
                <FormattedMessage id={'analytics.analysisPeriod'}/>
              </h4>

              <SelectInput
                name='step'
                label={<FormattedMessage id={'analytics.analysisStep'}/>}
                formSelectors={preconfigFormSelectors}
                options={ANALYSIS_STEPS_LIST}
                attr = "analysisStep"
              />

              <DatePickerInput
                label={<FormattedMessage id={'analytics.from'}/>}
                formSelectors={preconfigFormSelectors}
                name={'start_date'}
                minDate={minStartDate}
                maxDate={maxStartDate}
                views={['year', 'month']}
                disabled={completedData?.newAnalysisType === BY_CONTINUE_TYPE}
                valueFormatter={formattedDateToServFirstDay}
                openTo={'year'}
              />

              <DatePickerInput
                label={<FormattedMessage id={'analytics.to'}/>}
                formSelectors={preconfigFormSelectors}
                name={'end_date'}
                minDate={minEndDate}
                maxDate={maxEndDate}
                views={['year', 'month']}
                valueFormatter={formattedDateToServFirstDay}
                openTo={'year'}
              />

              <SelectInput
                label={<FormattedMessage id={'addressForm.unitTypeReport'}/>}
                name={'analytic_unit_type'}
                formSelectors={preconfigFormSelectors}
                options={ANALYTIC_UNIT_TYPES_ANALYTICS_PRECONFIG}
              />

              <SelectInput
                name='period_display_type'
                label={<FormattedMessage id={'analytics.periodDisplay'}/>}
                formSelectors={preconfigFormSelectors}
                options={ANALYSIS_PERIOD_TYPES}
              />
            </div>

            <div className="preconfig__btns-wrapper">
              <button
                className="btn"
                onClick={handleSubmit}
                disabled={!isValidForm}
              >
                <FormattedMessage id={'app.proceed'}/>
              </button>
            </div>
          </Loader>
        </div>

        {isShowAddForm &&
          <div className="preconfig__aside">
            <AddressForm
              isNewAddress
              addresses={addresses}
              formModelSelectors={analyticNewAddressFormSelectors}
              onValidSubmit={createNewAddress}
              hideAside={handleHideObj}
              isLoading={isCreatingAddress}
              isOpenPreexistModal={isOpenPreexistModal}
              togglePreexistModal={togglePreexistModal}
              preexistingAddressInfo={preexistingAddressInfo}
              canSelectPreexistAddress
              onAgreeWithSelectPreexistModal={onAgreeWithSelectPreexistModal}
            />
          </div>
        }

        {isShowEditForm &&
          <div className="preconfig__aside">
            <AddressForm
              formModelSelectors={analyticChangeAddressFormSelectors}
              onValidSubmit={saveEditAddress}
              hideAside={handleHideEditObj}
              editAddressId={editAddressId}
              isLoading={isLoadingEditAddress}
              isDeleteAvailable={false}
              isOpenPreexistModal={isOpenPreexistModal}
              togglePreexistModal={togglePreexistModal}
              preexistingAddressInfo={preexistingAddressInfo}
              canSelectPreexistAddress
              onAgreeWithSelectPreexistModal={onAgreeWithSelectPreexistModal}
            />
          </div>
        }
      </div>
    </LocalizationProvider>
  )
}

export default Preconfig
