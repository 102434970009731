import {userPageRu} from './ru/userPage'
import {userPageEn} from './en/userPage'
import {userPageDe} from './de/userPage'
import {appRu} from './ru/app'
import {appEn} from './en/app'
import {appDe} from './de/app'
import {validationsMsgsRu} from './ru/validationsMsgs'
import {validationsMsgsEn} from './en/validationsMsgs'
import {validationsMsgsDe} from './de/validationsMsgs'
import {passwordRulesRu} from './ru/passwordRules'
import {passwordRulesEn} from './en/passwordRules'
import {passwordRulesDe} from './de/passwordRules'
import {requestsEn} from "@/i18n/translations/en/requests";
import {requestsRu} from "@/i18n/translations/ru/requests";
import {requestsDe} from "@/i18n/translations/de/requests";
import {addressFormRu} from "@/i18n/translations/ru/addressForm";
import {addressFormEn} from "@/i18n/translations/en/addressForm";
import {addressFormDe} from "@/i18n/translations/de/addressForm";
import {objectTypesEn} from "@/i18n/translations/en/objectTypes";
import {objectTypesRu} from "@/i18n/translations/ru/objectTypes";
import {objectTypesDe} from "@/i18n/translations/de/objectTypes";
import {meteringPointFormRu} from "@/i18n/translations/ru/meteringPointForm";
import {meteringPointFormEn} from "@/i18n/translations/en/meteringPointForm";
import {meteringPointFormDe} from "@/i18n/translations/de/meteringPointForm";
import {resourcesTypesRu} from "@/i18n/translations/ru/resourcesTypes";
import {resourcesTypesEn} from "@/i18n/translations/en/resourcesTypes";
import {resourcesTypesDe} from "@/i18n/translations/de/resourcesTypes";
import {navBarRu} from "@/i18n/translations/ru/navBar";
import {navBarEn} from "@/i18n/translations/en/navBar";
import {navBarDe} from "@/i18n/translations/de/navBar";
import {authorizationRu} from "@/i18n/translations/ru/authorization";
import {authorizationEn} from "@/i18n/translations/en/authorization";
import {authorizationDe} from "@/i18n/translations/de/authorization";
import {unitsRu} from "@/i18n/translations/ru/units";
import {unitsEn} from "@/i18n/translations/en/units";
import {unitsDe} from "@/i18n/translations/de/units";
import {analyticsRu} from "@/i18n/translations/ru/analytics";
import {analyticsEn} from "@/i18n/translations/en/analytics";
import {analyticsDe} from "@/i18n/translations/de/analytics";
import {mainRu} from "@/i18n/translations/ru/main";
import {mainEn} from "@/i18n/translations/en/main";
import {mainDe} from "@/i18n/translations/de/main";
import {deviceFormRu} from "@/i18n/translations/ru/deviceForm";
import {deviceFormEn} from "@/i18n/translations/en/deviceForm";
import {deviceFormDe} from "@/i18n/translations/de/deviceForm";
import {devicesPageRu} from "@/i18n/translations/ru/devicesPage";
import {devicesPageEn} from "@/i18n/translations/en/devicesPage";
import {devicesPageDe} from "@/i18n/translations/de/devicesPage";
import {reportsPageRu} from "@/i18n/translations/ru/reportsPage";
import {reportsPageEn} from "@/i18n/translations/en/reportsPage";
import {reportsPageDe} from "@/i18n/translations/de/reportsPage";
import {intakeRu} from "@/i18n/translations/ru/intake";
import {intakeEn} from "@/i18n/translations/en/intake";
import {intakeDe} from "@/i18n/translations/de/intake";
import {backCallRu} from "@/i18n/translations/ru/backCall";
import {backCallEn} from "@/i18n/translations/en/backCall";
import {backCallDe} from "@/i18n/translations/de/backCall";
import {meteringPointsPageRu} from "@/i18n/translations/ru/meteringPointsPage";
import {meteringPointsPageEn} from "@/i18n/translations/en/meteringPointsPage";
import {meteringPointsPageDe} from "@/i18n/translations/de/meteringPointsPage";

export {
  appRu,
  appEn,
  appDe,
  userPageRu,
  userPageEn,
  userPageDe,
  validationsMsgsRu,
  validationsMsgsEn,
  validationsMsgsDe,
  passwordRulesRu,
  passwordRulesEn,
  passwordRulesDe,
  requestsRu,
  requestsEn,
  requestsDe,
  addressFormRu,
  addressFormEn,
  addressFormDe,
  objectTypesEn,
  objectTypesRu,
  objectTypesDe,
  meteringPointFormRu,
  meteringPointFormEn,
  meteringPointFormDe,
  resourcesTypesRu,
  resourcesTypesEn,
  resourcesTypesDe,
  navBarRu,
  navBarEn,
  navBarDe,
  authorizationDe,
  authorizationRu,
  authorizationEn,
  unitsRu,
  unitsEn,
  unitsDe,
  analyticsRu,
  analyticsEn,
  analyticsDe,
  mainDe,
  mainRu,
  mainEn,
  deviceFormDe,
  deviceFormRu,
  deviceFormEn,
  devicesPageDe,
  devicesPageRu,
  devicesPageEn,
  reportsPageRu,
  reportsPageEn,
  reportsPageDe,
  intakeRu,
  intakeEn,
  intakeDe,
  backCallDe,
  backCallRu,
  backCallEn,
  meteringPointsPageRu,
  meteringPointsPageEn,
  meteringPointsPageDe
}
