import {request} from "@/internal/lib/transport/request";

export const analyticsEndpoint = {
  createAnalyticEntity (data) {
    const params = {
      url: 'api/analytics/',
      method: 'POST',
      data,
    }

    return request(params)
  },

  getAnalyticById (id) {
    const params = {
      url: `api/analytics/${id}`,
      method: 'GET',
    }

    return request(params)
  },

  changeAnalyticParams (data, id) {
    const params = {
      url: `api/analytics/${id}/`,
      method: 'PUT',
      data,
    }

    return request(params)
  },

  changeAnalytic (data, id) {
    const params = {
      url: `api/analytics/${id}/metering/`,
      method: 'PUT',
      data: {
        accounting_objects: data
      }
    }

    return request(params)
  },

  calculateAnalytic (id) {
    const params = {
      url: 'api/calculation/analysis/',
      method: 'POST',
      data: {
        analytics_id: +id
      }
    }

    return request(params)
  },

  getAnalytics () {
    const params = {
      url: 'api/history_analys/',
      method: 'GET'
    }

    return request(params)
  },

  getAnalytic (id) {
    const params = {
      url: `api/history_analys/${id}/`,
      method: 'GET'
    }

    return request(params)
  },

  deleteAnalytic (id) {
    const params = {
      url: `api/analytics/${id}/`,
      method: 'DELETE'
    }

    return request(params)
  },

  saveAnalyticResult (id) {
    const params = {
      url: `api/calculation/save_history/`,
      method: 'POST',
      data: {
        analytics_id: +id
      }
    }

    return request(params)
  },

  getResultXLS (id) {
    const params = {
      url: `api/calculation/export/`,
      method: 'POST',
      data: {
        analytics_id: id
      }
    }

    return request(params)
  },
  
  pingAnalyticResult (id) {
    const params = {
      url: `api/calculation/calc_status/`,
      method: 'POST',
      data: {
        analytics_id: id
      }
    }

    return request(params)
  },

  sendResultMails (data) {
    const params = {
      url: 'api/calculation/send_mails/',
      method: 'POST',
      data,
    }

    return request(params)
  },

  getAnalyticTemp (data) {
    const params = {
      url: 'api/calculation/manual_temp_api/',
      method: 'POST',
      data,
    }

    return request(params)
  },
}
