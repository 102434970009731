export const INITIAL_NEW_ADDRESS_FORM_DATA = Object.freeze({
  name: '',
  region: '',
  city: '',
  street: '',
  building: '',
  object_type: 3,
  apartment: '',
  residents: '',
  area: '',
  geo_len: '',
  geo_lat: '',
  tariff: '',
  currency_type: 1,
  unit_type: 1,
  servicemanager: 0
})

export const ANALYTICS_INIT_VALIDATIONS = Object.freeze({
  name: [],
  tariff: [],
  area: [],
  geo_lat: [],
  geo_len: []
})
