import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'passwordRules'
const translations = {
  passwordRequirements: 'Password requirements',
  number: 'number',
  lowercaseLatinLetter: 'lowercase latin letter',
  uppercaseLatinLetter: 'uppercase latin letter',
  eightSymbols: '8 symbols',
  specialSymbols: 'special symbol (!@#$%^&*()_)'
}

export const passwordRulesEn = createTranslationsWithSpaceName(translations, spaceName)