import {LoadingImg} from "@/assets/svg";

const Spinner = () => {
  return (
    <div className={`spinner`}>
      <div className="spinner-wrapper">
        <div className="spinner__round">
          <img src={LoadingImg} alt='loading'/>
        </div>
      </div>
    </div>
  )
}

export default Spinner
