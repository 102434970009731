import {mkdsStateSelectors} from "@/internal/lib/storeModels/models/mkds/mkdsState";
import React, {useEffect} from "react";
import {mocks} from "@/presentation/components/heatCalculationPage/mocks";
import AddButton from "@/presentation/components/heatCalculationPage/components/AddButton";
import MKDCard from "@/presentation/components/heatCalculationPage/components/MKDCard";
import HoveredPopover from "@/presentation/components/common/HoveredPopover";
import Loader from "@/presentation/components/loader/Loader";
import {mkdsEndpoint} from "@/data/api_entities/mkds/mkds_endpoint";
import {selectors} from "@/presentation/shared/ui/message";
import {FormattedMessage} from "react-intl";
import {useLocation, useParams} from "react-router-dom";

const MKDList = () => {
  const [list, setList] = mkdsStateSelectors.list.useState()
  const [loading, setLoading] = mkdsStateSelectors.loading.useState()
  const setOpenAside = mkdsStateSelectors.sideForm.useSetValue()
  const setIsNew = mkdsStateSelectors.isNewMKD.useSetValue()
  const setSettingsId = mkdsStateSelectors.selectedMKDSettingsId.useSetValue()
  const setMessage = selectors.useSetMessage()

  useEffect(() => {
    setLoading(true)

    const {promise} = mkdsEndpoint.getAll()

    promise
      .then(res => {
        if (Array.isArray(res?.results)) {
          setList(res.results)
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось загрузить список МКД',
            type: 'error',
            count: 3000
          })
        }
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось загрузить список МКД',
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setLoading(false))
  }, [])

  const handleOpenAside = () => {
    setIsNew(true)
    setOpenAside(true)
  }

  const onClickSettings = (e, id) => {
    e.stopPropagation()
    setSettingsId(id)
    setIsNew(false)
    setOpenAside(true)
  }

  return (
    <Loader loading={loading}>
      <div className='mkd-list'>
        <HoveredPopover text={'Добавить МКД'}>
          <AddButton
            onClick={handleOpenAside}
          />
        </HoveredPopover>

        {list.map(object => <MKDCard
            key={object.id}
            object={object}
            onClickSettings={onClickSettings}
          />
        )}
      </div>
    </Loader>
  )
}

export default MKDList
