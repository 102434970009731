import AuthorizationLayout from "./AuthorizationLayout";
import {Link, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {authorizationEndpoint} from "@/data/api_entities/authorization/authorization_endpoint";
import {NOT_FOUND_EMAIL} from "@/internal/validators/validationsMessages";
import Loader from "@/presentation/components/loader/Loader";
import {recoveryPasswordSelectors} from "@/internal/lib/storeModels/models/authorization/forms/recoveryPasswordModels";
import Input from "../common/formComponents/Input";
import {selectors} from "@/presentation/shared/ui/message";
import {authorizationSelectors} from "@/internal/lib/storeModels/models/authorization/authorizationModels";
import {FormattedMessage, useIntl} from "react-intl";

const RecoveryPassword = () => {
  const intl = useIntl()

  const isValidForm = recoveryPasswordSelectors.useIsFormValid()
  const setOnValidSubmit = recoveryPasswordSelectors.useSetOnValidSubmit()
  const resetForm = recoveryPasswordSelectors.useResetForm()
  const onClickSubmit = recoveryPasswordSelectors.useOnClickSubmit()
  const [incorrectMsg, setIncorrectMsg, resetIncorrectMsg] = authorizationSelectors.incorrectRecoveryPasswordMsg.useState()
  const [isLoading, setIsLoading] = authorizationSelectors.isRecoveryPasswordLoading.useState()
  const setRecoveryEmail = authorizationSelectors.recoveryEmail.useSetValue()
  const setMessage = selectors.useSetMessage()

  const navigate = useNavigate()

  useEffect(() => {
    setOnValidSubmit(onValidSubmit)

    return () => {
      // resetForm()
      resetIncorrectMsg()
    }
  }, [])

  const onSubmit = () => {
    if (!isValidForm) return

    onClickSubmit()
  }

  const onValidSubmit = (data) => {
    setIsLoading(true)

    authorizationEndpoint.sendResetPasswordMail(data)
      .then(res => {
        if (res.code === 440) {
          setIncorrectMsg(NOT_FOUND_EMAIL)
        } else if (res.code === 200) {
          setRecoveryEmail(data.email)

          navigate('/email_approve/recovery_password')
        } else {
          console.log(res)
          setMessage({
            message: <FormattedMessage id={'requests.confirmMailError'}/>,
            header: <FormattedMessage id={'requests.error'}/>,
            type: 'error',
            count: 3000
          })
        }
      })
      .catch(err => {
        setMessage({
          message: <FormattedMessage id={'requests.confirmMailError'}/>,
          header: <FormattedMessage id={'requests.error'}/>,
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <AuthorizationLayout
      title={<FormattedMessage id={'authorization.recoveryPassword'}/>}
      customClass="recovery"
      footerAvailable={false}
    >
      <Loader loading={isLoading}>
        <div className="form-fields">
          <Input
            label={<FormattedMessage id={'authorization.e-mail'}/>}
            name={'email'}
            placeholder={intl.formatMessage({id: 'authorization.mailPlaceHolder'})}
            type="email"
            formSelectors={recoveryPasswordSelectors}
            additionalValidationMsg={incorrectMsg}
            withChangeValue={() => resetIncorrectMsg()}
          />
        </div>

        <button
          disabled={!isValidForm}
          onClick={onSubmit}
          className="btn"
        >
          <FormattedMessage id={'authorization.resetPassword'}/>
        </button>
      </Loader>

      <Link to="/input" className="authorization-layout__under-btn-link">
        <FormattedMessage id={'app.cancel'}/>
      </Link>
    </AuthorizationLayout>
  )
}

export default RecoveryPassword
