import SectionDeveloped from "@/presentation/components/common/SectionDeveloped";

const EventsPage = () => {
  return (
    <div className="events-page">
      <div className="events-page__column">
        <SectionDeveloped />
      </div>
    </div>
  )
}

export default EventsPage
