export const getMeterointAdapter = (data) => {
  return {
    point_id: data.id,
    address: data.address,
    name: data.name,
    type: data.type,
    device: data.gizmo.device,
    channel: data.gizmo.channel,
    si_number: data.gizmo.si_number,
    device_name: data.gizmo.name,
    serial: data.gizmo.serial,
    next_verification: data.gizmo.next_verification,
    unit_type: data.gizmo.unit_type,
    date: data.gizmo.metering.datetime.slice(0, 10),
    time: data.gizmo.metering.datetime.slice(11, 16),
    value: data.gizmo.metering.value,
    pulse: data.gizmo.pulse_settings.pulse,
    value_impulse: data.gizmo.pulse_settings.value,
    unit_type_impulse: data.gizmo.pulse_settings.unit_type
  }
}

export const sendMeterointAdapter = (data) => {
  return {
    address: data.address,
    name: data.name,
    type: parseInt(data.type),
    floor: 'null',
    gizmo: {
      device: data.device,
      channel: data.channel,
      si_number: data.si_number,
      name: data.device_name,
      serial: data.serial,
      next_verification: data.next_verification,
      unit_type: data.unit_type,
      metering: {
        datetime: `${data.date} ${data.time}`,
        value: parseInt(data.value)
      },
      pulse_settings: {
        pulse: parseInt(data.pulse),
        value: parseInt(data.value_impulse),
        unit_type: data.unit_type_impulse
      }
    }
  }
}

