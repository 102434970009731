import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'authorization'
const translations = {
  enter: 'Вход',
  apologies: 'Приносим свои извинения, но сервер временно не доступен.',
  'e-mail': 'e-mail',
  mailPlaceHolder: 'user@mail.ru',
  password: 'Пароль',
  signIn: 'Войти',
  'forgetPassword?': 'Забыли пароль?',
  'noAcc?': 'Нет аккаунта?',
  register: 'Зарегистрироваться',
  'haveAcc?': 'Уже есть аккаунт?',
  lookDemo: 'Посмотрите,<a></a> как все работает',
  demo: 'Демо',
  passwordReset: 'Ваш пароль сброшен',
  error: 'Ошибка',
  youRegistered: 'Вы зарегистрированны',
  confirmPasswordReset: 'Осталось подтвердить сброс пароля',
  confirmEmail: 'Осталось подтвердить почту',
  invalidLink: 'Ссылка недействительна!',
  emailConfirmed: 'Вы подтвердили почту!',
  weSendMail: 'Мы отправили письмо со ссылкой на\xa0{email}',
  checkMail: 'Проверить почту',
  sending: 'Отправляем...',
  sent: 'Отправлено. Повторить через 00:{time}',
  sendAgain: 'Отправить еще раз',
  resetPassword: 'Сбросить пароль',
  recoveryPassword: 'Восстановление пароля',
  createNewPassword: 'Придумайте новый пароль',
  newPassword: 'Новый пароль',
  repeatPassword: 'Повторить пароль',
  saveNewPassword: 'Сохранить новый пароль',
  invalidData: 'Неправильные или занятые данные',
  registration: 'Регистрация',
  individual: 'Физическое<a></a>лицо',
  corporate: 'Корпоративный<a></a>клиент',
  acceptTerms: 'Я принимаю <tag>условия использования сервиса</tag>',
  createAcc: 'Создать аккаунт',
  createUsr: 'Создать аккаунт клиента',
  inn: 'ИНН',
  legalName: 'Название юридического лица',
  legalAddress: 'Юридический адрес',
  actualAddress: 'Фактический адрес',
  ifActualIsLegal: 'Если Фактический адрес совпадает с Юридическим адресом',
  contactTel: 'Контакт для связи',
  phoneOptional: 'Телефон (по желанию)',
  legalNameOrINN: 'Введите название юр. лица или\xa0ИНН',
  findOrg: 'Найти организацию',
  loading: 'Загружаем...',
  login: 'Имя',
  accept: 'Принять'
}

export const authorizationRu = createTranslationsWithSpaceName(translations, spaceName)
