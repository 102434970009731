import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {
  CORPORATE_REGISTRATION_INIT_DATA, CORPORATE_REGISTRATION_INIT_VALIDATIONS,
  USER_REGISTRATION_INIT_DATA,
  USER_REGISTRATION_INIT_VALIDATIONS
} from "../const";
import {getUserCorporateValidator, getUserIndividualValidator} from "@/internal/validators/formValidators";
import {createEffect, createEvent, createStore, sample} from "effector";
import {useEvent, useStore} from "effector-react";

const individualFormModel = new FormModelFabric({
  initialData: USER_REGISTRATION_INIT_DATA,
  initialValidations: USER_REGISTRATION_INIT_VALIDATIONS,
  getValidators: getUserIndividualValidator
})

const corporateFormModel = new FormModelFabric({
  initialData: CORPORATE_REGISTRATION_INIT_DATA,
  initialValidations: CORPORATE_REGISTRATION_INIT_VALIDATIONS,
  getValidators: getUserCorporateValidator
})

corporateFormModel.toggleIsActualIsLegal = createEvent()
corporateFormModel.$isActualIsLegal = createStore(false)
corporateFormModel.setSameAddresses = createEffect(({organizationAddress, organizationActualAddress, isActualIsLegal}) => {
  if (!isActualIsLegal || organizationActualAddress === organizationAddress) return

  corporateFormModel.valueStateManager['organization_actual_address'].setValue(organizationAddress)
})

corporateFormModel.$isActualIsLegal
  .on(corporateFormModel.toggleIsActualIsLegal, (state) => !state)
  .reset(corporateFormModel.resetFormData)

sample({
  clock: [corporateFormModel.toggleIsActualIsLegal, corporateFormModel.valueStateManager['organization_address'].setValue],
  source: {
    organizationAddress: corporateFormModel.valueStateManager['organization_address'].$store,
    organizationActualAddress: corporateFormModel.valueStateManager['organization_actual_address'].$store,
    isActualIsLegal: corporateFormModel.$isActualIsLegal
  },
  target: corporateFormModel.setSameAddresses
})

export const individualFormSelectors = individualFormModel.createSelectors()
export const corporateFormSelectors = {
  ...corporateFormModel.createSelectors(),
  useIsActualIsLegal: () => useStore(corporateFormModel.$isActualIsLegal),
  useToggleIsActualIsLegal: () => useEvent(corporateFormModel.toggleIsActualIsLegal)
}
