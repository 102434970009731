import {RESOURCES_TYPES} from "@/internal/content/consts";
import {FormattedMessage} from "react-intl";

export const RESOURCES_LIST = [
  {
    value: 0,
    label: <>
      <div>
        {RESOURCES_TYPES[0].img
          ?  <img src={RESOURCES_TYPES[0].img} alt={RESOURCES_TYPES[0].name}/>
          : null }
      </div>

      <span>{RESOURCES_TYPES[0].name}</span>
    </>
  },
  {
    value: 1,
    label: <>
      <div>
        {RESOURCES_TYPES[1].img
          ?  <img src={RESOURCES_TYPES[1].img} alt={RESOURCES_TYPES[1].name}/>
          : null }
      </div>

      <span>{RESOURCES_TYPES[1].name}</span>
    </>
  },
  {
    value: 2,
    label: <>
      <div>
        {RESOURCES_TYPES[2].img
          ?  <img src={RESOURCES_TYPES[2].img} alt={RESOURCES_TYPES[2].name}/>
          : null }
      </div>

      <span>{RESOURCES_TYPES[2].name}</span>
    </>
  },
  {
    value: 3,
    label: <>
      <div>
        {RESOURCES_TYPES[3].img
          ?  <img src={RESOURCES_TYPES[3].img} alt={RESOURCES_TYPES[3].name}/>
          : null }
      </div>

      <span>{RESOURCES_TYPES[3].name}</span>
    </>
  },
  {
    value: 4,
    label: <>
      <div>
        {RESOURCES_TYPES[5].img
          ?  <img src={RESOURCES_TYPES[4].img} alt={RESOURCES_TYPES[4].name}/>
          : null }
      </div>

      <span>{RESOURCES_TYPES[4].name}</span>
    </>
  },
  {
    value: 5,
    label: <>
      <div>
        {RESOURCES_TYPES[5].img
          ?  <img src={RESOURCES_TYPES[5].img} alt={RESOURCES_TYPES[5].name}/>
          : null }
      </div>

      <span>{RESOURCES_TYPES[5].name}</span>
    </>
  },
  {
    value: 6,
    label: <>
      <div>
        {RESOURCES_TYPES[6].img
          ?  <img src={RESOURCES_TYPES[6].img} alt={RESOURCES_TYPES[6].name}/>
          : null }
      </div>

      <span>{RESOURCES_TYPES[6].name}</span>
    </>
  },
]

export const CHANNELS_LIST = [
  {
    value: 1,
    label: <FormattedMessage id={'meteringPointForm.pulseChannelOne'}/>,
  },
  {
    value: 2,
    label: <FormattedMessage id={'meteringPointForm.pulseChannelTwo'}/>,
  },
]

export const UNITS_LIST = [
  ...(() => {
    const arr = []

    for (let i = 0; i<5; i++) {
      arr[i] = {
        value: i,
        label: (
          <FormattedMessage
            id={`units.${i}`}
            values={{
              sup: chunks => (<sup>{chunks}</sup>)
            }}
          />
        )
      }
    }

    return arr
  })(),
]
