const CheckBoxInput = (
  {
    name,
    label,
    formSelectors
  }) => {
  const value = formSelectors.useFormDataValue(name)
  const setValue = formSelectors.useSetFormDataValue(name)

  const onChange = () => {
    setValue(!value)
  }

  return (
    <div className={'check-box'}>
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={value}
        onChange={onChange}
      />

      <label htmlFor={name}>{label}</label>
    </div>
  )
}

export default CheckBoxInput
