import {mkdsStateSelectors} from "@/internal/lib/storeModels/models/mkds/mkdsState";
import {MKDHelper} from "@/presentation/components/heatCalculationPage/helpers";
import {MKD_PAGE_TABS, PATHS} from "@/presentation/components/heatCalculationPage/components/MKDPage/const";
import LinkTab from "@/presentation/components/common/LinkTab";
import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import MKDForm from "@/presentation/components/heatCalculationPage/components/MKDForm";
import {changeMKDFormStateSelectors} from "@/internal/lib/storeModels/models/mkds/forms/mkds_forms";
import {mkdsEndpoint} from "@/data/api_entities/mkds/mkds_endpoint";
import React, {useEffect, useState} from "react";
import {useNavigateBack} from "@/internal/custom_hooks/navigateBack";
import ApartmentsPage from "@/presentation/components/heatCalculationPage/components/MKDPage/components/ApartmentsPage";
import CalculationsPage from "@/presentation/components/heatCalculationPage/components/MKDPage/components/CalculationsPage";
import {selectors} from "@/presentation/shared/ui/message";
import {FormattedMessage} from "react-intl";
import ArrowBack from "@/presentation/components/common/ArrowBack";

const MKDPage = () => {
  const [list, setList] = mkdsStateSelectors.list.useState()
  const selectedId = mkdsStateSelectors.selectedMKD.useValue()
  const selectedObject = list.filter(object => object.id == selectedId)[0] ?? {}
  const [isChanging, setIsChanging] = useState(false)
  const [navigateBack] = useNavigateBack()
  const resetApartmentsList = mkdsStateSelectors.apartmentsList.useReset()
  const setMessage = selectors.useSetMessage()
  const navigate = useNavigate()

  useEffect(() => {
    if (!selectedId) {
      navigate('/user/heat_calculation/list', {
        replace: true
      })
    }
  }, [selectedId])

  useEffect(() => {
    return () => {
      resetApartmentsList()
    }
  }, [])

  const onValidSubmitChangeMKD = (data) => {
    setIsChanging(true)
    const {promise} = mkdsEndpoint.change(data, selectedObject.id)

    promise
      .then(res => {
        if ('id' in res) {
          const currentDataIndex = list.findIndex(object => object.id === res.id)
          if (currentDataIndex >= 0) {
            const newList = [...list]
            newList[currentDataIndex] = res
            setList(newList)
            navigateBack()
          }
          setMessage({
            header: 'Изменение МКД',
            message: 'МКД успешно изменен',
            type: 'success',
            count: 3000
          })
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось изменить МКД',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось изменить МКД',
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsChanging(false))
  }

  return (
    <div className={'mkd-page'}>
      <div className="mkd-page__column">
        <ArrowBack
          pathOnVisible={'list'}
          withId={true}
        />
        <div className="mkd-page__title-wrapper">
          <h2 className="title">{MKDHelper.getAddress(selectedObject)}</h2>
          <span>{MKDHelper.getRegion(selectedObject)}</span>
        </div>

        {MKD_PAGE_TABS.map(tab => {
          return (
            <LinkTab
              key={tab.id}
              path={`${selectedObject?.id}/${tab.path}`}
              text={tab.textId}
            />
          )
        })}
      </div>

      <Routes>
        <Route
          path={`${selectedObject?.id}/${PATHS.settings}/*`}
          element={<MKDForm
            formStateSelectors={changeMKDFormStateSelectors}
            editId={selectedObject?.id}
            classPostfix={'mkd-page__column'}
            pathOnVisible={PATHS.settings}
            onValidSubmit={onValidSubmitChangeMKD}
            isLoading={isChanging}
          />}
        />

        <Route
          path={`${selectedObject?.id}/${PATHS.apartments}/*`}
          element={<ApartmentsPage selectedObject={selectedObject}/>}
        />

        <Route
          path={`${selectedObject?.id}/${PATHS.calculations}/*`}
          element={<CalculationsPage />}
        />
      </Routes>
    </div>
  )
}

export default MKDPage
