import {useEffect, useState} from "react";
import LinkTab from "@/presentation/components/common/LinkTab";
import {ACCOUNTING_OBJECTS_PATH, METERING_POINT_PATH, NEW_METEROINT_PATH, OBJECT_PARAMS_PATH} from "../../const";
import {addressEndpoint} from "@/data/api_entities/address/address_endpoint";
import Loader from "@/presentation/components/loader/Loader";
import { useParams, Routes, Route, useNavigate, NavLink} from "react-router-dom";
import {RESOURCES_TYPES} from "@/internal/content/consts";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {selectors} from "@/presentation/shared/ui/message";
import {addressesSelectors} from "@/internal/lib/storeModels/models/address/addressesModel";
import {userPageSelectors} from "@/internal/lib/storeModels/models/user/userPageModels";
import AddressForm from "@/presentation/components/addressForm/AddressForm";
import {userChangeAddressSelectors} from "@/internal/lib/storeModels/models/user/forms/addressFormModels";
import MeteringPointForm from "@/presentation/components/meteringPointForm/MeteringPointForm";
import {
  changeMeteringPointFormSelectors,
  meteringPointFormSelectors
} from "@/internal/lib/storeModels/models/user/forms/meteringPointFormModel";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import {meterointsEndpoint} from "@/data/api_entities/meteroints/meteroints_endpoint";
import {meterointsSelectors} from "@/internal/lib/storeModels/models/meteringPoints/meteriontsModel";
import {FormattedMessage} from "react-intl";
import {useNavigateBack} from "@/internal/custom_hooks/navigateBack";

const ObjectInfo = ({preexistingAddressInfo, setPreexistingAddressInfo}) => {
  const params = useParams()
  const [navigateBack] = useNavigateBack()

  const [currentAddress, setCurrentAddress] = useState(null)
  const [meteroints, setMeteroints] = useState([])
  const [firstMeteriontsLoading, setFirstMeterointsLoading] = useState(true)
  const [lastGetMeterointsRequest, setLastGetMeterointsRequest] = useState(null)
  const [isOpenDeleteObjWithDevicesModal, toggleIsOpenDeleteObjWithDevicesModal] = useToggle()
  const [isOpenDeleteObjModal, toggleIsOpenDeleteObjModal] = useToggle()
  const [isOpenPreexistModal, togglePreexistModal] = useToggle()
  const [isOpenDeletePointModal, toggleDeletePointModal] = useToggle()
  const resetForm = meteringPointFormSelectors.useResetForm()

  const addresses = addressesSelectors.addresses.useValue()
  const updateAddresses = addressesSelectors.addresses.useUpdateAddresses()
  const [availableDevicesCount, setAvailableDevicesCount] = userPageSelectors.availableDevicesCount.useState()
  const updateMeteroints = meterointsSelectors.meteroints.useUpdateMeteroints()
  const setMessage = selectors.useSetMessage()

  const toggleDeleteObjModal = () => {
    if (!!availableDevicesCount) {
      toggleIsOpenDeleteObjWithDevicesModal()
    } else {
      toggleIsOpenDeleteObjModal()
    }
  }

  useEffect(() => {
    const id = params.id

    if (!id || !addresses) return

    setCurrentAddress(addresses.find(address => address.id === +id) ?? null)
  }, [params.id, addresses])

  useEffect(() => {
    getMeterointsList()
  }, [params.id])

  useEffect(() => {
    if (!currentAddress) return

    setAvailableDevicesCount((currentAddress.devices ?? []).length)
  }, [currentAddress])

  const getMeterointsList = () => {
    const id = params.id
    if (!id) return

    if (lastGetMeterointsRequest) {
      lastGetMeterointsRequest.abort()
    }

    const {promise, controller} = addressEndpoint.getMeterointsByAddressId(id)

    setLastGetMeterointsRequest(controller)

    promise
      .then(res => {
        setMeteroints(res.metering_points)
        setLastGetMeterointsRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.getObjectsError'}/>,
          type: 'error',
          count: 3000
        })
        setLastGetMeterointsRequest(null)
        console.log(err)
      })

    if (firstMeteriontsLoading) {
      setFirstMeterointsLoading(false)
    }
  }

  const handleChangeAddress = (data) => {
    addressEndpoint.changeAddressInfo(data).promise
      .then((res) => {
        if (res.status_code === 409) {
          setPreexistingAddressInfo(res.object)
          togglePreexistModal()
        } else {
          navigateBack()

          updateAddresses()

          setMessage({
            header: <FormattedMessage id={'requests.changingObjectSuccessHeader'}/>,
            message: <FormattedMessage id={'requests.changingObjectSuccess'}/>,
            count: 3000
          })
        }
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.changingObjectError'} values={{name: data.name}}/>,
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
  }

  const handleDeleteAddress = (id) => {
    addressEndpoint.deleteAddress(id)
      .then(() => {
        toggleDeleteObjModal()
        navigateBack(2)

        updateAddresses()

        setMessage({
          header: <FormattedMessage id={'requests.removeObjectSuccessHeader'}/>,
          message: <FormattedMessage id={'requests.removeObjectSuccess'}/>,
          count: 3000
        })
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.removeObjectError'}/>,
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
  }

  const handleCreateMeteringPoint = (data) => {
    meterointsEndpoint.createMeteroint({address: currentAddress?.id, ...data})
      .then((res) => {
        if (res.status === 'error') {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: <FormattedMessage id={'requests.addMeteringPointError'}/>,
            type: 'error',
            count: 3000
          })
        } else {
          navigateBack()
          updateMeteroints()
          getMeterointsList()
          resetForm()

          setMessage({
            header: <FormattedMessage id={'requests.addMeteringPointSuccessHeader'}/>,
            message: <FormattedMessage id={'requests.addMeteringPointSuccess'}/>,
            count: 3000
          })
        }
      })
      .catch((err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.addMeteringPointError'}/>,
          type: 'error',
          count: 3000
        })
        console.log(err)
      }))
  }

  const handleChangeMeteringPoint= (data) => {
    meterointsEndpoint.changeMeteroint(data)
      .then(() => {
        navigateBack(2)
        updateMeteroints()
        getMeterointsList()

        setMessage({
          header: <FormattedMessage id={'requests.changingMeteringPointSuccessHeader'}/>,
          message: <FormattedMessage id={'requests.changingMeteringPointSuccess'}/>,
          count: 3000
        })
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.changingMeteringPointError'} values={{name: data.name}}/>,
          type: 'error',
          count: 3000
        })

        console.log(err)
      })
  }

  const handleDeleteMeteringPoint = (id) => {
    meterointsEndpoint.deleteMeteroint(id)
      .then(() => {
        toggleDeletePointModal()
        navigateBack()
        updateMeteroints()
        getMeterointsList()

        setMessage({
          header: <FormattedMessage id={'requests.removeMeteringPointSuccessHeader'}/>,
          message: <FormattedMessage id={'requests.removeMeteringPointSuccess'}/>,
          count: 3000
        })
      })
      .catch(err => {
        console.log(err)

        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.removeMeteringPointError'}/>,
          type: 'error',
          count: 3000
        })
      })
  }

  const meterointsList = meteroints && meteroints
    .map(meteroint => {
      const title = (
        <>
          <img src={RESOURCES_TYPES[meteroint.type].img} alt="resource"/>

          <span>{meteroint.name}</span>
        </>
      )

      return (
        <LinkTab
          key={meteroint.id}
          text={title}
          path={`${METERING_POINT_PATH}/${meteroint.id}`}
        />
      )
    })

  if (!currentAddress) return null

  return (
    <>
      <div className='user-page__column user-page__column_object-info'>
        <ArrowBack
          pathOnVisible={ACCOUNTING_OBJECTS_PATH}
          withId
        />

        <h2 className="title">{currentAddress.name}</h2>

        <div className="user-page__links">
          <LinkTab
            textId='userPage.objectParams'
            path={OBJECT_PARAMS_PATH}
          />
        </div>

        <div className="user-page__metring-points">
          <h4 className="title_h4">
            <FormattedMessage id={'userPage.objectsMeteringPoints'}/>
          </h4>

          <p>
            <FormattedMessage id={'userPage.historyIsStored'}/>
          </p>
        </div>

        <Loader loading={ !!lastGetMeterointsRequest}>
          {
            meteroints && meteroints.length
              ? <div className="user-page__metring-points-list" >
                  {meterointsList}
                </div>
              : <div className='user-page__warnings'>
                  <span>
                    <FormattedMessage id={'userPage.pointsNotAdded'}/>
                  </span>

                  {!availableDevicesCount
                    ? (
                      <p>
                        <FormattedMessage id={'userPage.addedIsNotActive'}/>
                      </p>
                    )
                    : null
                  }
                </div>
          }
        </Loader>

        <div className="user-page__btn-wrapper">
          <NavLink
            className={`btn btn_transparent-bg ${availableDevicesCount 
              ? ''
              : 'disabled'}`}
            to={NEW_METEROINT_PATH}
          >
            <FormattedMessage id={'userPage.addMore'}/>
          </NavLink>
        </div>
      </div>

      <Routes>
        <Route
          path={OBJECT_PARAMS_PATH}
          element={
            <AddressForm
              customClass='user-page__column user-page__column_add-object'
              formModelSelectors={userChangeAddressSelectors}
              onValidSubmit={handleChangeAddress}
              onDelete={handleDeleteAddress}
              pathOnVisible={OBJECT_PARAMS_PATH}
              isOpenDeleteModal={isOpenDeleteObjModal}
              toggleDeleteModal={toggleDeleteObjModal}
              isOpenPreexistModal={isOpenPreexistModal}
              isOpenDeleteObjWithDevicesModal={isOpenDeleteObjWithDevicesModal}
              togglePreexistModal={togglePreexistModal}
              preexistingAddressInfo={preexistingAddressInfo}
            />
          }
        />

        <Route
          path={NEW_METEROINT_PATH}
          element={
            <MeteringPointForm
              isNew
              customClass='user-page__column user-page__column_metring-point'
              formModelSelectors={meteringPointFormSelectors}
              selectedAddress={currentAddress?.id}
              onValidSubmit={handleCreateMeteringPoint}
              pathOnVisible={NEW_METEROINT_PATH}
            />
          }
        />

        <Route
          path={`${METERING_POINT_PATH}/:idP`}
          element={
            <MeteringPointForm
              customClass='user-page__column user-page__column_metring-point'
              formModelSelectors={changeMeteringPointFormSelectors}
              selectedAddress={currentAddress?.id}
              onValidSubmit={handleChangeMeteringPoint}
              handleDelete={handleDeleteMeteringPoint}
              pathOnVisible={METERING_POINT_PATH}
              pathWithId
              backCount={2}
              isOpenDeleteModal={isOpenDeletePointModal}
              toggleDeleteModal={toggleDeletePointModal}
            />
          }
        />
      </Routes>
    </>
  )
}

export default ObjectInfo
