export const modelMessage = Object.freeze({
  flag: false,
  header: '',
  message: '',
  type: '#05DEC4',
  count: 5000
})

export const typeMessage = Object.freeze({
  error: 'error',
  info: 'info',
  success: 'success'
})

export const typeMessageColors = Object.freeze({
  error: '#FC4F6E',
  info: '#eae598',
  success: '#05DEC4',
})