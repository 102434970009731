import StoreModelFabric from "@/internal/lib/storeModels/fabrics/storeModelFabric";
import {analyticsEndpoint} from "@/data/api_entities/analytics/analytics_endpoint";
import {setMessage} from "@/presentation/shared/ui/message";
import {createEffect, createEvent, sample} from "effector";
import {useEvent, useStore} from "effector-react";

const analyticsListModel = new StoreModelFabric([])
const isLoadingListModel = new StoreModelFabric(true)
const updateAnalyticsList = createEvent()

analyticsListModel.getList = createEffect(() => {
  isLoadingListModel.setState(true)

  analyticsEndpoint.getAnalytics().promise
    .then(res => {
      if (Array.isArray(res.results)) {
        analyticsListModel.setState(res.results)
      } else {
        analyticsListModel.resetStore()
        setMessage({
          message: 'Не удалось получить список отчетов',
          header: 'Ошибка!',
          type: 'error',
          count: 3000
        })
      }
    })
    .catch(err => {
      console.log(err)
      setMessage({
        message: 'Не удалось получить список отчетов',
        header: 'Ошибка!',
        type: 'error',
        count: 3000
      })
    })
    .finally(() => isLoadingListModel.setState(false))
})

sample({
  clock: updateAnalyticsList,
  target: analyticsListModel.getList
})

export const analyticsListSelectors = {
  list: {
    ...analyticsListModel.createSelectors(),
    useUpdateAnalyticsList: () => useEvent(updateAnalyticsList),
    useGetAnalyticsList: () => useEvent(analyticsListModel.getList),
  },
  loading: isLoadingListModel.createSelectors(),
}
