export const defaultAddressValue = {
  value: 0,
  label: 'Нет объектов'
}

export const SELECT_STYLES = {
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
    width: '149%',
    background: '#fff',
    boxShadow: '0 .5rem 1rem rgba(53, 96, 181, 0.2)',
    borderRadius: '.375rem',
    overflowX: 'hidden',
    margin: '0'
  }),
  option: () => ({
    display: 'flex',
    flexDirection: 'column',
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    padding: '.75rem 1rem',
    borderBottom: '1px solid #ECF0F6',
    cursor: 'pointer',
    ':hover': {
      background: '#E6EFFE'
    },
    ':last-child': {
      borderBottom: 'none'
    },
    'span': {
      ':nth-of-type(1)': {
        fontSize: '.875rem',
        lineHeight: '143%',
        fontWeight: '500',
        color: '#36455D',
      },
      ':last-child': {
        fontSize: '.875rem',
        lineHeight: '143%',
        color: '#727A86',
        marginTop: '-0.25rem'
      }
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#727A86',
    margin: 0,
    padding: '0.5rem 0 0.5rem  1.0625rem',
  })
}

export const NAV_BAR_LIST = [
  {
    id: 1,
    textId: 'main',
    iconId: '',
    path: '/user'
  },
  {
    id: 2,
    textId: 'consumption',
    iconId: 'consumption',
    path: 'intake'
  },
  {
    id: 3,
    textId: 'equipment',
    iconId: 'equipment',
    path: 'device'
  },
  {
    id: 4,
    textId: 'meteringPoints',
    iconId: '',
    path: 'metering_points'
  },
  {
    id: 5,
    textId: 'reports',
    iconId: 'broadcast',
    path: 'report'
  },
  {
    id: 6,
    textId: 'events',
    iconId: 'events',
    path: 'events'
  },
]

export const BLOCK_PATHS_LIST = [NAV_BAR_LIST[1].path, NAV_BAR_LIST[3].path, NAV_BAR_LIST[4].path]
