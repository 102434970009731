import React, {useState} from 'react';
import './Chat.sass'
import {Message, NoMessage, WhatsAppIcon, TelegramIcon, Pen} from '@/assets/svg'
import * as Sentry from "@sentry/react";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import {useIntl} from "react-intl";

const Chat = () => {
  const intl = useIntl()
  const [isShowMessengers, setIsShowMessengers] = useState(false)
  const toggleMessengers = () => setIsShowMessengers(!isShowMessengers)

  const userData = userDataSelectors.userData.useValue()

  const handleClick = () => {
    Sentry.captureException(new Error("This is custom user feedback error"))

    Sentry.showReportDialog({
      eventId: Sentry.lastEventId(),
      user: {
        name: userData?.login ?? '',
        email: userData?.email ?? '',
      },
      title: intl.formatMessage({id: 'backCall.titleCustom'}),
      subtitle: intl.formatMessage({id: 'backCall.subtitleCustom'}),
      subtitle2: intl.formatMessage({id: 'backCall.subtitle2Custom'}),
      labelName: intl.formatMessage({id: 'backCall.labelName'}),
      labelEmail: intl.formatMessage({id: 'backCall.labelEmail'}),
      labelComments: intl.formatMessage({id: 'backCall.labelComments'}),
      labelClose: intl.formatMessage({id: 'backCall.labelClose'}),
      labelSubmit: intl.formatMessage({id: 'backCall.labelSubmit'}),
      errorGeneric: intl.formatMessage({id: 'backCall.errorGeneric'}),
      errorFormEntry: intl.formatMessage({id: 'backCall.errorFormEntry'}),
      successMessage: intl.formatMessage({id: 'backCall.successMessage'}),
      onLoad: () => {
        const nameInput = document.getElementById("id_name")
        const emailInput = document.getElementById('id_email')
        const commentArea = document.getElementById("id_comments")

        nameInput && nameInput.setAttribute("placeholder",intl.formatMessage({id: 'backCall.namePlaceholder'}),)
        commentArea && commentArea.setAttribute("placeholder","")
        emailInput && emailInput.setAttribute('placeholder', intl.formatMessage({id: 'backCall.emailPlaceHolder'}),)
      }
    })

    toggleMessengers()
  }

  return (
    <div className="messengers">
      <div className="messengers__chat" onClick={toggleMessengers}>
        <img src={Message} style={{opacity: isShowMessengers ? '0' : '1'}} alt="message"/>
        <img src={NoMessage} style={{opacity: isShowMessengers ? '1' : '0'}} alt="message"/>
      </div>

      <div className={`messengers__list ${isShowMessengers ? 'messengers__list_open' : ''}`}>
        <button
          className={'messengers__item messengers__item_btn'}
          onClick={handleClick}
        >
          <img src={Pen} alt={'Pen'}/>
        </button>

        <a
          href="https://chat.whatsapp.com/Ict3K0rUKYU1dqMVrXMGx2"
          target="_blank"
          className="messengers__item messengers__item_wa"
          onClick={toggleMessengers}
        >
          <img src={WhatsAppIcon} alt="WhatsApp"/>
        </a>

        <a
          href="https://t.me/+y75z6eMbICM0NzEy"
          target="_blank"
          className="messengers__item messengers__item_tg"
          onClick={toggleMessengers}
        >
          <img src={TelegramIcon} alt="Telegram"/>
        </a>
      </div>
    </div>
  )
}

export default Chat
