import {DEVICE_CONNECTION_SYSTEM_PATH} from "./const";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import React, {useEffect, useRef, useState} from "react";
import {PERIOD_LEFT_SELECT_STYLES, PERIOD_RIGHT_SELECT_STYLES} from "@/presentation/components/reportsPage/components/const";
import {
  DAY_SCHEDULE_TYPE,
  DAYS_LIST,
  MONTH_SCHEDULE_TYPE,
  SCHEDULE_TYPES_LIST,
  WEEK_SCHEDULE_TYPE,
  WEEK_TYPES_LIST
} from "@/presentation/components/reportsPage/const";
import {devicesEndpoint} from "@/data/api_entities/devices/devices_endpoint";
import {devicePageSelectors} from "@/internal/lib/storeModels/models/devices/devicePageModels";
import {selectors, typeMessage} from "@/presentation/shared/ui/message";
import Loader from "@/presentation/components/loader/Loader";
import DottedSpinner from "@/presentation/components/dottedSpinner/DottedSpinner";
import {scheduleDeviceFormSelectors} from "@/internal/lib/storeModels/models/devices/forms/deviceFormModels";
import SelectInput from "@/presentation/components/common/formComponents/SelectInput";
import Input from "@/presentation/components/common/formComponents/Input";
import {FormattedDate, FormattedMessage, FormattedTime} from "react-intl";
import {responseHandler} from "@/internal/lib/transport/response_handler";

const DeviceConnectionSystem = ({deviceInfo, getDeviceInfo}) => {
  const scheduleType = scheduleDeviceFormSelectors.useFormDataValue('type')
  const setFormDataValue = (name) => scheduleDeviceFormSelectors.setFormDataValue(name)
  const resetForm = scheduleDeviceFormSelectors.useResetForm()
  const setOnValidSubmit = scheduleDeviceFormSelectors.useSetOnValidSubmit()
  const onClickSubmit = scheduleDeviceFormSelectors.useOnClickSubmit()
  const getDevicesList = devicePageSelectors.devicesList.useGetDevicesList()
  const [loading, setLoading] = devicePageSelectors.isDeviceChanging.useState()
  const setMessage = selectors.useSetMessage()

  const timer = useRef()
  const [isGetVisibility, setIsGetVisibility] = useState(false)

  useEffect(() => {
    setOnValidSubmit(onValidSubmit)

    if (!deviceInfo || !deviceInfo.schedule || deviceInfo.schedule.type === 3) return

    setFormDataValue('type')(deviceInfo.schedule.type)
    setFormDataValue('day')(deviceInfo.schedule.day)

    if ((deviceInfo.schedule.hour || deviceInfo.schedule.hour == 0) &&
      (deviceInfo.schedule.minute || deviceInfo.schedule.minute == 0)
    ) {
      const minute = deviceInfo.schedule.minute > 9
        ? deviceInfo.schedule.minute
        : `0${deviceInfo.schedule.minute}`
      const hour = deviceInfo.schedule.hour > 9
        ? deviceInfo.schedule.hour
        : `0${deviceInfo.schedule.hour}`

      setFormDataValue('time')(`${hour}:${minute}`)
    }

    return () => {
      resetForm()
    }
  }, [deviceInfo])

  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current)
      timer.current = 0
    }
  }, [])

  useEffect(() => {
    setFormDataValue('day')(1)
  }, [scheduleType])

  const onValidSubmit = (data) => {
    setLoading(true)

    const timeParts = data.time.split(':')
    const {promise} = devicesEndpoint.changeDeviceSettings({
      id: deviceInfo.id,
      schedule: {
        type: data.type,
        day: data.day,
        hour: parseInt(timeParts[0]) || 0,
        minute: parseInt(timeParts[1]) || 0
      }
    })

    promise
      .then(res => {
        responseHandler(res, {
          ok: {
            callback: () => {
              getDeviceInfo()
            },
            header: <FormattedMessage id={'requests.changeCallFrequencySuccessHeader'}/>,
            message: <FormattedMessage id={'requests.changeCallFrequencySuccess'}/>
          },
          error: {
            message: <FormattedMessage id={'requests.changeCallFrequencyError'}/>,
          }
        })
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.changeCallFrequencyError'}/>,
          count: 3000,
          type: typeMessage.error
        })
      })
      .finally(() => setLoading(false))
  }

  const handleGetBridgePingStatus = () => {
    if (!deviceInfo.id) return

    setIsGetVisibility(true)

    const {promise} = devicesEndpoint.getBridgePingStatus(deviceInfo.id)

    promise
      .then(res => {
        if (res.status === 'pending') {
          timer.current = setTimeout(handleGetBridgePingStatus, 5000)
        } else if (res.status === 'error') {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: <FormattedMessage id={'requests.pollError'}/>,
            count: 3000,
            type: 'error'
          })
          setIsGetVisibility(false)
        } else {
          console.log(res)
          getDevicesList()
          setIsGetVisibility(false)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const cancelPingBridgeStatus = () => {
    if (timer.current) clearTimeout(timer.current)
    setIsGetVisibility(false)
  }

  return (
    <div className={'device-connection'}>
      <ArrowBack
        pathOnVisible={DEVICE_CONNECTION_SYSTEM_PATH}
      />

      <h2 className="title">
        <FormattedMessage id={'devicesPage.systemConnecting'}/>
      </h2>

      <Loader loading={loading}>
        <div className="device-connection__sync">
          <div className="device-connection__sync-info">
            <span>
              <FormattedMessage id={'devicesPage.lastSession'}/>
            </span>

            <p>
              {deviceInfo?.last_sync_date
                ? (
                  <>
                    <FormattedDate value={deviceInfo?.last_sync_date} /> <FormattedTime  value={deviceInfo?.last_sync_date}/>
                  </>
                )
                : <FormattedMessage id={'devicesPage.noInfo'}/>
              }
            </p>
          </div>

          <div className="device-connection__sync-info">
            <span>
              <FormattedMessage id={'devicesPage.nextSession'}/>
            </span>

            <p>
              {deviceInfo?.next_sync_date
                ? (
                  <>
                    <FormattedDate value={deviceInfo?.next_sync_date} /> <FormattedTime value={deviceInfo?.next_sync_date}/>
                  </>
                )
                : <FormattedMessage id={'devicesPage.noInfo'}/>
              }
            </p>
          </div>
        </div>

        {deviceInfo?.type !== 3 &&
          <>
            <div className="device-connection__double-input">
              <SelectInput
                name={'type'}
                label={<FormattedMessage id={'devicesPage.contactFrequency'}/>}
                formSelectors={scheduleDeviceFormSelectors}
                options={SCHEDULE_TYPES_LIST}
                styles={PERIOD_LEFT_SELECT_STYLES}
              />

              {scheduleType === MONTH_SCHEDULE_TYPE &&
                <SelectInput
                  name={'day'}
                  formSelectors={scheduleDeviceFormSelectors}
                  options={DAYS_LIST}
                />
              }

              {scheduleType === WEEK_SCHEDULE_TYPE &&
                <SelectInput
                  name={'day'}
                  formSelectors={scheduleDeviceFormSelectors}
                  options={WEEK_TYPES_LIST}
                  styles={PERIOD_RIGHT_SELECT_STYLES}
                />
              }

              {scheduleType === DAY_SCHEDULE_TYPE &&
                <Input
                  type={'time'}
                  name={'time'}
                  formSelectors={scheduleDeviceFormSelectors}
                />
              }
            </div>

            {isGetVisibility &&
              <span>
                <FormattedMessage id={'devicesPage.devicePolling'}/>
              </span>
            }

            <div className="device-connection__btns">
              {isGetVisibility
                ? (
                  <div className={'device-connection__btn-loader'}>
                    <button className={'btn btn_transparent-bg'} onClick={cancelPingBridgeStatus}>
                      <FormattedMessage id={'devicesPage.stopPolling'}/>
                    </button>

                    <DottedSpinner scale={0.5}/>
                  </div>
                )
                : (
                  <button
                    className="btn btn_transparent-bg"
                    onClick={handleGetBridgePingStatus}
                    disabled={isGetVisibility}
                  >
                    <FormattedMessage id={'devicesPage.pollNow'}/>
                  </button>
                )
              }

              <button
                className="btn btn_transparent-bg"
                onClick={onClickSubmit}
              >
                <FormattedMessage id={'app.save'}/>
              </button>
            </div>
          </>
        }
      </Loader>
    </div>
  )
}

export default DeviceConnectionSystem
