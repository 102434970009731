import AsyncSelectComponent from "@/presentation/components/common/AsyncSelect";
import {useEffect, useRef, useState} from "react";
import {suggestEndpoint} from "@/data/api_entities/suggest/suggest_endpoint";
import {setMessage} from "@/presentation/shared/ui/message";
import {WITHOUT_FLAT_TYPES} from "./const";
import {FormattedMessage} from "react-intl";
import ValidationsWrapper from "@/presentation/components/common/formComponents/ValidationsWrapper";
import {isOpenAsideSelectors} from "@/internal/lib/storeModels/models/status/asideFormModel";

const DadataSelect = (
  {
    label,
    name = null,
    placeholder = '',
    addressFormSelectors,
    startQueryFormNames = [],
    clearNameOnChange
  }) => {
  const [noDadataOptionsMsg, setNoDadataOptionsMsg] = useState(<FormattedMessage id={'addressForm.enterAddress'}/>)
  const [addressInputValue, setAddressInputValue] = useState('')

  const lastRequest = useRef(null)

  const validations = name
    ? addressFormSelectors.useFormValueValidation(name)
    : []

  const formData = addressFormSelectors.useFormData()
  const setFormValue = (name) => addressFormSelectors.setFormDataValue(name)
  const inputValue = name
    ? addressFormSelectors.useFormDataValue(name)
    : addressInputValue

  const startQuery = startQueryFormNames.map(name => formData[name]).join(' ')

  useEffect(() => {
    setAddressInputValue('')
  }, [clearNameOnChange])

  const setDataFromDadata = (e) => {
    const {
      area,
      house,
      city,
      region,
      street,
      flat,
      geo_lat,
      geo_lon,
      block_type,
      block,
      settlement
    } = e.value

    const blockType = block_type ? ` ${block_type}` : ''
    const blockNum = block || ''

    const data = {
      area: area || formData.area,
      building: house ? house + blockType + blockNum : '',
      city: city || settlement || '',
      region: region || '',
      street: street || '',
      apartment: WITHOUT_FLAT_TYPES.includes(formData.object_type)
        ? ''
        : flat || '',
      geo_len: geo_lon || '',
      geo_lat: geo_lat || '',
    }

    for (const key in data) {
      if (key in formData) {
        setFormValue(key)(data[key])
      }
    }

    // if (!!data.street && !formData.name) {
    //   const building = house ? house + blockType + blockNum : ''
    //   setFormValue('name')(`${data.street} ${building}`)
    // }
  }

  const handleChangeDadataSelect = (e) => {
    if (!e) return

    if (!name) {
      setAddressInputValue(e.label)
    }

    setDataFromDadata(e)
  }

  const handleChangeAsyncInputValue = (value, {action}) => {
    const noOptionsMsg = value
      ? <FormattedMessage id={'app.noData'}/>
      : <FormattedMessage id={'addressForm.enterAddress'}/>

    setNoDadataOptionsMsg(noOptionsMsg)

    if (action !== 'menu-close' &&  action !== 'input-blur' ) {
      if (name) {
        setFormValue(name)(value)
      } else {
        setAddressInputValue(value)
      }
    }
  }

  const getOptions = (inputValue, query = '') => {
    lastRequest.current?.abort()
    const queryStr = query.length
      ? `${query} `
      : ''
    const {promise, controller} = suggestEndpoint.getDadataInfo({
      value: `${queryStr}${inputValue}`
    })
    lastRequest.current = controller
    const result = promise
      .then(res => {
        lastRequest.current = null
        const options = (res.suggests ?? []).map(suggest => {
          return {
            value: {...suggest},
            label: suggest.value
          }
        })

        return options
      })
      .catch(err => {
        if (err.name === 'AbortError') return
        lastRequest.current = null
        console.log('ERRRORRR')
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.loadAddressError'}/>,
          type: 'error',
          count: 3000
        })
        console.log(err)
      })

    return result
  }

  return (
    <div className={'form-group'}>
      <label>{label}</label>

      <ValidationsWrapper validationMsgs={validations}>
        <AsyncSelectComponent
          name={name}
          isError={!!validations.length}
          loadOptions={(value) => getOptions(value, startQuery)}
          placeholder={placeholder}
          noOptionsMessage={() => noDadataOptionsMsg}
          inputValue={inputValue}
          onInputChange={handleChangeAsyncInputValue}
          handleChange={handleChangeDadataSelect}
        />
      </ValidationsWrapper>
    </div>
  )
}

export default DadataSelect
