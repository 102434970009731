import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'intake'
const translations = {
  consumption: 'Verbrauch',
  chartType: 'Diagramm typ',
  mode: 'Modus',
  display: 'Anzeige',
  year: 'Jahr',
  month: 'Monat',
  forecastName: 'Vorhersage {name}',
  lastYear: 'Letztes Jahr',
  thisYear: 'Dieses Jahr',
  forecast: 'Vorhersage',
  m: 'm',
  byDays: 'Tageweise',
  byMonths: 'Nach Monaten',
  byYears: 'Auf Jahre',
  fact: 'Wirklichkeit',
  comparison: 'Vergleich',
  average: 'Durchschnitt - ',
  meteringPoints: 'Abrechnungspunkte',
  counter: 'Zähler',
  values: 'Hinweise',
  onMoment: 'Im Augenblick',
  startPeriod: 'Zu Beginn der Periode',
  endPeriod: 'Am Ende der Periode',
  increment: 'Zuwachs',
}

export const intakeDe = createTranslationsWithSpaceName(translations, spaceName)
