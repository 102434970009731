import {useParams} from "react-router-dom";
import {useEffect, useMemo, useRef, useState} from "react";
import {reportsEndpoint} from "@/data/api_entities/reports/reports_endpoint";
import {ImgLinkArrow} from "@/assets/svg";
import icons from "@/assets/svg/icons/icons.svg";
import Loader from "@/presentation/components/loader/Loader";
import {PREVIEW_REPORT_INIT_DATA} from "@/internal/lib/storeModels/models/report/const";
import {API_URL} from "@/config";
import {selectors} from "@/presentation/shared/ui/message";
import {FormattedDate, FormattedMessage, FormattedTime} from "react-intl";

const ReportHistory = ({children, reportData}) => {
  const params = useParams()

  const historyReportLink = useRef()

  const [historyTaskId, setHistoryTaskId] = useState(null)
  const [pendingTimerId, setPendingTimerId] = useState(null)
  const [lastGetHistoryRequest, setLastGetHistoryRequest] = useState(null)
  const [historyList, setHistoryList] = useState([])
  const [isShowHistoryList, setIsShowHistoryList] = useState(false)
  const [activeHistoryTab, setActiveHistoryTab] = useState(null)
  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const setMessage = selectors.useSetMessage()

  const toggleIsShowHistoryList = () => {
    if (isShowHistoryList) setActiveHistoryTab(null)

    setIsShowHistoryList(!isShowHistoryList)
  }

  const activeHistoryBtnClass = useMemo(() => {
    return isShowHistoryList ? 'active' : ''
  }, [isShowHistoryList])

  useEffect(() => {
    return () => {
      if (pendingTimerId) clearTimeout(pendingTimerId)
    }
  }, [])

  useEffect(() => {
    if (!params.id) return

    if (lastGetHistoryRequest) {
      lastGetHistoryRequest.abort()
    }

    const {promise, controller} = reportsEndpoint.getReportHistory(params.id)

    setLastGetHistoryRequest(controller)

    promise
      .then(res => {
        if (!res.entries) {
          setLastGetHistoryRequest(null)
          setHistoryList([])
          return
        }

        setHistoryList(res.entries)
        setLastGetHistoryRequest(null)
      })
      .catch(err => {
        if (err.name === 'AbortError') return

        setMessage({
          message: <FormattedMessage id={'requests.getReportsHistoryError'}/>,
          header:  <FormattedMessage id={'requests.error'}/>,
          type: 'error',
          count: 3000
        })
        setLastGetHistoryRequest(null)
        console.log(err)
      })
      .finally(() => {
        if (isFirstLoading) {
          setIsFirstLoading(false)
        }
      })
  }, [params.id])

  const onDownLoadFile = (url) => {
    historyReportLink.current.href = `${API_URL}${url}`

    historyReportLink.current.click()
  }

  const getPreviewFileUrl = (id) => {
    reportsEndpoint.getReportPreviewTaskId(id).promise
      .then(res => {
        if (res.status === 'pending') {
          const timerId = setTimeout(() => getPreviewFileUrl(id), 1000)
          setPendingTimerId(timerId)
        } else if (res.status === 'ok') {
          setHistoryTaskId(null)
          onDownLoadFile(res.url)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (!historyTaskId) return

    getPreviewFileUrl(historyTaskId)
  }, [historyTaskId])

  const handleClickHistoryTab = (id) => {
    if (id === activeHistoryTab) {
      setActiveHistoryTab(null)
    } else {
      setActiveHistoryTab(id)
    }
  }

  const handleSubmit = () => {
    reportsEndpoint.getUnsavedReportPreview({...reportData, ...PREVIEW_REPORT_INIT_DATA})
      .promise.then(res => {
        if (!res.task_id) return

        setHistoryTaskId(res.task_id)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const historyListItems = useMemo(() => {
    return historyList.map(history => {
      const sent = history.status === 1
        ? <FormattedMessage id={'reportsPage.successfulTransfer'}/>
        : history.status === 2
          ? (
            <FormattedMessage
              id={'reportsPage.delayed'}
              values={{
                time: (
                  <>
                    <FormattedDate value={history.sended}/>, <FormattedTime value={history.sended}/>
                  </>
                )
              }}
            />
          )
          : <FormattedMessage id={'reportsPage.missed'}/>
      const isRed = history.status !== 1
      const isActive = history.id === activeHistoryTab

      return (
        <div
          className="history-tab"
          key={history.id}
        >
          <div
            className={`history-tab__panel ${isActive ? 'active' : ''}`}
            onClick={() => handleClickHistoryTab(history.id)}
          >
            <div className="history-tab__short-info">
              <span className={'history-tab__schedule'}>
                <FormattedDate value={history.scheduled}/>, <FormattedTime value={history.scheduled}/>
              </span>
              <span className={`history-tab__sent ${isRed ? 'red' : ''}`}>{sent}</span>
            </div>

            <div className="history-tab__arrow">
              <img src={ImgLinkArrow} alt="ImgLinkArrow"/>
            </div>
          </div>

          {isActive &&
            <div className="history-tab__info">
              <div className="history-tab__time-info">
                <span>
                  <FormattedMessage id={'reportsPage.plan'}/>
                </span>

                <span>
                  <FormattedDate value={history.scheduled}/>, <FormattedTime value={history.scheduled}/>
                </span>
              </div>

              <div className={`history-tab__time-info ${isRed ? 'red' : ''}`}>
                <span>
                  <FormattedMessage id={'reportsPage.fact'}/>
                </span>

                <span>
                  {!!history.sended
                    ? (
                      <>
                        <FormattedDate value={history.sended}/>, <FormattedTime value={history.sended}/>
                      </>
                    )
                    : ' - '}
                </span>
              </div>

              <button className="btn btn_transparent-bg" onClick={handleSubmit}>
                <FormattedMessage id={'app.download'}/>
              </button>
            </div>
          }
        </div>
      )
    })
  }, [historyList, activeHistoryTab])

  return (
    <div className={'report-history'}>
      {children}
      <Loader loading={isFirstLoading || !!lastGetHistoryRequest || !!historyTaskId}>
        {!historyList.length
          ? <h2 className="title">
            <FormattedMessage id={'reportsPage.noHistory'}/>
          </h2>
          : <>
            <div className="report-history__tab">
              <h4 className="title_h4">
                <FormattedMessage id={'reportsPage.history'}/>
              </h4>

              <button
                className={`report-history__tab-btn ${activeHistoryBtnClass}`}
                onClick={toggleIsShowHistoryList}
              >
                <svg>
                  <use href={`${icons}#arrow`}/>
                </svg>
              </button>
            </div>

            {isShowHistoryList && historyListItems}
          </>
        }
      </Loader>

      <a ref={historyReportLink} style={{display: 'none'}} download/>
    </div>
  )
}

export default ReportHistory
