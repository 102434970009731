import Spinner from "./Spinner";
import LoadingOverlay from 'react-loading-overlay'
import {useLayoutEffect, useRef} from "react";
LoadingOverlay.propTypes = undefined

const Loader = (
  {
    children,
    loading = true,
    styles = {},
    withoutSpinner = false,
    onScrollWrapper = (e) => {},
    propRef
  }) => {
  const ref = useRef()
  const innerRef = propRef ?? ref
  useLayoutEffect(() => {
    if (!innerRef.current) return
    innerRef.current?.wrapper.current.addEventListener('scroll', onScrollWrapper)

    return () => {
      innerRef.current?.wrapper.current.removeEventListener('scroll', onScrollWrapper)
    }
  }, [innerRef, onScrollWrapper])
  return (
    <LoadingOverlay
      ref={innerRef}
      active={loading}
      spinner={withoutSpinner ? null : <Spinner />}
      fadeSpeed={200}
      styles={{
        overlay: (base) => ({
          ...base,
          background: 'rgba(255, 255, 255, 0.65)',
          backdropFilter: 'blur(1px)',
          minHeight: '88px',
        }),
        wrapper: (base) => ({
          ...base,
          width: '100%'
        }),
        content: (base) => base,
        spinner: (base) => base,
        ...styles
      }}
    >
      {children}
    </LoadingOverlay>
  )
}

export default Loader
