import {TableCell} from "@mui/material";
import {EMPTY_COLUMN} from "../const";
import {ACTIVE_COLOR, DISABLED_COLOR} from "@/presentation/components/analytics/const";

const InvestmentsTableCell = (
  {
    id,
    valueKey,
    activeCell,
    columnWidth,
    modelInfo,
    onFocusInput
  }) => {
  const isDisabled = valueKey === EMPTY_COLUMN
  const saveChanges = modelInfo.selectors.useSaveChanges()
  const hasInvestment = !!modelInfo.selectors.useInvestSum()
  const setValue = isDisabled
    ? () => {}
    : modelInfo.selectors.useSetCurrentInvestValue()
  const formValue = isDisabled
    ? ''
    : modelInfo.selectors.useCurrentInvestValue(valueKey)
  const canNotInput = formValue == '0' && hasInvestment

  const handleClickCell = (e) => {
    const target = e.target.closest('.MuiTableCell-root')
    target.children[0].focus()
  }

  const onChange = (e) => {
    if (isDisabled || canNotInput) return

    const {value} = e.target

    if (typeof value === 'string' && value.includes('-')) return

    setValue({
      key: valueKey,
      value
    })
  }

  const onBlur = (e) => {
    const {value} = e.target

    saveChanges()

    if (value.length) return

    setValue({
      value: '0',
      key: valueKey,
    })
  }

  const background = activeCell === id
    ? ACTIVE_COLOR
    : isDisabled || canNotInput
      ? DISABLED_COLOR
      : 'transparent'

  return (
    <TableCell
      id={id}
      onClick={handleClickCell}
      sx={{
        background,
        borderTop: 'none',
        borderBottom: 'none',
        cursor: 'text',
        input: {
          background,
        },
        pointerEvents: `${isDisabled || canNotInput ? 'none' : ''}`,
        width: columnWidth,
        minWidth: isDisabled || canNotInput ? '' : '7rem'
      }}
    >
      <input
        onWheel={(e) => e.target.blur()}
        type="number"
        disabled={isDisabled || canNotInput}
        value={formValue}
        min={'0'}
        onBlur={onBlur}
        onFocus={(e) => onFocusInput(e, id)}
        onChange={onChange}
      />
    </TableCell>
  )
}

export default InvestmentsTableCell
