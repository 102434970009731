import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'analytics'
const translations = {
  analytics: 'Analytik',
  noAnalytics: 'Es gibt noch keine gespeicherten Berichte. Sie können eine neue erstellen, indem sie auf + klicken',
  heatConsumption: 'Wärmeverbrauch',
  created: 'erstellt ',
  hide: 'ausblenden',
  andMore: 'und {objectsCount, plural, =1 {{objectsCount} weitere objekt} other {{objectsCount} weitere objekte}}',
  period: 'Zeitraum',
  step: 'Schritt',
  month: 'Monat',
  accountingObjects: 'Buchhaltungsobjekte',
  report: 'Bericht',
  edit: 'Bearbeiten',
  createReport: 'Bericht erstellen',
  'deleteReport?': 'Bericht löschen?',
  byTemplate: 'nach vorlage',
  byContinue: 'fortsetzung',
  objectsCount: '{count} objekte',
  intakeEfficiency: 'Verbrauchseffizienz',
  economicAnalysis: 'Wirtschaftliche analyse',
  economicEfficiency: 'Wirtschaftliche effizienz',
  returnOnInvestments: 'Kapitalrendite',
  pivotTable: 'Allgemeine Informationen zu objekten',
  analysisSettings: 'Analyse-setup',
  metersData: 'Zählerdaten',
  durationDays: 'Anzahl der tage mit heizung',
  temperature: 'Temperatur',
  objects: 'Objekte',
  intake: 'Verbrauch, Gcal',
  totalPeriod: 'Gesamt für\xa0zeitraum',
  temperatureWithSign: 'Temperatur, ℃',
  investmentsWithCur: 'Investiert, euro',
  unsavedReport: 'Nicht gespeicherter bericht',
  'leavePage?': 'Möchten sie die seite wirklich verlassen?',
  loseData: 'Alle daten in einem nicht gespeicherten bericht gehen verloren.',
  continueReportName: 'Fortsetzung des berichts "{name}"',
  intakeEfficiencyAnalysis: 'Effizienzanalyse des wärmeverbrauchs',
  analysis: 'Analyse',
  temperatureData: 'Temperaturdaten',
  noAccessToService: 'Momentan besteht kein zugriff auf den dienst mit temperaturdaten.',
  manuallyTemperature: 'Für berechnungen tragen sie bitte die fehlenden daten manuell ein.',
  investments: 'Investitionen',
  newReport: 'Neuer bericht',
  name: 'Name',
  chooseObjects: 'Buchhaltungsobjekte auswählen',
  analysisPeriod: 'Analysezeitraum',
  analysisStep: 'Analyseschritt',
  from: 'Von',
  to: 'Bis',
  periodDisplay: 'Periodenanzeige',
  calendarYear: 'Januar - Dezember',
  heatYear: 'Juli - Juni',
  applyValuesForAllObjects: 'Werte für alle objekte anwenden',
  temperatureBy: 'Temperatur aus der Weatherbit-ressource',
  manualTemp: 'Benutzerdaten',
  synchronize: 'Synchronisieren',
  tempSync: 'Temperatursynchronisation',
  tempSyncWarning: 'Synchronisierung der temperatur mit der Weatherbit-ressource. Möglicher verlust manuell eingegebener daten!',
  reportName: 'Titel des berichts',
  bestIntakeAverages: 'Die besten durchschnittlichen verbrauchswerte, Gcal/m<sup>2</sup>*С° pro tag:',
  byObjects: 'Nach benutzerobjekten:',
  bySystem: 'Nach system:',
  byRegion: 'Nach region:',
  objectType: 'Objekttyp',
  tariff: 'Tarif, rubel',
  payments: 'Heizungszahlungen, rubel',
  totalOfPeriod: 'Gesamt für\xa0zeitraum',
  specificConsumption: 'Spezifischer verbrauch, {unit}/m<sup>2</sup>*С° pro tag',
  overpayments: 'Überzahlung für heizung, rubel',
  overpaymentsPercent: 'Überzahlung in % der gesamtzahlung',
  totalOverpayment: 'Gesamtzahlung für den zeitraum',
  investmentReturn: 'Kapitalrendite, rubel',
  averageSpecificConsumption: 'Durchschnittlicher spezifischer verbrauch, Gcal/m<sup>2</sup>*С° pro tag',
  totalCurrency: 'Gesamt für\xa0zitraum, rubel/%',
  tariffByUnit: 'Tarif, rubel',
  totalPayments: 'Zahlungen für\xa0zeitraum, rubel',
  totalOverpayments: 'Überzahlung für\xa0zeitraum, rubel',
  totalOverpaymentsPercent: 'Überzahlung für\xa0zeitraum, %',
  bestValue: 'Bester verbrauchsindikator, {unit}/m<sup>2</sup>*℃ pro tag',
  averageValue: 'Durchschnittlicher verbrauch für\xa0zeitraum, {unit}/m<sup>2</sup>*℃ pro\xa0tag',
  downloadXLS: 'Herunterladen XLS',
  bestAverageValue: 'Bester durchschnitt über alle eigenschaften',
  worseAverageValue: 'Schlechtester durchschnitt über alle eigenschaften',
  acceptBuyReport: 'Der service ist kostenpflichtig, der bericht kostet {cost}. Bestätigen?',
  freeReport: 'Ein bericht mit diesen objekten für den ausgewählten zeitraum ist bereits im system vorhanden. Das sparen ist kostenlos.',
  reportCost: 'Der Service ist bezahlt, der Bericht kostet {cost}.',
  noCash: 'Unzureichende mittel, um den bericht zu speichern, füllen Sie das guthaben um {sum} auf',
  sendingReport: 'Senden eines berichts',
  saveReport: 'Bericht speichern',
  bestValueInObject: 'Der beste indikator für das objekt',
  bestValueInObjects: 'Der beste indikator für alle objekte',
  aboveAverageValue: 'Überdurchschnittlich für das objekt',
  worseValueInObject: 'Schlechteste immobilienbewertung',
  worseValueInObjects: 'Schlechteste performance für alle objekte',
  belowAverageValue: 'Unterdurchschnittlich für die immobilie',
  savingReport: 'Speichern eines berichts',
  objectSq: 'Objektfläche, m<sup>2</sup>',
  editObject: 'Objekt bearbeiten',
  editingObject: 'Bearbeiten eines Objekts',
  baseValue: 'Basiswert',
  saveBeforeInvest: 'Speichern Sie zuerst den Bericht'
}

export const analyticsDe = createTranslationsWithSpaceName(translations, spaceName)
