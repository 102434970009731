import {components} from "react-select";
import {Dropdown} from "@/assets/svg";
import React from "react";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={Dropdown} alt="Dropdown"/>
    </components.DropdownIndicator>
  )
}

export default DropdownIndicator
