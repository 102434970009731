import AuthorizationLayout from "./AuthorizationLayout";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {authorizationEndpoint} from "@/data/api_entities/authorization/authorization_endpoint";
import {
  recoveryPasswordValidationsSelectors,
} from "@/internal/lib/storeModels/models/authorization/recoveryPasswordModel";
import PasswordRules from "../common/PasswordRules";
import {changePasswordSelectors} from "@/internal/lib/storeModels/models/authorization/forms/recoveryPasswordModels";
import Input from "../common/formComponents/Input";
import {selectors} from "@/presentation/shared/ui/message";
import {authorizationSelectors} from "@/internal/lib/storeModels/models/authorization/authorizationModels";
import {FormattedMessage} from "react-intl";

const RecoveryPasswordForm = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const isValidForm = changePasswordSelectors.useIsFormValid()
  const resetForm = changePasswordSelectors.useResetForm()
  const onClickSubmit = changePasswordSelectors.useOnClickSubmit()
  const setOnValidSubmit = changePasswordSelectors.useSetOnValidSubmit()
  const setRecoveryEmail = authorizationSelectors.recoveryEmail.useSetValue()
  const validatePassword = recoveryPasswordValidationsSelectors.useValidatePassword()
  const resetPasswordValidations = recoveryPasswordValidationsSelectors.useResetValidations()
  const passwordValidations = recoveryPasswordValidationsSelectors.usePasswordValidations()
  const [recoveryToken, setRecoveryToken, resetRecoveryToken] = authorizationSelectors.recoveryToken.useState()
  const setMessage = selectors.useSetMessage()

  useEffect(() => {
    const locationParts = location.pathname.split('/').filter(item => !!item)

    if (locationParts.length < 2) {
      navigate('/input')

      return
    }

    authorizationEndpoint.checkConfirmationCode(locationParts[1])
      .then(res => {
        if (res.code === 404) {
          navigate('/input')
        } else {
          setRecoveryToken(res.token)
          setRecoveryEmail(res.user.email)
        }
      })
      .catch(err => {
        console.log(err)
      })

    return () => {
      resetForm()
      resetPasswordValidations()
      resetRecoveryToken()
    }
  }, [])

  const onValidSubmit = (data) => {
    authorizationEndpoint.recoveryPassword(
      {
        new_password1: data.new_password,
        new_password2: data.repeatedPassword,
      },
      recoveryToken
    )
      .then(() => {
        resetRecoveryToken()
        setMessage({
          message: <FormattedMessage id={'requests.changePasswordSuccess'}/>,
          header: <FormattedMessage id={'requests.changePasswordSuccessHeader'}/>,
          count: 3000
        })
        navigate('/input')
      })
      .catch(err => {
        console.log(err)
        setMessage({
          message: <FormattedMessage id={'requests.changePasswordError'}/>,
          header: <FormattedMessage id={'requests.error'}/>,
          type: 'error',
          count: 3000
        })
      })
  }

  const onSubmit = () => {
    if (!isValidForm) return

    setOnValidSubmit(onValidSubmit)
    onClickSubmit()
  }

  return (
    <AuthorizationLayout
      title={<FormattedMessage id={'authorization.createNewPassword'}/>}
      footerAvailable={false}
      demoAvailable={false}
      customClass="new-pass"
    >
      <div className="form-fields">
        <Input
          label={<FormattedMessage id={'authorization.newPassword'}/>}
          name={'new_password'}
          type="password"
          formSelectors={changePasswordSelectors}
          withChangeValue={validatePassword}
        />

        <Input
          label={<FormattedMessage id={'authorization.repeatPassword'}/>}
          name={'repeatedPassword'}
          type="password"
          formSelectors={changePasswordSelectors}
        />
      </div>

      {!passwordValidations.isValid ?
        <PasswordRules passwordValidations={passwordValidations} />
        : null
      }

      <button
        className="btn"
        onClick={onSubmit}
        disabled={!passwordValidations.isValid || !isValidForm}
      >
        <FormattedMessage id={'authorization.saveNewPassword'}/>
      </button>
    </AuthorizationLayout>
  )
}

export default RecoveryPasswordForm
