import {LOCALES} from "@/i18n/locales";

export const LANG_SELECT_OPTIONS = [
  {
    label: 'Ru',
    value: LOCALES.RUSSIAN
  },
  {
    label: 'En',
    value: LOCALES.ENGLISH,
  },
  {
    label: 'De',
    value: LOCALES.GERMAN,
  },
]

export const SELECT_LANG_STYLES = {
  control: (provided) => ({
    ...provided,
    boxShadow: 'none',
    border: 'none',
    borderRadius: '0',
    ':hover': {
      border: 'none'
    },
    minHeight: 'unset',
    cursor: 'pointer',
    backgroundColor: 'transparent',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    fontSize: '0.875rem',
    lineHeight: '0 !important',
    color: '#36455D',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    margin: 0,
    padding: '0 .4rem 0 0',
    display: 'flex',
    alignItems: 'center',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    margin: 0,
    padding: 0
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: (provided, state) => ({
    width: '12px',
    transition: 'transform .1s ease',
    transform: `rotate(${state.selectProps.menuIsOpen ? '180deg' : '0'})`,
    'img': {
      maxWidth: '100%',
      margin: 0
    }
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
    maxWidth: '100%',
    background: '#fff',
    boxShadow: '0 .5rem 1rem rgba(53, 96, 181, 0.2)',
    borderRadius: '.375rem',
    overflowX: 'hidden',
    margin: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0'
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: state.isDisabled ? '#BBC4D2' : '#36455D',
    padding: '.5rem',
    borderBottom: '1px solid #ECF0F6',
    cursor: state.isDisabled ? '' : 'pointer',
    background: state.isDisabled ? 'rgba(239, 239, 239, 0.3)' : 'transparent',
    ':hover': {
      background: state.isDisabled ? 'rgba(239, 239, 239, 0.3)' : '#E6EFFE'
    },
    ':last-child': {
      borderBottom: 'none'
    },
    'div': {
      width: '1rem',
      height: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '.25rem',
      'img': {
        display: 'block',
        objectFit: 'contain',
        maxHeight: '100%',
      }
    },
    'span': {
      color: 'inherit',
      marginTop: '.125rem',
      fontSize: '.875rem',
      lineHeight: '142.86%',
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#BBC4D2',
  })
}

export const ACTIVE_OBJECT_SELECT_STYLES = {
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
    maxWidth: '149%',
    width: '149%',
    background: '#fff',
    boxShadow: '0 .5rem 1rem rgba(53, 96, 181, 0.2)',
    borderRadius: '.375rem',
    overflowX: 'hidden',
    margin: '0',
  }),
}
