import {setMessage, typeMessage} from "@/presentation/shared/ui/message";
import {FormattedMessage} from "react-intl";

export const responseStatuses = Object.freeze({
  ok: 'ok',
  warning: 'warning',
  error: 'error',
  multiRequest: 'multi_request',
  pending: 'pending'
})

const getMessage = (config, response) => {
  return config?.message ?? response?.message ?? ''
}

export const responseHandler = (
  response,
  config = null
) => {
  if (!response) {
    throw Error("no response")
  } else {
    if (!response.status) {
      throw Error("response doesn't have status")
    } else {
      let message;
      switch (response.status) {
        case responseStatuses.ok:
          message = getMessage(config?.ok, response)

          if (message) {
            setMessage({
              header: config?.ok?.header ?? '',
              message: getMessage(config?.ok, response),
              count: 3000
            })
          }

          if (config?.ok?.callback) {
            config.ok.callback()
          }
          break;
        case responseStatuses.warning:
          message = getMessage(config?.warning, response)

          if (message) {
            setMessage({
              header: config?.warning?.header ?? '',
              message: getMessage(config?.warning, response),
              count: 3000,
              type: typeMessage.info
            })
          }

          if (config?.warning?.callback) {
            config.warning.callback()
          }
          break;
        case responseStatuses.error:
          message = getMessage(config?.error, response)

          if (message) {
            setMessage({
              header: config?.error?.header ?? <FormattedMessage id={'requests.error'}/>,
              message: getMessage(config?.error, response),
              count: 3000,
              type: typeMessage.error
            })
          }

          if (config?.error?.callback) {
            config.error.callback()
          }
          break;
        case responseStatuses.pending:
          if (config?.pending?.callback) {
            config.pending.callback()
          }
          break;
        case responseStatuses.multiRequest:
          let allOk = true;
          response.data.forEach(multiPart => {
            // if (multiPart?.status !== responseStatuses.ok) {
            //   allOk = false
            // }
            responseHandler(multiPart, config)
          })

          // if (allOk) {
          //   setMessage({
          //     header: config?.onOk?.header ?? '',
          //     message: getMessage(config?.onOk, response),
          //     count: 3000
          //   })
          // }
          break;
        default:
          throw Error("unknown response status")
      }
    }
  }


  return response
}
