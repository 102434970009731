import {analyticsListSelectors} from "@/internal/lib/storeModels/models/analytics/analyticsListModel";
import Loader from "@/presentation/components/loader/Loader";
import React, {useEffect, useState} from "react";
import AnalyticItem from "./AnalyticItem";
import {FormattedMessage} from "react-intl";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import {analyticsSelectors} from "@/internal/lib/storeModels/models/analytics/analyticsModels";
import TablesModal from "@/presentation/components/analytics/сomponents/TablesModal";
import Tables from "@/presentation/components/analytics/сomponents/Tables";
import {managerAnalyticsSelectors} from "@/internal/lib/storeModels/models/servicemen/stores";

const AnalyticsList = ({isServicemen = false, loading = false}) => {
  const managerAnalytics = managerAnalyticsSelectors.useValue()
  const analyticsList = analyticsListSelectors.list.useValue()
  const resetAnalyticsList = analyticsListSelectors.list.useReset()
  const isLoading = analyticsListSelectors.loading.useValue()
  const resetIsLoading = analyticsListSelectors.loading.useReset()
  const getAnalyticsList = analyticsListSelectors.list.useGetAnalyticsList()

  const analytics = managerAnalytics ?? analyticsList
  // const [isPaid] = analyticsSelectors.isPaidAnalytic.useState()
  // const [initialResultData, setInitialResultData] = useState(null)
  // const [isOpenCloseSubmitModal, toggleSubmitModal, setIsOpenCloseSubmitModal] = useToggle()
  // const [isOpenModal, toggleOpenModal] = useToggle()

  // const handleAgreeSubmit = () => {
  //   toggleSubmitModal()
  //   toggleOpenModal()
  // }

  // const toggleModal = () => {
  //   if (isOpenModal && !isPaid) {
  //     setInitialResultData(null)
  //     setIsOpenCloseSubmitModal(true)
  //   } else {
  //     toggleOpenModal()
  //   }
  // }

  useEffect(() => {
    if (isServicemen) return
    getAnalyticsList()

    return () => {
      resetAnalyticsList()
      resetIsLoading()
    }
  }, [])

  const intakeAnalyticsList = analytics.map(analytic => {
    return (
      <AnalyticItem
        key={analytic.id}
        data={analytic}
      />
    )
  })

  return (
      <div>
        <div>
          <Loader loading={loading || (isLoading && !isServicemen)}>
            {!analytics.length &&
              <p>
                <FormattedMessage id={'analytics.noAnalytics'}/>
              </p>
                // <div className={'analytic-item'} onClick={toggleModal}>
                //     <p>
                //       <FormattedMessage id={'analytics.noAnalytics'}/>
                //     </p>
                //   <h2 className="analytic-item__h2">+</h2>
                // </div>
            }

            {!!analytics.length &&
                <div className={'analytics__items-block'}>
                  <h4 className="title_h4">
                    <FormattedMessage id={'analytics.heatConsumption'}/>
                  </h4>

                  <div className="analytics__items-list">
                    {/*<div className={'analytic-item'} onClick={toggleModal}>*/}
                    {/*  <h2 className="analytic-item__h2">+</h2>*/}
                    {/*</div>*/}
                    {intakeAnalyticsList}
                  </div>
                </div>
            }
          </Loader>
          {/*{isOpenModal &&*/}
          {/*    <TablesModal*/}
          {/*        hideModal={toggleModal}*/}
          {/*        isOpenCloseSubmitModal={isOpenCloseSubmitModal}*/}
          {/*        toggleSubmitCloseModal={toggleSubmitModal}*/}
          {/*        handleAgreeSubmitCloseModal={handleAgreeSubmit}*/}
          {/*    >*/}
          {/*      <Tables*/}
          {/*          toggleModal={toggleModal}*/}
          {/*          startResultData={initialResultData}*/}
          {/*      />*/}
          {/*    </TablesModal>*/}
          {/*}*/}
        </div>
      </div>

  )
}

export default AnalyticsList
