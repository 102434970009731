import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'main'
const translations = {
  noObjects: 'Keine objekte',
  setUpSystem: 'Lassen sie uns das system einrichten',
  bindDevice: 'Es ist notwendig, das gerät an das buchhaltungsobjekt zu binden und die Übertragung von messwerten einzurichten.',
  noDevices: 'Keine ausrüstung?',
  onlineStore: 'Online-shop',
  stepOne: 'Schritt 1',
  stepTwo: 'Schritt 2',
  stepThree: 'Schritt 3',
  stepOneTitle: 'Buchhaltungsobjekt',
  stepTwoTitle: 'Ausrüstung',
  stepThreeTitle: 'Übertragung',
  stepOneText: 'Fügen sie eine wohnung, ein haus oder eine fabrik hinzu. Jedes objekt hat seine eigene ausstattung',
  stepTwoText: 'Verbinden sie ihr gerät mit der cloud und richten sie alle ein- und ausgänge ein',
  stepThreeText: 'Binden sie einen messpunkt und richten sie messwerte ein',
  stepOneBtn: 'Hinzufügen',
  stepTwoBtn: 'Einstecken',
  stepThreeBtn: 'Einstecken'
}

export const mainDe = createTranslationsWithSpaceName(translations, spaceName)
