import {mkdsStateSelectors} from "@/internal/lib/storeModels/models/mkds/mkdsState";
import MKDForm from "@/presentation/components/heatCalculationPage/components/MKDForm";
import {changeMKDFormStateSelectors, newMKDFormStateSelectors} from "@/internal/lib/storeModels/models/mkds/forms/mkds_forms";
import React, {useState} from "react";
import {mocks} from "@/presentation/components/heatCalculationPage/mocks";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import {mkdsEndpoint} from "@/data/api_entities/mkds/mkds_endpoint";
import {selectors} from "@/presentation/shared/ui/message";
import {FormattedMessage} from "react-intl";

const AsideForm = () => {
  const [isOpenAside, setIsOpenAside] = mkdsStateSelectors.sideForm.useState()
  const [list, setList] = mkdsStateSelectors.list.useState()
  const [isCreatingNew, setIsCreatingNew] = useState(false)
  const resetNewForm = newMKDFormStateSelectors.useResetForm()
  const isNew = mkdsStateSelectors.isNewMKD.useValue()
  const [settingsId, setSettingsId] = mkdsStateSelectors.selectedMKDSettingsId.useState()
  const [isDeleting, setIsDeleting] = useState(false)
  const [isOpenDeleteModal, toggleDeleteModal] = useToggle()
  const [isChanging, setIsChanging] = useState(false)
  const setMessage = selectors.useSetMessage()

  const hideAside = () => {
    setIsOpenAside(false)
    setSettingsId(null)
  }

  const onValidSubmitNewMKD = (data) => {
    setIsCreatingNew(true)

    const {promise} = mkdsEndpoint.create(data)

    promise
      .then(res => {
        if ('id' in res) {
          setList([res, ...list])
          setIsOpenAside(false)
          resetNewForm()
          setMessage({
            header: 'Добавление МКД',
            message: 'МКД успешно добавлен',
            type: 'success',
            count: 3000
          })
        } else {
          console.log(res)
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось создать МКД',
            type: 'error',
            count: 3000
          })
        }
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось создать МКД',
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
      .finally(() => setIsCreatingNew(false))
  }

  const onValidSubmitChangeMKD = (data) => {
    setIsChanging(true)
    const {promise} = mkdsEndpoint.change(data, settingsId)

    promise
      .then(res => {
        if ('id' in res) {
          const currentDataIndex = list.findIndex(object => object.id === res.id)
          if (currentDataIndex >= 0) {
            const newList = [...list]
            newList[currentDataIndex] = res
            setList(newList)
          }
          setIsOpenAside(false)
          setMessage({
            header: 'Изменение МКД',
            message: 'МКД успешно изменен',
            type: 'success',
            count: 3000
          })
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось изменить МКД',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось изменить МКД',
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsChanging(false))
  }

  const handleDeleteMKD = (id) => {
    setIsDeleting(true)

    const {promise} = mkdsEndpoint.delete(id)

    promise
      .then(res => {
        console.log(res)
        setList(list.filter(object => object.id !== id))
        toggleDeleteModal()
        hideAside()
        setMessage({
          header: 'Удаление МКД',
          message: 'МКД успешно удален',
          type: 'success',
          count: 3000
        })
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось удалить МКД',
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
      .finally(() => setIsDeleting(false))
  }

  return (
    <div className={`aside-form ${isOpenAside ? 'aside-form_open' : isOpenAside === null ? '' : 'aside-form_hidden'}`}>

      {isOpenAside &&
        <div className="aside-form__substr">

          {isNew
            ? <MKDForm
              isNew={isNew}
              hideAside={hideAside}
              formStateSelectors={newMKDFormStateSelectors}
              onValidSubmit={onValidSubmitNewMKD}
              isLoading={isCreatingNew}
            />
            : <MKDForm
              isLoading={isChanging}
              hideAside={hideAside}
              formStateSelectors={changeMKDFormStateSelectors}
              onValidSubmit={onValidSubmitChangeMKD}
              toggleDeleteModal={toggleDeleteModal}
              isOpenDeleteModal={isOpenDeleteModal}
              isDeleting={isDeleting}
              onDelete={handleDeleteMKD}
            />
          }
        </div>
      }
    </div>
  )
}

export default AsideForm
