import Select from "react-select";
import {SELECT_BTN_STYLES} from "../const";

const SelectButton = (
  {
    children,
    optionsList = [],
    value,
    onChange,
    onClickBtn
  }) => {
  return (
    <div className={'select-btn'}>
      <div
        className={'select-btn__btn'}
        onClick={onClickBtn}
      >
        {children}
      </div>

      <Select
        className={'select-btn__select'}
        menuPosition={'absolute'}
        options={optionsList}
        value={value}
        menuPlacement={'auto'}
        autoFocus={false}
        isClearable={false}
        styles={SELECT_BTN_STYLES}
        isSearchable={false}
        placeholder={''}
        onChange={onChange}
      />
    </div>
  )
}

export default SelectButton
