import { WattHome, RegistrationImg } from '@/assets/svg'
import {Link, useNavigate} from "react-router-dom";
import {authorizationEndpoint} from "@/data/api_entities/authorization/authorization_endpoint";
import { appSelectors} from "@/internal/lib/storeModels/models/app/appModels";
import {useEffect} from "react";
import cookiesManager from "@/internal/lib/services/cookiManager";
import {authorizationSelectors} from "@/internal/lib/storeModels/models/authorization/authorizationModels";
import LangSelect from "@/presentation/components/main/components/LangSelect";
import {FormattedMessage} from "react-intl";

const AuthorizationLayout = (
  {
    children,
    title = '',
    customClass = '',
    isSignIn = false,
    footerAvailable = true,
    beforeHeader = null,
    demoAvailable = true
  }) => {
  const navigate = useNavigate()

  const isAppError = appSelectors.isAppError.useValue()
  const resetRecoveryEmail = authorizationSelectors.recoveryEmail.useReset()
  const setIncorrectMsg = authorizationSelectors.incorrectSignInMsg.useSetValue()
  const setLoadingSignIn = authorizationSelectors.loadingSignIn.useSetValue()
  const resetRegistrationData = authorizationSelectors.registrationData.useReset()

  useEffect(() => {
    // console.log('isAppError', isAppError)
    // console.log(cookiesManager.get('X-pulse') && !isAppError)
    if (cookiesManager.getCookieToken()) {
      navigate('/')
    }
  }, [isAppError])

  const signInDemo = () => {
    setLoadingSignIn(true)

    authorizationEndpoint.signIn({
        email: "demo@e-watt.ru",
        password: "123123Qwe!",
      }
    )
      .then((res) => {
        if (res.non_field_errors) {
          setIncorrectMsg(res.non_field_errors[0])
        } else {
          setIncorrectMsg('')
        }

        if (res.access_token === undefined) return

        cookiesManager.set('X-pulseId', res.user.id)
        cookiesManager.set('X-pulseAvatar', res.user.avatar)
        cookiesManager.set('X-pulseEmail', res.user.email)
        cookiesManager.set('X-pulse', res.access_token)
        cookiesManager.set('X-pulseName', res.user.login)

        navigate('/')
        resetRegistrationData()
        resetRecoveryEmail()
      })
      .catch(err => {
        console.log('err signIn', err)
      })
      .finally(() => setLoadingSignIn(false))
  }

  return (
    <div className={`authorization-layout ${customClass}`}>
      {beforeHeader}
      <div className="authorization-layout__header">
        <Link to="/input">
          <img src={WattHome} alt="logo" />
          <span className='authorization-layout__logo-text'>Watt</span>
          <span className="authorization-layout__beta">beta</span>
        </Link>

        <LangSelect />
      </div>

      <div className="authorization-layout__body">
        <div className="authorization-layout__main">
          <img
            src={RegistrationImg}
            alt="logo"
            className="authorization-layout__icon"
          />

          <h2 className="title">{title}</h2>

          {children}

          {footerAvailable ?
            isSignIn ?
              <div className="authorization-layout__main-footer authorization-layout__main-footer_no-account">
                <span>
                  <FormattedMessage id={'authorization.noAcc?'}/>
                </span>

                <Link to="/registration">
                  <FormattedMessage id={'authorization.register'}/>
                </Link>
              </div>
              :
              <div className='authorization-layout__main-footer authorization-layout__main-footer_account'>
                <span>
                  <FormattedMessage id={'authorization.haveAcc?'}/>
                </span>

                <Link to="/input">
                  <FormattedMessage id={'authorization.signIn'}/>
                </Link>
              </div>
            : null
          }
        </div>

        {demoAvailable &&
          <div className="authorization-layout__demo">
            <span>
              <FormattedMessage
                id={'authorization.lookDemo'}
                values={{a: () => (<br></br>)}}
              />
            </span>

            <button onClick={signInDemo}>
              <FormattedMessage id={'authorization.demo'}/>
            </button>
          </div>
        }
      </div>
    </div>
  )
}

export default  AuthorizationLayout
