import {useEffect, useState} from "react";

import SelectComponent from "@/presentation/components/common/selects/Select";
import {sumFormatter} from "@/internal/formatters/formatters";
import ArrowBack from "@/presentation/components/common/ArrowBack";
import {HISTORY_PATH} from "../../const";
import {balanceEndpoint} from "@/data/api_entities/balance/balance_endpoint";
import {FormattedMessage} from "react-intl";
import Loader from "@/presentation/components/loader/Loader";

const History = () => {
  const [transactions, setTransactions] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    balanceEndpoint.getTransactionHistory().promise
      .then((result) => {
        const items = result.map(item => {
          return (
            <div key={item.id} className='history'>
              <div className="history__header">
                <span>{item.date} {item.time}</span>
              </div>
              <div className="history__body">
                <div className="history__entity-info">
                  <span className='bold'>
                    {item.sum > 0
                      ? <FormattedMessage id={'userPage.topUping'}/>
                      : <FormattedMessage id={'userPage.writeOff'}/>
                    }
                  </span>
                  {item.event &&
                    <span>
                        {item.event.info &&
                            <div> {item.event.info}</div> }
                        {item.event.device}
                    </span>
                  }
                </div>
                <div className="history__operation">
                  <span className={item.sum > 0 ? 'green' : ''}>
                    {sumFormatter(item.sum)} ₽
                  </span>
                  <span>{item.reason}</span>
                </div>
              </div>
            </div>
          )})
        setTransactions(items)
      })
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <div className='user-page__column user-page__column_history'>
      <ArrowBack
        pathOnVisible={HISTORY_PATH}
      />

      <h2 className="title user-page__title">
        <FormattedMessage id={'userPage.writeOffHistory'} />
      </h2>

      <p>
        <FormattedMessage id={'userPage.keepHistory'} />
      </p>

      {/*<button className="btn btn_transparent-bg">Скачать в XLS</button>*/}

      <h4 className="title_h4">
        <FormattedMessage id={'userPage.latestTransactions'} />
      </h4>

      <Loader loading={isLoading}>
        {/*<div className="form-group">*/}
        {/*  <label>Отображать</label>*/}
        {/*  <SelectComponent*/}
        {/*    optionsList={optionsList}*/}
        {/*    defaultValue={optionsList[0]}*/}
        {/*    value={optionsList[0]}*/}
        {/*  />*/}
        {/*</div>*/}

        <div className="user-page__history-list">
          {transactions.length
            ? transactions
            : <FormattedMessage id={'userPage.noTransactions'} />}
        </div>
      </Loader>
    </div>
  )
}

export default History
