import ArrowBack from "@/presentation/components/common/ArrowBack";
import Loader from "@/presentation/components/loader/Loader";
import Input from "@/presentation/components/common/formComponents/Input";
import {calculationNameFormStateSelectors} from "@/internal/lib/storeModels/models/mkds/calculations/forms";
import React, {useEffect, useRef, useState} from "react";
import {heatCalculateEndpoints} from "@/data/api_entities/heat_calculate/heat_calculate_endpoints";
import {calculationsSelectors} from "@/internal/lib/storeModels/models/mkds/calculations/calculationsState";
import {useNavigateBack} from "@/internal/custom_hooks/navigateBack";
import {selectors} from "@/presentation/shared/ui/message";
import {FormattedMessage} from "react-intl";

const CalcNamePage = ({info, setInfo}) => {
  const setInitData = calculationNameFormStateSelectors.useSetInitFormData()
  const isValidForm = calculationNameFormStateSelectors.useIsFormValid()
  const setOnValidSubmit = calculationNameFormStateSelectors.useSetOnValidSubmit()
  const onClickSubmit = calculationNameFormStateSelectors.useOnClickSubmit()
  const resetForm = calculationNameFormStateSelectors.useResetForm()
  const changeRequest = useRef(null)
  const [loading, setLoading] = useState(false)
  const [list, setList] = calculationsSelectors.calcList.useState()
  const [navigateBack] = useNavigateBack()
  const setMessage = selectors.useSetMessage()
  const onChangeName = (data) => {
    setLoading(true)
    changeRequest.current?.abort()
    const {promise, controller} = heatCalculateEndpoints.change({
      name: data.name,
      start_date: info?.start_date,
      end_date: info?.end_date,
      mansions: info?.mansions,
      energy_period: info?.energy_period,
      unit_type: info?.unit_type
    }, info?.calculate)

    changeRequest.current = controller

    promise
      .then(res => {
        if ('name' in res) {
          const updatedList = [...list]

          const index = updatedList.findIndex(calc => calc.calculate === res.id)
          setInfo({
            ...info,
            name: res.name
          })

          if (index >= 0) {
            updatedList[index] = {
              ...updatedList[index],
              name: res.name
            }

            setList(updatedList)
          }
          navigateBack()
          setMessage({
            header: 'Изменение названия расчета',
            message: 'Название расчета успешно изменено',
            type: 'success',
            count: 3000
          })
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось изменить название расчета',
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
        changeRequest.current = null
        setLoading(false)
      })
      .catch(err => {
        if (err.name === 'AbortError') return
        changeRequest.current = null
        setLoading(false)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось изменить название расчета',
          type: 'error',
          count: 3000
        })
        console.log(err)
      })
  }

  useEffect(() => {
    setOnValidSubmit(onChangeName)
    setInitData({
      name: info?.name ?? ''
    })

    return () => {
      resetForm()
      changeRequest.current?.abort()
    }
  }, [])

  const handleSubmit = () => {
    if (!isValidForm) return

    onClickSubmit()
  }

  return (
    <div className="column calc-name-page">
      <ArrowBack
        pathOnVisible={'edit'}
      />

      <Loader loading={loading}>
        <h2 className={'title'}>
          Название расчета
        </h2>

        <div className="form-fields">
          <Input
            formSelectors={calculationNameFormStateSelectors}
            name={'name'}
            label={'Название'}
          />
        </div>

        <button
          className={'btn'}
          onClick={handleSubmit}
        >
          Сохранить
        </button>
      </Loader>
    </div>
  )
}

export default CalcNamePage
