import ValidationsWrapper from "@/presentation/components/common/formComponents/ValidationsWrapper";
import DefaultSelect from "@/presentation/components/common/selects/DefaultSelect";

const InputWithSelect = (
  {
    formSelectors,
    inputName,
    selectName,
    label,
    options,
    valueFormatter = (value) => value,
    disabled = false,
    type = 'text',
  }) => {
  const inputValue = formSelectors.useFormDataValue(inputName)
  const selectValue = formSelectors.useFormDataValue(selectName)
  const validations = formSelectors.useFormValueValidation(inputName)
  const setInputFieldData = formSelectors.useSetFormDataValue(inputName)
  const setSelectFieldData = formSelectors.useSetFormDataValue(selectName)

  const option = options.find(option => option.value === selectValue) ?? null

  const error = validations?.length
    ? 'error'
    : ''

  const onChangeInput = (e) => {
    if (disabled) return

    const {value} = e.target

    setInputFieldData(valueFormatter(value))
  }

  const onChangeSelect = (option) => {
    if (disabled) return

    setSelectFieldData(option.value)
  }

  return (
    <div className={'input-with-select'}>
      {!!label &&
        <label>{label}</label>
      }

      <ValidationsWrapper
        validationMsgs={validations}
      >
        <div className={`input-with-select__inputs ${error}`}>
          <input
            value={inputValue}
            onChange={onChangeInput}
            disabled={disabled}
            type={type}
          />

          <DefaultSelect
            options={options}
            value={option}
            disabled={disabled}
            onChange={onChangeSelect}
            styles={{
              control: (provided, state) => ({
                ...provided,
                boxShadow: 0,
                border: 'none',
                borderLeft: '1px solid #ECF0F6',
                ':hover': {
                  borderLeft: '1px solid #ECF0F6'
                },
                minHeight: 'unset',
                cursor: 'pointer',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                maxHeight: '2.6175rem',
                backgroundColor: state.isDisabled ? 'rgba(239, 239, 239, 0.3)' : 'transparent',
              })
            }}
          />
        </div>
      </ValidationsWrapper>
    </div>
  )
}

export default InputWithSelect
