import DefaultSelect from "@/presentation/components/common/selects/DefaultSelect";
import {deviceOptionFormatter} from "@/presentation/components/deviceForm/components/helpers";
import DropdownIndicator from "@/presentation/components/common/selects/CustomDropdownIndicator";
import {MULTI_DEVICE_SELECT_STYLES} from "@/presentation/components/deviceForm/const";
import {devicesMasterIdSelectors} from "@/internal/lib/storeModels/models/devices/forms/deviceFormModels";
import {FormattedMessage} from "react-intl";

const TransferDeviceSelect = ({devices, loading}) => {
  const [masterId, setMasterId] = devicesMasterIdSelectors.useState()
  const value = devices.find(device => device.id === masterId)

  const onChange = (option) => setMasterId(option.id)

  return (
    <div className="form-group">
      <label>
        <FormattedMessage id={'deviceForm.toSelectDevice'}/>
      </label>

      <DefaultSelect
        value={value}
        loading={loading}
        options={devices}
        getOptionLabel={deviceOptionFormatter}
        getOptionValue={option => option.id}
        isSearchable={false}
        isClearable={false}
        placeholder={''}
        styles={MULTI_DEVICE_SELECT_STYLES}
        components={{DropdownIndicator}}
        maxMenuHeight={280}
        menuPosition={'absolute'}
        menuPlacement={'auto'}
        onChange={onChange}
      />
    </div>
  )
}

export default TransferDeviceSelect
