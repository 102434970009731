import {EMPTY_COLUMN} from "../const";
import {useEffect, useMemo} from "react";
import {TableCell} from "@mui/material";
import DurationsDayPicker from "@/presentation/components/common/DurationsDayPicker";
import {ACTIVE_COLOR, DISABLED_COLOR} from "@/presentation/components/analytics/const";

const DurationsTableCell = (
  {
    valueKey,
    id,
    activeCell,
    columnWidth,
    modelInfo,
    setActiveCell
  }) => {
  const isDisabled = valueKey === EMPTY_COLUMN
  const saveChanges = modelInfo.selectors.useSaveChanges()

  const formValue = isDisabled
    ? []
    : modelInfo.selectors.useCurrentDurationValue(valueKey)
  const setValue = isDisabled
    ? () => {}
    : modelInfo.selectors.useSetCurrentDurationValue()
  const setTempValue = isDisabled
    ? () => {}
    : modelInfo.selectors.useSetCurrentTempValue()
  const tempValue = isDisabled
    ? () => {}
    : modelInfo.selectors.useCurrentTempValue(valueKey)

  const maxDate = useMemo(() => {
    if (isDisabled) return ''

    let value
    const now = new Date().getTime()
    const date = new Date(valueKey)

    const days = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()

    value = new Date(valueKey)

    value.setDate(date.getDate() + days - 1)

    if (now < value.getTime()) {
      value = new Date()
    }

    return value
  }, [])

  useEffect(() => {
    if (formValue.length !== 0 && tempValue === null) {
      setTempValue({
        key: valueKey,
        value: ''
      })
    }
  }, [formValue])

  const onSelectDates = (values, isDisabled) => {
    if (isDisabled) return

    setValue({
      key: valueKey,
      value: values
    })
  }

  const handleClickCell = (e) => {
    const target = e.target.closest('.MuiTableCell-root')

    if (!target) return

    target.children[0].click()
  }

  const handleBlur = () => {
    setActiveCell(null)
    saveChanges()
  }

  const handleClickInput = () => {
    setActiveCell(id)
  }

  const background = activeCell === id
    ? ACTIVE_COLOR
    : isDisabled
      ? DISABLED_COLOR
      : 'transparent'

  return (
    <TableCell
      id={id}
      sx={{
        background,
        borderTop: 'none',
        borderBottom: 'none',
        cursor: 'text',
        input: {
          background,
        },
        pointerEvents: `${isDisabled ? 'none' : ''}`,
        width: columnWidth,
        minWidth: isDisabled ? '' : '7rem'
      }}
      onClick={handleClickCell}
    >
      <DurationsDayPicker
        fromDate={!isDisabled ? new Date(valueKey) : ''}
        toDate={maxDate}
        values={formValue}
        onSelectDates={(values) => onSelectDates(values, isDisabled)}
        handleBlur={handleBlur}
        handleClickInput={() => handleClickInput()}
      />
    </TableCell>
  )
}

export default DurationsTableCell
