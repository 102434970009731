import {useEffect, useState} from "react";
import {
  ACCESS_PATH,
  ACCOUNTING_OBJECTS_PATH,
  MY_DETAILS_PATH,
  TARIFF_PATH,
  USER_LINKS_LIST
} from "./const";
import {selectors} from '@/presentation/shared/ui/message'
import {Route, Routes, useNavigate} from "react-router-dom";
import LinkTab from "../common/LinkTab";
import Tariffs from "./tariffs/Tariffs";
import UserData from "./userData/UserData";
import cookiesManager from "@/internal/lib/services/cookiManager";
import Objects from "./objects/Objects";
import Access from "./access/Access";
import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import {userEndpoint} from "@/data/api_entities/user/user_endpoint";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import {FormattedMessage} from "react-intl";

const UserPage = ({isService = false}) => {
  const navigate = useNavigate()

  const [isUserDeleteLoading, setIsUserDeleteLoading] = useState(false)
  const [afterDeleteText, setAfterDeleteText] = useState('')
  const [isOpenDeleteModal, toggleDeleteModal] = useToggle()

  const userData = userDataSelectors.userData.useValue()
  const resetUserData = userDataSelectors.userData.useReset()
  const setMessage = selectors.useSetMessage()

  const links = USER_LINKS_LIST.filter(({id}) => {
    if (!isService) return true

    return id !== 4
  }).map(({id, path, textId}) => {
    const subText = id === 1
      ? (
        <FormattedMessage
          id={'userPage.balanceAndPoints'}
          values={{ bonus: userData?.bonus ?? 0, balance: userData?.balance ?? 0 }}
        />
      )
      : ''

    return (
      <LinkTab
        key={id}
        textId={textId}
        subText={subText}
        path={path}
      />
    )
  })

  const handleLogOut = () => {
    cookiesManager.cookiesDelete()
    resetUserData()
    navigate('/input')
  }

  const handleToggleDeleteModal = () => {
    if (!!afterDeleteText) return

    toggleDeleteModal()
  }

  const onDeleteUser = () => {
    cookiesManager.cookiesDelete()
    setIsUserDeleteLoading(true)

    const {promise} = userEndpoint.deleteUser()

    promise
      .then(() => {
        setAfterDeleteText((() => <FormattedMessage id={'userPage.accountDeleted'}/>)())

        setTimeout(() => {
          navigate('/input')
          cookiesManager.cookiesDelete()
          setAfterDeleteText('')
        }, 3000)
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.deleteAccErr'}/>,
          type: 'error',
          count: 3000
        })
      })
      .finally(() => setIsUserDeleteLoading(false))
  }

  return (
    <div className='user-page'>
      <div className="user-page__column">
        <h2 className="title">{userData.login}</h2>

        <div className="user-page__links">
          {links}
        </div>

        <div className="user-page__btn-wrapper user-page__btn-wrapper_first-page">
          <button
            className="btn btn_transparent-bg "
            onClick={handleLogOut}
          >
            <FormattedMessage id={'userPage.logOut'}/>
          </button>

          {!isService &&
            <button
              className="btn btn_red"
              onClick={toggleDeleteModal}
            >
              <FormattedMessage id={'userPage.deleteAcc'}/>
            </button>
          }
        </div>
      </div>

      <DefaultModal
        isOpen={isOpenDeleteModal}
        headerTitle={<FormattedMessage id={'userPage.deleteAcc?'}/>}
        agreeBtnConfig={{
          className: 'btn btn_red',
          text: <FormattedMessage id={'app.delete'}/>
        }}
        disagreeBtnConfig={{
          className: 'btn btn_transparent-bg',
          text: <FormattedMessage id={'app.cancel'}/>
        }}
        toggleModal={handleToggleDeleteModal}
        onDisagree={handleToggleDeleteModal}
        onAgree={onDeleteUser}
        loading={isUserDeleteLoading}
        afterLoadingText={afterDeleteText}
      />

      <Routes>
        <Route
          path={`${TARIFF_PATH}/*`}
          element={<Tariffs isService={isService}/>}
        />

        <Route
          path={`${MY_DETAILS_PATH}/*`}
          element={<UserData />}
        />

        {/*<Route*/}
        {/*  path={`${ACCOUNTING_OBJECTS_PATH}/*`}*/}
        {/*  element={<Objects />}*/}
        {/*/>*/}

        {!isService &&
          <Route
            path={`${ACCESS_PATH}/*`}
            element={<Access />}
          />
        }

      </Routes>
    </div>
  )
}

export default UserPage
