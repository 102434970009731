import InputMask from 'react-input-mask'
import ValidationsWrapper from "./ValidationsWrapper";

const MaskInput = (
  {
    label,
    formSelectors,
    name,
    type,
    mask,
    valueFormatter = (value) => value
  }) => {
  const value = formSelectors.useFormDataValue(name)
  const setFieldData = formSelectors.useSetFormDataValue(name)
  const validations = formSelectors.useFormValueValidation(name)

  const onChange = (e) => {
    const {value} = e.target

    setFieldData(valueFormatter(value))
  }

  return (
    <div className={'form-group'}>
      <label>{label}</label>

      <ValidationsWrapper validationMsgs={validations}>
        <InputMask
          name={name}
          mask={mask}
          type={type}
          value={value}
          onChange={onChange}
        />
      </ValidationsWrapper>

    </div>
  )
}

export default MaskInput
