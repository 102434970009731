import {FormattedMessage} from "react-intl";

export const shortLoginValidationMsg = (length) => <FormattedMessage id={'validationsMsgs.shortLogin'} values={{length}}/>
export const longLoginValidationMsg = (length) => <FormattedMessage id={'validationsMsgs.longLogin'} values={{length}}/>
export const longNameValidationMsg = (length) => <FormattedMessage id={'validationsMsgs.longName'} values={{length}}/>
export const loginValidationMsg = <FormattedMessage id={'validationsMsgs.login'}/>
export const EMAIL_VALIDATION_MSG = <FormattedMessage id={'validationsMsgs.email'}/>
export const phoneValidationMsg = <FormattedMessage id={'validationsMsgs.phone'}/>
export const requiredMsg = <FormattedMessage id={'validationsMsgs.required'}/>
export const repeatPasswordMsg = <FormattedMessage id={'validationsMsgs.repeat'}/>
export const minDateMsg = <FormattedMessage id={'validationsMsgs.minDate'}/>
export const maxDateMsg = <FormattedMessage id={'validationsMsgs.maxDate'}/>
export const NOT_FOUND_EMAIL = <FormattedMessage id={'validationsMsgs.notFoundEmail'}/>
export const innMsg = <FormattedMessage id={'validationsMsgs.inn'}/>
export const notFullRangeData = <FormattedMessage id={'validationsMsgs.notFullRangeData'}/>
export const notMinusMsg = <FormattedMessage id={'validationsMsgs.notMinus'}/>
export const wrongLonValueMsg = <FormattedMessage id={'validationsMsgs.wrongLon'}/>
export const wrongLatValueMsg = <FormattedMessage id={'validationsMsgs.wrongLat'}/>
export const wrongValue = <FormattedMessage id={'validationsMsgs.wrongValue'}/>
export const notZeroMsg = <FormattedMessage id={'validationsMsgs.notZero'}/>
export const getIncorrectSerialNumberMsg = (startSerial) => <FormattedMessage id={'validationsMsgs.incorrectSerialNumber'} values={{startSerial}}/>
