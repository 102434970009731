import {request} from "@/internal/lib/transport/request";

export const enumeratorEndpoint = {
  create(data) {
    const params = {
      url: 'api/heat_enumerator/',
      method: 'POST',
      data: data
    }

    return request(params)
  },
  getById(id) {
    const params = {
      url: `api/heat_enumerator/${id}/`,
      method: 'GET',
    }

    return request(params)
  },
  change(data, id) {
    const params = {
      url: `api/heat_enumerator/${id}/`,
      method: 'PUT',
      data: data
    }

    return request(params)
  },
  delete(id) {
    const params = {
      url: `api/heat_enumerator/${id}/`,
      method: 'DELETE'
    }

    return request(params)
  },
  find(query, id) {
    const params = {
      url: 'api/heat_handbook/search_handbook/',
      method: 'POST',
      data: {
        query: query?.trim(),
        id
      }
    }

    return request(params)
  },
  sendRequest(value) {
    const params = {
      url: 'api/heat_handbook/send_type_op/',
      method: 'POST',
      data: {
        message: (value ?? '').trim()
      }
    }

    return request(params)
  }
}
