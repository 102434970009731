import { API_URL as apiUrl } from "@/config";
import { transactionsHistory as TransHistMock, tariffsListItems as tariffsMock } from "../mock";
import md5 from 'md5';
import cookiesManager from "@/internal/lib/services/cookiManager";

const isMock = false;

const makeAuthHeaders = () => {
    let token = cookiesManager.getCookieToken()
    return {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
        "Authorization": `JWT ${token}`
    }
}

const makeRequest = async (url, method, body) => {
    let response;
    let params = { method: method, headers: makeAuthHeaders() };
    if (method === 'POST') params.body = body;
    try {
        response = await fetch(
            apiUrl + url,
            params,
        );
    } catch(e) {
        console.log(`Возникла непредвиденная ошибка: ${e}`);
        return;
    }
    const { status } = response;
    if (status === 401) {
        console.log('Необходимо еще раз произвести авторизацию')
        return;
    }
    const data = await response.json();
    if (status !== 200) {
        console.log(`Возникла непредвиденная ошибка`);
        console.log(`Код ошибки: ${status}\n Тип ошибки: ${data.error_type}\nТекст ошибки: ${data.error_message}`)
    }
    return data;
}

const getTransactionHistoryMock = async () => {
    return TransHistMock
}

// перенесено в balanceEndpoint

// const getTransactionHistory = async () => {
//     const url = 'api/balance/history';
//     if (isMock) return getTransactionHistoryMock();
//     const body = JSON.stringify({});
//     return makeRequest(url, 'GET', body)
// }

function makeSalt(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
          charactersLength));
    }
    return result;
}

const setPaymentMock = async (amount) => {
    let merchantID = 14194;
    let user = 1;
    let language = 'ru';
    let orderID = 123;
    let testingMode = 1;
    let successUrl = `http://localhost:3000/user_page/tariff/top_up?success=1&amount={tx.amount}`;
    let failureUrl = `http://localhost:3000/user_page/tariff/top_up?success=0`;
    let description = `balance_replenishment_for_user_${user}_on_${amount}`;
    let salt = makeSalt(6);
    let secretKey = '<смотреть_в_коде_бэка_и_ни_в_коем_случае_не_пушить>';
    let signature = md5(`payment.php;${amount};${description};${failureUrl};${language};${merchantID};${orderID};${salt};${successUrl};${testingMode};${secretKey}`);

    let url = `https://www.platron.ru/payment.php?pg_merchant_id=${merchantID}&pg_amount=${amount}
    &pg_description=${description}&pg_salt=${salt}&pg_testing_mode=${testingMode}
    &pg_order_id=${orderID}&pg_success_url=${successUrl}&pg_failure_url=${failureUrl}
    &pg_language=${language}&pg_sig=${signature}`

    return url
}

// перенесено в balanceEndpoint
// const setPayment = async (amount) => {
//     const url = 'api/balance/payment/';
//     if (isMock) return setPaymentMock(amount);
//     const body = JSON.stringify({amount});
//     return makeRequest(url, 'POST', body)
// }

const getTariffsMock = async () => {
    return tariffsMock
}

const getTariffs = async () => {
    // @TODO: переделать на АПИ
    const url = 'api/balance/tariffs';
    if (true) return getTariffsMock();
    // const body = JSON.stringify({});
    // return makeRequest(url, 'GET', body)
}

export { /*getTransactionHistory,*/ getTariffs, /*setPayment*/ };