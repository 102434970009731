import {
  registrationPasswordValidationsSelectors,
} from "@/internal/lib/storeModels/models/authorization/registrationPasswordModel";
import {phoneFormatter} from "@/internal/formatters/formatters";
import Input from "../../common/formComponents/Input";
import {corporateFormSelectors} from "@/internal/lib/storeModels/models/authorization/forms/registrationUserModels";
import MaskInput from "@/presentation/components/common/formComponents/MaskInput";
import FindOrganizationInput from "./FindOrganizationInput";
import {FormattedMessage} from "react-intl";

const CorporateForm = () => {
  const isActualIsLegal = corporateFormSelectors.useIsActualIsLegal()
  const toggleIsActualIsLegal = corporateFormSelectors.useToggleIsActualIsLegal()
  const validatePassword = registrationPasswordValidationsSelectors.useValidatePassword()

  return (
    <>
      <FindOrganizationInput />

      <Input
        label={<FormattedMessage id={'authorization.inn'}/>}
        type="number"
        name="inn"
        formSelectors={corporateFormSelectors}
      />

      <Input
        label={<FormattedMessage id={'authorization.legalName'}/>}
        name="organization_name"
        formSelectors={corporateFormSelectors}
      />

      <Input
        label={<FormattedMessage id={'authorization.legalAddress'}/>}
        name="organization_address"
        formSelectors={corporateFormSelectors}
      />

      <Input
        label={<FormattedMessage id={'authorization.actualAddress'}/>}
        name={'organization_actual_address'}
        formSelectors={corporateFormSelectors}
        disabled={isActualIsLegal}
      />

      <div className="registration__address-match">
        <input
          className="checkbox"
          type="checkbox"
          id="checkbox-id"
          checked={isActualIsLegal}
          onChange={toggleIsActualIsLegal}
        />

        <p onClick={toggleIsActualIsLegal}>
          <FormattedMessage id={'authorization.ifActualIsLegal'}/>
        </p>
      </div>

      <MaskInput
        type="tel"
        label={<FormattedMessage id={'authorization.contactTel'}/>}
        name={'contact'}
        mask={'+7 (999) 999-99-99'}
        formSelectors={corporateFormSelectors}
        valueFormatter={phoneFormatter}
      />

      <Input
        label={<FormattedMessage id={'authorization.e-mail'}/>}
        name={'email'}
        type="email"
        formSelectors={corporateFormSelectors}
      />

      <MaskInput
        type="tel"
        label={<FormattedMessage id={'authorization.phoneOptional'}/>}
        name={'phone'}
        mask={'+7 (999) 999-99-99'}
        formSelectors={corporateFormSelectors}
        valueFormatter={phoneFormatter}
      />

      <Input
        label={<FormattedMessage id={'authorization.password'}/>}
        type={'password'}
        name="password"
        withChangeValue={(value) => validatePassword(value)}
        formSelectors={corporateFormSelectors}
      />
    </>
  )
}

export default CorporateForm
