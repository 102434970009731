import {cloneElement, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@mui/material";
import Loader from "@/presentation/components/loader/Loader";
import {periodFormatter} from "./helpers";
import SelectComponent from "../../common/selects/Select";
import {BY_CONTINUE_TYPE, paginationDirection, TABLE_ROWS_PER_PAGE_LIST} from "./const";
import {analyticsEndpoint} from "@/data/api_entities/analytics/analytics_endpoint";
import {setMessage} from "@/presentation/shared/ui/message";
import { analyticsSelectors} from "@/internal/lib/storeModels/models/analytics/analyticsModels";
import {formattedDateToServ} from "@/internal/formatters/formatters";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import {FormattedMessage, useIntl} from "react-intl";
import WithVisibilitySensor from "@/presentation/components/analytics/сomponents/WithVisibilitySensor";
import {emulateTab} from "emulate-tab";

const TableGenerator = (
  {
    getHeaderConfig,
    children,
    dataRowComponent,
    tablesConfig,
    selectedAddresses,
    isLoading,
    editAnalyticId,
    models,
    columnWidth,
    getObjWithAllMeterings,
    completedData,
    isTempTable = false,}) => {
  const intl = useIntl()

  const [currentTable, setCurrentTable] = useState(1)
  const [page, setPage] = useState(0)
  const [firstTableHeader, setFirstTableHeader] = useState(null)
  const [loaderWrapper, setLoaderWrapper] = useState(null)
  const [isOpenSyncTemp, toggleIsOpenSyncTemp] = useToggle()
  const [isCreatingRows, setIsCreatingRows] = useState(true)

  const analyticId = analyticsSelectors.analyticId.useValue()
  const tempFromDB = analyticsSelectors.temperatureFromDB.useValue()
  const [rowsPerPage, setRowsPerPage] = analyticsSelectors.rowsPerPage.useState()

  useLayoutEffect(() => {
    const continueTypeMinDate = completedData?.newAnalysisType === BY_CONTINUE_TYPE
      ? new Date(completedData.end_date)
      : null

    if (!continueTypeMinDate) return

    continueTypeMinDate.setMonth(continueTypeMinDate.getMonth() + 1)

    const minDate = formattedDateToServ(continueTypeMinDate)
    const actualTableId = tablesConfig.find(config => {
      return config.columns.find(column => column.value === minDate)
    })

    if (actualTableId) {
      setCurrentTable(actualTableId.id)
    }
  }, [])

  useEffect(() => {
    onOpenTable()
  }, [])

  const rowsPerPageOption = useMemo(() => {
    return TABLE_ROWS_PER_PAGE_LIST.find(option => option.value === rowsPerPage)
  }, [rowsPerPage])

  const firstTableHeaderHeight = useMemo(() => {
    if (!firstTableHeader) return 0

    return firstTableHeader.offsetHeight
  }, [firstTableHeader])

  const handleChangeCurrentTable = useCallback((id) => {
    if (isLoading || currentTable === id) return

    scrollToStartTable()
    setIsCreatingRows(true)
    setCurrentTable(id)
  }, [isLoading, currentTable])

  const periodTabs = useMemo(() => {
    return tablesConfig.map((tableConfig) => {
      return (
        <h3
          key={tableConfig.id}
          className={`title_h3 ${currentTable === tableConfig.id ? 'active' : ''}`}
          onClick={() => handleChangeCurrentTable(tableConfig.id)}
        >
          {periodFormatter(tableConfig.startPeriod, tableConfig.endPeriod)}
        </h3>
      )
    })
  }, [tablesConfig, currentTable, handleChangeCurrentTable])

  const scrollToStartTable = () => {
    const loaderWrapperElem = document.querySelector('.tables > ._loading_overlay_wrapper')

    if (!loaderWrapperElem) return

    setLoaderWrapper(loaderWrapperElem)
    loaderWrapperElem.scrollTo(0, 0)
  }

  useEffect(() => {
    scrollToStartTable()
  }, [currentTable])

  const onOpenTable = () => {
    const id = editAnalyticId ?? analyticId

    if (!Object.keys(models).length || !id) return

    const accountingObj = getObjWithAllMeterings()

    const {promise} = analyticsEndpoint.changeAnalytic(accountingObj, id)

    promise
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.analyticMeteringsError'}/>,
          type: 'error',
          count: 3000
        })
      })
  }

  const handleChangePage = (e, newPage) => {
    if (isLoading) return

    setPage(newPage)
    scrollToStartTable()
  }

  const handleChangePerPage = (e) => {

    if (isLoading) return

    setRowsPerPage(e.value)
    setPage(0)
    scrollToStartTable()
  }

  const setRandomData = (e, model, storeName, setValueMethod, order) => {
    e.persist()
    const href = window.location.href

    if (e.key !== "Control" ||
      !(href.includes('.dev.') || href.includes('localhost'))) return

    const storeData = model[storeName].getState()
    const randomStore = {}

    for (const key in storeData) {
      if (storeData[key] === null) {
        randomStore[key] = null

        continue
      }

      const randomValue = Math.round(Math.random() * 100 * order)/100

      randomStore[key] = randomValue
    }

    model[setValueMethod](randomStore)
  }

  const onSyncTemp = () => {
    if (!tempFromDB) toggleIsOpenSyncTemp()

    for (const key in tempFromDB) {
      const currentModel = models[key]
      if (!currentModel) continue

      for (const dateKey in tempFromDB[key]) {
        if (tempFromDB[key][dateKey] === '') continue

        currentModel.selectors.setCurrentTempValue({
          key: dateKey,
          value: tempFromDB[key][dateKey]
        })
      }
    }

    toggleIsOpenSyncTemp()
  }

  const tableHeadRows = useMemo(() => {
    return getHeaderConfig(tablesConfig, currentTable, columnWidth).map((row, i) => {
      return (
        <TableRow
          key={`head-row-${i}`}
        >
          {row.cellsList.map((cell, j) => {
            const topStyles = cell.topStyleForStickyHeader
              ? {top: firstTableHeaderHeight ?? 0}
              : {}

            return (
              <TableCell
                key={`head-row-${i}:cell-${j}`}
                rowSpan={cell.rowSpan ?? 1}
                colSpan={cell.colSpan ?? 1}
                sx={cell.styles
                  ? {...cell.styles, ...topStyles}
                  : {...topStyles}}
                ref={(elem) => {
                  if (cell.isRefForFirstTableHeaderRow) setFirstTableHeader(elem)
                }}
              >
                {cell.title}
              </TableCell>
            )
          })}
        </TableRow>
      )
    })
  }, [tablesConfig, currentTable, firstTableHeaderHeight])

  const onPressEnter = (e) => {
    if (e.code !== 'Enter') return

    e.stopPropagation()
    emulateTab()
  }

  return (
    <>
      <div className="tables__pagination-wrapper">
        <div
          className="tables__period-tabs"
          style={{
            width: selectedAddresses.length > 20 ? '' : '100%'
          }}
        >
          {periodTabs}
        </div>

        {selectedAddresses.length > 20 &&
          <div className="tables__pagination">
            <div className="form-group">
              <label>
                <FormattedMessage id={'app.onPage'}/>
              </label>

              <SelectComponent
                optionsList={TABLE_ROWS_PER_PAGE_LIST}
                value={rowsPerPageOption}
                handleChange={handleChangePerPage}
              />
            </div>

            <TablePagination
              component={'div'}
              count={selectedAddresses.length}
              rowsPerPage={rowsPerPage}
              getItemAriaLabel={(type) => {
                return intl.formatMessage(
                    {id: 'app.pageControl'},
                    {type: intl.formatMessage({id: paginationDirection[type]})}
                )
              }}
              onPageChange={handleChangePage}
              page={page}
              labelDisplayedRows={({from, to, count}) => {
                return intl.formatMessage({id: 'app.countOf'}, {
                  from,
                  to,
                  count: count !== -1
                    ? count
                    : intl.formatMessage(
                      {id: 'app.moreThen'},
                      {to}
                    )
                })
              }}
            />
          </div>
        }
      </div>

      {isTempTable &&
        (
          <div className="tables__temp">
            <div className="tables__marks">
              <div className={'tables__mark'}>
                <div className='tables__mark-color tables__mark-color_white'/>

                <span>
                  <FormattedMessage id={'analytics.temperatureBy'}/>
                </span>
              </div>

              <div className={'tables__mark'}>
                <div className='tables__mark-color tables__mark-color_yellow'/>

                <span>
                  <FormattedMessage id={'analytics.manualTemp'}/>
                </span>
              </div>
            </div>

            <button
              className="btn"
              onClick={toggleIsOpenSyncTemp}
            >
              <FormattedMessage id={'analytics.synchronize'}/>
            </button>
          </div>
        )
      }

      <Loader
        loading={isCreatingRows || isLoading}
        withoutSpinner={!isLoading}
        styles={{
          wrapper: (base) => ({
            ...base,
            width: '100%',
            maxWidth: '100%',
            overflow: 'auto',
            flex: 1,
            marginBottom: '1.25rem'
          })
        }}
      >
        <div
          className="tables__all-wrapper"
        >
          <Table
            stickyHeader
            onKeyPress={onPressEnter}
          >
            <TableHead>
              {tableHeadRows}
            </TableHead>

            <TableBody>
              {
                selectedAddresses
                  .slice(rowsPerPage * page, rowsPerPage * (page + 1))
                  .map(address => {
                    const addressModel = models[address.id]
                    const tableConfig = tablesConfig[currentTable - 1]

                    if (!addressModel || !tableConfig) return null

                    return (
                      <WithVisibilitySensor
                        key={`${address.id}-${tableConfig.id}`}
                        component={
                          cloneElement(dataRowComponent, {
                            address,
                            columns: tableConfig.columns,
                            modelInfo: addressModel,
                            columnWidth,
                            models,
                            editAnalyticId,
                            setRandomData,
                            setIsCreatingRows
                          })
                        }
                        placeholderComponent={
                          <TableRow
                            sx={{
                              height: '62px'
                            }}
                          />
                        }
                        setIsCreatingRows={setIsCreatingRows}
                        sensorsProps={{
                          containment: loaderWrapper
                        }}
                      />
                    )
                  })
              }
            </TableBody>
          </Table>
        </div>
      </Loader>

      {children}

      <DefaultModal
        isOpen={isOpenSyncTemp}
        toggleModal={toggleIsOpenSyncTemp}
        headerTitle={<FormattedMessage id={'analytics.tempSync'}/>}
        onDisagree={toggleIsOpenSyncTemp}
        onAgree={onSyncTemp}
        bodyText={<FormattedMessage id={'analytics.tempSyncWarning'}/>}
        agreeBtnConfig={{
          className: 'btn',
          text: <FormattedMessage id={'app.ok'}/>
        }}
      />
    </>
  )
}

export default TableGenerator
