import {useEffect, useState} from "react";
import {
  AVERAGE_LINES_COLOR,
  RESOURCES_COLORS,
  RESOURCES_TYPES
} from "@/internal/content/consts";
import {sampleParamsFormSelectors} from "@/internal/lib/storeModels/models/intake/forms/sampleParamsFormModel";
import ChartHeader from "@/presentation/components/intake/components/ChartHeader";
import Loader from "@/presentation/components/loader/Loader";
import {useIntl} from "react-intl";
import MainChart from "@/presentation/components/intake/components/MainChart";
import CompareChart from "@/presentation/components/intake/components/CompareChart";

const Chart = (
  {
    xAxis,
    chartType,
    isLoading,
    pointData,
    handleSelectColumn,
    changeOnlyDetailed,
    isLoadingMeterings
  }
) => {
  const intl = useIntl()
  const displayMode = sampleParamsFormSelectors.useFormDataValue('displayMode')
  const chartMode = sampleParamsFormSelectors.useFormDataValue('mode')
  const month = sampleParamsFormSelectors.useFormDataValue('month')
  const pointValue = sampleParamsFormSelectors.useFormDataValue('pointValue')
  const selectedPoints = sampleParamsFormSelectors.useFormDataValue('pointsIds')

  const [data, setData] = useState([])
  const [plotLines, setPlotLines] = useState([])
  const [averages, setAverages] = useState([])

  const eRender = (e) => {
    e.target.series.forEach(item => {
      item.data.forEach(point => {
        if (point.category == `${pointValue}`) {
          point.selected = true
        } else {
          point.selected = false
        }
      })
    })
  }

  useEffect(() => {
    const selectedPointsParams = pointData
      .filter(point => selectedPoints.includes(point.point_id))

    const pointsParams = selectedPointsParams
      .map(point => {
        const name = intl.formatMessage({id: RESOURCES_TYPES[point.type_resource].nameId})

        if (!!chartMode) {
          const {compare_date, compare_forecast} = point.compare
          const forecastData = compare_forecast.map(item => item < 0 ? 0 : item)

          return [
            {
              type: 'column',
              name: intl.formatMessage({id: 'intake.forecastName'}, {name: name.toLowerCase()}),
              data: forecastData,
              color: "#FFCBD4",
              borderRadius: 4,
              maxPointWidth: 6,
              plotOptions: {
                column: {
                  stacking: 'normal',
                  dataLabels: {
                    enabled: false
                  }
                }
              },
              stack: point.type_resource
            },
            {
              type: 'column',
              name,
              data: compare_date,
              color: RESOURCES_COLORS[point.type_resource].color,
              borderRadius: 4,
              maxPointWidth: 6,
              stack: point.type_resource
            },
          ]
        } else {
          return {
            name,
            data: point.array_main_data,
            color: RESOURCES_COLORS[point.type_resource].color,
            borderRadius: 4,
            maxPointWidth: 6,
            cursor: 'pointer',
            point: {
              events: {
                select: handleSelectColumn
              }
            },
          }
        }
      })

    if (!!chartMode) {
      const pointsAverageLines = selectedPointsParams
        .map(point => {
          return {
            color: AVERAGE_LINES_COLOR[point.type_resource].color,
            dashStyle: 'solid',
            value: point.compare.compare_average,
            width: 3
          }
        })

      const averagesList = selectedPointsParams
        .map(point => ({
          value: point.compare.compare_average,
          color: RESOURCES_COLORS[point.type_resource].color
        }))

      setPlotLines(pointsAverageLines)
      setAverages(averagesList)
    }

    if (!!chartMode) {
      setData(pointsParams.flat())
    } else {
      setData(pointsParams)
    }
  }, [pointData])

  return (
    <div className="intake__chart-wrapper">
      <Loader loading={!isLoadingMeterings && isLoading && !changeOnlyDetailed}>
        <ChartHeader />

        <div className="intake__chart">
          <div className='chart'>
            {!!chartMode
              ? (
                <CompareChart
                  xAxis={xAxis}
                  data={data}
                  averages={averages}
                  plotLines={plotLines}
                />
              )
              : (
                <MainChart
                  chartType={chartType}
                  eRender={eRender}
                  displayMode={displayMode}
                  data={data}
                  month={month}
                  xAxis={xAxis}
                />
              )
            }
          </div>
        </div>
      </Loader>
    </div>
  )
}

export default Chart
