export const loginValidator = (value) => {
  const re = /^([а-яё]+|[a-z\-\s]+)$/i

  return re.test(String(value).toLowerCase())
}

export const phoneValidator = (value) => {
  const re = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/

  return re.test(value)
}

export const emailValidator = (value) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(String(value).toLowerCase());
}

export const requiredValidator = (value) => {
  return !!value
}

export const passwordValidator = (value) => {
  const valueStr = String(value)

  const reNumber = /(?=.*\d)/
  const reSmallCase = /(?=.*[a-z])/
  const reUpperCase = /(?=.*[A-Z])/
  const reEightCharacters = /.{8,}$/
  const reSpecial = /(?=.*[!@#$%^&*()_])/
  const re = /^(?=.*\d)(?=.*[!@#$%^&*()_])(?=.*[a-z])(?=.*[A-Z]).{8,}$/

  return {
    hasNumber: reNumber.test(valueStr),
    hasSmallCase: reSmallCase.test(valueStr),
    hasUpperCase: reUpperCase.test(valueStr),
    hasEightCharacters: reEightCharacters.test(valueStr),
    hasSpecial: reSpecial.test(valueStr),
    isValid: re.test(valueStr)
  }
}

export const repeatPasswordValidator = (pass, newPass) => {
  return pass === newPass
}

export const INNValidator = (value) => {
  const length = String(value).length

  return length === 10 || length === 12
}

export const maxDateValidator = (value, limit = 0) => {
  const now = new Date()
  const valueDate = new Date(value)

  valueDate.setFullYear(+valueDate.getFullYear() + limit)

  return now.getTime() - valueDate.getTime() >= 0
}

export const minDateValidator = (value, limit = 5) => {
  const now = new Date()
  const valueDate = new Date(value)

  valueDate.setFullYear(+valueDate.getFullYear() + limit)

  return now.getTime() - valueDate.getTime() < 0
}

export const consistencyDatesValidator = (startDate, endDate) => {
  const start = new Date(startDate)
  const end = new Date(endDate)

  return end.getTime() - start.getTime() > 0
}

export const isEmptyObject = (value) => {
  return !!Object.keys(value).length
}

export const minLengthValidator = (value, min) => {
  return value.length >= min
}

export const maxLengthValidator = (value, max) => {
  return value.length <= max
}

export const maxWordsValidator = (value, max) => {
  return value.split(' ').length <= max
}

export const notMinusValue = (value) => {
  return +value >= 0 && !Number.isNaN(+value)
}

export const notZeroValidator = (value) => {
  return +value !== 0
}

export const notZeroFirst = (value) => {
  if (!value) return false
  return !(value.length > 1 && value[0] === '0')
}

export const geoLonValidator = (value) => {
  return +value <= 180 && +value >= -180 && !Number.isNaN(+value)
}

export const geoLatValidator = (value) => {
  return +value <= 90 && +value >= -90 && !Number.isNaN(+value)
}

export const deviceSerialNumberValidator = (value, substring) => {
  return value.substring(0, 2) === substring
}

export const minMaxValueValidator = (value, minValue = 0, maxValue = 100) => {
  return value >= minValue && value <= maxValue
}
