import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {ANALYTICS_INIT_VALIDATIONS, INITIAL_NEW_ADDRESS_FORM_DATA} from "@/internal/lib/storeModels/models/address/const";
import {getAddressFormValidators} from "@/internal/validators/formValidators";

const formModel = new FormModelFabric({
  initialData: INITIAL_NEW_ADDRESS_FORM_DATA,
  initialValidations: ANALYTICS_INIT_VALIDATIONS,
  getValidators: getAddressFormValidators
})

export const mainPageNewAddressFormSelectors = formModel.createSelectors()
