import React, {Component} from 'react';
import * as Sentry from "@sentry/react";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import {appSelectors} from "@/internal/lib/storeModels/models/app/appModels";
import {messages} from "@/i18n/messages";

export default class ErrorBoundry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error: true})

    const userData = userDataSelectors.userData.getUserData()
    const locale = appSelectors.locale.getLocale()

    Sentry.showReportDialog({
      eventId: Sentry.lastEventId(),
      user: {
        email: userData.email,
        name: userData.login,
      },
      title: messages[locale]['backCall.title'],
      subtitle: messages[locale]['backCall.subtitle'],
      subtitle2: messages[locale]['backCall.subtitle2'],
      labelName: messages[locale]['backCall.labelName'],
      labelEmail: messages[locale]['backCall.labelEmail'],
      labelComments: messages[locale]['backCall.labelComments'],
      labelClose: messages[locale]['backCall.labelClose'],
      labelSubmit: messages[locale]['backCall.labelSubmit'],
      errorGeneric: messages[locale]['backCall.errorGeneric'],
      errorFormEntry: messages[locale]['backCall.errorFormEntry'],
      successMessage: messages[locale]['backCall.successMessage'],
      onLoad: () => {
        const nameInput = document.getElementById("id_name")
        const emailInput = document.getElementById('id_email')
        const commentArea = document.getElementById("id_comments")

        nameInput && nameInput.setAttribute("placeholder", messages[locale]['backCall.namePlaceholder'])
        commentArea && commentArea.setAttribute("placeholder","")
        emailInput && emailInput.setAttribute('placeholder', messages[locale]['backCall.emailPlaceHolder'])
      }
    })
  }

  render() {
    if (this.state.error){
      return(
        <div className="error">
          <h1>
              Error
          </h1>
        </div>
      )
    }

    return this.props.children
  }
}