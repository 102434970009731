import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {INIT_CHANGE_ENTITY_DATA, INIT_CHANGE_ENTITY_VALIDATIONS} from "../const";
import {getChangeEntityValidator} from "@/internal/validators/formValidators";
import {createEffect, createEvent, createStore, sample} from "effector";
import {useEvent, useStore} from "effector-react";

const userEntityFormModel = new FormModelFabric({
  initialData: INIT_CHANGE_ENTITY_DATA,
  initialValidations: INIT_CHANGE_ENTITY_VALIDATIONS,
  getValidators: getChangeEntityValidator
})

userEntityFormModel.toggleIsActualIsLegal = createEvent()
userEntityFormModel.$isActualIsLegal = createStore(false)
userEntityFormModel.setSameAddresses = createEffect(({address, actualAddress, isActualIsLegal}) => {
  if (!isActualIsLegal || actualAddress === address) return

  userEntityFormModel.valueStateManager['actual_address'].setValue(address)
})

userEntityFormModel.$isActualIsLegal
  .on(userEntityFormModel.toggleIsActualIsLegal, (state) => !state)
  .reset(userEntityFormModel.resetFormData)

sample({
  clock: [userEntityFormModel.toggleIsActualIsLegal, userEntityFormModel.valueStateManager['address'].setValue],
  source: {
    address: userEntityFormModel.valueStateManager['address'].$store,
    actualAddress: userEntityFormModel.valueStateManager['actual_address'].$store,
    isActualIsLegal: userEntityFormModel.$isActualIsLegal
  },
  target: userEntityFormModel.setSameAddresses
})

export const userEntityFormSelectors = {
  ...userEntityFormModel.createSelectors(),
  useIsActualIsLegal: () => useStore(userEntityFormModel.$isActualIsLegal),
  useToggleIsActualIsLegal: () => useEvent(userEntityFormModel.toggleIsActualIsLegal)
}
