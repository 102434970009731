import Select, {components} from 'react-select'
import {Dropdown, Pen} from '@/assets/svg'
import {useRef} from "react";
import {customStyles, customStylesError} from "../const";
import {Link} from 'react-router-dom'
import {Edit} from '@mui/icons-material'

const SelectComponent = (
  {
    handleChange,
    customClass = '',
    optionsList = [],
    value = null,
    styles = {},
    name,
    loading = false,
    isMainSelect = false,
    disabled = false,
    withAddObject = false,
    handleClickAddOption,
    isSearchable = false,
    noOptionsMessage = () => 'Нет данных',
    isMulti = false,
    isError = false,
    placeholder = '',
    isAddresses = false,
    onEditMulti
  }
) => {
  const select = useRef()

  const Menu = (props) => {
    return (
      <components.Menu {...props}>
        {
          withAddObject
            ? <div
                className='select__add-option'
              >
                <div
                  onClick={onClickAddOption}
                >
                  + Добавить новый
                </div>
            </div>
            : null
        }

        {props.children}

        {
          isMainSelect
            ? <div className='select__add-address'>
                <Link
                  to='/user_page/accounting_objects/new_object'
                  onClick={onClickAddOption}
                >
                  Добавить
                </Link>
              </div>
            : null
        }
      </components.Menu>
    )
  }

  const SingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        {
          isMainSelect || isAddresses
            ? <>
              {props.children.props
                ? props.children.props.children[0]
                : props.children}
            </>
            : <>{props.children}</>
        }
      </components.SingleValue>
    )
  }

  const handleMultiValueClick = (e, props) => {
    e.stopPropagation()
    e.preventDefault()

    const {target} = e

    if (target && target.closest('.select-edit-icon')) {
      const currentValue = select.current.getValue()

      select.current.setValue(currentValue)
      onEditMulti(e, props.data.value)
    }
  }

  const handleMouseDown = (e) => {
    const {target} = e

    if (target && target.closest('.select-edit-icon')) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  const MultiValue = (props) => {
    return (
      <div onMouseDown={handleMouseDown} onClick={(e) => handleMultiValueClick(e, props)}>
        <components.MultiValue {...props}>
          {props.children.props
            ? <>
                {props.children.props.children[0].props.children[0]}
              <div className="select-edit-icon">
                <Edit className={'select-edit-icon__icon'}/>
              </div>

              </>
            : <>
              {props.children}
              <div className="select-edit-icon">
                <Edit className={'select-edit-icon__icon'}/>
              </div>
            </>
          }
        </components.MultiValue>
      </div>
    )
  }

  const onClickAddOption = () => {
    const currentValue = select.current.getValue()
    select.current.setValue(currentValue)

    if (!handleClickAddOption) return

    handleClickAddOption()
  }

  return (
    <Select
      ref={select}
      value={value}
      onChange={handleChange}
      options={optionsList}
      className={`select-component ${customClass}`}
      components={{ DropdownIndicator, Menu, SingleValue, MultiValue }}
      styles={isError ? {...customStylesError, ...styles} : {...customStyles, ...styles}}
      autoFocus={false}
      maxMenuHeight={180}
      menuPosition={'absolute'}
      menuPlacement={'auto'}
      name={name}
      isLoading={loading}
      isDisabled={disabled}
      isClearable={false}
      isSearchable={isSearchable}
      noOptionsMessage={noOptionsMessage}
      isMulti={isMulti}
      placeholder={placeholder}
      escapeClearsValue={false}
      backspaceRemovesValue={false}
      closeMenuOnSelect={!isMulti}
    />
  )
}


const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={Dropdown} alt="Dropdown"/>
    </components.DropdownIndicator>
  )
}

export default SelectComponent
