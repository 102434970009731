import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {
  CORPORATE_REGISTRATION_INIT_DATA, CORPORATE_REGISTRATION_INIT_VALIDATIONS,
  USER_REGISTRATION_INIT_DATA,
  USER_REGISTRATION_INIT_VALIDATIONS
} from "@/internal/lib/storeModels/models/authorization/const";
import {getUserCorporateValidator, getUserIndividualValidator} from "@/internal/validators/formValidators";
import {createEffect, createEvent, createStore, sample} from "effector";
import {useEvent, useStore} from "effector-react";

const individualUserFormModel = new FormModelFabric({
  initialData: USER_REGISTRATION_INIT_DATA,
  initialValidations: USER_REGISTRATION_INIT_VALIDATIONS,
  getValidators: getUserIndividualValidator
})

const corporateUserFormModel = new FormModelFabric({
  initialData: CORPORATE_REGISTRATION_INIT_DATA,
  initialValidations: CORPORATE_REGISTRATION_INIT_VALIDATIONS,
  getValidators: getUserCorporateValidator
})

corporateUserFormModel.toggleIsActualIsLegal = createEvent()
corporateUserFormModel.$isActualIsLegal = createStore(false)
corporateUserFormModel.setSameAddresses = createEffect(({organizationAddress, organizationActualAddress, isActualIsLegal}) => {
  if (!isActualIsLegal || organizationActualAddress === organizationAddress) return

  corporateUserFormModel.valueStateManager['organization_actual_address'].setValue(organizationAddress)
})

corporateUserFormModel.$isActualIsLegal
  .on(corporateUserFormModel.toggleIsActualIsLegal, (state) => !state)
  .reset(corporateUserFormModel.resetFormData)

sample({
  clock: [corporateUserFormModel.toggleIsActualIsLegal, corporateUserFormModel.valueStateManager['organization_address'].setValue],
  source: {
    organizationAddress: corporateUserFormModel.valueStateManager['organization_address'].$store,
    organizationActualAddress: corporateUserFormModel.valueStateManager['organization_actual_address'].$store,
    isActualIsLegal: corporateUserFormModel.$isActualIsLegal
  },
  target: corporateUserFormModel.setSameAddresses
})

export const individualUserFormSelectors = individualUserFormModel.createSelectors()
export const corporateUserFormSelectors = {
  ...corporateUserFormModel.createSelectors(),
  useIsActualIsLegal: () => useStore(corporateUserFormModel.$isActualIsLegal),
  useToggleIsActualIsLegal: () => useEvent(corporateUserFormModel.toggleIsActualIsLegal)
}