import {API_URL} from "@/config";
import {analyticsSelectors} from "@/internal/lib/storeModels/models/analytics/analyticsModels";
import {analyticsEndpoint} from "@/data/api_entities/analytics/analytics_endpoint";
import {useEffect, useRef, useState} from "react";
import {selectors} from "@/presentation/shared/ui/message";
import {FormattedMessage} from "react-intl";

const GetXLSButton = ({getRequest}) => {
  const link = useRef()

  const [loading, setLoading] = useState(false)
  const [saveResultUrl, setSaveResultUrl] = useState('')
  const setMessage = selectors.useSetMessage()


  useEffect(() => {
    if (!saveResultUrl) return
    link.current.click()

    const filePathParts = saveResultUrl.split('/')

    setMessage({
      header: '',
      message: (
        <FormattedMessage
          id={'requests.downloadReport'}
          values={{
            name: filePathParts[filePathParts.length - 1]
          }}
        />
      ),
      type: 'info',
      count: 3000
    })

    setTimeout(() => setSaveResultUrl(''), 1000)
  }, [saveResultUrl])

  const onGetResultXLS = () => {
    if (loading) return
    setLoading(true)
    const {promise} = getRequest()
    promise
      .then(res => {
        if ('url' in res) {
          setSaveResultUrl(res.url)
        } else {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: <FormattedMessage id={'requests.downloadReportError'}/>,
            type: 'error',
            count: 3000
          })
          console.log(res)
        }
      })
      .catch(err => {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.downloadReportError'}/>,
          type: 'error',
          count: 3000
        })

        console.log(err)
      })
      .finally(() => setLoading(false))
  }

  return (
    <button
      className='btn'
      onClick={onGetResultXLS}
      disabled={loading}
    >
      <FormattedMessage id={'analytics.downloadXLS'}/>
      <a
        target={'_blank'}
        onClick={(e) => {
          e.stopPropagation()
        }}
        ref={link}
        style={{display: 'none'}}
        href={`${API_URL}${saveResultUrl}`}
        download
      />
    </button>
  )
}

export default GetXLSButton
