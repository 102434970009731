import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'meteringPointForm'
const translations = {
  newPoint: 'Neuer abrechnungspunkt',
  pointParams: 'Abrechnungspunktparameter',
  dontForget: 'Um es nicht zu vergessen, nennen wir den installationsort - Küche, Bad, Tambour ...',
  name: 'Name',
  resourceType: 'Ressourcentyp',
  systemDevice: 'Systemgerät',
  device: 'Gerät',
  channel: 'Kanal',
  primaryDevice: 'PRIMÄRGERÄT (ZÄHGERÄT)',
  deviceName: 'Benennung',
  serial: 'Seriennummer',
  date: 'Datum der zukünftigen Überprüfung',
  siNumber: 'Registriernummer',
  unitType: 'Maßeinheit',
  value: 'Ablesung',
  onDate: 'Am datum',
  time: 'Zeitspanne',
  fillWhenConnecting: 'Beim anschließen des messgeräts ausfüllen oder auf korrekte messwerte aktualisieren',
  impulseSettings: 'Pulseinstellung',
  impulses: 'Impulse',
  resource: 'Ressource',
  'deletePoint?': 'Konto löschen?',
  pulseChannelOne: 'Impulskanal 1',
  pulseChannelTwo: 'Impulskanal 2',
}

export const meteringPointFormDe = createTranslationsWithSpaceName(translations, spaceName)