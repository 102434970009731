import {FormattedMessage, FormattedNumber} from "react-intl";

export const WITHOUT_FLAT_TYPES = [2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13]
export const WITHOUT_RESIDENTS = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]

export const SELECT_STYLES = {
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
    width: '100%',
    background: '#fff',
    boxShadow: '0 .5rem 1rem rgba(53, 96, 181, 0.2)',
    borderRadius: '.375rem',
    overflowX: 'hidden',
    margin: '0'
  }),
  option: () => ({
    display: 'flex',
    flexDirection: 'column',
    fontSize: '.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    padding: '.75rem 1rem',
    borderTop: '1px solid #ECF0F6',
    cursor: 'pointer',
    ':hover': {
      background: '#E6EFFE'
    },
    ':last-child': {
      borderBottom: 'none'
    },
    'span': {
      ':nth-of-type(1)': {
        fontSize: '.875rem',
        lineHeight: '143%',
        fontWeight: '500',
        color: '#36455D',
      },
      ':last-child': {
        fontSize: '.875rem',
        lineHeight: '143%',
        color: '#727A86',
        marginTop: '-0.25rem'
      }
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '0.875rem',
    lineHeight: '142.86%',
    color: '#36455D',
    margin: 0,
    padding: '0.5rem 0 0.5rem  .5625rem',
  }),
}

export const CURRENCY_TYPES = [
  {
    value: 1,
    label: '₽'
  },
  /*{
    value: 2,
    label: '$'
  },
  {
    value: 3,
    label: '€'
  },
  {
    value: 4,
    label: '£'
  },*/
]

export const ANALYTIC_UNIT_TYPES = [
  {
    value: 1,
    label: <FormattedMessage id={'app.gcal'}/>
  },
  {
    value: 2,
    label: <FormattedMessage id={'app.gj'}/>
  },
  {
    value: 3,
    label: <FormattedMessage id={'app.kwh'}/>
  },
  {
    value: 4,
    label: <FormattedMessage
      id={'app.mCubeGas'}
      values={{
        sup: chunks => (<sup>{chunks}</sup>)
      }}
    />
  },

]

export const ANALYTIC_UNIT_TYPES_ANALYTICS_PRECONFIG = [
  {
    value: 1,
    label: <FormattedMessage id={'app.gcal'}/>
  },
  {
    value: 2,
    label: <FormattedMessage id={'app.gj'}/>
  },
  {
    value: 3,
    label: <FormattedMessage id={'app.kwh'}/>
  },
  {
    value: 4,
    label: <FormattedMessage
      id={'app.mCubeGas'}
      values={{
        sup: chunks => (<sup>{chunks}</sup>)
      }}
    />
  },
]
