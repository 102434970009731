import {intakeSelectors} from "@/internal/lib/storeModels/models/intake/intakeModels";
import {RESOURCES_TYPES} from "@/internal/content/consts";
import {FormattedMessage} from "react-intl";

const ChartHeader = () => {
  const activeTypes = intakeSelectors.activeTypes.useValue()

  return (
    <div className={'intake__chart-header'}>
      <h3 className='title_h3'>
        {activeTypes.map((type, i) => {
          return (
            <span key={type}>
              {RESOURCES_TYPES[type].name}, <FormattedMessage id={'intake.m'}/><sup>3</sup> {i === activeTypes.length - 1 ? '' : '/ '}
            </span>
          )
        })}
      </h3>

      {/*<button className="btn">Скачать XLS</button>*/}
    </div>
  )
}

export default ChartHeader
