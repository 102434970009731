import ArrowBack from "@/presentation/components/common/ArrowBack";
import {DEVICE_SIM_SETTINGS_PATH} from "./const";
import {useEffect, useState} from "react";
import {phoneFormatter} from "@/internal/formatters/formatters";
import Loader from "@/presentation/components/loader/Loader";
import {devicesEndpoint} from "@/data/api_entities/devices/devices_endpoint";
import {setMessage, typeMessage} from "@/presentation/shared/ui/message";
import MaskInput from "@/presentation/components/common/formComponents/MaskInput";
import {deviceSimSettingsFromSelectors} from "@/internal/lib/storeModels/models/devices/forms/deviceFormModels";
import {devicePageSelectors} from "@/internal/lib/storeModels/models/devices/devicePageModels";
import {FormattedMessage} from "react-intl";
import {responseHandler} from "@/internal/lib/transport/response_handler";

const DeviceSimSettings = ({deviceInfo, getDeviceInfo}) => {
  const resetForm = deviceSimSettingsFromSelectors.useResetForm()
  const isValidForm = deviceSimSettingsFromSelectors.useIsFormValid()
  const setOnValidSubmit = deviceSimSettingsFromSelectors.useSetOnValidSubmit()
  const onClickSubmit = deviceSimSettingsFromSelectors.useOnClickSubmit()
  const [isLoading, setIsLoading] = devicePageSelectors.isChangeSim.useState()

  const [isOpenForm, setIsOpenForm] = useState(false)

  useEffect(() => {
    resetForm()
    setOnValidSubmit(onValidSubmit)

    return () => {
      resetForm()
    }
  }, [isOpenForm])

  const onValidSubmit = (data) => {
    setIsLoading(true)

    const {promise} = devicesEndpoint.changeDeviceSettings({
      ...data,
      id: deviceInfo.id
    })

    promise
      .then(res => {
        responseHandler(res, {
          ok: {
            callback: () => {
              getDeviceInfo()
              setIsOpenForm(false)
            },
            header: <FormattedMessage id={'requests.changeSimSuccessHeader'}/>,
            message: <FormattedMessage id={'requests.changeSimSuccess'}/>,
          },
          error: {
            message: <FormattedMessage id={'requests.changeSimError'}/>,
          }
        })
      })
      .catch(err => {
        console.log(err)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: <FormattedMessage id={'requests.changeSimError'}/>,
          count: 3000,
          type: typeMessage.error
        })
      })
      .finally(() => setIsLoading(false))
  }

  const handleSubmit = () => {
    if (!isValidForm) return

    onClickSubmit()
  }

  if (!deviceInfo) return null

  return (
    <div className={'device-sim-settings'}>
      <ArrowBack
        pathOnVisible={DEVICE_SIM_SETTINGS_PATH}
      />

      <h2 className="title">
        <FormattedMessage id={'devicesPage.simSettings'}/>
      </h2>

      {isOpenForm
        ? (
          <Loader loading={isLoading}>
            <div className="form-fields">
              <MaskInput
                label={<FormattedMessage id={'devicesPage.simNumber'}/>}
                type="tel"
                name="sim"
                mask="+7 (999) 999-99-99"
                placeholder={'+7 845 6651-11-56'}
                formSelectors={deviceSimSettingsFromSelectors}
                valueFormatter={phoneFormatter}
              />
            </div>

            <div className="device-sim-settings__btns">
              <button
                className="btn btn_transparent-bg"
                onClick={handleSubmit}
              >
                <FormattedMessage id={'app.save'}/>
              </button>

              <button
                className="btn btn_transparent-bg"
                onClick={() => setIsOpenForm(false)}
              >
                <FormattedMessage id={'app.cancel'}/>
              </button>
            </div>
          </Loader>
        )
        : (
          <>
            <p>
              <FormattedMessage id={'devicesPage.servicedByWatt'}/>
            </p>

            <div className="device-sim-settings__info">
              <span>
                <FormattedMessage id={'devicesPage.simNumber'}/>
              </span>
              <p>{deviceInfo.sim || <FormattedMessage id={'devicesPage.notConfigured'}/>}</p>
            </div>

            <button
              className="btn btn_transparent-bg"
              onClick={() => setIsOpenForm(true)}
            >
              <FormattedMessage id={'devicesPage.changeSim'}/>
            </button>
          </>
        )
      }
    </div>
  )
}

export default DeviceSimSettings
