import FormModelFabric from "@/internal/lib/storeModels/fabrics/formModelFabric";
import {
  CHANGE_PASSWORD_INIT_DATA, CHANGE_PASSWORD_INIT_VALIDATIONS,
  RECOVERY_PASSWORD_BY_EMAIL_INIT_DATA,
  RECOVERY_PASSWORD_BY_EMAIL_INIT_VALIDATIONS
} from "../const";
import {getChangePassValidator, getRecoveryPasswordValidator} from "@/internal/validators/formValidators"

const recoveryPasswordModel = new FormModelFabric({
  initialData: RECOVERY_PASSWORD_BY_EMAIL_INIT_DATA,
  initialValidations: RECOVERY_PASSWORD_BY_EMAIL_INIT_VALIDATIONS,
  getValidators: getRecoveryPasswordValidator
})

const changePasswordModel = new FormModelFabric({
  initialData: CHANGE_PASSWORD_INIT_DATA,
  initialValidations: CHANGE_PASSWORD_INIT_VALIDATIONS,
  getValidators: getChangePassValidator
})

export const recoveryPasswordSelectors = recoveryPasswordModel.createSelectors()
export const changePasswordSelectors = changePasswordModel.createSelectors()
