import AuthorizationLayout from "./AuthorizationLayout";
import {Link, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {authorizationEndpoint} from "@/data/api_entities/authorization/authorization_endpoint";
import cookiesManager from "@/internal/lib/services/cookiManager";
import {appSelectors} from "@/internal/lib/storeModels/models/app/appModels";
import Loader from "@/presentation/components/loader/Loader";
import {signInFormSelectors} from "@/internal/lib/storeModels/models/authorization/forms/signInModels";
import Input from "@/presentation/components/common/formComponents/Input";
import {authorizationSelectors} from "@/internal/lib/storeModels/models/authorization/authorizationModels";
import {FormattedMessage, useIntl} from "react-intl";

const SignIn = () => {
  const intl = useIntl()
  const isValidForm = signInFormSelectors.useIsFormValid()
  const resetForm = signInFormSelectors.useResetForm()
  const setOnValidSubmit = signInFormSelectors.useSetOnValidSubmit()
  const onClickSubmit = signInFormSelectors.useOnClickSubmit()
  const [incorrectSignInMsg, setIncorrectSignInMsg, resetIncorrectSignInMsg] = authorizationSelectors.incorrectMsg.useState()
  const isAppError = appSelectors.isAppError.useValue()
  const resetRecoveryEmail = authorizationSelectors.recoveryEmail.useReset()
  const [loadingSignIn, setLoadingSignIn] = authorizationSelectors.loadingSignIn.useState()
  const resetRegistrationData = authorizationSelectors.registrationData.useReset()

  const navigate = useNavigate()

  useEffect(() => {
    setOnValidSubmit(onValidSubmit)

    return () => {
      resetForm()
    }
  }, [])

  const handleSubmit = () => {
    if (!isValidForm) return

    onClickSubmit()
  }

  const onValidSubmit = (data) => {
    setLoadingSignIn(true)

    authorizationEndpoint.signIn(data)
      .then(res => {
        if (res.non_field_errors) {
          setIncorrectSignInMsg(res.non_field_errors[0])
        } else {
          cookiesManager.set('X-pulseId', res.user.id)
          cookiesManager.set('X-pulseAvatar', res.user.avatar)
          cookiesManager.set('X-pulseEmail', res.user.email)
          cookiesManager.set('X-pulseFirstName', res.user.first_name)
          cookiesManager.set('X-pulseLastName', res.user.last_name)
          cookiesManager.set('X-pulse', res.access_token)

          navigate('/service')
          resetRegistrationData()
          resetRecoveryEmail()
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setLoadingSignIn(false))
  }

  return (
    <AuthorizationLayout
      title={<FormattedMessage id={'authorization.enter'}/>}
      customClass="sign-in"
      isSignIn
    >
      {isAppError &&
        <h3 className={'authorization-layout__app-error'}>
          <FormattedMessage id={'authorization.apologies'}/>
        </h3>
      }

      <Loader
        loading={loadingSignIn}
        styles={{
          wrapper: (base) => ({
            ...base,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          })
        }}
      >
        <div className="form-fields">
          <Input
            label={<FormattedMessage id={'authorization.e-mail'}/>}
            name={'email'}
            type={'email'}
            placeholder={intl.formatMessage({id: 'authorization.mailPlaceHolder'})}
            formSelectors={signInFormSelectors}
            withChangeValue={resetIncorrectSignInMsg}
          />

          <Input
            label={<FormattedMessage id={'authorization.password'}/>}
            name={'password'}
            type={'password'}
            formSelectors={signInFormSelectors}
            withChangeValue={resetIncorrectSignInMsg}
          />
        </div>

        {!!incorrectSignInMsg &&
          <span className={'sign-in__incorrect-msg'}>{incorrectSignInMsg}</span>
        }

        <button
          className="btn"
          disabled={!isValidForm}
          onClick={handleSubmit}
        >
          <FormattedMessage id={'authorization.signIn'}/>
        </button>

        <Link to="/password_recovery" className="authorization-layout__under-btn-link">
          <FormattedMessage id={'authorization.forgetPassword?'}/>
        </Link>
      </Loader>
    </AuthorizationLayout>
  )
}

export default SignIn
