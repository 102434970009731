export const NEW_METERING_POINT_INIT_DATA = Object.freeze({
  name: '',
  type: 0,
  device: 0,
  channel: 1,
  si_number: '',
  device_name: '',
  serial: '',
  next_verification: '',
  unit_type: 0,
  date: '',
  time: '',
  value: '',
  pulse: '',
  value_impulse: '',
  unit_type_impulse: 0
})

export const INIT_VALIDATIONS = Object.freeze({
  name: [],
  device: [],
  type: [],
  si_number: [],
  device_name: [],
  serial: [],
  date: [],
  time: [],
  pulse: [],
  value: [],
  value_impulse: [],
  next_verification: []
})

export const INIT_DATA = Object.freeze({
  point_id: 0,
  address: 0,
  name: '',
  type: 0,
  device: 0,
  channel: 1,
  si_number: '',
  device_name: '',
  serial: '',
  next_verification: '',
  unit_type: 0,
  date: '',
  time: '',
  value: '',
  pulse: '',
  value_impulse: '',
  unit_type_impulse: 0
})
