import * as React from 'react';
import {Stack, Snackbar} from '@mui/material';
import useStyles from "./Alert.style";

export function CustomizedSnackbars({pr}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return
        setOpen(false);
    };

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
                <div className={+pr.success ? classes.alertGreen : classes.alertRed}>
                    <h6>{pr.header}</h6>
                    <p>{pr.paragraph}</p>
                </div>
            </Snackbar>
        </Stack>
    );
}