import {FormattedMessage} from "react-intl";

export const getScoreboardRows = (data) => {
  if (!data) return []

  return [
    {
      name: <FormattedMessage id={'devicesPage.serial'}/>,
      value: data.serial
    },
    {
      name: <FormattedMessage id={'devicesPage.version'}/>,
      value: data.version
    },
    {
      name: <FormattedMessage id={'devicesPage.voltage'}/>,
      value: data.voltage,
    },
    {
      name: <FormattedMessage id={'devicesPage.statusCode'}/>,
      value: data.status_code,
    },
    {
      name: <FormattedMessage id={'devicesPage.reportPeriodNum'}/>,
      value: data.report_period_num,
    },
    {
      name: <FormattedMessage id={'devicesPage.channelOne'}/>,
      value: data.channels[1].V_id.join(''),
    },
    {
      name: <FormattedMessage id={'devicesPage.channelOneVolume'}/>,
      value: data.channels[1].V,
    },
    {
      name: <FormattedMessage id={'devicesPage.channelOneVolumeDate'}/>,
      value: data.channels[1].V_previous,
    },
    {
      name: <FormattedMessage id={'devicesPage.channelNum'}/>,
      value: data.channels[1].ch_number,
    },
    {
      name: <FormattedMessage id={'devicesPage.pulseWeight'}/>,
      value: data.channels[1].pulse_weight,
    },
    {
      name: <FormattedMessage id={'devicesPage.pulseOneCount'}/>,
      value: data.channels[1].pulse_count,
    },
    {
      name: <FormattedMessage id={'devicesPage.channelTwo'}/>,
      value: data.channels[2].V_id.join(''),
    },
    {
      name: <FormattedMessage id={'devicesPage.channelTwoVolume'}/>,
      value: data.channels[2].V,
    },
    {
      name: <FormattedMessage id={'devicesPage.channelTwoVolumeDate'}/>,
      value: data.channels[2].V_previous,
    },
    {
      name: <FormattedMessage id={'devicesPage.channelNum'}/>,
      value: data.channels[2].ch_number,
    },
    {
      name: <FormattedMessage id={'devicesPage.pulseWeight'}/>,
      value: data.channels[2].pulse_weight,
    },
    {
      name: <FormattedMessage id={'devicesPage.pulseTwoCount'}/>,
      value: data.channels[2].pulse_count,
    },
  ]
}

export const createData = (id, name, calories, fat, carbs, protein) => {
  return { id, name, calories, fat, carbs, protein }
}

export const  stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])

    if (order !== 0) return order

    return a[1] - b[1]
  })

  return stabilizedThis.map((el) => el[0])
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }

  if (b[orderBy] > a[orderBy]) {
    return 1

  }

  return 0
}


export const  getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
