import {request} from "@/internal/lib/transport/request";

export const servicemenEndpoints = {
  getServicemensByRegion(region) {
    const params = {
      url: 'api/user_manager/service_user/',
      method: 'POST',
      data: {
        region
      }
    }

    return request(params)
  },

  getServicemenInfo() {
    const params = {
      url: 'api/service_manager/',
      method: 'GET'
    }
    
    return request(params)
  },

  getUserManagerInfo(id) {
    const params = {
      url: `api/service_manager/${id}/`,
      method: 'GET'
    }

    return request(params)
  },

  acceptOffer(id) {
    const params = {
      url:  `api/service_manager/${id}/offer/`,
      method: 'PUT',
      data: {
        offer: true
      }
    }

    return request(params)
  }
}
