import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'devicesPage'
const translations = {
  equipment: 'Equipment',
  noDevices: 'You can add new equipment by clicking +',
  willConnect: 'Get in touch - {date}',
  notConfigured: 'Not configured',
  activityLog: 'Activity log',
  scoreboard: 'Scoreboard',
  net: 'Net',
  state: 'State',
  lastUpdate: 'Last update',
  title: 'Name',
  value: 'Meaning',
  notBeenConnectedYet: 'This device has not yet been connected.',
  serial: 'Fac №',
  version: 'Version',
  voltage: 'Upow, V',
  statusCode: 'Status code',
  reportPeriodNum: 'Reporting period number',
  channelOne: 'Channel ID V1',
  channelOneVolume: 'V1, m3',
  channelOneVolumeDate: 'V1, m3 as of the previous reporting date',
  channelNum: '№ channel',
  pulseWeight: 'Imp. weight,l',
  pulseOneCount: 'Number of pulses N1',
  channelTwo: 'Channel ID V2',
  channelTwoVolume: 'V2, m3',
  channelTwoVolumeDate: 'V2, m3 as of the previous reporting date',
  pulseTwoCount: 'Number of pulses N2',
  localTime: 'The local time',
  periodFrom: 'Period from',
  to: 'To',
  entries: '{count} entries',
  operation: 'Operation',
  message: 'Message',
  source: 'Source',
  executionStatus: 'Execution status',
  generalSettings: 'General settings',
  devices: 'Devices',
  pulseInputOne: 'Pulse input 1',
  pulseInputTwo: 'Pulse input 2',
  tempSensor: 'Temperature sensor',
  simSettings: 'SIM card settings',
  systemConnecting: 'Connecting to the system',
  noInfo: 'No information',
  lastSession: 'Last session',
  nextSession: 'Next session',
  contactFrequency: 'Contact frequency',
  devicePolling: 'Polling device in progress, please wait..',
  stopPolling: 'Stop survey',
  pollNow: 'Poll now',
  meteringPoint: 'Accounting point',
  storedInSystem: 'Readings are stored in the System at the selected metering point',
  setUpMeteringPoint: 'Set up an accounting point to save readings in the System',
  accountingObject: 'Accounting object',
  location: 'Installation location',
  warrantyUpTo: 'Warranty up to',
  serialNumber: 'Serial number',
  'removeDevice?': 'Remove device?',
  simNumber: 'SIM card number',
  servicedByWatt: 'SIM card serviced by Watt',
  changeSim: 'Change SIM',
  deviceUnbind: 'Device unbind',
  deviceBind: 'Device binding',
  successfullyUnpaired: 'Device successfully unpaired',
  successfullyPaired: 'Device successfully paired',
  failedUnpaired: 'Unable to unpair device',
  failedPaired: 'Failed to pair device',
  untie: 'Untie',
  bind: 'Bind',
  deviceSearch: 'Device search',
  tied: 'Tied',
  noTied: 'No connected devices',
  inVisibility: 'IN VISIBILITY',
  noDevicesAvailable: 'No devices available',
  time: 'Time',
  reading: 'Reading',
  toBindDevice: 'Link a device',
  availableDevices: 'Available devices',
  untieAndChange: 'Untie and change',
  editDeviceSettings: 'Editing device parameters',
  untieAll: 'Untie the equipment',
  untieAllTitle: 'Confirmation of equipment decoupling',
  untieAllBody: 'This action will untie the equipment from the linked devices',
  removeDevice: 'Deleting a device',
  alertEdit: 'Before linking to another accounting object, untie the equipment from other devices.',
  alertDelete: 'Before deleting untie the linked devices from the equipment',
  createAndTie: 'Create and link devices'
}

export const devicesPageEn = createTranslationsWithSpaceName(translations, spaceName)
