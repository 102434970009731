import {FormattedMessage} from "react-intl";

const BestValues = ({resultData}) => {
  return (
    <div className={'best-values'}>
      <span>
        <FormattedMessage
          id={'analytics.bestIntakeAverages'}
          values={{
            sup: chunks => (<sup>{chunks}</sup>)
          }}
        />
      </span>

      <div className="best-values__values">
        <div>
          <FormattedMessage id={'analytics.byObjects'}/>
          <br/>
          {resultData?.middle_objects
            ? resultData.middle_objects.toFixed(7)
            : '-'}
        </div>

        <div>
          <FormattedMessage id={'analytics.bySystem'}/>
          <br/>
          {resultData?.best_system
            ? resultData.best_system.toFixed(7)
            : '-'}
        </div>

        <div>
          <FormattedMessage id={'analytics.byRegion'}/>
          <br/>
          {resultData?.middle_region
            ? resultData.middle_region.toFixed(7)
            : '-'}
        </div>
      </div>
    </div>
  )
}

export default BestValues
