import {createTranslationsWithSpaceName} from "@/internal/helpers/helpers";

const spaceName = 'resourcesTypes'
const translations = {
  0: 'Не задан',
  1: 'Холодная вода',
  2: 'Горячая вода',
  3: 'Тепло',
  4: 'Электроэнергия',
  5: 'Газ',
  6: 'Вентиляция'
}

export const resourcesTypesRu = createTranslationsWithSpaceName(translations, spaceName)
