import {Close} from "@/assets/svg";
import CalculationSteps from "@/presentation/components/heatCalculationPage/components/MKDPage/components/CalculationSteps";
import {calculationsSelectors} from "@/internal/lib/storeModels/models/mkds/calculations/calculationsState";
import Tabs from "@/presentation/components/common/tabs/Tabs";
import CalculationSettings from "@/presentation/components/heatCalculationPage/components/MKDPage/components/CalculationSettings";
import DataTable from "@/presentation/components/heatCalculationPage/components/MKDPage/components/DataTable";
import EnumeratorsValuesStateFabric from "@/internal/lib/storeModels/models/mkds/calculations/enumeratorsValuesStateFabric";
import {heatCalculateEndpoints} from "@/data/api_entities/heat_calculate/heat_calculate_endpoints";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {calculationSettingsFormSelectors} from "@/internal/lib/storeModels/models/mkds/calculations/forms";
import {mkdsStateSelectors} from "@/internal/lib/storeModels/models/mkds/mkdsState";
import {CALCULATION_STEPS} from "@/presentation/components/heatCalculationPage/components/MKDPage/components/const";
import ResultTable from "@/presentation/components/heatCalculationPage/components/MKDPage/components/ResultTable";
import {selectors} from "@/presentation/shared/ui/message";
import {FormattedMessage} from "react-intl";
import {useToggle} from "@/internal/custom_hooks/useToggle";
import DefaultModal from "@/presentation/components/common/modals/DefaultModal";
import {balanceEndpoint} from "@/data/api_entities/balance/balance_endpoint";
import {userDataSelectors} from "@/internal/lib/storeModels/models/user/userDataModel";
import {dateFormatter, formattedDateToServ} from "@/internal/formatters/formatters";

const Calculations = (
  {
    toggleModal,
    resultData = null,
    uploadCalcList,
    startSettings = null
  }) => {
  const [activeStep, _, resetActiveStep] = calculationsSelectors.activeStep.useState()
  const [calcId, setCalcId, resetCalcId] = calculationsSelectors.editCalculateId.useState()
  const [loadingCalc, setLoadingCalc] = calculationsSelectors.loadingCalc.useState()
  const resetCalcForm = calculationSettingsFormSelectors.useResetForm()
  const resetEnumeratorsForms = calculationsSelectors.enumeratorsForms.useReset()
  const [isCreateCalc, setIsCreateCalc] = useState(false)
  const [isCreatingForms, setIsCreatingForms] = useState(false)
  const selectedMKDId = mkdsStateSelectors.selectedMKD.useValue()
  const [enumeratorsForms, setEnumeratorsForms] = calculationsSelectors.enumeratorsForms.useState()
  const [apartments, setApartments] = calculationsSelectors.apartmentsList.useState()
  const setActiveStep = calculationsSelectors.activeStep.useSetValue()
  const [notValidApartments, setNotValidApartments, resetNotValidApartments] = calculationsSelectors.notValidApartments.useState()
  const [__, setCalcResultApartments, resetCalcResultApartments] = calculationsSelectors.calcResultApartments.useState()
  const [___, setResultTotalValues, resetResultTotalValues] = calculationsSelectors.resultTotalValues.useState()
  const [paginationLoading, setPaginationLoading] = useState(false)
  const [apartmentsPaginationParams, setApartmentsPaginationParams, resetApartmentsPaginationParams] = calculationsSelectors.apartmentsPaginationParams.useState()
  const [notValidPaginationParams, setNotValidPaginationParams, resetNotValidPaginationParams] = calculationsSelectors.notValidPaginationParams.useState()
  const [resultPaginationParams, setResultPaginationParams, resetResultPaginationParams] = calculationsSelectors.resultsPaginationParams.useState()
  const [isForceCalc, setIsForceCalc, resetIsForceCalc] = calculationsSelectors.isForceCalc.useState()
  const [dataSending, setDataSending] = useState({})
  const rowsPerPage = calculationsSelectors.rowsPerPage.useValue()
  const setInitCalcSettings = calculationSettingsFormSelectors.useSetInitFormData()
  const setMessage = selectors.useSetMessage()
  const [cost, setCost] = calculationsSelectors.calcCoast.useState()
  const [openCalcModal, toggleOpenCalcModal] = useToggle()
  const [shortCoast, setShortCoast] = useState(null)
  const updateUserData = userDataSelectors.userData.useUpdateUserData()

  const calcModalBody = useMemo(() => {
    return shortCoast
      ? `Вам не хватает ${shortCoast}₽ для выполнения расчета. Пожалуйста, пополните баланс.`
      : `Услуга платная, отчет будет стоить ${cost}₽. Подтверждаете?`
  }, [cost, shortCoast])

  const agreeCalcModalBtnConfig = useMemo(() => {
    return shortCoast
      ? {
        className: 'btn',
        text: 'Пополнить'
      }
      : {
        className: 'btn',
        text: 'Подтверждаю'
      }
  }, [shortCoast])

  const handleTopUpBalance = () => {
    balanceEndpoint.setPayment(shortCoast).promise.then((result) => {
      window.open(result)
      toggleOpenCalcModal()
      setShortCoast(null)
    })
  }

  const onAgreeCalcModal = () => {
    if (shortCoast) {
      handleTopUpBalance()
    } else {
      calculate({
        page: 1,
        limit: rowsPerPage
      })
    }
  }

  useEffect(() => {
    if (resultData) {
      setActiveStep(CALCULATION_STEPS.length - 1)
      setInitCalcSettings({
        name: resultData.name,
        start_date: resultData.start_date,
        end_date: resultData.end_date,
        energy_period: resultData.energy_period,
        mansions: resultData.mansions,
        unit_type: resultData.unit_type
      })
      setResultTotalValues(resultData.total_calculate)
      setCalcId(resultData.calculate)
      setResultPaginationParams({
        count: resultData.count,
        pageSize: resultData.limit,
        currentPage: 0
      })
      setCalcResultApartments(resultData.results)
    } else if (startSettings) {
      const date = new Date(startSettings.end_date)
      date.setDate(date.getDate() + 1)
      const today = new Date()

      if (date <= today) {
        setInitCalcSettings({
          start_date: date.toISOString().split('T')[0],
          unit_type: startSettings.unit_type
        })
      }
    } else {
      const currentDate = new Date();
      const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth - 1);
      const firstDayOfPreviousMonth = new Date(lastDayOfPreviousMonth.getFullYear(), lastDayOfPreviousMonth.getMonth(), 1);

      setInitCalcSettings({
        start_date: new Date(formattedDateToServ(firstDayOfPreviousMonth)).toISOString().split('T')[0],
        end_date: new Date(formattedDateToServ(lastDayOfPreviousMonth)).toISOString().split('T')[0],
      })
    }
  }, [resultData, startSettings])

  useEffect(() => {
    return () => {
      resetCalcId()
      resetCalcForm()
      resetActiveStep()
      resetEnumeratorsForms()
      resetCalcResultApartments()
      resetNotValidApartments()
      resetResultTotalValues()
      resetApartmentsPaginationParams()
      resetNotValidPaginationParams()
      resetResultPaginationParams()
      resetIsForceCalc()
    }
  }, [])

  const initializeForms = async (apartments, currentForms) => {
    const forms = await createForms(apartments, currentForms)

    setEnumeratorsForms({...currentForms, ...forms})
    setIsCreatingForms(false)
  }

  const handleCalcMethod = async (data, method) => {
    setIsCreateCalc(true)

    try {
      const {promise} = heatCalculateEndpoints[method]({
        ...data,
        mansions: selectedMKDId
      }, calcId)
      const calcRes = await promise

      if (calcRes?.detail === 'no apartments') {
        setIsCreateCalc(false)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'В МКД отсутствуют квартиры',
          type: 'error',
          count: 3000
        })
        return
      }

      if ('id' in calcRes) {
        setCalcId(calcRes.id)
        setIsCreatingForms(true)

        try {
          const {promise} = heatCalculateEndpoints.getIndicationsData(calcRes.id, {
            page: 1,
            limit: rowsPerPage
          })
          const indicationsRes = await promise

          if (Array.isArray(indicationsRes?.results)) {
            setApartments(indicationsRes.results)
            setApartmentsPaginationParams({
              count: indicationsRes.count,
              pageSize: indicationsRes.limit,
              currentPage: 0
            })
            setCost(indicationsRes?.paid)
            await initializeForms(indicationsRes.results, enumeratorsForms)
            setActiveStep(1)
          } else {
            setMessage({
              header: <FormattedMessage id={'requests.error'}/>,
              message: 'Не удалось инициализировать расчет',
              type: 'error',
              count: 3000
            })
            console.log(indicationsRes)
          }
        } catch (err) {
          setMessage({
            header: <FormattedMessage id={'requests.error'}/>,
            message: 'Не удалось получить список квартир',
            type: 'error',
            count: 3000
          })
          console.log(err)
        }
      }
    } catch (err) {
      setMessage({
        header: <FormattedMessage id={'requests.error'}/>,
        message: 'Не удалось инициализировать расчет',
        type: 'error',
        count: 3000
      })
      console.log(err)
    } finally {
      setIsCreateCalc(false)
    }
  }

  const onCreateCalc = async (data) => {
    await handleCalcMethod(data, 'create')
  }

  const onChangeCalc = async (data) => {
    await handleCalcMethod(data, 'change')
  }

  const addForms = async (apartments, currentForms) => {
    const forms = await createForms(apartments, currentForms)

    setEnumeratorsForms({...currentForms, ...forms})
    setPaginationLoading(false)
  }

  const handleChangeApartmentsPage = async (e, newPage, rows = null) => {
    if (paginationLoading || isSendingData) return
    setPaginationLoading(true)

    try {
      const {promise} = heatCalculateEndpoints.getIndicationsData(calcId, {
        page: newPage + 1,
        limit: rows ?? rowsPerPage
      })
      const res = await promise

      if (Array.isArray(res?.results)) {
        await addForms(res.results, enumeratorsForms)
        setApartments(res.results)
        setApartmentsPaginationParams({
          count: res.count,
          currentPage: newPage
        })
        setCost(res?.paid)
      } else {
        setPaginationLoading(false)
        console.log(res)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось загрузить список квартир',
          type: 'error',
          count: 3000
        })
      }
    } catch (err) {
      console.log(err)
      setMessage({
        header: <FormattedMessage id={'requests.error'}/>,
        message: 'Не удалось загрузить список квартир',
        type: 'error',
        count: 3000
      })
      setPaginationLoading(false)
    }
  }

  const handleChangeNotValidPage = async (e, newPage, rows = null) => {
    if (paginationLoading || isSendingData) return
    setPaginationLoading(true)

    try {
      const {promise} = heatCalculateEndpoints.calculate(calcId, {
        page: newPage + 1,
        limit: rows ?? rowsPerPage
      }, false)
      const res = await promise

      if (Array.isArray(res?.results)) {
        await addForms(res.results, enumeratorsForms)
        setNotValidApartments(res.results)
        setNotValidPaginationParams({
          count: res.count,
          pageSize: res.limit,
          currentPage: newPage
        })
      } else {
        setPaginationLoading(false)
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось загрузить список квартир',
          type: 'error',
          count: 3000
        })
        console.log(res)
      }
    } catch (err) {
      setMessage({
        header: <FormattedMessage id={'requests.error'}/>,
        message: 'Не удалось загрузить список квартир',
        type: 'error',
        count: 3000
      })
      console.log(err)
      setPaginationLoading(false)
    }
  }

  const handleChangeResultPage = async (e, newPage, rows = null) => {
    if (paginationLoading) return
    setPaginationLoading(true)

    try {
      const {promise} = heatCalculateEndpoints.getHistoryById(calcId, {
        page: newPage + 1,
        limit: rows ?? rowsPerPage
      })
      const res = await promise

      if (Array.isArray(res?.results)) {
        setCalcResultApartments(res.results)
        setResultPaginationParams({
          count: res.count,
          pageSize: res.limit,
          currentPage: newPage
        })
      } else {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось загрузить результаты расчета',
          type: 'error',
          count: 3000
        })
        console.log(res)
      }
    } catch (err) {
      setMessage({
        header: <FormattedMessage id={'requests.error'}/>,
        message: 'Не удалось загрузить результаты расчета',
        type: 'error',
        count: 3000
      })
      console.log(err)
    } finally {
      setPaginationLoading(false)
    }
  }

  const createForms = async (apartments, currentForms) => {
    const batchSize = 100
    let index = 0
    const forms = {}

    while (index < apartments.length) {
      const batch = apartments.slice(index, index + batchSize);
      batch.forEach(apartment => {
        if (currentForms[apartment.id]) return
        forms[apartment.id] = new EnumeratorsValuesStateFabric({
          enumerators: apartment.enumerator ?? []
        }).createSelectors()
      })

      index += batchSize;

      await new Promise(resolve => setTimeout(resolve, 0));
    }

    return forms
  }

  const calculate = async (pageParams = null) => {
    if (!calcId || isCreateCalc || isCreatingForms || isSendingData) return

    setLoadingCalc(true)

    try {
      const {promise} = heatCalculateEndpoints.calculate(calcId, pageParams, isForceCalc)
      const res = await promise

      if (res?.status === 'error' && Array.isArray(res?.results)) {
        await addForms(res.results, enumeratorsForms)
        setIsForceCalc(true)
        setNotValidApartments(res.results)
        setActiveStep(CALCULATION_STEPS.length - 2)
        resetResultTotalValues()
        resetCalcResultApartments()
        setNotValidPaginationParams({
          count: res.count,
          pageSize: res.limit,
          currentPage: 0
        })
        toggleOpenCalcModal()
        setMessage({
          header: 'Невалидные данные расчета',
          message: 'Проверьте невалидные данные перед проведением оплаты',
          type: 'info',
          count: 10000
        })
      } else if (res?.status === 'ok' && Array.isArray(res?.results)) {
        uploadCalcList()
        setCalcResultApartments(res?.results)
        setActiveStep(CALCULATION_STEPS.length - 1)
        resetNotValidApartments()
        setResultTotalValues(res?.total_calculate)
        setResultPaginationParams({
          count: res.count,
          pageSize: res.limit,
          currentPage: 0
        })
        toggleOpenCalcModal()
        updateUserData()
      } else if (res.user_balance) {
        setShortCoast(res.user_balance)
      } else {
        setMessage({
          header: <FormattedMessage id={'requests.error'}/>,
          message: 'Не удалось выполнить расчет',
          type: 'error',
          count: 3000
        })
        console.log(res)
      }
    } catch (err) {
      setMessage({
        header: <FormattedMessage id={'requests.error'}/>,
        message: 'Не удалось выполнить расчет',
        type: 'error',
        count: 3000
      })
      console.log(err)
    } finally {
      setLoadingCalc(false)
    }
  }

  const isSendingData = useMemo(() => {
    let isSending = false

    for (const key in dataSending) {
      if (dataSending[key]) {
        isSending = true
        break
      } else continue
    }

    return isSending
  }, [dataSending])

  return (
    <div className={'calculations'}>
      <div className="calculations__header">
        <h2 className={'title'}>
          Расчет теплопотребления
        </h2>

        <div
          className='calculations__close'
          onClick={toggleModal}
        >
          <img src={Close} alt="close"/>
        </div>
      </div>

      {!resultData &&
        <CalculationSteps
          loading={isCreateCalc ||
            isCreatingForms ||
            loadingCalc ||
            paginationLoading}
          onCreateCalc={onCreateCalc}
          onChangeCalc={onChangeCalc}
          calculate={calculate}
        />
      }

      <Tabs activeTab={activeStep}>
        <CalculationSettings
          name={0}
          onCreateCalc={onCreateCalc}
          onChangeCalc={onChangeCalc}
          loading={isCreateCalc ||
            isCreatingForms ||
            paginationLoading}
        />

        <DataTable
          key={1}
          name={1}
          apartments={apartments}
          calculate={calculate}
          onChangePage={handleChangeApartmentsPage}
          paginationParams={apartmentsPaginationParams}
          loadingCalc={loadingCalc || paginationLoading}
          calcDisabled={isSendingData}
          toggleOpenCalcModal={toggleOpenCalcModal}
          setDataSending={setDataSending}
        />

        <DataTable
          key={2}
          name={2}
          apartments={notValidApartments}
          calculate={calculate}
          onChangePage={handleChangeNotValidPage}
          paginationParams={notValidPaginationParams}
          loadingCalc={loadingCalc || paginationLoading}
          calcDisabled={isSendingData}
          toggleOpenCalcModal={toggleOpenCalcModal}
          setDataSending={setDataSending}
        />

        <ResultTable
          name={3}
          loading={paginationLoading}
          paginationParams={resultPaginationParams}
          onChangePage={handleChangeResultPage}
        />
      </Tabs>

      <DefaultModal
        isOpen={openCalcModal}
        bodyText={calcModalBody}
        headerTitle={'Оплата расчета'}
        onAgree={onAgreeCalcModal}
        toggleModal={toggleOpenCalcModal}
        onDisagree={toggleOpenCalcModal}
        agreeBtnConfig={agreeCalcModalBtnConfig}
      />
    </div>
  )
}

export default Calculations
